import { useFormikContext } from "formik";
import React from "react";

import AdvancedOptionsSection from "../../TempInviteAgency/AdvancedOptions";
import ContractWorkforce from "../../TempInviteAgency/ContractWorkforce";
import PermanentPlacement from "../../TempInviteAgency/PermanentPlacement";
import PlacementTypeSelection from "../../TempInviteAgency/PlacementTypeSelection";
import EmployerAbsorptionRateField from "../../TempInviteAgency/shared/EmployerAbsorptionRateField";

function AddAsLegacyModalContent({ currentOrganization }) {
  const { values } = useFormikContext();

  const displayAdvancedOptions =
    values.placementTypes.temp || values.placementTypes.perm;

  return (
    <>
      <div style={{ paddingBottom: 8 }}>
        As a preferred agency you will be able to control which JobCasts this
        agency receives. After they are invited their team will show up in
        Agency Selection when creating a JobCast.
      </div>
      {currentOrganization.contractActivated && <PlacementTypeSelection />}
      {values.placementTypes?.perm && <PermanentPlacement />}
      {currentOrganization.contractActivated &&
        values.placementTypes?.temp && <ContractWorkforce />}
      {displayAdvancedOptions && (
        <AdvancedOptionsSection>
          <EmployerAbsorptionRateField fieldId="employerAbsorptionRate" />
        </AdvancedOptionsSection>
      )}
    </>
  );
}

export default AddAsLegacyModalContent;
