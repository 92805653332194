// sections
export const PENDING = "pending";
export const INVITE = "invite";
export const DEACTIVATED = "deactivated";
export const ACTIVE = "active";
export const TIMESHEET_SUBMITTERS = "timesheet_submitters";

export const SECTIONS = {
  [ACTIVE]: { param: "", label: "Active" },
  [PENDING]: { param: "pending", label: "Pending" },
  [INVITE]: { param: "invite", label: "Invite" },
  [DEACTIVATED]: { param: "deactivated", label: "Deactivated" },
};
