import { Tooltip } from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grade from "@material-ui/icons/Grade";
import StarBorder from "@material-ui/icons/StarBorder";
import range from "lodash/range";
import React, { Component } from "react";

import { Row, Col } from "react-grid-system";

const TOOLTIP_LABELS = ["Terrible", "Poor", "Fair", "Good", "Great"];

class StarRatingPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverRating: null,
      rating: parseInt(props.value, 10) || 0,
    };
  }

  hover = (i) => () => {
    this.setState({ hoverRating: i });
  };

  mouseOut = () => {
    this.setState({ hoverRating: 0 });
  };

  click = (i) => () => {
    this.setState({ rating: i, value: i }, () =>
      this.props.onChange({ target: { value: this.state.rating } })
    );
  };

  renderErrorMsg() {
    return (
      <FormHelperText
        style={{
          width: "100%",
          color: "#FF6D6D",
          fontSize: "10px",
          marginBottom: "8px",
          marginTop: "0px",
          paddingBottom: "0px",
        }}
        id={`${this.props.id}-helper-text`}
      >
        {this.props.helperText}
      </FormHelperText>
    );
  }

  renderStar(i) {
    const filled = this.state.rating >= i || this.state.hoverRating >= i;
    if (filled) {
      return (
        <Grade
          style={{
            cursor: "pointer",
            color: "#008DAE",
            width: "1.5em",
            height: "1.5em",
          }}
        />
      );
    }
    return (
      <StarBorder
        style={{
          cursor: "pointer",
          width: "1.5em",
          height: "1.5em",
          color: "#008DAE",
        }}
      />
    );
  }

  renderRadio(i) {
    return (
      <span
        key={`${i}-star-label`}
        data-cy={`${i}-star-label`}
        onMouseEnter={this.hover(i)}
        onMouseLeave={this.mouseOut}
      >
        <Tooltip title={TOOLTIP_LABELS[i - 1]}>
          <label
            id="star"
            key={`${i}-star-label`}
            onClick={this.click(i)}
            data-layout={`${this.props.id}_${i}`}
          >
            {this.renderStar(i)}
          </label>
        </Tooltip>
      </span>
    );
  }

  renderRadios() {
    return range(5).map((i) => this.renderRadio(i + 1));
  }

  colStyle() {
    const style = { padding: 0, margin: 0 };

    if (this.props.centered) {
      style.alignItems = "center";
      style.display = "flex";
      style.flexDirection = "column";
    }

    return style;
  }

  render() {
    const { label, error } = this.props;

    return (
      <Col
        xs={this.props.col_width}
        style={this.colStyle()}
        onMouseLeave={this.mouseOut}
        data-layout={this.props.id}
      >
        {label && (
          <div
            className="static-input-label"
            style={
              error
                ? { color: "#FF6D6D", paddingBottom: "0px" }
                : { paddingBottom: "0px" }
            }
          >
            {label}
          </div>
        )}
        <Row style={{ margin: 0 }} onMouseLeave={this.mouseOut}>
          <span onMouseLeave={this.mouseOut}>{this.renderRadios()}</span>
        </Row>
        <Row style={{ margin: 0 }}>
          <Col xs={12} style={{ padding: 0 }}>
            {this.renderErrorMsg()}
          </Col>
        </Row>
      </Col>
    );
  }
}

export default StarRatingPanel;
