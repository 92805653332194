import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import map from "lodash/map";
import React from "react";

import constants from "../../../../util/constants";

const { joinRecruitifiContent } = constants;

const styles = {
  container: {
    display: "flex",
    justifyContent: "space-around"
  },
  featureContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "33%",
    padding: "0 10px",
    "&:last-of-type": {
      borderRight: "none"
    }
  },
  featureImage: {
    height: 68
  },
  featureCaption: {
    marginTop: 12,
    textAlign: "center",
    fontSize: 12,
    width: "100%",
    lineHeight: "16px"
  },
  arrowIcon: {
    fontSize: 32,
    color: "#979797ad",
    position: "relative",
    left: "50%",
    bottom: "62%",
    fontStretch: "expanded"
  }
};

function RFIFeaturesGraphic({ classes, features = joinRecruitifiContent }) {
  return (
    <div className={classes.container}>
      {map(features, ({ imageURL, title, alt }, index) => (
        <div className={classes.featureContainer}>
          <img src={imageURL} alt={alt} className={classes.featureImage} />

          <div className={classes.featureCaption}>{title}</div>
          {index != 2 && <Icon className={classes.arrowIcon}>trending_flat</Icon>}

        </div>
      ))}
    </div>
  );
}

export default withStyles(styles)(RFIFeaturesGraphic);
