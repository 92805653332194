import MUICheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";

import classnames from "classnames";
import React from "react";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  helperText: {
    fontSize: 10,
    marginTop: 2,
    minHeight: "1em",
    lineHeight: "1em",
    fontFamily: "'proxima-nova', helvetica",
  },
  error: {
    color: "#FF6D6D",
  },
});

function Checkbox({
  id,
  value,
  label,
  onChange = () => {},
  onBlur = () => {},
  error,
  labelStyle = {},
  helperText,
  disabled,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FormControlLabel
        control={
          <MUICheckbox
            {...{
              id,
              onChange,
              onBlur,
              disabled,
            }}
            checked={value}
            color={error ? "secondary" : "primary"}
            inputProps={{ "data-cy": `checkbox-${id}` }}
            classes={{ colorSecondary: classes.error }}
          />
        }
        label={label}
        style={labelStyle}
      />
      <FormHelperText
        className={classnames(classes.helperText, { [classes.error]: error })}
      >
        {helperText}
      </FormHelperText>
    </div>
  );
}

export default Checkbox;
