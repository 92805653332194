import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

import {
  RECEIVE_JOBCAST_DRAFTS,
  RECEIVE_JOBCAST_TEMPLATES,
  REMOVE_JOBCAST_DRAFT,
  RECEIVE_JOBCAST_DRAFT,
} from "../actions/employers/draftActions";
import { PERM, TEMP } from "../selectors/jobcastSelectors";

const defaultState = {
  items: {},
  draftsLoaded: {
    [TEMP]: false,
    [PERM]: false,
  },
  templatesLoaded: false,
};

const draftReducer = (state = defaultState, { type, payload } = {}) => {
  Object.freeze(state);
  let newItems;
  let newDraft;

  switch (type) {
    case RECEIVE_JOBCAST_DRAFTS:
      return merge({}, state, {
        draftsLoaded: { [payload.type]: true },
        items: payload.drafts,
      });
    case RECEIVE_JOBCAST_TEMPLATES:
      return merge({}, state, { templatesLoaded: true, items: payload });
    case REMOVE_JOBCAST_DRAFT:
      newItems = cloneDeep(state.items);
      delete newItems[payload];
      return { ...state, items: newItems };
    case RECEIVE_JOBCAST_DRAFT:
      newItems = cloneDeep(state.items);
      newDraft = payload;

      return {
        ...state,
        items: { ...newItems, ...newDraft },
      };
    default:
      return state;
  }
};

export default draftReducer;
