import { makeStyles, SvgIcon } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { useDispatch, useSelector } from "react-redux";

import { openModal } from "../../../actions/shared/uiActions";
import { getCurrentIndividual } from "../../../selectors/individualSelectors";
import ViolationModal from "../../employer/Agencies/modals/ViolationModal/ViolationModal";
import ConvertContractModal from "../../employer/TempJobCasts/view/Candidates/actions/modals/ConvertContract/ConvertContractModal";
import ActionMenu from "../../General/ActionMenu";
import CloseContractModal from "../Contracts/CloseContractModal";
import EditContractModal from "../Contracts/EditContractModal";

const CLOSE_CONTRACT = "close_contract";
const CONVERT_CONTRACT = "convert_contract";
const EDIT_CONTRACT = "edit_contract";
const REPORT = "report";

const StopIcon = () => (
  <SvgIcon>
    <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M9,9H15V15H9" />
  </SvgIcon>
);

const employerActions = [
  {
    name: CLOSE_CONTRACT,
    icon: <StopIcon />,
  },
  {
    name: CONVERT_CONTRACT,
    icon: <CheckIcon />,
  },
  {
    name: EDIT_CONTRACT,
    icon: <EditIcon />,
  },
  {
    name: REPORT,
    icon: "flag",
  }
];

const recruiterActions = [
  {
    name: CLOSE_CONTRACT,
    icon: <StopIcon />,
  },
  {
    name: EDIT_CONTRACT,
    icon: <EditIcon />,
  }
];

const useActionDispatch = (candidate) => {
  const { recruiterId, recruiterFullName } = candidate.attributes;
  const dispatch = useDispatch();

  return (actionName) => {
    switch (actionName) {
      case CLOSE_CONTRACT:
        dispatch(openModal(<CloseContractModal candidate={candidate} />));
        break;
      case CONVERT_CONTRACT:
        dispatch(openModal(<ConvertContractModal candidateId={candidate.id} />));
        break;
      case EDIT_CONTRACT:
        dispatch(openModal(<EditContractModal candidate={candidate} />));
        break;
      case REPORT:
        dispatch(openModal(
          <ViolationModal
            recruiterId={recruiterId}
            recruiterName={recruiterFullName}
          />
        ));
        break;
      default:
        break;
    }
  };
};

const useStyles = makeStyles(() => ({
  menuPaper: { width: 250 }
}));

export function EditContractActionMenu({ candidate }) {
  const handleActionSelection = useActionDispatch(candidate);
  const classes = useStyles();

  return (
    <ActionMenu
      actions={[
        { name: EDIT_CONTRACT, icon: <EditIcon /> },
        { name: REPORT, icon: "flag" }
      ]}
      handleActionSelection={handleActionSelection}
      overrideMenuStyles={{ marginLeft: 0, marginTop: 0 }}
      menuClasses={{ paper: classes.menuPaper }}
    />
  );
}

export default function ContractCandidateActionMenu({ candidate }) {
  const currentIndividual = useSelector(getCurrentIndividual);
  const classes = useStyles();
  const handleActionSelection = useActionDispatch(candidate);

  return (
    <ActionMenu
      actions={currentIndividual.employer ? employerActions : recruiterActions}
      dataCy={`candidate-action-menu-${candidate.id}`}
      handleActionSelection={handleActionSelection}
      overrideMenuStyles={{ marginLeft: 0, marginTop: 0 }}
      menuClasses={{ paper: classes.menuPaper }}
    />
  );
}
