const tableStyles = {
  body: {
    height: "100%",
    border: "none",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    overflow: "hidden",
    fontSize: 14
  },
  tableContainer: {
    padding: "0px 12px 24px 12px",
    backgroundColor: "white",
    paddingTop: 10
  },
  header: {
    paddingLeft: 24,
    paddingRight: 12,
    height: 48,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "10px 0"
  },
  tableBody: {
    paddingLeft: 12,
    backgroundColor: "white",
    border: "none",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    overflow: "hidden",
    fontSize: 14,
    "& > div:not(:last-child)": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)"
    }
  },
  bodyRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: 48,
    padding: 12
  },
  tableCell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
    overflowWrap: "break-word",
    "& > div": {
      width: "90%"
    }
  },
  headerCell: {
    color: "#37474f",
    fontSize: "14px",
    fontWeight: "normal",
    textTransform: "uppercase",
    letterSpacing: "-0.1px",
    position: "relative",
  },
  sortableHeaderLabel: {
    display: "flex",
    position: "relative",
    "&:hover": {
      color: "#008dae",
      cursor: "pointer"
    }
  },
  staticHeaderLabel: {
    width: '100%'
  },
  sortIconContainer: {
    position: "absolute",
    left: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  sortIcon: {
    fontSize: 12,
    marginLeft: 2
  }
};

export default tableStyles;
