import merge from "lodash/merge";
import React from "react";

import SortableTable from "./SortableTable";

export const drawerTableStyles = {
  container: {
    paddingTop: 0,
    backgroundColor: "rgb(236, 239, 241)"
  },
  body: {
    border: "1px solid #b0bec5",
    overflowY: "scroll"
  }
};

function AgencySortableTable({
  columns, items, defaultSortKey, customStyles
}) {
  return (
    <SortableTable
      columns={columns}
      items={items}
      defaultSortKey={defaultSortKey}
      customStyles={merge({}, drawerTableStyles, customStyles)}
    />
  );
}

export default AgencySortableTable;
