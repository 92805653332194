import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { archiveQuestion } from "../../../../../actions/employers/jobcastUpdatesActions";
import { clearModal } from "../../../../../actions/shared/uiActions";
import ActionModalContainer from "../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../General/ActionModal/ActionModalHeader";

import { REMOTE } from "../../create/constants";

export class ArchiveQuestionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { submitted } = this.state;
    const { archiveQuestion, questionId, clearModal } = this.props;

    if (submitted) {

    } else {
      this.setState({ submitted: true });
      archiveQuestion(questionId).then(clearModal());
    }
  }

  render() {
    const { locations, title, workRemotely } = this.props.jobcast.attributes;

    const location = (workRemotely === REMOTE)
      ? `Remote, ${locations[0].country_code}`
      : `${locations[0].city}, ${locations[0].state}`;

    return (
      <ActionModalContainer color="red">
        <ActionModalHeader
          title="Archive Agency Question"
          subtitle={`${title} - ${location}`}
        />
        <ActionModalContent>
          Are you sure you would like to archive this question? You will not be
          able to undo this action.
        </ActionModalContent>
        <ActionModalFooter
          actionText="Archive"
          handleAction={this.handleClick}
        />
      </ActionModalContainer>
    );
  }
}

ArchiveQuestionModal.propTypes = {
  archiveQuestion: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  jobcast: PropTypes.object.isRequired,
  questionId: PropTypes.number.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  clearModal: () => dispatch(clearModal()),
  archiveQuestion: (id) => dispatch(archiveQuestion(id)),
});

export default connect(null, mapDispatchToProps)(ArchiveQuestionModal);
