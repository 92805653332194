export const RECEIVE_TEMP_PREREQUISITES = "RECEIVE_TEMP_PREREQUISITES";
export const RECEIVE_TEMP_PREREQUISITE = "RECEIVE_TEMP_PREREQUISITE";

export const receiveTempPrerequisites = (tempPrerequisites) => ({
  type: RECEIVE_TEMP_PREREQUISITES,
  payload: tempPrerequisites,
});

export const receiveTempPrerequisite = (tempPrerequisite) => ({
  type: RECEIVE_TEMP_PREREQUISITE,
  payload: tempPrerequisite,
});

export const getTempPrerequisites = (state) => state.tempPrerequisites.items;
