// callback action types//
export const CLEAR_CURRENT_ORGANIZATION = "CLEAR_CURRENT_ORGANIZATION";
export const RECEIVE_CURRENT_ORGANIZATION = "RECEIVE_CURRENT_ORGANIZATION";
export const RECEIVE_ORGANIZATIONS = "RECEIVE_ORGANIZATIONS";

// callback actions//
export const clearCurrentOrganization = () => ({
  type: CLEAR_CURRENT_ORGANIZATION
});

export const receiveCurrentOrganization = organization => ({
  type: RECEIVE_CURRENT_ORGANIZATION,
  payload: organization
});

export const receiveOrganizations = organizations => ({
  type: RECEIVE_ORGANIZATIONS,
  payload: organizations
});
