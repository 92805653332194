import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import map from "lodash/map";
import merge from "lodash/merge";
import sortBy from "lodash/sortBy";
import PropTypes from "prop-types";
import React from "react";

class DropdownInput extends React.Component {
  renderOptions(options) {
    const isValueDefined = (value) => value || value === 0;

    return map(options, (option) => (
      <option
        key={isValueDefined(option.value) ? option.value : option}
        value={isValueDefined(option.value) ? option.value : option}
        disabled={option && option.disabled}
      >
        {option.label || option}
      </option>
    ));
  }

  renderOptionGroup(group) {
    const options = sortBy(group.options, 'value');

    return (
      <optgroup label={group.label}>
        {this.renderOptions(options)}
      </optgroup>
    );
  }

  renderOptionGroups() {
    return map(this.props.optionGroups, (optionGroup) => this.renderOptionGroup(optionGroup));
  }

  render() {
    const {
      disabled,
      id,
      label,
      onChange,
      onBlur,
      value,
      error,
      helperText,
      noShrink,
      allowEmpty,
      overrideStyle,
      disableUnderline,
      labelStyle,
      helperTextOverrideStyle,
      inputProps = {},
      InputProps = {},
      options,
      optionGroups,
    } = this.props;

    return (
      <FormControl disabled={disabled} style={overrideStyle || {}}>
        <InputLabel
          error={error}
          htmlFor={id}
          shrink={noShrink || disabled}
          style={merge(
            labelStyle,
            error ? { color: "#FF6D6D" } : { color: "#b0bec5" }
          )}
        >
          {label}
        </InputLabel>
        <NativeSelect
          input={(
            <Input
              disableUnderline={Boolean(disableUnderline || disabled)}
              id={id}
              error={error}
              {...InputProps}
            />
          )}
          IconComponent={(disabled || undefined) && (() => null)}
          onChange={onChange}
          onBlur={onBlur}
          value={value || ""}
          inputProps={({
            "data-cy": `dropdown-input-${
              typeof label === "string" ? label.toLowerCase() : ""
            }`,
            ...inputProps
          })}
        >
          {(allowEmpty || !value || value.length === 0) && (
            <option key="blank" value="" disabled={!allowEmpty} />
          )}
          {optionGroups
            ? this.renderOptionGroups()
            : this.renderOptions(options)}
        </NativeSelect>
        {helperText && (
          <FormHelperText
            style={({
              width: "100%",
              margin: "0",
              position: "inherit",
              fontSize: "10px",
              textAlign: "left",
              marginTop: "8px",
              minHeight: "1em",
              fontFamily: "proxima-nova",
              lineHeight: "1em",
              ...helperTextOverrideStyle
            })}
            error={Boolean(error)}
            data-cy={`${label}-dropdown-helper-text`}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

DropdownInput.defaultProps = {
  disableUnderline: false,
  allowEmpty: false,
  helperTextOverrideStyle: {},
  options: [],
};

DropdownInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  forceShrink: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  value: PropTypes.any,
  noShrink: PropTypes.bool,
  overrideStyle: PropTypes.object,
  disableUnderline: PropTypes.bool,
};

export default DropdownInput;
