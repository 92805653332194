import React from "react";

function getPlatform() {
  if (
    typeof navigator.userAgentData !== "undefined" &&
    navigator.userAgentData != null
  ) {
    return navigator.userAgentData.platform;
  }
  if (typeof navigator.platform !== "undefined") {
    if (
      typeof navigator.userAgent !== "undefined" &&
      /android/.test(navigator.userAgent.toLowerCase())
    ) {
      return "android";
    }
    return navigator.platform;
  }
  return "unknown";
}

function isMacLike() {
  return /(mac|iphone|ipod|ipad)/i.test(getPlatform());
}

function ControlHotkey({ apple }) {
  return apple ? (
    <abbr title="Command" style={{ textDecoration: "none" }}>
      ⌘
    </abbr>
  ) : (
    <kbd title="Control" style={{ fontFamily: "sans-serif" }}>
      Ctrl
    </kbd>
  );
}

export default function Hotkey({ hotkey, modifierKey = "control" }) {
  const apple = isMacLike();

  const modifier =
    modifierKey === "control" ? (
      <ControlHotkey {...{ apple }} />
    ) : (
      <kbd style={{ fontFamily: "sans-serif" }} title={modifierKey}>modifierKey</kbd>
    );

  return (
    <span
      style={{
        fontWeight: 400,
        color: "#B0BEC5",
        fontStyle: "normal",
        fontFamily: "sans-serif",
        paddingRight: 8,
        fontSize: 12,
      }}
    >
      {modifier}
      {modifierKey && !apple && " + "}
      <kbd style={{ fontFamily: "sans-serif" }}>{hotkey}</kbd>
    </span>
  );
}
