import { useFormikContext } from "formik";
import intersection from "lodash/intersection";
import mapValues from "lodash/mapValues";
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";

import GeneralFormStepper from "../../../forms/custom/FormStepper";

import FormStepContext from "./FormStepContext";

export function FormStepper() {
  const { steps, currStep } = useContext(FormStepContext);

  return <GeneralFormStepper activeStep={currStep} steps={steps} />;
}

function FormStepProvider({
  allSteps, stepComponents, sectionFields, children
}) {
  const [currStep, setCurrStep] = useState(0);

  const {
    errors, values, validateForm, setTouched
  } = useFormikContext();

  // populate errors object on mount
  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const validateStep = useCallback(
    (stepName) => {
      const fieldsToValidate = sectionFields[stepName];
      const touchedUpdate = mapValues(
        fieldsToValidate,
        (touchResolver, fieldName) => {
          if (typeof touchResolver !== "function") return true;

          // create a `touched` object with the same shape as `values`, handle array nesting
          return new Array(values[fieldName].length)
            .fill(null)
            .map(touchResolver);
        }
      );

      setTouched(touchedUpdate);

      const errorFields = intersection(
        Object.keys(errors),
        Object.keys(fieldsToValidate)
      );

      return !errorFields.length;
    },
    [sectionFields, setTouched, errors, values]
  );

  const handleStepTransition = useCallback(
    (delta) => {
      if (delta <= 0) {
        setCurrStep(Math.max(0, currStep + delta));
        return;
      }

      if (validateStep(allSteps[currStep])) {
        setCurrStep(Math.min(currStep + delta, allSteps.length - 1));
      }
    },
    [validateStep, allSteps, currStep]
  );

  const contextValue = useMemo(
    () => ({
      steps: allSteps,
      currStep,
      handleStepTransition,
    }),
    [allSteps, currStep, handleStepTransition]
  );

  return (
    <FormStepContext.Provider value={contextValue}>
      {children(stepComponents[allSteps[currStep]])}
    </FormStepContext.Provider>
  );
}

export default FormStepProvider;
