import { RECEIVE_FEATURE_FLAGS } from "../actions/shared/featureFlagActions";

const _defaultState = {
  flags: [],
};

export default function featureFlagReducer(
  state = _defaultState,
  { type, payload } = {}
) {
  switch (type) {
    case RECEIVE_FEATURE_FLAGS: {
      const flags = Object.entries(payload).reduce((prev, [key, value]) => {
        if (value === true) prev.push(key);
        return prev;
      }, []);
      return { flags };
    }
    default:
      return state;
  }
}
