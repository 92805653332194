import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";

import CalendarClock from "mdi-material-ui/CalendarClock";
import moment from "moment";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

// components
import { fetchCandidate } from "../../../../actions/employers/candidateActions";
import {
  openProfileDrawer,
  setNotificationsDrawerVisible,
} from "../../../../actions/shared/uiActions";
import { getCandidate } from "../../../../selectors/candidateSelectors";
import {
  makeGetFilteredNotifications,
  UNREAD,
} from "../../../../selectors/notificationsSelectors";
import LoadingPage from "../../../General/LoadingPage";
import withNotificationsReader from "../../../shared/CandidateDrawer/CandidateDrawerTabs/withNotificationsReader";
import Content from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Content";
import Subheader from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";

// retrieve candidates
import { EVENT_HISTORY } from "../../JobCasts/view/Candidates/constants";
import CandidateDrawer from "../../JobCasts/view/Candidates/view/CandidateDrawer";

// Material UI

function CustomTextComponent({ label, value, helperText }) {
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>{value}</div>
      <FormHelperText style={{ fontSize: "12px" }}>{helperText}</FormHelperText>
    </div>
  );
}

function OfferStageReminder(props) {
  const {
    notification: {
      attributes: { recruiterSubmissionId, createdAt },
    },
  } = props;
  const dispatch = useDispatch();

  const candidate = useSelector((state) => getCandidate(state, recruiterSubmissionId));

  const handleCandidateDrawerOpen = () => {
    dispatch(setNotificationsDrawerVisible(false));
    dispatch(
      openProfileDrawer(
        <CandidateDrawer
          candidateId={candidate.id}
          navSelection={EVENT_HISTORY}
        />
      )
    );
  };

  useEffect(() => {
    dispatch(fetchCandidate(recruiterSubmissionId));
  }, [recruiterSubmissionId, dispatch]);

  return (
    <div>
      {candidate ? (
        <>
          <Subheader headerText="Candidate Update" />
          <Content
            iconImage={CalendarClock}
            iconCaption="Candidate Update"
            label="Offer Stage Reminder"
            value={(
              <>
                <span
                  style={{
                    cursor: "pointer",
                    color: "#008DAE",
                    textDecoration: "underline",
                  }}
                  onClick={handleCandidateDrawerOpen}
                >
                  {candidate.attributes.firstName}
                  {" "}
                  {candidate.attributes.lastName}
                </span>
                {" "}
                has been in the offer stage for two weeks. If anything has
                changed, please update them .
              </>
            )}
            helperText={moment(createdAt).format("MMMM Do, YYYY")}
            CustomTextComponent={CustomTextComponent}
          />
        </>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
}

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();

  const mapStateToProps = (state, { notification }) => {
    const notifs = getFilteredNotifications(state, {
      [UNREAD]: true,
      subjectId: notification.attributes.notificationSubjectId,
      event: notification.attributes.event,
    });

    return { unreadNotifications: notifs };
  };

  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  null
)(withNotificationsReader(OfferStageReminder));
