import debounce from "lodash/debounce";
import forEach from "lodash/forEach";
import isArray from "lodash/isArray";
import merge from "lodash/merge";
import pick from "lodash/pick";

import queryString from "query-string";
import { useEffect, useCallback, useState } from "react";

import { usePrevious } from "./usePrevious";

export const LOADED = "LOADED";
export const LOADING = "LOADING";
export const IDLE = "IDLE";
export const ERROR = "ERROR";

export const DEFAULT_PARAMS = { page: 1, perPage: 15 };

const convertArrayParamsToStr = (params) => {
  const convertedParams = {};

  forEach(params, (value, key) => {
    convertedParams[key] = isArray(value) ? value.join(",") : value;
  });

  return convertedParams;
};

function usePagination({
  fetchFunction,
  customParams = {},
  debounceInterval = 100,
}) {
  const [loadStatus, setLoadStatus] = useState(IDLE);
  const [responseData, setResponseData] = useState({});

  const [fetchParams, setFetchParams] = useState(
    merge({}, DEFAULT_PARAMS, customParams)
  );

  const customParamsStr = queryString.stringify(customParams);
  const prevCustomParamsStr = usePrevious(customParamsStr);
  useEffect(() => {
    if (
      prevCustomParamsStr !== undefined &&
      customParamsStr !== prevCustomParamsStr
    ) {
      setResponseData({});
      setFetchParams(merge({}, DEFAULT_PARAMS, customParams, { page: 1 }));
    }
  }, [customParamsStr, prevCustomParamsStr, customParams]);

  /* eslint-disable react-hooks/exhaustive-deps */
  const performFetch = useCallback(
    debounce(
      (fetchParams) => fetchFunction(convertArrayParamsToStr(fetchParams))
        .then(({ items, metaData }) => {
          setResponseData((currResponseData) => ({
            ...metaData,
            currentItems: items,
            allFetchedItems: merge(
              {},
              currResponseData.allFetchedItems || {},
              items
            ),
            allOrderedIds: (currResponseData.allOrderedIds || []).concat(
              metaData.currentOrderedIds
            ),
            appliedFetchParams: fetchParams,
          }));
          setLoadStatus(LOADED);
        })
        .catch(() => {
          setLoadStatus(ERROR);
        }),
      debounceInterval
    ),
    []
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setLoadStatus(LOADING);
    performFetch(fetchParams);
  }, [fetchParams, performFetch]);

  const handlePageChange = useCallback(
    (newPage) => {
      if (loadStatus !== LOADED) return;

      setFetchParams((currFetchParams) => (
        merge({}, currFetchParams, {
          page: newPage === undefined ? currFetchParams.page + 1 : newPage,
        })
      ));
    },
    [loadStatus]
  );

  return {
    data: {
      ...pick(responseData, [
        "currentPage",
        "currentItems",
        "allFetchedItems",
        "allOrderedIds",
        "currentOrderedIds",
        "totalItemsCount",
        "shownItemsCount",
        "offsetValue",
        "limitValue",
        "appliedFetchParams",
      ]),
    },
    loadStatus,
    handlePageChange,
  };
}

export default usePagination;
