import includes from "lodash/includes";
import keys from "lodash/keys";
import merge from "lodash/merge";
import pick from "lodash/pick";
import moment from "moment";
import constants from "../../../../util/constants";

import { string, object, boolean } from "../../../../util/yup";

export const buildInitialValues = (
  placement,
  candidate,
  organization,
  costCenters
) => {
  const {
    salaryCurrency,
    salaryCents,
    workplaceCountryCode,
    workplaceLocale,
    hireStartDate,
    workplacePostalCode,
    invoiceMemo,
    costCenterId,
  } = placement.attributes;

  const orgInvoiceMemoRequired = organization.invoiceMemoRequired;
  let costCenterInvoiceMemoRequired = false;

  const costCenterKeys = keys(costCenters);
  if (costCenterKeys.length === 1) {
    const defaultCostCenter = costCenters[costCenterKeys[0]];
    if (defaultCostCenter.attributes.invoiceMemoRequired) {
      costCenterInvoiceMemoRequired = true;
    }
  }

  const noSubUnit = constants.currency_options[salaryCurrency]?.decimal === 0;

  return {
    currency: salaryCurrency,
    salary: (salaryCents / (noSubUnit ? 1 : 100.0)).toString(),
    workplace_country_code: workplaceCountryCode,
    workplace_locale: workplaceLocale,
    hire_start_date: hireStartDate,
    workplace_postal_code: workplacePostalCode,
    invoice_memo: invoiceMemo || "",
    cost_center_id: costCenterId,
    fee_option_id: candidate.attributes.feeOption.id,
    invoice_memo_required:
      orgInvoiceMemoRequired || costCenterInvoiceMemoRequired,
  };
};

export const validationSchema = object().shape({
  currency: string().required("Cannot be blank").nullable(),
  salary: string()
    .required("Cannot be blank")
    .test(
      "salary-validation",
      "Must be at greater than 999",
      (value) => parseFloat(value?.replace(/,/g, "")) > 999
    ),
  workplace_country_code: string().required("Cannot be blank"),
  workplace_locale: string()
    .nullable()
    .when("workplace_country_code", {
      is: "USA",
      then: string().required("Cannot be blank."),
    }),
  hire_start_date: string().required("Cannot be blank"),
  workplace_postal_code: string()
    .nullable()
    .when("workplace_country_code", {
      is: "USA",
      then: string().required("Cannot be blank.").min(5).max(5),
    }),
  invoice_memo: string().when("invoice_memo_required", {
    is: true,
    then: string()
      .max(140)
      .nullable()
      .required("Required by your organization admin"),
    otherwise: string().max(140),
  }),
  fixed_fee: boolean(),
  ats_invite: boolean(),
  fee_option: string().when("fixed_fee", {
    is: false,
    then: string().required("Cannot be blank"),
  }),
  cost_center_id: string().required("Cannot be blank"),
});

export const handleSubmit =
  (
    dispatchFetchOpenTransaction,
    dispatchUpdatePlacement,
    dispatchClearModal,
    candidate
  ) =>
  (values, { setSubmitting, resetForm }) => {
    const submissionData = pick(values, [
      "currency",
      "hire_start_date",
      "invoice_memo",
      "workplace_country_code",
      "cost_center_id",
      "fee_option_id",
    ]);

    if (values.workplace_country_code === "USA") {
      merge(
        submissionData,
        pick(values, ["workplace_locale", "workplace_postal_code"])
      );
    }

    submissionData.salary = parseInt(values.salary.replace(/,/g, ""));
    submissionData.hire_start_date = moment
      .utc(values.hire_start_date)
      .toISOString();

    dispatchUpdatePlacement(submissionData)
      .then(() => {
        setSubmitting(false);
        dispatchClearModal();
        dispatchFetchOpenTransaction();
      })
      .then(() => {
        resetForm();
      })
      .catch(() => {
        setSubmitting(false);
      });
  };
