import TextField from "@material-ui/core/TextField";
import React from "react";

import EditableNumberedList from "../../../../../../../forms/formik/EditableNumberedList";

function Emails(props) {
  return (
    <EditableNumberedList
      {...props}
      title="Send To"
      showNumber={false}
      listName="emails"
      minItems={1}
      itemStyle={{ width: 480 }}
      inputField={<TextField label="" />}
    />
  );
}

export default Emails;
