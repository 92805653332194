import { withStyles } from "@material-ui/core/styles";
import capitalize from "lodash/capitalize";
import merge from "lodash/merge";
import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchCandidate } from "../../../../actions/employers/candidateActions";
import { getCandidate } from "../../../../selectors/candidateSelectors";

import CandidateChip from "../../../General/CandidateChip";
import LoadingPage from "../../../General/LoadingPage";
import sharedStyles from "../../../shared/NotificationsDrawer/NotificationViewTab/sharedStyles";

import WithdrawnHistoryTab from "./WithdrawnHistoryTab";

const styles = () => merge(sharedStyles, {
  participantProfile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginRight: "12px",
  },
  chipHeader: {
    color: "#90A4AE",
    marginBottom: "5px",
  },
});

class WithdrawnCandidate extends Component {
  componentDidMount() {
    this.props.fetchCandidate();
  }

  componentDidUpdate() {
    if (!this.props.candidate) {
      this.props.fetchCandidate();
    }
  }

  render() {
    const { classes, candidate } = this.props;
    if (candidate) {
      const {
        firstName, lastName, avatar, status
      } = candidate.attributes;
      const fullName = `${firstName} ${lastName}`;
      return (
        <>
          <div className={classes.subheaderContainer}>
            <h5>Candidate History</h5>
            <div className={classes.participantProfile}>
              <div className={`overline ${classes.chipHeader}`}>About candidate</div>
              <CandidateChip
                name={fullName}
                stage={capitalize(status)}
                individual={{
                  avatar,
                  firstName,
                  lastName
                }}
                info="Withdrawn"
                type="candidate"
              />
            </div>
          </div>
          <WithdrawnHistoryTab candidate={candidate} />
        </>
      );
    }
    return <LoadingPage />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  candidate: getCandidate(state, ownProps.candidateId)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchCandidate: () => dispatch(fetchCandidate(ownProps.candidateId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(WithdrawnCandidate));
