import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  sectionHeaderContainer: {
    borderBottom: "1px solid #B0BEC5",
    marginBottom: 12,
    paddingBottom: 5,
    paddingLeft: 12,
    marginTop: 12,
  },
  sectionHeader: {
    color: "#90A4AE",
  },
  sectionContainer: {
    padding: 12,
  },
}));

function Section({ title, children }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.sectionHeaderContainer}>
        <h5 className={classes.sectionHeader}>{title}</h5>
      </div>
      <div className={classes.sectionContainer}>{children}</div>
    </>
  );
}

export default Section;
