import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

import {
  RECEIVE_INSURANCE_REQUIREMENT_AGREEMENTS,
  RECEIVE_INSURANCE_REQUIREMENT_AGREEMENT,
} from "../actions/recruiters/insuranceRequirementAgreementActions";

const _defaultState = {
  items: {},
  loaded: false,
};

const insuranceRequirementAgreementReducer = (state = _defaultState, action) => {
  Object.freeze(state);
  let newItems;

  switch (action.type) {
    case RECEIVE_INSURANCE_REQUIREMENT_AGREEMENT:
      newItems = cloneDeep(state.items);
      newItems[action.payload.id] = merge({}, action.payload, { loaded: true });
      return { ...state, items: newItems };
    case RECEIVE_INSURANCE_REQUIREMENT_AGREEMENTS:
      return { items: action.payload, loaded: true };
    default:
      return state;
  }
};

export default insuranceRequirementAgreementReducer;
