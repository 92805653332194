import includes from "lodash/includes";
import React from "react";
import { connect } from "react-redux";

import { getCurrentIndividual } from "../../../selectors/individualSelectors";
import CandidateActionMenu, {
  DisabledCandidateActionMenu,
} from "../../employer/JobCasts/view/Candidates/actions/CandidateActionMenu";
import ConvertContractButton from "../../employer/JobCasts/view/Candidates/actions/ConvertContractButton";
import KeepButton from "../../employer/JobCasts/view/Candidates/actions/KeepButton";
import AdvanceButton from "../../employer/JobCasts/view/Candidates/actions/modalMountCTAs/AdvanceButton";
import RejectButton from "../../employer/JobCasts/view/Candidates/actions/modalMountCTAs/RejectButton";
import RestoreButton from "../../employer/JobCasts/view/Candidates/actions/modalMountCTAs/RestoreButton";
import RatingSubmissionForm from "../../employer/JobCasts/view/Candidates/actions/RatingSubmissionForm/RatingSubmissionForm";
import { MakeOfferButton } from "../../employer/TempJobCasts/view/Candidates/index/item/CandidateCardType/InterviewingCard";
import DeleteCandidateButton from "../../recruiter/Candidates/actions/DeleteCandidateButton";
import HireDetailsButton from "../../recruiter/Candidates/actions/modalMountCTAs/HireDetailsButton";
import SubmitHoursButton from "../../recruiter/Candidates/actions/modalMountCTAs/SubmitHoursButton";
import WithdrawButton from "../../recruiter/Candidates/actions/modalMountCTAs/WithdrawButton";
import RemindButton from "../../recruiter/Candidates/actions/RemindButton";
import OpenPreAssignmentModalCTA from "../Candidates/PreAssignmentModal/OpenPreAssignmentModalCTA";

import ContractCandidateActionMenu, { EditContractActionMenu } from "./ContractCandidateActionMenu";

function CTAList({ buttons, candidate }) {
  const buttonElements = buttons.map((buttonEl, idx) =>
    React.createElement(buttonEl, {
      key: `${buttonEl.displayName}-${candidate.id}`,
      candidate,
      drawerBtn: true,
      overrideStyle: idx ? { marginLeft: 16 } : {},
      displayTime: true,
    }));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {buttonElements}
    </div>
  );
}

function recruiterButtons(candidate) {
  switch (candidate.attributes.status) {
    case "unconfirmed":
      return [DeleteCandidateButton, RemindButton];
    case "contract_active":
      return candidate.attributes.contractEndedAt ?
        [SubmitHoursButton] :
        [SubmitHoursButton, ContractCandidateActionMenu];
    case "contract_ended":
      return [];
    case "hired":
    case "hired_failed":
      return [HireDetailsButton];
    case "rejected":
    case "withdrawn":
      return [];
    case "offer":
      return [WithdrawButton, OpenPreAssignmentModalCTA];
    case "new":
    case "awaiting_sort":
    case "reviewing":
    case "interviewing":
    default:
      return [WithdrawButton];
  }
}

const EmptyMenuContainer = () => <div style={{ width: 60, height: 48 }} />;

function employerButtons(candidate) {
  const { status, offPlatform, contractEndedAt } = candidate.attributes;

  switch (status) {
    case "new":
      return [RejectButton, KeepButton, EmptyMenuContainer];
    case "offer": {
      const offerButtons = [OpenPreAssignmentModalCTA];
      const actionMenu = offPlatform ? EditContractActionMenu : CandidateActionMenu;

      return [...offerButtons, RejectButton, actionMenu];
    }
    case "rejected":
    case "withdrawn":
      return [RestoreButton, DisabledCandidateActionMenu];
    case "interviewing":
      return [RejectButton, MakeOfferButton, CandidateActionMenu];
    case "contract_active":
      return contractEndedAt ? [ConvertContractButton] : [ContractCandidateActionMenu];
    case "contract_ended":
      return [];
    case "reviewing":
    default:
      return [RejectButton, AdvanceButton, CandidateActionMenu];
  }
}

function EmployerButtonContainer({ candidate }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <CTAList buttons={employerButtons(candidate)} candidate={candidate} />
      {!includes(
        ["contract_active", "contract_ended"],
        candidate.attributes.status
      ) && <RatingSubmissionForm candidate={candidate} />}
    </div>
  );
}

function RecruiterButtonContainer({ candidate }) {
  return (
    <CTAList buttons={recruiterButtons(candidate)} candidate={candidate} />
  );
}

function HeaderButtonContainer({ isEmployer, candidate }) {
  return isEmployer ? (
    <EmployerButtonContainer candidate={candidate} />
  ) : (
    <RecruiterButtonContainer candidate={candidate} />
  );
}

const mapStateToProps = (state) => ({
  isEmployer: getCurrentIndividual(state).employer,
});

export default connect(mapStateToProps, null)(HeaderButtonContainer);
