import { combineReducers } from "redux";
import { createResponsiveStateReducer } from "redux-responsive";

import { animationsReducer } from "./animationsReducer";
import { headerMenuReducer } from "./headerMenuReducer";
import { leftDrawerReducer } from "./leftDrawerReducer";
import { modalReducer } from "./modalReducer";
import { profileDrawerReducer } from "./profileDrawerReducer";
import { publicReducer } from "./publicReducer";
import { subNavReducer } from "./subNavReducer";
import { viewingAsBannerReducer } from "./viewingAsBannerReducer";

export default combineReducers({
  leftDrawer: leftDrawerReducer,
  modal: modalReducer,
  profileDrawer: profileDrawerReducer,
  headerMenu: headerMenuReducer,
  public: publicReducer,
  subNav: subNavReducer,
  animations: animationsReducer,
  viewingAsBanner: viewingAsBannerReducer,
  browser: createResponsiveStateReducer(
    {
      extraSmall: 479,
      small: 767,
      medium: 991,
      large: 1199,
      extraLarge: 1280,
    },
    {
      extraFields: () => ({
        width: window.innerWidth,
      }),
    }
  ),
});
