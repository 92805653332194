import merge from "lodash/merge";

import {
  RECEIVE_PLACEMENTS,
  SET_PLACEMENTS_LOADED,
} from "../../actions/shared/financeActions";

const _defaultState = { items: {}, loaded: false };

const placementReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PLACEMENTS:
      return merge({}, state, { items: action.payload });
    case SET_PLACEMENTS_LOADED:
      return merge({}, state, { loaded: action.payload });
    default:
      return state;
  }
};

export default placementReducer;
