import { withStyles } from "@material-ui/core/styles";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import moment from "moment";
import React from "react";

import NotificationItem from "./NotificationItem";

const styles = () => ({
  container: {
    backgroundColor: "#f5f5f5",
    height: "calc(100% - 103px)",
    overflowY: "auto"
  }
});

function NotificationsList({
  classes,
  notifications,
  setSelectedNotification,
  selectedNotification,
  overrideStyle
}) {
  const sortedNotifications = orderBy(notifications, notification => moment().diff(notification.attributes.createdAt));

  return (
    <div className={classes.container} style={overrideStyle || {}}>
      {map(sortedNotifications, notification => (
        <NotificationItem
          key={notification.id}
          notificationId={notification.id}
          isSelected={Boolean(
            selectedNotification && selectedNotification.id === notification.id
          )}
          setSelectedNotification={setSelectedNotification}
        />
      ))}
    </div>
  );
}

export default withStyles(styles)(NotificationsList);
