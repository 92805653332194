import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, {
  useRef, useCallback, useState, useMemo
} from "react";

import { GREATER_THAN_VIEWING_AS_Z_INDEX } from "../../util/namedConstants";

const StyledSnackbarContent = withStyles({
  message: {
    margin: "auto",
  },
})(SnackbarContent);

const defaultOptions = {
  duration: 10000
};

export const SnackbarContext = React.createContext({
  snackbar: { showMessage: (message, action, handleAction, options = defaultOptions) => {} },
});

function GlobalSnackbarProvider({ children, style }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [action, setAction] = useState(null);
  const [options, setOptions] = useState(defaultOptions);
  const handleAction = useRef(() => {});

  const value = useMemo(() => {
    const showMessage = (m = "", a = null, hA = () => {}, o = {}) => {
      setMessage(m);
      setAction(a);
      setOptions({ ...defaultOptions, ...o });
      handleAction.current = hA;
      setOpen(true);
    };
    return ({ snackbar: { showMessage } });
  }, []);

  const handleClose = useCallback(() => {
    handleAction.current = () => {};
    setOpen(false);
  }, []);

  const handleActionClick = useCallback(() => {
    handleAction.current();
    handleClose();
  }, [handleClose]);

  return (
    <div style={{ width: "inherit", height: "inherit", ...style }}>
      <SnackbarContext.Provider
        value={value}
      >
        {children}
        <Snackbar
          style={{ bottom: 20, zIndex: GREATER_THAN_VIEWING_AS_Z_INDEX }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          autoHideDuration={options.duration}
          onClose={handleClose}
        >
          <StyledSnackbarContent
            style={{ margin: "auto" }}
            message={(
              <div
                id="message-id"
                style={{ textAlign: "center" }}
                data-cy="snackbar-message"
              >
                {message}
              </div>
              )}
            action={
                (action != null) && (
                  <span
                    style={{ cursor: "pointer", color: "#00c0ee" }}
                    onClick={handleActionClick}
                  >
                    {action}
                  </span>
                )
              }
          />
        </Snackbar>
      </SnackbarContext.Provider>
    </div>
  );
}

GlobalSnackbarProvider.defaultProps = {
  style: {}
};

GlobalSnackbarProvider.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node.isRequired,
};

export default GlobalSnackbarProvider;
