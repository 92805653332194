import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import { Row, Col } from "react-grid-system";

import FieldSection from "./FieldSection";
import SectionHeader from "./SectionHeader";

function JobCastStatus({
  values, errors, setFieldValue, submitCount
}) {
  return (
    <>
      <SectionHeader
        title="JobCast Status"
        titleStyle={{ paddingTop: 10, paddingLeft: 24 }}
        lineStyle={{ width: "100%" }}
      />
      <FieldSection>
        <Row>
          <Col>
            <RadioGroup style={{ padding: "0 0 16px 0px" }}>
              <FormControlLabel
                control={(
                  <Radio
                    color="primary"
                    checked={values.close_after_hire === "false"}
                  />
                )}
                label="I would like to receive additional submissions."
                id="close_after_hire-opt-1"
                onChange={() => setFieldValue("close_after_hire", "false")}
                value="false"
              />
              <FormControlLabel
                control={(
                  <Radio
                    color="primary"
                    checked={values.close_after_hire === "true"}
                  />
                )}
                label="I would like to close this JobCast, reject all other candidates, and alert my agencies."
                onChange={() => setFieldValue("close_after_hire", "true")}
                id="close_after_hire-opt-2"
                value="true"
              />
              {Boolean(submitCount) && errors.close_after_hire && (
                <FormHelperText className="helper-text error">
                  {errors.close_after_hire}
                </FormHelperText>
              )}
            </RadioGroup>
          </Col>
        </Row>
      </FieldSection>
    </>
  );
}

export default JobCastStatus;
