import Star from "@material-ui/icons/Star";
import { withFormik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";

import { restoreCandidate } from "../../../../../../../actions/employers/candidateActions";
import {
  clearModal,
  clearProfileDrawer,
} from "../../../../../../../actions/shared/uiActions";
import { getCurrentIndividual } from "../../../../../../../selectors/individualSelectors";
import { getJobCast } from "../../../../../../../selectors/jobcastSelectors";
import { getCurrentOrganization } from "../../../../../../../selectors/organizationSelectors";
import { buildFullName } from "../../../../../../../util/formatHelpers";
import { string, object } from "../../../../../../../util/yup";

import MultiLineTextField from "../../../../../../forms/custom/MultiLineTextField";
import ActionModalContainer from "../../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../../../General/ActionModal/ActionModalHeader";

import withSnackbar from "../../../../../../General/withSnackbar";

import { shouldInviteToATS } from "../constants";

class RestoreModal extends Component {
  getCandidateName() {
    const { candidate } = this.props;
    return `${candidate.attributes.firstName} ${candidate.attributes.lastName}`;
  }

  renderContent() {
    const {
      candidate: {
        attributes: { firstName, lastName, rejectedFrom },
      },
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
    } = this.props;

    const rejectedFromNewText =
      " If the candidate was rejected from NEW they will be moved to the REVIEWING stage.";

    return (
      <>
        <p style={{ margin: "0 0 24px 0" }}>
          {`You are moving this candidate back to the stage they were rejected or withdrawn from.${
            rejectedFrom === "New" ? rejectedFromNewText : ""
          } The agency will be notified.`}
        </p>
        <MultiLineTextField
          error={Boolean(errors.nextSteps && touched.nextSteps)}
          helperText={touched.nextSteps && errors.nextSteps}
          label={`What are the next steps with ${buildFullName(
            firstName,
            lastName
          )}?`}
          onChange={handleChange}
          value={values.nextSteps}
          id="nextSteps"
          rows={3}
          placeholder="After reconsidering the candidate, we would like to bring them back in for another interview."
          onBlur={handleBlur}
        />
      </>
    );
  }

  renderFeedback() {
    const { feedbackRating } = this.props;

    if (!feedbackRating) {
      return <></>;
    }
    return (
      <div
        className="subtitle-2"
        style={{ display: "flex", alignItems: "center" }}
      >
        Your Responsiveness Rating
        <a
          href="/app/reports/my_ratings"
          style={{
            display: "flex",
            marginLeft: 8,
            textDecoration: "underline",
          }}
        >
          <Star />
          {feedbackRating}
        </a>
      </div>
    );
  }

  render() {
    const { handleSubmit, isSubmitting } = this.props;

    return (
      <ActionModalContainer color="blue">
        <ActionModalHeader
          title="Restore Candidate"
          subtitle={this.getCandidateName()}
        />
        <ActionModalContent>{this.renderContent()}</ActionModalContent>
        <ActionModalFooter
          actionText="Restore"
          handleAction={handleSubmit}
          isSubmitting={isSubmitting}
        >
          {this.renderFeedback()}
        </ActionModalFooter>
      </ActionModalContainer>
    );
  }
}

const RestoreModalForm = withFormik({
  handleSubmit: (values, { props, setSubmitting }) => {
    const {
      candidate,
      restoreCandidate,
      clearModal,
      clearProfileDrawer,
      snackbar,
      organization,
      jobcast,
    } = props;

    const showAtsInvite = () => {
      const { sourceTrackingCode, atsSetup, candidateStage } = organization;
      const { atsApplicationUrl } = jobcast.attributes;
      const rejectedFrom = candidate.attributes.rejectedFrom.toLowerCase();

      return (
        rejectedFrom == "new" && // only send ats if specifically if rejected from new
        atsSetup &&
        Boolean(sourceTrackingCode) &&
        Boolean(atsApplicationUrl) &&
        Boolean(shouldInviteToATS[rejectedFrom]) &&
        shouldInviteToATS[rejectedFrom][candidateStage]
      );
    };

    restoreCandidate({
      candidateId: candidate.id,
      next_steps: values.nextSteps,
      ats_invite: showAtsInvite(),
    })
      .then(
        ({
          payload: {
            attributes: { stageEvents },
          },
        }) => {
          setSubmitting(false);

          // Try to use the stageEvents formatted event status text, otherwise use the candidate's previous rejectedFrom
          let action =
            stageEvents[stageEvents.length - 1]?.attributes?.formattedEvent ??
            `restored to ${candidate.attributes.rejectedFrom}`;
          // Update the language to fit the specified wording
          action = action.replace(/restored to/i, "restored to the");
          return snackbar.showMessage(
            `The candidate has been ${action} stage.`
          );
        }
      )
      .then(clearModal)
      .then(clearProfileDrawer)
      .catch(() => {});
  },
  mapPropsToValues: () => ({ nextSteps: "" }),
  validationSchema: object().shape({
    nextSteps: string().required("Cannot be blank").minWords(3),
  }),
})(RestoreModal);

const mapStateToProps = (state, ownProps) => {
  const currentIndividual = getCurrentIndividual(state);
  const organization = getCurrentOrganization(state);
  const jobcast = getJobCast(state, ownProps.candidate.attributes.jobcastId);

  return {
    organization,
    jobcast,
    feedbackRating: currentIndividual.aggregateRatings.jobcastRatings
      ?.numReviews
      ? currentIndividual.aggregateRatings.jobcastRatings.RESPONSIVENESS?.average?.toFixed(
          2
        )
      : null,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearModal: () => dispatch(clearModal()),
  clearProfileDrawer: () => dispatch(clearProfileDrawer()),
  restoreCandidate: (data) => dispatch(restoreCandidate(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar()(RestoreModalForm));
