import { TableCell, TableRow } from "@material-ui/core";
import { useField } from "formik";
import React from "react";
import { useSelector } from "react-redux";

import { getCurrentIndividual } from "../../../../../selectors/individualSelectors";
import Button from "../../../../forms/custom/Button";
import {
  EITHER_PARTY, BOTH_PARTIES, AGENCY, EMPLOYER
} from "../fieldHelpers";

import ConfirmationCellContent from "./ConfirmationCellContent";
import NoActionNeeded from "./NoActionNeeded";
import PreAssignmentDescriptionPopover from "./PreAssignmentDescriptionPopover";

function EditableCellContent({ fieldId }) {
  const [{ value }] = useField(fieldId);
  const [, , { setValue, setTouched }] = useField(`${fieldId}.basicConfirmed`);

  const handleConfirmationSelection = (isConfirmed) => {
    setValue(isConfirmed);
    setTouched(true);
  };

  return value.basicConfirmed ? (
    <Button
      color="red"
      variant="secondary"
      onClick={() => handleConfirmationSelection(false)}
    >
      Unconfirm
    </Button>
  ) : (
    <Button
      color="blue"
      variant="secondary"
      onClick={() => handleConfirmationSelection(true)}
    >
      Confirm
    </Button>
  );
}

function StaticCellContent({
  checklistItem: {
    employerConfirmedAt, agencyConfirmedAt, responsibility
  }
}) {
  const currentIndividual = useSelector(getCurrentIndividual);
  const agencyConfirmed = Boolean(agencyConfirmedAt);
  const employerConfirmed = Boolean(employerConfirmedAt);

  switch (responsibility) {
    case BOTH_PARTIES:
      if (employerConfirmed && agencyConfirmed) return 'Confirmed';
      if (currentIndividual.employer && employerConfirmed) return 'Pending Staffing Firm';
      if (currentIndividual.recruiter && agencyConfirmed) return 'Pending Company';
      return 'Unconfirmed';
    case EITHER_PARTY:
      if (agencyConfirmed || employerConfirmed) return 'Confirmed';
      break;
    case AGENCY:
      if (agencyConfirmed) return 'Confirmed';
      break;
    case EMPLOYER:
      if (employerConfirmed) return 'Confirmed';
      break;
    default:
      return "N/A";
  }

  return 'Unconfirmed';
}

function BasicConfirmationFieldRow({ checklistItem, editable, fieldId }) {
  return (
    <TableRow data-cy="checklist-item-confirmation-row">
      <TableCell>
        <div className="bold">
          {checklistItem.name}
        </div>
        <PreAssignmentDescriptionPopover {...checklistItem} />
      </TableCell>
      <TableCell align="center">
        <NoActionNeeded {...{
          checklistItem,
          editable,
          Editing: <EditableCellContent {...{ fieldId }} />,
          Viewing: <StaticCellContent {...{ checklistItem }} />
        }}
        />
      </TableCell>
      <TableCell>
        <ConfirmationCellContent {...{ checklistItem }} />
      </TableCell>
    </TableRow>
  );
}

export default BasicConfirmationFieldRow;
