import React, { useContext } from "react";

import { SnackbarContext } from "./GlobalSnackbarProvider";

export default function withSnackbar() {
  return Component => {
    function ComponentWithSnackbar(props) {
      const context = useContext(SnackbarContext);

      return <Component snackbar={context.snackbar} {...props} />;
    }

    return ComponentWithSnackbar;
  };
}
