import { Formik } from "formik";
import React, { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchAgency } from "../../../../../../../../actions/employers/agencyActions";
import { fetchCandidate } from "../../../../../../../../actions/employers/candidateActions";
import { createAgencyExperienceReview } from "../../../../../../../../actions/employers/reviewActions";
import { clearModal } from "../../../../../../../../actions/shared/uiActions";
import { makeGetAgencyRecruiter } from "../../../../../../../../selectors/employer/agencySelectors";
import { makeGetCandidate } from "../../../../../../../../selectors/recruiter/candidateSelectors";
import { string, object, number } from "../../../../../../../../util/yup";

import ActionModalContent from "../../../../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../../../../General/ActionModal/ActionModalFooter";
import LoadingPage from "../../../../../../../General/LoadingPage";

import withSnackbar from "../../../../../../../General/withSnackbar";

import FooterButtons from "./FooterButtons";
import HireProcessed from "./HireProcessed";
import RateRecruiter from "./RateRecruiter";
import RejectionProcessed from "./RejectionProcessed";

const HIRED = "hired";
const REJECTED = "rejected";

const TYPED_CONTENT = {
  [HIRED]: {
    modalColor: "blue",
    modalTitle: "Hire Candidate",
    ContentIntroComponent: HireProcessed,
  },
  [REJECTED]: {
    modalColor: "red",
    modalTitle: "Reject Candidate",
    ContentIntroComponent: RejectionProcessed,
  },
};

function useCandidateFetch(candidateId) {
  const dispatch = useDispatch();
  const getCandidate = useMemo(makeGetCandidate, []);
  const candidate = useSelector((state) => getCandidate(state, candidateId));

  useEffect(() => {
    if (candidateId && !candidate) dispatch(fetchCandidate(candidateId));
  }, [candidate, dispatch, candidateId]);

  return candidate;
}

function useRecruiterFetch(recruiterId) {
  const dispatch = useDispatch();

  const getAgencyRecruiter = useMemo(makeGetAgencyRecruiter, []);

  const recruiter = useSelector(
    (state) => getAgencyRecruiter(state, recruiterId),
    [recruiterId]
  );

  useEffect(() => {
    if (recruiterId) dispatch(fetchAgency(recruiterId));
  }, [recruiterId, dispatch]);

  const recruiterLoaded = Boolean(
    recruiter && recruiter.individual && recruiter.individual.id
  );

  return { recruiter, recruiterLoaded };
}

function RateRecruiterModalContent({
  candidateId,
  renderCustomIntroComponent,
  successMessage,
  snackbar,
}) {
  const dispatch = useDispatch();

  const candidate = useCandidateFetch(candidateId);

  const recruiterId = Boolean(candidate)
    ? candidate.attributes.recruiterId
    : null;

  const { recruiter, recruiterLoaded } = useRecruiterFetch(recruiterId);

  if (!(candidate && recruiterLoaded)) return <LoadingPage />;

  const {
    requestId,
    status,
    employerAgencyRating,
    employerAgencyRatingVisbility,
    employerAgencyReview,
  } = candidate.attributes;

  const handleSubmit = (values, { setSubmitting }) => {
    const submitData = {
      note: values.recruiterReview,
      subject_id: requestId,
      ratings: [
        {
          characteristic: "RECRUITER-EXPERIENCE",
          rating: values.recruiterExperience,
        },
      ],
      visibility: !values.privateRecruiterRating ? "PUBLIC" : "PRIVATE",
    };

    dispatch(createAgencyExperienceReview(submitData))
      .then(() => {
        dispatch(clearModal());
        snackbar.showMessage(successMessage ?? "Review successfully submitted");
      })
      .catch(() => setSubmitting(false));
  };

  const handleClose = () => {
    dispatch(clearModal());
    if (successMessage) snackbar.showMessage(successMessage);
  };

  const reviewPreviouslySubmitted = Boolean(employerAgencyRating);

  return (
    <Formik
      validationSchema={object().shape({
        recruiterExperience: number().nullable().required("Cannot be blank"),
        recruiterReview: string()
          .nullable()
          .max(1000, "Must be less than 1000 characters"),
      })}
      initialValues={{
        recruiterExperience: employerAgencyRating,
        recruiterReview: employerAgencyReview,
        privateRecruiterRating: !!(
          employerAgencyRatingVisbility &&
          employerAgencyRatingVisbility === "PRIVATE"
        ),
      }}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <>
          <ActionModalContent style={{ paddingLeft: 32, paddingRight: 32 }}>
            <div>
              {renderCustomIntroComponent
                ? renderCustomIntroComponent()
                : React.createElement(
                  TYPED_CONTENT[status].ContentIntroComponent
                )}
              <div
                style={{
                  borderBottom: "1px solid #90A4AE",
                  margin: "20px 2px",
                }}
              />
              <RateRecruiter {...{ recruiter, reviewPreviouslySubmitted }} />
            </div>
          </ActionModalContent>
          <ActionModalFooter
            customButtons={
              <FooterButtons handleCancel={handleClose} />
            }
            isSubmitting={isSubmitting}
          />
        </>
      )}
    </Formik>
  );
}

export default withSnackbar()(RateRecruiterModalContent);
