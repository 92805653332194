import get from "lodash/get";
import PropTypes from "prop-types";
import React, { memo } from "react";

import DropdownInput from "../../../../forms/custom/DropdownInput.react";

import { withFormContext } from "../../../../forms/formik/FormContext";

import shouldSkipRerender from "./shouldSkipRerender";

function FormContextDropdown({
  formContext: {
    values, errors, touched, handleChange, handleBlur
  },
  inputProps = {},
  ...passThroughProps
}) {
  const { id } = passThroughProps;
  const errorMessage = (get(touched, id) && get(errors, id)) || "";

  return (
    <DropdownInput
      value={get(values, id)}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      helperTextOverrideStyle={{ marginBottom: "-18px" }}
      inputProps={({
        "data-cy": `${id}-dropdown-input`,
        ...inputProps
      })}
      {...passThroughProps}
    />
  );
}

FormContextDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  formContext: PropTypes.shape({
    values: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired
  }).isRequired
};

export default withFormContext(memo(FormContextDropdown, shouldSkipRerender));
