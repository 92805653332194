export const TEAM = "team";
export const PERSONAL = "personal";

export const listTypeOptions = [
  { value: TEAM, label: "Team" },
  { value: PERSONAL, label: "Personal" },
];

export const EXPANSION_COL = "EXPANSION_COL";
export const LIST_COL = "LIST_COL";
export const COUNT_COL = "COUNT_COL";
export const ACTION_COL = "ACTION_COL";

export const COLUMNS = [EXPANSION_COL, LIST_COL, COUNT_COL, ACTION_COL];

export const EDIT_DESCRIPTION =
  "Update this agency list below by adding agencies to the “Selected Agencies” area. When you have added all of the agencies you want click “Save.”";
export const CREATE_DESCRIPTION =
  "Create an agency list below by adding agencies to the “New List Selection” area. When you have added all of the agencies you want to click “Save.”";
