import cloneDeep from "lodash/cloneDeep";
import keys from "lodash/keys";
import merge from "lodash/merge";

import {
  RECEIVE_REQUIRED_TEMP_AGENCY_AGREEMENTS,
  RECEIVE_REQUIRED_TEMP_AGENCY_AGREEMENT,
} from "../actions/employers/requiredTempAgencyAgreementActions";

const _defaultState = {
  items: {},
  loaded: false,
};

const requiredTempAgencyAgreementReducer = (state = _defaultState, action) => {
  Object.freeze(state);
  let newItems;

  switch (action.type) {
    case RECEIVE_REQUIRED_TEMP_AGENCY_AGREEMENT:
      newItems = cloneDeep(state.items);
      newItems[action.payload.id] = merge({}, action.payload, { loaded: true });
      return { ...state, items: newItems };
    case RECEIVE_REQUIRED_TEMP_AGENCY_AGREEMENTS:
      return merge({}, state, { items: action.payload, loaded: true });
    default:
      return state;
  }
};

export default requiredTempAgencyAgreementReducer;
