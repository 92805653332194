import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setNotificationsDrawerVisible } from "../../../actions/shared/uiActions";

import { TAB_1_WIDTH, TAB_2_WIDTH, TAB_3_WIDTH } from "./constants";

const styles = (theme) => ({
  drawerPaper: {
    position: "fixed",
    height: "100vh",
    borderRight: 0,
  },
  drawerPaperSingle: {
    width: TAB_1_WIDTH,
    [theme.breakpoints.down(`${TAB_1_WIDTH + 16}`)]: {
      width: "calc(100% - 16px)",
      height: "100%",
    },
  },
  drawerPaperDouble: {
    width: TAB_1_WIDTH + TAB_2_WIDTH,
    [theme.breakpoints.down(`${TAB_1_WIDTH + TAB_2_WIDTH + 16}`)]: {
      width: "calc(100% - 16px)",
      height: "100%",
    },
  },
  drawerPaperTriple: {
    width: TAB_1_WIDTH + TAB_2_WIDTH + TAB_3_WIDTH,
    [theme.breakpoints.down(
      `${TAB_1_WIDTH + TAB_2_WIDTH + TAB_3_WIDTH + 16}`
    )]: {
      width: "calc(100% - 16px)",
      height: "100%",
    },
  },
});

class NotificationsDrawer extends Component {
  state = { open: false };

  componentDidMount() {
    this.setState({
      open: true,
    });
  }

  handleClose = () => {
    const { setNotificationsDrawerNotVisible } = this.props;

    this.setState({ open: false }, () => {
      setTimeout(setNotificationsDrawerNotVisible, 1000);
    });
  };

  determineWidthClass() {
    const { classes, hasSelectedNotification } = this.props;

    if (hasSelectedNotification) {
      return classes.drawerPaperTriple;
    }
    return classes.drawerPaperDouble;
  }

  render() {
    const { classes, children } = this.props;

    return (
      <Drawer
        open={this.state.open}
        anchor="left"
        onClose={this.handleClose}
        classes={{
          paper: classnames(classes.drawerPaper, this.determineWidthClass()),
        }}
      >
        {children}
      </Drawer>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setNotificationsDrawerNotVisible: () => dispatch(setNotificationsDrawerVisible(false)),
});

export default withRouter(
  connect(null, mapDispatchToProps)(withStyles(styles)(NotificationsDrawer))
);
