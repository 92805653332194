import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setHeaderMenuScope } from "../../../../actions/shared/uiActions";

import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import { getCurrentOrganization } from "../../../../selectors/organizationSelectors";

import { PERM_SCOPE, TEMP_SCOPE } from "./constants";
import EmployerTabs from "./EmployerTabs";
import RecruiterTabs from "./RecruiterTabs";
import TabsScopeToggle from "./TabsScopeToggle";

function NavigationTabs() {
  const dispatch = useDispatch();
  const currentIndividual = useSelector(getCurrentIndividual);

  const currentOrganization = useSelector(getCurrentOrganization);

  const hasTempOption = currentOrganization.contractActivated;

  const scope = useSelector((state) => state.ui.headerMenu.scope);

  useEffect(() => {
    if (!hasTempOption && scope === TEMP_SCOPE) {
      dispatch(setHeaderMenuScope(PERM_SCOPE));
    }
  }, [hasTempOption, scope, dispatch]);

  const TabsList = currentIndividual.employer ? EmployerTabs : RecruiterTabs;

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {hasTempOption ? <TabsScopeToggle /> : <div style={{ width: 64 }} />}
      <TabsList scope={hasTempOption ? scope : PERM_SCOPE} />
    </div>
  );
}

export default NavigationTabs;
