import merge from "lodash/merge";

import { RECEIVE_RECRUITER_APPLICATION } from "../actions/recruiters/individualActions";
import { UPDATE_CURRENT_INDIVIDUAL } from "../actions/shared/individualActions";

const _defaultState = {};

export const recruiterApplicationReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_RECRUITER_APPLICATION:
      return merge({}, state, action.payload);
    case UPDATE_CURRENT_INDIVIDUAL:
      return merge({}, state, action.payload.recruiter_application);
    default:
      return state;
  }
};
