import classnames from "classnames";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

import { makeGetPlacementItem } from "../../../../../../selectors/recruiter/financeSelectors";
import {
  EXPECTED, FAILED, PROCESSED, SCHEDULED, STATUS_KEYS
} from "../../earnings/PayoutsTable/tableHelpers";

const formatDate = (date) => moment.utc(date).format("MMM D, YYYY");

const STATUS_EXPLANATION = {
  [EXPECTED]:
    "This is the date that your payout will be automatically processed assuming the criteria in the “Payout Status” section is completed.",
  [SCHEDULED]:
    "This is the date that your payout will be automatically processed.",
  [PROCESSED]:
    "Your payout has been processed and should be visible in your bank account within 1-3 business days.",
  [FAILED]:
    "This payout has been canceled as the candidate failed to complete the 60 day guarantee period.",
};

function HireStatus({ placementId }) {
  const getPlacement = makeGetPlacementItem();
  const { attributes } = useSelector(state => getPlacement(state, placementId));

  const { payoutStatus } = attributes;
  const { text, color, attribute } = STATUS_KEYS[payoutStatus];

  return (
    <div
      style={{
        borderBottom: "1px solid #B0BEC5",
        padding: "0px 12px 12px 12px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={classnames("bold")} style={{ marginBottom: 12 }}>
          {`Status: `}
          <span className={color}>
            {`Payout ${text}`}
          </span>
          {", "}
          {formatDate(attributes[attribute])}
        </div>
        <div>{STATUS_EXPLANATION[payoutStatus]}</div>
      </div>
    </div>
  );
}

export default HireStatus;
