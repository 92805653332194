import { GET } from "../../util/apiHelpers";

export const RECEIVE_RELEASE_TOKEN = "RECEIVE_RELEASE_TOKEN";

export const receiveReleaseToken = (token) => ({
  type: RECEIVE_RELEASE_TOKEN,
  payload: token,
});

export const fetchReleaseToken = () => (dispatch) => {
  return GET(`/api/v3/release_token`).then((res) => {
    dispatch(receiveReleaseToken(res.data.token));
  });
};
