import React from "react";

function SearchResultSectionHeader({ title, numResults }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: 40,
        borderTop: "1px solid #90A4AE",
        borderBottom: "1px solid #90A4AE",
        backgroundColor: "#eceff1",
      }}
    >
      <h5 style={{ paddingLeft: 24 }}>
        {title}
        {' '}
        (
        {numResults}
        )
      </h5>
    </div>
  );
}

export default SearchResultSectionHeader;
