import { GET } from "../../util/apiHelpers";

export const RECEIVE_INSURANCE_REQUIREMENTS = "RECEIVE_INSURANCE_REQUIREMENTS";
export const RECEIVE_INSURANCE_REQUIREMENT = "RECEIVE_INSURANCE_REQUIREMENT";

export const receiveInsuranceRequirements = (insuranceRequirements) => ({
  type: RECEIVE_INSURANCE_REQUIREMENTS,
  payload: insuranceRequirements,
});

export const receiveInsuranceRequirement = (insuranceRequirement) => ({
  type: RECEIVE_INSURANCE_REQUIREMENT,
  payload: insuranceRequirement,
});

export const getInsuranceRequirements = (state) => state.insuranceRequirements.items;

export const fetchInsuranceRequirements = () => (dispatch) => GET("/api/v3/insurance_requirements").then((res) => {
  const newItems = {};
  res.data.data.forEach((a) => { newItems[a.id] = a.attributes; });
  dispatch(receiveInsuranceRequirements(newItems));
});
