import merge from "lodash/merge";
import values from "lodash/values";

import {
  RECEIVE_CURRENT_INDIVIDUAL,
  RECEIVE_EMAIL_SETTINGS,
} from "../../actions/shared/individualActions";

const _defaultState = {
  notificationSettings: [],
  immediateNotifications: [],
};

export const emailSettingsReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_EMAIL_SETTINGS:
      return merge({}, state, action.payload);
    case RECEIVE_CURRENT_INDIVIDUAL:
      const individual = values(action.payload)[0];
      const { emailSettings } = individual.attributes;
      return merge({}, state, emailSettings);
    default:
      return state;
  }
};

export default emailSettingsReducer;
