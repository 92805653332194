export const RECEIVE_TEAMMATE_INVITATIONS = "RECEIVE_TEAMMATE_INVITATIONS";
export const REMOVE_TEAMMATE_INVITATION = "REMOVE_TEAMMATE_INVITATION";

export const receiveTeammateInvitations = (invitations) => ({
  type: RECEIVE_TEAMMATE_INVITATIONS,
  payload: invitations,
});

export const removeTeammateInvitation = (id) => ({
  type: REMOVE_TEAMMATE_INVITATION,
  payload: id,
});
