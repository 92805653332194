import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Row, Col } from "react-grid-system";

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 24,
    paddingBottom: 24,
  },
}));

function ActionModalContent({ children, style = {} }) {
  const classes = useStyles();
  return (
    <DialogContent classes={{ root: classes.root }} style={style}>
      <Row nogutter style={{ flexGrow: 1 }}>
        <Col className="modal-content-area">{children}</Col>
      </Row>
    </DialogContent>
  );
}

export default ActionModalContent;
