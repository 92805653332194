import React from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { getCurrentIndividual } from '../../selectors/individualSelectors';
import { GET } from "../../util/apiHelpers";

export const getUserData = () => GET(`/api/v3/intercom/`).then(res => res.data);

export const getAppId = () => GET(`/api/v3/intercom/identify`).then(res => res.data);

export class Intercom extends React.Component {
  state = { data: {} }

  async componentDidMount() { this.loadIntercom(); }

  async componentDidUpdate(prevProps, prevState) {
    const { location, individualLoaded } = this.props;
    const userLoggedIn = individualLoaded && !prevProps.individualLoaded;
    const userLoggedOut = !individualLoaded && prevState.data.user_id;

    if (userLoggedIn) {
      this.getIntercomUserData();
    } else if (userLoggedOut) {
      this.clearIntercomUserData(prevState);
    }

    if (location !== prevProps.location && window.Intercom) {
      window.Intercom('update', this.state.data);
    }
  }

  setIntercomToWindow(APP_ID) {
    const DOMAvailable = !!(
      (typeof window !== 'undefined' &&
      window.document && window.document.createElement)
    );

    if (DOMAvailable && !window.Intercom) {
      (function (window, document, id, script, arg) {
        function intercom() {
          intercom.call(arguments);
        }
        intercom.arr = [];
        intercom.call = function (args) {
          intercom.arr.push(args);
        };
        window.Intercom = intercom;
        script = document.createElement('script');
        script.async = 1;
        script.src = `https://widget.intercom.io/widget/${id}`;
        document.head.appendChild(script);
      }(window, document, APP_ID));
    }
  }

  async loadIntercom() {
    await getAppId().then(({ app_id }) => this.setIntercomToWindow(app_id));

    window.intercomSettings = this.state.data;
    if (window.Intercom) {
      window.Intercom('boot', this.state.data);
    }
  }

  async getIntercomUserData() {
    await getUserData().then(res => this.setState({ data: res }));

    window.intercomSettings = this.state.data;

    if (window.Intercom) {
      window.Intercom('shutdown');
      window.Intercom('boot', this.state.data);
    }
  }

  clearIntercomUserData(prevState) {
    this.setState({ data: { app_id: prevState.data.app_id } });
    window.intercomSettings = this.state.data;

    if (window.Intercom) {
      window.Intercom('shutdown');
      window.Intercom('boot', this.state.data);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  individualLoaded: Boolean(getCurrentIndividual(state))
});

export default withRouter(
  connect(mapStateToProps, null)(Intercom)
);
