import Icon from "@material-ui/core/Icon";
import AccountMultiple from "mdi-material-ui/AccountMultiple";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { logout } from "../../../../actions/shared/individualActions";
import { getFeatureFlags } from "../../../../selectors/featureFlagSelector";
import Menu from "../../../forms/custom/Menu";

import GlobalAvatar from "../../../General/GlobalAvatar";
import StarRatingLink from "../../../General/StarRatingLink";
import { TEMP_SCOPE } from "../NavigationTabs/constants";
import sum from "lodash/sum";

class UserSectionMenu extends React.Component {
  static renderCaret() {
    return (
      <Icon style={{ color: "#263238", fontSize: "12px", marginLeft: "8px" }}>
        expand_more
      </Icon>
    );
  }

  state = {
    anchorEl: null,
  };

  handleLink = ({ title, base, custom_path }) => {
    if (custom_path) {
      this.props.history.push(`/${custom_path}`);
    } else {
      const formattedPath = title.toLowerCase().replace(/ /g, "_");
      const formattedBase = base ? `${base}/` : "";
      this.props.history.push(`/${formattedBase}${formattedPath}`);
    }
  };

  handleClick = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    this.handleClose();
    this.props.logout();
  };

  handleSelection = (item) => {
    this.setState({ anchorEl: null }, () => {
      item.custom_action ? item.custom_action() : this.handleLink(item);
    });
  };

  renderMenu() {
    const { anchorEl } = this.state;
    const { activated, individual, headerMenuScope } = this.props;
    const isTalkOnly =
      individual?.agreedToTalkTerms && !individual?.agreedToRfiTerms;

    const { type, firstName, lastName, aggregateRatings } = individual;
    const isEmployer = type === "employer";
    const isRecruiter = type === "recruiter";

    const {
      feedbackRatings,
      jobcastRatings,
      employerReviewRatings,
      candidateReviewRatings,
    } = aggregateRatings;

    const calculateRating = () => {
      const typedRatings = [];
      if (isEmployer) {
        if (feedbackRatings?.numRatings)
          typedRatings.push(feedbackRatings.average);

        if (jobcastRatings?.numRatings)
          typedRatings.push(jobcastRatings.average);
      } else if (isRecruiter) {
        if (employerReviewRatings?.numRatings)
          typedRatings.push(employerReviewRatings.average);

        if (candidateReviewRatings?.numRatings)
          typedRatings.push(candidateReviewRatings.average);
      }

      if (!typedRatings.length) return 0;

      return sum(typedRatings) / typedRatings.length;
    };

    const LOGOUT_ITEM = {
      title: "Logout",
      isVisible: true,
      overrideStyles: { fontWeight: "bold" },
      custom_action: () => this.props.logout(),
    };

    const HELP_ITEM = {
      title: "Help",
      isVisible: true,
      icon: "help_outline",
      custom_action: () =>
        window.open(
          isRecruiter
            ? "https://help.recruitifi.com/en/collections/89319-agency-faqs"
            : "https://help.recruitifi.com/en/collections/89305-employer-faqs",
          "_blank"
        ),
    };

    const items = {
      ACTIVATED_INDIVIDUAL_ITEMS: [
        {
          avatar: <GlobalAvatar size="extra large" individual={individual} />,
          isVisible: true,
          name: `${firstName} ${lastName}`,
          starRating: (
            <StarRatingLink
              rating={calculateRating()}
              individual={individual}
            />
          ),
        },
        { divider: true, isVisible: true },
        {
          title: "Settings",
          custom_path: `${
            isRecruiter ? "settings/account" : "settings/profile"
          }`,
          isVisible: true,
          icon: "settings",
        },
        {
          title: "Billing",
          isVisible: isEmployer,
          custom_path: "billing/open_transactions",
          icon: "credit_card",
        },
        {
          title: "Payouts",
          isVisible: isRecruiter,
          custom_path:
            headerMenuScope === TEMP_SCOPE
              ? "contract_payouts/upcoming"
              : "payouts/upcoming",
          icon: "money_outlined",
        },
        { title: "Team", isVisible: true, icon: <AccountMultiple /> },
        { ...HELP_ITEM },
        { divider: true, isVisible: true },
        { ...LOGOUT_ITEM },
      ],
      ACTIVATING_INDIVIDUAL_ITEMS: [
        {
          avatar: <GlobalAvatar size="extra large" individual={individual} />,
          isVisible: true,
          name: `${firstName} ${lastName}`,
        },
        { ...HELP_ITEM },
        { divider: true, isVisible: true },
        { ...LOGOUT_ITEM },
      ],
      TALK_ONLY_INDIVIDUAL_ITEMS: [
        {
          avatar: <GlobalAvatar size="extra large" individual={individual} />,
          isVisible: true,
          name: `${firstName} ${lastName}`,
        },
        { divider: true, isVisible: true },
        {
          title: "Settings",
          custom_path: `${
            this.props?.featureFlags?.includes("profile_specializations") &&
            isRecruiter
              ? "settings/account"
              : "settings/profile"
          }`,
          isVisible: true,
          icon: "settings",
        },
        { ...HELP_ITEM },
        { ...LOGOUT_ITEM },
      ],
    };

    return (
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={this.handleClose}
        items={
          this.props?.featureFlags?.includes("profile_specializations") &&
          isTalkOnly
            ? items.TALK_ONLY_INDIVIDUAL_ITEMS
            : activated
            ? items.ACTIVATED_INDIVIDUAL_ITEMS
            : items.ACTIVATING_INDIVIDUAL_ITEMS
        }
        handleSelection={this.handleSelection}
        handleLogout={this.handleLogout}
      />
    );
  }

  render() {
    const spanStyle = {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    };
    const { individual } = this.props;

    return (
      <span>
        <span
          style={spanStyle}
          onClick={this.handleClick}
          data-cy="header-profile-menu"
        >
          <GlobalAvatar individual={individual} />
          {this.constructor.renderCaret()}
        </span>
        {this.renderMenu()}
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  featureFlags: getFeatureFlags(state),
  headerMenuScope: state.ui.headerMenu.scope,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserSectionMenu)
);
