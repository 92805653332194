import { TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";

function FormContextTextField({
  id, inputProps, InputProps, ...passThroughProps
}) {
  const {
    values, errors, touched, handleChange, handleBlur
  } = useFormikContext();

  const errorMessage = get(touched, id) && get(errors, id);

  return (
    <TextField
      id={id}
      value={get(values, id)}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      FormHelperTextProps={{
        style: { marginBottom: "-18px" },
        "data-cy": `${id}-helper-text`,
      }}
      InputProps={{
        ...InputProps,
        inputProps: {
          "data-cy": `${id}-text-field-input`,
          ...inputProps
        }
      }}
      multiline
      {...passThroughProps}
    />
  );
}

FormContextTextField.defaultProps = {
  inputProps: {},
  InputProps: {},
};

FormContextTextField.propTypes = {
  id: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
};

export default FormContextTextField;
