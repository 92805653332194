import Input from "@material-ui/core/Input";
import NativeSelect from "@material-ui/core/NativeSelect";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import find from "lodash/find";
import ChevronDown from "mdi-material-ui/ChevronDown";
import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchJobCast as fetchEmployerJobCast } from "../../../../actions/employers/jobcastActions";
import { fetchJobcastNotifications } from "../../../../actions/employers/notificationActions";
import { fetchJobcastRequestByJobCastId } from "../../../../actions/recruiters/jobcastActions";
import { fetchRequestNotifications } from "../../../../actions/recruiters/notificationActions";
import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import { getJobCast } from "../../../../selectors/jobcastSelectors";
import { makeGetFilteredNotifications } from "../../../../selectors/notificationsSelectors";
import { makeGetRequestByJobcastId } from "../../../../selectors/recruiter/requestSelectors";
import JobCastLocations from "../../../General/JobCastLocations";
import LoadingPage from "../../../General/LoadingPage";

import EmptyNotifications from "./EmptyNotifications";
import NotificationsIndexHeader from "./NotificationsIndexHeader";
import NotificationsList from "./NotificationsList";

const styles = () => ({
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "3px 0px 3px 12px",
    alignItems: "center",
    height: 30,
    borderBottom: "1px solid #B0BEC5",
  },
  selectRoot: {
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      "& svg": {
        color: "#008dae",
      },
      "& select": {
        color: "#008dae",
      },
    },
  },
  select: {
    color: "#37474F",
    width: "100%",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.75,
    textTransform: "uppercase",
    "&:focus": {
      backgroundColor: "white",
    },
  },
  selectIcon: {
    fontSize: 24,
    top: "calc(50% - 13px)",
    color: "#37474F",
  },
});

class JobCastNotificationsIndex extends Component {
  // fetches unread notifications when the jobcast is set,
  // fetches read notifications the first time the filter's unset
  defaultState = {
    filtered: true,
    loaded: false,
    loadedAll: false,
    // important that we store these in state so that items don't disappear from
    // view when changed from unread to read
    filteredNotifications: [],
  };

  state = this.defaultState;

  async componentDidMount() {
    if (!this.props.jobcast && this.props.jobcastId) {
      if (this.props.currentIndividual.employer) {
        await this.props.fetchEmployerJobCast(this.props.jobcastId);
        this.props.jobcast && this.loadNotifications();
      } else {
        await this.props.fetchJobcastRequestByJobCastId(this.props.jobcastId);
        this.props.request && this.loadNotifications();
      }
    } else if (this.props.jobcastId) {
      this.loadNotifications();
    }
  }

  componentDidUpdate(
    { jobcastId: prevJobcastId },
    { filtered: prevFiltered, loaded: prevLoaded }
  ) {
    const { jobcastId } = this.props;
    const { filtered, loaded, loadedAll } = this.state;

    // we changed jobcasts or set a jobcast, need to start from scratch
    if (prevJobcastId !== jobcastId) {
      this.setState(this.defaultState, this.loadNotifications);
      // we changed the filter from unread => all and haven't loaded all notifications
    } else if (prevFiltered && !filtered && !loadedAll) {
      this.setState({ loaded: false }, this.loadNotifications);
      // we just finished loading new notifications or changed the filter val
    } else if ((!prevLoaded && loaded) || prevFiltered !== filtered) {
      this.filterNotifications();
    }
  }

  loadNotifications() {
    const {
      fetchJobcastNotifications,
      fetchRequestNotifications,
      currentIndividual,
      request,
    } = this.props;

    const { filtered, loadedAll } = this.state;

    const fetchNotifications = currentIndividual.employer
      ? fetchJobcastNotifications
      : fetchRequestNotifications(request.attributes.id);

    fetchNotifications(filtered)
      .then(() => this.setState({
        loaded: true,
        loadedAll: !filtered || loadedAll,
      }))
      .then(() => {
        if (this.props.presetNotificationId) {
          this.props.setSelectedNotification(
            this.getJobCastNotification(this.props.presetNotificationId)
          );
        }
      });
  }

  filterNotifications() {
    const { notifications } = this.props;
    const { filtered } = this.state;

    let filteredNotifications = notifications;

    if (filtered) {
      filteredNotifications = filteredNotifications.filter(
        (notification) => !notification.attributes.markedReadAt
      );
    }

    this.setState({
      filteredNotifications,
    });
  }

  getJobCastNotification = (notificationId) => {
    const { notifications } = this.props;
    const { loadedAll } = this.state;
    const notification = find(notifications, {
      id: notificationId,
    });

    if (!loadedAll && !notification) {
      this.setState({ filtered: false });
    }

    return notification;
  };

  renderContent() {
    const {
      setSelectedNotification,
      selectedNotification,
      jobcastId,
    } = this.props;
    const { loaded, filteredNotifications } = this.state;

    if (!loaded) {
      return <LoadingPage />;
    } if (filteredNotifications.length) {
      return (
        <NotificationsList
          notifications={filteredNotifications}
          setSelectedNotification={setSelectedNotification}
          jobcastId={jobcastId}
          selectedNotification={selectedNotification}
        />
      );
    }
    return (
      <EmptyNotifications
        text={!this.state.filtered && "There are no alerts to display"}
      />
    );
  }

  changeFilter = (e) => {
    this.setState({ filtered: e.target.value === "true" });
  };

  renderFilter() {
    const { classes } = this.props;

    return (
      <div className={classnames(classes.filterContainer)}>
        <NativeSelect
          classes={{
            root: classes.selectRoot,
            select: classes.select,
            icon: classes.selectIcon,
          }}
          input={<Input disableUnderline />}
          IconComponent={ChevronDown}
          onChange={this.changeFilter}
          style={{ width: this.state.filtered ? 152 : 116 }}
          value={this.state.filtered ? "true" : "false"}
        >
          <option value="true">Unread Alerts</option>
          <option value="false">All Alerts</option>
        </NativeSelect>
      </div>
    );
  }

  renderSubtitle = () => {
    const { currentIndividual, jobcast } = this.props;

    if (currentIndividual.employer) {
      return <JobCastLocations locations={jobcast.attributes.locations} />;
    }
    return (
      <div>
        <JobCastLocations locations={jobcast.attributes.locations} />
        <span>{jobcast.attributes.organizationName}</span>
      </div>
    );
  };

  render() {
    const jobcastAvailable =
      this.props.jobcast && this.props.jobcast.attributes;
    if (jobcastAvailable) {
      const { title } = this.props.jobcast.attributes;

      return (
        <>
          <NotificationsIndexHeader
            title={title}
            subtitle={this.renderSubtitle()}
          />
          {this.renderFilter()}
          {this.renderContent()}
        </>
      );
    }
    return <></>;
  }
}

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();
  const getRequestByJobcastId = makeGetRequestByJobcastId();

  const mapStateToProps = (state, { jobcastId }) => {
    const currentIndividual = getCurrentIndividual(state);

    return {
      currentIndividual,
      jobcast: getJobCast(state, jobcastId),
      request:
        currentIndividual.recruiter && getRequestByJobcastId(state, jobcastId),
      notifications: getFilteredNotifications(state, { jobcastId }),
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch, { jobcastId }) => ({
  fetchEmployerJobCast: () => dispatch(fetchEmployerJobCast(jobcastId)),
  fetchJobcastNotifications: (shouldFilterUnread) => dispatch(fetchJobcastNotifications(jobcastId, shouldFilterUnread)),

  fetchJobcastRequestByJobCastId: () => dispatch(fetchJobcastRequestByJobCastId(jobcastId)),
  fetchRequestNotifications: (requestId) => (shouldFilterUnread) => dispatch(fetchRequestNotifications(requestId, shouldFilterUnread)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(JobCastNotificationsIndex));
