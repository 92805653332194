import MoneyOff from '@material-ui/icons/MoneyOff';
import moment from "moment";
import React from "react";

import Content from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Content";
import Subheader from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";

import withNotificationMarkedRead from "./withNotificationMarkedRead";

function PriorityBonusRemoved({
  notification: {
    attributes: { messageBody, createdAt },
  },
  jobcast: {
    attributes: {
      individualAvatar,
      individualFirstName,
      individualLastInitial,
      organizationName,
    },
  },
}) {
  return (
    <div>
      <Subheader
        leftChip={{
          chipHeader: "Update from",
          individualFirstName,
          individualLastInitial,
          individualAvatar,
          chipSubText: organizationName,
        }}
      />
      <Content
        iconImage={MoneyOff}
        iconCaption="Priority Bonus Removed"
        label="Priority Bonus Removed"
        value={messageBody}
        helperText={moment(createdAt).format("MMMM Do, YYYY")}
      />
    </div>
  );
}

export default withNotificationMarkedRead(PriorityBonusRemoved);
