import { Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { removeFromCommunity } from "../../../../../actions/employers/agencyActions";
import { clearModal } from "../../../../../actions/shared/uiActions";
import { makeGetAgencyOrganization } from "../../../../../selectors/employer/agencySelectors";
import { getCurrentOrganization } from "../../../../../selectors/organizationSelectors";
import ActionModalContainer from "../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../General/ActionModal/ActionModalHeader";
import withSnackbar from "../../../../General/withSnackbar";

import { handleSubmit, initialValues, validationSchema } from "./fieldHelpers";
import RemoveFromCommunityModalContent from "./RemoveFromCommunityModalContent";

function RemoveFromCommunityModal({
  organizationId,
  snackbar,
  setExcludedOrgIds,
}) {
  const dispatch = useDispatch();
  const getAgencyOrganization = makeGetAgencyOrganization();
  const agencyOrganization = useSelector((state) => getAgencyOrganization(state, organizationId));
  const currentOrganization = useSelector((state) => getCurrentOrganization(state));
  const {
    organization: {
      attributes: { name: agencyName, id: agencyId },
    },
  } = agencyOrganization;

  const dispatchClearModal = () => dispatch(clearModal());
  const dispatchRemoveFromCommunity = (data) => dispatch(removeFromCommunity(agencyId, data));

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit(
        dispatchRemoveFromCommunity,
        dispatchClearModal,
        snackbar,
        agencyName,
        setExcludedOrgIds,
        agencyId
      )}
    >
      {(props) => (
        <ActionModalContainer color="red">
          <ActionModalHeader
            title="Remove from Community"
            subtitle={agencyName}
          />
          <ActionModalContent>
            <RemoveFromCommunityModalContent
              agencyName={agencyName}
              employerName={currentOrganization.name}
            />
          </ActionModalContent>
          <ActionModalFooter
            actionText="Remove"
            handleAction={props.handleSubmit}
            isSubmitting={props.isSubmitting}
          />
        </ActionModalContainer>
      )}
    </Formik>
  );
}

export default withSnackbar()(RemoveFromCommunityModal);
