import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import React from "react";
import { Col } from "react-grid-system";

function FormikTextField({
  id,
  tooltipText,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  status,
  label,
}) {
  return (
    <Col xs={4} style={{ marginBottom: 12 }}>
      <TextField
        id={id}
        label={(
          <>
            {label}
            {tooltipText && (
              <Tooltip placement="top" title={tooltipText}>
                <InfoOutlined
                  style={{
                    margin: "0px 0px -2px 4px",
                    fontSize: 14,
                  }}
                />
              </Tooltip>
            )}
          </>
        )}
        value={values[id]}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={touched[id] && errors[id]}
        error={Boolean((touched[id] && errors[id]) || (status && status[id]))}
      />
    </Col>
  );
}

export default FormikTextField;
