import { withFormik } from "formik";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import sortBy from "lodash/orderBy";
import React, { Component } from "react";
import { connect } from "react-redux";

import { transferCandidate } from "../../../../../../../actions/employers/candidateActions";
import {
  fetchJobCasts,
  fetchJobCast,
} from "../../../../../../../actions/employers/jobcastActions";
import {
  clearModal,
  clearProfileDrawer,
} from "../../../../../../../actions/shared/uiActions";
import {
  getJobCasts,
  PERM,
  TEMP,
} from "../../../../../../../selectors/jobcastSelectors";
import {
  buildFullName,
  truncateText,
} from "../../../../../../../util/formatHelpers";
import { string, object } from "../../../../../../../util/yup";
import DropdownInput from "../../../../../../forms/custom/DropdownInput.react";
import ActionModalContainer from "../../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../../../General/ActionModal/ActionModalHeader";
import LoadingPage from "../../../../../../General/LoadingPage";
import withSnackbar from "../../../../../../General/withSnackbar";

import { REMOTE } from "../../../../create/constants";

const extractJobCastLabel = (jobCast) => {
  const { workRemotely, locations, title } = jobCast.attributes;

  if (workRemotely === REMOTE) {
    return `${title} - Remote, ${locations[0].countryCode}`;
  }

  let secondaryText = "";

  if (!locations || locations.length === 0) return title;

  if (locations[0].city === "") {
    secondaryText = `${locations[0].state}`;
  } else {
    secondaryText = map(
      locations,
      (location) => `${location.city}, ${location.state}`
    ).join(" OR ");
  }

  return `${title} - ${secondaryText}`;
};

class TransferModal extends Component {
  state = { submitError: null };

  componentDidMount() {
    const { loaded, fetchJobCasts } = this.props;

    if (!loaded) {
      fetchJobCasts();
    }
  }

  componentDidUpdate({ isSubmitting: prevSubmitting, values: prevValues }) {
    const { isSubmitting, values, errors } = this.props;
    const { submitError } = this.state;

    if (prevSubmitting && !isSubmitting && !isEmpty(errors)) {
      this.setState({
        submitError:
          errors.backend || "Please review the fields highlighted in red.",
      });
    } else if (submitError && !isEqual(values, prevValues)) {
      this.setState({ submitError: null });
    }
  }

  renderDropdown() {
    const {
      availableTargetJobCasts, handleChange, values, errors, touched
    } =
      this.props;

    const dropdownOptions = sortBy(
      map(availableTargetJobCasts, (jobCast) => ({
        label: truncateText(extractJobCastLabel(jobCast), 80),
        value: jobCast.id,
      })),
      (option) => option.label.toLowerCase()
    );

    return (
      <DropdownInput
        id="target_jobcast_id"
        label="Which JobCast Would You Like To Transfer The Candidate To?"
        options={dropdownOptions}
        value={values.target_jobcast_id}
        onChange={handleChange}
        error={Boolean(touched.target_jobcast_id && errors.target_jobcast_id)}
        helperText={touched.target_jobcast_id && errors.target_jobcast_id}
        noShrink
        overrideStyle={{ width: 460 }}
      />
    );
  }

  renderContent() {
    return (
      <>
        <p style={{ margin: "0 0 24px 0" }}>
          Please select the JobCast you would like to transfer this candidate
          to. Note that the candidate will no longer be accessible from this
          JobCast and the submitting recruiter will be notified of the transfer.
        </p>
        <p style={{ marginBottom: 24 }}>
          You can only transfer candidates to JobCasts you own.
        </p>
        {this.renderDropdown()}
      </>
    );
  }

  render() {
    const {
      candidate, loaded, handleSubmit, isSubmitting
    } = this.props;
    const { submitError } = this.state;

    return (
      <ActionModalContainer color="blue">
        <ActionModalHeader
          title="Transfer Candidate to Another JobCast"
          subtitle={buildFullName(
            candidate.attributes.firstName,
            candidate.attributes.lastName
          )}
        />
        <ActionModalContent>
          {loaded ? this.renderContent() : <LoadingPage />}
        </ActionModalContent>
        <ActionModalFooter
          actionText="Transfer"
          handleAction={handleSubmit}
          isSubmitting={isSubmitting}
          submissionError={Boolean(submitError)}
          overrideErrorStyles={{ width: 435, fontSize: 14 }}
          customErrorMessage={submitError}
        />
      </ActionModalContainer>
    );
  }
}

const TransferModalWithFormik = withFormik({
  handleSubmit: (values, { props, setSubmitting, setFieldError }) => {
    const targetJobCast = props.availableTargetJobCasts.find(
      ({ id }) => id === values.target_jobcast_id
    );

    props
      .transferCandidate(values)
      .then(() => {
        setSubmitting(false);
        props.clearModal();
        props.clearProfileDrawer();

        props.snackbar.showMessage(
          `This candidate has been transferred to ${extractJobCastLabel(
            targetJobCast
          )}`
        );
      })
      .then(() => {
        props.fetchJobCast(targetJobCast.id);
      })
      .catch((res = {}) => {
        const { errors } = res;
        if (errors.existing_submission_id && errors.existing_submission_stage) {
          const errorMessage =
            "Candidate has already confirmed interest in that JobCast. " +
            `Their profile can be accessed from the ${errors.existing_submission_stage} stage of that JobCast.`;

          setFieldError("backend", errorMessage);
          setSubmitting(false);
        } else {
          setFieldError("backend", "Internal server error");
          setSubmitting(false);
        }
      });
  },
  mapPropsToValues: () => ({
    target_jobcast_id: "",
  }),
  validationSchema: object().shape({
    target_jobcast_id: string().required("Cannot be blank"),
  }),
})(TransferModal);

const mapStateToProps = (state, ownProps) => ({
  loaded: !!ownProps.candidate.attributes.temp ? state.jobcasts.indexTypesLoaded[TEMP].active : state.jobcasts.indexTypesLoaded[PERM].active,
  availableTargetJobCasts: getJobCasts(state, "STARTED").filter(
    (jobcast) => jobcast.id !== ownProps.candidate.attributes.jobcastId && ((!ownProps.candidate.attributes.temp && !jobcast.attributes.isTemp) || (ownProps.candidate.attributes.temp && jobcast.attributes.isTemp))
  ),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchJobCasts: () => dispatch(fetchJobCasts("active", true, !!ownProps.candidate.attributes.temp)),
  fetchJobCast: (id) => dispatch(fetchJobCast(id)),
  transferCandidate: (data) => dispatch(
    transferCandidate({ candidateId: ownProps.candidate.id, ...data })
  ),
  clearModal: () => dispatch(clearModal()),
  clearProfileDrawer: () => dispatch(clearProfileDrawer()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar()(TransferModalWithFormik));
