export const RECEIVE_ORG_JOB_CATEGORIES = "RECEIVE_ORG_JOB_CATEGORIES";
export const RECEIVE_ORG_JOB_CATEGORY = "RECEIVE_ORG_JOB_CATEGORY";

export const receiveOrgJobCategories = (orgJobCategories) => ({
  type: RECEIVE_ORG_JOB_CATEGORIES,
  payload: orgJobCategories,
});

export const receiveOrgJobCategory = (orgJobCategory) => ({
  type: RECEIVE_ORG_JOB_CATEGORY,
  payload: orgJobCategory,
});

export const getOrgJobCategories = (state) => state.orgJobCategories.items;
