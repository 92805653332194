import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import compose from 'lodash/flowRight';
import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchJobCast as fetchEmployerJobCast } from "../../../../actions/employers/jobcastActions";
import { fetchJobCast as fetchRecruiterJobCast } from "../../../../actions/recruiters/jobcastActions";

import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import { getJobCast } from "../../../../selectors/jobcastSelectors";

import AgencyChat from "../../../employer/NotificationsDrawer/NotificationViewTab/AgencyChat";
import InvitationAccepted from "../../../employer/NotificationsDrawer/NotificationViewTab/InvitationAccepted";
import JobcastTransfer from "../../../employer/NotificationsDrawer/NotificationViewTab/JobcastTransfer";
import OfferStageReminder from "../../../employer/NotificationsDrawer/NotificationViewTab/OfferStageReminder";
import PositionArchived from "../../../employer/NotificationsDrawer/NotificationViewTab/PositionArchived";
import PositionArchivingSoon from "../../../employer/NotificationsDrawer/NotificationViewTab/PositionArchivingSoon/PositionArchivingSoon";
import PositionCorrespondence from "../../../employer/NotificationsDrawer/NotificationViewTab/PositionCorrespondence/PositionCorrespondence";
import WithdrawnCandidate from "../../../employer/NotificationsDrawer/NotificationViewTab/WithdrawnCandidate";
import JobCastLocations from "../../../General/JobCastLocations";
import LoadingPage from "../../../General/LoadingPage";

// employer

// recruiter
import Announcement from "../../../recruiter/NotificationsDrawer/NotificationViewTab/Announcement";
import CandidateUpdate from "../../../recruiter/NotificationsDrawer/NotificationViewTab/CandidateUpdate";
import EmployerChat from "../../../recruiter/NotificationsDrawer/NotificationViewTab/EmployerChat";
import HighPriorityJobCast from "../../../recruiter/NotificationsDrawer/NotificationViewTab/HighPriorityJobCast";
import HighPriorityRemoved from "../../../recruiter/NotificationsDrawer/NotificationViewTab/HighPriorityRemoved";
import JobCastClosed from "../../../recruiter/NotificationsDrawer/NotificationViewTab/JobCastClosed";
import JobCastLocked from "../../../recruiter/NotificationsDrawer/NotificationViewTab/JobCastLocked";
import JobCastReopened from "../../../recruiter/NotificationsDrawer/NotificationViewTab/JobCastReopened";
import JobCastUnlocked from "../../../recruiter/NotificationsDrawer/NotificationViewTab/JobCastUnlocked";
import PriorityBonusAdded from "../../../recruiter/NotificationsDrawer/NotificationViewTab/PriorityBonusAdded";
import PriorityBonusRemoved from "../../../recruiter/NotificationsDrawer/NotificationViewTab/PriorityBonusRemoved";
import PriorityBonusUpdated from "../../../recruiter/NotificationsDrawer/NotificationViewTab/PriorityBonusUpdated";
import QuestionAnswer from "../../../recruiter/NotificationsDrawer/NotificationViewTab/QuestionAnswer";
import StandardizedFeeUpdate from "../../../recruiter/NotificationsDrawer/NotificationViewTab/StandardizedFeeUpdate";
import {
  POSITION_ARCHIVED,
  POSITION_ARCHIVING_SOON,
  PRIORITY_BONUS_ADDED,
  PRIORITY_BONUS_REMOVED,
  PRIORITY_BONUS_UPDATED,
  TAB_1_WIDTH,
  TAB_2_WIDTH,
  TAB_3_WIDTH,
} from "../constants";

const styles = ({
  root: {
    position: "fixed",
    backgroundColor: "white",
    height: "100%",
    width: TAB_3_WIDTH - 1,
    left: TAB_1_WIDTH + TAB_2_WIDTH,
    borderRadius: 0,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    boxShadow: "none",
    borderLeft: "1px solid #90A4AE",
  },
  headerContainer: {
    backgroundColor: "#008dae",
    height: "65px",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  overflowEllipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
});

class NotificationViewTab extends Component {
  componentDidMount() {
    const {
      jobcast,
      notification,
      fetchEmployerJobCast,
      fetchRecruiterJobCast,
      currentIndividual,
    } = this.props;

    const fetchJobCast = currentIndividual.employer
      ? fetchEmployerJobCast
      : fetchRecruiterJobCast;

    if (notification && !jobcast) {
      fetchJobCast();
    }
  }

  componentDidUpdate() {
    const {
      notification,
      jobcast,
      fetchEmployerJobCast,
      fetchRecruiterJobCast,
      currentIndividual,
    } = this.props;

    const fetchJobCast = currentIndividual.employer
      ? fetchEmployerJobCast
      : fetchRecruiterJobCast;

    if (notification && !jobcast) {
      fetchJobCast();
    }
  }

  renderSubject() {
    const { notification, jobcast } = this.props;
    const { type, recruiterSubmissionId, positionCorrespondenceId } =
      notification.attributes;

    switch (type) {
      case "Chat":
        return <AgencyChat candidateId={recruiterSubmissionId} />;
      case "Feedback":
      case "Question":
        return (
          <PositionCorrespondence
            positionCorrespondenceId={String(positionCorrespondenceId)}
            jobcast={jobcast}
          />
        );
      case "Withdrawl":
        return (
          <WithdrawnCandidate
            notification={notification}
            jobcast={jobcast}
            candidateId={recruiterSubmissionId}
          />
        );
      case "Announcement":
        return <Announcement {...{ notification, jobcast }} />;
      case "JobCast Question":
      case "JobCast Feedback":
        return <QuestionAnswer {...{ notification, jobcast }} />;
      case "JobCast Locked":
      case "Contract Role Locked":
        return <JobCastLocked {...{ notification, jobcast }} />;
      case "JobCast Unlocked":
      case "Contract Role Unlocked":
        return (
          <JobCastUnlocked {...{ notification, jobcast, type }} />
        );
      case "JobCast Closed":
        return <JobCastClosed {...{ notification, jobcast }} />;
      case "JobCast Reopened":
        return (
          <JobCastReopened {...{ notification, jobcast }} />
        );
      case "Candidate Update":
      case "Candidate Transfer":
        return <CandidateUpdate notification={notification} />;
      case "Offer Stage Reminder":
        return <OfferStageReminder notification={notification} />;
      case "Employer Chat":
        return <EmployerChat {...{ notification, jobcast }} />;
      case "PENDING-INVITATION-ACCEPTED":
        return <InvitationAccepted notification={notification} />;
      case "High Priority":
        return (
          <HighPriorityJobCast {...{ notification, jobcast }} />
        );
      case "High Priority Removed":
        return (
          <HighPriorityRemoved {...{ notification, jobcast }} />
        );
      case "Fee Update":
        return (
          <StandardizedFeeUpdate {...{ notification, jobcast }} />
        );
      case "JobCast Transfer":
        return (
          <JobcastTransfer {...{ notification, jobcast }} />
        );
      case PRIORITY_BONUS_ADDED:
        return (
          <PriorityBonusAdded {...{ notification, jobcast }} />
        );
      case PRIORITY_BONUS_UPDATED:
        return (
          <PriorityBonusUpdated {...{ notification, jobcast }} />
        );
      case PRIORITY_BONUS_REMOVED:
        return (
          <PriorityBonusRemoved {...{ notification, jobcast }} />
        );
      case POSITION_ARCHIVING_SOON:
        return (
          <PositionArchivingSoon {...{ notification, jobcast }} />
        );
      case POSITION_ARCHIVED:
        return (
          <PositionArchived {...{ notification, jobcast }} />
        );
      default:
        return null;
    }
  }

  renderContent(title, subtitle, additionalContent) {
    const { classes } = this.props;

    return (
      <div data-cy="notification-view-tab">
        <div className={classes.headerContainer}>
          <h4
            style={{ color: "white", maxWidth: "400px" }}
            className={classes.overflowEllipsis}
          >
            {title}
          </h4>
          <div
            className={classnames("subtitle-1", classes.overflowEllipsis)}
            style={{ color: "white", maxWidth: 400 }}
          >
            {subtitle}
          </div>
        </div>
        {additionalContent}
        {this.renderSubject()}
      </div>
    );
  }

  renderInvitationAcceptedContent() {
    const { notification } = this.props;
    const title = "New Preferred Agency";
    const subtitle = notification.attributes.metadata.organizationName;

    return this.renderContent(title, subtitle);
  }

  renderJobcastContent() {
    const { jobcast, currentIndividual } = this.props;
    const { title, locations } = jobcast.attributes;
    const subtitle = (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <JobCastLocations locations={locations} />
        {!currentIndividual.employer && <span>{jobcast.attributes.organizationName}</span>}
      </div>
    );

    return this.renderContent(title, subtitle);
  }

  render() {
    const { notification, jobcast, classes } = this.props;

    if (!notification) {
      return null;
    }

    let content;
    if (notification.attributes?.type === "PENDING-INVITATION-ACCEPTED") {
      content = this.renderInvitationAcceptedContent();
    } else if (jobcast) {
      content = this.renderJobcastContent();
    } else {
      content = <LoadingPage />;
    }

    return (
      <Paper className={classnames(classes.root)}>
        {content}
      </Paper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const currentIndividual = getCurrentIndividual(state);
  const jobcast = currentIndividual.employer
    ? ownProps.notification &&
      getJobCast(state, ownProps.notification.attributes.positionId)
    : ownProps.notification &&
      getJobCast(state, ownProps.notification.attributes.jobcastId);

  return {
    jobcast,
    currentIndividual,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchEmployerJobCast: () => dispatch(fetchEmployerJobCast(ownProps.notification.attributes.positionId)),
  fetchRecruiterJobCast: () => dispatch(fetchRecruiterJobCast(ownProps.notification.attributes.jobcastId)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(NotificationViewTab);
