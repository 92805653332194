import { connect } from "react-redux";

import {
  fetchConversation,
  createMessage
} from "../../../../../../../actions/employers/candidateActions";
import ChatTab from "../../../../../../shared/CandidateDrawer/CandidateDrawerTabs/ChatTab";

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchConversation: () => dispatch(fetchConversation(ownProps.candidate.id)),
  createMessage: message => dispatch(createMessage(ownProps.candidate.id, message))
});

export default connect(null, mapDispatchToProps)(ChatTab);
