import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchJobCast } from "../../../actions/employers/jobcastActions";
import { openProfileDrawer } from "../../../actions/shared/uiActions";
import { JOBCASTS, CANDIDATES, AGENCIES } from "../../shared/Search/constants";
import SearchResultItemBody from "../../shared/Search/SearchResultItem/SearchResultItemBody";
import SearchResultsItemProfile from "../../shared/Search/SearchResultItem/SearchResultItemProfile";
import SearchResultItemSupportInfo from "../../shared/Search/SearchResultItem/SearchResultItemSupportInfo";
import { parseCandidateStatus } from "../../shared/Search/utils";
import AgencyRecruiterDrawer from "../Agencies/view/AgencyRecruiterDrawer";

import CandidateDrawer from "../JobCasts/view/Candidates/view/CandidateDrawer";

const styles = () => ({
  card: {
    borderRadius: 0,
    height: "60px",
    minHeight: "60px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    borderBottom: "0.5px solid #B0BEC5",
    "&:hover": {
      backgroundColor: "rgb(0, 0, 0, 0.08)",
    },
  },
  paper: {
    boxShadow: "none",
  },
});

function SearchResultsItem({
  classes,
  item,
  history,
  exitSearch,
  openProfileDrawer,
  fetchJobCast,
}) {
  const handleClick = () => {
    switch (item.type) {
      case JOBCASTS:
        fetchJobCast(item.searchableId).then(() => {
          history.push(`/jobcasts/${item.searchableId}/description`);
        });
        break;
      case CANDIDATES:
        const jobcastId = JSON.parse(item.description).position_id;

        fetchJobCast(jobcastId).then(() => {
          history.push(
            `/jobcasts/${jobcastId}/${parseCandidateStatus(item.status)}`
          );
          openProfileDrawer(
            <CandidateDrawer
              candidateId={item.searchableId}
              navSelection="view_resume"
            />
          );
        });
        break;
      case AGENCIES:
        openProfileDrawer(
          <AgencyRecruiterDrawer recruiterId={item.searchableId} />
        );
        break;
      default:
        return null;
    }

    exitSearch();
  };

  return (
    <Card
      className={classes.card}
      onClick={handleClick}
      classes={{ root: classes.paper }}
      data-cy={`search-result-item-${item.type}`}
    >
      <SearchResultsItemProfile item={item} />
      <SearchResultItemBody item={item} />
      <SearchResultItemSupportInfo item={item} />
    </Card>
  );
}

const mapDispatchToProps = (dispatch) => ({
  openProfileDrawer: (content, positionId) => dispatch(openProfileDrawer(content, positionId)),
  fetchJobCast: (id) => dispatch(fetchJobCast(id)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withRouter(SearchResultsItem)));
