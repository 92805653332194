import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Checkbox from "@material-ui/core/Checkbox";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import merge from "lodash/merge";
import sortBy from "lodash/sortBy";
import React from "react";
import { Row } from "react-grid-system";

import { buildFullName } from "../../../../../../../../util/formatHelpers";
import GlobalAvatar from "../../../../../../../General/GlobalAvatar";

function CandidateList(props) {
  const {
    expanded,
    handleDisplayClick,
    candidates,
    values,
    setFieldValue
  } = props;

  const renderAvatar = ({ attributes: { firstName, lastName, avatar } }) => (
    <GlobalAvatar
      individual={{
        avatarIcon: avatar,
        firstName,
        lastName,
      }}
    />
  );

  const renderOption = candidate => {
    const {
      attributes: { id, firstName, lastName }
    } = candidate;

    const isChecked = values.candidates[id];
    const handleChange = e => setFieldValue(
      "candidates",
      merge(values.candidates, { [id]: !isChecked })
    );

    return (
      <div
        className="expansion-panel-row"
        onClick={handleChange}
        key={`share-candidate-${id}`}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 40
          }}
        >
          {renderAvatar(candidate)}
          <div style={{ marginLeft: 12, fontSize: 16 }} className="regular">
            {buildFullName(firstName, lastName)}
          </div>
        </div>
        <div style={{ marginRight: candidates.length > 3 ? 33 : 48 }}>
          <Checkbox
            id={`candidate-${id}-share`}
            checked={isChecked}
            color="primary"
            onChange={handleChange}
          />
        </div>
      </div>
    );
  };

  const renderOptions = () => {
    const { selectedCandidateId } = props;
    if (candidates.length) {
      const sortedCandidates = sortBy(candidates, [
        candidate => (candidate.id === selectedCandidateId ? -1 : 1)
      ]);

      return (
        <div>{sortedCandidates.map(candidate => renderOption(candidate))}</div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "12px 0px 12px 0px",
          width: "100%",
          justifyContent: "center",
          maxHeight: 200
        }}
      >
        No candidates are currently in this stage
      </div>
    );
  };

  return (
    <Row style={{ margin: "0 0 10px 0" }}>
      <Accordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="subtitle-1"
          onClick={handleDisplayClick}
        >
          {props.title}
          {' '}
          (
          {props.candidates.length}
          )
        </AccordionSummary>
        <AccordionDetails
          style={{
            padding: 0,
            maxHeight: 220,
            overflowY: candidates.length > 3 ? "scroll" : "hidden",
            overflowX: "hidden"
          }}
        >
          {renderOptions()}
        </AccordionDetails>
      </Accordion>
    </Row>
  );
}

export default CandidateList;
