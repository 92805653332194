import map from "lodash/map";
import React from "react";
import { Row, Col } from "react-grid-system";

import PageCardRowHeader from "../../../../General/PageCardRowHeader";

import FieldSection, { Field } from "./FieldSection";

function AgencyMustHaves({ candidate }) {
  const { mustHavesTitle, mustHavesValue, recruiterNote } =
    candidate.attributes;

  return (
    <>
      <PageCardRowHeader title="Agency Responses to Must-Haves" />
      <FieldSection>
        {map(mustHavesTitle, (title, idx) => (
          <Field label={title}>{mustHavesValue[idx] || ""}</Field>
        ))}
        {recruiterNote && (
          <Row>
            <Col xs={12}>
              <Field label="Additional Information from the agency">
                {recruiterNote}
              </Field>
            </Col>
          </Row>
        )}
      </FieldSection>
    </>
  );
}

export default AgencyMustHaves;
