import normalize from "json-api-normalizer";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { receiveNotifications } from "../../../../actions/shared/notificationActions";
import ActionCableConsumer from "../ActionCableConsumer";

function NotificationsConsumer() {
  const dispatch = useDispatch();

  const handleReceived = useCallback(
    (data) => {
      const normalized = normalize(data);
      dispatch(receiveNotifications(normalized.notification));
    },
    [dispatch]
  );

  return (
    <ActionCableConsumer
      channel="NotificationsChannel"
      onReceived={handleReceived}
    />
  );
}

export default NotificationsConsumer;
