import React from "react";

import GlobalAvatar from "../../../General/GlobalAvatar";
import {
  CANDIDATES,
  AGENCIES,
  JOBCASTS,
} from "../../../shared/Search/constants";
import { deconstructName } from "../../../shared/Search/utils";

function SearchResultItemProfile({ item }) {
  switch (item.type) {
    case JOBCASTS:
      return (
        <GlobalAvatar
          overrideStyle={{ borderRadius: "0%", minWidth: 60, marginLeft: 6 }}
          organization={{
            avatarIcon: item.avatar,
            ...deconstructName(item),
          }}
          jobcastSearch
        />
      );
    case CANDIDATES:
    case AGENCIES:
      return (
        <GlobalAvatar
          overrideStyle={{ marginLeft: 12 }}
          individual={{
            avatarIcon: item.avatar,
            ...deconstructName(item),
          }}
        />
      );
    default:
      return <></>;
  }
}

export default SearchResultItemProfile;
