export const RECEIVE_PLACEMENTS = "RECEIVE_PLACEMENTS";
export const RECEIVE_PAYMENT_NOTIFICATIONS = "RECEIVE_PAYMENT_NOTIFICATIONS";
export const RECEIVE_PAYMENT_METHOD = "RECEIVE_PAYMENT_METHODS";
export const SET_PLACEMENTS_LOADED = "SET_PLACEMENTS_LOADED";

export const receivePlacements = (payload) => ({
  payload,
  type: RECEIVE_PLACEMENTS,
});

export const receivePaymentNotifications = (payload) => ({
  payload,
  type: RECEIVE_PAYMENT_NOTIFICATIONS,
});

export const receivePaymentMethod = (payload) => ({
  payload,
  type: RECEIVE_PAYMENT_METHOD
});

export const setPlacementsLoaded = (isLoaded) => ({
  payload: isLoaded,
  type: SET_PLACEMENTS_LOADED,
});
