import { withStyles } from "@material-ui/core/styles";
import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";
import React, { Component } from "react";

import { ASC, DESC } from "./constants";
import TableHeader from "./TableHeader";
import TableRows from "./TableRows";

import tableStyles from "./tableStyles";

class SortableTable extends Component {
  state = (({ items, defaultSortKey: sortKey, defaultSortDir }) => ({
    sortKey,
    sortDir: defaultSortDir || ASC,
    sortedItems: sortKey ? this.sortItems(items) : items
  }))(this.props);

  componentDidMount() {
    this.sortItems();
  }

  componentDidUpdate(
    { items: prevItems },
    { sortKey: prevSortKey, sortDir: prevSortDir }
  ) {
    const { items } = this.props;
    const { sortKey, sortDir } = this.state;

    if (items !== prevItems) {
      // re-sort updated items only if we've received new items/deleted items
      this.updateItems(!Boolean(items.length === prevItems.length));
    } else if (sortKey !== prevSortKey || sortDir !== prevSortDir) {
      this.setState({ sortedItems: this.sortItems() });
    }
  }

  updateItems(withResort = false) {
    const { items: newItems } = this.props;
    const { sortedItems: currItems } = this.state;

    const newItemsObj = {};
    newItems.forEach(item => (newItemsObj[item.id] = item));
    const isOldItem = {};

    const updatedItems = [];
    let updatedItem;
    currItems.forEach(currItem => {
      isOldItem[currItem.id] = true;
      updatedItem = newItemsObj[currItem.id];
      updatedItem && updatedItems.push(updatedItem);
    });

    newItems.forEach(item => {
      if (!isOldItem[item.id]) {
        updatedItems.push(item);
      }
    });

    this.setState({
      sortedItems: withResort ? this.sortItems(updatedItems) : updatedItems
    });
  }

  sortItems(items = this.state.sortedItems) {
    const { columns } = this.props;
    const { sortKey, sortDir } = this.state || {
      sortKey: this.props.defaultSortKey,
      sortDir: this.props.defaultSortDir
    };

    const targetKey = sortKey || columns[0].colKey;
    const { orderFunc } = columns.find(({ colKey }) => colKey === targetKey);

    return orderBy(items, [orderFunc], [sortDir]);
  }

  handleSortClick = colKey => () => {
    const { sortKey: currSortKey, sortDir: currSortDir } = this.state;

    const sortKey = colKey;
    let sortDir = ASC;

    if (colKey === currSortKey) {
      sortDir = currSortDir === ASC ? DESC : ASC;
    }

    this.setState({ sortKey, sortDir });
  };

  render() {
    const { columns, classes, customStyles } = this.props;
    const { sortedItems, sortKey, sortDir } = this.state;

    return (
      <div
        className={classes.tableContainer}
        style={customStyles.container}
        data-cy="sortable-table-container"
      >
        <TableHeader
          columns={columns}
          sortKey={sortKey}
          sortDir={sortDir}
          handleSortClick={this.handleSortClick}
          customStyles={customStyles}
        />
        <TableRows
          columns={columns}
          items={sortedItems}
          customStyles={customStyles}
        />
      </div>
    );
  }
}

SortableTable.defaultProps = {
  customStyles: {}
};

SortableTable.propTypes = {
  // the table "schema" - define the logic for each column
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      // unique identifier for a column
      colKey: PropTypes.string.isRequired,
      // the header cell content for the column
      label: PropTypes.node,
      // function returning the cell content in the column for each row item;
      // takes items[some_row_idx] as its arg
      renderData: PropTypes.func.isRequired,
      // function returning the sorting value for each row item in the column;
      // takes items[some_row_idx] as its arg
      orderFunc: PropTypes.func,
      // the styles applied across the column, ie width, justifyContent, etc
      style: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired
      }).isRequired
    })
  ),
  // the table "data" - define the data for each row
  items: PropTypes.arrayOf(
    PropTypes.shape({
      // only used for row component key values => need to be unique for each item
      id: PropTypes.any.isRequired
    })
  ).isRequired,
  // the initial column to sort by (if any)
  defaultSortKey: PropTypes.string,
  // default styling via class (see ./tableStyles)
  classes: PropTypes.object.isRequired,
  // in-line styles to override the defaults (if needed)
  customStyles: PropTypes.shape({
    container: PropTypes.object,
    header: PropTypes.object,
    body: PropTypes.object,
    bodyRow: PropTypes.object,
    headerCell: PropTypes.object,
    bodyCell: PropTypes.object,
    sortIcon: PropTypes.object
  })
};

export default withStyles(tableStyles)(SortableTable);
