const uniq = require("lodash/uniq");
const yup = require("yup");

const { default: constants } = require("./constants");
const { replaceHTMLTags } = require("./formatHelpers");
const { mapToInclusionHash } = require("./formatHelpers");
const { REGEX_LINKEDIN_URL } = require("./formHelpers");

yup.addMethod(yup.string, "minWords", function (minNum) {
  return this.test(
    `minimum-words`,
    `Must be at least ${minNum} words`,
    (value) => !value ||
      Boolean(value.split(/[^\w]+/g).filter((wrd) => wrd).length >= minNum)
  );
});

yup.addMethod(yup.array, "unique", function (additionalValues = []) {
  return this.test(
    `unique`,
    "Cannot contain duplicate values",
    (array) => {
      const fullArray = array.concat(additionalValues);
      return fullArray.length === uniq(fullArray).length;
    }
  );
});

const parseFormattedNumStr = (formattedNum) => parseFloat(formattedNum.replace(/,/g, ""));

yup.addMethod(
  yup.string,
  "greaterThanOther",
  function (ref, message, allowEquality = false) {
    return this.test(
      `greater-than-other`,
      message || "Out of range",
      function (value) {
        const otherValue = this.resolve(ref);
        if (!value || !otherValue) return true;

        return allowEquality
          ? parseFormattedNumStr(value) >= parseFormattedNumStr(otherValue)
          : parseFormattedNumStr(value) > parseFormattedNumStr(otherValue);
      }
    );
  }
);

yup.addMethod(
  yup.string,
  "lessThanOther",
  function (ref, message, allowEquality = false) {
    return this.test(
      `less-than-other`,
      message || "Out of range",
      function (value) {
        const otherValue = this.resolve(ref);

        if (!value || !otherValue) return true;

        return allowEquality
          ? parseFormattedNumStr(value) <= parseFormattedNumStr(otherValue)
          : parseFormattedNumStr(value) < parseFormattedNumStr(otherValue);
      }
    );
  }
);

yup.addMethod(yup.string, "greaterThan", function (minNum, message) {
  return this.test(
    `greater-than`,
    message || `Must be at greater than ${minNum}`,
    (value) => !value || parseFormattedNumStr(value) > minNum
  );
});

yup.addMethod(yup.string, "lessThan", function (maxNum, message) {
  return this.test(
    `less-than`,
    message || `Must be less than ${maxNum}`,
    (value) => !value || parseFormattedNumStr(value) < maxNum
  );
});

const FEE_REGEX = /^\d{0,2}\.?(\d{1,2})?$/;
yup.addMethod(yup.string, "validFeePercentage", function (fee) {
  return this.test(
    `valid-fee-percentage`,
    "Cannot be more than 2 decimal places",
    (value) => Boolean(FEE_REGEX.test(value)) || !value
  );
});

const URL_REGEX =
  /^(((https?|ftp):)?\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
const ALLOW_SPACES_URL_REGEX =
  /^\s*(((https?|ftp):)?\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?\s*$/i;
yup.addMethod(yup.string, "urlValidation", function (urlString) {
  const urlSchema = this.required("Cannot be blank").matches(URL_REGEX, {
    message: "Improper format, example: example.com",
  });

  return urlSchema.validate(urlString);
});

// for use within an existing yup schema, does not perform validation on empty strings
yup.addMethod(yup.string, "validURL", function (allowSpaces = false) {
  const URL_RESTRICTION = allowSpaces ? ALLOW_SPACES_URL_REGEX : URL_REGEX;
  return this.test(
    `valid-url`,
    "Improper format, example: example.com",
    (value) => Boolean(URL_RESTRICTION.test(value)) || !value
  );
});

const ABSOLUTE_URL_REGEX = /^(((https|http):)\/\/)/i;
yup.addMethod(yup.string, "absoluteURL", function () {
  return this.test(
    `valid-url`,
    "Improper format, URL must begin with http:// or https://",
    (value) => Boolean(ABSOLUTE_URL_REGEX.test(value)) || !value
  );
});

yup.addMethod(yup.string, "notPublicEmail", function () {
  const isPublicEmailDomain = mapToInclusionHash(constants.publicEmailDomains);
  const whitelistedEmailAddresses = new Set(
    constants.whitelistedEmailAddresses
  );
  return this.test(
    `not-public-email`,
    "Cannot be a personal email address",
    (value) => (
      !value ||
        whitelistedEmailAddresses.has(value) ||
        !isPublicEmailDomain[value.replace(/.*@/, "")]
    )
  );
});

yup.addMethod(yup.string, "minWordsWithoutHTML", function (minNum) {
  return this.test(
    `min-words-without-html`,
    `Must be at least ${minNum} words`,
    (value) => {
      if (!value) {
        return true;
      }
      const rawText = replaceHTMLTags(value, "");
      const words = rawText.split(/[^\w]+/g).filter((wrd) => wrd);
      return words.length >= minNum;
    }
  );
});

yup.addMethod(yup.string, "validPostalCode", function () {
  return this.test(
    `minimum-characters`,
    `Must be 5 digits`,
    (value) => !Boolean(value) || (!isNaN(value) && value.length === 5)
  );
});

const CANADIAN_POSTAL_CODE_REGEX = /^[a-zA-Z\d\s]{6,7}$/;
yup.addMethod(yup.string, "validCanadianPostalCode", function () {
  return this.test(
    `minimum-characters`,
    `Must be 6 characters`,
    (value) => Boolean(CANADIAN_POSTAL_CODE_REGEX.test(value)) || !value
  );
});

yup.addMethod(yup.string, "validLinkedinUrl", function () {
  return this.test(
    `valid-linkedin-url`,
    `Must be a valid LinkedIn profile URL, e.g. linkedin.com/in/yourname`,
    (value) => (value
      ? REGEX_LINKEDIN_URL.test(value)
      : false)
  );
});

yup.addMethod(yup.string, "validNonIndeedEmail", function () {
  return this.test(
    "valid-email",
    "Indeed email addresses are not accepted",
    (value) => Boolean(value && !value.toLowerCase().includes("indeedemail.com"))
  );
});

yup.addMethod(
  yup.array,
  "minNumberedListItems",
  function (minItems, message = "") {
    return this.test(
      `min-numbered-list-items`,
      message || `Must be at least ${minItems} items`,
      (value) => Boolean(
        value &&
            value.filter &&
            value.filter((itemVal) => itemVal).length >= minItems
      )
    );
  }
);

yup.addMethod(
  yup.mixed,
  "notInArray",
  function (
    ref,
    message = "${path} not found in ${ref}" // eslint-disable-line no-template-curly-in-string
  ) {
    return this.test({
      message,
      name: "notInArray",
      exclusive: false,
      params: { ref },
      test(value) {
        return !(this.resolve(ref) || []).includes(value);
      },
    });
  }
);

yup.addMethod(
  yup.string,
  "uniqueEmail",
  function (ref, message) {
    return this.test({
      message,
      name: "uniqueEmail",
      exclusive: false,
      test(value) {
        if (!value) {
          return true;
        }

        const email = value.toLowerCase().trim();
        const compare = this.resolve(ref);

        if (typeof compare === 'string') {
          return email !== compare.toLowerCase().trim();
        }
        if (Array.isArray(compare)) {
          return !compare.some(e => email === e.toLowerCase().trim());
        }
        return true;
      }
    });
  }
);

yup.addMethod(yup.string, "notCareerPageLink", function () {
  return this.test(
    "not-career-page-link",
    "Error: please provide the job-specific link.",
    (value) => Boolean(
      value &&
          /(http:\/\/|https:\/\/)?(www|ww)?\.?\w+\.\w+\W+.+$/.test(
            value.toLowerCase()
          )
    )
  );
});

module.exports = yup;
