import makeStyles from "@material-ui/styles/makeStyles";

import map from "lodash/map";
import orderBy from "lodash/orderBy";
import startCase from "lodash/startCase";
import React from "react";
import { useSelector } from "react-redux";

import { getCurrentOrganization } from "../../../../../selectors/organizationSelectors";
import { formatFeeOptionString } from "../../../../../util/formatHelpers";

import Section from "./Section";

const PERCENTAGE = "percentage";

const useStyles = makeStyles(() => ({
  feeOptionRow: {
    display: "flex",
    flexDirection: "row",
  },
  feeDatumContainer: {
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom: 32,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function FeeDatum({ label, content, style = {} }) {
  const classes = useStyles();

  return (
    <div className={classes.feeDatumContainer} style={style}>
      <div
        className="static-input-label"
        style={{ paddingBottom: 6, lineHeight: "13px" }}
      >
        {label}
      </div>
      {content}
    </div>
  );
}

function FeeOptionRow({
  idx,
  hasMultiple,
  option,
  typeLabel = "Agency Fee Type",
}) {
  const { feeType, note } = option;

  const classes = useStyles();

  return (
    <div className={classes.feeOptionRow}>
      <FeeDatum
        label={typeLabel}
        content={startCase(feeType)}
        style={{ alignItems: "flex-start", paddingLeft: 0 }}
      />
      <FeeDatum label="Agency Fee" content={formatFeeOptionString(option)} />
      {note && (
        <FeeDatum
          label={hasMultiple ? `Note ${idx + 1}` : "Note"}
          content={note}
          style={{ alignItems: "flex-start" }}
        />
      )}
    </div>
  );
}

export function FeeOptionsList({ options, typeLabel }) {
  const currentOrganization = useSelector(getCurrentOrganization);

  const sortedOptions = orderBy(
    options || currentOrganization.standardizedPreferredFeeOptions || [],
    [
      (option) => (option.feeType === PERCENTAGE ? -1 : 1),
      (option) =>
        option.feeType === PERCENTAGE ? option.feePercentage : option.feeCents,
    ],
    ["asc", "asc"]
  );

  return (
    <>
      {map(sortedOptions, (option, idx) => (
        <FeeOptionRow
          key={option.id}
          idx={idx}
          option={option}
          hasMultiple={sortedOptions.length > 1}
          typeLabel={typeLabel}
        />
      ))}
    </>
  );
}

function StandardizedFees({ options }) {
  return (
    <Section title="Standardized Fees">
      <FeeOptionsList options={options} />
    </Section>
  );
}

export default StandardizedFees;
