// callback action types//
export const RECEIVE_ANNOUNCEMENT = "RECEIVE_ANNOUNCEMENT";
export const RECEIVE_ANNOUNCEMENTS = "RECEIVE_ANNOUNCEMENTS";
export const RECEIVE_POSITION_CORRESPONDENCES = "RECEIVE_POSITION_CORRESPONDENCES";
export const RECEIVE_POSITION_CORRESPONDANCE = "RECEIVE_POSITION_CORRESPONDANCE";
export const ARCHIVE_POSITION_CORRESPONDANCE = "ARCHIVE_POSITION_CORRESPONDANCE";
export const RECEIVE_POSITION_HISTORY = "RECEIVE_POSITION_HISTORY";
export const RECEIVE_POSITION_OWNER_TRANSFER_HISTORY =
  "RECEIVE_POSITION_OWNER_TRANSFER_HISTORY";
export const RECEIVE_ANNOUNCEMENT_LOADED = "RECEIVE_ANNOUNCEMENT_LOADED";
export const RECEIVE_POSITION_CORRESPONDANCE_LOADED = "RECEIVE_POSITION_CORRESPONDANCE_LOADED";
export const RECEIVE_POSITION_HISTORY_LOADED = "RECEIVE_POSITION_HISTORY_LOADED";
export const RECEIVE_POSITION_OWNER_TRANSFER_HISTORY_LOADED =
  "RECEIVE_POSITION_OWNER_TRANSFER_HISTORY_LOADED";

export const receiveAnnouncementLoaded = (jobcastId) => ({
  type: RECEIVE_ANNOUNCEMENT_LOADED,
  payload: jobcastId,
});

export const receivePositionCorrespondenceLoaded = jobcastId => ({
  type: RECEIVE_POSITION_CORRESPONDANCE_LOADED,
  payload: jobcastId
});

export const receivePositionHistoryLoaded = (jobcastId) => ({
  type: RECEIVE_POSITION_HISTORY_LOADED,
  payload: jobcastId,
});

export const receivePositionOwnerTransferHistoryLoaded = (jobcastId) => ({
  type: RECEIVE_POSITION_OWNER_TRANSFER_HISTORY_LOADED,
  payload: jobcastId,
});

// callback actions//
export const receivePositionCorrespondences = positionCorrespondences => ({
  type: RECEIVE_POSITION_CORRESPONDENCES,
  payload: positionCorrespondences
});

export const receivePositionCorrespondence = positionCorrespondence => ({
  type: RECEIVE_POSITION_CORRESPONDANCE,
  payload: positionCorrespondence
});

export const receiveAnnouncements = (announcements) => ({
  type: RECEIVE_ANNOUNCEMENTS,
  payload: announcements,
});

export const receivePositionHistory = (positionHistory) => ({
  type: RECEIVE_POSITION_HISTORY,
  payload: positionHistory,
});

export const receiveAnnouncement = (announcement) => ({
  type: RECEIVE_ANNOUNCEMENT,
  payload: announcement,
});

export const receivePositionOwnerTransferHistory = (
  positionOwnerTransferHistory
) => ({
  type: RECEIVE_POSITION_OWNER_TRANSFER_HISTORY,
  payload: positionOwnerTransferHistory,
});
