import { connect as formikConnect } from "formik";
import React, { createContext } from "react";

// use this context wrapper to thread formikBag props (ex: values, errors, handleChange, etc)
// and other form state values/functions through large forms
const FormContext = createContext({});

// wrap this HOC around descendants of FormContext to expose
// global form state values/functions to it. those values/functions
// will then be bundled in a formContext prop for that child
export const withFormContext = (Component) => formikConnect(({ formik, ...props }) => (
  <FormContext.Consumer>
    {(formContextValues) => (
      <Component
        formContext={({ ...formik, ...formContextValues })}
        {...props}
      />
    )}
  </FormContext.Consumer>
));

export function useFormContext() {
  const context = React.useContext(FormContext);
  if (context === undefined) {
    throw new Error('useFormContext must be used within FormContext');
  }
  return context;
}

export default FormContext;
