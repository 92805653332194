import { makeStyles } from "@material-ui/core";
import React, { useRef } from "react";

const useStyles = makeStyles(() => ({
  cls1: {
    fill: "#9b7408",
  },
  cls2: {
    fill: "#dcaa05",
  },
  cls3: {
    fill: "#f1d667",
  },
  cls6: {
    fill: "#f2bb05",
  },
  cls7: {
    fill: "#dbc35e",
  },
  cls8: {
    fill: "#b68d05",
  },
}));

function CommunityCoinIcon() {
  const clipPathId1 = useRef(`clippath-${Math.random().toString(36)}`).current;
  const clipPathId2 = useRef(`clippath-${Math.random().toString(36)}`).current;

  const classes = useStyles();

  return (
    <svg viewBox="0 0 200 200">
      <defs>
        <clipPath id={clipPathId1}>
          <path className={classes.cls8} d="m171.99,98.55c-.73-40.01-31.68-72.34-70.13-73.36-.62-.01-1.24-.02-1.86-.02s-1.24.01-1.86.02c-38.44,1.02-69.4,33.35-70.13,73.36-.01.48-.01.96-.01,1.44,0,41.33,32.24,74.83,72,74.83s72-33.5,72-74.83c0-.48,0-.96-.01-1.44Zm-30.05-40.02c.08.08.17.15.24.24,21,22.07,21.71,56.3,1.88,78.32-.65.72-1.33,1.43-2.02,2.12-.69.69-1.4,1.37-2.12,2.02-22.02,19.83-56.25,19.12-78.32-1.88-.09-.07-.16-.16-.24-.24-.1-.11-.21-.22-.31-.33,21.6,20.24,55.24,20.48,77.13.72.73-.65,1.43-1.33,2.13-2.02.69-.7,1.37-1.41,2.02-2.13,19.76-21.89,19.52-55.53-.72-77.13.11.1.22.21.33.31Z" />
        </clipPath>
        <clipPath id={clipPathId2}>
          <path className={classes.cls6} d="m172,97.17c0,.46,0,.92-.01,1.38-.74,39.13-32.68,70.62-71.99,70.62S28.75,137.68,28.01,98.55c-.01-.46-.01-.92-.01-1.38,0-39.14,31.24-70.99,70.14-71.98.62-.01,1.24-.02,1.86-.02s1.24.01,1.86.02c38.91.99,70.14,32.84,70.14,71.98Z" />
        </clipPath>
      </defs>
      <g>
        <path className={classes.cls8} d="m171.99,98.55c-.73-40.01-31.68-72.34-70.13-73.36-.62-.01-1.24-.02-1.86-.02s-1.24.01-1.86.02c-38.44,1.02-69.4,33.35-70.13,73.36-.01.48-.01.96-.01,1.44,0,41.33,32.24,74.83,72,74.83s72-33.5,72-74.83c0-.48,0-.96-.01-1.44Zm-30.05-40.02c.08.08.17.15.24.24,21,22.07,21.71,56.3,1.88,78.32-.65.72-1.33,1.43-2.02,2.12-.69.69-1.4,1.37-2.12,2.02-22.02,19.83-56.25,19.12-78.32-1.88-.09-.07-.16-.16-.24-.24-.1-.11-.21-.22-.31-.33,21.6,20.24,55.24,20.48,77.13.72.73-.65,1.43-1.33,2.13-2.02.69-.7,1.37-1.41,2.02-2.13,19.76-21.89,19.52-55.53-.72-77.13.11.1.22.21.33.31Z" />
        <g style={{ clipPath: `url(#${clipPathId1})` }}>
          <polyline className={classes.cls7} points="100 169.17 80.14 166.4 80.14 173.87 100 176.04 100 169.17 80.14 166.4" />
          <polyline className={classes.cls7} points="71.64 163.38 65.42 160.34 65.42 167.93 71.64 168.8 71.64 163.38" />
          <polyline className={classes.cls7} points="46.05 144.85 41.76 139.51 41.76 146.1 46.05 150.39 46.05 144.85" />
          <polyline className={classes.cls7} points="36.27 130.72 30.63 116.54 30.63 124.78 36.27 135.63 36.27 130.72" />
          <polyline className={classes.cls7} points="28.29 103.67 29.82 113.35 29.82 118.34 28.29 109.18 28.29 103.67" />
        </g>
      </g>
      <g>
        <path className={classes.cls6} d="m172,97.17c0,.46,0,.92-.01,1.38-.74,39.13-32.68,70.62-71.99,70.62S28.75,137.68,28.01,98.55c-.01-.46-.01-.92-.01-1.38,0-39.14,31.24-70.99,70.14-71.98.62-.01,1.24-.02,1.86-.02s1.24.01,1.86.02c38.91.99,70.14,32.84,70.14,71.98Z" />
        <g style={{ clipPath: `url(#${clipPathId2})` }}>
          <rect className={classes.cls3} x="54.51" y="-32.96" width="7.41" height="213.52" transform="translate(61.11 -20.15) rotate(40.03)" />
          <rect className={classes.cls3} x="63.5" y="-26.91" width="13.44" height="213.52" transform="translate(67.82 -26.46) rotate(40.03)" />
          <rect className={classes.cls3} x="83.84" y="-16.12" width="6.72" height="213.52" transform="translate(78.74 -34.85) rotate(40.03)" />
          <rect className={classes.cls3} x="104.78" y="7.95" width="6.72" height="213.52" transform="translate(99.12 -42.68) rotate(40.03)" />
          <rect className={classes.cls3} x="114.15" y="22.25" width="17.01" height="213.52" transform="translate(111.73 -48.66) rotate(40.03)" />
        </g>
      </g>
      <path className={classes.cls8} d="m140.31,56.87c.27.27.54.54.79.82-21.34-20.24-55.94-19.11-77.82,2.76-21.88,21.88-23,56.48-2.76,77.82-.28-.25-.55-.52-.82-.79-22.22-22.22-22.22-58.39,0-80.61,22.22-22.22,58.39-22.22,80.61,0Z" />
      <path className={classes.cls2} d="m142.33,135.36c-.65.72-1.33,1.43-2.02,2.13-.7.69-1.4,1.37-2.13,2.02-21.89,19.76-55.53,19.52-77.13-.72-.1-.09-.2-.19-.3-.28-.09-.07-.16-.16-.23-.23-18.92-19.94-19.18-51.47-1.26-73.37.33-.4.66-.8,1-1.19.95-1.11,1.96-2.2,3.02-3.26s2.15-2.07,3.26-3.02c.39-.34.79-.67,1.19-1,21.9-17.92,53.43-17.66,73.37,1.26.07.07.16.14.23.23.09.1.19.2.28.3,20.24,21.6,20.48,55.24.72,77.13Z" />
      <path className={classes.cls3} d="m144.06,137.1c-.65.72-1.33,1.43-2.02,2.12-.69.69-1.4,1.37-2.12,2.02-22.02,19.83-56.25,19.12-78.32-1.88-.09-.07-.16-.16-.24-.24-.1-.11-.21-.22-.31-.33,21.6,20.24,55.24,20.48,77.13.72.73-.65,1.43-1.33,2.13-2.02.69-.7,1.37-1.41,2.02-2.13,19.76-21.89,19.52-55.53-.72-77.13.11.1.22.21.33.31.08.08.17.15.24.24,21,22.07,21.71,56.3,1.88,78.32Z" />
      <g>
        <polygon className={classes.cls1} points="80.49 125.7 80.49 127.72 72.37 135.84 64.24 127.72 64.24 125.7 72.37 133.83 80.49 125.7" />
        <polygon className={classes.cls1} points="109.16 116.14 109.16 118.16 101.04 126.28 92.91 118.16 92.91 116.14 101.04 124.27 105.1 120.21 109.16 116.14" />
        <polygon className={classes.cls1} points="80.49 87.47 80.49 89.49 76.43 93.55 72.36 97.61 64.24 89.49 64.24 87.47 72.36 95.59 76.43 91.53 80.49 87.47" />
        <polygon className={classes.cls1} points="90.05 77.91 90.05 79.93 85.98 83.99 81.92 88.05 73.8 79.93 73.8 77.91 81.92 86.04 85.98 81.97 90.05 77.91" />
        <polygon className={classes.cls1} points="80.49 106.59 80.49 108.6 76.43 112.66 72.36 116.72 64.24 108.6 64.24 106.59 72.36 114.71 76.43 110.65 80.49 106.59" />
        <polygon className={classes.cls1} points="90.05 97.03 90.05 99.04 85.98 103.1 81.92 107.17 73.8 99.04 73.8 97.03 81.92 105.15 85.98 101.09 90.05 97.03" />
        <polygon className={classes.cls3} points="109.16 77.91 101.04 86.04 92.91 77.91 96.98 73.85 101.04 69.79 109.16 77.91" />
        <polygon className={classes.cls1} points="109.16 77.91 109.16 79.93 102.05 87.05 101.04 88.05 92.91 79.93 92.91 77.91 101.04 86.04 109.16 77.91" />
        <polygon className={classes.cls1} points="90.05 116.14 90.05 118.16 85.98 122.22 81.92 126.28 73.8 118.16 73.8 116.14 81.92 124.27 90.05 116.14" />
        <polygon className={classes.cls1} points="99.6 106.59 99.6 108.6 92.49 115.72 91.48 116.72 83.36 108.6 83.36 106.58 91.48 114.71 99.6 106.59" />
        <polygon className={classes.cls1} points="118.72 87.47 118.72 89.49 110.6 97.61 106.53 93.55 102.47 89.49 102.47 87.47 106.53 91.53 110.6 95.59 118.72 87.47" />
        <polygon className={classes.cls1} points="128.28 77.91 128.28 79.93 120.15 88.05 112.03 79.93 112.03 77.91 120.15 86.04 128.28 77.91" />
        <polygon className={classes.cls1} points="128.28 97.03 128.28 99.04 121.16 106.17 120.15 107.17 112.03 99.04 112.03 97.03 120.15 105.15 128.28 97.03" />
        <polygon className={classes.cls1} points="128.28 116.14 128.28 118.16 120.15 126.28 112.03 118.16 112.03 116.14 116.09 120.21 120.15 124.27 128.28 116.14" />
        <polygon className={classes.cls1} points="137.83 68.35 137.83 70.37 129.71 78.49 121.59 70.37 121.59 68.36 129.71 76.47 137.83 68.35" />
        <polygon className={classes.cls1} points="99.61 125.7 99.61 127.72 91.48 135.84 83.36 127.72 83.36 125.7 91.48 133.83 99.61 125.7" />
        <polygon className={classes.cls1} points="118.72 125.7 118.72 127.72 110.59 135.84 102.47 127.72 102.47 125.7 110.59 133.83 118.72 125.7" />
        <polygon className={classes.cls1} points="137.83 106.59 137.83 108.6 129.71 116.73 121.58 108.6 121.58 106.59 129.71 114.71 137.83 106.59" />
        <polygon className={classes.cls1} points="137.84 125.7 137.84 127.72 129.71 135.84 121.59 127.72 121.59 125.7 129.71 133.83 137.84 125.7" />
        <polygon className={classes.cls1} points="80.49 68.35 80.49 70.37 72.36 78.49 64.24 70.37 64.24 68.35 72.36 76.48 80.49 68.35" />
        <polygon className={classes.cls1} points="99.6 68.36 99.6 70.37 91.48 78.49 83.36 70.37 83.36 68.35 91.48 76.48 99.6 68.36" />
        <polygon className={classes.cls1} points="118.71 68.36 118.71 70.37 110.59 78.5 102.47 70.37 102.47 68.36 110.59 76.48 118.71 68.36" />
        <polygon className={classes.cls1} points="118.72 106.59 118.72 108.6 110.59 116.72 102.47 108.6 102.47 106.59 110.59 114.71 118.72 106.59" />
        <polygon className={classes.cls1} points="137.84 87.47 137.84 89.48 129.72 97.61 121.59 89.48 121.59 87.47 129.72 95.59 137.84 87.47" />
        <polygon className={classes.cls1} points="99.6 87.47 99.6 89.49 91.48 97.61 90.48 96.61 83.36 89.49 83.36 87.47 91.48 95.59 99.6 87.47" />
      </g>
      <g>
        <polygon className={classes.cls3} points="109.16 116.14 105.1 120.21 101.04 124.27 92.91 116.14 96.98 112.08 101.04 108.02 109.16 116.14" />
        <polygon className={classes.cls3} points="80.49 87.47 76.43 91.53 72.36 95.59 64.24 87.47 72.36 79.35 80.49 87.47" />
        <polygon className={classes.cls3} points="90.05 77.91 85.98 81.97 81.92 86.04 73.8 77.91 81.92 69.79 90.05 77.91" />
        <polygon className={classes.cls3} points="80.49 106.59 76.43 110.65 72.36 114.71 64.24 106.59 72.36 98.46 80.49 106.59" />
        <polygon className={classes.cls3} points="90.05 97.03 85.98 101.09 81.92 105.15 73.8 97.03 81.92 88.9 90.05 97.03" />
        <rect className={classes.cls3} x="85.74" y="81.73" width="11.48" height="11.48" transform="translate(-35.06 90.31) rotate(-45)" />
        <polygon className={classes.cls3} points="90.05 116.14 81.92 124.27 73.8 116.14 81.92 108.02 90.05 116.14" />
        <polygon className={classes.cls3} points="99.6 106.58 99.6 106.59 91.48 114.71 83.36 106.58 90.47 99.47 90.48 99.47 91.48 98.46 99.6 106.58" />
        <polygon className={classes.cls3} points="118.72 87.47 110.6 95.59 106.53 91.53 102.47 87.47 110.6 79.35 118.72 87.47" />
        <polygon className={classes.cls3} points="128.28 77.91 120.15 86.04 112.03 77.91 120.15 69.79 128.28 77.91" />
        <polygon className={classes.cls3} points="118.72 106.59 110.59 114.71 102.47 106.59 106.53 102.52 110.6 98.46 116.81 104.67 118.72 106.59" />
        <polygon className={classes.cls3} points="128.28 97.03 120.15 105.15 112.03 97.03 119.14 89.91 120.15 88.9 124.21 92.97 128.28 97.03" />
        <polygon className={classes.cls3} points="128.28 116.14 120.15 124.27 116.09 120.21 112.03 116.14 120.15 108.02 124.21 112.08 128.28 116.14" />
        <polygon className={classes.cls3} points="80.49 125.7 72.37 133.83 64.24 125.7 72.37 117.58 80.49 125.7" />
        <polygon className={classes.cls3} points="137.83 68.35 129.71 76.47 121.59 68.36 121.59 68.35 129.71 60.23 137.83 68.35" />
        <polygon className={classes.cls3} points="99.61 125.7 91.48 133.83 83.36 125.7 91.48 117.58 99.61 125.7" />
        <polygon className={classes.cls3} points="137.84 87.47 129.72 95.59 121.59 87.47 129.72 79.34 137.84 87.47" />
        <polygon className={classes.cls3} points="118.72 125.7 110.59 133.83 102.47 125.7 110.59 117.58 118.72 125.7" />
        <polygon className={classes.cls3} points="137.83 106.59 129.71 114.71 121.58 106.59 129.71 98.46 137.83 106.59" />
        <polygon className={classes.cls3} points="137.84 125.7 129.71 133.83 121.59 125.7 129.71 117.58 137.84 125.7" />
        <polygon className={classes.cls3} points="80.49 68.35 72.36 76.48 64.24 68.35 72.36 60.23 80.49 68.35" />
        <polygon className={classes.cls3} points="99.6 68.35 99.6 68.36 91.48 76.48 83.36 68.35 91.48 60.23 99.6 68.35" />
        <rect className={classes.cls3} x="104.85" y="62.62" width="11.49" height="11.49" transform="translate(-15.95 98.22) rotate(-45)" />
      </g>
    </svg>
  );
}

export default CommunityCoinIcon;
