import makeStyles from "@material-ui/core/styles/makeStyles";
import classnames from "classnames";
import React from "react";

const useStyles = makeStyles(() => ({
  organizationLogo: {
    textAlign: "right",
    maxWidth: 180,
    fontWeight: 600,
  },
}));

const OrganizationLogo = ({ publicRecruiterSubmission }) => {
  const { employer_organization, organization_avatar_xs } =
    publicRecruiterSubmission;

  const classes = useStyles();

  if (organization_avatar_xs) {
    return (
      <img alt={`${employer_organization}`} src={organization_avatar_xs} />
    );
  }

  return (
    <h4 className={classnames("subtitle-1", classes.organizationLogo)}>
      {employer_organization}
    </h4>
  );
};

export default OrganizationLogo;
