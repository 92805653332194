import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { openModal } from "../../../../../actions/shared/uiActions";

import Button from "../../../../forms/custom/Button";
import ArchiveQuestionModal from "../../../JobCasts/view/MessageBoard/ArchiveQuestionModal";

import { PUBLIC, PRIVATE, REPLY_ICONS } from "./constants.js";
import ReplyForm from "./ReplyForm";

const styles = () => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center"
  }
});

export class QuestionActions extends Component {
  constructor(props) {
    super(props);

    this.state = { showReplyForm: false };
  }

  handleReplyClick = type => async () => {
    this.setState({ showReplyForm: true, selectedReplyType: type });
  };

  handleFormClose = () => {
    this.setState({ showReplyForm: false });
  };

  mountArchiveQuestionModal = () => {
    const { openModal, questionId, jobcast } = this.props;
    const modalProps = {
      questionId,
      jobcast
    };

    openModal(<ArchiveQuestionModal {...modalProps} />);
  };

  renderActions() {
    const { classes } = this.props;

    return (
      <div className={classes.buttonContainer}>
        <Button
          icon={REPLY_ICONS[PUBLIC].icon}
          color="blue"
          variant="primary"
          style={{ marginRight: 20 }}
          onClick={this.handleReplyClick(PUBLIC)}
        >
          Public Reply
        </Button>
        <Button
          icon={REPLY_ICONS[PRIVATE].icon}
          color="blue"
          variant="secondary"
          style={{ marginRight: 10 }}
          onClick={this.handleReplyClick(PRIVATE)}
        >
          Private Reply
        </Button>
        <Tooltip
          id="tooltip-icon"
          title={<span style={{ fontSize: "12px" }}>Archive</span>}
        >
          <IconButton onClick={() => this.mountArchiveQuestionModal()}>
            <Icon style={{ color: "#FF6D6D", fontSize: 30, cursor: "pointer" }}>
              archive
            </Icon>
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  renderForm() {
    const { questionId, questionSubjectType } = this.props;

    return (
      <ReplyForm
        questionId={questionId}
        questionSubjectType={questionSubjectType}
        type={this.state.selectedReplyType}
        handleClose={this.handleFormClose}
      />
    );
  }

  render() {
    return this.state.showReplyForm ? this.renderForm() : this.renderActions();
  }
}

QuestionActions.propTypes = {
  questionId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  openModal: content => dispatch(openModal(content))
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(QuestionActions));
