import merge from "lodash/merge";

import {
  RECEIVE_CERTIFICATION,
  RECEIVE_CERTIFICATIONS,
} from "../actions/recruiter/certificationActions";

const defaultState = {
  loaded: false,
  items: {},
};

const certificationReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_CERTIFICATIONS:
      return merge({}, state, { loaded: true, items: action.payload });
    case RECEIVE_CERTIFICATION:
      return merge({}, state, {
        items: { [action.payload.id]: action.payload },
      });
    default:
      return state;
  }
};

export default certificationReducer;
