import React, { Component } from "react";
import { connect } from "react-redux";

import { setNotificationsDrawerVisible } from "../../actions/shared/uiActions";

import NotificationsCenter from "../shared/NotificationsDrawer/NotificationsCenter";

class NotificationsLeftDrawer extends Component {
  render() {
    const { visible, content } = this.props;

    if (visible) {
      return content || <NotificationsCenter />;
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  visible: state.ui.leftDrawer.notificationsVisible,
  content: state.ui.leftDrawer.notificationsContent,
});

const mapDispatchToProps = (dispatch) => ({
  setNotificationsDrawerNotVisible: () => dispatch(setNotificationsDrawerVisible(false)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsLeftDrawer);
