// col keys
export const DATE_COL = "dateCol";
export const JOBCAST_COL = "jobcastCol";
export const CANDIDATE_COL = "candidateCol";
export const ACTION_COL = "actionCol";

export const COL_KEYS = [DATE_COL, JOBCAST_COL, CANDIDATE_COL, ACTION_COL];

// sort dirs
export const ASC = "asc";
export const DESC = "desc";
