import React from "react";

import { useDispatch } from "react-redux";

import { openModal } from "../../../../actions/shared/uiActions";
import Button from "../../../forms/custom/Button";

import DeleteCandidateModal from "./modals/DeleteCandidateModal";

function DeleteCandidateButton({ candidate }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openModal(<DeleteCandidateModal candidate={candidate} />));
  };

  return (
    <Button
      color="red"
      variant="primary"
      style={{ width: 130, marginRight: 10 }}
      onClick={handleClick}
      data-cy="delete-candidate"
    >
      Delete
    </Button>
  );
}

export default DeleteCandidateButton;
