import normalize from "json-api-normalizer";

import queryString from "query-string";

import { GET, PATCH } from "../../util/apiHelpers";

import { mapKeysToSnakeCase } from "../../util/formatHelpers";
import { receiveIndividuals } from "../shared/individualActions";

export const fetchTeammates = (params) => (dispatch) => {
  const queryParams = params
    ? `?${queryString.stringify(mapKeysToSnakeCase(params))}`
    : "";

  return GET(`/api/v3/recruiter/teammates${queryParams}`).then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveIndividuals(normalized.teammate));
  });
};

export const updateTeammatePermissions = (data) => (dispatch) => PATCH(
  `/api/v3/recruiter/teammates/${data.id}/update_permissions`,
  data
).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receiveIndividuals(normalized.teammate));
});

export const deactivateTeammate = (id) => (dispatch) => PATCH(`/api/v3/recruiter/teammates/${id}/deactivate`).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receiveIndividuals(normalized.teammate));
});

export const reactivateTeammate = (id) => (dispatch) => PATCH(`/api/v3/recruiter/teammates/${id}/reactivate`).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receiveIndividuals(normalized.teammate));
});

export const assignTeammatesToJobcast = (data) => (dispatch) => PATCH(
  `/api/v3/recruiter/teammates/assign_position`,
  mapKeysToSnakeCase(data)
).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receiveIndividuals(normalized.teammate));
});
