import normalize from "json-api-normalizer";

import { GET, POST } from "../../util/apiHelpers";
import { mapKeysToSnakeCase } from "../../util/formatHelpers";
import { receiveRequests } from "../shared/requestActions";
import { receiveReviews } from "../shared/reviewActions";

import { receiveFeedbackRating } from "./candidateActions";

export const fetchReviews = (recipientId) => {
  const queryStr = recipientId ? `?recipientId=${recipientId}` : "";

  return (dispatch) =>
    GET(`/api/v3/recruiter/reviews${queryStr}`)
      .then((res) => {
        const normalized = normalize(res.data);
        dispatch(receiveReviews(normalized.review));
      })
      .catch((error) => Promise.reject(error.response.data));
};

export const createJobcastReview = (data) => (dispatch) =>
  POST(
    "/api/v3/recruiter/reviews/create_jobcast_review",
    mapKeysToSnakeCase(data)
  )
    .then((res) => {
      const normalized = normalize(res.data);
      dispatch(receiveRequests(normalized.request));
    })
    .catch((error) => Promise.reject(error.response.data));

export const createFeedbackReview = (data) => (dispatch) =>
  POST(
    "/api/v3/recruiter/reviews/create_feedback_review",
    mapKeysToSnakeCase(data)
  )
    .then(() => {
      dispatch(
        receiveFeedbackRating({
          candidateId: data.candidateId,
          stageEventId: data.subjectId.toString(),
        })
      );
    })
    .catch((error) => Promise.reject(error.response.data));
