import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import merge from "lodash/merge";
import unset from "lodash/unset";

import { RECEIVE_RATING, REMOVE_CANDIDATE } from "../actions/recruiters/candidateActions";
import {
  RECEIVE_CANDIDATES,
  RECEIVE_CANDIDATE,
  SET_CANDIDATES_INDEX_LOADED,
  CLEAR_CANDIDATES,
} from "../actions/shared/candidateActions";
import { PERM, TEMP } from "../selectors/jobcastSelectors";
import { mergeWithArrayOverwrite } from "../util/formatHelpers";

const _defaultState = {
  items: {},
  loaded: false,
  indexTypesLoaded: { [PERM]: {}, [TEMP]: {} },
};

export const candidateReducer = (state = _defaultState, action) => {
  Object.freeze(state);
  let newItems;

  switch (action.type) {
    case RECEIVE_CANDIDATES:
      return mergeWithArrayOverwrite({}, state, {
        items: action.payload,
        loaded: true,
      });
    case RECEIVE_RATING:
      newItems = cloneDeep(state.items);
      const stageEvent = find(
        newItems[action.payload.candidateId].attributes.stageEvents,
        (event) => event.id === action.payload.stageEventId
      );
      stageEvent.attributes.rejectionFeedback = true;

      return { ...state, items: newItems };
    case RECEIVE_CANDIDATE:
      newItems = cloneDeep(state.items);
      newItems[action.payload.id] = merge({}, action.payload, { loaded: true });
      return { ...state, items: newItems };
    case SET_CANDIDATES_INDEX_LOADED:
      const { indexType, positionType, customKey } = action.payload;

      return merge({}, state, {
        indexTypesLoaded: customKey
          ? { [customKey]: true }
          : { [positionType]: { [indexType]: true } },
      });
    case CLEAR_CANDIDATES:
      return _defaultState;
    case REMOVE_CANDIDATE:
      const toRemoveId = action.payload;
      const newState = cloneDeep(state);

      unset(newState, ["items", toRemoveId]);

      return newState;
    default:
      return state;
  }
};
