export const ACTIVE_STATUS = "active";
export const UNCONFIRMED_STATUS = "unconfirmed";
export const DECLINED_STATUS = "declined";
export const REJECTED_STATUS = "rejected";
export const WITHDRAWN_STATUS = "withdrawn";
export const HIRED_STATUS = "hired";
export const AWAITING_SORT_STATUS = "awaiting_sort";
export const REVIEWING_STATUS = "reviewing";
export const INTERVIEWING_STATUS = "interviewing";
export const OFFER_STATUS = "offer";
export const HIRED_FAILED_STATUS = "hired_failed";
export const CONTRACT_ENDED = "contract_ended";
export const CONTRACT_ACTIVE = "contract_active";

// position statuses
export const ARCHIVED_STATUS = "archived";
export const OPEN_STATUS = "open";
