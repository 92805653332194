import merge from "lodash/merge";
import React from "react";
import ReactQuill, { Quill } from "react-quill";

import ProvideCharacterCounter from "./ProvideCharacterCounter";

const Link = Quill.import("formats/link");

class CustomLink extends Link {
  static create(url) {
    const node = super.create(url);
    url = this.sanitize(url);
    node.setAttribute("target", "_blank");

    return node;
  }

  static sanitize(url) {
    const value = super.sanitize(url);
    if (value) {
      for (let i = 0; i < CustomLink.PROTOCOL_WHITELIST.length; i++) { if (value.startsWith(CustomLink.PROTOCOL_WHITELIST[i])) return value; }

      return `http://${value}`;
    }
    return value;
  }
}

Quill.register("formats/link", CustomLink);

class WysiwygEditor extends React.Component {
  constructor(props) {
    super(props);
    this.quillRef = null; // Quill instance
    this.reactQuillRef = null; // ReactQuill component

    this.state = {
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" }
          ],
          ["link", "image"],
          ["clean"],
          [{ color: [] }],
          [{ align: [] }]
        ]
      },

      formats: [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "clean",
        "color",
        "align"
      ]
    };

    this.handleChange = this.handleChange.bind(this);
  }

  attachQuillRefs = () => {
    if (typeof this.reactQuillRef.getEditor !== "function") return;
    this.quillRef = this.reactQuillRef.getEditor();
  };

  componentDidMount() {
    this.attachQuillRefs();
    this.handleChange(this.props.value, { keepErrors: true });
  }

  componentDidUpdate() {
    this.attachQuillRefs();
  }

  handleChange(value, options) {
    if (this.props.disabled) {
      return () => null;
    }
    this.props.onChange(value, this.quillRef, options);
  }

  maxCharInput() {
    return this.props.maxLength || 5000;
  }

  render() {
    const modules = merge(this.state.modules, {
      toolbar: !this.props.disabled
    });
    return (
      <div style={this.props.style}>
        {this.props.label && (
          <div
            className="static-input-label"
            style={this.props.error ? { color: "#FF6D6D" } : {}}
          >
            {this.props.label}
          </div>
        )}
        <ProvideCharacterCounter
          visible={!this.props.disabled}
          error={this.props.error}
          compareCount={this.maxCharInput()}
          helperText={this.props.helperText}
          wysiwyg
          rawText={this.props.rawText}
          width="calc(100% - 56px)"
        >
          <ReactQuill
            ref={el => {
              this.reactQuillRef = el;
            }}
            id={this.props.id}
            value={this.props.value}
            onChange={this.handleChange}
            onBlur={this.props.onBlur}
            modules={modules}
            formats={this.state.formats}
            className={this.props.error ? "error-style" : ""}
            readOnly={this.props.disabled}
            style={{ marginBottom: "8px", paddingBottom: "8px" }}
          />
        </ProvideCharacterCounter>
      </div>
    );
  }
}

WysiwygEditor.defaultProps = {
  onBlur: () => {}
};

export default WysiwygEditor;
