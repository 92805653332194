import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";

export const styles = theme => ({
  actionContainer: {
    display: "flex",
    flexDirection: "row",
    height: "80px",
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("1024")]: {
      width: "calc(100% - 4px)",
      minWidth: "552px",
      paddingBottom: "10px",
      marginTop: "-12px",
      justifyContent: "space-evenly"
    }
  },
  smallActionContainer: {
    width: "260px"
  },
  wideActionContainer: {
    width: "320px"
  }
});

class ActionContainer extends React.PureComponent {
  render() {
    const {
      classes,
      children,
      overrideStyle,
      wideActionContainer
    } = this.props;
    const oneButton = React.Children.count(children) === 1;

    return (
      <div
        className={classnames(
          "candidate-index-action-container-ie",
          classes.actionContainer,
          wideActionContainer
            ? classes.wideActionContainer
            : classes.smallActionContainer
        )}
        style={overrideStyle || {}}
      >
        {React.Children.map(children, child => React.cloneElement(child, {
          overloadedClasses: [
            oneButton ? "" :
              wideActionContainer
                ? "candidateActionButtonSmall"
                : "candidateActionButton"
          ]
        }))}
      </div>
    );
  }
}

export default withStyles(styles)(ActionContainer);
