import React from "react";
import { useDispatch } from "react-redux";

import {
  openModal,
  updateAnimation,
} from "../../../../../../../../actions/shared/uiActions";

import Button from "../../../../../../../forms/custom/Button";

import CandidateCardActionContainer from "../../../../../../../shared/JobCast/Candidates/CandidateCard/Actions/ActionContainer";
import CandidateCard from "../../../../../../../shared/JobCast/Candidates/CandidateCard/CandidateCard";
import CandidateCardNavContainer from "../../../../../../../shared/JobCast/Candidates/CandidateCard/Navigation/NavigationContainer";
import RejectButton from "../../../../../../JobCasts/view/Candidates/actions/modalMountCTAs/RejectButton";
import ShareButton from "../../../../../../JobCasts/view/Candidates/actions/modalMountCTAs/ShareButton";

import {
  AGENCY_CHAT,
  VIEW_RESUME,
  VIEW_RESPONSES,
} from "../../../../../../JobCasts/view/Candidates/constants";
import CandidateCardNav from "../../../../../../JobCasts/view/Candidates/index/item/CandidateCardNav";
import CandidateCardProfile from "../../../../../../JobCasts/view/Candidates/index/item/CandidateCardProfile";
import { ARCHIVED } from "../../../../constants";
import AdvanceToPreAssignmentModal from "../../../actions/modals/AdvanceToPreAssignmentModal/AdvanceToPreAssignmentModal";

export function MakeOfferButton({
  candidate,
  overrideStyle,
  overloadedClasses,
}) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      updateAnimation(`candidate-card-${candidate.id}`, {
        classes: { exit: "bounceOutRight" },
      })
    );
    dispatch(
      openModal(<AdvanceToPreAssignmentModal candidateId={candidate.id} />)
    );
  };

  return (
    <Button
      color="blue"
      variant="primary"
      onClick={handleClick}
      style={overrideStyle || {}}
      overloadedClasses={overloadedClasses}
      data-cy="advance-button"
    >
      Make Offer
    </Button>
  );
}

class InterviewingCard extends React.PureComponent {
  render() {
    const { candidate, jobcastStatus } = this.props;

    return (
      <CandidateCard key={candidate.id} wideActionContainer>
        <CandidateCardProfile candidate={candidate} />
        <CandidateCardNavContainer
          candidate={candidate}
          overrideStyle={{ width: "calc(100% - 336px - 320px)" }}
        >
          <CandidateCardNav
            navSelection={jobcastStatus !== ARCHIVED && AGENCY_CHAT}
          />
          <CandidateCardNav navSelection={VIEW_RESUME} />
          <CandidateCardNav navSelection={VIEW_RESPONSES} />
        </CandidateCardNavContainer>
        <CandidateCardActionContainer overrideStyle={{ width: 320 }}>
          <ShareButton candidate={candidate} />
          <RejectButton candidate={candidate} />
          <MakeOfferButton candidate={candidate} />
        </CandidateCardActionContainer>
      </CandidateCard>
    );
  }
}

export default InterviewingCard;
