import merge from "lodash/merge";

import {
  RECEIVE_REQUESTS,
  SET_REQUESTS_INDEX_LOADED,
  CLEAR_REQUESTS,
} from "../actions/shared/requestActions.js";
import {
  ARCHIVED,
  PERM,
  TEMP,
  UNARCHIVED,
} from "../selectors/recruiter/jobcastSelectors.js";

const defaultState = {
  items: {},
  indexTypesLoaded: {
    [PERM]: {
      [ARCHIVED]: false,
      [UNARCHIVED]: false,
    },
    [TEMP]: {
      [ARCHIVED]: false,
      [UNARCHIVED]: false,
    },
  },
};

export const requestReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_REQUESTS:
      return merge({}, state, { items: action.payload });
    case SET_REQUESTS_INDEX_LOADED:
      const { indexType, positionType, isLoaded } = action.payload;

      return merge({}, state, {
        indexTypesLoaded: { [positionType]: { [indexType]: isLoaded } },
      });
    case CLEAR_REQUESTS:
      return defaultState;
    default:
      return state;
  }
};
