import round from "lodash/round";
import moment from "moment";
import React from "react";

import { formatCurrency } from "../../../../../../util/formatHelpers";

import MultiColStyledField from "./MultiColStyledField";
import usePlacementContext from "./usePlacementContext";

export const formatDate = (date) => moment(date).format("MMM D, YYYY");

export function PayoutConversionRate() {
  const {
    payoutConversionCurrency, payoutConversionRate, payoutCompletedAt
  } = usePlacementContext();

  const payoutCurrency = payoutConversionCurrency || "USD";

  if (payoutCurrency === "USD") return null;

  const conversion = `$1.00 USD : ${
    formatCurrency(round(payoutConversionRate, 2) * 100, payoutCurrency, { salaryConversion: true })
  } ${payoutCurrency}
  `;

  const tooltip = `$1.00 USD = ${
    formatCurrency(payoutConversionRate * 100, payoutCurrency, { salaryConversion: true })
  } ${payoutCurrency} on ${formatDate(payoutCompletedAt)}`;

  return (
    <MultiColStyledField
      label="Conversion Rate:"
      tooltip={tooltip}
      value={conversion}
      underline
    />
  );
}

export function EarningsConversionRate() {
  const { salaryCurrency, usdConversion, createdAt } = usePlacementContext();

  if (salaryCurrency === "USD") return null;

  const conversion =
  `${
    formatCurrency(100, salaryCurrency, { salaryConversion: true })
  } ${salaryCurrency} : ${(
    formatCurrency(round(usdConversion, 2) * 100, "USD")
  )} USD`;

  const tooltip =
  `${
    formatCurrency(100, salaryCurrency, { salaryConversion: true, })
  } ${salaryCurrency} = $${usdConversion} USD on ${formatDate(
    createdAt
  )}`;

  return (
    <MultiColStyledField
      label="Conversion Rate:"
      tooltip={tooltip}
      value={conversion}
      underline
    />
  );
}
