import map from "lodash/map";
import React from "react";

import EmployerSearchResultItem from "../../employer/Search/SearchResultsItem";
import RecruiterSearchResultItem from "../../recruiter/Search/SearchResultItem";

function SearchResultItems({ items, exitSearch, currentIndividual }) {
  return (
    <>
      {currentIndividual.employer
        ? map(items, (item, idx) => (
          <EmployerSearchResultItem
            key={`search-result-${idx}`}
            item={item}
            exitSearch={exitSearch}
          />
        ))
        : map(items, (item, idx) => (
          <RecruiterSearchResultItem
            key={`search-result-${idx}`}
            item={item}
            exitSearch={exitSearch}
          />
        ))}
    </>
  );
}

export default SearchResultItems;
