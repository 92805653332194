import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";
import React from "react";

const styles = (theme) => ({
  container: {
    marginLeft: 0,
    alignItems: "center",
    marginBottom: 12,
    minHeight: "80px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    width: "calc(100% - 4px)",
    flexWrap: "wrap",
    boxShadow: "1px 2px 12px #B0BEC5",
    maxWidth: "950px",
  },
  smallActionContainer: {
    [theme.breakpoints.down("1024")]: {
      minHeight: "150px",
      width: "calc(100% - 4px - 260px)",
      minWidth: "552px",
    },
  },
  wideActionContainer: {
    [theme.breakpoints.down("1024")]: {
      minHeight: "150px",
      width: "calc(100% - 4px - 320px)",
      minWidth: "552px",
    },
  },
  borderLine: {
    borderLeft: "4px solid #008dae",
    "&$blue": {
      borderLeft: "4px solid #008dae",
    },
    "&$red": {
      borderLeft: "4px solid #FF6D6D",
      color: "red",
    },
    "&$grey": {
      borderLeft: "4px solid #90A4AE",
    },
  },
  blue: {},
  red: {},
  grey: {},
});

class CandidateCard extends React.PureComponent {
  // if there are no children in the CandidateCardActionContainer
  // we do not add an extra row of space for the card
  determineResponsiveSize() {
    const { children } = this.props;

    if (Array.isArray(children) && children.length === 3) {
      const child = children[2];

      return isEmpty(child.props) ||
        !Boolean(child.props.children) ||
        Boolean(child.props.children.type === React.Fragment)
        ? {
          minHeight: 80,
          maxHeight: 80,
        }
        : {};
    }
  }

  render() {
    const {
      classes,
      wideActionContainer,
      borderLineColor,
      overrideCardStyle = {},
    } = this.props;

    return (
      <Card
        style={merge({}, this.determineResponsiveSize(), overrideCardStyle)}
        className={classnames(
          classes.container,
          classes.borderLine,
          { [classes[borderLineColor]]: true },
          wideActionContainer
            ? classes.wideActionContainer
            : classes.smallActionContainer
        )}
        data-cy="candidate-card"
      >
        {React.Children.map(this.props.children, (child) => child && React.cloneElement(child, {
          wideActionContainer,
        }))}
      </Card>
    );
  }
}

export default withStyles(styles)(CandidateCard);
