// jobcast types
export const MULTIPLE_LIVE = "multiple_live";
export const LIVE = "live";
export const OPEN = "open";
export const STALE = "stale";
export const LOCKED = "locked";
export const AUTO_LOCKED = "auto_locked";
export const SUBMITTED = "submitted";
export const DRAFTS = "drafts";
export const ARCHIVED = "archived";
export const CLOSED = "closed";
export const UNSTARTED = "unstarted";
export const PRIORITY_BONUS = "priority_bonus";
export const OPEN_CONTRACT = "open_contract";

// early_close_reason type
export const FILLED_THROUGH_RECRUITIFI =
  "Filled successfully through RecruitiFi";
export const FILLED_INTERNALLY = "Filled through internal sourcing efforts";
export const CANCELED_INTERNALLY = "Canceled for internal reasons";
export const TEMPORARILY_PAUSE = "Temporarily pause submissions";

// stage types
export const DATA = "data";
export const ACTION = "action";

// data stages
export const NEW = "new";
export const REVIEWING = "reviewing";
export const INTERVIEWING = "interviewing";
export const OFFERS = "offers";
export const HIRES = "hires";
export const PRESENTED = "candidates";

// action stages
export const CLOSE = "close";
export const CHANGE_OWNER = "change_owner";
export const DUPLICATE = "duplicate";
export const EDIT = "edit";
export const REOPEN = "reopen";
export const DELETE = "delete";
export const MAKE_ANNOUNCEMENT = "make_annoucement";
export const LOCK = "lock";
export const UNLOCK = "unlock";
export const UPDATE_LOCK_REASON = "update_lock_reason";
export const SHARE_CANDIDATES = "share_candidates";
export const ADD_AGENCIES = "add_agencies";
export const SAVE_AS_TEMPLATE = "save_as_template";
export const ADD_PRIORITY_BONUS = "add_priority_bonus";
export const UPDATE_PRIORITY_BONUS = "update_priority_bonus";
export const REMOVE_PRIORITY_BONUS = "remove_priority_bonus";

// sortable types
export const TITLE = "title";
export const ALERTS = "alerts";

// filter types
export const BRANDS = "brands";
export const TEAMMATES = "teammates";
export const ACTIVE = "active";

export const MENU_ACTIONS = ({
  [STALE]: [
    MAKE_ANNOUNCEMENT,
    LOCK,
    EDIT,
    CHANGE_OWNER,
    DUPLICATE,
    SHARE_CANDIDATES,
    CLOSE,
  ],
  [LOCKED]: [
    MAKE_ANNOUNCEMENT,
    UNLOCK,
    UPDATE_LOCK_REASON,
    EDIT,
    CHANGE_OWNER,
    DUPLICATE,
    SHARE_CANDIDATES,
    CLOSE,
  ],
  [OPEN]: [
    MAKE_ANNOUNCEMENT,
    LOCK,
    ADD_PRIORITY_BONUS,
    EDIT,
    CHANGE_OWNER,
    SAVE_AS_TEMPLATE,
    DUPLICATE,
    ADD_AGENCIES,
    SHARE_CANDIDATES,
    CLOSE,
  ],
  [SUBMITTED]: [EDIT, CHANGE_OWNER, DUPLICATE, CLOSE],
  [ARCHIVED]: [SAVE_AS_TEMPLATE, DUPLICATE, SHARE_CANDIDATES, REOPEN],
  [PRIORITY_BONUS]: [
    MAKE_ANNOUNCEMENT,
    LOCK,
    UPDATE_PRIORITY_BONUS,
    REMOVE_PRIORITY_BONUS,
    EDIT,
    CHANGE_OWNER,
    DUPLICATE,
    SHARE_CANDIDATES,
    CLOSE,
  ],
});

export const VIEWING_AS_SOMEONE_ELSE_MENU_ACTIONS = ({
  [STALE]: [
    MAKE_ANNOUNCEMENT,
    LOCK,
    EDIT,
    CHANGE_OWNER,
    SHARE_CANDIDATES,
    CLOSE,
  ],
  [LOCKED]: [
    MAKE_ANNOUNCEMENT,
    UNLOCK,
    UPDATE_LOCK_REASON,
    EDIT,
    CHANGE_OWNER,
    SHARE_CANDIDATES,
    CLOSE,
  ],
  [OPEN]: [
    MAKE_ANNOUNCEMENT,
    LOCK,
    EDIT,
    CHANGE_OWNER,
    SHARE_CANDIDATES,
    CLOSE,
  ],
  [SUBMITTED]: [EDIT, CHANGE_OWNER, DUPLICATE, CLOSE],
  [ARCHIVED]: [SHARE_CANDIDATES, REOPEN],
  [PRIORITY_BONUS]: [
    MAKE_ANNOUNCEMENT,
    LOCK,
    EDIT,
    CHANGE_OWNER,
    SHARE_CANDIDATES,
    CLOSE,
  ],
});
