export const RECEIVE_CANDIDATES = "RECEIVE_CANDIDATES";
export const CLEAR_CANDIDATES = "CLEAR_CANDIDATES";
export const SET_CANDIDATES_INDEX_LOADED = "SET_CANDIDATES_INDEX_LOADED";
export const RECEIVE_CANDIDATE = "RECEIVE_CANDIDATE";
export const RECEIVE_CONVERSATION = "RECEIVE_CONVERSATION";
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";

export const receiveCandidates = (candidates) => ({
  type: RECEIVE_CANDIDATES,
  payload: candidates,
});

export const receiveCandidate = (candidate) => ({
  type: RECEIVE_CANDIDATE,
  payload: candidate,
});

export const setCandidatesIndexLoaded = ({
  customKey,
  indexType,
  positionType,
}) => ({
  type: SET_CANDIDATES_INDEX_LOADED,
  payload: { indexType, positionType, customKey },
});

export const clearCandidates = () => ({
  type: CLEAR_CANDIDATES,
});

export const receiveConversation = (conversation) => ({
  type: RECEIVE_CONVERSATION,
  payload: conversation,
});

export const receiveMessage = (message) => ({
  type: RECEIVE_MESSAGE,
  payload: message,
});
