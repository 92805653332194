import { useMediaQuery } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";

const HeaderSub = ({ content }) => {
  const isMobile = useMediaQuery("(max-width:750px)");

  const renderContent = () => {
    if (typeof content === "string" || content instanceof String) {
      return <span className="nav-text">{content}</span>;
    }

    return content;
  };

  return (
    <div className="subnav-row" style={{ paddingTop: isMobile && 72 }}>
      <div style={{ maxWidth: 1948 }}>
        {renderContent()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  content: state.ui.subNav.content,
});

export default connect(mapStateToProps, null)(HeaderSub);
