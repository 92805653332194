import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import MUIRadioGroup from "@material-ui/core/RadioGroup";
import map from "lodash/map";
import React from "react";

function RadioGroup({
  id,
  label: labelContent,
  options,
  error,
  helperText,
  itemStyle,
  disabled,
  children,
  labelStyle = {},
  errorMessageStyle = {},
  ...muiRadioGroupProps
}) {
  const label = labelContent && (
    <div style={{ marginBottom: 6, ...labelStyle }}>
      <InputLabel shrink={false} error={error}>
        {labelContent}
      </InputLabel>
    </div>
  );

  const optionInputStyle = Object.assign(
    error ? { color: "#FF6D6D" } : {},
    itemStyle
  );

  const optionInputs = map(options, (optionProps, idx) => (
    <FormControlLabel
      control={<Radio color="primary" />}
      style={optionInputStyle}
      key={`option-${idx}`}
      data-cy={`${id}-${optionProps.value}-option`}
      disabled={disabled}
      {...optionProps}
    />
  ));

  const errorMessage = error && helperText && (
    <FormHelperText style={errorMessageStyle} className="helper-text error">
      {helperText}
    </FormHelperText>
  );

  return (
    <div style={{ marginBottom: 20 }}>
      {label}
      <MUIRadioGroup {...muiRadioGroupProps}>
        {optionInputs}
        {children}
      </MUIRadioGroup>
      {errorMessage}
    </div>
  );
}

RadioGroup.defaultProps = {
  itemStyle: {},
  disabled: false,
};

export default RadioGroup;
