import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import pick from "lodash/pick";
import reject from "lodash/reject";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from 'react-redux';

import { openModal } from "../../../../../../../actions/shared/uiActions";
import { GET } from "../../../../../../../util/apiHelpers";
import ActionMenu from "../../../../../../General/ActionMenu";
import JobCastLocations from "../../../../../../General/JobCastLocations";
import LoadingPage from "../../../../../../General/LoadingPage";
import DrawerSortableTable from "../../../../../../General/SortableTable/DrawerSortableTable";
import ToolTipColumnLabel from "../../../../../../General/SortableTable/ToolTipColumnLabel";
import DeleteAgencyRequestModal from "../../../../../Shared/Modals/DeleteAgencyRequestModal";
import RestoreAgencyRequestModal from "../../../../../Shared/Modals/RestoreAgencyRequestModal";

import InviteCTA from "./InviteCTA";

const JOBCAST_COL = "jobCastCol";
const INVITED_COL = "invitedCol";
const ACCEPTED_COL = "acceptedCol";
const CANDIDATES_COL = "candidatesCol";
const IN_PROCESS_COL = "inProcessCol";
const ACTION_MENU = "actionMenu";

const styles = {
  jobCastCellContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    "& > div": {
      maxWidth: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
};

const formatDate = (date) => moment(date).format("MMM D, YYYY");

class AgencyJobCastsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      dataItems: [],
    };
  }

  componentDidMount() {
    this.fetchActivityData();
  }

  componentDidUpdate(
    _prevProps,
    { sortKey: prevSortKey, sortDir: prevSortDir }
  ) {
    const { sortKey, sortDir } = this.state;

    if (sortKey !== prevSortKey || sortDir !== prevSortDir) {
      this.sortItems();
    }
  }

  fetchActivityData() {
    const {
      jobCasts, recruiterId, isCommunity, agency, openDeleteModal, openRestoreModal
    } = this.props;
    const jobCastIds = map(jobCasts, ({ id }) => id);

    const mapDataToItems = (activityData) => {
      const items = map(jobCasts, (jobCast) => ({
        id: jobCast.id,
        agency,
        openDeleteModal,
        openRestoreModal,
        ...pick(jobCast.attributes, ["title", "locations"]),
        ...activityData[jobCast.id],
      }));

      return isCommunity ? reject(items, (item) => !item.invited_at) : items;
    };

    const initSortItems = (dataItems) => orderBy(dataItems, [
      ({ invited_at }) => (invited_at ? -1 : 1),
      ({ title }) => title,
    ]);

    return GET(
      `/api/v3/employer/agencies/${recruiterId}/recruiter_jobcasts_status?jobcast_ids=${jobCastIds}`
    ).then(({ data }) => {
      const dataItems = initSortItems(mapDataToItems(data));

      this.setState({ dataItems, loaded: true });
    });
  }

  setItemInvited = (id) => {
    const updatedItems = cloneDeep(this.state.dataItems);

    const item = updatedItems.find((item) => item.id === id);
    item.invited_at = moment().format();

    this.setState({ dataItems: updatedItems });
  };

  isDeactivatedRequest = (request_id) => this.props.agency.individual.attributes.deactivatedRequestPositions.includes(request_id)

  tableColumns = [
    {
      colKey: JOBCAST_COL,
      label: this.props.temp ? "Contract Role" : "JobCast",
      renderData: ({
        title, locations, request_id
      }) => (
        <div className={this.props.classes.jobCastCellContent} style={{ color: this.isDeactivatedRequest(request_id) ? "#90A4AE" : "" }}>
          <div>
            {title}
          </div>
          <div className="small" style={{ maxHeight: "22px" }}>
            <JobCastLocations locations={locations} />
            {this.isDeactivatedRequest(request_id) && (
            <Chip
              label="Removed"
              className="caption"
              style={{
                backgroundColor: "#546E7A",
                color: "white",
                marginLeft: "142px",
                marginTop: "-40px",
              }}
            />
            )}
          </div>
        </div>
      ),
      orderFunc: ({ title }) => title.toLowerCase(),
      style: {
        width: "32%",
        justifyContent: "flex-start",
      },
    },
    {
      colKey: INVITED_COL,
      label: this.props.isCommunity ? (
        <ToolTipColumnLabel
          labelText="Matched"
          toolTipText="Date the Agency was matched to the JobCast"
        />
      ) : (
        <ToolTipColumnLabel
          labelText="Invited"
          toolTipText="Date the agency was invited to work on the JobCast"
        />
      ),
      renderData: ({
        id, invited_at, request_id
      }) => (
        <span style={{ color: this.isDeactivatedRequest(request_id) ? "#90A4AE" : "" }}>
          {invited_at ? (
            formatDate(invited_at)
          ) : (
            <InviteCTA
              id={id}
              recruiterId={this.props.recruiterId}
              setItemInvited={this.setItemInvited}
            />
          )}
        </span>
      ),
      orderFunc: ({ invited_at }) => (invited_at ? moment().diff(moment(invited_at)) : Infinity),
      style: { width: "17%", justifyContent: "center" },
    },
    {
      colKey: ACCEPTED_COL,
      label: (
        <ToolTipColumnLabel
          labelText="Accepted"
          toolTipText="Date the agency accepted the JobCast"
        />
      ),
      renderData: ({
        declined_at, accepted_at, request_id
      }) => (
        <span style={{ color: this.isDeactivatedRequest(request_id) ? "#90A4AE" : "" }}>
          {(declined_at && "Declined") || (accepted_at && formatDate(accepted_at))}
        </span>
      ),
      orderFunc: ({ accepted_at }) => (accepted_at ? moment().diff(moment(accepted_at)) : Infinity),
      style: { width: "17%", justifyContent: "center" },
    },
    {
      colKey: CANDIDATES_COL,
      label: (
        <ToolTipColumnLabel
          labelText="Candidates"
          toolTipText="Number of candidates presented by the agency"
        />
      ),
      renderData: ({ candidates, request_id }) => (
        <span style={{ color: this.isDeactivatedRequest(request_id) ? "#90A4AE" : "" }}>
          {candidates}
        </span>
      ),
      orderFunc: ({ candidates }) => (candidates || candidates === 0 ? candidates : -1),
      style: { width: "17%", justifyContent: "center" },
    },
    {
      colKey: IN_PROCESS_COL,
      label: (
        <ToolTipColumnLabel
          labelText="In Process"
          toolTipText="Number of candidates still in the hiring process"
        />
      ),
      renderData: ({ in_process, request_id }) => (
        <span style={{ color: this.isDeactivatedRequest(request_id) ? "#90A4AE" : "" }}>
          {in_process}
        </span>
      ),
      orderFunc: ({ in_process }) => (in_process || in_process === 0 ? in_process : -1),
      style: { width: "17%", justifyContent: "center" },
    },
    {
      colKey: ACTION_MENU,
      label: (""
      ),
      renderData: ({
        request_id, agency, openDeleteModal, openRestoreModal, title, locations
      }) => this.renderActionMenu(request_id, agency, openDeleteModal, openRestoreModal, title, locations),
      style: { width: "7%", justifyContent: "center" }
    },
  ];

  customTableStyles = {
    container: {
      height: "calc(100% - 107px)",
    },
    body: {
      height: "calc(100% - 50px)",
    },
    bodyRow: {
      color: this.props.agency.individual.attributes.deactivatedRequestPositions.includes(this.props.request_id) ? "#90A4AE" : "",
    },
  };

  handleActionSelection(request_id, agency, openDeleteModal, openRestoreModal, title, locations) {
    return (actionName) => {
      const jobCast = {
        attributes: { title, locations }
      };

      switch (actionName) {
        case "REMOVE_FROM_JOBCAST":
          openDeleteModal(agency, jobCast, request_id);
          break;
        case "RESTORE_TO_JOBCAST":
          openRestoreModal(agency, jobCast, request_id);
          break;
        default:
          break;
      }
    };
  }

  renderActionMenu(request_id, agency, openDeleteModal, openRestoreModal, title, locations) {
    return (
      <div style={{
        display: "flex", alignItems: "center", width: "25px", marginLeft: "-30px"
      }}
      >
        <ActionMenu
          key={request_id}
          actions={this.isDeactivatedRequest(request_id) ? [
            {
              name: "RESTORE_TO_JOBCAST",
              icon: "restore",
            }
          ] : [
            {
              name: "REMOVE_FROM_JOBCAST",
              icon: "cancel-rounded",
            }
          ]}
          handleActionSelection={this.handleActionSelection(request_id, agency, openDeleteModal, openRestoreModal, title, locations)}
        />
      </div>
    );
  }

  render() {
    const { loaded, dataItems } = this.state;

    return loaded ? (
      <DrawerSortableTable
        columns={this.tableColumns}
        items={dataItems}
        customStyles={this.customTableStyles}
      />
    ) : (
      <LoadingPage />
    );
  }
}

AgencyJobCastsTable.propTypes = {
  jobCasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      attributes: PropTypes.object.isRequired,
    })
  ).isRequired,
  classes: PropTypes.object.isRequired,
  recruiterId: PropTypes.string.isRequired,
  isCommunity: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = (dispatch) => ({
  openDeleteModal: (agency, jobCast, request_id) => dispatch(
    openModal(
      <DeleteAgencyRequestModal agency={agency} jobCast={jobCast} request_id={request_id} />
    )
  ),
  openRestoreModal: (agency, jobCast, request_id) => dispatch(
    openModal(
      <RestoreAgencyRequestModal agency={agency} jobCast={jobCast} request_id={request_id} />
    )
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AgencyJobCastsTable));
