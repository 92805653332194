import { withFormik } from "formik";
import React from "react";
import { connect } from "react-redux";

import { object, number, string } from "yup";

import { createJobcastReview } from "../../../../../../../../actions/recruiters/reviewActions";
import { clearModal } from "../../../../../../../../actions/shared/uiActions";
import MultiLineTextField from "../../../../../../../forms/custom/MultiLineTextField";
import ActionModalContainer from "../../../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../../../../General/ActionModal/ActionModalHeader";
import JobCastLocations from "../../../../../../../General/JobCastLocations";

import EmployerRatingPanel from "./EmployerRatingPanel";

class RateModal extends React.Component {
  renderContent() {
    const {
      handleChange,
      handleBlur,
      values,
      errors,
      touched,
      setFieldValue,
      setFieldTouched,
    } = this.props;
    const { individualFirstName, organizationName } = this.props.jobcast;
    return (
      <>
        <p style={{ margin: 0 }}>
          Provide anonymous feedback on your experience working with{" "}
          {individualFirstName} at
          {organizationName}.
        </p>
        <EmployerRatingPanel
          jobcast={this.props.jobcast}
          values={values}
          errors={errors}
          touched={touched}
          formContext={this}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
        />
        <MultiLineTextField
          id="jobcast_note"
          label="Additional Information about your rating (optional)"
          value={this.props.values.jobcast_note}
          onChange={handleChange}
          onBlur={handleBlur}
          rows={3}
          helperText="Ratings are visible to Employers but the provider is always anonymous."
          style={{ marginBottom: 0 }}
        />
      </>
    );
  }

  renderSubtitle() {
    const { locations, title } = this.props.jobcast;
    return (
      <JobCastLocations
        locations={locations}
        placement="left"
        color="blue"
        title={title}
        preLocationText={`${title} - `}
      />
    );
  }

  render() {
    const { handleSubmit, isSubmitting } = this.props;

    return (
      <ActionModalContainer color="blue">
        <ActionModalHeader
          title="Rate JobCast Owner"
          subtitle={this.renderSubtitle()}
        />
        <ActionModalContent>{this.renderContent()}</ActionModalContent>
        <ActionModalFooter
          actionText="Submit Rating"
          handleAction={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </ActionModalContainer>
    );
  }
}

const RateModalForm = withFormik({
  handleSubmit: (values, { props, setSubmitting }) => {
    const data = {
      subjectId: props.requestId,
      subjectType: "Request",
      note: values.jobcast_note,
      ratings: [
        {
          characteristic: "CANDIDATE-EXPERIENCE",
          rating: values.candidateExperience,
        },
        { characteristic: "RESPONSIVENESS", rating: values.responsiveness },
        { characteristic: "LIKELIHOOD", rating: values.likelihood },
      ],
    };

    props
      .createJobcastReview(data)
      .then(() => props.clearModal())
      .catch(() => setSubmitting(false));
  },
  mapPropsToValues: () => ({
    candidateExperience: null,
    responsiveness: null,
    likelihood: null,
    jobcast_note: "",
  }),
  validationSchema: object().shape({
    candidateExperience: number().nullable().required("Cannot be blank"),
    responsiveness: number().nullable().required("Cannot be blank"),
    likelihood: number().nullable().required("Cannot be blank"),
    jobcast_note: string().nullable(),
  }),
})(RateModal);

const mapDispatchToProps = (dispatch) => ({
  clearModal: () => dispatch(clearModal()),
  createJobcastReview: (data) => dispatch(createJobcastReview(data)),
});

export default connect(null, mapDispatchToProps)(RateModalForm);
