export const RECEIVE_REQUIRED_TEMP_AGENCY_AGREEMENTS = "RECEIVE_REQUIRED_TEMP_AGENCY_AGREEMENTS";
export const RECEIVE_REQUIRED_TEMP_AGENCY_AGREEMENT = "RECEIVE_REQUIRED_TEMP_AGENCY_AGREEMENT";

export const receiveRequiredTempAgencyAgreements = (requiredTempAgencyAgreements) => ({
  type: RECEIVE_REQUIRED_TEMP_AGENCY_AGREEMENTS,
  payload: requiredTempAgencyAgreements,
});

export const receiveRequiredTempAgencyAgreement = (requiredTempAgencyAgreement) => ({
  type: RECEIVE_REQUIRED_TEMP_AGENCY_AGREEMENT,
  payload: requiredTempAgencyAgreement,
});

export const getRequiredTempAgencyAgreements = (state) => state.requiredTempAgencyAgreements.items;
