import { useFormikContext } from "formik";
import React from "react";

import AdvancedOptionsSection from "../../TempInviteAgency/AdvancedOptions";
import ContractWorkforce from "../../TempInviteAgency/ContractWorkforce";
import PermanentPlacement from "../../TempInviteAgency/PermanentPlacement";
import PlacementTypeSelection from "../../TempInviteAgency/PlacementTypeSelection";

import EmployerAbsorptionRateField from "../../TempInviteAgency/shared/EmployerAbsorptionRateField";

function ChangeFeeModalContent({ currentOrganization }) {
  const { values } = useFormikContext();

  const displayAdvancedOptions =
      values.placementTypes.temp || values.placementTypes.perm;

  const activeContract = currentOrganization.contractActivated;

  return (
    <>
      <div style={{ textAlign: "left" }}>
        If you update, add, or remove a Preferred Agency fee the agency will
        be notified via email.
        <br />
        New fee(s) will be applied to all future candidate submissions. This
        will not affect any hires that have been marked previously. For more
        on processing fees
        {" "}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://help.recruitifi.com/en/articles/5014829-does-recruitifi-take-a-fee-from-agencies-on-hires"
        >
          click here.
        </a>
      </div>
      <div style={{ paddingTop: 20 }}>
        {activeContract && (
          <div style={{ paddingLeft: 20 }}>
            <PlacementTypeSelection />
          </div>
        )}
        {values.placementTypes.perm && (
          <PermanentPlacement editFees />
        )}
        {activeContract && values.placementTypes.temp && (
          <ContractWorkforce editFees />
        )}
        {displayAdvancedOptions && (
          <AdvancedOptionsSection>
            <EmployerAbsorptionRateField fieldId="employerAbsorptionRate" />
          </AdvancedOptionsSection>
        )}
      </div>
    </>
  );
}

export default ChangeFeeModalContent;
