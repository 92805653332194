import actioncable from "actioncable";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import ActionCableContext from "./ActionCableContext";

export function ActionCableProvider({ url, children }) {
  const [cable, setCable] = useState(null);

  useEffect(() => {
    const newCable = url ? actioncable.createConsumer(url) : null;

    setCable(newCable);

    return () => {
      newCable && newCable.disconnect();
    };
  }, [url]);

  return (
    <ActionCableContext.Provider value={{ cable }}>
      {children}
    </ActionCableContext.Provider>
  );
}

ActionCableProvider.propTypes = {
  url: PropTypes.string,
  children: PropTypes.any,
};

export default ActionCableProvider;
