const styles = () => ({
  list: {
    padding: 0,
    marginBottom: 24
  },
  listItem: {
    padding: "6px 12px 6px 24px",
    width: "100%",
    backgroundColor: "inherit",
    fontFamily: "'proxima-nova', helvetica",
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "19px",
    letterSpacing: "0.15px",
    textAlign: "left",
    color: "#ffffff"
  },
  selectedItem: {
    paddingLeft: "20px",
    borderLeft: "4px solid #ffffff",
    fontWeight: "700"
  },
  listItemText: {
    display: "flex",
    justifyContent: "space-between",
    padding: 0
  }
});

export default styles;
