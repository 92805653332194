import { withStyles } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import round from "lodash/round";
import PropTypes from "prop-types";
import React from "react";

import {
  DonutChartContainer,
  DonutChartSVG,
  DonutChartLegend,
} from "../../../../../../General/Charts/DonutChart";
import FunnelBarChart from "../../../../../../General/Charts/FunnelBarChart";
import PageCardRowHeader from "../../../../../../General/PageCardRowHeader";

import RotatingEmptyState from "../../../../../../shared/EmptyState/RotatingEmptyState";

import MoreReportsLink from "./MoreReportsLink";

const styles = {
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 24,
    paddingRight: 24,
  },
  chartsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24,
  },
};

function JobCastParticipationChart({
  isCommunity,
  invite,
  inviteNoAction = 0,
  inviteDeclined = 0,
  inviteAcceptedNoSubmissions = 0,
  inviteAcceptedAndSubmissions = 0,
}) {
  const dataItems = [
    {
      id: "No Action",
      label: "No Action",
      value: inviteNoAction,
      color: "#F1B7B0",
    },
    {
      id: "Declined",
      label: "Declined",
      value: inviteDeclined,
      color: "#ff6d6d",
    },
    {
      id: "Submitted",
      label: "Submitted",
      value: inviteAcceptedAndSubmissions,
      color: "#D2EAC8",
    },
    {
      id: "Accepted with No Submission",
      label: "Accepted with No Submission",
      value: inviteAcceptedNoSubmissions,
      color: "#B8CDE1",
    },
  ];

  return (
    <div
      style={{
        width: 400,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {isCommunity ? (
        <>
          <h4 style={{ marginBottom: 20 }}>JobCast Matches</h4>
          <DonutChartContainer
            data={dataItems}
            style={{ height: 200, width: "100%" }}
          >
            <DonutChartLegend />
            <DonutChartSVG
              innerRadius={0}
              enableSlicesLabels={false}
              tooltipFormat={(value) => `${round((value / invite) * 100)}%`}
            />
          </DonutChartContainer>
        </>
      ) : (
        <>
          <h4 style={{ marginBottom: 20 }}>JobCast Invitations</h4>
          <DonutChartContainer
            data={dataItems}
            style={{ height: 200, width: "100%" }}
          >
            <DonutChartLegend />
            <DonutChartSVG
              totalLabel={`Total Invitation${invite === 1 ? "" : "s"}`}
            />
          </DonutChartContainer>
        </>
      )}
    </div>
  );
}

function CandidateProgressChart({
  presented,
  reviewing = 0,
  interviewing = 0,
  offers = 0,
  hires = 0,
}) {
  const dataItems = [
    {
      id: "Hired",
      Candidates: hires,
      color: "rgba(0, 141, 174, 1)",
    },
    {
      id: "Offers",
      Candidates: offers,
      color: "rgba(0, 141, 174, 0.8)",
    },
    {
      id: "Interviews",
      Candidates: interviewing,
      color: "rgba(0, 141, 174, 0.6)",
    },
    {
      id: "Reviewing",
      Candidates: reviewing,
      color: "rgba(0, 141, 174, 0.4)",
    },
    {
      id: "Presented",
      Candidates: presented,
      color: "rgba(0, 141, 174, 0.2)",
    },
  ];

  return (
    <div
      style={{
        width: 400,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h4 style={{ marginBottom: 24 }}>
        Candidates
        {" "}
        <Tooltip title="The number of candidates who reached each stage.">
          <InfoOutlinedIcon
            style={{
              color: "#B0BEC5",
              fontSize: 16,
              marginBottom: -1,
            }}
          />
        </Tooltip>
      </h4>
      <FunnelBarChart
        data={dataItems}
        valueKey="Candidates"
        height={200}
        axisWidth={90}
        svgWidth={310}
      />
    </div>
  );
}

function AllTimeActivity({
  jobcastParticipationStats,
  candidateProgressStats,
  classes,
  isCommunity,
}) {
  const renderCharts = () => (
    <div className={classes.chartsContainer}>
      <JobCastParticipationChart
        isCommunity={isCommunity}
        {...jobcastParticipationStats}
      />
      {Boolean(candidateProgressStats.presented) && (
        <CandidateProgressChart {...candidateProgressStats} />
      )}
    </div>
  );

  return (
    <>
      <PageCardRowHeader title="All-time Activity" />
      <div className={classes.contentContainer}>
        {jobcastParticipationStats.invite ? (
          renderCharts()
        ) : (
          <RotatingEmptyState />
        )}
        {isCommunity ? <></> : <MoreReportsLink />}
      </div>
    </>
  );
}

AllTimeActivity.propTypes = {
  jobcastParticipationStats: PropTypes.object,
  candidateProgressStats: PropTypes.object,
  isCommunity: PropTypes.bool,
};

export default withStyles(styles)(AllTimeActivity);
