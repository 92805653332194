import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React, { Component } from "react";

import FileInput from "../../../../../../../forms/custom/FileInput.react";

const acceptedFileTypes = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
].join(", ");

class SupportingDocument extends Component {
  state = { showTooltip: false };

  renderInputField() {
    const {
      values,
      touched,
      errors,
      setFieldValue
    } = this.props;

    const handleChange = (_, newFile) => setFieldValue("document", newFile);
    return (
      <FileInput
        accept={acceptedFileTypes}
        id="document"
        label="Supporting Document"
        helperText={(
          <span className="gray">
            Must be a .jpg, .jpeg, .png, .pdf, .doc, .docx
          </span>
        )}
        error={Boolean(errors.document && touched.document)}
        onChange={handleChange}
        value={values.document}
      />
    );
  }

  render() {
    return (
      <div style={{ position: "relative", width: "100%" }}>
        {this.renderInputField()}
        <Tooltip
          id="tooltip-icon"
          title="May be a separation agreement, a screenshot of email resignation, etc."
        >
          <InfoOutlinedIcon
            style={{
              color: "#546e7a",
              margin: "-14px",
              fontSize: 16,
              top: 40,
              left: "calc(100% + 24px)",
              position: "absolute"
            }}
          />
        </Tooltip>
      </div>
    );
  }
}

export default SupportingDocument;
