import filter from "lodash/filter";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";
import values from "lodash/values";
import { createSelector } from "reselect";

// performs a union between unlike entities, with their inclusion
// in the result based on inclusionFunc. provide array of orderFuncs for sorting
// the filtered result (if desired)
const makeEntityUnionSelector = (selectors = [], inclusionFunc, orderFuncs = []) => createSelector(
  selectors,
  (...entityTypes) => {
    const filtered = reduce(
      entityTypes,
      (included, entityCollection) => (
        included.concat(filter(values(entityCollection), inclusionFunc))
      ),
      []
    );
    return sortBy(filtered, orderFuncs);
  }
);

export default makeEntityUnionSelector;
