import PropTypes from "prop-types";
import React, { memo } from "react";

import { replaceHTMLTags } from "../../../../../util/formatHelpers";
import WysiwygEditor from "../../../../forms/custom/WysiwygEditor.react";

import { withFormContext } from "../../../../forms/formik/FormContext";

import shouldSkipRerender from "./shouldSkipRerender";

function FormContextWysiwyg({
  formContext: {
    values, errors, touched, setFieldValue, setFieldTouched
  },
  ...passThroughProps
}) {
  const handleChange = value => {
    setFieldValue(id, value || '');
  };
  const handleBlur = () => setFieldTouched(id, true);

  const { id } = passThroughProps;
  const value = values[id] || '';
  const errorMessage = (touched[id] && errors[id]) || "";

  return (
    <WysiwygEditor
      value={value}
      rawText={replaceHTMLTags(value)}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      {...passThroughProps}
    />
  );
}

FormContextWysiwyg.propTypes = {
  id: PropTypes.string.isRequired,
  formContext: PropTypes.shape({
    values: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired
  }).isRequired
};

export default withFormContext(memo(FormContextWysiwyg, shouldSkipRerender));
