import AttachMoney from '@material-ui/icons/AttachMoney';
import moment from 'moment';
import React from "react";

import Content from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Content";
import Subheader from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";

import withNotificationMarkedRead from "./withNotificationMarkedRead";

function PriorityBonusAdded({
  notification: {
    attributes: { createdAt, messageBody },
  },
  jobcast: {
    attributes: {
      individualAvatar,
      individualFirstName,
      individualLastInitial,
      organizationName,
    }
  }
}) {
  return (
    <div>
      <Subheader
        leftChip={{
          chipHeader: "Update from",
          individualFirstName,
          individualLastInitial,
          individualAvatar,
          chipSubText: organizationName,
        }}
      />
      <Content
        iconImage={AttachMoney}
        iconCaption="Priority Bonus Added"
        label="Priority Bonus Added"
        value={messageBody}
        helperText={moment(createdAt).format("MMMM Do, YYYY")}
      />
    </div>
  );
}

export default withNotificationMarkedRead(PriorityBonusAdded);
