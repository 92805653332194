import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchAgency } from "../../../../actions/employers/agencyActions";

import { makeGetAgencyRecruiter } from "../../../../selectors/employer/agencySelectors";

import CommunityDrawer from "./types/community/CommunityDrawer";
import LegacyDrawer from "./types/legacy/LegacyDrawer";

const useStyles = makeStyles(() => ({
  loadingContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
}));

function LoadingContent() {
  return (
    <div className={useStyles().loadingContainer}>
      <CircularProgress size={128} style={{ marginTop: "50px" }} />
    </div>
  );
}

function AgencyRecruiterDrawer({
  recruiterId,
  tabSelection,
  setExcludedOrgIds,
}) {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchAgency(recruiterId)).then(() => setLoaded(true));
    }
  }, [loaded, recruiterId, setLoaded, dispatch]);

  const getAgencyRecruiter = useMemo(() => makeGetAgencyRecruiter(), []);

  const agencyRecruiter = useSelector(
    (state) => getAgencyRecruiter(state, recruiterId),
    [recruiterId]
  );

  if (!loaded) {
    return <LoadingContent />;
  } if (agencyRecruiter.preferredLink) {
    return (
      <LegacyDrawer agency={agencyRecruiter} tabSelection={tabSelection} />
    );
  }
  return (
    <CommunityDrawer
      agency={agencyRecruiter}
      tabSelection={tabSelection}
      setExcludedOrgIds={setExcludedOrgIds}
    />
  );
}

export default AgencyRecruiterDrawer;
