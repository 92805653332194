import { makeStyles } from "@material-ui/core/styles";

import {
  EXPANSION_COL,
  AGENCY_COL,
  RECRUITERS_COL,
  FEE_COL,
  RATING_COL,
  ACTIONS_COL,
  PLACEMENT_TYPE_COL,
} from "./constants";

export const COL_WIDTHS = {
  [EXPANSION_COL]: "5%",
  [AGENCY_COL]: "32%",
  [RECRUITERS_COL]: "9%",
  [FEE_COL]: "14%",
  [PLACEMENT_TYPE_COL]: "8%",
  [RATING_COL]: "14%",
  [ACTIONS_COL]: "18%",
};

export const AVATAR_WIDTH = 90;

const useSharedStyles = makeStyles(() => ({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  cell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  [EXPANSION_COL]: {
    width: COL_WIDTHS[EXPANSION_COL],
    justifyContent: "center",
  },
  [AGENCY_COL]: {
    width: COL_WIDTHS[AGENCY_COL],
    justifyContent: "flex-start",
  },
  [RECRUITERS_COL]: {
    width: COL_WIDTHS[RECRUITERS_COL],
    justifyContent: "center",
  },
  [FEE_COL]: {
    width: COL_WIDTHS[FEE_COL],
    justifyContent: "center",
  },
  [PLACEMENT_TYPE_COL]: {
    width: COL_WIDTHS[PLACEMENT_TYPE_COL],
    justifyContent: "center",
  },
  [RATING_COL]: {
    width: COL_WIDTHS[RATING_COL],
    justifyContent: "center",
  },
  [ACTIONS_COL]: {
    width: COL_WIDTHS[ACTIONS_COL],
    justifyContent: "center",
  },
  ellipsisOverflow: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  linkText: {
    color: "#008DAE",
    "&:hover": {
      textDecoration: "underline",
      color: "#B0BEC5",
      cursor: "pointer",
    },
  },
}));

export default useSharedStyles;
