import {
  UPDATE_BRANDS,
  UPDATE_TEAMMATES,
} from "../actions/shared/filterActions";

const defaultState = { brands: [], teammates: [] };

const filtersReducer = (state = defaultState, { type, payload } = {}) => {
  Object.freeze(state);

  switch (type) {
    case UPDATE_BRANDS:
      return {
        brands: payload,
        teammates: state.teammates,
      };
    case UPDATE_TEAMMATES:
      return {
        brands: state.brands,
        teammates: payload,
      };
    default:
      return state;
  }
};

export default filtersReducer;
