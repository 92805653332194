import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Assignment from "@material-ui/icons/AssignmentInd";
import DescriptionIcon from "@material-ui/icons/Description";
import History from "@material-ui/icons/History";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import classnames from "classnames";
import includes from "lodash/includes";
import startCase from "lodash/startCase";
import propTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  setProfileDrawerLoading,
  setProfileDrawerVisible,
  setProfileDrawerHeaderText,
} from "../../../actions/shared/uiActions";

import { buttonSets } from "../../../util/styleHelpers";

import AgencyChat from "../../employer/JobCasts/view/Candidates/view/CandidateDrawerTabs/AgencyChat";
import IconTabs from "../../forms/custom/IconTabs";
import JobCastLocations from "../../General/JobCastLocations";
import EmployerChat from "../../recruiter/Candidates/view/CandidateDrawerTabs/EmployerChat";
import IndividualDetails from "../Jobcasts/index/item/IndividualDetails";

import CandidateDrawerHeader from "./CandidateDrawerHeader";

import HistoryTab from "./CandidateDrawerTabs/HistoryTab";
import ResponsesTab from "./CandidateDrawerTabs/ResponsesTab";
import ResumeTab from "./CandidateDrawerTabs/ResumeTab";
import {
  NEW,
  AGENCY_CHAT,
  EMPLOYER_CHAT,
  HISTORY,
  RESUME,
  RESPONSES,
  VIEW_RESUME,
  VIEW_RESPONSES,
  EVENT_HISTORY,
  ARCHIVED,
  UNCONFIRMED,
  AWAITING_SORT,
  HIRED,
  CONTRACT_ACTIVE,
} from "./constants";

const style = (theme) => ({
  profileDrawerButton: {
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "1px",
    padding: "6px 16px",
    height: "36px",
    boxShadow: "none",
    margin: "9px 0px 6px 0px",
  },
  footer: {
    flex: 1,
    backgroundColor: "#F5F5F5",
  },
  loadingContent: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  candidateHeaderContainer: {
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("900")]: {
      width: 900,
    },
  },
  tabContent: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "100%",
    [theme.breakpoints.down("900")]: {
      width: 900,
    },
  },
  headerTitle: {
    marginLeft: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const determineInitialTab = (navSelection) => {
  switch (navSelection) {
    case AGENCY_CHAT:
    case EMPLOYER_CHAT:
      return 0;
    case VIEW_RESUME:
      return 1;
    case VIEW_RESPONSES:
      return 2;
    case EVENT_HISTORY:
      return 3;
    default:
      return -1;
  }
};

class CandidateDrawer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: determineInitialTab(props.navSelection),
      loaded: false,
    };
  }

  fetchData = async () => {
    const { candidateId, fetchCandidate } = this.props;

    await fetchCandidate(candidateId);

    const { candidate, fetchJobcast } = this.props;

    await fetchJobcast(candidate.attributes.jobcastId);

    this.setState({ loaded: true });
  };

  async componentDidMount() {
    this.props.setProfileDrawerHeaderText("");

    await this.fetchData();

    this.props.setProfileDrawerHeaderText(this.handlePositionInfoHeaderText());
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.loaded && !prevState.loaded) {
      this.props.setProfileDrawerHeaderText(
        this.handlePositionInfoHeaderText()
      );
    }
  }

  handleTabChange = (_event, value) => {
    this.setState({ tabValue: value });
  };

  renderLoadingContent() {
    return (
      <div className={this.props.classes.loadingContent}>
        <CircularProgress size={128} style={{ marginTop: "50px" }} />
      </div>
    );
  }

  handlePositionInfoHeaderText() {
    const { jobcast, classes } = this.props;

    if (jobcast) {
      const { title, locations } = jobcast.attributes;
      return (
        <div className={classes.headerTitle}>
          <JobCastLocations
            locations={locations}
            preLocationText={`${title} - `}
            maxWidth={700}
          />
          <IndividualDetails
            jobcast={jobcast}
            overrideStyle={{ container: { paddingBottom: 0 } }}
          />
        </div>
      );
    }
    return "";
  }

  renderContentHeader() {
    const { status, hasMessage } = this.props.candidate.attributes;
    const {
      jobcast,
      classes,
      currentIndividual: { employer },
    } = this.props;

    const jobcastStatus = jobcast?.attributes?.status;
    const archived = (jobcastStatus === ARCHIVED) && ![HIRED, CONTRACT_ACTIVE].includes(status);

    const roleBasedTooltip = `The Chat tab is not available while the candidate is in ${status.split("_").join(" ")}${status === UNCONFIRMED ? "" : " stage unless the employer sends a message"}.`;

    const chatIconLabel = employer
      ? startCase(AGENCY_CHAT)
      : startCase(EMPLOYER_CHAT);

    const tooltipText = () => {
      if (includes([NEW, UNCONFIRMED, AWAITING_SORT], status)) {
        return roleBasedTooltip;
      }
      if (jobcastStatus === ARCHIVED) {
        return "Agency Chat is disabled on Archived JobCasts";
      }
      return "";
    };

    const tabItems = [
      {
        icon: <QuestionAnswerIcon />,
        label: chatIconLabel,
        disabled: includes([NEW, UNCONFIRMED, AWAITING_SORT], status) &&
          !employer && !hasMessage,
        tooltipText: tooltipText(),
        show_badge: this.props.unread_chat_count > 0,
        badge_count: this.props.unread_chat_count,
      },
      { icon: <DescriptionIcon />, label: RESUME, disabled: false },
      { icon: <Assignment />, label: RESPONSES, disabled: false },
      {
        icon: <History />,
        label: HISTORY,
        disabled: false,
        show_badge: this.props.unread_history_count > 0,
        badge_count: this.props.unread_history_count,
      },
    ];

    return (
      <div
        className={classnames(
          "candidate-header-container",
          classes.candidateHeaderContainer
        )}
      >
        <CandidateDrawerHeader
          candidate={this.props.candidate}
          archived={archived}
        />
        <IconTabs
          items={tabItems}
          style={{ width: 900 }}
          value={this.state.tabValue}
          handleChange={this.handleTabChange}
        />
      </div>
    );
  }

  renderTabContent() {
    const {
      candidate,
      jobcast,
      jobcastStatus,
      currentIndividual: { employer },
    } = this.props;

    switch (this.state.tabValue) {
      case 1:
        return <ResumeTab candidate={candidate} />;
      case 2:
        return <ResponsesTab {...{ candidate, employer, jobcast }} />;
      case 3:
        return (
          <HistoryTab
            candidate={candidate}
            subjectType={[
              "EmployerCandidateUpdateNotification",
              "RecruiterCandidateUpdateNotification",
              "Transfer",
            ]}
          />
        );
      case 0:
      default:
        return employer ? (
          <AgencyChat
            candidate={candidate}
            showIntroSection
            buttonStyle={buttonSets.intercom}
            jobcastStatus={jobcastStatus}
            jobcast={jobcast}
            multiTextStyle={{
              width: "70%",
              paddingTop: 12,
            }}
          />
        ) : (
          <EmployerChat
            candidate={candidate}
            showIntroSection
            buttonStyle={buttonSets.intercom}
            jobcastStatus={jobcastStatus}
            jobcast={jobcast}
            multiTextStyle={{
              width: "70%",
              paddingTop: 12,
            }}
          />
        );
    }
  }

  renderContent() {
    if (!this.state.loaded) {
      return this.renderLoadingContent();
    }
    return (
      <div
        className="drawer-content-container"
        data-cy="drawer-content-container"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: "auto",
          height: "100%",
        }}
      >
        {this.renderContentHeader()}
        <div
          className={this.props.classes.tabContent}
          data-cy="candidate-drawer-tab-content"
        >
          {this.renderTabContent()}
        </div>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        {this.renderContent()}
        <footer className={classes.footer} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setProfileDrawerLoading: (isLoading) => dispatch(setProfileDrawerLoading(isLoading)),
  setProfileDrawerNotVisible: () => dispatch(setProfileDrawerVisible(false)),
  setProfileDrawerHeaderText: (text) => dispatch(setProfileDrawerHeaderText(text)),
});

CandidateDrawer.propTypes = {
  loading: propTypes.bool,
  candidate: propTypes.object.isRequired,
  navSelection: propTypes.string.isRequired,
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(style)(CandidateDrawer));
