import keys from "lodash/keys";
import merge from "lodash/merge";

import {
  CLEAR_CURRENT_ORGANIZATION,
  RECEIVE_CURRENT_ORGANIZATION,
  RECEIVE_ORGANIZATIONS,
} from "../actions/shared/organizationActions";

const _defaultState = {
  currentOrganizationId: null,
  items: {},
};

const organizationReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_CURRENT_ORGANIZATION:
      const currentOrganizationId = keys(action.payload)[0];
      return merge({}, state, {
        currentOrganizationId,
        items: action.payload,
      });
    case RECEIVE_ORGANIZATIONS:
      return merge({}, state, { items: action.payload });
    case CLEAR_CURRENT_ORGANIZATION:
      return _defaultState;
    default:
      return state;
  }
};

export default organizationReducer;
