import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { openModal } from "../../../actions/shared/uiActions";

import Hotkey from "./Hotkey";
import OmnisearchModal from "./OmnisearchModal";

const useStyles = makeStyles({
  container: {
    width: "220px",
    maxWidth: 400,
    borderRadius: 4,
    marginRight: 4,
    cursor: "pointer",
    fontWeight: 400,
    color: "#37474F",
    flexGrow: 1,
  },
  searchIcon: {
    fontSize: 24,
    paddingLeft: 6,
    paddingRight: 6,
    color: "#37474F",
  },
  fauxInput: {
    display: "flex",
    alignItems: "center",
    fontStyle: "italic",
    fontSize: 16,
    height: 35,
    fontWeight: 400,
    color: "#B0BEC5",
    justifyContent: "space-between",
  },
  fauxInputText: {
    display: "flex",
    alignItems: "center",
  },
});

export default function OmnisearchButton({ currentIndividual }) {
  const dispatch = useDispatch();
  const ref = useRef();

  const classes = useStyles();

  const modalVisible = useSelector((state) => state.ui.modal.visible);
  const profileDrawerVisible = useSelector(
    (state) => state.ui.profileDrawer.visible
  );

  function openOmnisearch() {
    dispatch(openModal(<OmnisearchModal {...{ currentIndividual }} />));
  }

  const handleUserKeyPress = useCallback(
    ({ metaKey, ctrlKey, code }) => {
      if ((metaKey || ctrlKey) && code === "KeyK") {
        if (!(modalVisible || profileDrawerVisible)) {
          openOmnisearch();
        }
      }
    },
    [modalVisible, profileDrawerVisible]
  );

  useEffect(() => {
    if (ref.current) {
      window.removeEventListener("keydown", ref.current);
    }

    ref.current = handleUserKeyPress;
    window.addEventListener("keydown", ref.current);

    return () => {
      window.removeEventListener("keydown", ref.current);
    };
  }, [handleUserKeyPress]);

  return (
    <Paper onClick={openOmnisearch} className={classes.container}>
      <div className={classes.fauxInput} data-cy={"omnisearch-button"}>
        <span className={classes.fauxInputText}>
          <Icon className={classes.searchIcon}>search</Icon>
          Search...
        </span>
        <span className={classes.fauxInputText}>
          <Hotkey {...{ hotkey: "K" }} />
        </span>
      </div>
    </Paper>
  );
}
