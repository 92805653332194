import normalize from "json-api-normalizer";

import { PERM, TEMP } from "../../selectors/jobcastSelectors";

import { GET, POST, PATCH, DELETE } from "../../util/apiHelpers";

// callback action types//
export const RECEIVE_JOBCAST_DRAFTS = "RECEIVE_JOBCAST_DRAFTS";
export const RECEIVE_JOBCAST_TEMPLATES = "RECEIVE_JOBCAST_TEMPLATES";
export const REMOVE_JOBCAST_DRAFT = "REMOVE_JOBCAST_DRAFT";
export const RECEIVE_JOBCAST_DRAFT = "RECEIVE_JOBCAST_DRAFT";

// callback actions//
export const receiveJobCastDrafts = (drafts, type = PERM) => ({
  type: RECEIVE_JOBCAST_DRAFTS,
  payload: { type, drafts },
});

export const receiveJobCastTemplates = (templates) => ({
  type: RECEIVE_JOBCAST_TEMPLATES,
  payload: templates,
});

export const removeJobCastDraft = (id) => ({
  type: REMOVE_JOBCAST_DRAFT,
  payload: id,
});

export const receiveJobCastDraft = (id) => ({
  type: RECEIVE_JOBCAST_DRAFT,
  payload: id,
});

// api actions//
export const fetchJobCastDrafts =
  (isTemp = false) =>
  (dispatch) =>
    GET(`/api/v3/employer/drafts?type=${isTemp ? TEMP : PERM}`).then((res) => {
      const normalized = normalize(res.data);
      dispatch(receiveJobCastDrafts(normalized.draft, isTemp ? TEMP : PERM));
    });

export const fetchJobCastTemplates = () => (dispatch) =>
  GET(`/api/v3/employer/drafts?type=PositionTemplate`).then((res) => {
    const normalized = normalize(res.data);
    dispatch(receiveJobCastTemplates(normalized.draft));
  });

export const fetchJobCastDraft = (id) => (_dispatch) =>
  GET(`/api/v3/employer/drafts/${id}`)
    .then((res) => Promise.resolve(res.data))
    .catch((res) => Promise.reject(res));

export const createJobCastDraft = (data, subjectType) => (dispatch) =>
  POST("/api/v3/employer/drafts", { subject_type: subjectType, ...data })
    .then((res) => {
      const normalized = normalize(res.data);
      dispatch(receiveJobCastDraft(normalized.draft));
      return Promise.resolve(res.data.data.id);
    })
    .catch((res) => Promise.reject(res));

export const updateJobCastDraft = (data, subjectType) => (dispatch) =>
  PATCH(`/api/v3/employer/drafts/${data.id}`, {
    subject_type: subjectType,
    ...data,
  })
    .then((res) => {
      const normalized = normalize(res.data);
      dispatch(receiveJobCastDraft(normalized.draft));
      return Promise.resolve(res.data.data.id);
    })
    .catch((res) => Promise.reject(res));

export const deleteJobCastDraft = (id) => (dispatch) =>
  DELETE(`/api/v3/employer/drafts/${id}`)
    .then(() => {
      dispatch(removeJobCastDraft(id));
      Promise.resolve();
    })
    .catch((res) => Promise.reject(res));

export const duplicateJobCastDraft = (id) => (dispatch) =>
  POST(`/api/v3/employer/drafts/${id}/duplicate`)
    .then((res) => {
      const normalized = normalize(res.data);
      dispatch(receiveJobCastDraft(normalized.draft));
      return Promise.resolve(res.data.data);
    })
    .catch((res) => Promise.reject(res));

export const changeDraftOwner = (data) => (dispatch) =>
  PATCH(`/api/v3/employer/drafts/${data.draft_id}/change_owner`, data)
    .then((res) => {
      const normalized = normalize(res.data);
      dispatch(receiveJobCastDraft(normalized.draft));
    })
    .catch((errors) => Promise.reject(errors.response.data));
