import { TextField } from "@material-ui/core";
import { useFormikContext } from "formik";
import React from "react";
import { Row, Col } from "react-grid-system";

import { disabledProps } from "../../../../../../../../../util/formHelpers";
import GlobalAvatar from "../../../../../../../../General/GlobalAvatar";
import SectionHeader from "../../../../../../../JobCasts/view/Candidates/actions/modals/Hire/SectionHeader";

import FieldSection from "../../../../../../../Shared/Modals/sections/FieldSection";

function StaffingAgencyField() {
  const { values } = useFormikContext();

  const { agencyLogoUrl, agencyName } = values;

  return (
    <div>
      <div style={{ marginTop: "-5px" }} className="static-input-label">
        Staffing Agency
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <GlobalAvatar
            size="medium"
            organization={{
              name: agencyName,
              avatarIcon: agencyLogoUrl,
            }}
            overrideStyle={{
              borderRadius: 0,
              width: "initial",
              height: "initial",
              maxWidth: 225 * 0.5,
              maxHeight: 100 * 0.5,
            }}
          />
          <div>{agencyName}</div>
        </div>
      </div>
    </div>
  );
}

function RecruiterField() {
  const { values } = useFormikContext();

  return (
    <TextField
      {...disabledProps(true)}
      value={values.recruiterName}
      label="Submitting Recruiter"
    />
  );
}

function BillRateField() {
  const { values } = useFormikContext();

  return (
    <TextField
      {...disabledProps(true)}
      value={`$${values.hourlyBillRate}/hr`}
      label="Hourly Bill Rate"
    />
  );
}

function AgencyInformation() {
  return (
    <>
      <SectionHeader title="Staffing Firm Information" />
      <FieldSection>
        <Row style={{ alignItems: "flex-start" }}>
          <Col xs={12} md={4}>
            <StaffingAgencyField />
          </Col>
          <Col xs={12} md={4}>
            <RecruiterField />
          </Col>
          <Col xs={12} md={4}>
            <BillRateField />
          </Col>
        </Row>
      </FieldSection>
    </>
  );
}

export default AgencyInformation;
