import merge from "lodash/merge";

import {
  RECEIVE_ANNOUNCEMENTS,
  RECEIVE_ANNOUNCEMENT,
  RECEIVE_ANNOUNCEMENT_LOADED
} from "../../actions/shared/jobcastUpdatesActions";

const _defaultState = {
  loaded: {},
  items: {}
};

const announcementsReducer = (state = _defaultState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_ANNOUNCEMENTS:
    case RECEIVE_ANNOUNCEMENT:
      return merge({}, state, { items: action.payload });
    case RECEIVE_ANNOUNCEMENT_LOADED:
      return merge({}, state, { loaded: { [action.payload]: true } });
    default:
      return state;
  }
};

export default announcementsReducer;
