import each from "lodash/each";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import replace from "lodash/replace";
import { createSelector } from "reselect";

import {
  NEW,
  REVIEWING,
  INTERVIEWING,
  OFFER,
  REJECTED,
  WITHDRAWN,
  HIRED,
  AWAITING_SORT,
  SCREENING,
  EARLY_INTERVIEW,
  LATE_INTERVIEW,
  CONFIRMED_NOT_WATCHLISTED,
} from "../components/employer/JobCasts/view/Candidates/constants";
import {
  CLOSED_CONTRACT,
  OPEN_CONTRACT,
  PRE_ASSIGNMENT,
} from "../components/employer/TempJobCasts/view/constants";

// candidate selectors
export const getAllCandidates = (state) => state.candidates.items;

const replaceOldStage = (stage) =>
  replace(
    stage,
    /(CONFIRMED-NOT-WATCHLISTED|AWAITING-SORT|SCREENING|EARLY-INTERVIEW|LATE-INTERVIEW)/,
    (_match, p1) => {
      switch (p1) {
        case CONFIRMED_NOT_WATCHLISTED:
          return "PRESENTED";
        case AWAITING_SORT:
          return "NEW";
        case SCREENING:
          return "REVIEWING";
        case EARLY_INTERVIEW:
          return "INTERVIEWING";
        case LATE_INTERVIEW:
          return "OFFER";
        default:
          return p1;
      }
    }
  );
const updateStageEvents = (stageEvents) =>
  each(stageEvents, (event) => {
    event.stage = replaceOldStage(event.stage);
    event.event = replaceOldStage(event.event);
  });

export const filterCandidatesByJobCast = (jobcastId) => (candidates) =>
  filter(candidates, ({ attributes }) => attributes.jobcastId === jobcastId);

export const filterCandidatesByType = (type) => (candidates) => {
  const typeStatuses = {
    new: [NEW],
    reviewing: [REVIEWING],
    interviewing: [INTERVIEWING],
    offers: [OFFER],
    rejected: [REJECTED, WITHDRAWN],
    hires: [HIRED],
    [PRE_ASSIGNMENT]: [PRE_ASSIGNMENT],
    [OPEN_CONTRACT]: [OPEN_CONTRACT],
    [CLOSED_CONTRACT]: [CLOSED_CONTRACT],
  }[type.toLowerCase()] || [NEW];

  if (!isEmpty(candidates)) {
    each(candidates, (candidate) => {
      candidate.attributes.stageEvents = updateStageEvents(
        candidate.attributes.stageEvents
      );
    });
  }

  return filter(candidates, ({ attributes }) =>
    typeStatuses.includes(attributes.status)
  );
};

export const makeGetFilteredCandidates = (filterFuncs) =>
  createSelector([getAllCandidates], (candidates) => {
    let res = candidates;
    filterFuncs.forEach((filterFunc) => {
      res = filterFunc(res);
    });
    return res;
  });

export const getFilteredCandidates = (state, filterFuncs = []) =>
  makeGetFilteredCandidates(filterFuncs)(state);

export const getCandidate = (state, id) => {
  const items = getAllCandidates(state);

  if (items) {
    return items[id];
  }
};

export const makeGetCandidate = () =>
  createSelector([getCandidate], (candidate) => candidate);

export const selectAttributes = (candidates, attributes) => {
  if (Array.isArray(candidates)) {
    return map(candidates, (candidate) => {
      const selected = {};
      each(attributes, (attribute) => {
        selected[attribute] = candidate.attributes[attribute];
      });
      return selected;
    });
  }
  if (typeof candidates === "object" || candidates instanceof Object) {
    const selected = {};
    each(attributes, (attribute) => {
      selected[attribute] = candidates.attributes[attribute];
    });
    return selected;
  }
};
