import React from "react";

import successImage from "../../../../../../../../assets/images/candidate-success.svg";

function HireProcessed() {
  return (
    <>
      <h5>Your hire has been processed!</h5>
      <div style={{ marginTop: 16, display: "flex", flexDirection: "row" }}>
        <img
          style={{ height: 80, width: 235, marginRight: 24 }}
          src={successImage}
          alt="success.svg"
        />
        <p>
          The invoice has been generated and the agency recruiter has been
          notified.
        </p>
      </div>
    </>
  );
}

export default HireProcessed;
