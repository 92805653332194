import merge from "lodash/merge";

import { RECEIVE_REVIEWS } from "../actions/shared/reviewActions";

const defaultState = {
  items: {},
};

export default function reviewsReducer(
  state = defaultState,
  { type, payload } = {}
) {
  Object.freeze(state);

  switch (type) {
    case RECEIVE_REVIEWS:
      return merge({}, state, {
        items: payload,
      });
    default:
      return state;
  }
}
