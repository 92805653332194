import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchPlacement } from "../../../../../../actions/recruiters/financeActions";
import { makeGetPlacementItem } from "../../../../../../selectors/recruiter/financeSelectors";
import ActionModalContainer from "../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../General/ActionModal/ActionModalContent";
import ActionModalHeader from "../../../../../General/ActionModal/ActionModalHeader";
import LoadingPage from "../../../../../General/LoadingPage";
import { UPCOMING, COMPLETED } from "../../../constants";

import EarningsCalculation from "./EarningsCalculation";
import HireDetailsFields from "./HireDetailsFields";
import HireStatus from "./HireStatus";
import PayoutCalculation from "./PayoutCalculation";
import PayoutStatus from "./PayoutStatus";
import { PlacementContext } from './usePlacementContext';

function HireDetailsModal({ match, placementId }) {
  const dispatch = useDispatch();
  const getPlacement = makeGetPlacementItem();
  const placement = useSelector(state => getPlacement(state, placementId));

  useEffect(() => {
    if (!placement) dispatch(fetchPlacement(placementId));
  }, [dispatch, placement, placementId]);

  const loaded = Boolean(placement);

  return (
    <ActionModalContainer color="grey">
      <ActionModalHeader
        title="Hire Details"
        subtitle={loaded && placement.attributes.candidateName}
      />
      <ActionModalContent>
        {!loaded
          ? <LoadingPage />
          : (
            <PlacementContext.Provider value={placement.attributes}>
              {(match?.params?.section === UPCOMING || match?.params?.section === COMPLETED) && (
                <HireStatus {...{ placementId }} />
              )}
              {(match?.params?.section === UPCOMING) && (
                <PayoutStatus {...{ placementId }} />
              )}
              <HireDetailsFields {...placement.attributes} />
              <EarningsCalculation />
              <PayoutCalculation />
            </PlacementContext.Provider>
          )}
      </ActionModalContent>
    </ActionModalContainer>
  );
}

HireDetailsModal.propTypes = {
  placementId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  match: PropTypes.object
};

export default HireDetailsModal;
