import { withFormik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";

import { returnCandidate } from "../../../../../../../actions/employers/candidateActions";
import {
  clearModal,
  setProfileDrawerVisible,
} from "../../../../../../../actions/shared/uiActions";
import { buildFullName } from "../../../../../../../util/formatHelpers";
import { string, object } from "../../../../../../../util/yup";
import DropdownInput from "../../../../../../forms/custom/DropdownInput.react";
import ActionModalContainer from "../../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../../../General/ActionModal/ActionModalHeader";
import withSnackbar from "../../../../../../General/withSnackbar";

class BackModal extends Component {
  stageOptions() {
    const { status } = this.props.candidate.attributes;

    switch (status) {
      case "interviewing":
        return [{ value: "Reviewing", label: "Reviewing" }];
      case "offer":
        return [
          { value: "Interviewing", label: "Interviewing" },
          { value: "Reviewing", label: "Reviewing" },
        ];
      default:
        return [];
    }
  }

  renderContent() {
    const { values, handleChange } = this.props;

    return (
      <>
        <div style={{ marginBottom: 24 }}>
          Please select the stage you would like this candidate moved back to
          below. The submitting agency recruiter will be notified of this
          action.
        </div>
        <div style={{ height: 60, width: 184 }}>
          <DropdownInput
            noShrink
            id="stage"
            label="Select the stage this candidate should be in"
            onChange={handleChange}
            options={this.stageOptions()}
            value={values.stage}
            labelStyle={{
              color: "#B0BEC5",
            }}
          />
        </div>
      </>
    );
  }

  render() {
    const {
      handleSubmit,
      errors,
      submitCount,
      isSubmitting,
      candidate: {
        attributes: { firstName, lastName },
      },
    } = this.props;

    return (
      <ActionModalContainer color="grey">
        <ActionModalHeader
          title="Move Candidate Backward"
          subtitle={buildFullName(firstName, lastName)}
        />
        <ActionModalContent>{this.renderContent()}</ActionModalContent>
        <ActionModalFooter
          actionText="Submit"
          handleAction={handleSubmit}
          isSubmitting={isSubmitting}
          customErrorMessage={errors.customErrorMessage}
          submissionError={Boolean(submitCount && Object.values(errors).length)}
        />
      </ActionModalContainer>
    );
  }
}

const BackModalForm = withFormik({
  handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
    const { firstName, lastName } = props.candidate.attributes;
    const submissionData = { stage: values.stage };

    props
      .returnCandidate(submissionData)
      .then(() => {
        setSubmitting(false);
        props.snackbar.showMessage(
          `${buildFullName(
            firstName,
            lastName
          )} returned to ${values.stage.toLowerCase()}`
        );
        props.clearModal();
        props.setProfileDrawerNotVisible();
      })
      .catch(({ errors }) => {
        setSubmitting(false);
        if (errors) setFieldError("customErrorMessage", errors);
      });
  },
  mapPropsToValues: ({
    candidate: {
      attributes: { status },
    },
  }) => ({
    stage: status === "offer" ? "Interviewing" : "Reviewing",
  }),
  validationSchema: object().shape({
    stage: string().required("Cannot be blank"),
  }),
})(BackModal);

const mapDispatchToProps = (dispatch, ownProps) => ({
  returnCandidate: (data) => dispatch(returnCandidate(ownProps.candidate.id, data)),
  clearModal: () => dispatch(clearModal()),
  setProfileDrawerNotVisible: () => dispatch(setProfileDrawerVisible(false)),
});

export default connect(null, mapDispatchToProps)(withSnackbar()(BackModalForm));
