import { withStyles } from "@material-ui/core/styles";
import StatisticsIcon from "@material-ui/icons/BarChart";
import ProfileIcon from "mdi-material-ui/Account";
import JobcastIcon from "mdi-material-ui/Briefcase";
import FeesIcon from "mdi-material-ui/CurrencyUsd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setProfileDrawerHeaderText } from "../../../../../../actions/shared/uiActions";
import { getCurrentIndividual } from "../../../../../../selectors/individualSelectors";
import IconTabs from "../../../../../forms/custom/IconTabs";
import AgencyDrawerHeader from "../../shared/AgencyDrawerHeader";
import {
  JOBCASTS_TAB,
  FEES_TAB,
  STATISTICS_TAB,
  PROFILE_TAB,
} from "../../shared/constants";
import JobCastsTab from "../../shared/tabs/JobCastsTab/JobCastsTab";
import ProfileTab from "../../shared/tabs/ProfileTab";
import StatisticsTab from "../../shared/tabs/StatisticsTab/StatisticsTab";

import FeesTab from "./tabs/FeesTab/FeesTab";

const style = (theme) => ({
  loading: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflowY: "auto",
    height: "100%",
  },
  headerContainer: {
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("900")]: {
      width: 900,
    },
  },
  tabContent: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("900")]: {
      width: 900,
    },
  },
});

const tabItems = [
  {
    icon: <JobcastIcon />,
    label: "JobCasts",
  },
  { icon: <FeesIcon />, label: "Fees", disabled: false },
  { icon: <StatisticsIcon />, label: "Statistics", disabled: false },
  {
    icon: <ProfileIcon />,
    label: "Profile",
    disabled: false,
  },
];

function Header({
  agency,
  tabValue,
  handleTabChange,
  classes,
  setExcludedOrgIds,
}) {
  return (
    <div className={classes.headerContainer}>
      <AgencyDrawerHeader
        agency={agency}
        handleTabChange={handleTabChange}
        setExcludedOrgIds={setExcludedOrgIds}
      />
      <IconTabs
        items={tabItems}
        style={{ width: 900 }}
        value={tabValue}
        handleChange={handleTabChange}
      />
    </div>
  );
}

function CommunityDrawer({
  agency,
  classes,
  tabSelection = JOBCASTS_TAB,
  setExcludedOrgIds,
}) {
  const [tab, setTab] = useState(tabSelection);

  const dispatch = useDispatch();

  const handleTabChange = (_event, value) => {
    setTab(value);
  };

  useEffect(() => {
    dispatch(setProfileDrawerHeaderText("Community Agency Information"));

    return () => {
      dispatch(setProfileDrawerHeaderText(""));
    };
  }, [dispatch]);

  const renderTabContent = () => {
    switch (tab) {
      case JOBCASTS_TAB:
      default:
        return (
          <div>
            <JobCastsTab agency={agency} />
          </div>
        );
      case FEES_TAB:
        return (
          <div style={{ height: "100%" }}>
            <FeesTab agency={agency} />
          </div>
        );
      case STATISTICS_TAB:
        return <StatisticsTab agency={agency} isCommunity />;
      case PROFILE_TAB:
        return <ProfileTab agency={agency} isCommunity />;
    }
  };

  return (
    <div className={classes.drawerContainer}>
      <Header
        classes={classes}
        agency={agency}
        tabValue={tab}
        handleTabChange={handleTabChange}
        setExcludedOrgIds={setExcludedOrgIds}
      />
      <div className={classes.tabContent}>{renderTabContent()}</div>
    </div>
  );
}

export default withStyles(style)(CommunityDrawer);
