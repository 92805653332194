import SwapHorizontal from "mdi-material-ui/SwapHorizontal";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

import {
  makeGetFilteredNotifications,
  UNREAD,
} from "../../../../selectors/notificationsSelectors";
import withNotificationsReader from "../../../shared/CandidateDrawer/CandidateDrawerTabs/withNotificationsReader";
import Content from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Content";
import Subheader from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";

function JobcastTransfer({
  notification: {
    attributes: { messageBody, createdAt },
  },
}) {
  return (
    <div>
      <Subheader headerText="JobCast Transfer" />
      <Content
        iconImage={SwapHorizontal}
        iconCaption="JobCast Transfer"
        label="JobCast Transfer"
        value={messageBody}
        helperText={moment(createdAt).format("MMMM Do, YYYY")}
      />
    </div>
  );
}

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();

  const mapStateToProps = (
    state,
    { notification: { id: notificationId } }
  ) => ({
    unreadNotifications: getFilteredNotifications(state, {
      [UNREAD]: true,
      notificationId,
    }),
  });

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  withNotificationsReader(JobcastTransfer)
);
