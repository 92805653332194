import { useFormikContext } from "formik";
import get from "lodash/get";
import includes from "lodash/includes";
import PropTypes from "prop-types";
import React from "react";

import DropdownInput from "../../custom/DropdownInput.react";

function FormContextDropdown({ id, inputProps, ...passThroughProps }) {
  const { values, errors, touched, handleChange, handleBlur } =
    useFormikContext();
  const errorMessage = (get(touched, id) && get(errors, id)) || "";

  const value = get(values, id);
  const formattedValue = includes([null, undefined], value)
    ? null
    : String(value);

  return (
    <DropdownInput
      id={id}
      value={formattedValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      helperTextOverrideStyle={{ marginBottom: "-18px" }}
      inputProps={{
        "data-cy": `${id}-dropdown-input`,
        ...inputProps,
      }}
      {...passThroughProps}
    />
  );
}
FormContextDropdown.defaultProps = {
  inputProps: {},
};

FormContextDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  inputProps: PropTypes.object,
};

export default FormContextDropdown;
