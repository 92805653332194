import { withStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import classnames from "classnames";
import merge from "lodash/merge";
import React from "react";

import { trimTrailingNewlines } from "../../../../../util/formatHelpers";
import { disabledProps } from "../../../../../util/formHelpers";

const styles = () => ({
  noMargin: {
    margin: 0,
  },
});
function DisabledTextField({
  classes, label, helperText, value
}) {
  const customSubTextFieldProps = merge({}, disabledProps(true), {
    FormHelperTextProps: {
      classes: {
        root: classnames("caption", "gray", classes.noMargin),
      },
    },
    style: { marginBottom: 0 },
  });

  return (
    <TextField
      {...customSubTextFieldProps}
      id=""
      label={label}
      helperText={helperText}
      // value={value}
      value={trimTrailingNewlines(value)}
      multiline
    />
  );
}

export default withStyles(styles)(DisabledTextField);
