import filter from "lodash/filter";
import { createSelector } from "reselect";

import { ACTIVE, DEACTIVATED, PENDING } from "../../components/shared/Team/constants";
import makeEntityUnionSelector from "../entityUnionSelector";

import { getCurrentIndividual } from "../individualSelectors";

import { getAllTeammateInvitations, getAllTeammates } from "../teammateSelectors";

export const makeGetTeammates = (scope, excludeSelf = false) => createSelector(
  [getAllTeammates, getCurrentIndividual],
  (teammates, currentIndividual) => {
    const getScopedTeammates = () => {
      switch (scope) {
        case ACTIVE:
          return filter(
            teammates,
            ({ attributes }) => Boolean(attributes.deactivatedAt) === false
          );
        case DEACTIVATED:
          return filter(
            teammates,
            ({ attributes }) => Boolean(attributes.deactivatedAt) === true
          );
        default:
          return teammates || [];
      }
    };

    const scopedTeammates = getScopedTeammates();

    return excludeSelf
      ? scopedTeammates.filter(({ id }) => id !== currentIndividual.id)
      : scopedTeammates;
  }
);

export const makeGetTeammateInvitations = scope => createSelector([getAllTeammateInvitations], teammateInvitations => {
  switch (scope) {
    case PENDING:
      return filter(
        teammateInvitations,
        ({ attributes }) => !attributes.acceptedAt
      );
    default:
      return teammateInvitations;
  }
});

export const getTeammatesAndInvites = state => makeEntityUnionSelector(
  [makeGetTeammates("all", true), makeGetTeammateInvitations(PENDING)],
  _ => true
)(state);
