import {
  FormControlLabel,
  Radio,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import classnames from "classnames";
import { useFormikContext } from "formik";
import get from "lodash/get";
import map from "lodash/map";
import React, { useCallback } from "react";

import RadioGroup from "../../../../forms/custom/RadioGroup";

const useStyles = makeStyles(() => ({
  fieldLabel: {
    paddingBottom: 6,
    lineHeight: "13px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tooltipIcon: {
    color: "#B0BEC5",
    fontSize: 13,
    marginLeft: 2,
  },
}));

const RADIO_OPTIONS = [
  {
    value: "0",
    label: (
      <>
        <span className="bold">Agency Paid:</span> The processing fee is taken
        out of the agency payout
      </>
    ),
  },
  {
    value: "0.5",
    label: (
      <>
        <span className="bold">Shared 50-50:</span> Half of the processing fee
        is added to your invoice and the other half is taken out of the agency
        payout
      </>
    ),
  },
  {
    value: "1",
    label: (
      <>
        <span className="bold">Employer Paid:</span> The processing fee is added
        to your invoice
      </>
    ),
  },
];

function FieldLabel() {
  const classes = useStyles();

  return (
    <div className={classnames("static-input-label", classes.fieldLabel)}>
      Which party should pay the processing fee?{" "}
      <Tooltip
        placement="top"
        title="RecruitiFi charges a processing fee of 6% of the transaction amount."
      >
        <InfoOutlined className={classes.tooltipIcon} />
      </Tooltip>
    </div>
  );
}

function EmployerAbsorptionRateField({ fieldId = "employerAbsorptionRate" }) {
  const { setFieldValue, values } = useFormikContext();

  const value = String(get(values, fieldId));

  const onChange = useCallback(
    (_, newValue) => {
      setFieldValue(fieldId, parseFloat(newValue));
    },
    [setFieldValue, fieldId]
  );

  return (
    <>
      <FieldLabel />
      <RadioGroup
        id={fieldId}
        {...{ value, onChange }}
        style={{ padding: "0 0 16px 32px" }}
      >
        {map(RADIO_OPTIONS, ({ value, label }) => (
          <FormControlLabel
            control={<Radio color="primary" />}
            {...{ label, value }}
          />
        ))}
      </RadioGroup>
    </>
  );
}

export default EmployerAbsorptionRateField;
