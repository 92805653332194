import makeStyles from "@material-ui/core/styles/makeStyles";
import React from 'react';

const useStyles = makeStyles(() => ({
  talkSubNav: {
    height: "65px",
    backgroundColor: "#008dae",
    fontSize: "30px",
  },
}));

const TalkSubNav = () => {
  const classes = useStyles();

  return (
    <div className={classes.talkSubNav}>
      <span className="nav-text" style={{ display: "flex" }}>
        <span style={{ display: "flex" }}>
          <h4 className="white" style={{ display: "flex" }}>
            TALK Agency Directory Profile
          </h4>
        </span>
      </span>
    </div>
  );
};

export default TalkSubNav;
