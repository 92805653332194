import { useFormikContext } from "formik";
import get from "lodash/get";
import React, { useCallback, useEffect } from "react";

import FormikFeeOptions from "../../../../forms/formik/FormikFeeOptions/FormikFeeOptions";
import { FeeOptionsList } from "../../../JobCastSettings/AgencyFees/sections/StandardizedFees";
import {
  CUSTOM,
  OPTIMIZED,
  STANDARDIZED,
} from "../fieldHelpers";

function FeeOptionsField({ scope, typeLabel }) {
  const { values, errors, setFieldValue } = useFormikContext();

  const applyScope = useCallback(
    (suffix) => [scope, suffix].join("."),
    [scope]
  );

  const selectedFeeType = get(values, applyScope("feeType"));
  const numFeeOptions = get(values, applyScope("feeOptions")).length;

  useEffect(() => {
    setFieldValue(applyScope("multipleFees"), numFeeOptions > 1);
  }, [numFeeOptions, applyScope, setFieldValue]);

  switch (selectedFeeType) {
    case CUSTOM:
      return (
        <FormikFeeOptions
          fieldArrayName={`${scope}.feeOptions`}
          formProps={{ values, errors }}
          maxOptions={10}
          typeLabel={typeLabel}
        />
      );
    case STANDARDIZED:
      return (
        <div style={{ paddingLeft: 24 }}>
          <FeeOptionsList typeLabel={typeLabel} />
        </div>
      );
    case OPTIMIZED:
    default:
      return null;
  }
}

export default FeeOptionsField;
