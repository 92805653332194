import each from "lodash/each";
import every from "lodash/every";
import filter from "lodash/filter";
import includes from "lodash/includes";
import intersection from "lodash/intersection";
import isArray from "lodash/isArray";
import isNumber from "lodash/isNumber";
import map from "lodash/map";
import some from "lodash/some";
import { createSelector } from "reselect";

import createDeepEqualSelector from "../createDeepEqualSelector";
import { getAllJobcasts, makeGetJobCast } from "../jobcastSelectors";
import { makeGetNotificationItems } from "../notificationsSelectors";
import { getAllRequests, makeGetRequestById } from "../requestSelectors";

import { makeGetMatchPreferences } from "./settingSelectors";

// position types
export const TEMP = "TempPosition";
export const PERM = "PermPosition";

// status types
export const INVITED = "invited";
export const ACTIVE = "active";
export const REMOVED = "removed";
export const ARCHIVED = "archived";
export const UNARCHIVED = "unarchived";

const buildFilterFunctions = ({
  isTemp = false,
  status,
  legacy,
  country,
  state,
  role,
  industry,
  jobcast_status,
  relationship,
  jobcastId,
  submittedTo,
  submissionsMax,
  submissionsMin,
  notification,
  titleSearch
}) => {
  const filterFunctions = [];

  filterFunctions.push(({ jobcast }) => {
    const requiredType = isTemp ? TEMP : PERM;

    return jobcast.attributes.type === requiredType;
  });

  if (!notification) {
    filterFunctions.push(
      ({ request }) => {
        if (request.attributes.deactivated) {
          return request.attributes.submittedTo;
        }
        return request;
      }
    );
  }

  if (isArray(country)) {
    const countryCodeSet = new Set(country);

    filterFunctions.push(({ jobcast }) => some(
      jobcast.attributes.locations,
      (location) => countryCodeSet.has(location.countryCode)
    ));
  }

  if (isArray(state)) {
    const stateCodeSet = new Set(state);

    filterFunctions.push(({ jobcast }) => some(
      jobcast.attributes.locations,
      (location) => stateCodeSet.has(location.state)
    ));
  }

  if (isArray(role)) {
    const roleSet = new Set(role);

    filterFunctions.push(({ jobcast }) => some(
      jobcast.attributes.roleGroups,
      (roleGroup) => roleSet.has(roleGroup)
    ));
  }

  if (isArray(industry)) {
    const industrySet = new Set(industry);

    filterFunctions.push(({ jobcast }) => some(
      jobcast.attributes.majorIndustries,
      (majorIndustry) => industrySet.has(majorIndustry)
    ));
  }

  if (isArray(jobcast_status) && jobcast_status.length !== 3) {
    const statusList = jobcast_status.slice(0);

    if (includes(statusList, "locked")) statusList.push("auto_locked");

    filterFunctions.push(({ jobcast }) => includes(statusList, jobcast.attributes.status));
  }

  if (isArray(relationship) && relationship.length !== 2) {
    const requiredSourceType =
      relationship[0] === "preferred" ? "legacy" : "community";

    filterFunctions.push(
      ({ request }) => request.attributes.sourceType === requiredSourceType
    );
  }

  if (typeof legacy === "boolean") {
    const requiredSourceType = legacy ? "legacy" : "community";

    filterFunctions.push(
      ({ request }) => request.attributes.sourceType === requiredSourceType
    );
  }

  if (status) {
    filterFunctions.push(({ request }) => request.attributes.status === status);
  }

  if (submittedTo && isNumber(submissionsMin)) {
    filterFunctions.push(
      ({ request }) => request.attributes.submittedTo
    );
  }

  if (!submittedTo && isNumber(submissionsMax)) {
    filterFunctions.push(
      ({ request }) => !request.attributes.submittedTo
    );
  }

  if (jobcastId) {
    if (isArray(jobcastId)) {
      const jobcastIdSet = new Set(jobcastId);

      filterFunctions.push(({ jobcast }) => jobcastIdSet.has(jobcast.attributes.id));
    } else {
      filterFunctions.push(
        ({ request }) => request.attributes.jobcastId === jobcastId
      );
    }
  }

  if (titleSearch) {
    filterFunctions.push(({ jobcast }) => {
      const title = jobcast.attributes.title.toLowerCase();
      const organization = jobcast.attributes.organizationName.toLowerCase();
      const search = titleSearch.toLowerCase();

      return title.includes(search) || organization.includes(search);
    });
  }

  return filterFunctions;
};

export const makeGetFilteredJobcasts = () => {
  const getFilters = (_state, filters = {}) => filters;

  const getFiltersSelector = createDeepEqualSelector(
    [getFilters],
    (filters) => filters
  );

  return createSelector(
    [getAllJobcasts, getFiltersSelector],
    (jobcasts, filters) => {
      const filterFunctions = buildFilterFunctions(filters);

      return filter(jobcasts, (jobcast) => every(
        filterFunctions,
        (filterFunction) => filterFunction({ jobcast })
      ));
    }
  );
};

export const makeGetFilteredJobcastRequests = (
  transformer = (jobcastRequest) => jobcastRequest
) => {
  const getRequestFilters = (_state, filters = {}) => filters;
  const getRequestFiltersSelector = createDeepEqualSelector(
    [getRequestFilters],
    (filters) => filters
  );

  return createSelector(
    [getAllJobcasts, getAllRequests, getRequestFiltersSelector],
    (jobcasts, requests, requestFilters) => {
      const items = map(requests, (request) => {
        const jobcast = jobcasts[request.attributes.jobcastId];
        return {
          id: request.id,
          jobcast,
          request,
        };
      });

      // compile list of filter functions to be met
      const filterFunctions = buildFilterFunctions(requestFilters);

      // ensure every filter function returns truthy
      const filteredItems = filter(items, (item) => every(
        [({ jobcast, request }) => jobcast && request].concat(
          filterFunctions
        ),
        (filterFunction) => filterFunction(item)
      ));

      return map(filteredItems, transformer);
    }
  );
};

export const makeGetJobcastRequest = () => {
  const getRequest = makeGetRequestById();

  return createSelector([getRequest, getAllJobcasts], (request, jobcasts) => {
    const jobcast = request && jobcasts[request.attributes.jobcastId];

    return { request, jobcast };
  });
};

export const makeGetJobcastMatchReasons = () => createSelector(
  [makeGetJobCast(), makeGetMatchPreferences()],
  (jobcast, matchPreferences) => {
    if (!jobcast) {
      return {
        roles: [],
        levels: [],
        industries: [],
        states: [],
      };
    }

    return {
      roles: intersection(
        jobcast.attributes.roleGroups,
        matchPreferences.items.jobCastRoles
      ),
      levels: intersection(
        jobcast.attributes.level,
        matchPreferences.items.levels
      ),
      industries: intersection(
        jobcast.attributes.majorIndustries,
        matchPreferences.items.majorIndustries
      ),
      states: intersection(
        map(jobcast.attributes.locations, ({ state }) => state),
        map(matchPreferences.items.states, ({ code }) => code).concat(
          map(matchPreferences.items.regions, ({ code }) => code)
        )
      ),
    };
  }
);

export const makeGetJobcastsRequiringAttention = () => createSelector(
  [makeGetNotificationItems(), makeGetFilteredJobcastRequests()],
  (notifications, jobcastRequests) => {
    const hasUnreadAlerts = {};
    each(notifications, ({ attributes }) => {
      if (!attributes.markedReadAt && attributes.positionId) {
        hasUnreadAlerts[attributes.positionId] = true;
      }
    });

    return map(
      filter(
        jobcastRequests,
        ({ jobcast, request }) =>
          hasUnreadAlerts[jobcast.id] || request.attributes.status === ACTIVE
      ),
      ({ jobcast, request }) => {
        if (
          request.attributes.deactivated &&
          !(hasUnreadAlerts[jobcast.id] || request.attributes.submittedTo)
        ) {
          return null;
        }
        return jobcast;
      }
    );
  }
);
