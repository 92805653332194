import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentIndividual } from '../../../../../selectors/individualSelectors';
import {
  AGENCY, BOTH_PARTIES, EMPLOYER, checklistItemComplete
} from '../fieldHelpers';

function NoActionNeeded({
  editable, Editing, Viewing, checklistItem, children
}) {
  const individual = useSelector(getCurrentIndividual);

  if (!children && !(Editing && Viewing)) {
    return "Missing Field";
  }

  if (checklistItem.responsibility !== BOTH_PARTIES) {
    let responsibility = '';
    if (checklistItem.responsibility === AGENCY && individual.employer) {
      responsibility = "Staffing Firm";
    }
    if (checklistItem.responsibility === EMPLOYER && individual.recruiter) {
      responsibility = "Company";
    }
    if (responsibility) {
      if (checklistItemComplete(checklistItem, individual) && Viewing) {
        return Viewing;
      }
      return (
        <span style={{ whiteSpace: "pre-wrap" }}>
          {`${editable ? 'No action needed.\n' : ''}Responsibility of ${responsibility}`}
        </span>
      );
    }
  }

  return children ?? (editable ? Editing : Viewing);
}

export default NoActionNeeded;
