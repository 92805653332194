import { useFormikContext } from "formik";
import React from "react";

import Button from "../../../../../../../forms/custom/Button";

function FooterButtons({ handleCancel }) {
  const { submitForm, isSubmitting } = useFormikContext();

  return (
    <div>
      <Button
        color="grey"
        variant="secondary"
        onClick={handleCancel}
        disabled={isSubmitting}
        data-cy="action-modal-button-cancel"
        style={{ marginRight: 24 }}
      >
        Skip
      </Button>
      <Button
        color="blue"
        variant="primary"
        onClick={submitForm}
        disabled={isSubmitting}
        data-cy="action-modal-button-save"
      >
        Submit Review
      </Button>
    </div>
  );
}

export default FooterButtons;
