import filter from "lodash/filter";
import values from "lodash/values";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import HeaderTabLinks from "../HeaderTabLinks";

import { EMPLOYER_TAB_OPTIONS } from "./constants";

function EmployerTabs() {
  const scope = useSelector((state) => state.ui.headerMenu.scope);

  const tabItems = useMemo(() => {
    return values(
      filter(
        EMPLOYER_TAB_OPTIONS,
        (option) => option.exclusiveScope === scope || !option.exclusiveScope
      )
    );
  }, [scope]);

  return <HeaderTabLinks items={tabItems} />;
}

export default withRouter(EmployerTabs);
