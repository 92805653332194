import merge from "lodash/merge";

import {
  RECEIVE_EMPLOYEE_WORK_HISTORY,
  RECEIVE_EMPLOYEE_WORK_HISTORIES,
} from "../actions/recruiter/employeeWorkHistoryActions";

const defaultState = {
  loaded: false,
  items: {},
};

const employeeWorkHistoryReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_EMPLOYEE_WORK_HISTORIES:
      return merge({}, state, { loaded: true, items: action.payload });
    case RECEIVE_EMPLOYEE_WORK_HISTORY:
      return merge({}, state, {
        items: { [action.payload.id]: action.payload },
      });
    default:
      return state;
  }
};

export default employeeWorkHistoryReducer;
