import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchFullUnreadNotifications as fetchEmployerFullUnreadNotifications } from "../../../../actions/employers/notificationActions";
import { fetchFullUnreadNotifications as fetchRecruiterFullUnreadNotifications } from "../../../../actions/recruiters/notificationActions";
import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import {
  makeGetFilteredNotifications,
  UNREAD,
} from "../../../../selectors/notificationsSelectors";
import LoadingPage from "../../../General/LoadingPage";

import EmptyNotifications from "./EmptyNotifications";
import NotificationsIndexHeader from "./NotificationsIndexHeader";
import NotificationsList from "./NotificationsList";

class AllNotificationsIndex extends Component {
  state = {
    loaded: false,
    // important that we store these in state so that items don't disappear from
    // view when changed from unread to read
    notifications: this.props.notifications,
  };

  componentDidMount() {
    const {
      fetchEmployerFullUnreadNotifications,
      fetchRecruiterFullUnreadNotifications,
      currentIndividual,
    } = this.props;

    const fetchNotifications = currentIndividual.employer
      ? fetchEmployerFullUnreadNotifications
      : fetchRecruiterFullUnreadNotifications;

    fetchNotifications().then(() =>
      this.setState({ loaded: true, notifications: this.props.notifications })
    );
  }

  renderContent() {
    const { setSelectedNotification, selectedNotification } = this.props;
    const { loaded, notifications } = this.state;

    if (!loaded) {
      return <LoadingPage />;
    }
    if (notifications.length) {
      return (
        <NotificationsList
          notifications={notifications}
          setSelectedNotification={setSelectedNotification}
          selectedNotification={selectedNotification}
          overrideStyle={{ height: "calc(100% - 66px)" }}
        />
      );
    }
    return <EmptyNotifications />;
  }

  render() {
    return (
      <>
        <NotificationsIndexHeader
          title="My Unread Alerts"
          subtitle="Across All JobCasts"
        />
        {this.renderContent()}
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();

  const mapStateToProps = (state) => {
    const currentIndividual = getCurrentIndividual(state);

    return {
      notifications: getFilteredNotifications(state, {
        [UNREAD]: true,
        recipientId: currentIndividual.id,
      }),
      currentIndividual,
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  fetchEmployerFullUnreadNotifications: () =>
    dispatch(fetchEmployerFullUnreadNotifications()),
  fetchRecruiterFullUnreadNotifications: () =>
    dispatch(fetchRecruiterFullUnreadNotifications()),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(AllNotificationsIndex);
