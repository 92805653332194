import capitalize from "lodash/capitalize";
import React, { Component } from "react";
import { useSelector } from "react-redux";

import { TEMP_SCOPE } from "../../../../../../../Header/HeaderPrimary/NavigationTabs/constants";

import CandidateList from "./CandidateList";

function Title({ type }) {
  const scope = useSelector((state) => state.ui.headerMenu.scope);
  if (scope === TEMP_SCOPE && type === "OFFERS") {
    type = "PRE-ASSIGNMENT";
  }
  return <>{capitalize(type)}</>;
}

class CandidateLists extends Component {
  constructor(props) {
    super(props);
    this.state = { selected: this.determineInitialSelected() };
  }

  determineInitialSelected() {
    const { typedCandidates, selectedCandidateId } = this.props;

    return (
      Object.keys(typedCandidates).find((type) =>
        typedCandidates[type].find(
          (candidate) => candidate.id === selectedCandidateId
        )
      ) || null
    );
  }

  handleDisplayClick = (type) => () => {
    this.setState({ selected: this.state.selected === type ? null : type });
  };

  renderError() {
    const { errors, touched } = this.props;

    if (touched.candidates && errors.candidates) {
      return <div className="error helper-text">{errors.candidates}</div>;
    }
  }

  render() {
    const { typedCandidates } = this.props;

    return (
      <div style={{ marginTop: 24, marginBottom: 24 }}>
        <div
          className="static-input-label"
          style={{ paddingBottom: 6, lineHeight: "13px" }}
        >
          Candidates To Share
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {["REVIEWING", "INTERVIEWING", "OFFERS"].map((type) => (
            <CandidateList
              {...this.props}
              title={<Title type={type} />}
              expanded={this.state.selected === type}
              candidates={typedCandidates[type]}
              handleDisplayClick={this.handleDisplayClick(type)}
              key={`share-${type}-list`}
            />
          ))}
        </div>
        {this.renderError()}
      </div>
    );
  }
}

export default CandidateLists;
