// reply types
export const PRIVATE = "PRIVATE";
export const PUBLIC = "PUBLIC";

export const REPLY_ICONS = {
  [PUBLIC]: { icon: "reply_all" },
  [PRIVATE]: { icon: "reply" }
};

// subject tyoes
export const MATCH_QUESTION = "match_question";
export const FEEDBACK = "feedback";
