import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import classnames from "classnames";
import filter from "lodash/filter";
import map from "lodash/map";
import startCase from "lodash/startCase";
import Linkedin from "mdi-material-ui/Linkedin";
import MapMarker from "mdi-material-ui/MapMarker";
import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";

import { alpha2ToCountry } from "../../../util/constantsToOptions";
import GlobalAvatar from "../../General/GlobalAvatar";

import HeaderButtonContainer from "./HeaderButtonContainer";

const styles = {
  root: {
    borderBottom: 0,
    paddingTop: 20,
  },
  action: {
    width: "35%",
    paddingTop: 4,
    marginRight: 0,
    minWidth: 350,
  },
  avatar: {
    marginLeft: "-4px",
  },
  content: {
    maxWidth: 470,
  },
  icon: {
    color: "#90A4AE",
    marginRight: 4,
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
  },
};

const useStyles = makeStyles(styles);

function SubheaderList({ items }) {
  const classes = useStyles();

  const filteredItems = filter(items, (item) => item.visible);

  return (
    <div
      className={classnames(classes.infoContainer, "subtitle-1")}
      style={{ marginBottom: 4 }}
    >
      {map(filteredItems, (item, idx) => (
        <>
          <div
            className={classes.infoContainer}
            data-cy={item.title && `candidate-drawer-header-${item.title}`}
          >
            {item.content}
          </div>
          {idx !== filteredItems.length - 1 && (
            <div style={{ marginLeft: 8, marginRight: 8 }}>&bull;</div>
          )}
        </>
      ))}
    </div>
  );
}

SubheaderList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      visible: PropTypes.bool.isRequired,
      content: PropTypes.node.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
};

function CandidateDrawerHeader({ classes, candidate, archived, employer }) {
  const {
    firstName,
    lastName,
    status,
    avatar,
    email,
    phone,
    phonePrefix,
    linkedinUrl,
    feeOption,
    feeOptionChoices,
    offPlatform,
    location,
  } = candidate.attributes;

  const renderSubHeader = () => {
    const subheaderItems = [
      {
        title: "email",
        content: (
          <>
            <Email className={classes.icon} />
            {email}
          </>
        ),
        visible: true,
      },
      {
        title: "linkedIn",
        content: (
          <>
            <Linkedin className={classes.icon} />
            <a
              href={linkedinUrl}
              style={{ textDecoration: "underline" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </>
        ),
        visible: Boolean(linkedinUrl),
      },
    ];

    return <SubheaderList items={subheaderItems} />;
  };

  const renderContactInfo = () => {
    const items = [
      {
        title: "phone",
        content: !offPlatform && (
          <>
            <Phone className={classes.icon} />
            <NumberFormat
              value={phone}
              displayType="text"
              format={`+${
                alpha2ToCountry(phonePrefix)?.number ?? ""
              } (###) ###-####`}
            />
          </>
        ),
        visible: !offPlatform,
      },
      {
        title: "location",
        content: (
          <>
            <MapMarker className={classes.icon} />
            {location}
          </>
        ),
        visible: !offPlatform,
      },
      {
        title: "off-platform",
        content: "Off Platform",
        visible: Boolean(offPlatform),
      },
    ];

    return <SubheaderList items={items} />;
  };

  const fullName = startCase(`${firstName} ${lastName}`);

  return (
    <CardHeader
      classes={{
        title: classes.title,
        avatar: classes.avatar,
        action: classes.action,
        root: classes.root,
        content: classes.content,
      }}
      title={
        fullName.length > 26 ? (
          <Tooltip title={`${fullName}`}>
            <h3 className="truncate" data-cy="candidate-drawer-header-name">
              {`${fullName}`}
            </h3>
          </Tooltip>
        ) : (
          <h3 className="truncate" data-cy="candidate-drawer-header-name">
            {`${fullName}`}
          </h3>
        )
      }
      subheader={
        <div>
          {renderSubHeader()}
          {renderContactInfo()}
        </div>
      }
      avatar={
        <GlobalAvatar
          size="large"
          individual={{
            avatarIcon: avatar,
            firstName,
            lastName,
          }}
        />
      }
      action={!archived && <HeaderButtonContainer candidate={candidate} />}
    />
  );
}

CandidateDrawerHeader.defaultProps = {
  employer: true,
  archived: false,
};

CandidateDrawerHeader.propTypes = {
  candidate: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  employer: PropTypes.bool,
  archived: PropTypes.bool,
};
export default withStyles(styles)(CandidateDrawerHeader);
