import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";

import constants from "../../../util/constants";

const { genericEmptyStateContent: contentOptions } = constants;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 48,
    marginBottom: 24,
  },
  imageContainer: {
    marginBottom: 24,
    width: 170,
    height: 170,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
  textContainer: {
    maxWidth: "80%",
    textAlign: "center",
  },
};

class RotatingEmptyState extends Component {
  constructor(props) {
    super(props);

    const randContentOptionsIdx = Math.floor(
      Math.random() * contentOptions.length
    );
    this.content = contentOptions[randContentOptionsIdx];
  }

  render() {
    const {
      classes, customTitle, customBody, customStyle
    } = this.props;
    const { imageURL, title } = this.content;

    return (
      <div className={classes.container} style={customStyle}>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={imageURL} alt="empty" />
        </div>
        <div className={classes.textContainer}>
          <h4>{customTitle || title}</h4>
          {customBody && <div>{customBody}</div>}
        </div>
      </div>
    );
  }
}

RotatingEmptyState.defaultProps = {
  customStyle: {},
};

RotatingEmptyState.propTypes = {
  classes: PropTypes.object.isRequired,
  customStyle: PropTypes.object.isRequired,
};

export const somethingHere = 1;

export default withStyles(styles)(RotatingEmptyState);
