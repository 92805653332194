import map from "lodash/map";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { makeGetFilteredSearchResults } from "../../../selectors/searchSelectors";

import SearchResultItem from "./SearchResultItem";

const sectionTitle = {
  Individual: "Agencies",
  Position: "JobCasts",
  RecruiterSubmission: "Candidates",
};

function OmnisearchSearchResultsSectionTitle({ type }) {
  if (!sectionTitle[type]) return null;

  const getFilteredSearchResults = useMemo(makeGetFilteredSearchResults, []);
  const results = useSelector((state) => getFilteredSearchResults(state, { type }));

  return (
    <h5 style={{ margin: "12px 0" }}>
      {sectionTitle[type]}
      {' '}
      (
      {results.length}
      )
    </h5>
  );
}

function OmnisearchSearchResultsEmptyState({ type }) {
  if (!sectionTitle[type]) return null;
  return (
    <h5 style={{ color: "#90A4AE", textAlign: "center", margin: "12px 0" }}>
      No results
    </h5>
  );
}

function OmnisearchSearchResultsItems({ type }) {
  const getFilteredSearchResults = useMemo(makeGetFilteredSearchResults, []);
  let results = useSelector((state) => getFilteredSearchResults(state, { type }));

  if (!type) {
    results = results.slice(0, 3);
  }

  return results && results.length > 0 ? (
    map(results, (result, idx) => (
      <SearchResultItem key={`search-result-${idx}`} item={result} />
    ))
  ) : (
    <OmnisearchSearchResultsEmptyState {...{ type }} />
  );
}

export default function OmnisearchResultsSection({ type }) {
  return (
    <div
      style={{
        marginLeft: -8,
        borderBottom: "0.5px solid #B0BEC5",
        marginBottom: 24,
        paddingBottom: 6
      }}
    >
      <OmnisearchSearchResultsSectionTitle {...{ type }} />
      <OmnisearchSearchResultsItems {...{ type }} />
    </div>
  );
}
