import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";

import App from "./App";
import BugsnagWrapper from "./BugsnagWraper";

import "./App.css";

if (!window.location.pathname.match(/app/)) {
  window.location = "/app";
}

ReactDOM.render(
  <BugsnagWrapper>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </BugsnagWrapper>,
  document.getElementById("root")
);
