import FormHelperText from "@material-ui/core/FormHelperText";
import React from "react";

function ProvideCharacterCounter(props) {
  const {
    visible,
    width,
    id,
    compareCount,
    helperText,
    wysiwyg,
    rawText
  } = props;

  const counter = () => {
    if (wysiwyg) {
      const rawTextLength = rawText.replace(/\n/, '').length;
      return `${rawTextLength} / ${compareCount}`;
    }
    return `${props.children.props.value.length} / ${compareCount}`;
  };

  const length = () => {
    if (wysiwyg) {
      return `${rawText.length}`;
    }
    return `${props.children.props.value.length}`;
  };

  return (
    <div style={{ position: "relative" }}>
      {props.children}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormHelperText
          style={{
            width: width || "calc(100% - 60px)",
            marginTop: "-12px",
            color: "#FF6D6D",
            fontSize: "10px"
          }}
          id={`${id}-helper-text`}
        >
          {helperText}
        </FormHelperText>
        {visible && (
          <div
            style={{
              color: `${length() <= compareCount ? "#546e7a" : "#FF6D6D"}`,
              fontSize: "10px",
              position: "inherit",
              marginTop: "-12px",
              bottom: "0",
              right: "0",
              textAlign: "right",
              overflow: "hidden",
              whiteSpace: "nowrap"
            }}
          >
            {counter()}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProvideCharacterCounter;
