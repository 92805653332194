import MaterialButton from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import propTypes from "prop-types";
import React from "react";
import { Row } from "react-grid-system";

function ProfileDrawerHeader({ setProfileDrawerNotVisible, text }) {
  return (
    <Row
      className="overline position-container-header"
      style={{ marginBottom: 0 }}
    >
      <header className="primary">
        <MaterialButton
          style={{
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "1px",
            padding: "6px 12px",
            height: "36px",
            boxShadow: "none",
            margin: "9px 0px 6px 12px",
          }}
          onClick={setProfileDrawerNotVisible}
          data-cy="profile-drawer-close-button"
        >
          <Close style={{ fontSize: 20, marginRight: 4, marginLeft: -3 }} />
          Close
        </MaterialButton>
        <div
          style={{
            width: "900px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "-97px",
          }}
        >
          <h5 className="truncate" style={{ textAlign: "center" }}>
            {text}
          </h5>
        </div>
      </header>
    </Row>
  );
}

ProfileDrawerHeader.propTypes = {
  setProfileDrawerNotVisible: propTypes.func.isRequired,
  text: propTypes.string,
};

export default ProfileDrawerHeader;
