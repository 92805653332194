import startCase from "lodash/startCase";
import React from "react";

import GlobalAvatar from "../../../../../General/GlobalAvatar";

import CandidateCardHeader from "./Header";

function Profile({ drawerComponent, subtitle, candidate: { attributes } }) {
  const {
    firstName, lastName, statusText, avatar, id, jobcastId
  } = attributes;

  const renderAvatar = () => (
    <GlobalAvatar
      individual={{
        avatarIcon: avatar,
        firstName,
        lastName
      }}
      overrideStyle={{ marginLeft: 12, marginRight: 12 }}
    />
  );

  return (
    <CandidateCardHeader
      avatar={renderAvatar()}
      title={startCase(`${firstName} ${lastName}`)}
      subtitle={subtitle || statusText}
      candidateId={id}
      jobcastId={jobcastId}
      drawerComponent={drawerComponent}
    />
  );
}

export default Profile;
