import { connect } from "react-redux";

import {
  fetchConversation,
  createMessage,
} from "../../../../../actions/recruiters/candidateActions";
import { getOrganizationProfileById } from "../../../../../selectors/organizationProfileSelectors";
import ChatTab from "../../../../shared/CandidateDrawer/CandidateDrawerTabs/ChatTab";

const mapStateToProps = (state, ownProps) => {
  if (ownProps.jobcast && ownProps.jobcast.attributes) {
    return {
      organizationProile: getOrganizationProfileById(
        state,
        ownProps.jobcast.attributes.organizationProfileId
      ),
    };
  }
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchConversation: () => dispatch(fetchConversation(ownProps.candidate.id)),
  createMessage: (message) => dispatch(createMessage(ownProps.candidate.id, message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatTab);
