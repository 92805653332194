import useMediaQuery from "@material-ui/core/useMediaQuery";

import React from "react";
import { useSelector } from "react-redux";

import HeaderPrimary from "./HeaderPrimary/HeaderPrimary";
import HeaderSub from "./HeaderSub/HeaderSub";

function Header() {
  const visible = useSelector(state => state.ui.subNav.isVisible);
  const isMobile = useMediaQuery("(max-width:750px)");

  return (
    <div style={isMobile ? {} : { minWidth: 1180 }}>
      <HeaderPrimary />
      {visible && <HeaderSub />}
    </div>
  );
}

export default Header;
