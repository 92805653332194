import React, { memo } from "react";

function FieldLabel(props) {
  return (
    <div
      className={`static-input-label ${props.error ? "error" : ""}`}
      style={{ paddingBottom: 6, lineHeight: "13px" }}
    >
      {props.name}
    </div>
  );
}

export default memo(FieldLabel);
