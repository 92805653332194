import every from "lodash/every";
import filter from "lodash/filter";
import { createSelector } from "reselect";

import createDeepEqualSelector from "./createDeepEqualSelector";

export const getSearchResults = (state) => state.search.results;
export const getSearchTerm = (state) => state.search.term;
export const getSearchedTerms = (state) => state.search.searchedTerms;

export const buildFilterFunctions = ({ term, type }) => {
  const filterFunctions = [];

  if (type) {
    filterFunctions.push((searchResult) => searchResult.type === type);
  }

  if (term) {
    filterFunctions.push((searchResult) => searchResult.term === term);
  }

  return filterFunctions;
};

export const makeGetFilteredSearchResults = () => {
  const getFilters = (_state, filters = {}) => filters;

  const filtersSelector = createDeepEqualSelector(
    [getFilters],
    (filters) => filters
  );

  return createSelector(
    [getSearchResults, getSearchTerm, filtersSelector],
    (searchResults, currentTerm, filters) => {
      const appliedFilters = { term: currentTerm, ...filters };
      const filterFunctions = buildFilterFunctions(appliedFilters);

      return filter(searchResults, (searchResult) => every(filterFunctions, (filterFunc) => filterFunc(searchResult)));
    }
  );
};

export const makeGetSearchResultsLoaded = () => {
  const getRequestedTerm = (_state, term = undefined) => term;

  return createSelector(
    [getSearchedTerms, getSearchTerm, getRequestedTerm],
    (searchedTerms, currentTerm, requestedTerm) => searchedTerms.has(requestedTerm || currentTerm)
  );
};
