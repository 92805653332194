import keys from "lodash/keys";
import size from "lodash/size";
import { createContext, useMemo } from "react";
import { useSelector } from "react-redux";

import { object, string } from "../../../../../../../../util/yup";

export const ContractContext = createContext();
export const CandidateContext = createContext();

export const useInitialValues = (candidate) => {
  const costCenters = useSelector((state) => state.costCenters.items);

  return useMemo(() => ({
    fee_option_id: candidate.attributes.feeOptionChoices
      ? ""
      : candidate.attributes.feeOption.id,
    last_day: null,
    currency: "USD",
    salary: "",
    hire_start_date: null,
    workplace_country_code: "USA",
    workplace_locale: "",
    workplace_postal_code: "",
    cost_center_id: size(costCenters) === 1 ? keys(costCenters)[0] : "",
    invoice_memo: "",
  }), [costCenters, candidate]);
};

export const validationSchema = object().shape({
  fee_option_id: string().required("Cannot be Blank"),
  last_day: string().nullable().required("Cannot be blank"),
  currency: string().required("Cannot be blank"),
  salary: string()
    .required("Cannot be blank")
    .test(
      "salary-validation",
      "Must be at greater than 999",
      (value) => parseFloat(value?.replace(/,/g, "")) > 999
    ),
  hire_start_date: string().nullable().required("Cannot be blank"),
  workplace_country_code: string().required("Cannot be blank"),
  workplace_locale: string().when("workplace_country_code", {
    is: "USA",
    then: string().required("Cannot be blank."),
  }),
  workplace_postal_code: string().when("workplace_country_code", {
    is: "USA",
    then: string().required("Cannot be blank.").min(5).max(5),
  }),
  cost_center_id: string().required("Cannot be blank"),
  invoice_memo: string().max(140)
});
