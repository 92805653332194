import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import capitalize from "lodash/capitalize";
import React from "react";

import Menu from "../forms/custom/Menu";

const styles = {
  menu: {
    width: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const DisabledActionMenu = withStyles({
  disabledIconButton: {
    cursor: "default",
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
})(({ classes, TooltipProps }) => {
  const withTooltip = (children) => (
    <Tooltip {...TooltipProps}>{children}</Tooltip>
  );
  const renderIcon = () => <MoreVertIcon style={{ color: "#90A4AE" }} />;

  return (
    <IconButton
      className={classes.disabledIconButton}
      disableRipple
      disableFocusRipple
    >
      {TooltipProps ? withTooltip(renderIcon()) : renderIcon()}
    </IconButton>
  );
});

class ActionMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      items: this.createItems(props.actions)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // simple shallow compare to see if the menu options should be rebuilt
    if (this.props.actions.length !== nextProps.actions.length) {
      this.setState({ items: this.createItems(nextProps.actions) });
    }
  }

  handleOpenClick = (event) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleSelection = (item) => {
    this.setState({ anchorEl: null }, () => {
      this.props.handleActionSelection(item.action);
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null }, () => {
      this.props.handleClose && this.props.handleClose();
    });
  };

  createItems = (actions) => actions.map(
    ({
      name,
      icon,
      isVisible = true,
      disabled = false,
      tooltip,
      label,
      CustomComponent,
      CustomComponentProps,
    }) => ({
      action: name,
      title: label || name
        .split("_")
        .map((wrd) => wrd === "JOBCAST" ? wrd = "JobCast" : capitalize(wrd))
        .join(" "),
      isVisible,
      icon,
      disabled,
      tooltip,
      CustomComponent,
      CustomComponentProps,
    })
  )

  render() {
    const { anchorEl, items } = this.state;

    return (
      <div
        className={this.props.classes.menu}
        style={this.props.style || {}}
        data-cy="action-menu"
      >
        <IconButton
          id="menu"
          aria-label="More"
          aria-haspopup="true"
          onClick={this.handleOpenClick}
          style={this.props.overrideIconButtonStyles}
          data-cy={this.props.dataCy}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          handleSelection={this.handleSelection}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          items={items}
          style={({
            marginLeft: "-5.5%",
            marginTop: "-1%",
            ...this.props.overrideMenuStyles
          })}
          classes={this.props.menuClasses || {}}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ActionMenu);
