import React from "react";

function FieldSection(props) {
  return (
    <div
      style={({
        padding: "0px 12px",
        ...props.style || {}
      })}
    >
      {props.children}
    </div>
  );
}

export default FieldSection;
