import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
import React, { memo } from "react";

import { ASC, DESC } from "./constants";
import tableStyles from "./tableStyles";

const SortArrow = withStyles(tableStyles)(({ dir, customStyles, classes }) => (
  <span className={classes.sortIconContainer} style={customStyles.sortIconContainer}>
    <Icon className={classes.sortIcon} style={customStyles.sortIcon}>
      {{ [ASC]: "arrow_upward", [DESC]: "arrow_downward" }[dir]}
    </Icon>
  </span>
));

const HeaderCell = withStyles(tableStyles)(
  ({
    colKey,
    label,
    orderFunc,
    style,
    handleSortClick,
    sortDir,
    customStyles,
    classes
  }) => {
    const isSortable = Boolean(orderFunc);
    const handleClick = isSortable ? handleSortClick(colKey) : () => {};
    return (
      <div
        className={classnames(classes.tableCell, classes.headerCell)}
        style={Object.assign(
          sortDir ? { color: "#008dae" } : {},
          style,
          customStyles.headerCell
        )}
      >
        {/* future ryan: make sure the class change doesnt make other tables look weird */}
        <span className={isSortable ? classes.sortableHeaderLabel : classes.staticHeaderLabel} onClick={handleClick}>
          {label}
          {sortDir && <SortArrow dir={sortDir} customStyles={customStyles} />}
        </span>
      </div>
    );
  }
);

function TableHeader({
  columns,
  handleSortClick,
  sortKey,
  sortDir,
  customStyles,
  classes
}) {
  return (
    <div className={classnames(classes.header)} style={customStyles.header}>
      {columns.map(columnAttrs => (
        <HeaderCell
          key={columnAttrs.colKey}
          handleSortClick={handleSortClick}
          sortDir={sortKey === columnAttrs.colKey && sortDir}
          customStyles={customStyles}
          {...columnAttrs}
        />
      ))}
    </div>
  );
}

TableHeader.propTypes = {
  sortKey: PropTypes.string,
  sortDir: PropTypes.oneOf([ASC, DESC]).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      colKey: PropTypes.string.isRequired,
      label: PropTypes.node,
      orderFunc: PropTypes.func,
      style: PropTypes.object
    })
  ),
  handleSortClick: PropTypes.func.isRequired,
  customStyles: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default memo(withStyles(tableStyles)(TableHeader));
