import React from "react";
import { connect } from "react-redux";

import {
  openModal,
  updateAnimation
} from "../../../../../actions/shared/uiActions";
import Button from "../../../../forms/custom/Button";
import WithdrawModal from "../modals/WithdrawModal";

function WithdrawButton({
  candidate,
  openModal,
  overrideStyle,
  overloadedClasses,
  updateCardExitAnimation
}) {
  const handleClick = () => {
    updateCardExitAnimation();
    openModal(<WithdrawModal candidate={candidate} />);
  };

  return (
    <Button
      color="red"
      variant="secondary"
      onClick={handleClick}
      style={overrideStyle || {}}
      overloadedClasses={overloadedClasses}
      data-cy="withdraw-button"
    >
      Withdraw
    </Button>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateCardExitAnimation: () => dispatch(
    updateAnimation(`candidate-card-${ownProps.candidate.id}`, {
      classes: { exit: "bounceOutRight" }
    })
  ),
  openModal: content => dispatch(openModal(content))
});

export default connect(null, mapDispatchToProps)(WithdrawButton);
