import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchCandidate } from "../../../../actions/recruiters/candidateActions";
import { openProfileDrawer, setNotificationsDrawerVisible } from "../../../../actions/shared/uiActions";
import { getCandidate } from "../../../../selectors/candidateSelectors";

import { capitalCase } from "../../../../util/formatHelpers";
import { EVENT_HISTORY } from "../../../employer/JobCasts/view/Candidates/constants";
import LoadingPage from "../../../General/LoadingPage";
import HistoryTab from "../../../shared/CandidateDrawer/CandidateDrawerTabs/HistoryTab";
import Subheader from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";
import CandidateDrawer from "../../Candidates/view/CandidateDrawer";

function CandidateUpdate(props) {
  const {
    notification: {
      attributes: { recruiterSubmissionId }
    }
  } = props;
  const dispatch = useDispatch();

  const handleCandidateDrawerOpen = (id) => {
    dispatch(setNotificationsDrawerVisible(false));

    return dispatch(
      openProfileDrawer(
        <CandidateDrawer candidateId={id} navSelection={EVENT_HISTORY} />
      )
    );
  };

  useEffect(() => {
    dispatch(fetchCandidate(recruiterSubmissionId));
  }, [recruiterSubmissionId, dispatch]);

  const candidate = useSelector(state => getCandidate(state, recruiterSubmissionId));

  return (
    <div>
      {candidate ? (
        <>
          <Subheader
            headerText="Candidate History"
            rightChip={{
              chipHeader: "About Candidate",
              individualFirstName: candidate.attributes.firstName,
              individualLastInitial: candidate.attributes.lastName,
              individualAvatar: candidate.attributes.avatar,
              chipSubText: capitalCase(candidate.attributes.status),
              onNameClick: () => handleCandidateDrawerOpen(candidate.id),
            }}
          />

          <HistoryTab
            candidate={candidate}
            subjectType={["RecruiterCandidateUpdateNotification", "Transfer"]}
            smallContainer
            displayHeader={false}
          />
        </>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
}

export default CandidateUpdate;
