import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Form, Field, FieldArray } from "formik";
import get from "lodash/get";
import keys from "lodash/keys";
import map from "lodash/map";
import React from "react";
import { Col, Row } from "react-grid-system";

const styles = () => ({
  optionContainer: {
    width: "calc(100% - 60px)",
    paddingLeft: 12,
    display: "inline",
    "& div": {
      width: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
});
class FormikAccordionInput extends React.Component {
  renderCheckRow(field, idx, arrayHelpers, option) {
    const { classes } = this.props;
    return (
      <Row className="input-row small" style={{ padding: "12px 0px" }}>
        <div
          style={{
            paddingLeft: 12,
            display: "flex",
            alignItems: "center",
            width: "calc(100% - 24px)",
          }}
        >
          <Checkbox
            name={field.field.name}
            checked={get(field.form.values, this.props.id).includes(
              option.value
            )}
            color="primary"
            onChange={() => {
              if (
                get(field.form.values, this.props.id).includes(option.value)
              ) {
                arrayHelpers.remove(idx);
              } else {
                arrayHelpers.push(option.value);
              }
            }}
            data-cy={`${field.field.name}-checkbox`}
          />
          <Tooltip id="tooltip-icon" title={option.label}>
            <div className={classes.optionContainer}>{option.label}</div>
          </Tooltip>
        </div>
      </Row>
    );
  }

  renderOptions() {
    const { fieldArrayName } = this.props;

    return (
      <Form>
        <FieldArray
          name={fieldArrayName}
          render={(arrayHelpers) =>
            map(this.props.options, (option, idx) => (
              <Field
                key={`${option.value}`}
                name={`${fieldArrayName}.${idx}`}
                render={(field) =>
                  this.renderCheckRow(field, idx, arrayHelpers, option)
                }
              />
            ))
          }
        />
      </Form>
    );
  }

  render() {
    return (
      <div>
        <div className="static-input-label">{this.props.label}</div>
        <Row style={{ margin: "0 0 10px 0" }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className="subtitle-1"
              data-cy="jobcast-assignments"
            >
              {this.props.title} ({keys(this.props.options).length})
            </AccordionSummary>
            <AccordionDetails>
              <Row>
                <Col md={12} className="vertical-centered">
                  {this.renderOptions()}
                </Col>
              </Row>
            </AccordionDetails>
          </Accordion>
        </Row>
      </div>
    );
  }
}

export default withStyles(styles)(FormikAccordionInput);
