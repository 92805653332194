import { combineReducers } from "redux";

import announcementsReducer from "./announcementsReducer";
import jobcastHistoryReducer from "./jobcastHistoryReducer";
import jobcastOwnerTransferHistoryReducer from "./jobcastOwnerTransferHistoryReducer";
import positionCorrespondencesReducer from "./positionCorrespondencesReducer";

export default combineReducers({
  announcements: announcementsReducer,
  positionCorrespondences: positionCorrespondencesReducer,
  jobcastHistory: jobcastHistoryReducer,
  jobcastOwnerTransferHistory: jobcastOwnerTransferHistoryReducer
});
