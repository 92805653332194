import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import { createFeedbackReview } from "../../../../actions/recruiters/reviewActions";

function RejectionFeedbackCTA({ stageEvent, candidateId }) {
  const [displayGreenCheckmark, setDisplayGreenCheckmark] = useState(false);

  const dispatch = useDispatch();
  const reviewParams = {
    candidateId,
    subjectId: stageEvent.id,
  };

  const dispatchCreateFeedbackRating = useCallback(
    (data) =>
      dispatch(createFeedbackReview(data)).then(() => {
        setDisplayGreenCheckmark(true);

        setTimeout(() => {
          setDisplayGreenCheckmark(false);
        }, 5000);
      }),
    [dispatch]
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", paddingBottom: 4 }}>
      <span>{stageEvent.note}</span>
      {!stageEvent.rejectionFeedback && (
        <div>
          <span
            className="subtitle-2"
            style={{
              fontWeight: 400,
              fontStyle: "italic",
              marginRight: 12,
            }}
          >
            Is this feedback helpful?
          </span>
          <div>
            <IconButton
              style={{
                height: 21,
                width: 21,
                marginRight: 12,
                backgroundColor: "#3C9255",
              }}
              onClick={() =>
                dispatchCreateFeedbackRating({
                  ratings: [{ rating: 1, characteristic: "REJECTION_REASON" }],
                  ...reviewParams,
                })
              }
              data-cy="feedback-thumbs-up"
            >
              <ThumbUpIcon style={{ color: "#FFFFFF", fontSize: 12 }} />
            </IconButton>
            <IconButton
              style={{
                height: 21,
                width: 21,
                backgroundColor: "#FF6D6D",
              }}
              onClick={() =>
                dispatchCreateFeedbackRating({
                  ratings: [{ rating: 1, characteristic: "REJECTION_REASON" }],
                  ...reviewParams,
                })
              }
            >
              <ThumbDownIcon style={{ color: "#FFFFFF", fontSize: 12 }} />
            </IconButton>
          </div>
        </div>
      )}
      {displayGreenCheckmark && (
        <div
          style={{ display: "flex", alignItems: "center", color: "#3C9255" }}
          data-cy="feedback-rated-affirmation"
        >
          <CheckIcon style={{ height: 21, width: 21 }} />{" "}
          <span className="subtitle-2 green">RATED</span>
        </div>
      )}
    </div>
  );
}

export default RejectionFeedbackCTA;
