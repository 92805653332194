export const RECEIVE_FEATURE_FLAGS = "RECEIVE_FEATURE_FLAGS";

export const receiveFeatureFlags = (featureFlags) => ({
  type: RECEIVE_FEATURE_FLAGS,
  payload: featureFlags,
});

export const processFeatureFlags = async (data, dispatch) => {
  dispatch(receiveFeatureFlags(data));
};
