import { createStore, applyMiddleware, compose } from "redux";
import { responsiveStoreEnhancer } from "redux-responsive";
import thunk from "redux-thunk";

import RootReducer from "./rootReducer";

const middlewares = [thunk];

// if (process.env.NODE_ENV !== "production") {
//   const { createLogger } = require("redux-logger");
//   middlewares.push(createLogger());
// }

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export default function configureStore(preloadedState = {}) {
  return createStore(
    RootReducer,
    preloadedState,
    composeEnhancers(responsiveStoreEnhancer, applyMiddleware(...middlewares))
  );
}
