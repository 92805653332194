import React from "react";
import { connect } from "react-redux";

import { openModal } from "../../../../../actions/shared/uiActions";
import Button from "../../../../forms/custom/Button";
import HireDetailsModal from "../../../Payouts/sections/shared/HireDetailsModals/HireDetailsModal";

function HireDetailsButton({
  openModal, overrideStyle, overloadedClasses, candidate
}) {
  const handleClick = () => {
    openModal(<HireDetailsModal placementId={candidate.attributes.placementId} />);
  };
  return (
    <Button
      color="blue"
      variant="secondary"
      onClick={handleClick}
      style={overrideStyle || {}}
      overloadedClasses={overloadedClasses}
      data-cy="hire-details-button"
    >
      Hire Details
    </Button>
  );
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  openModal: content => dispatch(openModal(content))
});
export default connect(null, mapDispatchToProps)(HireDetailsButton);
