import React from "react";

import PageCardRowHeader from "../../../../../General/PageCardRowHeader";
import ChecklistTable from "../../../../Candidates/PreAssignmentModal/ChecklistTable/ChecklistTable";
import FormInitializer from "../../../../Candidates/PreAssignmentModal/FormInitializer";
import FieldSection from "../../ResponsesTab/FieldSection";

function PreAssignmentChecklist({ candidate }) {
  return (
    <>
      <PageCardRowHeader title="Pre-Assignment Checklist" />
      <FieldSection>
        <p>Each of these steps must be completed to begin the contract.</p>
        <FormInitializer {...{ candidate }}>
          {() => <ChecklistTable editing={false} />}
        </FormInitializer>
      </FieldSection>
    </>
  );
}

export default PreAssignmentChecklist;
