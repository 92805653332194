import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";
import { connect } from "react-redux";

import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import {
  makeGetFilteredNotifications,
  UNREAD
} from "../../../../selectors/notificationsSelectors";
import { truncateText } from "../../../../util/formatHelpers";
import JobCastLocations from "../../../General/JobCastLocations";

import styles from "./styles";

class JobCastItem extends React.Component {
  renderPrimary() {
    const { jobcast, selectedId, currentIndividual } = this.props;
    const {
      id, title, locations, organizationName
    } = jobcast.attributes;
    const isSelected = selectedId === id;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          color: "white"
        }}
      >
        <span style={{ maxWidth: 170 }}>{truncateText(title, 40)}</span>
        <span
          className={classnames("caption", {
            bold: isSelected,
            "gray-light": !isSelected
          })}
          style={{
            maxWidth: 170,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis"
          }}
        >
          {currentIndividual.employer ? (
            <JobCastLocations locations={locations} disableClick />
          ) : (
            organizationName
          )}
        </span>
      </div>
    );
  }

  renderSecondary() {
    const notificationsCount = this.props.notifications.length;

    if (notificationsCount) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              marginRight: 16,
              fontVariantNumeric: "tabular-nums lining-nums"
            }}
          >
            {notificationsCount}
          </span>
          <span className="left-drawer-item-rfi" />
        </div>
      );
    }
  }

  render() {
    const {
      setSelectedId, selectedId, jobcast, classes
    } = this.props;

    return (
      <ListItem
        className={classnames(classes.listItem, {
          [classes.selectedItem]: selectedId === jobcast.id
        })}
        button
        onClick={setSelectedId(this.props.jobcast.id)}
      >
        <ListItemText
          className={classnames(classes.listItemText)}
          disableTypography
          primary={this.renderPrimary()}
          secondary={this.renderSecondary()}
          data-cy={`jobcast-${jobcast.attributes.title}`}
        />
      </ListItem>
    );
  }
}

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();

  const mapStateToProps = (state, ownProps) => {
    const currentIndividual = getCurrentIndividual(state);

    return {
      currentIndividual,
      notifications: getFilteredNotifications(state, {
        jobcastId: ownProps.jobcast.id,
        [UNREAD]: true
      })
    };
  };

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(withStyles(styles)(JobCastItem));
