import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from 'prop-types';
import React from "react";
import { Row, Col } from "react-grid-system";

function MultiColStyledField({
  label, value, tooltip, underline
}) {
  return (
    <Row>
      <Col xs={4} style={{ marginBottom: 16 }}>
        {label}
      </Col>
      <Col
        xs={4}
        style={
          underline
            ? {
              marginBottom: 16,
              width: "100%",
              borderBottom: "1px solid #37474F",
              paddingBottom: 8
            }
            : { marginBottom: 16 }
        }
      >
        {value}
        {" "}
        {tooltip && (
          <Tooltip
            id="tooltip-icon"
            title={<span style={{ fontSize: "12px" }}>{tooltip}</span>}
          >
            <Icon style={{ fontSize: 12 }}>info_outline</Icon>
          </Tooltip>
        )}
      </Col>
    </Row>
  );
}

MultiColStyledField.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired,
  tooltip: PropTypes.node,
  underline: PropTypes.bool
};

export default MultiColStyledField;
