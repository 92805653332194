import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import classnames from "classnames";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import toLower from "lodash/toLower";
import moment from "moment";
import React from "react";
import { Row } from "react-grid-system";
import { connect } from "react-redux";

import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import {
  makeGetFilteredNotifications,
  UNREAD,
} from "../../../../selectors/notificationsSelectors";
import RejectionFeedbackCTA from "../../../recruiter/Candidates/actions/RejectionFeedbackCTA";

import withNotificationsReader from "./withNotificationsReader";

const styles = {
  row: {
    "&$rejectionFeedback": {
      backgroundColor: "#EEF7F8",
    },
  },
  dateCell: {
    color: "#37474F",
    whiteSpace: "nowrap",
    paddingLeft: 36,
    "&$rejectionFeedback": {
      fontWeight: 700,
    },
    "&$smallContainer": {
      paddingRight: 0,
      paddingLeft: 12,
    },
  },
  formattedEventCell: {
    color: "#37474F",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    "&$rejectionFeedback": {
      fontWeight: 700,
    },
    "&$smallContainer": {
      paddingRight: 0,
    },
  },
  notesCell: {
    color: "#37474F",
    wordBreak: "break-word",
    whiteSpace: "pre-wrap",
    "&$rejectionFeedback": {
      fontWeight: 700,
    },
    "&$smallContainer": {
      whiteSpace: "normal",
    },
  },
  headerContainer: {
    paddingTop: 24,
  },
  header: {
    color: "#90A4AE",
    paddingLeft: 24,
  },
  dateHeaderCell: {
    color: "#37474F",
    paddingLeft: 36,
    "&$smallContainer": {
      paddingLeft: 28,
    },
  },
  formattedEventHeaderCell: {
    color: "#37474F",
    "&$smallContainer": {
      paddingLeft: 28,
    },
  },
  notesHeaderCell: {
    color: "#37474F",
    "&$smallContainer": {
      paddingLeft: 28,
    },
  },
  rejectionFeedback: {},
  smallContainer: {},
};

class HistoryTab extends React.PureComponent {
  collectHistoryEvents() {
    const {
      currentIndividual,
      candidate: {
        attributes: {
          id: candidateId,
          stageEvents,
          transferEvents,
          submissionHistories,
          atsEmailEvents,
        },
      },
    } = this.props;

    const formatTransferEvent = (transferEvent) => ({
      id: transferEvent.id,
      date: transferEvent.updatedAt,
      formattedEvent: "JobCast Transfer",
      notes:
        "Transferred to " +
        `${transferEvent.targetPositionTitle} - ${transferEvent.targetPositionLocation}` +
        " from " +
        `${transferEvent.sourcePositionTitle} - ${transferEvent.sourcePositionLocation}`,
    });

    const formatStageEvent = (stageEvent) => ({
      id: stageEvent.id,
      date: stageEvent.updatedAt,
      formattedEvent: stageEvent.formattedEvent,
      notes: stageEvent.note,
    });

    const formatRejectedStageEvent = (stageEvent) => ({
      id: stageEvent.id,
      date: stageEvent.updatedAt,
      formattedEvent: stageEvent.formattedEvent,
      rejectionFeedback: stageEvent.rejectionFeedback,
      stage: stageEvent.stage,
      notes: (
        <RejectionFeedbackCTA
          candidateId={candidateId}
          stageEvent={stageEvent}
        />
      ),
    });

    const formatAtsEmailEvent = (atsEmailEvent) => ({
      id: atsEmailEvent.id,
      date: atsEmailEvent.actionAt,
      formattedEvent: atsEmailEvent.formattedEvent,
    });

    const formattedEvents = map(stageEvents, (stageEvent) => (toLower(stageEvent.attributes.stage) === "rejected" &&
      currentIndividual.recruiter
      ? formatRejectedStageEvent(stageEvent.attributes)
      : formatStageEvent(stageEvent.attributes)))
      .concat(
        map(transferEvents, (transferEvent) => formatTransferEvent(transferEvent.attributes))
      )
      .concat(
        map(atsEmailEvents, (atsEmailEvent) => formatAtsEmailEvent(atsEmailEvent.attributes))
      );

    if (submissionHistories && submissionHistories.length > 0) {
      formattedEvents.push(
        ...map(submissionHistories, ({ attributes }) => attributes)
      );
    }

    return orderBy(formattedEvents, "date", "desc");
  }

  renderTableCells() {
    const { classes, smallContainer } = this.props;
    const historyRows = this.collectHistoryEvents();

    return map(historyRows, (historyRow) => {
      const requiresRejectionFeedback =
        historyRow.stage &&
        toLower(historyRow.stage) === "rejected" &&
        !historyRow.rejectionFeedback;

      const classRejectionFeedback = {
        [classes.rejectionFeedback]: requiresRejectionFeedback,
      };

      const classSmallContainer = {
        [classes.smallContainer]: smallContainer,
      };

      return (
        <TableRow
          key={historyRow.id}
          className={classnames(
            classes.row,
            classRejectionFeedback,
            classSmallContainer
          )}
        >
          <TableCell
            className={classnames(
              "small",
              classes.dateCell,
              classRejectionFeedback,
              classSmallContainer
            )}
            component="th"
            scope="row"
          >
            {moment(historyRow.date).format("ll")}
          </TableCell>
          <TableCell
            className={classnames(
              "small",
              classes.formattedEventCell,
              classRejectionFeedback,
              classSmallContainer
            )}
          >
            {historyRow.formattedEvent}
          </TableCell>
          <TableCell
            className={classnames(
              "small",
              classes.notesCell,
              classRejectionFeedback,
              classSmallContainer
            )}
          >
            {historyRow.notes}
          </TableCell>
        </TableRow>
      );
    });
  }

  render() {
    const { classes, displayHeader = true } = this.props;

    return (
      <>
        {displayHeader ? (
          <Row
            className="overline position-container-header"
            style={{ marginBottom: 0 }}
          >
            <div className={classnames("primary", classes.headerContainer)}>
              <h5 className={classnames("semibold", classes.header)}>
                Timeline of candidate events
              </h5>
            </div>
          </Row>
        ) : (
          <></>
        )}

        <Table>
          <colgroup>
            <col style={{ width: "20%" }} />
            <col style={{ width: "30%" }} />
            <col style={{ width: "50%" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell
                className={classnames(
                  "small",
                  "semibold",
                  classes.dateHeaderCell
                )}
              >
                Date
              </TableCell>
              <TableCell
                className={classnames(
                  "small",
                  "semibold",
                  classes.formattedEventHeaderCell
                )}
              >
                Event
              </TableCell>
              <TableCell
                className={classnames(
                  "small",
                  "semibold",
                  classes.notesHeaderCell
                )}
              >
                Notes
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderTableCells()}</TableBody>
        </Table>
      </>
    );
  }
}

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();
  const getOfferStageReminderNotifications = makeGetFilteredNotifications();

  const mapStateToProps = (state, ownProps) => ({
    unreadNotifications: getFilteredNotifications(state, {
      [UNREAD]: true,
      recruiterSubmissionId: ownProps.candidate.id,
      subjectType: ownProps.subjectType,
    }).concat(
      getOfferStageReminderNotifications(state, {
        [UNREAD]: true,
        subjectId: ownProps.candidate.id,
        event: "OFFER-STAGE-IDLE",
      })
    ),
    currentIndividual: getCurrentIndividual(state),
  });

  return mapStateToProps;
};

export default connect(
  makeMapStateToProps,
  null
)(withNotificationsReader(withStyles(styles)(HistoryTab)));
