import { withStyles } from "@material-ui/core/styles";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";

import { openModal } from "../../../../../../../actions/shared/uiActions";
import { GET } from "../../../../../../../util/apiHelpers";
import JobCastLocations from "../../../../../../General/JobCastLocations";
import LoadingPage from "../../../../../../General/LoadingPage";
import DrawerSortableTable from "../../../../../../General/SortableTable/DrawerSortableTable";
import ToolTipColumnLabel from "../../../../../../General/SortableTable/ToolTipColumnLabel";
import RotatingEmptyState from "../../../../../../shared/EmptyState/RotatingEmptyState";
import HireDetailsModal from "../../../../../JobCasts/view/Candidates/actions/modals/HireDetailsModal";

import {
  DATE_COL,
  JOBCAST_COL,
  CANDIDATE_COL,
  ACTION_COL,
} from "../../../types/legacy/tabs/FeesTab/constants";

const styles = () => ({
  jobCastContentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "90%",
    "& > div": {
      maxWidth: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  },
});

const formatDate = (date) => moment(date).format("MMM D, YYYY");

class FeePlacementTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      dataItems: [],
    };
  }

  componentDidMount() {
    this.fetchPlacementData();
  }

  fetchPlacementData() {
    const { recruiterId } = this.props;

    const mapDataToItems = (placementData) => map(placementData, (placement) => placement.attributes);

    const initSortItems = (dataItems) => orderBy(dataItems, [
      ({ hired_at }) => (hired_at ? -1 : 1),
      ({ jobcast_title }) => jobcast_title,
    ]);

    GET(
      `/api/v3/employer/agencies/${recruiterId}/hired_recruiter_submissions`
    ).then(({ data }) => {
      const dataItems = initSortItems(mapDataToItems(data.data));
      this.setState({ dataItems, loaded: true });
    });
  }

  viewHireDetails = (recruiterSubmissionId) => {
    const { openModal } = this.props;

    openModal(<HireDetailsModal candidate={{ id: recruiterSubmissionId }} />);
  };

  tableColumns = [
    {
      colKey: DATE_COL,
      label: (
        <ToolTipColumnLabel
          labelText="Date"
          toolTipText="Date candidate was marked as hired"
        />
      ),
      renderData: ({ hired_at }) => <div>{formatDate(hired_at)}</div>,
      orderFunc: ({ hired_at }) => moment().diff(moment(hired_at)),
      style: { width: "20%", justifyContent: "flex-start" },
    },
    {
      colKey: JOBCAST_COL,
      label: "Jobcast",
      renderData: ({ jobcast_title, jobcast_locations }) => (
        <div className={this.props.classes.jobCastContentContainer}>
          <div>{jobcast_title}</div>
          <div className="small">
            <JobCastLocations locations={jobcast_locations} />
          </div>
        </div>
      ),
      orderFunc: ({ jobcast_title }) => jobcast_title.toLowerCase(),
      style: {
        width: "33%",
        justifyContent: "flex-start",
      },
    },
    {
      colKey: CANDIDATE_COL,
      label: "Candidate",
      renderData: ({ name }) => (
        <div className={this.props.classes.jobCastContentContainer}>
          <div>{name}</div>
        </div>
      ),
      orderFunc: ({ name }) => name.toLowerCase(),
      style: { width: "25%", justifyContent: "flex-start" },
    },
    {
      colKey: ACTION_COL,
      label: "Actions",
      renderData: ({ id }) => (
        <div style={{ marginLeft: "10px" }}>
          <div
            className="link pseudo-link"
            onClick={() => this.viewHireDetails(id)}
            data-cy="view-hire-link"
          >
            View Hire
          </div>
        </div>
      ),
      style: { width: "15%", justifyContent: "flex-start" },
    },
  ];

  customTableStyles() {
    const { feeOptions } = this.props;
    const feeHeight = (feeOptions.length && (feeOptions.length - 1) * 49) || 0;

    return {
      container: {
        height: `calc(100% - 231px - ${feeHeight}px)`,
        minHeight: "310px",
      },
      body: {
        height: "calc(100% - 50px)",
      },
    };
  }

  render() {
    const { loaded, dataItems } = this.state;

    if (loaded && dataItems.length > 0) {
      return (
        <DrawerSortableTable
          columns={this.tableColumns}
          items={dataItems}
          defaultSortKey={DATE_COL}
          customStyles={this.customTableStyles()}
        />
      );
    } if (loaded) {
      return <RotatingEmptyState />;
    }
    return <LoadingPage />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  openModal: (content) => dispatch(openModal(content)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(FeePlacementTable));
