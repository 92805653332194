import { OFFER, OFFERS } from "../../JobCasts/view/Candidates/constants";

export * from "../../JobCasts/view/constants";

export const PRE_ASSIGNMENT = "pre_assignment";
export const OPEN_CONTRACT = "open_contract";
export const CLOSED_CONTRACT = "closed_contract";

// temp candidates in pre-assignment are technically in 'offer' stage,
// but we want to display them as 'pre-assignment' in the UI
export function getStatusGroupFromStageLabel(stageLabel) {
  switch (stageLabel) {
    case PRE_ASSIGNMENT:
      return OFFERS;
    default:
      return stageLabel;
  }
}

export function getStageLabelFromDataStatus(dataStatus) {
  switch (dataStatus) {
    case OFFERS:
    case OFFER:
      return "Pre-Assignment";
    default:
      return dataStatus.replace(/_/g, " ");
  }
}

export function getDataStatusFromStageLabel(stageLabel) {
  switch (stageLabel) {
    case PRE_ASSIGNMENT:
      return OFFER;
    default:
      return stageLabel;
  }
}
