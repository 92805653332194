import { TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import uniqBy from 'lodash/uniqBy';
import React from 'react';
import { Col, Row } from 'react-grid-system';

import {
  currencyOptions,
  currencySymbol, servicedCountryOptions, stateOptions
} from '../../../../../../../../../util/formHelpers';
import FormContextDatePicker from '../../../../../../../../forms/formik/FormContextInputs/FormContextDatePicker';
import FormContextNumberInput from '../../../../../../../../forms/formik/FormContextInputs/FormContextNumberInput';
import FormContextTextField from '../../../../../../../../forms/formik/FormContextInputs/FormContextTextField';
import CostCenterDropdown from '../../../../../../../Billing/CostCenterDropdown';
import FormContextDropdown from '../../../../../../../JobCasts/create/formContextInputs/FormContextDropdown';
import FormContextDropdownSelect from '../../../../../../../JobCasts/create/formContextInputs/FormContextDropdownSelect';
import SectionHeader from '../../../../../../../JobCasts/view/Candidates/actions/modals/Hire/SectionHeader';
import FieldSection from '../../../../../../../Shared/Modals/sections/FieldSection';

const sortedCurrencyOptions = uniqBy(
  [
    { value: "USD", label: "$ USD", currencyCode: "USD" },
    ...currencyOptions,
  ],
  "value"
);

function HireAndBilling() {
  const {
    values, touched, errors, handleChange
  } = useFormikContext();

  return (
    <div className="body-default">
      <Row style={{ margin: "20px 24px" }}>
        <div>
          Carefully complete the form below. Upon hire, the agency will be notified
          and an invoice will be sent to your AP department. You can review your hires
          in the “Billing” section.
        </div>
      </Row>
      <SectionHeader title="Hire Information" />
      <FieldSection>
        <Row>
          <Col md={4.4} xs={12}>
            <FormContextDropdownSelect
              id="currency"
              label="Currency"
              options={sortedCurrencyOptions}
            />
          </Col>
          <Col md={3.8} xs={12}>
            <FormContextNumberInput
              id="salary"
              label="Guaranteed Compensation"
              thousandSeparator
              InputProps={{
                startAdornment: currencySymbol(values.currency),
              }}
            />
          </Col>
          <Col md={3.8} xs={12}>
            <FormContextDatePicker
              id="hire_start_date"
              label="Start Date"
            />
          </Col>
        </Row>
        <Row>
          <Col md={4.4} xs={12}>
            <FormContextDropdown
              id="workplace_country_code"
              label="Primary Work Country"
              options={servicedCountryOptions}
              isClearable={false}
            />
          </Col>
          <Col md={3.8} xs={12}>
            <FormContextDropdownSelect
              id="workplace_locale"
              label="Primary Work State"
              options={stateOptions}
            />
          </Col>
          <Col md={3.8} xs={12}>
            <FormContextTextField
              id="workplace_postal_code"
              label="Primary Work Zip"
              inputProps={{ maxLength: 5 }}
            />
          </Col>
        </Row>
      </FieldSection>
      <SectionHeader title="Billing Information" />
      <FieldSection>
        <Row>
          <Col xs={6}>
            <CostCenterDropdown
              id="cost_center_id"
              helperText={touched.cost_center_id && errors.cost_center_id}
              error={Boolean(errors.cost_center_id && touched.cost_center_id)}
              onChange={handleChange}
              value={values.cost_center_id}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              label="Invoice Memo / PO Number (Optional)"
              value={values.invoice_memo}
              inputProps={{ maxLength: 140 }}
              helperText={touched.invoice_memo && errors.invoice_memo}
              error={Boolean(errors.invoice_memo && touched.invoice_memo)}
              onChange={handleChange}
              multiline
              id="invoice_memo"
            />
          </Col>
        </Row>
      </FieldSection>
    </div>
  );
}

export default HireAndBilling;
