import map from "lodash/map";
import without from "lodash/without";

import React from "react";

import { useSelector } from "react-redux";

import { getCurrentIndividual } from "../../../selectors/individualSelectors";
import { JOBCASTS, CANDIDATES, AGENCIES } from "../Search/constants";

import OmnisearchResultsSection from "./OmnisearchResultSection";

const DEFAULT_SECTIONS = [null, CANDIDATES, JOBCASTS, AGENCIES];

export default function OmnisearchResults() {
  const currentIndividual = useSelector(getCurrentIndividual);

  const sections = currentIndividual.recruiter
    ? without(DEFAULT_SECTIONS, AGENCIES)
    : DEFAULT_SECTIONS;

  return map(sections, (type, idx) => (
    <span data-cy={`omni-results-section-${type}`}>
      <OmnisearchResultsSection {...{ type, key: `${type}-${idx}` }} />
    </span>
  ));
}
