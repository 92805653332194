import TextField from "@material-ui/core/TextField";
import React from "react";

function AdditionalInformation({
  values, touched, errors, handleChange
}) {
  return (
    <TextField
      error={Boolean(touched.note && errors.note)}
      helperText={touched.note && errors.note}
      id="note"
      label="Additional Information (optional)"
      onChange={handleChange}
      value={values.note}
      multiline
      inputProps={{ "data-cy": "report-failure-additional-information" }}
    />
  );
}

export default AdditionalInformation;
