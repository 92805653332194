import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Money from "@material-ui/icons/Money";
import moment from "moment";
import React from "react";

import Content from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Content";
import Subheader from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";

import withNotificationMarkedRead from "./withNotificationMarkedRead";

function CustomTextComponent({ label, value, helperText }) {
  return (
    <div>
      <InputLabel>{label}</InputLabel>
      <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>{value}</div>
      <FormHelperText style={{ fontSize: "12px" }}>{helperText}</FormHelperText>
    </div>
  );
}

function StandardizedFeeUpdate({
  notification: {
    attributes: {
      createdAt,
      notificationSpecifics: { previousFeeLabel, nextFeeLabel },
    },
  },
  jobcast: {
    attributes: {
      individualAvatar,
      individualFirstName,
      individualLastInitial,
      organizationName,
    },
  },
}) {
  const renderMessageBody = () => (previousFeeLabel ? (
    <>
      Your fee on this JobCast has been updated from
      {" "}
      <span className="bold">{previousFeeLabel}</span>
      {' '}
      to
      {" "}
      <span className="bold">{nextFeeLabel}</span>
    </>
  ) : (
    <>
      Your fee on this JobCast has been updated to
      {" "}
      <span className="bold">{nextFeeLabel}</span>
    </>
  ));

  return (
    <div>
      <Subheader
        leftChip={{
          chipHeader: "Update from",
          individualFirstName,
          individualLastInitial,
          individualAvatar,
          chipSubText: organizationName,
        }}
      />
      <Content
        iconImage={Money}
        iconCaption="Fee Update"
        label="Fee Change Details"
        value={renderMessageBody()}
        helperText={moment(createdAt).format("MMMM Do, YYYY")}
        CustomTextComponent={CustomTextComponent}
      />
    </div>
  );
}

export default withNotificationMarkedRead(StandardizedFeeUpdate);
