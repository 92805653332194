import React from "react";

import constants from "../../../../../../../../util/constants";
import DropdownInput from "../../../../../../../forms/custom/DropdownInput.react";

const dropdownOptions = constants.guaranteeFailureReasons.map(str => ({
  label: str,
  value: str
}));

function Reason({
  values, touched, errors, handleChange
}) {
  return (
    <DropdownInput
      label="Reason for refund"
      options={dropdownOptions}
      id="reason"
      error={Boolean(errors.reason && touched.reason)}
      onChange={handleChange}
      value={values.reason}
    />
  );
}

export default Reason;
