import { CircularProgress } from "@material-ui/core";
import { useFormikContext } from "formik";
import every from "lodash/every";
import keys from "lodash/keys";
import some from "lodash/some";
import React, { useContext } from "react";

import Button from "../../../forms/custom/Button";
import ActionModalFooter from "../../../General/ActionModal/ActionModalFooter";

import FormStepContext from "./FormStepContext";

function BackButton() {
  const { currStep, handleStepTransition } = useContext(FormStepContext);

  if (!currStep) {
    return null;
  }

  return (
    <Button
      color="grey"
      variant="secondary"
      onClick={() => handleStepTransition(-1)}
      disabled={false}
      data-cy="action-modal-button-back"
    >
      Back
    </Button>
  );
}

function ContinueButton({ finalCta = "Open Contract" }) {
  const { currStep, handleStepTransition, steps } = useContext(FormStepContext);

  const { handleSubmit, isSubmitting } = useFormikContext();

  const isFinalStep = currStep === steps.length - 1;

  const handleAction = isFinalStep
    ? handleSubmit
    : () => handleStepTransition(1);

  return (
    <div>
      <Button
        color="blue"
        variant="primary"
        onClick={handleAction}
        disabled={isSubmitting}
        data-cy="action-modal-button-next"
        style={{ marginLeft: 12 }}
      >
        {isFinalStep ? finalCta : "Next"}
      </Button>
      {isSubmitting && (
        <CircularProgress
          size={24}
          style={{
            position: "absolute",
            top: "20%",
            left: "64%",
            marginRight: "-12px",
            marginLeft: "-12px",
            color: "#90A4AE",
          }}
        />
      )}
    </div>
  );
}

function FormFooter({ sectionFields, finalCta }) {
  const { touched, errors } = useFormikContext();
  const { currStep, steps } = useContext(FormStepContext);

  const allStepFieldsTouched = every(
    keys(sectionFields[steps[currStep]]),
    (fieldName) => Boolean(touched[fieldName])
  );

  const anyStepFieldErrors = some(
    keys(sectionFields[steps[currStep]]),
    (fieldName) => Boolean(errors[fieldName])
  );

  return (
    <ActionModalFooter
      submissionError={Boolean(allStepFieldsTouched && anyStepFieldErrors)}
      customButtons={(
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <BackButton />
          <ContinueButton {...{ finalCta }} />
        </div>
      )}
    />
  );
}

export default FormFooter;
