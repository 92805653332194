export const EXPANSION_COL = "EXPANSION_COL";
export const AGENCY_COL = "AGENCY_COL";
export const RECRUITERS_COL = "RECRUITERS_COL";
export const FEE_COL = "FEE_COL";
export const PLACEMENT_TYPE_COL = "PLACEMENT_TYPE_COL";
export const RATING_COL = "RATING_COL";
export const ACTIONS_COL = "ACTIONS_COL";

export const COLUMNS = [
  EXPANSION_COL,
  AGENCY_COL,
  RECRUITERS_COL,
  FEE_COL,
  PLACEMENT_TYPE_COL,
  RATING_COL,
  ACTIONS_COL,
];
