import normalize from "json-api-normalizer";

import { GET } from "../../util/apiHelpers";

export const RECEIVE_COMMUNITY_TERMS = "RECEIVE_COMMUNITY_TERMS";

export const receiveCommunityTerms = (communityTerms) => ({
  type: RECEIVE_COMMUNITY_TERMS,
  payload: communityTerms,
});

export function fetchCommunityTerms() {
  return (dispatch) =>
    Promise.all([
      GET("/api/v3/public/community_terms"),
      GET("/api/v3/public/temp_community_terms"),
    ]).then((responses) => {
      const [permResponse, tempResponse] = responses.map((res) =>
        normalize(res.data)
      );

      const permCommunityTerms = permResponse.communityTerms;
      const { tempCommunityTerms } = tempResponse;

      dispatch(
        receiveCommunityTerms({
          perm: permCommunityTerms,
          temp: tempCommunityTerms,
        })
      );
    });
}
