import filter from "lodash/filter";
import { createSelector } from "reselect";

import { mapToInclusionHash } from "../util/formatHelpers";

export const getAllOrganizations = (state) => state.organizations.items;
export const getCurrentOrganizationId = (state) => state.organizations.currentOrganizationId;

export const getCurrentOrganization = createSelector(
  [getCurrentOrganizationId, getAllOrganizations],
  (currentOrganizationId, organizations) => (currentOrganizationId &&
      organizations[currentOrganizationId] &&
      organizations[currentOrganizationId].attributes) ||
    {}
);

export const makeGetOrganization = (organizationId) => createSelector([getAllOrganizations], (organizations) => (
  filter(organizations, ({ id }) => id === organizationId)[0] || {}
));

export const makeGetOrganizations = (ids) => {
  const isIncludedId = mapToInclusionHash(ids);
  return createSelector([getAllOrganizations], (organizations) => filter(organizations, ({ id }) => isIncludedId[id]));
};

export const getOrganization = (state, id) => makeGetOrganization(id)(state);

export const getOrganizationsById = (state, ids) => makeGetOrganizations(ids)(state);
