import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import filter from "lodash/filter";
import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import React, {
  memo, useCallback, useEffect, useMemo, useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { array, object } from "yup";

import {
  addToRecruiterLists,
  fetchRecruiterLists,
} from "../../../../actions/employers/recruiterListActions";
import { clearModal } from "../../../../actions/shared/uiActions";
import { makeGetRecruiterLists } from "../../../../selectors/employer/recruiterListSelectors";
import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import { ConnectedDropdownSelect } from "../../../forms/formik/ConnectedInputs";
import ActionModalContainer from "../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../General/ActionModal/ActionModalHeader";

import { PERSONAL_LISTS, TEAM_LISTS } from "../constants";
import { PERSONAL, TEAM } from "../RecruiterLists/constants";
import {
  createOption,
  createOptionGroup,
} from "../RecruiterLists/fieldHelpers";

const useStyles = makeStyles({ dialogPaper: { overflow: "visible" } });

function AddToListModal({ agency: { individual, organization } }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [listStatus, setListStatus] = useState("idle");
  const [options, setOptions] = useState([]);

  const getMyLists = useMemo(() => makeGetRecruiterLists(PERSONAL_LISTS), []);
  const getTeamLists = useMemo(() => makeGetRecruiterLists(TEAM_LISTS), []);
  const myLists = useSelector((state) => getMyLists(state), [listStatus]);
  const teamLists = useSelector((state) => getTeamLists(state), [listStatus]);

  const isTeamManager = useSelector((state) => getCurrentIndividual(state))
    .teamManager;

  const filteredLists = useCallback(
    (lists) => ({
      includedTeamLists: filter(
        lists,
        ({ attributes }) => includes(attributes.subjectIds, individual.id) &&
            attributes.listType === TEAM
      ),
      includedMyLists: filter(
        lists,
        ({ attributes }) => includes(attributes.subjectIds, individual.id) &&
            attributes.listType === PERSONAL
      ),
    }),
    [individual.id, myLists, teamLists]
  )(myLists.concat(teamLists));

  useEffect(() => {
    if (listStatus === "idle") {
      dispatch(fetchRecruiterLists()).then(() => setListStatus("loaded"));
    }
  }, [listStatus, dispatch]);

  useEffect(() => {
    if (listStatus === "loaded") {
      const options = [];

      if (teamLists.length > 0 && isTeamManager) {
        options.push(createOptionGroup(teamLists, "Team Lists"));
      }
      if (myLists.length > 0) {
        options.push(createOptionGroup(myLists, "Personal Lists"));
      }

      setOptions(options);
    }
  }, [listStatus]);

  const getIncludedListOptions = () => {
    const { includedMyLists, includedTeamLists } = filteredLists;

    if (includedMyLists.length + includedTeamLists.length > 0) {
      const includedLists = isTeamManager
        ? includedMyLists.concat(includedTeamLists)
        : includedMyLists;

      return map(includedLists, (list) => createOption(list));
    }
  };

  const handleSubmit = (values) => {
    const selected_list_ids = map(values.selected_lists, ({ value }) => value);
    const { includedMyLists, includedTeamLists } = filteredLists;
    const includedListIds = map(
      includedMyLists.concat(includedTeamLists),
      ({ id }) => id
    );
    const removed_list_ids = filter(
      includedListIds,
      (id) => !includes(selected_list_ids, id)
    );

    dispatch(
      addToRecruiterLists(individual.id, {
        selected_list_ids,
        removed_list_ids,
      })
    ).then(() => dispatch(clearModal()));
  };

  return (
    <Formik
      validationSchema={object().shape({
        selected_lists: array().min(1, "Must select at least one list"),
      })}
      initialValues={{
        selected_lists: getIncludedListOptions(),
      }}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <ActionModalContainer
          PaperProps={{ className: classes.dialogPaper }}
          color="blue"
        >
          <ActionModalHeader
            title="Add to List"
            subtitle={`${individual.attributes.name} - ${organization.attributes.name}`}
          />
          <ActionModalContent style={{ overflow: "visible" }}>
            <p>
              Please select the list(s) you would like to add this agency to.
            </p>
            <ConnectedDropdownSelect
              id="selected_lists"
              options={options}
              menuListProps={{ style: { maxHeight: 196 } }}
              style={{ width: "75%" }}
            />
          </ActionModalContent>
          <ActionModalFooter
            actionText="Save"
            handleAction={() => handleSubmit()}
            isSubmitting={false}
          />
        </ActionModalContainer>
      )}
    </Formik>
  );
}

export default memo(AddToListModal, isEqual);
