import SvgIcon from "@material-ui/core/SvgIcon";
import React from "react";

export default function OptimizedRatesIcon(props) {
  return (
    <SvgIcon {...props}>
      <circle cx="12" cy="12" r="8" fill="#fee738" />
      <path
        d="M12 2.02c-5.51 0-9.98 4.47-9.98 9.98s4.47 9.98 9.98 9.98 9.98-4.47 9.98-9.98S17.51 2.02 12 2.02zM11.48 20v-6.26H8L13 4v6.26h3.35L11.48 20z"
        fill="#008DAE"
      />
    </SvgIcon>
  );
}
