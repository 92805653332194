import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchContract as employerFetchContract } from "../../../../../actions/employers/contractActions";
import { fetchContract as recruiterFetchContract } from "../../../../../actions/recruiters/contractActions";
import { makeGetFilteredContracts } from "../../../../../selectors/contractSelector";
import { getAllJobcasts } from "../../../../../selectors/jobcastSelectors";
import LoadingPage from "../../../../General/LoadingPage";

import ContractInformation from "./ContractInformation/ContractInformation";
import PaymentHistory from "./PaymentHistory/PaymentHistory";
import PreAssignmentChecklist from "./PreAssignmentChecklist/PreAssignmentChecklist";

function ContractWorkTab({ candidate, employer }) {
  const dispatch = useDispatch();
  const getContracts = useMemo(makeGetFilteredContracts, []);

  const jobcast = useSelector(
    (state) => getAllJobcasts(state)[candidate.attributes.jobcastId]
  );

  const contract = useSelector((state) => {
    const items = getContracts(state, { candidateId: candidate.id });

    return items[Object.keys(items)[0]];
  });

  useEffect(() => {
    dispatch(
      employer
        ? employerFetchContract(candidate.id)
        : recruiterFetchContract(candidate.id)
    );
  }, [dispatch, candidate.id, employer]);

  if (!contract) return <LoadingPage />;

  return (
    <>
      <ContractInformation {...{
        candidate, contract, jobcast, employer
      }}
      />
      <PaymentHistory {...{ candidate, contract }} />
      <PreAssignmentChecklist {...{ candidate, contract }} />
    </>
  );
}

export default ContractWorkTab;
