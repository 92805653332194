import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchCandidate } from "../../../../../../../actions/employers/candidateActions";
import { fetchJobCast } from "../../../../../../../actions/employers/jobcastActions";
import { clearModal } from "../../../../../../../actions/shared/uiActions";
import { getCandidate } from "../../../../../../../selectors/candidateSelectors";
import { getJobCast } from "../../../../../../../selectors/jobcastSelectors";

import { buildFullName } from "../../../../../../../util/formatHelpers";
import ActionModalContainer from "../../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../../General/ActionModal/ActionModalContent";
import ActionModalHeader from "../../../../../../General/ActionModal/ActionModalHeader";
import LoadingPage from "../../../../../../General/LoadingPage";

import HireOverview from "./Hire/HireOverview";
import WorkingInformation from "./Hire/WorkingInformation";

class HireDetailsModal extends Component {
  state = { loaded: false };

  componentDidMount() {
    this.loadCandidate()
      .then(this.loadJobcast)
      .then(() => this.setState({ loaded: true }));
  }

  loadCandidate = async () => {
    const { fetchCandidate, candidateLoaded, id } = this.props;

    if (!candidateLoaded) {
      return fetchCandidate(id);
    }
  };

  loadJobcast = async () => {
    const { fetchJobCast, jobcastLoaded } = this.props;
    if (!jobcastLoaded) {
      return fetchJobCast(this.props.candidate.attributes.jobcastId);
    }
  };

  getCandidateName() {
    const { candidateLoaded } = this.props;

    if (candidateLoaded) {
      const {
        candidate: {
          attributes: { firstName, lastName },
        },
      } = this.props;
      return buildFullName(firstName, lastName);
    }
  }

  renderContent() {
    const { jobcast, candidate } = this.props;

    return (
      <>
        <HireOverview jobcast={jobcast} candidate={candidate} />
        <WorkingInformation candidate={candidate} />
        <div
          className="small"
          style={{ marginTop: 20, marginLeft: 17, marginRight: 17 }}
        >
          For billing and invoice information, please refer to the billing
          section in your navigation menu
        </div>
      </>
    );
  }

  render() {
    const { loaded } = this.state;

    return (
      <ActionModalContainer color="blue">
        <ActionModalHeader
          title="Hire Details"
          subtitle={this.getCandidateName()}
        />
        <ActionModalContent>
          {loaded ? this.renderContent() : <LoadingPage />}
        </ActionModalContent>
      </ActionModalContainer>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const candidate = getCandidate(state, ownProps.candidate.id);
  const jobcast =
    candidate && getJobCast(state, candidate.attributes.jobcastId);
  return {
    jobcast,
    candidate,
    jobcastLoaded: Boolean(jobcast && jobcast.loaded),
    candidateLoaded: Boolean(candidate && candidate.loaded),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearModal: () => dispatch(clearModal()),
  fetchJobCast: (jobcastId) => dispatch(fetchJobCast(jobcastId)),
  fetchCandidate: () => dispatch(fetchCandidate(ownProps.candidate.id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HireDetailsModal);
