import { useFormikContext } from "formik";
import isEqual from "lodash/isEqual";
import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import ActionModalFooter from "../../../General/ActionModal/ActionModalFooter";

import { allChecklistItemsComplete } from "./fieldHelpers";

function FormFooter({ initialValues }) {
  const currentIndividual = useSelector(getCurrentIndividual);
  const { values, isSubmitting, handleSubmit } = useFormikContext();

  const isDisabled = useMemo(
    () => isEqual(values, initialValues),
    [initialValues, values]
  );

  const isCompleteOnSubmit = useMemo(
    () => allChecklistItemsComplete(values.checklistItems, currentIndividual),
    [values, currentIndividual]
  );

  return (
    <ActionModalFooter
      actionText={isCompleteOnSubmit ? "Complete" : "Update"}
      handleAction={handleSubmit}
      isSubmitting={isSubmitting}
      disabled={isDisabled}
    />
  );
}

export default FormFooter;
