import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

import { ARCHIVE_POSITION_CORRESPONDANCE } from "../../actions/employers/jobcastUpdatesActions";
import {
  RECEIVE_POSITION_CORRESPONDENCES,
  RECEIVE_POSITION_CORRESPONDANCE,
  RECEIVE_POSITION_CORRESPONDANCE_LOADED
} from "../../actions/shared/jobcastUpdatesActions";

const _defaultState = {
  loaded: {},
  items: {}
};

const positionCorrespondencesReducer = (state = _defaultState, action) => {
  Object.freeze(state);
  let newState;

  switch (action.type) {
    case ARCHIVE_POSITION_CORRESPONDANCE:
      newState = cloneDeep(state.items);
      delete newState[action.payload];
      return { ...state, items: newState };
    case RECEIVE_POSITION_CORRESPONDENCES:
    case RECEIVE_POSITION_CORRESPONDANCE:
      return merge({}, state, { items: action.payload });
    case RECEIVE_POSITION_CORRESPONDANCE_LOADED:
      return merge({}, state, { loaded: { [action.payload]: true } });
    default:
      return state;
  }
};

export default positionCorrespondencesReducer;
