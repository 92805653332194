import { FormControlLabel, Radio } from "@material-ui/core";
import { useFormikContext } from "formik";
import get from "lodash/get";
import React from "react";
import { useSelector } from "react-redux";

import { getCurrentOrganization } from "../../../../../selectors/organizationSelectors";
import RadioGroup from "../../../../forms/custom/RadioGroup";
import OptimizedRatesIcon from "../../../../shared/Icons/OptimizedRatesIcon";
import FieldLabel from "../../../JobCasts/view/Description/sections/FieldLabel";

function FeeTypeField({ scope, label }) {
  const { values, setFieldValue } = useFormikContext();
  const currentOrganization = useSelector(getCurrentOrganization);

  const scoped = (str) => [scope, str].join(".");

  return (
    <>
      <FieldLabel name={label} />
      <RadioGroup
        id={scoped("feeType")}
        onChange={(_, value) => setFieldValue(scoped("feeType"), value)}
        style={{ padding: "0 0 16px 32px" }}
        value={get(values, scoped("feeType"))}
      >
        <FormControlLabel
          control={
            <Radio color="primary" data-cy="optimized-fee-radio-button" />
          }
          label={(
            <div style={{ display: "flex", alignItems: "center" }}>
              <OptimizedRatesIcon
                style={{ marginRight: 8, fontSize: 24 }}
              />
              RecruitiFi Optimized Fee
              {" "}
              <a
                style={{ marginLeft: 4 }}
                href="https://help.recruitifi.com/en/articles/5485229-what-is-the-recruitifi-optimized-fee-and-how-does-it-work"
                target="_blank"
                rel="noopener noreferrer"
              >
                (Learn More)
              </a>
            </div>
          )}
          value="optimized"
        />
        {currentOrganization.hasStandardizedPreferredFees ? (
          <FormControlLabel
            control={<Radio color="primary" />}
            label={`${currentOrganization.name}'s Standardized Fees`}
            value="standardized"
            data-cy="standardized-fee-radio-button"
          />
        ) : (
          <FormControlLabel
            control={<Radio color="primary" />}
            label="Custom Fee"
            value="custom"
            data-cy="custom-fee-radio-button"
          />
        )}
      </RadioGroup>
    </>
  );
}

export default FeeTypeField;
