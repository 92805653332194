import { chain } from "lodash";
import round from "lodash/round";
import React, { useState, useEffect } from "react";

import { GET } from "../../../../../../../../util/apiHelpers";
import { alpha3ToName } from "../../../../../../../../util/constantsToOptions";

import PageCardRowHeader from "../../../../../../../General/PageCardRowHeader";
import DrawerSortableTable from "../../../../../../../General/SortableTable/DrawerSortableTable";
import ToolTipColumnLabel from "../../../../../../../General/SortableTable/ToolTipColumnLabel";

import FeePlacementTable from "../../../../shared/tabs/FeesTab/FeePlacementTable";

import { JOBCAST_LOCATION_COL, COMMUNITY_FEE_COL } from "./constants";

const DAYS_FROM_START_COL = "days_from_start_col";
const CLIENT_BILL_COL = "client_bill_col";

const tableColumns = [
  {
    colKey: JOBCAST_LOCATION_COL,
    label: (
      <ToolTipColumnLabel
        labelText="JobCast Location"
        toolTipText="This Agency may not work in all of these countries. To see what countries they work in, please click on the Profile tab."
      />
    ),
    renderData: ({ country }) => <div>{country}</div>,
    style: { width: "30%", justifyContent: "flex-start" },
  },
  {
    colKey: COMMUNITY_FEE_COL,
    label: "Community Fee",
    renderData: ({ fee_option }) => (
      <div>{`${round(fee_option.fee_percentage * 100, 2)}%`}</div>
    ),
    style: { width: "30%", justifyContent: "flex-start" },
  },
];

const tempTableColumns = [
  {
    colKey: DAYS_FROM_START_COL,
    label: "Days From Start",
    renderData: ({ note }) => <div>{note}</div>,
    style: { width: "50%", justifyContent: "flex-start" },
  },
  {
    colKey: CLIENT_BILL_COL,
    label: "Client Bill",
    renderData: ({ fee_percentage }) => (
      <div>{`${round(fee_percentage * 100, 2)}%`}</div>
    ),
    style: { width: "50%", justifyContent: "flex-start" },
  },
];

export function TempCommunityFeeStructure() {
  const [tempCommunityTerms, setTempCommunityTerms] = useState([]);

  useEffect(() => {
    GET("/api/v3/public/temp_community_terms").then(({ data: { data } }) => {
      const terms = data[0].attributes.conversion_fee_options;

      setTempCommunityTerms(terms);
    });
  }, []);

  return (
    <div>
      <DrawerSortableTable
        columns={tempTableColumns}
        items={tempCommunityTerms}
        customStyles={{
          container: {
            height: "304px",
          },
          body: {
            height: "calc(100% - 50px)",
            overflowY: "auto",
          },
          bodyRow: {
            height: 50,
          },
          header: {
            marginBottom: 0,
          },
        }}
      />
    </div>
  );
}

export function CommunityFeeStructure() {
  const [communityTerms, setCommunityTerms] = useState([]);

  useEffect(() => {
    GET("/api/v3/public/community_terms").then(({ data: { data } }) => {
      const terms = chain(data)
        .map(({ attributes }) => ({
          ...attributes,
          country: alpha3ToName(attributes.country_code),
        }))
        .sortBy(({ country, country_code }) =>
          country_code === "USA" ? "AAA" : country
        )
        .value();
      setCommunityTerms(terms);
    });
  }, []);

  return (
    <div>
      <DrawerSortableTable
        columns={tableColumns}
        items={communityTerms}
        customStyles={{
          container: {
            height: "304px",
          },
          body: {
            height: "calc(100% - 50px)",
            overflowY: "auto",
          },
          bodyRow: {
            height: 50,
          },
          header: {
            marginBottom: 0,
          },
        }}
      />
    </div>
  );
}

function FeesTab({ agency }) {
  return (
    <div>
      <PageCardRowHeader
        title="RecruitiFi Optimized Fee Structure"
        style={{ textAlign: "left" }}
      />
      <CommunityFeeStructure />
      <PageCardRowHeader
        title="Recent Placements"
        style={{ textAlign: "left" }}
      />
      <FeePlacementTable recruiterId={agency.individual.id} feeOptions={[]} />
    </div>
  );
}

export default FeesTab;
