import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchOrganizationProfile } from "../../../../actions/recruiter/organizationProfileActions";
import { fetchCandidate } from "../../../../actions/recruiters/candidateActions";
import {
  openProfileDrawer,
  setNotificationsDrawerVisible,
} from "../../../../actions/shared/uiActions";
import { getCandidate } from "../../../../selectors/candidateSelectors";

import { getOrganizationProfileById } from '../../../../selectors/organizationProfileSelectors';
import { capitalCase } from "../../../../util/formatHelpers";
import { AGENCY_CHAT } from "../../../employer/JobCasts/view/Candidates/constants";
import LoadingPage from "../../../General/LoadingPage";
import Subheader from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";
import CandidateDrawer from "../../Candidates/view/CandidateDrawer";
import ChatTab from "../../Candidates/view/CandidateDrawerTabs/EmployerChat";

function EmployerChat({
  notification: {
    attributes: { recruiterSubmissionId }
  },
  jobcast: {
    attributes: {
      individualAvatar,
      individualFirstName,
      individualLastInitial,
      organizationName,
      organizationProfileId
    }
  }
}) {
  const dispatch = useDispatch();
  const dispatchFetchCandidate = useCallback(
    () => dispatch(fetchCandidate(recruiterSubmissionId)),
    [recruiterSubmissionId, dispatch]
  );

  const organizationProfile = useSelector(state => getOrganizationProfileById(state, organizationProfileId));

  useEffect(() => {
    if (!organizationProfile) {
      dispatch(fetchOrganizationProfile(organizationProfileId));
    }
  }, [dispatch, organizationProfile, organizationProfileId]);

  const handleCandidateDrawerOpen = (id) => {
    dispatch(setNotificationsDrawerVisible(false));

    return dispatch(
      openProfileDrawer(
        <CandidateDrawer candidateId={id} navSelection={AGENCY_CHAT} />
      )
    );
  };

  useEffect(() => {
    dispatchFetchCandidate();
  }, [dispatchFetchCandidate]);

  const candidate = useSelector(state => getCandidate(state, recruiterSubmissionId));

  return (
    <div>
      {candidate && organizationProfile ? (
        <>
          <Subheader
            leftChip={{
              chipHeader: "Chatting With",
              individualFirstName,
              individualLastInitial,
              individualAvatar,
              chipSubText: organizationName,
            }}
            rightChip={{
              chipHeader: "About Candidate",
              individualFirstName: candidate.attributes.firstName,
              individualLastInitial: candidate.attributes.lastName,
              individualAvatar: candidate.attributes.avatar,
              chipSubText: capitalCase(candidate.attributes.status),
              onNameClick: () => handleCandidateDrawerOpen(candidate.id),
            }}
          />
          <div style={{ height: "calc(100vh - 150px)" }}>
            <ChatTab
              candidate={candidate}
              organizationProfile={organizationProfile}
              multiTextStyle={{
                width: "65%",
                paddingTop: 12,
              }}
            />
          </div>
        </>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
}

export default EmployerChat;
