import { withStyles } from "@material-ui/core/styles";

import { ResponsivePie } from "@nivo/pie";
import PropTypes from "prop-types";
import React from "react";

export const DonutChartLegend = withStyles({
  legendContainer: {
    height: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  legendItemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  legendItemLabel: {
    fontSize: "13px",
    lineHeight: "16px",
    whiteSpace: "normal",
    fontWeight: 600,
    color: "#B0BEC5",
    fontFamily: "proxima-nova",
    letterSpacing: "0.4px",
    textTransform: "uppercase",
    maxHeight: 22,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "right",
    marginRight: 8
  },
  legendItemColor: {
    height: 12,
    width: 12,
    minHeight: 12,
    minWidth: 12
  }
})(({ data = [], classes, style = {} }) => (
  <div
    style={({ width: "34%", ...style })}
    className={classes.legendContainer}
  >
    {data.map((datum, i) => (
      <div className={classes.legendItemContainer} key={`${datum.label}${i}`}>
        <div className={classes.legendItemLabel}>{datum.label}</div>
        <div
          className={classes.legendItemColor}
          style={{ backgroundColor: datum.color }}
        />
      </div>
    ))}
  </div>
));

DonutChartLegend.propTypes = {
  data: PropTypes.array,
  style: PropTypes.object
};

export const DonutChartSVG = withStyles({
  svgContainer: {
    position: "relative",
    height: "100%"
  },
  centerTextContainer: {
    position: "absolute",
    width: "50%",
    height: "50%",
    top: "25%",
    left: "25%",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  }
})(
  ({
    data = [],
    totalLabel,
    style = {},
    classes,
    innerRadius = 0.75,
    enableSlicesLabels = true,
    tooltipFormat
  }) => {
    const total = data.reduce((accum, datum) => datum.value + accum, 0);
    const renderedData = data.filter(({ value }) => value > 0);
    const colors = renderedData.map(({ color }) => color);

    return (
      <div
        className={classes.svgContainer}
        style={({ width: "66%", ...style })}
        data-cy="pie-chart"
      >
        <ResponsivePie
          data={renderedData}
          innerRadius={innerRadius}
          colors={colors}
          enableRadialLabels={false}
          enableSlicesLabels={enableSlicesLabels}
          padAngle={1}
          cornerRadius={3}
          tooltipFormat={tooltipFormat}
        />
        {totalLabel && (
          <span className={classes.centerTextContainer}>
            <h3>{total}</h3>
            <span className="subtitle-1">{totalLabel}</span>
          </span>
        )}
      </div>
    );
  }
);

DonutChartSVG.propTypes = {
  data: PropTypes.array,
  totalLabel: PropTypes.node,
  style: PropTypes.object
};

export const DonutChartContainer = withStyles({
  donutChartContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
})(({
  data, style, classes, children
}) => (
  <div style={style} className={classes.donutChartContainer}>
    {React.Children.map(children, childEl => React.cloneElement(childEl, { data }))}
  </div>
));

DonutChartContainer.propTypes = {
  data: PropTypes.array.isRequired,
  style: PropTypes.object,
  children: PropTypes.node
};
