// agency drawer tabs
export const JOBCASTS_TAB = 0;
export const FEES_TAB = 1;
export const STATISTICS_TAB = 2;
export const PROFILE_TAB = 3;
export const CONTRACT_WORK_TAB = 4;

// relationship types
export const LEGACY = "legacy";

// legacy agency action menu
export const buildLegacyAgencyActions = (
  isAgencyManager,
  recruiterListCount
) => [
  {
    name: "edit_fee",
    icon: "edit",
    isVisible: isAgencyManager,
  },
  {
    name: "add_to_jobs",
    icon: "playlist_add",
  },
  {
    name: "edit_list",
    icon: "recent_actors",
    isVisible: recruiterListCount > 0,
  },
  {
    name: "delete_agency",
    icon: "delete",
    isVisible: isAgencyManager,
  },
  {
    name: "report_violation",
    icon: "flag",
  }
];

// community agency action menu
export const buildCommunityAgencyActions = (alreadyRemoved) => [
  {
    name: "add_to_preferred_agencies",
    icon: "group_add",
  },
  {
    name: "remove_from_community",
    icon: "bookmark_remove",
    disabled: alreadyRemoved,
    tooltip: alreadyRemoved && { placement: "left", title: "Already Removed" },
  },
  {
    name: "report_violation",
    icon: "flag",
  }
];
