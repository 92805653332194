import isArray from "lodash/isArray";
import map from "lodash/map";
import startCase from "lodash/startCase";
import moment from "moment";
import React from "react";

import PageCardRowHeader from "../../../../../General/PageCardRowHeader";

class ProfileTab extends React.PureComponent {
  renderList(label, content) {
    const formattedContent = isArray(content) ? (
      map(content, (listEl) => (
        <div style={{ marginLeft: 24, width: "200px" }}>{listEl}</div>
      ))
    ) : (
      <div style={{ marginLeft: 24, width: "200px" }}>{content}</div>
    );

    return (
      <div
        style={{ minWidth: 180 }}
        data-cy={`jobcast-tab-list-${label.toLowerCase()}`}
      >
        <div className="field-label" style={{ paddingLeft: 24 }}>
          {label}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: 232,
            overflow: "auto",
            paddingRight: 12,
          }}
        >
          {formattedContent}
        </div>
      </div>
    );
  }

  render() {
    const {
      roles,
      industries,
      levels,
      profileRegions,
      states,
      relationshipType,
      invitedBy,
      activatedAt,
    } = this.props.agency.individual.attributes;
    const { isCommunity } = this.props;

    const relationship = !isCommunity ? "Preferred" : startCase(relationshipType);

    return (
      <>
        <PageCardRowHeader title="Relationship Details" />
        <div style={{ display: "flex" }}>
          {this.renderList("Relationship", relationship)}
          {this.renderList(
            "Joined RecruitiFi",
            moment(activatedAt).format("MMM Do, YYYY")
          )}
          {invitedBy && this.renderList("Invited By", invitedBy)}
        </div>
        <PageCardRowHeader title="Specialties" />
        <div style={{ display: "flex" }}>
          {this.renderList("Roles", roles)}
          {this.renderList("Industries", industries)}
          {this.renderList("Levels", levels.slice().reverse())}
        </div>
        <PageCardRowHeader title="Geographies" />
        <div style={{ display: "flex", marginTop: "30px" }}>
          {this.renderList("Countries", profileRegions.slice().sort())}
          {states.length
            ? this.renderList("States", states.slice().sort())
            : ""}
        </div>
      </>
    );
  }
}

export default ProfileTab;
