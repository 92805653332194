import {
  SET_SUB_NAV_CONTENT,
  CLEAR_SUB_NAV,
} from "../../actions/shared/uiActions";

const _defaultState = {
  content: "",
  isVisible: false,
};

export const subNavReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case SET_SUB_NAV_CONTENT:
      return {
        ...state,
        content: action.payload,
        isVisible: true,
      };
    case CLEAR_SUB_NAV:
      return _defaultState;
    default:
      return state;
  }
};
