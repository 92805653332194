import map from "lodash/map";
import orderBy from "lodash/orderBy";
import partition from "lodash/partition";
import React, { useState, useEffect } from "react";

import { buildFullName } from "../../../util/formatHelpers";
import LeftNavDropdown, {
  createOption,
  dividerOption,
  createOptionLabel,
} from "../../forms/custom/LeftNavDropdown/LeftNavDropdown";

export const meOption = {
  key: "me",
  label: createOptionLabel("Me"),
  value: "Me",
  selectedLabel: "Me",
  id: null,
};

const createTeammateOption =
  (tempSide = false) =>
    ({
      attributes: {
        id, firstName, lastName, jobcastCount
      }
    }) =>
      createOption({
        id,
        labelPrefix: buildFullName(firstName, lastName),
        count: tempSide ? null : jobcastCount,
        selectedLabel: firstName,
      });

function ChangeTeamMemberDropdown({
  teammates,
  viewingAs,
  fetchTeammates,
  setViewingAs,
  tempSide,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const orderedTeammates = orderBy(
    teammates,
    (teammate) => teammate.attributes.firstName
  );

  const [activeTeammates, inactiveTeammates] = partition(
    orderedTeammates,
    (teammate) => teammate.attributes.jobcastCount
  );

  const inactiveOptions = inactiveTeammates.length
    ? [dividerOption, ...map(inactiveTeammates, createTeammateOption(tempSide))]
    : [];

  const options = tempSide
    ? [meOption, ...map(orderedTeammates, createTeammateOption(tempSide))]
    : [
      meOption,
      ...map(activeTeammates, createTeammateOption()),
      ...inactiveOptions,
    ];

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleSelection = (option) => {
    setViewingAs(option);
    toggleOpen();
  };

  useEffect(() => {
    if (isOpen) {
      fetchTeammates().then(() => setIsLoaded(true));
    } else {
      setIsLoaded(false);
    }
  }, [isOpen, setIsLoaded, fetchTeammates]);

  return (
    <LeftNavDropdown
      options={options}
      handleSelection={handleSelection}
      selectedOption={viewingAs}
      isOpen={isOpen}
      toggleOpen={toggleOpen}
      isLoaded={isLoaded}
    />
  );
}

export default ChangeTeamMemberDropdown;
