import { RECEIVE_RELEASE_TOKEN } from "../actions/shared/releaseTokenActions";

const defaultState = {
  currToken: null,
};

export default function releaseTokenReducer(state = defaultState, action) {
  switch (action.type) {
    case RECEIVE_RELEASE_TOKEN:
      return { currToken: action.payload };
    default:
      return state;
  }
}
