import Card from "@material-ui/core/Card";
import React from "react";

function EmptyResults() {
  return (
    <Card
      style={{
        position: "absolute",
        width: "100%",
        minWidth: 120,
        maxHeight: 270,
        zIndex: 99,
        boxShadow: "6px 8px 12px"
      }}
    >
      <div
        style={{
          width: "100%",
          height: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <h5 style={{ color: "#90A4AE" }}>No Results Found</h5>
      </div>
    </Card>
  );
}
export default EmptyResults;
