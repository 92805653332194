import React from "react";

function PageCardRowHeader(props) {
  return (
    <div style={props.style}>
      <h5
        className="page-card-row-header"
        style={props.titleStyle ? props.titleStyle : {}}
      >
        {props.title}
        {props.chip}
        {props.action}
      </h5>
      <hr
        className="divider"
        style={({

          width: "calc(100% - 48px)",
          marginBottom: 24,
          borderTop: "1px solid #B0BEC5",
          ...props.lineStyle
        })}
      />
    </div>
  );
}

export default PageCardRowHeader;
