import React from "react";

import { parseSupportingInfo } from "../../../shared/Search/utils";

function SearchResultItemSupportInfo({ item }) {
  return (
    <div
      className="small"
      style={{
        textAlign: "end",
        paddingRight: 12,
        width: "100%",
        maxWidth: 100,
        display: "flex",
        justifyContent: "center",
        color: "#37474F",
        fontSize: 16,
        fontWeight: 600
      }}
    >
      {parseSupportingInfo(item)}
    </div>
  );
}

export default SearchResultItemSupportInfo;
