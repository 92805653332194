import moment from "moment";
import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { openProfileDrawer } from "../../../../../../../actions/shared/uiActions";
import { makeGetFilteredContracts } from "../../../../../../../selectors/contractSelector";
import GlobalAvatar from "../../../../../../General/GlobalAvatar";
import LoadingPage from "../../../../../../General/LoadingPage";
import DrawerSortableTable from "../../../../../../General/SortableTable/DrawerSortableTable";
import RotatingEmptyState from "../../../../../../shared/EmptyState/RotatingEmptyState";
import EmployerCandidateDrawer from "../../../../../JobCasts/view/Candidates/view/CandidateDrawer";

const CONTRACT_WORK = "contract_work";
const NAME_COL = "nameCol";
const JOB_TITLE_COL = "jobTitleCol";
const HOURS_WORKED_COL = "hoursWorkedCol";
const CONTRACT_END_COL = "contractEndCol";

const ClosedContractTable = ({ recruiterId }) => {
  const dispatch = useDispatch();
  const getContracts = useMemo(makeGetFilteredContracts, []);
  const loaded = useSelector((state) => state.contracts.loaded);

  const closedContracts = useSelector((state) => getContracts(
    state,
    { closed: true, recruiterId }
  ));

  const closedDataItems = closedContracts.map(item => item.attributes);
  const formatDate = (date) => moment(date).format("MMM D, YYYY");

  const closedTableColumns = [
    {
      colKey: "AVATAR",
      label: "",
      renderData: ({ avatarIcon, firstName, lastName }) => (
        <GlobalAvatar
          individual={{
            avatarIcon,
            firstName,
            lastName,
          }}
        />
      ),
      style: { width: "6%" }
    },
    {
      colKey: NAME_COL,
      label: "Name",
      renderData: ({ candidateName, recruiterSubmissionId }) => {
        const handleClick = () => {
          dispatch(openProfileDrawer(
            <EmployerCandidateDrawer
              candidateId={recruiterSubmissionId}
              navSelection={CONTRACT_WORK}
            />
          ));
        };
        return (
          <div
            role="button"
            style={{
              color: "#008DAE",
              textDecoration: "underline",
              cursor: "pointer"
            }}
            className="truncate psuedo-link"
            onClick={handleClick}
          >
            {candidateName}
          </div>
        );
      },
      orderFunc: ({ candidateName }) => candidateName.toLowerCase(),
      style: { width: "20%", justifyContent: "flex-start" },
    },
    {
      colKey: JOB_TITLE_COL,
      label: "Job Title",
      renderData: ({ jobTitle }) => (
        <span>
          {jobTitle}
        </span>
      ),
      orderFunc: ({ jobTitle }) => jobTitle.toLowerCase(),
      style: { width: "30%", justifyContent: "flex-start" },
    },
    {
      colKey: CONTRACT_END_COL,
      label: "Contract End",
      renderData: ({ scheduledEndDate }) => (
        <span>
          {formatDate(scheduledEndDate)}
        </span>
      ),
      orderFunc: ({ scheduledEndDate }) => moment().diff(moment(scheduledEndDate)),
      style: { width: "22%", justifyContent: "center" },
    },
    {
      colKey: HOURS_WORKED_COL,
      label: "Hours Worked",
      renderData: ({ totalHours }) => (
        <span>
          {parseFloat(totalHours)}
        </span>
      ),
      orderFunc: ({ totalHours }) => parseFloat(totalHours),
      style: { width: "22%", justifyContent: "center" },
    }
  ];

  return (
    <>
      {!loaded && <LoadingPage />}
      {loaded && closedContracts.length > 0 && (
        <DrawerSortableTable
          columns={closedTableColumns}
          items={closedDataItems}
        />
      )}
      {loaded && closedContracts.length === 0 && <RotatingEmptyState />}
    </>
  );
};

export default ClosedContractTable;
