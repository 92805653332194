import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import tableStyles from "./tableStyles";

const TableRow = withStyles(tableStyles)(
  ({
    columns, data, classes, customStyles
  }) => (
    <div className={classnames(classes.bodyRow)} style={customStyles.bodyRow} data-cy="contract-row">
      {columns.map(({ colKey, renderData, style }) => (
        <div
          key={colKey}
          data-cy={`table-cell-${colKey}`}
          className={classnames(classes.tableCell)}
          style={({ ...style || {}, ...customStyles.bodyCell })}
        >
          {renderData(data)}
        </div>
      ))}
    </div>
  )
);

function TableRows({
  items, columns, customStyles, classes
}) {
  return (
    <div className={classes.tableBody} style={customStyles.body}>
      {items.map(itemData => (
        <TableRow
          key={itemData.id}
          data={itemData}
          columns={columns}
          customStyles={customStyles}
        />
      ))}
    </div>
  );
}

TableRows.propTypes = {
  items: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  customStyles: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(tableStyles)(TableRows);
