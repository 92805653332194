import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import JobcastsTab from "./JobCastsTab/JobcastsTab";
import NotificationDrawer from "./NotificationsDrawer";

import NotificationsIndexTab from "./NotificationsIndexTab/NotificationsIndexTab";
import NotificationViewTab from "./NotificationViewTab/NotificationViewTab";

class NotificationsCenter extends Component {
  state = {
    selectedJobCastId: null,
    selectedNotification: null
  };

  componentDidMount() {
    const { jobcastId, notificationId } = this.props;

    if (jobcastId && notificationId) {
      this.setState({
        selectedJobCastId: jobcastId,
        presetNotificationId: notificationId,
      });
    }
  }

  setSelectedJobCastId = jobcastId => () => {
    this.setState({ selectedJobCastId: jobcastId, selectedNotification: null });
  };

  setSelectedNotification = notification => {
    this.setState({ selectedNotification: notification });
  };

  render() {
    const { selectedJobCastId, selectedNotification } = this.state;

    return (
      <NotificationDrawer
        hasSelectedNotification={Boolean(selectedNotification)}
      >
        <JobcastsTab
          setSelectedId={this.setSelectedJobCastId}
          selectedId={selectedJobCastId}
        />
        <NotificationsIndexTab
          jobcastId={selectedJobCastId}
          selectedNotification={selectedNotification}
          setSelectedNotification={this.setSelectedNotification}
          presetNotificationId={this.state.presetNotificationId}
        />
        <NotificationViewTab notification={selectedNotification} />
      </NotificationDrawer>
    );
  }
}

export default withRouter(NotificationsCenter);
