import merge from "lodash/merge";
import values from "lodash/values";

import { RECEIVE_ORGANIZATION_POSITION_TEMPLATE } from "../../actions/employers/organizationActions";

const _defaultState = {};

export const organizationPositionTemplateReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_ORGANIZATION_POSITION_TEMPLATE:
      return merge({}, state, values(action.payload)[0]);
    default:
      return state;
  }
};

export default organizationPositionTemplateReducer;
