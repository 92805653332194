import normalize from "json-api-normalizer";

import { GET } from "../../util/apiHelpers";
import {
  receiveNotifications,
  setNotificationsLoaded
} from "../shared/notificationActions";

export const fetchNotifications = () => dispatch => GET("/api/v3/employer/notifications")
  .then(res => {
    const normalized = normalize(res.data);
    dispatch(receiveNotifications(normalized.notification));
    dispatch(setNotificationsLoaded(true));
  })
  .catch(error => Promise.reject(error.response.data));

export const fetchFullUnreadNotifications = () => dispatch => GET("/api/v3/employer/notifications?full=true")
  .then(res => {
    const normalized = normalize(res.data);
    return dispatch(receiveNotifications(normalized.notification));
  })
  .catch(errors => Promise.reject(errors.response.data));

export const fetchJobcastNotifications = (
  jobcastId,
  filter = false
) => dispatch => GET(
  `/api/v3/employer/jobcasts/${jobcastId}/notifications?filter=${filter}`,
  {}
)
  .then(res => {
    const normalized = normalize(res.data);
    return dispatch(receiveNotifications(normalized.notification));
  })
  .catch(errors => Promise.reject(errors.response.data));
