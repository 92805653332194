import normalize from "json-api-normalizer";

import { GET } from "../../util/apiHelpers";

import { receiveSearchResults } from "../shared/searchActions";
import { makeGetSearchResultsLoaded } from "../../selectors/searchSelectors";

export const fetchSearchResults = term => dispatch => GET(`/api/v3/recruiter/search?query=${term}`).then(res => {
  const normalized = normalize(res.data);
  dispatch(receiveSearchResults(normalized.search || {}));
});

const getSearchResultsLoaded = makeGetSearchResultsLoaded();
export const fetchSearchResultsV2 = (term) => (dispatch, getState) => {
  if (!getSearchResultsLoaded(getState(), term)) {
    return GET(`/api/v3/recruiter/search?query=${term}`).then((res) => {
      const normalized = normalize(res.data);
      return dispatch(receiveSearchResults(normalized.search || {}, term));
    });
  }
  return Promise.resolve();
};
