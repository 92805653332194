import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Check from "mdi-material-ui/Check";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";

import { remindCandidate } from "../../../../actions/recruiters/candidateActions";
import Button from "../../../forms/custom/Button";

import withSnackbar from "../../../General/withSnackbar";

const styles = {
  reminderText: {
    color: "#3C9255",
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "1px",
    padding: "6px 16px",
    height: "36px",
    boxShadow: "none",
    textTransform: "capitalize",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    fontSize: 24,
    marginBottom: 4,
    marginRight: 8,
  },
  buttonStyle: {
    position: "absolute",
    top: "20%",
    left: "50%",
    marginRight: "-12px",
    marginLeft: "-12px",
    color: "#008dae",
  },
};

class RemindButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleSubmit() {
    const { firstName, lastName } = this.props.candidate.attributes;

    this.props.remindCandidate().then(() => {
      this.setState({ loading: false });
      this.props.snackbar.showMessage(
        `Reminder email sent to ${firstName} ${lastName}`
      );
    });
  }

  render() {
    const { reminderSentAt } = this.props.candidate.attributes;
    const { classes } = this.props;
    const { loading } = this.state;
    const displayButton =
      !reminderSentAt || moment().diff(moment(reminderSentAt), "day") > 1;

    if (displayButton) {
      return (
        <Button
          variant="primary"
          color="blue"
          onClick={this.handleSubmit.bind(this)}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} style={classes.buttonStyle} />
          ) : (
            "Send Reminder"
          )}
        </Button>
      );
    }
    return (
      <div className={classes.reminderText}>
        <Check className={classes.icon} />
        <div>REMINDER SENT</div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, { candidate }) => ({
  remindCandidate: () => dispatch(remindCandidate(candidate.id)),
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withSnackbar()(RemindButton)));
