import moment from "moment";
import React from "react";

import { BOTH_PARTIES } from "../fieldHelpers";

const Completed = ({ text }) => (
  <span>
    {text}
  </span>
);

const NotCompleted = () => (
  <span style={{ color: "#E85454" }}>
    Not Completed
  </span>
);

function ConfirmationCellContent({ checklistItem }) {
  const {
    agencyConfirmedAt, agencyConfirmerName, employerConfirmedAt, employerConfirmerName,
    responsibility
  } = checklistItem;

  let agencyConfirmation = "";
  let employerConfirmation = "";

  if (agencyConfirmedAt) {
    agencyConfirmation = `${moment(agencyConfirmedAt).format(
      "MMM Do, YYYY"
    )} by ${agencyConfirmerName}`;
  }

  if (employerConfirmedAt) {
    employerConfirmation = `${moment(employerConfirmedAt).format(
      "MMM Do, YYYY"
    )} by ${employerConfirmerName}`;
  }

  if (responsibility === BOTH_PARTIES) {
    return (
      <div>
        <div style={{ marginBottom: "8px" }}>
          <span className="bold">Company:</span>
          <br />
          {employerConfirmation ? <Completed text={employerConfirmation} /> : <NotCompleted />}
        </div>
        <div>
          <span className="bold">Staffing Firm:</span>
          <br />
          {agencyConfirmation ? <Completed text={agencyConfirmation} /> : <NotCompleted />}
        </div>
      </div>
    );
  }

  if (agencyConfirmation) return <Completed text={agencyConfirmation} />;
  if (employerConfirmation) return <Completed text={employerConfirmation} />;
  return (<NotCompleted />);
}

export default ConfirmationCellContent;
