import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  textSection: { marginBottom: 16 },
  description: {
    padding: "4px 0px",
  },
}));

function PositionExtended() {
  const classes = useStyles();

  return (
    <>
      <div className={classes.textSection}>
        <div className="semibold">Are you still working on this jobcast?</div>
        <div className={classes.description}>
          Great! The JobCast has been extended
        </div>
      </div>
      <div className={classes.textSection}>
        <div className="semibold">Want more candidates?</div>
        <div className={classes.description}>Here are some tips:</div>
      </div>
      <div className={classes.textSection}>
        <div className="semibold">
          Add a Priority Bonus(
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://help.recruitifi.com/en/articles/5464238-what-is-a-priority-bonus-and-how-do-i-use-it"
          >
            Learn More
          </a>
          )
        </div>
        <div className={classes.description}>
          Tell your agencies why this is one of the top JobCasts that you need
          to fill by adding a Priority Bonus.
        </div>
      </div>
      <div className={classes.textSection}>
        <div className="semibold">
          Make an Announcement (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://help.recruitifi.com/en/articles/3040869-how-do-i-make-an-announcement"
          >
            Learn More
          </a>
          )
        </div>
        <div className={classes.description}>
          Start a dialogue about this opening on the Message Board by asking the
          agencies working on your JobCast for feedback.
        </div>
      </div>
    </>
  );
}

export default PositionExtended;
