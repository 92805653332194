import { combineReducers } from "redux";

import emailSettingsReducer from "./emailSettingsReducer";
import matchPreferenceReducer from "./matchPreferenceReducer";
import organizationPositionTemplateReducer from "./organizationPositionTemplateReducer";

export default combineReducers({
  organizationPositionTemplate: organizationPositionTemplateReducer,
  emailSettings: emailSettingsReducer,
  matchPreferences: matchPreferenceReducer,
});
