import cloneDeep from "lodash/cloneDeep";
import every from "lodash/every";
import filter from "lodash/filter";
import find from "lodash/find";
import findKey from "lodash/findKey";
import flatten from "lodash/flatten";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import range from "lodash/range";
import reject from "lodash/reject";
import reverse from "lodash/reverse";
import sortBy from "lodash/sortBy";
import values from "lodash/values";
import valuesIn from "lodash/valuesIn";

import { replaceHTMLTags } from "./formatHelpers";
import {
  RECRUITMENT_SERVICE_OFFERING_NAMES,
  DIVERSITY_FOCUS_NAMES,
} from "./namedConstants";

export * from './constantsToOptions';

export const dayOptions = Array.from({ length: 31 }, (v, i) => ({
  value: i + 1,
  label: i + 1,
}));

export const monthOptions = map(
  [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  (month, index) => ({
    value: index + 1,
    label: month,
  })
);

export const yearOptions = reverse(
  Array.from({ length: new Date().getFullYear() - (1902 + 15) }, (v, i) => ({
    value: i + 1901,
    label: i + 1901,
  }))
);

export const recruitmentServiceOfferingOptions = sortBy(
  map(RECRUITMENT_SERVICE_OFFERING_NAMES, (name) => ({
    value: name,
    label: name,
  })),
  (option) => option.label
);

export const diversityFocusOptions = sortBy(
  map(DIVERSITY_FOCUS_NAMES, (name) => ({
    value: name,
    label: name,
  })),
  (option) => option.label
);

export const specialtyOptions = map(
  [
    "I am a generalist",
    "I specialize in a specific type of candidate"
  ],
  (option, index) => ({
    value: option,
    label: option,
  })
);

export function inputValue({ value = "", errors = [], ...rest } = {}) {
  return { value, errors, ...rest };
}

export function numberedListValue() {
  return inputValue({ value: { 0: inputValue() } });
}

export function wysiwygValue({ value = "", errors = [], rawText = "" } = {}) {
  return inputValue({ value, errors, rawText });
}

export function anyErrors(formKeys) {
  return !!find(formKeys, (key) => this.hasErrors(key));
}

export function validatedNumberedList(list, validator) {
  let newList = cloneDeep(list);
  forEach(newList, (item) => (item.errors = []));

  if (
    validator.requiredNumberedList &&
    every(newList, (item) => item.value === "")
  ) {
    newList = { 0: inputValue({ errors: ["List can't be blank"] }) };
  } else {
    newList[0].errors = [];
  }

  if (
    validator.minItems &&
    validator.requiredNumberedList &&
    reject(newList, (item) => item.value === "").length < validator.minItems
  ) {
    map(range(validator.minItems), (i) => String(i)).forEach((i) => {
      if (newList[i]) {
        if (newList[i].value === "") { return (newList[i].errors = ["Can't be blank"]); }
      } else {
        return (newList[i] = inputValue({ errors: ["Can't be blank"] }));
      }
    });
  }

  mapValues(newList, (e) => {
    e.errors = e.errors.concat(calculateErrors(e.value, validator));
  });
  return newList;
}

export const disabledProps = (isDisabled) => (isDisabled ? disabledFieldProps : {});

export function errorString(controlId) {
  return hasErrors.call(this, controlId)
    ? this.state[controlId].errors.join(", ")
    : null;
}

export function hasErrors(controlId) {
  return !isEmpty(this.state[controlId] && this.state[controlId].errors);
}

export const numberedListToArray = (numberedListValue) => {
  if (
    numberedListValue.constructor === Array
  ) {
    return filter(numberedListValue, (val) => val !== "");
  }
  return map(
    filter(valuesIn(numberedListValue), (obj) => obj.value !== ""),
    (listItem) => listItem.value
  );
};

export const numberedListFromArray = (listItems) => {
  if (listItems.length === 0) {
    return numberedListValue();
  }
  return inputValue({
    value: mapValues({ ...listItems }, (value) => inputValue({ value })),
  });
};

export function presenceAndNoErrors(controlId) {
  return (
    presence(this.state[controlId].value) &&
    isEmpty(this.state[controlId].errors)
  );
}

export const snakeCaseToTitleCase = (string) => string
  .split("_")
  .map((word) => word.charAt(0).concat(word.slice(1)))
  .join(" ");

export function stateWithBackendErrors(errors) {
  return mapValues(errors, (error, key) => ({
    errors: error,
    value: this.state[key].value,
  }));
}

export function updateCheckbox(controlId, validator) {
  return (e) => this.setState({
    [controlId]: {
      errors: calculateErrors(e.target.checked, validator),
      value: e.target.checked,
    },
  });
}

export function flashUploadError(controlId) {
  return () => {
    this.setState(
      {
        [controlId]: {
          errors: ["Invalid file type"],
        },
      },
      () => {
        this.flashTimeoutId = setTimeout(
          () => this.setState((currState) => {
            const currErrors = currState[controlId].errors;
            return {
              [controlId]: {
                errors:
                    currErrors &&
                    currErrors.filter((err) => err !== "Invalid file type"),
              },
            };
          }),
          5000
        );
      }
    );
  };
}

export function validateFields(validators) {
  let valid = true;
  const fieldsWithErrors = {};
  keys(validators).forEach((controlId) => {
    const errors = calculateErrors(
      this.state[controlId].value,
      validators[controlId]
    );
    if (!isEmpty(errors)) {
      const newValue = {
        errors,
        value: determineValue(
          this.state[controlId].value,
          validators[controlId]
        ),
      };
      if (validators[controlId].wysiwyg) {
        newValue.rawText = this.state[controlId].rawText;
      }

      valid = false;
      fieldsWithErrors[controlId] = newValue.errors;
      this.setState({ [controlId]: newValue });
    }
  });

  return valid ? Promise.resolve() : Promise.reject(fieldsWithErrors);
}

// updates component state to validating == true when running,
// then sets validating = false after all validations complete.
// note: this does not return anything, so make use of it via
// componentDidUpdate in your form
export function validateFieldsAsync(validators) {
  this.setState({ isValidating: true }, () => {
    const newValues = cloneDeep(this.state);
    keys(validators).forEach((controlId) => {
      const errors = calculateErrors(
        this.state[controlId].value,
        validators[controlId]
      );
      if (!isEmpty(errors)) {
        newValues[controlId] = {
          errors,
          value: determineValue(
            this.state[controlId].value,
            validators[controlId]
          ),
        };
        if (validators[controlId].wysiwyg) {
          newValues[controlId].rawText = this.state[controlId].rawText;
        }
      }
    });

    this.setState(Object.assign(newValues, { isValidating: false }));
  });
}

function determineValue(value, validator) {
  if (
    value &&
    typeof value === "object" &&
    value.constructor === Object
  ) {
    return validatedNumberedList(value, validator);
  }
  return value;
}

export function updateCheckboxValue(controlId) {
  return (e) => this.setState({
    [controlId]: {
      value: e.target.checked,
    },
  });
}

export function updateFormValue(controlId, validator, customSetValueFnc) {
  if (validator && validator.bounce) {
    return updateFormValueBounce.call(this, controlId, validator);
  }

  if (validator && validator.wysiwyg) {
    return (value, quillRef, options = {}) => {
      const newState = { rawText: quillRef.getText(), value };
      if (!options.keepErrors) { newState.errors = calculateErrors(value, validator); }

      return this.setState({ [controlId]: newState });
    };
  }
  return (e, value) => {
    if (
      e &&
        e.target &&
        e.target.value &&
        validator &&
        validator.stripSpaces
    ) {
      value = e.target.value.replace(/\s/, "").trim();
    }

    if (customSetValueFnc) {
      value = customSetValueFnc(e, value);
    }

    return this.setState({
      [controlId]: {
        errors: calculateErrors(
          value || value === "" ? value : e.target.value,
          validator
        ),
        value: value || value === "" ? value : e.target.value,
      },
    });
  };
}

export function updateFormValueBounce(controlId, validator) {
  const updateError = (value) => {
    this.setState((prevState) => {
      const newState = {
        errors: calculateErrors(value, validator),
        value: prevState[controlId].value,
        timeoutId: prevState[controlId].timeoutId,
        validated: true,
      };

      if (validator && validator.wysiwyg) {
        newState.rawText = prevState[controlId].rawText;
      }

      return { [controlId]: newState };
    });
  };

  const updateValue = (value, quillRef, options = {}) => {
    this.setState((prevState) => {
      const newState = {
        errors: prevState[controlId].errors,
        timeoutId:
          !options.keepErrors && setTimeout(() => updateError(value), 1000),
        value,
        validated: false,
      };

      if (validator && validator.wysiwyg && quillRef) {
        newState.rawText = quillRef.getText();
      }

      return { [controlId]: newState };
    });
  };

  if (validator.wysiwyg) {
    return (value, quillRef, options = {}) => {
      clearTimeout(this.state[controlId].timeoutId);
      updateValue(value, quillRef, options);
    };
  }
  return (e) => {
    const { value } = e.target;
    clearTimeout(this.state[controlId].timeoutId);
    updateValue(value);
  };
}

export const removeBlankKeys = (data) => {
  Object.keys(data).forEach((key) => !data[key] && delete data[key]);
  return data;
};

export const calculateErrors = (value, validator) => {
  if (!validator) return [];

  if (validator.file) {
    if (validator.required && (!value || !value.name || !value.preview)) {
      return ["Cannot be blank"];
    }
  } else if (
    value &&
    typeof value === "object" &&
    value.constructor === Object
  ) {
    const newValue = validatedNumberedList(value, validator);
    return flatten(map(values(cloneDeep(newValue)), (e) => e.errors));
  } else {
    switch (typeof validator) {
      case "object":
        return errorsFromValidator(value, validator);
      case "function":
        return validator(value);
      default:
        return [];
    }
  }
};

export const REGEX_EMAIL_FORMAT =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const REGEX_LINKEDIN_URL =
  /\s*((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/in\/([-\w|\d&#?=ç¨´-øµ˚])+\/?)+\/?\s*/i;

export const REGEX_DIGITS_ONLY = /^\d+$/;
export const REGEX_LETTERS_ONLY = /^[a-zA-Z]+$/;
export const REGEX_URL =
  /^(((https?|ftp):)?\/\/)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const REGEX_US_POSTAL = /^\d{5}$/;

const disabledFieldProps = {
  InputProps: {
    disabled: true,
    disableUnderline: true,
    style: { color: "#90A4AE !important" },
  },
  InputLabelProps: { className: "disabled-input-label", shrink: true },
};

const errorsFromValidator = (value, validators) => {
  const errors = [];
  if (
    find(["match", "required", "usPhone"], (controlId) => findKey(validators, controlId))
  ) {
    return [];
  }

  if (validators.tokens && !value) {
    errors.push("must select a recruiter to advance to the next step.");
  }

  if (
    validators.required &&
    (value === "" ||
      value === "<p><br></p>" ||
      (Array.isArray(value) && value.length === 0) ||
      !value ||
      (typeof value === "string" && !value.replace(/\s/g, "").length))
  ) {
    errors.push("Cannot be blank");
  }

  if (validators.digitsOnly && value && !REGEX_DIGITS_ONLY.test(value)) {
    errors.push("Must only contain digits");
  }

  if (validators.lettersOnly && value && !REGEX_LETTERS_ONLY.test(value)) {
    errors.push("Must only contain letters");
  }

  if (
    value &&
    validators.maxLength &&
    (validators.phoneNumber
      ? value.replace(/[^0-9]/g, "").length
      : value.length) > validators.maxLength
  ) {
    errors.push(
      `Must be ${validators.maxLength} ${
        validators.digitsOnly ? "digits" : "characters"
      } or less`
    );

    if (validators.demo) {
      errors.push(
        "This is a very long error message to demonstrate the wrapping mechanism when combined with a character counter"
      );
    }
  }

  if (value && validators.minLength && value.length < validators.minLength) {
    errors.push(
      `Must be at least ${validators.minLength} ${
        validators.digitsOnly ? "digits" : "characters"
      }`
    );
  }

  if (value && validators.minWords) {
    const wordsMatched = (
      validators.wysiwyg ? replaceHTMLTags(value, " ") : value
    )
      .split(/[^\w]+/)
      .filter((word) => word.length);
    if (!wordsMatched || wordsMatched.length < validators.minWords) {
      errors.push(`Must be at least ${validators.minWords} words`);
    }
  }

  if (
    validators.matches &&
    validators.matches.value &&
    value !== validators.matches.value
  ) {
    errors.push(
      validators.matches.message
        ? validators.matches.message
        : "values must match"
    );
  }

  if (validators.match && validators.match.context && validators.match.key) {
    const compared_value =
      validators.match.context.state[validators.match.key].value;
    const errorMsg = "Fields must match";
    if (compared_value === value) {
      validators.match.context.setState({
        [validators.match.key]: {
          value: compared_value,
          errors: [],
        },
      });
    } else {
      const newErrors = filter(
        validators.match.context.state[validators.match.key].errors,
        (e) => e !== errorMsg
      ).concat(errorMsg);
      validators.match.context.setState({
        [validators.match.key]: {
          value: compared_value,
          errors: newErrors,
        },
      });
      errors.push(errorMsg);
    }
  }

  if (
    validators.compareNumber &&
    validators.compareNumber.context &&
    (validators.compareNumber.isMin || validators.compareNumber.isMax)
  ) {
    const {
      context, isMin, isMax, minKey, maxKey
    } = validators.compareNumber;
    if (
      isMin &&
      context.state[maxKey].value &&
      Number(value.replace(/[, ]+/g, "").trim()) >=
        Number(context.state[maxKey].value.replace(/[, ]+/g, "").trim())
    ) {
      errors.push("Field 1 must be less than Field 2");
    } else if (
      isMax &&
      context.state[minKey].value &&
      Number(value.replace(/[, ]+/g, "").trim()) <=
        Number(context.state[minKey].value.replace(/[, ]+/g, "").trim())
    ) {
      errors.push("Field 1 must be less than Field 2");
    }
  }

  if (
    validators.range &&
    value &&
    (value.replace(/[, ]+/g, "").trim() < validators.range.min ||
      value.replace(/[, ]+/g, "").trim() > validators.range.max)
  ) {
    errors.push(
      `Must be between ${validators.range.min}-${validators.range.max}`
    );
  }

  if (
    validators.url &&
    typeof value === "string" &&
    !REGEX_URL.test(value)
  ) {
    errors.push("Improper format, example: example.com");
  }

  if (
    validators.linkedin &&
    typeof value === "string" &&
    !REGEX_LINKEDIN_URL.test(value)
  ) {
    errors.push(
      "Must be a valid LinkedIn profile URL, e.g. www.linkedin.com/in/yourname"
    );
  }

  if (
    validators.customRegex &&
    typeof value === "string" &&
    !validators.customRegex.test(value)
  ) {
    errors.push(validators.customRegexMsg || "Must match described format");
  }

  if (
    validators.email &&
    typeof value === "string" &&
    !REGEX_EMAIL_FORMAT.test(value)
  ) {
    errors.push("Improper format, example: example@email.com");
  }

  if (
    validators.usPhone &&
    typeof value === "string" &&
    value.length !== 10
  ) {
    errors.push("US phone numbers must contain exactly 10 characters");
  }

  if (
    validators.usPostalCode &&
    typeof value === "string" &&
    !REGEX_US_POSTAL.test(value)
  ) {
    errors.push("US Postal Codes must be 5 digits");
  }

  if (validators.sourceTrackingCode && value && REGEX_URL.test(value)) {
    errors.push(
      "Error: please only input the text to mark RecruitiFi as the source in your ATS, eg. source_code='RecruitiFi'"
    );
  }

  return errors;
};

const presence = (value) => value && !isEmpty(value);
