import merge from "lodash/merge";

import { RECEIVE_COMMUNITY_TERMS } from "../actions/shared/communityTermsActions";

const defaultState = {
  loaded: false,
  items: {
    perm: {},
    temp: {},
  },
};

const communityTermsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_COMMUNITY_TERMS:
      return merge({}, state, { loaded: true, items: action.payload });
    default:
      return state;
  }
};

export default communityTermsReducer;
