import { useMemo } from "react";
import { useSelector } from "react-redux";

function useFeatureFlagCheck(flagName) {
  const featureFlags = useSelector((state) => state.featureFlags.flags);

  return useMemo(
    () => featureFlags.includes(flagName),
    [flagName, featureFlags]
  );
}

export default useFeatureFlagCheck;
