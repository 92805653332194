import { withFormik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";

import {
  fetchCandidates,
  shareCandidates,
} from "../../../../../../../actions/employers/candidateActions";
import { fetchJobCast } from "../../../../../../../actions/employers/jobcastActions";
import { clearCandidates } from "../../../../../../../actions/shared/candidateActions";
import { clearModal } from "../../../../../../../actions/shared/uiActions";
import {
  getFilteredCandidates,
  filterCandidatesByJobCast,
  filterCandidatesByType,
} from "../../../../../../../selectors/candidateSelectors";
import { getJobCast } from "../../../../../../../selectors/jobcastSelectors";
import { string, object, array } from "../../../../../../../util/yup";

import ActionModalContainer from "../../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../../../General/ActionModal/ActionModalHeader";
import JobCastLocations from "../../../../../../General/JobCastLocations";
import LoadingPage from "../../../../../../General/LoadingPage";

import withSnackbar from "../../../../../../General/withSnackbar";

import CandidateLists from "./Share/CandidateLists";
import EmailNote from "./Share/EmailNote";
import Emails from "./Share/Emails";

function ShareModalContent(props) {
  return (
    <>
      <div>
        For each candidate selected, the name, resume, and LinkedIn profile
        will be shared along with your note. Please note that all links to
        resumes will expire 7 days after sending.
      </div>
      <div>
        <CandidateLists {...props} />
        <Emails {...props} />
        <EmailNote {...props} />
      </div>
    </>
  );
}

class ShareModal extends Component {
  state = { loaded: false };

  loadJobCast = async () => {
    const { jobcastLoaded, fetchJobCast } = this.props;
    if (!jobcastLoaded) {
      return fetchJobCast();
    }
  };

  componentDidMount() {
    this.loadJobCast()
      .then(this.props.fetchCandidates)
      .then(this.setFieldValues)
      .then(() => this.setState({ loaded: true }));
  }

  setFieldValues = async () => {
    const {
      typedCandidates, setValues, selectedCandidateId, jobcast: { attributes }
    } = this.props;

    const candidateValues = {};
    Object.values(typedCandidates).forEach((candidatesList) => {
      candidatesList.forEach((candidate) => {
        candidateValues[candidate.id] = candidate.id === selectedCandidateId;
      });
    });

    const emails = attributes.sharedEmails?.length > 0 ? attributes.sharedEmails : [""];

    setValues({ candidates: candidateValues, emails, emailNote: "" });
  };

  render() {
    const {
      jobcastLoaded,
      jobcast,
      handleSubmit,
      errors,
      submitCount,
      isSubmitting,
      status,
    } = this.props;
    const { loaded } = this.state;

    if (!jobcastLoaded) {
      return (
        <ActionModalContainer>
          <ActionModalContent>
            <LoadingPage />
          </ActionModalContent>
        </ActionModalContainer>
      );
    }

    return (
      <ActionModalContainer color="blue">
        <ActionModalHeader
          title="Candidate Sharing"
          subtitle={(
            <JobCastLocations
              locations={jobcast.attributes.locations}
              preLocationText={`${jobcast.attributes.title} - `}
              displayTextColor="#FFFFFF"
            />
          )}
        />
        <ActionModalContent>
          { !loaded
            ? <LoadingPage />
            : <ShareModalContent {...this.props} />}
        </ActionModalContent>
        <ActionModalFooter
          actionText="Share"
          handleAction={handleSubmit}
          isSubmitting={isSubmitting}
          submissionError={Boolean(
            submitCount && Object.values(errors).length
          )}
          customErrorMessage={status && status.backendError}
        />
      </ActionModalContainer>
    );
  }
}

const ShareModalForm = withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
    const submissionData = {
      notes: values.emailNote,
      emails: values.emails.filter((val) => val),
      submissionIds: Object.keys(values.candidates).filter(
        (candidateId) => values.candidates[candidateId]
      ),
    };

    props
      .shareCandidates(submissionData)
      .then(() => {
        props.fetchJobCast(); // refetching the jobcast so we have updated list of emails
        props.snackbar.showMessage("Candidates have been shared via email");
        setSubmitting(false);
      })
      .then(props.clearModal)
      .catch((err) => {
        setFieldError(
          "emails",
          "Could not share candidates with the above email addresses"
        );
        setSubmitting(false);
      });
  },
  validationSchema: object().shape({
    candidates: object().test(
      "has-truthy-val",
      "Must select at least one candidate to share",
      (candidateObj) => Object.values(candidateObj).includes(true)
    ),
    emails: array().of(
      string()
        .required("Cannot be blank")
        .email("Must be a valid email address")
    ),
  }),
})(ShareModal);

const mapStateToProps = (state, ownProps) => {
  const jobcast = getJobCast(state, ownProps.jobcastId);
  const candidatesLoaded = state.candidates.loaded;
  const createTypedCandidates = () => {
    const typedCandidates = {};
    ["REVIEWING", "INTERVIEWING", "OFFERS"].forEach((type) => {
      typedCandidates[type] = getFilteredCandidates(state, [
        filterCandidatesByJobCast(ownProps.jobcastId),
        filterCandidatesByType(type),
      ]);
    });

    return typedCandidates;
  };
  return {
    jobcast,
    jobcastLoaded: Boolean(jobcast),
    candidatesLoaded,
    typedCandidates: candidatesLoaded ? createTypedCandidates() : {},
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchJobCast: () => dispatch(fetchJobCast(ownProps.jobcastId)),
  fetchCandidates: () => dispatch(fetchCandidates(ownProps.jobcastId)),
  shareCandidates: (data) => dispatch(shareCandidates(data)),
  clearCandidates: () => dispatch(clearCandidates()),
  clearModal: () => dispatch(clearModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar()(ShareModalForm));
