import cloneDeep from "lodash/cloneDeep";
import keys from "lodash/keys";
import merge from "lodash/merge";

import {
  RECEIVE_COST_CENTERS,
  RECEIVE_COST_CENTER,
  REMOVE_COST_CENTER,
} from "../actions/employers/costCenterActions";

const _defaultState = {
  items: {},
  loaded: false,
};

const costCenterReducer = (state = _defaultState, action) => {
  Object.freeze(state);
  let newItems;

  switch (action.type) {
    case RECEIVE_COST_CENTER:
      newItems = cloneDeep(state.items);
      newItems[action.payload.id] = merge({}, action.payload, { loaded: true });
      return { ...state, items: newItems };
    case RECEIVE_COST_CENTERS:
      return merge({}, state, { items: action.payload, loaded: true });
    case REMOVE_COST_CENTER:
      const newState = cloneDeep(state);
      const costCenterId = keys(action.payload)[0];
      delete newState.items[costCenterId];
      return newState;
    default:
      return state;
  }
};

export default costCenterReducer;
