import filter from "lodash/filter";
import { createSelector } from "reselect";

import {
  ACTIVE,
  DEACTIVATED,
  PENDING,
  TIMESHEET_SUBMITTERS,
} from "../../components/shared/Team/constants";
import { getCurrentIndividual } from "../individualSelectors";
import {
  getAllTeammates,
  getAllTeammateInvitations,
} from "../teammateSelectors";

export const makeGetTeammates = (scope, excludeSelf = false) => createSelector(
  [getAllTeammates, getCurrentIndividual],
  (teammates, currentIndividual) => {
    const getScopedTeammates = () => {
      switch (scope) {
        case ACTIVE:
          return filter(teammates, ({ attributes }) => Boolean(attributes.activatedAt && !attributes.deactivatedAt));
        case DEACTIVATED:
          return filter(
            teammates,
            ({ attributes }) => Boolean(attributes.deactivatedAt) === true
          );
        case TIMESHEET_SUBMITTERS:
          return filter(teammates, ({ attributes }) => attributes.roles.includes("timesheet_submitter"));
        default:
          return teammates || [];
      }
    };

    const scopedTeammates = getScopedTeammates();

    return excludeSelf
      ? scopedTeammates.filter(({ id }) => id !== currentIndividual.id)
      : scopedTeammates;
  }
);

export const makeGetTeammateInvitations = (scope) => createSelector([getAllTeammateInvitations], (teammateInvitations) => {
  switch (scope) {
    case PENDING:
      return filter(
        teammateInvitations,
        ({ attributes }) => !attributes.acceptedAt ||
            attributes.status === "Activating" ||
            attributes.status === "Not Approved"
      );
    default:
      return teammateInvitations;
  }
});
