import normalize from "json-api-normalizer";
import { batch } from "react-redux";

import { GET, POST, PATCH } from "../../util/apiHelpers";

import { receiveIndividuals } from "../shared/individualActions";
import {
  receiveCurrentOrganization,
  receiveOrganizations,
} from "../shared/organizationActions";

import { receiveOrgJobCategories } from "./orgJobCategoryActions";
import { receiveTempPrerequisites } from "./tempPrerequisiteActions";

// callback action types//
export const RECEIVE_ORGANIZATION_POSITION_TEMPLATE =
  "RECEIVE_ORGANIZATION_POSITION_TEMPLATE";

// callback actions//
export const receiveOrganizationPositionTemplate = (positionTemplate) => ({
  type: RECEIVE_ORGANIZATION_POSITION_TEMPLATE,
  payload: positionTemplate,
});

// api actions//
export const fetchOrganizationJobCastDefaults = () => (dispatch) =>
  GET("/api/v3/employer/organization_position_templates")
    .then((res) => {
      const normalized = normalize(res.data);
      dispatch(
        receiveOrganizationPositionTemplate(
          normalized.organizationPositionTemplate
        )
      );
    })
    .catch((error) => Promise.reject(error));

export const updateJobCastDefaults = (position_template) => (dispatch) =>
  PATCH("/api/v3/employer/organization_position_templates", position_template)
    .then((res) => {
      const normalized = normalize(res.data);

      batch(() => {
        dispatch(
          receiveOrganizationPositionTemplate(
            normalized.organizationPositionTemplate
          )
        );
        dispatch(receiveOrganizations(normalized.organization));
      });
    })
    .catch((error) => Promise.reject(error));

export const updateEmployerOrganization = (formData) => (dispatch) =>
  PATCH("/api/v3/employer/organization", formData)
    .then((res) => {
      const normalized = normalize(res.data);
      return batch(() => {
        dispatch(receiveCurrentOrganization(normalized.organization));
        dispatch(receiveOrgJobCategories(normalized.orgJobCategory));
        dispatch(receiveTempPrerequisites(normalized.tempPrerequisite));
      });
    })
    .catch((error) => Promise.reject(error));

export const activateOrganization = () => (dispatch) =>
  POST("/api/v3/employer/organization/activate").then((res) => {
    const normalized = normalize(res.data);
    dispatch(receiveIndividuals(normalized.individual));

    return dispatch(receiveCurrentOrganization(normalized.organization));
  });

export const fetchLegacyInvitation = (token) =>
  GET(`/api/v3/employer/legacy_invitations/${token}`).then((res) => {
    const normalized = normalize(res.data);

    const { legacyInvitation, preferredLink } = normalized;

    return {
      legacyInvitation:
        legacyInvitation[Object.keys(legacyInvitation)[0]].attributes,
      preferredLink: preferredLink[Object.keys(preferredLink)[0]].attributes,
    };
  });

export const requestManualSolicitationCheck = (url) => (dispatch) =>
  POST("/api/v3/employer/organization/request-manual-solicitation-check", {
    career_page_url: url,
  })
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));
