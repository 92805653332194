import TextField from "@material-ui/core/TextField";
import pick from "lodash/pick";
import round from "lodash/round";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-grid-system";
import { useDispatch } from "react-redux";

import {
  clearModal,
  openProfileDrawer,
} from "../../../../../../../../actions/shared/uiActions";
import {
  buildFullName,
  formatCurrency,
} from "../../../../../../../../util/formatHelpers";
import { disabledProps } from "../../../../../../../../util/formHelpers";
import {
  PERCENTAGE_OF_COMPENSATION,
  FLAT_FEE,
} from "../../../../../../../forms/formik/FormikFeeOptions/fieldHelpers";
import AgencyRecruiterDrawer from "../../../../../../Agencies/view/AgencyRecruiterDrawer";

import FieldSection from "./FieldSection";
import SectionHeader from "./SectionHeader";

const determineRate = ({ feeType, feePercentage, feeCents, currency }) => {
  if (feeType === PERCENTAGE_OF_COMPENSATION) {
    return `${round(feePercentage * 100, 2)}%`;
  }
  if (feeType === FLAT_FEE) {
    return `${formatCurrency(feeCents, currency)}`;
  }
};

function AgencyDrawerLink({ candidate }) {
  const { recruiterId, recruiterFirstName, recruiterLastName, offPlatform } =
    candidate.attributes;
  const dispatch = useDispatch();
  const dispatchOpenAgencyDrawer = () => {
    dispatch(clearModal());
    dispatch(
      openProfileDrawer(<AgencyRecruiterDrawer recruiterId={recruiterId} />)
    );
  };

  return (
    <div>
      <div className="static-input-label">Agent</div>
      <span
        data-cy="hire-overview-recruiter-full-name"
        style={
          offPlatform
            ? {}
            : {
                color: "#008dae",
                textDecoration: "underline",
                cursor: "pointer",
              }
        }
        onClick={offPlatform ? () => {} : dispatchOpenAgencyDrawer}
      >
        {buildFullName(recruiterFirstName, recruiterLastName)}
      </span>
    </div>
  );
}

function HireOverView({ candidate }) {
  const { agencyName, feeOptionChoices, feeOption } = candidate.attributes;

  return (
    <>
      <SectionHeader title="Hire Overview" style={{ marginTop: 0 }} />
      <FieldSection>
        <Row>
          <Col xs={4}>
            <TextField
              {...disabledProps(true)}
              label={<div className="static-input-label">Agency</div>}
              multiline
              value={agencyName}
              inputProps={{ "data-cy": "hire-overview-agency-name" }}
            />
          </Col>
          <Col xs={3}>
            <AgencyDrawerLink candidate={candidate} />
          </Col>
          <Col xs={2} style={{ minWidth: 144 }}>
            <TextField
              {...disabledProps(true)}
              label={<div className="static-input-label">Agency Fee</div>}
              value={
                feeOptionChoices && !candidate.attributes.hiredAt
                  ? "Multiple Fees"
                  : determineRate({
                      ...pick(feeOption, [
                        "feeType",
                        "currency",
                        "feePercentage",
                        "feeCents",
                      ]),
                    })
              }
              inputProps={{ "data-cy": "hire-overview-fees-you-pay" }}
            />
          </Col>
        </Row>
      </FieldSection>
    </>
  );
}

HireOverView.propTypes = {
  candidate: PropTypes.object.isRequired,
};

export default HireOverView;
