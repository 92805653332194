// Recruitment Service Offerings
export const CONTINGENT_SEARCH = "Contingent Search";
export const RETAINED_SEARCH = "Retained Search";
export const VOLUME_HIRING = "Volume Hiring";
export const STAFF_AUGMENTATION = "Staff Augmentation";
export const CONTINGENT_LABOR = "Contingent Labor";

export const RECRUITMENT_SERVICE_OFFERING_NAMES = [
  CONTINGENT_SEARCH,
  RETAINED_SEARCH,
  VOLUME_HIRING,
  STAFF_AUGMENTATION,
  CONTINGENT_LABOR,
];

// Diversity Focus
export const RACIAL_DIVERSITY = "Racial diversity";
export const SEX_GENDER_DIVERSITY = "Sex / Gender diversity";
export const SEXUAL_ORIENTATION = "Sexual orientation";
export const DISABILITY = "Disability";
export const MILITARY_VETERAN = "Military / Veteran";
export const RELIGIOUS_DIVERSITY = "Religious diversity";
export const AGE_DIVERSITY = "Age diversity";
export const CULTURAL_DIVERSITY = "Cultural diversity";

export const DIVERSITY_FOCUS_NAMES = [
  RACIAL_DIVERSITY,
  SEX_GENDER_DIVERSITY,
  SEXUAL_ORIENTATION,
  DISABILITY,
  MILITARY_VETERAN,
  AGE_DIVERSITY,
  CULTURAL_DIVERSITY,
];

export const VIEWING_AS_Z_INDEX = 9999;
export const GREATER_THAN_VIEWING_AS_Z_INDEX = 10000;
