import Divider from "@material-ui/core/Divider";
import MaterialMenu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import filter from "lodash/filter";
import map from "lodash/map";
import PropTypes from "prop-types";

import React from "react";

import MenuItem from "./MenuItem";

const styles = {
  paper: {
    width: 208,
    margin: "10px 0px",
    maxHeight: "100%",
  },
};

class Menu extends React.Component {
  renderMenuItems(items) {
    return map(filter(items, "isVisible"), (item, i) => {
      if (item.CustomComponent) {
        return React.createElement(item.CustomComponent, {
          onClose: this.props.onClose,
          open: this.props.open,
          ...item,
        });
      }

      if (!!item.avatar) {
        return (
          <div
            key={`menu-item-avatar-${i}`}
            style={{
              outline: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 4,
            }}
          >
            {item.avatar}
            <div
              className="subtitle-2"
              style={{ padding: "8px 12px", textAlign: "center" }}
            >
              {item.name}
            </div>
            {item.starRating}
          </div>
        );
      } if (item.divider) {
        return <Divider key={`divider-${i}`} />;
      } if (item.title === "Logout") {
        return (
          <MenuItem
            disabled={item.disabled}
            data-layout={`menu-option-${item.title}`}
            id={`option-${item.title}`}
            key={`menu-item-${item.title}-${i}`}
            onClick={this.props.handleLogout}
            icon={item.icon}
          >
            <strong>Logout</strong>
          </MenuItem>
        );
      }
      if (item.tooltip) {
        return (
          <Tooltip {...item.tooltip}>
            <div>
              <MenuItem
                disabled={item.disabled}
                data-layout={`menu-option-${item.title}`}
                id={`option-${item.title}`}
                key={`menu-item-${item.title}-${i}`}
                onClick={() => this.props.handleSelection(item)}
                icon={item.icon}
              >
                {item.label || item.title}
              </MenuItem>
            </div>
          </Tooltip>
        );
      }
      return (
        <MenuItem
          disabled={item.disabled}
          data-layout={`menu-option-${item.title}`}
          id={`option-${item.title}`}
          key={`menu-item-${item.title}-${i}`}
          onClick={() => this.props.handleSelection(item)}
          icon={item.icon}
          data-cy="menu-option-items"
        >
          {item.label || item.title}
        </MenuItem>
      );
    });
  }

  render() {
    const {
      style, open, anchorEl, onClose, items, classes
    } = this.props;
    return (
      <MaterialMenu
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        style={style}
        classes={{ paper: classes.paper }}
        className={classes.menu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        {this.renderMenuItems(items)}
      </MaterialMenu>
    );
  }
}

Menu.propTypes = {
  style: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  handleSelection: PropTypes.func,
};

export default withStyles(styles)(Menu);
