import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import values from "lodash/values";

import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { getCurrentIndividual } from "../../../../selectors/individualSelectors";

import HeaderTabLinks from "../HeaderTabLinks";

import {
  RECRUITER_TAB_OPTIONS,
  TALK_PROFILE,
  TALK_PROFILE_V2,
} from "./constants";

function RecruiterTabs({ scope }) {
  const currentIndividual = useSelector(getCurrentIndividual);

  const tabItems = useMemo(() => {
    const scopedTabs = pickBy(
      RECRUITER_TAB_OPTIONS,
      (option) => option.exclusiveScope === scope || !option.exclusiveScope
    );

    const omitTabs = [];

    omitTabs.push(TALK_PROFILE);

    if (!currentIndividual.talkAgencyProfileEligible) {
      omitTabs.push(TALK_PROFILE, TALK_PROFILE_V2);
    }

    return values(omit(scopedTabs, omitTabs));
  }, [scope, currentIndividual.talkAgencyProfileEligible]);

  return <HeaderTabLinks items={tabItems} />;
}

export default withRouter(RecruiterTabs);
