import normalize from "json-api-normalizer";
import queryString from "query-string";

import { PERM, TEMP } from "../../selectors/jobcastSelectors";

import { ACTIVE } from "../../selectors/recruiter/candidateSelectors";
import { GET, PATCH, POST, DELETE } from "../../util/apiHelpers";
import {
  receiveCandidate,
  receiveCandidates,
  receiveConversation,
  receiveMessage,
  setCandidatesIndexLoaded,
} from "../shared/candidateActions";
import { receiveRequests } from "../shared/requestActions";

export const RECEIVE_RATING = "RECEIVE_RATING";
export const REMOVE_CANDIDATE = "REMOVE_CANDIDATE";

export const receiveFeedbackRating = (rating) => ({
  type: RECEIVE_RATING,
  payload: rating,
});

export const removeCandidate = (id) => ({
  type: REMOVE_CANDIDATE,
  payload: id,
});

export const fetchCandidates =
  (params = { status: ACTIVE, temp: false }) =>
  (dispatch) =>
    GET(`/api/v3/recruiter/candidates?${queryString.stringify(params)}`).then(
      (res) => {
        const normalized = normalize(res.data);

        dispatch(receiveCandidates(normalized.candidate));
        if (params.status && !params.limit) {
          dispatch(
            setCandidatesIndexLoaded({
              indexType: params.status,
              positionType: params.temp ? TEMP : PERM,
            })
          );
        }

        return normalized.candidate;
      }
    );

export const submitCandidate = (data) => (dispatch) =>
  POST("/api/v3/recruiter/candidates", data)
    .then((res) => {
      const normalized = normalize(res.data);

      dispatch(receiveRequests(normalized.request));
      dispatch(receiveCandidate(normalized.candidate[res.data.data.id]));
    })
    .catch((errors) => Promise.reject(errors));

export const withdrawCandidate = (data) => (dispatch) =>
  PATCH(`/api/v3/recruiter/candidates/${data.candidateId}/withdraw`, data)
    .then((res) => {
      const normalized = normalize(res.data);
      return dispatch(receiveCandidate(normalized.candidate[res.data.data.id]));
    })
    .catch((errors) => Promise.reject(errors.response.data));

export const fetchCandidate = (id) => (dispatch) =>
  GET(`/api/v3/recruiter/candidates/${id}`).then((res) => {
    const normalized = normalize(res.data);

    dispatch(receiveCandidates(normalized.candidate));
  });

export const fetchRequestCandidates = (requestId, status) => (dispatch) => {
  const queryParams = status ? `?status=${status}` : "";

  return GET(
    `/api/v3/recruiter/requests/${requestId}/candidates${queryParams}`
  ).then((res) => {
    const normalized = normalize(res.data);

    dispatch(receiveCandidates(normalized.candidate));
    dispatch(
      setCandidatesIndexLoaded({
        customKey: requestId + (status ? `-${status}` : ""),
      })
    );
  });
};

export const remindCandidate = (candidateId) => (dispatch) =>
  PATCH(`/api/v3/recruiter/candidates/${candidateId}/remind`)
    .then((res) => {
      const normalized = normalize(res.data);

      dispatch(receiveCandidate(normalized.candidate[res.data.data.id]));
      return Promise.resolve();
    })
    .catch((error) => error.response.data);

export const fetchConversation = (id) => (dispatch) =>
  GET(`/api/v3/recruiter/candidates/${id}/conversations`).then((res) => {
    const normalized = normalize(res.data);
    const normalizedMessages = normalize(
      res.data.data.attributes.messages
    ).message;
    normalized.conversation[res.data.data.id].attributes.messages =
      normalizedMessages;
    return dispatch(
      receiveConversation(normalized.conversation[res.data.data.id])
    );
  });

export const createMessage = (id, message) => (dispatch) =>
  POST(
    `/api/v3/recruiter/candidates/${id}/conversations/messages`,
    message
  ).then((res) => {
    const normalized = normalize(res.data);
    return dispatch(
      receiveMessage({
        message: normalized.message,
        conversationId:
          normalized.message[res.data.data.id].attributes.conversationId,
      })
    );
  });

export const deleteCandidate = (data) => (dispatch) =>
  DELETE(`/api/v3/recruiter/candidates/${data.id}`).then((res) => {
    dispatch(removeCandidate(data.id));

    return res;
  });
