import { useFormik } from "formik";
import includes from "lodash/includes";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { object, string } from "yup";

import { closeJobcast } from "../../../../../../actions/employers/jobcastActions";
import Button from "../../../../../forms/custom/Button";
import DropdownInput from "../../../../../forms/custom/DropdownInput.react";
import MultiLineTextField from "../../../../../forms/custom/MultiLineTextField";
import useSnackbar from "../../../../../General/customHooks/useSnackbar";
import {
  FILLED_THROUGH_RECRUITIFI,
  FILLED_INTERNALLY,
  CANCELED_INTERNALLY,
  TEMPORARILY_PAUSE,
} from "../../../../JobCasts/index/constants";

const TYPE_OPTIONS = [
  FILLED_THROUGH_RECRUITIFI,
  FILLED_INTERNALLY,
  CANCELED_INTERNALLY,
  TEMPORARILY_PAUSE,
];

const explanationRequired = (closeType) => closeType && includes([FILLED_INTERNALLY, CANCELED_INTERNALLY], closeType);

const validationSchema = object().shape({
  closeType: string()
    .nullable()
    .required("Cannot be blank")
    .when("hiresCount", {
      is: (hiresCount) => hiresCount > 0,
      then: string().oneOf(
        [FILLED_THROUGH_RECRUITIFI, FILLED_INTERNALLY, CANCELED_INTERNALLY],
        "Must be a valid reason"
      ),
      otherwise: string().oneOf(
        [FILLED_INTERNALLY, CANCELED_INTERNALLY],
        "Must be a valid reason"
      ),
    }),
  closeExplanation: string()
    .nullable()
    .when("closeType", {
      is: explanationRequired,
      then: string().required("Cannot be blank").minWords(3),
    }),
});

const TemporarilyPausedDisclaimer = () => (
  <p className="bold" style={{ marginTop: 0 }}>
    You can temporarily pause submissions by locking the JobCast.
    {" "}
    <a
      rel="noopener noreferrer"
      target="_blank"
      href="http://help.recruitifi.com/en/articles/3040705-what-is-a-locked-jobcast-and-how-do-i-use-it"
    >
      Learn more about how to lock JobCasts.
    </a>
  </p>
);

const FilledThroughRFIDisclaimer = () => (
  <p className="bold" style={{ color: "#FF6D6D", marginTop: 0 }}>
    No hires have been logged on this JobCast.
    {" "}
    <a
      style={{ color: "#FF6D6D", textDecoration: "underline" }}
      rel="noopener noreferrer"
      target="_blank"
      href="http://help.recruitifi.com/en/articles/3040924-how-do-i-hire-a-candidate-or-log-a-hire"
    >
      Learn more about how to log hires.
    </a>
  </p>
);

function ArchiveForm({ jobcast, handleCancel }) {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();

  const hiresCount = jobcast.attributes.hiredSubmissions?.length || 0;

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    dirty: changedFromInitialValues,
  } = useFormik({
    initialValues: {
      closeType: null,
      closeExplanation: null,
      hiresCount,
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const submitValues = {
        jobcastId: jobcast.id,
        type: values.closeType,
      };

      if (explanationRequired(submitValues.type)) {
        Object.assign(submitValues, { reason: values.closeExplanation });
      } else if (submitValues.type === FILLED_THROUGH_RECRUITIFI) {
        Object.assign(submitValues, {
          reason: "JobCast filled successfully",
        });
      }

      dispatch(closeJobcast(submitValues))
        .then(() => {
          showMessage(
            "Jobcast is now closed and all active candidates rejected"
          );
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
  });

  const renderCloseTypeDisclaimer = () => {
    switch (values.closeType) {
      case FILLED_THROUGH_RECRUITIFI:
        return hiresCount ? null : <FilledThroughRFIDisclaimer />;
      case TEMPORARILY_PAUSE:
        return <TemporarilyPausedDisclaimer />;
      default:
        return null;
    }
  };

  const submitDisabled =
    isSubmitting ||
    !changedFromInitialValues ||
    Boolean(Object.keys(errors).length);

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        Please let us know why this JobCast is closed
      </div>
      <DropdownInput
        id="closeType"
        label="Why are you closing this JobCast?"
        value={values.closeType}
        options={TYPE_OPTIONS}
        error={Boolean(touched.closeType && errors.closeType)}
        helperText={touched.closeType && errors.closeType}
        onChange={handleChange}
        onBlur={handleBlur}
        noShrink
      />
      {renderCloseTypeDisclaimer()}
      {explanationRequired(values.closeType) && (
        <MultiLineTextField
          id="closeExplanation"
          value={values.closeExplanation}
          label="Explanation to agencies"
          error={Boolean(touched.closeExplanation && errors.closeExplanation)}
          helperText={touched.closeExplanation && errors.closeExplanation}
          required
          onChange={handleChange}
          onBlur={handleBlur}
          rows={3}
        />
      )}
      <div>
        <Button
          variant="secondary"
          color="blue"
          onClick={handleCancel}
          style={{ marginRight: 8 }}
        >
          Back
        </Button>
        <Button
          variant="primary"
          color="blue"
          onClick={handleSubmit}
          disabled={submitDisabled}
        >
          Close JobCast
        </Button>
      </div>
    </>
  );
}

ArchiveForm.propTypes = {
  jobcast: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default ArchiveForm;
