import React from "react";

import {
  JOBCASTS,
  CANDIDATES,
  AGENCIES,
} from "../constants";
import { deconstructName, deconstructDescription } from "../utils";

const renderTitleCaption = (item, JOBCASTS) => {
  const styles = {
    infoContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "calc(100% - 140px)",
      minWidth: 120,
      paddingLeft: JOBCASTS ? 6 : 24,
    },
    ellipsisOverflow: {
      maxWidth: "100%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };

  return (
    <div style={styles.infoContainer}>
      <span style={styles.ellipsisOverflow}>
        {Object.values(deconstructName(item)).join(" ")}
      </span>
      <span className="small gray" style={styles.ellipsisOverflow}>
        {deconstructDescription(item)}
      </span>
    </div>
  );
};

function SearchResultItemBody({ item }) {
  switch (item.type) {
    case CANDIDATES:
    case AGENCIES:
      return renderTitleCaption(item);
    case JOBCASTS:
      return renderTitleCaption(item, JOBCASTS);
    default:
      return <></>;
  }
}

export default SearchResultItemBody;
