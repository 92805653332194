import normalize from "json-api-normalizer";

import { GET, PATCH } from "../../util/apiHelpers";

import { receiveIndividuals } from "../shared/individualActions";

export const fetchTeammates = () => (dispatch) => GET("/api/v3/employer/teammates").then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receiveIndividuals(normalized.teammate));
});

export const updateTeammatePermissions = (data) => (dispatch) => PATCH(
  `/api/v3/employer/teammates/${data.id}/update_permissions`,
  data
).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receiveIndividuals(normalized.teammate));
});

export const deactivateTeammate = (data) => (dispatch) => PATCH(
  `/api/v3/employer/teammates/${data.deactivateId}/deactivate`,
  data
).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receiveIndividuals(normalized.teammate));
});

export const reactivateTeammate = (id) => (dispatch) => PATCH(`/api/v3/employer/teammates/${id}/reactivate`).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receiveIndividuals(normalized.teammate));
});
