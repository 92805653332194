import Icon from "@material-ui/core/Icon";
import MaterialMenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    minWidth: "124px",
    maxWidth: "260px",
    padding: "11px 16px",
    fontFamily: "'proxima-nova', helvetica",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.29",
    letterSpacing: "-0.2px",
    textAlign: "left",
    color: "#263238",
  },
  icon: {
    marginRight: 24,
    color: "#B0BEC5",
    fontSize: 20,
    marginTop: "-2px",
  },
}));

function MenuItem(props) {
  const {
    className,
    icon,
    children,
    onClick,
    id,
    style,
    disabled = false,
  } = props;
  const classes = useStyles();

  return (
    <MaterialMenuItem
      disabled={disabled}
      id={id}
      key={`menu-item-${id}`}
      className={classNames(className, classes.root)}
      onClick={onClick}
      style={style}
      data-cy={`menu-item-${id.toLowerCase()}`}
    >
      {icon && <Icon classes={{ root: classes.icon }}>{icon}</Icon>}
      {children}
    </MaterialMenuItem>
  );
}

MenuItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default MenuItem;
