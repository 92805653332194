import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { addJobCastAgencies } from "../../../../../../../actions/employers/jobcastActions";
import Button from "../../../../../../forms/custom/Button";
import withSnackbar from "../../../../../../General/withSnackbar";

class InviteCTA extends Component {
  state = { submitting: false };

  componentDidUpdate(_prevProps, { submitting: prevSubmitting }) {
    const { submitting } = this.state;

    if (submitting && !prevSubmitting) {
      this.submit();
    }
  }

  submit = () => {
    const {
      id, submitInvitation, setItemInvited, snackbar
    } = this.props;

    submitInvitation()
      .then(() => {
        setItemInvited(id);
        snackbar.showMessage("Agency successfully invited to JobCast");
      })
      .catch(() => this.setState({ submitting: false }));
  };

  handleClick = () => {
    this.setState({ submitting: true });
  };

  render() {
    const { submitting } = this.state;

    return (
      <Button
        color="blue"
        variant="secondary"
        onClick={this.handleClick}
        disabled={submitting}
        data-cy="invite-button"
      >
        Invite
      </Button>
    );
  }
}

InviteCTA.propTypes = {
  id: PropTypes.string.isRequired,
  setItemInvited: PropTypes.func.isRequired,
  snackbar: PropTypes.object.isRequired,
  recruiterId: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitInvitation: () => dispatch(
    addJobCastAgencies({
      jobcast_id: ownProps.id,
      community_inclusion: false,
      selected_agencies: [ownProps.recruiterId],
    })
  ),
});

export default connect(null, mapDispatchToProps)(withSnackbar()(InviteCTA));
