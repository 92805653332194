// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
export function storageAvailable() {
  let storage;
  try {
    storage = window.localStorage;
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export function encodedStringToFileObj(encodedString, fileName, contentType) {
  const binary = atob(encodedString.replace(/\s/g, ""));
  const array = [];

  for (let i = 0; i < binary.length; i++) array.push(binary.charCodeAt(i));
  const blob = new Blob([new Uint8Array(array)], { type: contentType });
  blob.name = fileName;

  return blob;
}

export const getFileValueAttributes = async (fileValue, fieldName) => new Promise((resolve) => {
  const fileReader = new FileReader();
  fileReader.onload = (output) => resolve(output.target.result);
  return fileReader.readAsDataURL(fileValue);
}).then((fileContent) => ({
  [`${fieldName}_file`]: fileContent,
  [`${fieldName}_file_name`]: fileValue.name.replace(/ /g, "_"),
  [`${fieldName}_file_type`]: fileValue.type,
}));
