import { Form, Field, FieldArray } from "formik";
import get from "lodash/get";
import map from "lodash/map";
import React from "react";

import Button from "../../custom/Button";

import { PERCENTAGE_OF_COMPENSATION } from "./fieldHelpers";
import FormikFeeOptionRow from "./FormikFeeOptionRow";

class FormikFeeOptions extends React.Component {
  render() {
    const {
      fieldArrayName,
      formProps,
      typeLabel = "Fee Type",
      maxOptions,
    } = this.props;

    return (
      <Form style={{ width: "100%" }}>
        <FieldArray
          name={fieldArrayName}
          render={(arrayHelpers) => (
            <>
              {map(get(formProps.values, fieldArrayName), (_feeOption, idx) => (
                <Field
                  key={`${fieldArrayName}-${idx}`}
                  name={`${fieldArrayName}.${idx}`}
                  render={(field) => (
                    <FormikFeeOptionRow
                      {...{
                        field,
                        idx,
                        arrayHelpers,
                        fieldArrayName,
                        formProps,
                        typeLabel,
                      }}
                    />
                  )}
                />
              ))}
              {!maxOptions ||
              get(formProps.values, fieldArrayName).length < maxOptions ? (
                <Button
                  style={{ border: "none", padding: 0, marginBottom: 12 }}
                  onClick={() =>
                    arrayHelpers.push({
                      fee: "",
                      fee_note: "",
                      fee_type: PERCENTAGE_OF_COMPENSATION,
                      currency: null,
                    })
                  }
                  data-cy="another-fee-button"
                >
                  + Another Fee
                </Button>
              ) : (
                <></>
              )}
            </>
          )}
        />
      </Form>
    );
  }
}

export default FormikFeeOptions;
