import filter from "lodash/filter";
import React from "react";
import { connect } from "react-redux";

import {
  makeGetFilteredNotifications,
  UNREAD
} from "../../../../../../../selectors/notificationsSelectors";
import Navigation from "../../../../../../shared/JobCast/Candidates/CandidateCard/Navigation/Navigation";
import { AGENCY_CHAT, EVENT_HISTORY } from "../../constants";
import CandidateDrawer from "../../view/CandidateDrawer";

function CandidateCardNav({ candidate, navSelection, ...props }) {
  const drawerComponent = navSelection => (
    <CandidateDrawer candidateId={candidate.id} navSelection={navSelection} />
  );

  return (
    <Navigation
      {...props}
      candidate={candidate}
      navSelection={navSelection}
      drawerComponent={drawerComponent}
    />
  );
}

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();

  const mapStateToProps = (state, ownProps) => {
    const props = {};
    if (
      ownProps.navSelection === AGENCY_CHAT ||
      ownProps.navSelection === EVENT_HISTORY
    ) {
      const notifications = getFilteredNotifications(state, {
        [UNREAD]: true,
        recruiterSubmissionId: ownProps.candidate.id
      });

      props.unread_chat_count = filter(
        notifications,
        no => no.attributes.notificationSubjectType === "Message"
      ).length;

      props.unread_history_count = filter(
        notifications,
        no => no.attributes.notificationSubjectType ===
          "EmployerCandidateUpdateNotification"
      ).length;
    }

    return props;
  };

  return mapStateToProps;
};

export default connect(makeMapStateToProps, null)(CandidateCardNav);
