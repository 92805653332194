import merge from "lodash/merge";

import { RECEIVE_PAYMENT_NOTIFICATIONS } from "../../actions/shared/financeActions";

const defaultState = {
  loaded: false,
  items: {},
};

const paymentNotificationReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PAYMENT_NOTIFICATIONS:
      return merge({}, state, { loaded: true, items: action.payload });
    default:
      return state;
  }
};

export default paymentNotificationReducer;
