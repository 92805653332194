import React from "react";
import { connect } from "react-redux";

import { openModal, updateAnimation } from "../../../../../../../actions/shared/uiActions";
import Button from "../../../../../../forms/custom/Button";
import AdvanceModal from "../modals/AdvanceModal";

function AdvanceButton({
  candidate,
  openModal,
  overrideStyle,
  overloadedClasses,
  updateCardExitAnimation
}) {
  const handleClick = () => {
    updateCardExitAnimation();
    openModal(<AdvanceModal candidate={candidate} />);
  };

  return (
    <Button
      color="blue"
      variant="primary"
      onClick={handleClick}
      style={overrideStyle || {}}
      overloadedClasses={overloadedClasses}
      data-cy="advance-button"
    >
      Advance
    </Button>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateCardExitAnimation: () => dispatch(
    updateAnimation(`candidate-card-${ownProps.candidate.id}`, {
      classes: { exit: "bounceOutRight" }
    })
  ),
  openModal: content => dispatch(openModal(content))
});

export default connect(
  null,
  mapDispatchToProps
)(AdvanceButton);
