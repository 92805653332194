import React from 'react';
import { useDispatch } from "react-redux";

import { openModal } from '../../../../../../actions/shared/uiActions';
import Button from "../../../../../forms/custom/Button";
import ConvertContractModal from '../../../../TempJobCasts/view/Candidates/actions/modals/ConvertContract/ConvertContractModal';

function ConvertContractButton({
  candidate, overrideStyle, overloadedClasses, customText = "Convert"
}) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(openModal(
      <ConvertContractModal candidateId={candidate.id} />
    ));
  };

  return (
    <Button
      color="blue"
      variant="primary"
      onClick={handleClick}
      style={overrideStyle || {}}
      overloadedClasses={overloadedClasses}
      data-cy="convert-contract-button"
    >
      {customText}
    </Button>
  );
}

export default ConvertContractButton;
