import moment from "@date-io/moment";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import ReactQuill, { Quill } from "react-quill";
import IntlTelInput from "react-intl-tel-input";

import GlobalSnackbarProvider from "./components/General/GlobalSnackbarProvider";
import Main from "./components/Main";
import UserActionCableProvider from "./components/shared/ActionCable/UserActionCableProvider";
import useAppInitialization from "./hooks/useAppInitialization";
import configureStore from "./reducers/store";
import theme from "./util/theme";

const store = configureStore();

const AppContent = () => {
  const loading = useAppInitialization();

  return (
    <GlobalSnackbarProvider>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={moment}>
          <BrowserRouter basename="app">
            <CompatRouter basename="app">
              <UserActionCableProvider>
                <Main loading={loading} />
              </UserActionCableProvider>
            </CompatRouter>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </GlobalSnackbarProvider>
  );
};

const App = () => {
  return (
    <Provider store={store}>
      <AppContent />
    </Provider>
  );
};

export default App;
