import { Formik } from "formik";
import find from "lodash/find";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import pick from "lodash/pick";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { completePreassignment as employerCompletePreassignment } from "../../../../actions/employers/contractActions";
import { completePreassignment as recruiterCompletePreassignment } from "../../../../actions/recruiters/contractActions";

import { clearModal } from "../../../../actions/shared/uiActions";
import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import { buildFullName } from "../../../../util/formatHelpers";
import { getFileValueAttributes } from "../../../../util/storageHelpers";
import { object, string, array } from "../../../../util/yup";

import useSnackbar from "../../../General/customHooks/useSnackbar";

import {
  determineChecklistItemType,
  SOW_FIELD,
  BASIC_CONFIRMATION_FIELD,
  DATE_FIELD,
  DOCUMENT_FIELD,
  allChecklistItemsComplete,
  checkResponsibility,
  TIMESHEET_SUBMITTERS_FIELD,
} from "./fieldHelpers";

function buildIntialValues(candidate, contract) {
  const { tempPrerequisites } = candidate.attributes;

  // order handled here, by both field type and id to ensure consistent ordering of array items
  // between form re-initializations on candidate update
  const orderedPrerequisites = orderBy(
    tempPrerequisites,
    [
      (preReq) =>
        determineChecklistItemType(preReq.attributes) === SOW_FIELD ? -1 : 1,
      (preReq) =>
        determineChecklistItemType(preReq.attributes) === BASIC_CONFIRMATION_FIELD ? -1 : 1,
      (preReq) =>
        determineChecklistItemType(preReq.attributes) === DOCUMENT_FIELD ? -1 : 1,
      (preReq) =>
        determineChecklistItemType(preReq.attributes) === DATE_FIELD ? -1 : 1,
      (preReq) =>
        determineChecklistItemType(preReq.attributes) === TIMESHEET_SUBMITTERS_FIELD ? -1 : 1,
      ({ attributes }) => attributes.id,
    ],
    ["asc", "asc", "asc", "asc", "asc"]
  );

  const timesheetSubmitters = (contract?.attributes?.timesheetSubmitters ?? [])
    .map(({ id, firstName, lastName }) => (
      {
        value: id,
        label: buildFullName(firstName, lastName)
      }
    ));

  const checklistItems = map(orderedPrerequisites, ({ attributes }) => ({
    ...pick(attributes, [
      "id",
      "name",
      "description",
      "documentRequired",
      "requestedDate",
      "timesheetSubmittersRequired",
      "submittedDate",
      "employerConfirmedAt",
      "employerConfirmerName",
      "employerConfirmationRequired",
      "agencyConfirmedAt",
      "agencyConfirmerName",
      "agencyConfirmationRequired",
      "responsibility",
    ]),
    document: attributes.document?.url
      ? {
        name: attributes.document.url.split("?")[0].split("/").slice(-1)[0],
        preview: attributes.document.url,
      }
      : null,
    basicConfirmed: checkResponsibility(attributes),
    contractId: candidate.attributes.contractId,
    timesheetSubmitters: attributes.timesheetSubmittersRequired ? timesheetSubmitters : [],
  }));

  return { checklistItems };
}

function buildValidationSchema() {
  // these validations are simply type checks, validations mostly handled manually
  // since this form can be completed in pieces and submitted in an 'incomplete' state
  return object().shape({
    checklistItems: array().of(
      object().shape({
        document: string().nullable(),
        submittedDate: string().nullable(),
        timesheetSubmitters: array().nullable(),
      })
    ),
  });
}

function FormInitializer({ candidate, children }) {
  const dispatch = useDispatch();
  const currentIndividual = useSelector(getCurrentIndividual);
  const contract = useSelector(
    (state) => state.contracts.items[candidate.attributes.contractId]
  );
  const snackbar = useSnackbar();

  const initialValues = useMemo(
    () => buildIntialValues(candidate, contract),
    [candidate, contract]
  );

  const validationSchema = useMemo(
    () => buildValidationSchema(candidate),
    [candidate]
  );

  const handleSubmit = async (values, { setSubmitting }) => {
    const { checklistItems } = values;

    const submitValues = {
      contract_id: candidate.attributes.contractId,
      temp_recruiter_submission_prerequisites: await Promise.all(
        map(checklistItems, async (item) => {
          const preReqItem = find(
            candidate.attributes.tempPrerequisites,
            (preReq) => preReq.attributes.id === item.id
          );

          // we only pass the document up if it's been modified, so we
          // also need to separately track if the document has been deleted (delete_document param)
          const documentAttrs =
            item.document && item.document.lastModified
              ? await getFileValueAttributes(item.document, "document")
              : {};

          const prevDocSet = preReqItem.attributes.document?.url;

          const itemValues = {
            id: item.id,
            submitted_date: item.submittedDate,
            confirmation_selected: item.basicConfirmed,
            timesheet_submitter_ids: item.timesheetSubmitters.map(({ value }) => value),
            delete_document: prevDocSet && !item.document,
            ...documentAttrs,
          };

          return itemValues;
        })
      ),
    };

    const submissionFunc = currentIndividual.employer
      ? employerCompletePreassignment
      : recruiterCompletePreassignment;

    return dispatch(submissionFunc(submitValues))
      .then(() => {
        setSubmitting(false);

        dispatch(clearModal());

        const successMessage = allChecklistItemsComplete(checklistItems, currentIndividual)
          ? "All pre-assignment steps have been completed and the contract is now open."
          : "Pre-Assignment steps have been updated";

        snackbar.showMessage(successMessage);
      })
      .catch(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik
      {...{
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
      }}
    >
      {(formikBag) => children({ ...formikBag, initialValues })}
    </Formik>
  );
}

export default FormInitializer;
