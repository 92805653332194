import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

function ToolTipColumnLabel({
  labelText,
  toolTipText,
  toolTipStyle = {},
  iconStyle = {}
}) {
  return (
    <>
      {labelText}
      <Tooltip
        id="tooltip-icon"
        title={<span style={{ fontSize: "12px" }}>{toolTipText}</span>}
        style={({ width: "30%", ...toolTipStyle })}
      >
        <Icon
          style={({
            color: "#546e7a",
            fontSize: 18,
            marginLeft: 3,
            ...iconStyle
          })}
        >
          info_outline
        </Icon>
      </Tooltip>
    </>
  );
}

export default ToolTipColumnLabel;
