import { connect } from "formik";
import React from "react";

import DropdownSelect from "../custom/DropdownSelect";

export const ConnectedDropdownSelect = connect(
  ({
    formik: {
      values, errors, touched, setFieldValue, setFieldTouched
    },
    options,
    id,
    ...props
  }) => {
    const handleChange = (selection) => {
      setFieldValue(id, selection);
    };

    const handleBlur = () => {
      setFieldTouched(id, true);
    };

    return (
      <DropdownSelect
        id={id}
        value={values[id]}
        options={options}
        error={Boolean(touched[id] && errors[id])}
        helperText={touched[id] && errors[id]}
        onChange={handleChange}
        onBlur={handleBlur}
        isMulti
        isClearable
        {...props}
      />
    );
  }
);
