export const buttonSets = {
  intercom: {
    marginRight: "80px"
  },
  default: {}
};

export const toolTip = {
  icon: {
    color: "#546e7a",
    margin: "0 0 -3px 5px",
    fontSize: 18
  }
};
