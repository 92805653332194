import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { clearModal } from "../../actions/shared/uiActions";

function ConnectedModal(props) {
  return (
    props.visible ? (
      props.content
    ) : (
      <></>
    )
  );
}

ConnectedModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  content: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  visible: state.ui.modal.visible,
  content: state.ui.modal.content
});

const mapDispatchToProps = dispatch => ({
  clearModal: () => dispatch(clearModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedModal);
