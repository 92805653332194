import classnames from "classnames";
import merge from "lodash/merge";

import moment from "moment";

import { disabledProps } from "../../../../../util/formHelpers";

export const APPROVED_MESSAGE = "Approved by manager";
export const AWAITING_MESSAGE = "Submitted for approval";
export const ZERO_HOURS_MESSAGE = "Submitted";
export const NOT_SUBMITTED_MESSAGE = "Not submitted";

export const isWithin48Hours = (dateToCheck, formattedDate) => {
  const dateChecked = moment(dateToCheck);
  const targetDate = moment(formattedDate, 'dddd, MMMM D, YYYY h:mm A z');
  const hoursDifference = Math.abs(targetDate.diff(dateChecked, 'hours'));

  return hoursDifference <= 48;
};

export const getFormattedDate = (date) => {
  return date.format('dddd, MMMM D, YYYY h:mm A [EST]');
};

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const customSubTextFieldProps = merge({}, disabledProps(true), {
  FormHelperTextProps: {
    classes: {
      root: classnames("caption", "grey", "noMargin"),
    },
  },
  style: { marginBottom: 0 },
});
