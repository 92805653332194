import { SET_PAGE_PUBLIC } from "../../actions/shared/uiActions";

const _defaultState = false;

export const publicReducer = (state = _defaultState, action) => {
  switch (action.type) {
    case SET_PAGE_PUBLIC:
      return action.payload;
    default:
      return state;
  }
};
