import React, { useContext } from "react";

/** @type React.Context<any> */
export const PlacementContext = React.createContext({});

const usePlacementContext = () => {
  const context = useContext(PlacementContext);
  if (context === undefined) {
    throw new Error("usePlacementContext must be used within PlacementContextProvider");
  }
  return context;
};

export default usePlacementContext;
