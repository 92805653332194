import React from "react";

import CandidateActionMenu, {
  DisabledCandidateActionMenu,
} from "../actions/CandidateActionMenu";
import KeepButton from "../actions/KeepButton";
import AdvanceButton from "../actions/modalMountCTAs/AdvanceButton";
import HireButton from "../actions/modalMountCTAs/HireButton";
import RejectButton from "../actions/modalMountCTAs/RejectButton";
import ReportFailureButton from "../actions/modalMountCTAs/ReportFailureButton";
import RestoreButton from "../actions/modalMountCTAs/RestoreButton";
import RatingSubmissionForm from "../actions/RatingSubmissionForm/RatingSubmissionForm";

function EmptyMenuContainer() {
  return <div style={{ width: 60, height: 48 }} />;
}

function ButtonContainer({ candidate, archived = false }) {
  const buttons = (() => {
    if (archived) {
      return candidate.attributes.status === "withdrawn"
        ? []
        : [HireButton, CandidateActionMenu];
    }

    switch (candidate.attributes.status) {
      case "new":
        return [RejectButton, KeepButton, EmptyMenuContainer];
      case "offer":
        return [RejectButton, HireButton, CandidateActionMenu];
      case "hired":
        const {
          collectionAt,
          guaranteeFailure: { approvedAt },
        } = candidate.attributes;

        const buttons = [ReportFailureButton];
        !collectionAt && !approvedAt && buttons.push(CandidateActionMenu);

        return buttons;
      case "rejected":
        return [RestoreButton, CandidateActionMenu];
      case "withdrawn":
        return [RestoreButton, DisabledCandidateActionMenu];
      case "reviewing":
      case "interviewing":
      default:
        return [RejectButton, AdvanceButton, CandidateActionMenu];
    }
  })();

  const buttonElements = buttons.map((buttonEl, idx) =>
    React.createElement(buttonEl, {
      key: `${buttonEl}-${candidate.id}-${idx}`,
      candidate,
      drawerBtn: true,
      overrideStyle: idx ? { marginLeft: 16 } : {},
      displayTime: true,
    })
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        alignItems: "flex-end",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {buttonElements}
      </div>
      {candidate.attributes.status !== "hired" && (
        <RatingSubmissionForm candidate={candidate} />
      )}
    </div>
  );
}

export default ButtonContainer;
