import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import { withStyles, createStyles } from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteForever from "@material-ui/icons/DeleteForever";
import PropTypes from "prop-types";
import React, { createRef } from "react";

import Dropzone from "react-dropzone";

import FileInputLabel from "./FileInputLabel";

const styles = createStyles({
  adornment: {
    marginLeft: "0",
  },
  input: {
    color: "#546e7a",
    width: "100%",
  },
  labelText: {
    color: "#B0BEC5",
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  labelTextError: {
    color: "#FF6D6D",
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  uploadArrow: {
    color: "#008DAE",
    cursor: "pointer",
    fontSize: "23px",
    height: "23px",
    width: "23px",
  },
  uploadArrowError: {
    color: "#FF6D6D",
    cursor: "pointer",
    fontSize: "23px",
    height: "23px",
    width: "23px",
  },
  uploadArrowDisabled: {
    color: "#B0BEC5",
    fontSize: "23px",
    height: "23px",
    width: "23px",
  },
  helperText: {
    width: "100%",
    color: "#B0BEC5",
    margin: "0",
    position: "inherit",
    fontSize: "10px",
    textAlign: "left",
    marginTop: "8px",
    minHeight: "1em",
    fontFamily: "proxima-nova",
    lineHeight: "1em",
  },
  helperTextError: {
    width: "100%",
    color: "#FF6D6D",
    margin: "0",
    position: "inherit",
    fontSize: "10px",
    textAlign: "left",
    marginTop: "8px",
    minHeight: "1em",
    fontFamily: "proxima-nova",
    lineHeight: "1em",
  },
});

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes'

  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

class FileInput extends React.Component {
  state = {
    open: false,
  };

  constructor(props) {
    super(props);
    this.dropzoneRef = createRef();

    this.onDrop = this.onDrop.bind(this);
    this.handleInputClick = this.handleInputClick.bind(this);
  }

  get filename() {
    return (this.props.value && this.props.value.name) || "";
  }

  handleDelete = (e) => {
    this.props.onChange(e, null);
  };

  handleInputClick = (e) => {
    if (!this.filename) {
      e.preventDefault();
      this.dropzoneRef.current.open();
    }
  };

  onDrop(files, rejectedFiles) {
    if (files.length >= 1 && files[0]) {
      this.props.onChange(null, files[0]);
    } else if (this.props.flashUploadError) {
      this.props.flashUploadError();
    } else if (rejectedFiles[0]) {
      this.setState({
        rejectedFileSize: rejectedFiles[0].size,
      });
    }
  }

  renderFileUploadIcon() {
    const { error, classes, disabled, redTrashIcon, adornmentDisableOverride } =
      this.props;

    let iconStyle;
    if (error) {
      iconStyle = classes.uploadArrowError;
    } else if (adornmentDisableOverride) {
      iconStyle = classes.uploadArrowDisabled;
    } else {
      iconStyle = classes.uploadArrow;
    }

    return (
      <InputAdornment className={classes.adornment} position="end">
        {this.filename ? (
          <DeleteForever
            onClick={this.handleDelete}
            className={
              error || redTrashIcon
                ? classes.uploadArrowError
                : classes.uploadArrow
            }
          />
        ) : (
          <CloudUploadIcon
            className={iconStyle}
            data-cy={`file-input-upload-${this.props.id}`}
          />
        )}
      </InputAdornment>
    );
  }

  render() {
    const {
      classes,
      disabled,
      id,
      label,
      value,
      error,
      helperText,
      overrideStyle,
      minSize,
      maxSize,
      accept,
      onBlur,
      redTrashIcon,
      adornmentDisableOverride,
    } = this.props;
    const { open, rejectedFileSize } = this.state;

    const exceedsMaxSize = rejectedFileSize && rejectedFileSize > maxSize;
    const belowMinSize = rejectedFileSize && rejectedFileSize < minSize;

    return (
      <FormControl style={overrideStyle}>
        {label && (
          <FileInputLabel
            disabled={disabled}
            htmlFor={id}
            shrink={Boolean(open) || Boolean(value)}
          >
            <span style={error ? styles.labelTextError : styles.labelText}>
              {label}
            </span>
          </FileInputLabel>
        )}
        <Input
          id={id}
          error={error}
          onClick={this.handleInputClick}
          inputProps={{ className: classes.input }}
          endAdornment={
            (!disabled || adornmentDisableOverride) &&
            this.renderFileUploadIcon()
          }
          value={this.filename}
          onBlur={onBlur}
          autoComplete="off"
          disabled={disabled}
        />
        <Dropzone
          accept={accept}
          multiple={false}
          onDrop={this.onDrop}
          ref={this.dropzoneRef}
          style={{ display: "none" }}
          minSize={minSize}
          maxSize={maxSize}
          disabled={disabled}
          inputProps={{
            "data-cy": `file-input-${
              typeof label === "string" ? label.toLowerCase() : ""
            }`,
            "test-cy": `file-input-dropzone-${id}`,
          }}
        />
        {Boolean(helperText) && (
          <FormHelperText
            className={error ? classes.helperTextError : classes.helperText}
          >
            {helperText}
          </FormHelperText>
        )}
        {exceedsMaxSize && (
          <FormHelperText className={classes.helperTextError}>
            {`File size exceeds max limit of ${formatBytes(maxSize)}`}
          </FormHelperText>
        )}
        {belowMinSize && (
          <FormHelperText className={classes.helperTextError}>
            {`File size below min limit of ${formatBytes(minSize)}`}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

FileInput.defaultProps = {
  error: false,
  disabled: false,
  overrideStyle: {},
  onBlur: () => {},
};

FileInput.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  accept: PropTypes.string.isRequired,
  overrideStyle: PropTypes.object,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.node,
  flashUploadError: PropTypes.func,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
};

export default withStyles(styles)(FileInput);
