import { Formik } from "formik";
import React, { useCallback, useMemo } from "react";

import { useDispatch } from "react-redux";

import { violationReport } from "../../../../../actions/employers/agencyActions";
import { clearModal } from "../../../../../actions/shared/uiActions";
import ActionModalContainer from "../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../General/ActionModal/ActionModalFooter";

import ActionModalHeader from "../../../../General/ActionModal/ActionModalHeader";

import withSnackbar from "../../../../General/withSnackbar";

import { buildInitialValues, validationSchema } from "./fieldHelpers";
import ViolationModalContent from "./ViolationModalContent";

function ViolationModal({ recruiterId, recruiterName, snackbar }) {
  const dispatch = useDispatch();

  const initialValues = useMemo(() => buildInitialValues({ recruiterId }), [recruiterId]);

  const handleSubmit = useCallback((values, { setSubmitting, resetForm }) => {
    dispatch(violationReport(values))
      .then(() => {
        setSubmitting(false);
        dispatch(clearModal());
        snackbar.showMessage("Your report has been submitted to our team. Thank you.");
        resetForm();
      })
      .catch(() => {
        setSubmitting(false);
        snackbar.showMessage("There was an issue processing your request");
      });
  }, [dispatch, snackbar]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <ActionModalContainer color="red">
          <ActionModalHeader title="Report Violation" subtitle={recruiterName} />
          <ActionModalContent>
            <ViolationModalContent />
          </ActionModalContent>
          <ActionModalFooter
            actionText="Report"
            handleAction={props.handleSubmit}
            isSubmitting={props.isSubmitting}
          />
        </ActionModalContainer>
      )}
    </Formik>
  );
}

export default withSnackbar()(ViolationModal);
