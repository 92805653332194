// sections
export const ACTIVE = "active";
export const PENDING = "pending";
export const INVITE = "invite";
export const TEAM_LISTS = "team_lists";
export const PERSONAL_LISTS = "personal_lists";
export const CREATE_RECRUITER_LIST = "create_recruiter_list";
export const EDIT_RECRUITER_LIST = "edit_recruiter_list";
export const YOUR_COMMUNITY = "your_community";
export const BLOCKED = "blocked";

// col keys
export const RECRUITER_COL = "recruiterCol";
export const AGENCY_COL = "agencyCol";
export const AGENT_COL = "agentCol";
export const CONTACT_COL = "contactCol";
export const EMAIL_COL = "emailCol";
export const FEE_COL = "feeCol";
export const STATUS_COL = "statusCol";
export const ACTION_COL = "actionCol";

export const COL_KEYS = [
  AGENT_COL,
  RECRUITER_COL,
  AGENCY_COL,
  CONTACT_COL,
  EMAIL_COL,
  FEE_COL,
  ACTION_COL,
];

// sort dirs
export const ASC = "asc";
export const DESC = "desc";

// pending agency action menu items
export const CHANGE_FEES = "EDIT_FEES";
export const DELETE_AGENCY = "DELETE_AGENCY";

export const ACTION_ITEMS = [
  {
    name: CHANGE_FEES,
    icon: "edit",
  },
  {
    name: DELETE_AGENCY,
    icon: "delete",
  },
];
