import every from "lodash/every";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import { createSelector } from "reselect";

import createDeepEqualSelector from "./createDeepEqualSelector";

import { makeGetViewingIndividualId } from "./individualSelectors";

export const UNREAD = "unread";

export const getNotifications = ({ notifications }) => notifications;

export const makeGetNotificationsLoaded = () => createSelector([getNotifications], ({ loaded }) => loaded);

export const makeGetNotificationItems = () => createSelector([getNotifications], (notifications) => notifications.items);

const buildFilterFunctions = ({
  unread,
  announcementId,
  jobcastId,
  recruiterSubmissionId,
  positionCorrespondenceId,
  subjectType,
  subjectId,
  positionState,
  event,
  recipientId
}) => {
  const filterFunctions = [];

  if (unread) {
    filterFunctions.push(({ attributes }) => !attributes.markedReadAt);
  }

  if (jobcastId) {
    filterFunctions.push(
      ({ attributes }) => jobcastId === attributes.jobcastId
    );
  }

  if (announcementId) {
    filterFunctions.push(
      ({ attributes: { notificationSpecifics } }) =>
        announcementId ===
        (notificationSpecifics && notificationSpecifics.announcementId)
    );
  }

  if (positionCorrespondenceId) {
    filterFunctions.push(
      ({
        attributes: {
          notificationSpecifics,
          positionCorrespondenceId: entityPositionCorrespondenceId,
        },
      }) => {
        if (entityPositionCorrespondenceId) {
          // eslint-disable-next-line eqeqeq
          return positionCorrespondenceId == entityPositionCorrespondenceId;
        }
        if (
          notificationSpecifics &&
          notificationSpecifics.positionCorrespondenceId
        ) {
          return (
          // eslint-disable-next-line eqeqeq
            notificationSpecifics.positionCorrespondenceId == positionCorrespondenceId
          );
        }
        return false;
      }
    );
  }

  if (recruiterSubmissionId) {
    filterFunctions.push(
      ({ attributes }) =>
        recruiterSubmissionId === attributes.recruiterSubmissionId
    );
  }

  if (subjectType) {
    filterFunctions.push(({ attributes }) =>
      isArray(subjectType)
        ? includes(subjectType, attributes.notificationSubjectType)
        : attributes.notificationSubjectType === subjectType);
  }

  if (subjectId) {
    filterFunctions.push(({ attributes }) =>
      isArray(subjectId)
        ? includes(subjectId, attributes.notificationSubjectId)
        : attributes.notificationSubjectId === subjectId);
  }

  if (positionState) {
    filterFunctions.push(
      ({ attributes: { notificationSpecifics } }) =>
        positionState ===
        (notificationSpecifics && notificationSpecifics.positionState)
    );
  }

  if (recipientId) {
    filterFunctions.push(
      ({ attributes: { individualId } }) => recipientId === individualId
    );
  }

  if (event) {
    filterFunctions.push(({ attributes }) => attributes.event === event);
  }

  return filterFunctions;
};

export const makeGetFilteredNotifications = () => {
  const getFilters = (_state, filters = {}) => filters;

  const filtersSelector = createDeepEqualSelector(
    [getFilters],
    (filters) => filters
  );

  return createSelector(
    [makeGetNotificationItems(), makeGetViewingIndividualId(), filtersSelector],
    (items, viewingIndividualId, filters) => {
      const filterFunctions = [].concat(
        buildFilterFunctions(filters)
      );

      return filter(items, (item) => every(filterFunctions, (filterFunction) => filterFunction(item)));
    }
  );
};

export const makeGetGroupedNotifications = (groupKey) => createSelector([makeGetFilteredNotifications()], (notifications) => {
  const notificationsWithKey = filter(
    notifications,
    (notification) => notification.attributes[groupKey]
  );

  return groupBy(
    notificationsWithKey,
    (notification) => notification.attributes[groupKey]
  );
});
