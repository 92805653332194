import { Grid, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeContract as employerCloseContract } from '../../../actions/employers/contractActions';
import { closeContract as recruiterCloseContract } from '../../../actions/recruiters/contractActions';
import { clearModal } from '../../../actions/shared/uiActions';
import { getCurrentIndividual } from '../../../selectors/individualSelectors';
import { disabledProps } from '../../../util/formHelpers';
import ConvertContractButton from '../../employer/JobCasts/view/Candidates/actions/ConvertContractButton';
import FieldLabel from '../../employer/JobCasts/view/Description/sections/FieldLabel';
import FormContextDatePicker from '../../forms/formik/FormContextInputs/FormContextDatePicker';
import FormContextDropdown from '../../forms/formik/FormContextInputs/FormContextDropdown';
import FormContextTextField from '../../forms/formik/FormContextInputs/FormContextTextField';
import ActionModalContainer from '../../General/ActionModal/ActionModalContainer';
import ActionModalContent from '../../General/ActionModal/ActionModalContent';
import ActionModalFooter from '../../General/ActionModal/ActionModalFooter';
import ActionModalHeader from '../../General/ActionModal/ActionModalHeader';
import useSnackbar from '../../General/useSnackbar';

import {
  REASON_OPTIONS, validationSchema, initialValues, CONVERTING, OTHER
} from './constants';

function CloseContractModal({ candidate }) {
  const currentIndividualType = useSelector((state) => getCurrentIndividual(state).type);

  const { firstName, lastName } = candidate.attributes;

  const dispatch = useDispatch();
  const snackbar = useSnackbar();

  const closeContract = currentIndividualType === "employer" ? employerCloseContract : recruiterCloseContract;

  const onSubmit = (values) => {
    dispatch(closeContract(candidate.id, values))
      .then(() => {
        dispatch(clearModal());
        snackbar.showMessage("Contract Ended");
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, ...props }) => {
        const { reason } = props.values;

        return (
          <ActionModalContainer color="red">
            <ActionModalHeader
              title="Close Contract"
              subtitle={`${firstName} ${lastName}`}
            />
            <ActionModalContent>
              <p style={{ margin: "0 0 24px 0" }}>
                Complete this form to end this open contract. This action will alert the agency,
                but not the worker. Worker hours, up to and including the provided last day,
                will be editable until the end of this week&apos;s pay period.
              </p>
              <div style={{ width: "100%", overflow: 'hidden' }}>
                <Grid container spacing={9}>
                  <Grid item xs={3}>
                    <TextField
                      {...disabledProps(true)}
                      label={<FieldLabel name="Worker Name" />}
                      value={`${firstName} ${lastName}`}
                    />
                  </Grid>
                  <Grid item xs={3} style={{ marginRight: '60px' }}>
                    <FormContextDropdown
                      id="reason"
                      label="Why is this contract ending?"
                      options={currentIndividualType === "employer" ? [CONVERTING, ...REASON_OPTIONS] : REASON_OPTIONS}
                      overrideStyle={{ width: "170%" }}
                      noShrink
                    />
                  </Grid>
                  {(reason !== CONVERTING && reason !== "") && (
                    <Grid item xs={3}>
                      <FormContextDatePicker
                        id="last_day"
                        label="Last Day of Work"
                        style={{ width: "150px" }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  )}
                </Grid>
                {reason === OTHER && (
                  <Grid container spacing={6}>
                    <Grid item xs={6}>
                      <FormContextTextField
                        id="other_reason"
                        label="Reason For Contract End"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                )}
                {(reason === CONVERTING) && (
                  <>
                    <Grid container spacing={12}>
                      <Grid item xs={12}>
                        <p>
                          Congratulations! Please click the button below to launch the
                          <strong> Convert Candidate</strong> flow.
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container spacing={12}>
                      <Grid item xs={12}>
                        <div style={{ textAlign: "center", paddingTop: "12px" }}>
                          <ConvertContractButton candidate={candidate} customText="Begin Conversion" />
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>
            </ActionModalContent>
            <ActionModalFooter
              actionText="End Contract"
              handleAction={() => {
                props.setFieldTouched("reason", true);
                props.setFieldTouched("last_day", true);
                handleSubmit();
              }}
              disabled={reason === CONVERTING}
              isSubmitting={props.isSubmitting}
            />
          </ActionModalContainer>
        );
      }}
    </Formik>
  );
}

export default CloseContractModal;
