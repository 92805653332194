import React from "react";
import { Col, Row } from "react-grid-system";
import { connect } from "react-redux";

import StarRatingPanel from "../../../../../../../forms/custom/StarRatingPanel";
import GlobalAvatar from "../../../../../../../General/GlobalAvatar";

function EmployerRatingPanel({
  mobileView,
  jobcast,
  setFieldValue,
  setFieldTouched,
  values,
  errors,
  touched,
}) {
  const createStarRatingPanel = (name, label) => (
    <div>
      <StarRatingPanel
        centered={mobileView}
        id={name}
        key={name}
        label={label || name}
        context={this}
        error={Boolean(touched[name] && errors[name])}
        helperText={touched[name] && errors[name]}
        onChange={(target) => setFieldValue(name, target.target.value)}
        onBlur={() => setFieldTouched(name, true)}
        value={values.name}
        col_width={12}
      />
    </div>
  );

  return (
    <div style={{ paddingTop: `${mobileView ? "0px" : "24px"}` }}>
      <Row>
        <Col
          xs={12}
          md={5}
          className="centered"
          style={{
            paddingBottom: `${mobileView ? "1em" : 0}`,
            paddingTop: "30px",
          }}
        >
          <Row>
            <Col
              xs={5}
              md={12}
              offset={{ xs: 1, md: 3 }}
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                padding: 0,
                paddingBottom: `${mobileView ? "0px" : "12px"}`,
              }}
            >
              <GlobalAvatar
                size="large"
                individual={{
                  avatarIcon: jobcast.individualAvatar,
                  firstName: jobcast.individualFirstName,
                  lastName: jobcast.individualLastInitial,
                }}
              />
            </Col>
            <Col
              xs={5}
              md={12}
              offset={{ md: 3 }}
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: `${mobileView ? "flex-start" : "center"}`,
                padding: 0,
              }}
            >
              <div
                className={`${mobileView ? "small" : null}`}
                style={{
                  lineHeight: "1.25em",
                  fontSize: `${!mobileView ? "12px" : null}`,
                  textAlign: `${mobileView ? "left" : "center"}`,
                  maxWidth: 225,
                }}
              >
                <div className="subtitle-2" data-layout="employer-name">
                  {jobcast.individualFirstName}
                  {' '}
                  {jobcast.individualLastInitial}
                </div>
                <div
                  className="small"
                  data-layout="employer-organization"
                  style={{
                    maxWidth: 170,
                    wordBreak: "break-word",
                    marginBottom: 12,
                  }}
                >
                  {jobcast.organizationName}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6} style={{ maxWidth: "100%" }}>
          {createStarRatingPanel("candidateExperience", "candidate experience")}
          {createStarRatingPanel("responsiveness")}
          {createStarRatingPanel(
            "likelihood",
            "Likelihood of recruiting for again"
          )}
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  mobileView: state.ui.browser.lessThan.medium,
});

export default connect(mapStateToProps, null)(EmployerRatingPanel);
