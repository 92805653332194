import filter from "lodash/filter";
import values from "lodash/values";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { EMPLOYER_TAB_OPTIONS } from "../HeaderPrimary/NavigationTabs/constants";

import HeaderListLinks from "./HeaderListLinks";

function EmployerTabs({ handleMenuClick }) {
  const scope = useSelector((state) => state.ui.headerMenu.scope);

  const tabItems = useMemo(() => {
    return values(
      filter(
        EMPLOYER_TAB_OPTIONS,
        (option) => option.exclusiveScope === scope || !option.exclusiveScope
      )
    );
  }, [scope]);

  return <HeaderListLinks handleMenuClick={handleMenuClick} items={tabItems} />;
}

export default EmployerTabs;
