import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import classnames from "classnames";
import capitalize from "lodash/capitalize";
import includes from "lodash/includes";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { submitPositionCorrespondenceAnswer } from "../../../../../actions/employers/jobcastUpdatesActions";
import {
  inputValue,
  errorString,
  hasErrors,
  updateFormValue,
  validateFields,
} from "../../../../../util/formHelpers";
import Button from "../../../../forms/custom/Button";

import {
  PUBLIC, PRIVATE, MATCH_QUESTION, REPLY_ICONS
} from "./constants.js";

const styles = () => ({
  container: {
    marginTop: 20,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 24,
  },
});

export class ReplyForm extends Component {
  constructor(props) {
    super(props);

    this.replySubmissionId = `reply-submission-message-${this.props.questionId}`;

    this.state = {
      [this.replySubmissionId]: inputValue(),
      submitted: false,
    };

    this.inputErrorString = errorString.bind(this);
    this.inputHasErrors = hasErrors.bind(this);
    this.updateInputValue = updateFormValue.bind(this);
    this.validateFields = validateFields.bind(this);
    this.preSubmitValidations = {
      [this.replySubmissionId]: { required: true, minWords: 3 },
    };
  }

  handleSubmit = async () => {
    if (!this.state.submitted) {
      const { questionId, type, submitReply } = this.props;
      return this.validateFields(this.preSubmitValidations)
        .then(() => {
          this.setState({ submitted: true }, () => {
            submitReply({
              questionId,
              visibility: type,
              answer: this.state[this.replySubmissionId].value,
            });
          });
        })
        .catch(() => {});
    }
  };

  renderDescriptionText() {
    switch (this.props.type) {
      case PRIVATE:
      default:
        return (
          <div className="small">
            A
            {' '}
            <strong>private reply</strong>
            {' '}
            will only share your answer with
            the agency that asked the
            {" "}
            {this.props.questionSubjectType === MATCH_QUESTION
              ? "question"
              : "feedback"}
          </div>
        );
      case PUBLIC:
        return (
          <div className="small">
            A
            {' '}
            <strong>public reply</strong>
            {' '}
            will share your answer with all
            agencies working on this JobCast
          </div>
        );
    }
  }

  renderButtonSubmissionText() {
    switch (this.props.type) {
      case PRIVATE:
      default:
        return "Send";
      case PUBLIC:
        return "Publish";
    }
  }

  render() {
    const {
      classes, type, handleClose, questionSubjectType
    } = this.props;

    return (
      <div className={classes.container}>
        <TextField
          id={this.replySubmissionId}
          helperText={this.inputErrorString(this.replySubmissionId)}
          error={this.inputHasErrors(this.replySubmissionId)}
          label={`${capitalize(type)} Reply to ${
            questionSubjectType === MATCH_QUESTION ? "Question" : "Feedback"
          }`}
          value={this.state[this.replySubmissionId].value}
          onChange={this.updateInputValue(this.replySubmissionId, {
            minWords: 3,
            bounce: true,
          })}
          autoFocus
          multiline
        />
        <div className={classnames(classes.buttonContainer)}>
          <Button
            icon={REPLY_ICONS[type].icon}
            color="blue"
            variant="primary"
            style={{ marginRight: 20 }}
            onClick={this.handleSubmit}
          >
            {this.renderButtonSubmissionText()}
          </Button>
          <Button
            color="grey"
            variant="secondary"
            style={{ marginRight: 20 }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
        {this.renderDescriptionText()}
      </div>
    );
  }
}

ReplyForm.propTypes = {
  questionId: PropTypes.number.isRequired,
  type: (props, propName, componentName) => {
    if (!includes([PUBLIC, PRIVATE], props[propName])) {
      return new Error(
        `Invalid prop \`${
          propName
        }\` supplied to` +
          ` \`${
            componentName
          }\`. Validation failed.`
      );
    }
  },
  submitReply: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  submitReply: (data) => dispatch(submitPositionCorrespondenceAnswer(data)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ReplyForm));
