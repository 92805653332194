import keys from "lodash/keys";
import merge from "lodash/merge";

import {
  RECEIVE_POSITION_HISTORY,
  RECEIVE_POSITION_HISTORY_LOADED
} from "../../actions/shared/jobcastUpdatesActions";

const _defaultState = {
  loaded: {},
  items: []
};

const jobcastHistoryReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_POSITION_HISTORY:
      const key = keys(action.payload)[0];

      return merge({}, state, { items: action.payload[key] });
    case RECEIVE_POSITION_HISTORY_LOADED:
      return merge({}, state, { loaded: { [action.payload]: true } });
    default:
      return state;
  }
};

export default jobcastHistoryReducer;
