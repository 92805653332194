import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchCandidate } from "../../../../../../../../actions/employers/candidateActions";
import successImage from "../../../../../../../../assets/images/candidate-success.svg";
import { buildFullName } from "../../../../../../../../util/formatHelpers";

import ActionModalContainer from "../../../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../../../General/ActionModal/ActionModalContent";
import ActionModalHeader from "../../../../../../../General/ActionModal/ActionModalHeader";
import RateRecruiterModalContent from "../../../../../../JobCasts/view/Candidates/actions/modals/RateRecruiter/RateRecruiterModalContent";
import { OFFER } from "../../../../../../JobCasts/view/Candidates/constants";

import FormFooter from "../../../../../../Shared/Modals/FormFooter";

import FormStepProvider, {
  FormStepper,
} from "../../../../../../Shared/Modals/FormStepProvider";

import BillingInformation from "../../../../../../Shared/Modals/sections/BillingInformation/BillingInformation";
import WorkInformation from "../../../../../../Shared/Modals/sections/WorkInformation";

import FormInitializer from "./FormInitializer";

import AgencyInformation from "./sections/AgencyInformation";

const AGENCY_INFORMATION = "Agency Information";
const WORK_INFORMATION = "Work Information";
const BILLING_INFORMATION = "Billing Information";

const ALL_STEPS = [AGENCY_INFORMATION, WORK_INFORMATION, BILLING_INFORMATION];

const STEP_COMPONENTS = {
  [AGENCY_INFORMATION]: AgencyInformation,
  [WORK_INFORMATION]: WorkInformation,
  [BILLING_INFORMATION]: BillingInformation,
};

// field mappings to touched object, scoped by section
export const SECTION_FIELDS = {
  [AGENCY_INFORMATION]: {}, // all these fields are un-editable
  [WORK_INFORMATION]: {
    scheduledStartDate: true,
    scheduledEndDate: true,
    primaryWorkState: true,
    primaryWorkZipCode: true,
    statementOfWorkDescription: true,
    temp_prerequisites: true,
  },
  [BILLING_INFORMATION]: {
    costCenterId: true,
    invoiceMemo: true,
    timesheetApproverIds: true,
  },
};

function PreAssignmentModalContent({ candidate }) {
  return (
    <FormInitializer candidate={candidate}>
      {() => (
        <FormStepProvider
          allSteps={ALL_STEPS}
          stepComponents={STEP_COMPONENTS}
          sectionFields={SECTION_FIELDS}
        >
          {(stepComponent) => (
            <>
              <ActionModalContent>
                <FormStepper />
                {React.createElement(stepComponent)}
              </ActionModalContent>
              <FormFooter sectionFields={SECTION_FIELDS} />
            </>
          )}
        </FormStepProvider>
      )}
    </FormInitializer>
  );
}

function SuccessMessage({ agencyName }) {
  return (
    <>
      <h5>This candidate has been moved to Pre-Assignment</h5>
      <div style={{ marginTop: 16, display: "flex", flexDirection: "row" }}>
        <img
          style={{ height: 80, width: 235, marginRight: 24 }}
          src={successImage}
          alt="success.svg"
        />
        <p>
          The next actions will be taken by {agencyName}. You will receive
          updates as each stage of pre-assignment is completed.
        </p>
      </div>
    </>
  );
}

function AdvanceToPreAssignmentModal({ candidateId }) {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // fetch full candidate, not enough data on index serialized version
    dispatch(fetchCandidate(candidateId)).then(() => setLoaded(true));
  }, [dispatch, candidateId]);

  const candidate = useSelector((state) => state.candidates.items[candidateId]);

  if (!loaded) {
    return null;
  }

  const { firstName, lastName } = candidate.attributes;

  // should be truthy immediately after advancing candidate to pre-assignment
  const successfullyAdanced = candidate.attributes.status === OFFER;

  return (
    <ActionModalContainer color="blue">
      <ActionModalHeader
        title="Make Offer for Pre-Assignment"
        subtitle={buildFullName(firstName, lastName)}
      />
      {successfullyAdanced ? (
        <RateRecruiterModalContent
          candidateId={candidate.id}
          renderCustomIntroComponent={() => (
            <SuccessMessage agencyName={candidate.attributes.agencyName} />
          )}
        />
      ) : (
        <PreAssignmentModalContent candidate={candidate} />
      )}
    </ActionModalContainer>
  );
}

export default AdvanceToPreAssignmentModal;
