import { pickBy, omit, values } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentIndividual } from '../../../selectors/individualSelectors';

import {
  RECRUITER_TAB_OPTIONS,
  TALK_PROFILE,
  TALK_PROFILE_V2
} from '../HeaderPrimary/NavigationTabs/constants';

import HeaderListLinks from './HeaderListLinks';

function RecruiterTabs({ handleMenuClick }) {
  const scope = useSelector((state) => state.ui.headerMenu.scope);
  const currentIndividual = useSelector(getCurrentIndividual);

  const tabItems = useMemo(() => {
    const scopedTabs = pickBy(
      RECRUITER_TAB_OPTIONS,
      (option) => option.exclusiveScope === scope || !option.exclusiveScope
    );

    const omitTabs = [];

    omitTabs.push(TALK_PROFILE);

    if (!currentIndividual.talkAgencyProfileEligible) {
      omitTabs.push(TALK_PROFILE, TALK_PROFILE_V2);
    }

    return values(omit(scopedTabs, omitTabs));
  }, [scope, currentIndividual.talkAgencyProfileEligible]);

  return <HeaderListLinks handleMenuClick={handleMenuClick} items={tabItems} />;
}

export default RecruiterTabs;
