import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import { Col } from "react-grid-system";

function StyledField({ label, value, tooltip }) {
  return (
    <Col xs={4} style={{ marginBottom: 12 }}>
      <div className="static-input-label" style={{ lineHeight: "13px" }}>
        {label}
        {tooltip && (
        <Tooltip
          id="tooltip-icon"
          title={<span style={{ fontSize: "12px" }}>{tooltip}</span>}
        >
          <Icon style={{ fontSize: 12, marginLeft: 2 }}>info_outline</Icon>
        </Tooltip>
        )}
      </div>
      <div style={{ padding: "6px 0px" }}>{value}</div>
    </Col>
  );
}

export default StyledField;
