import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import React from 'react';

const styles = {
  shrink: {
    transform: 'translate(0, 1.5px)',
    transformOrigin: 'top left',
  }
};

function FileInputLabel(props) {
  return (
    <InputLabel
      {...props}
      classes={{ shrink: props.classes.shrink }}
    >
      {props.children}
    </InputLabel>
  );
}

export default withStyles(styles)(FileInputLabel);
