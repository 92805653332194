import merge from "lodash/merge";

import {
  SET_VIEWABLE_AS_TEAMMATE_PAGE,
} from "../../actions/shared/uiActions";

const _defaultState = {
  isViewableAsTeammatePage: false,
};

export const viewingAsBannerReducer = (state = _defaultState, action) => {
  switch (action.type) {
    case SET_VIEWABLE_AS_TEAMMATE_PAGE:
      return merge({}, state, { isViewableAsTeammatePage: action.payload });
    default:
      return state;
  }
};
