import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";

const styles = () => ({
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 65,
    borderBottom: "1px solid #B0BEC5",
    padding: "0px 24px 0px 12px",
    "& div": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "100%"
    }
  },
  titleText: {
    marginBottom: 3
  }
});

function NotificationsIndexHeader({ classes, title, subtitle }) {
  return (
    <div className={classes.headerContainer}>
      <div className={classnames("subtitle-1", "semibold", classes.titleText)}>
        {title}
      </div>
      <div className={classnames("subtitle-2", "regular")}>{subtitle}</div>
    </div>
  );
}

export default withStyles(styles)(NotificationsIndexHeader);
