import { makeStyles } from "@material-ui/core/styles";
import CalendarTodayIcon from "mdi-material-ui/CalendarToday";
import React from "react";
import { useSelector } from "react-redux";

import { getCurrentIndividual } from "../../selectors/individualSelectors";
import { getCurrentOrganization } from "../../selectors/organizationSelectors";
import AlertsBar from "../shared/Jobcasts/AlertsBar";

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    minWidth: 0,
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    height: 48,
    boxShadow: "none",
    borderBottom: "1px solid #90A4AE",
  },
  bodyContainer: {
    display: "flex",
    minWidth: 0,
  },
  iconContainer: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FF6D6D",
    borderRight: "1px solid #90A4AE",
  },
  icon: {
    color: "#FFF",
    fontSize: 24,
  },
  button: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    fontSize: 14,
    marginLeft: 12,
    fontWeight: 600,
    letterSpacing: 1,
    "&:hover": {
      color: "#008dae",
    },
  },
}));




function RelinkIntegrationBanner() {
  const classes = useStyles();
  const currentIndividual = useSelector((state) => getCurrentIndividual(state));
  const currentOrganization = useSelector((state) => getCurrentOrganization(state));

  const managerMessageRelinkNeeded = "Your ATS Integration token has expired. Please reconnect your ATS";
  const nonManagerMessageRelinkNeeded = "Your ATS Integration token has expired. Please contact your team manager.";
  const managerMessageMissingPermission = "Your ATS Integration API key is missing permissions, please check your ATS API key settings to make sure the correct permissions are enabled.";
  const nonManagerMessageMissingPermission = "Your ATS Integration API key is missing permissions. Please contact your team manager.";

  const managerMessage = currentOrganization?.mergeSyncStatus === "Relink Needed" ? managerMessageRelinkNeeded : managerMessageMissingPermission;
  const nonManagerMessage = currentOrganization?.mergeSyncStatus === "Relink Needed" ? nonManagerMessageRelinkNeeded : nonManagerMessageMissingPermission;

  const displayBanner =
    currentIndividual &&
    currentIndividual.employer &&
    currentOrganization?.mergeSyncStatus === "Relink Needed" || currentOrganization?.mergeSyncStatus === "Missing Permission"

  if (!displayBanner) return null;

  const onClick = () => window.location.href = "/app/settings/integrations";

  const message = currentIndividual?.teamManager ? managerMessage : nonManagerMessage;

  return (
    <AlertsBar
      IconComponent={CalendarTodayIcon}
      theme="red"
      body={(
        <div className={classes.bodyContainer}>
          <span className="small">{message}</span>
          {currentIndividual?.teamManager && currentOrganization?.mergeSyncStatus === "Relink Needed" && (
            <a
              className={classes.button}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClick}
            >
              CLICK HERE
            </a>
            )
          }
        </div>
      )}
      displayTooltip={false}
    />
  );
}

export default RelinkIntegrationBanner;
