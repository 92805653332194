import normalize from "json-api-normalizer";

import map from "lodash/map";
import queryString from "query-string";

import {
  PERM,
  TEMP,
  UNARCHIVED,
} from "../../selectors/recruiter/jobcastSelectors";
import { GET, PATCH, POST } from "../../util/apiHelpers";

import {
  mapKeysToCamelCase,
  mapKeysToSnakeCase,
} from "../../util/formatHelpers";
import { receiveJobCast, mergeJobCasts } from "../shared/jobcastActions";
import {
  receiveRequests,
  setRequestsIndexLoaded,
} from "../shared/requestActions";

export const fetchJobcastRequests =
  (params = { status: UNARCHIVED }) =>
  (dispatch) =>
    GET(`/api/v3/recruiter/requests?${queryString.stringify(params)}`).then(
      (res) => {
        const normalized = normalize(res.data);

        dispatch(mergeJobCasts(normalized.jobcast));
        dispatch(receiveRequests(normalized.request));
        if (params.status && !params.limit) {
          dispatch(
            setRequestsIndexLoaded(params.temp ? TEMP : PERM, params.status)
          );
        }

        return map(normalized.request, (request) => ({
          request,
          jobcast: normalized.jobcast[request.attributes.jobcastId],
        }));
      }
    );

export const fetchTempJobcastRequests = (params = { status: UNARCHIVED }) =>
  fetchJobcastRequests(Object.assign(params, { temp: true }));

export const fetchJobcastRequest = (requestId) => (dispatch) =>
  GET(`/api/v3/recruiter/requests/${requestId}`).then((res) => {
    const normalized = normalize(res.data);

    dispatch(mergeJobCasts(normalized.jobcast));
    dispatch(receiveRequests(normalized.request));
  });

export const fetchJobcasts =
  (params = {}) =>
  (dispatch) =>
    GET(
      `/api/v3/recruiter/jobcasts?${queryString.stringify(
        mapKeysToSnakeCase(params)
      )}`
    ).then((res) => {
      const normalized = normalize(res.data);
      const jobcasts = normalized.jobcast;

      dispatch(mergeJobCasts(jobcasts));

      return { items: jobcasts, metaData: mapKeysToCamelCase(res.data.meta) };
    });

export const fetchTempJobcasts = (params = {}) =>
  fetchJobcasts(Object.assign(params, { temp: true }));

export const fetchJobCast = (id) => (dispatch) =>
  GET(`/api/v3/recruiter/jobcasts/${id}`).then((res) => {
    const normalized = normalize(res.data);
    const jobcasts = normalized.jobcast;

    dispatch(mergeJobCasts(jobcasts));

    return { items: jobcasts, metaData: mapKeysToCamelCase(res.data.meta) };
  });

export const fetchJobcastRequestByJobCastId = (jobcastId) => (dispatch) =>
  GET(`/api/v3/recruiter/jobcasts/${jobcastId}/jobcast_request`).then((res) => {
    const normalized = normalize(res.data);

    dispatch(mergeJobCasts(normalized.jobcast));
    dispatch(receiveRequests(normalized.request));
  });

export const createJobcastRequests = () => (dispatch) => {
  dispatch(setRequestsIndexLoaded(PERM, UNARCHIVED, false)); // MEDONOW: handle when pulling temp positions
  return POST(`/api/v3/recruiter/requests/create_pull_requests`).then((res) => {
    const normalized = normalize(res.data);

    dispatch(mergeJobCasts(normalized.jobcast));
    dispatch(receiveRequests(normalized.request));
    dispatch(setRequestsIndexLoaded(PERM, UNARCHIVED));

    return normalized.request;
  });
};

export const fetchRequestByJobcast = (id) => (dispatch) => {
  GET(`/api/v3/recruiter/requests/find_by_params?position_id=${id}`).then(
    (res) => {
      const normalized = normalize(res.data);

      dispatch(receiveRequests(normalized.request));

      return normalized.request;
    }
  );
};

export const createRequest = (params) => (dispatch) =>
  POST(`/api/v3/recruiter/requests`, params).then((res) => {
    const normalized = normalize(res.data);
    dispatch(receiveRequests(normalized.request));

    return normalized.request[Object.keys(normalized.request)[0]];
  });

export const acceptRequest = (id) => (dispatch) =>
  PATCH(`/api/v3/recruiter/requests/${id}/accept`).then((res) => {
    const normalized = normalize(res.data);
    dispatch(receiveRequests(normalized.request));
  });

export const removeRequest = (id) => (dispatch) =>
  PATCH(`/api/v3/recruiter/requests/${id}/remove`).then((res) => {
    const normalized = normalize(res.data);
    dispatch(receiveRequests(normalized.request));
  });

export const fetchProjectedJobcastFeeData = (jobcastId) =>
  GET(`/api/v3/recruiter/jobcasts/${jobcastId}/projected_fees`);

export const fetchJobcastEmployerRatings = (jobcastId) =>
  GET(`/api/v3/recruiter/jobcasts/${jobcastId}/employer_ratings`);

export const fetchRequestTypeCounts = (params = {}) =>
  GET(
    `/api/v3/recruiter/requests/type_counts?${queryString.stringify(params)}`
  );
