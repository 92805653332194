import { makeStyles, Tooltip } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import { InfoOutlined } from "@material-ui/icons";

import size from "lodash/size";
import PropTypes from "prop-types";
import React from "react";
import { Row, Col } from "react-grid-system";
import { useSelector, useDispatch } from "react-redux";

import { remindInvoiceMemo } from "../../../../../../../../actions/employers/jobcastActions";
import { formatCurrency } from "../../../../../../../../util/formatHelpers";
import Button from "../../../../../../../forms/custom/Button";

import withSnackbar from "../../../../../../../General/withSnackbar";

import CostCenterDropdown from "../../../../../../Billing/CostCenterDropdown";

import FeeOptionSelection from "./FeeOptionSelection";

import FieldSection from "./FieldSection";
import SectionHeader from "./SectionHeader";

const AVAYA_ORG_ID = "ebee6a4c-5760-41ce-8b0c-f8ea0a705a98";

const useStyles = makeStyles(() => ({
  tooltipIcon: {
    color: "#546e7a",
    margin: "0 0 -3px 5px",
    fontSize: 18,
  },
}));

function PriorityBonus({
  candidate: {
    attributes: { priorityBonus },
  },
}) {
  const classes = useStyles();
  if (!priorityBonus) return null;
  const { amount, currencyCode } = priorityBonus;

  return (
    <Col xs={6}>
      <TextField
        label={(
          <>
            Priority Placement Bonus
            <Tooltip
              title="This candidate was submitted while the priority placement bonus was active for this JobCast. The amount shown in this field will be added to your invoice."
              placement="bottom-start"
            >
              <InfoOutlined className={classes.tooltipIcon} />
            </Tooltip>
          </>
        )}
        value={`${formatCurrency(amount, currencyCode, {
          noConversion: true,
        })} ${currencyCode}`}
        inputProps={{ maxLength: 140 }}
        InputProps={{ disabled: true, disableUnderline: true }}
        // Allows tooltip hover to work
        InputLabelProps={{ style: { pointerEvents: "auto" } }}
      />
    </Col>
  );
}

function BillingInfo({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  candidate,
  snackbar,
  jobcast,
}) {
  const dispatch = useDispatch();
  const costCenters = useSelector((state) => state.costCenters.items);
  const currentOrganization = useSelector(
    (state) => state.organizations.items[state.organizations.currentOrganizationId]
  );

  const orgInvoiceMemoRequired =
    currentOrganization.attributes.invoiceMemoRequired;

  const createInvoiceMemoLabel = () => {
    if (orgInvoiceMemoRequired) return "Invoice Memo / PO Number";

    const costCenter = costCenters[values.cost_center_id];
    if (costCenter && costCenter.attributes.invoiceMemoRequired) {
      return "Invoice Memo / PO Number";
    }
    return "Invoice Memo / PO Number (Optional)";
  };

  const dispatchRemindInvoiceMemo = () => dispatch(remindInvoiceMemo(jobcast.id));

  const setInvoiceMemoRequired = (id) => setFieldValue(
    "invoice_memo_required",
    costCenters[id].attributes.invoiceMemoRequired
  );

  const handleCollectionLinkCopy = () => {
    const token = btoa(
      JSON.stringify({
        position_id: jobcast.id,
        email: values.deferred_invoice_memo_email,
      })
    );

    if (navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(
          `${window.location.origin}/app/public/provide-billing-info/${token}`
        )
        .then(snackbar.showMessage("Collection link copied"));
    }
  };

  const renderCostCenterDropdown = () => {
    const isVisible = size(costCenters) > 1;

    return isVisible ? (
      <Col xs={6}>
        <CostCenterDropdown
          id="cost_center_id"
          helperText={touched.cost_center_id && errors.cost_center_id}
          error={Boolean(errors.cost_center_id && touched.cost_center_id)}
          onChange={(e) => {
            if (!orgInvoiceMemoRequired) {
              setInvoiceMemoRequired(e.currentTarget.value);
            }
            handleChange(e);
          }}
          value={values.cost_center_id}
        />
      </Col>
    ) : (
      null
    );
  };

  const renderInvoiceMemo = () => (
    <Col xs={6}>
      <TextField
        label={createInvoiceMemoLabel()}
        value={values.invoice_memo}
        inputProps={{ maxLength: 140 }}
        helperText={touched.invoice_memo && errors.invoice_memo}
        error={Boolean(errors.invoice_memo && touched.invoice_memo)}
        onChange={handleChange}
        multiline
        id="invoice_memo"
        style={{ width: 351 }}
      />
    </Col>
  );

  const renderDeferInvoiceMemoCheckbox = () => (
    <Col xs={6} style={{ display: "flex", alignItems: "center" }}>
      <div>
        <Checkbox
          error={Boolean(
            errors.deferred_invoice_memo && touched.deferred_invoice_memo
          )}
          checked={values.deferred_invoice_memo}
          onChange={() => setFieldValue(
            "deferred_invoice_memo",
            !values.deferred_invoice_memo
          )}
          value={values.deferred_invoice_memo}
          color="primary"
        />
        Colleague will provide invoice memo
      </div>
    </Col>
  );

  const renderEmailColleagueSection = () => (values.deferred_invoice_memo ? (
    <Col xs={12}>
      <TextField
        label="Email of Colleague"
        value={values.deferred_invoice_memo_email}
        inputProps={{ maxLength: 140 }}
        helperText={
            touched.deferred_invoice_memo_email &&
            errors.deferred_invoice_memo_email
          }
        error={Boolean(
          errors.deferred_invoice_memo_email &&
              touched.deferred_invoice_memo_email
        )}
        onChange={handleChange}
        multiline
        id="deferred_invoice_memo_email"
        data-cy="deferred_invoice_memo_email-text-field"
        style={{ width: 351 }}
      />
      <>
        <Button
          style={{ border: "none", padding: 0, marginRight: 16 }}
          onClick={() => {
            dispatchRemindInvoiceMemo().then(
              snackbar.showMessage("Reminder email sent")
            );
          }}
          data-cy="remind-invoice-memo-button"
        >
          Remind
        </Button>
        <Button
          style={{ border: "none", padding: 0 }}
          onClick={handleCollectionLinkCopy}
          data-cy="copy-collection-link-button"
        >
          Copy Collection Link
        </Button>
      </>
    </Col>
  ) : (
    null
  ));

  return (
    <>
      <SectionHeader title="Billing Information" />
      <FieldSection>
        <Row>
          {renderCostCenterDropdown()}
          <FeeOptionSelection
            {...{
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              candidate,
            }}
          />
          {renderInvoiceMemo()}
          {currentOrganization.id === AVAYA_ORG_ID &&
            renderDeferInvoiceMemoCheckbox()}
          {currentOrganization.id === AVAYA_ORG_ID &&
            renderEmailColleagueSection()}
          <PriorityBonus {...{ candidate }} />
        </Row>
      </FieldSection>
    </>
  );
}

BillingInfo.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  candidate: PropTypes.object.isRequired,
  snackbar: PropTypes.object.isRequired,
  jobcast: PropTypes.object.isRequired,
};

export default withSnackbar()(BillingInfo);
