import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import classnames from "classnames";
import React from "react";

import GlobalAvatar from "./GlobalAvatar";

const styles = () => ({
  container: {
    display: "inline-flex",
    height: 50,
    maxWidth: 200,
    alignItems: "center",
    borderRadius: 11,
    background: "#FFFFFF",
  },
  nameLink: {
    cursor: "pointer",
    color: "#008DAE",
    textDecoration: "underline",
  },
  name: {
    maxWidth: 120,
  },
});

function CandidateChip(props) {
  const { classes } = props;
  const renderName = () => (
    <div
      className={classnames(
        "subtitle-2. truncate",
        classes.name,
        props.onNameClick ? classes.nameLink : ""
      )}
      onClick={props.onNameClick}
    >
      {props.name}
    </div>
  );

  return (
    <div className={classes.container} style={props.overrideStyle || {}}>
      <GlobalAvatar
        size="small"
        overrideStyle={{ margin: "0 8px 0 6px" }}
        individual={props.individual}
      />
      <div
        style={{ display: "flex", flexDirection: "column", marginRight: 12 }}
      >
        {props.name.length > 20 ? (
          <Tooltip title={props.name}>{renderName()}</Tooltip>
        ) : (
          renderName()
        )}
        {props.info.length > 20 ? (
          <Tooltip title={props.info}>
            <div
              className="caption truncate"
              style={{ marginBottom: "-6px", maxWidth: 120 }}
            >
              {props.type === "candidate"
                ? `Stage: ${props.info[0].toUpperCase() + props.info.slice(1)}`
                : props.info}
            </div>
          </Tooltip>
        ) : (
          <div className="caption" style={{ marginBottom: "-6px" }}>
            {props.type === "candidate"
              ? `Stage: ${props.info[0].toUpperCase() + props.info.slice(1)}`
              : props.info}
          </div>
        )}
      </div>
    </div>
  );
}

export default withStyles(styles)(CandidateChip);
