import { useField } from "formik";
import React from "react";
import { useSelector } from "react-redux";

import { makeGetTeammates } from "../../../../selectors/recruiter/teammateSelectors";
import { buildFullName } from "../../../../util/formatHelpers";
import DropdownSelect from "../../../forms/custom/DropdownSelect";
import { TIMESHEET_SUBMITTERS } from "../../Team/constants";

export default function TimesheetSubmittersField() {
  const teammates = useSelector(makeGetTeammates(TIMESHEET_SUBMITTERS));

  const [{ value, onBlur }, { error }, { setValue }] = useField(`timesheetSubmitters`);

  const options = teammates.map(({ id, attributes }) => (
    {
      value: id,
      label: buildFullName(attributes.firstName, attributes.lastName)
    }
  ));

  return (
    <DropdownSelect
      id="timesheetSubmitters"
      label="Timesheet Submitters"
      isMulti
      isClearable
      options={options}
      value={value}
      onChange={setValue}
      onBlur={onBlur}
      error={Boolean(error)}
      helperText={error}
    />
  );
}
