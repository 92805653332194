import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useField } from "formik";
import React, { useCallback } from "react";

import Button from "../../../../../forms/custom/Button";

import { otRoles } from './utils';

export default function QuickFill({
  editRowsModel, setEditRowsModel, disabled
}) {
  const [{ value: rows },, { setValue: setRows, setTouched }] = useField("timesheetHours");

  const handleSetZeroHours = useCallback(() => {
    const model = { ...editRowsModel };
    const type = Object.keys(model)[0];
    /* This is necceary to reset the editing state inside cells */
    if (type === "Standard") {
      Object.entries(model[type]).forEach(([day]) => {
        model[type][day].error = false;
        model[type][day].value = "0";
      });
      setEditRowsModel(model);
    } else if (type) {
      Object.entries(model[type]).forEach(([day]) => {
        model[type][day].error = false;
        model[type][day].value = "";
      });
      setEditRowsModel(model);
    }

    const standard = {
      id: "Standard", total: 0, monday: "0", tuesday: "0", wednesday: "0", thursday: "0", friday: "0", saturday: "0", sunday: "0"
    };
    setTouched(true, false);
    setRows(rows.length === 1 ? [standard] : [standard, ...otRoles]);
  }, [editRowsModel, rows.length, setEditRowsModel, setRows, setTouched]);

  const handleGenerateHours = useCallback(() => {
    const model = { ...editRowsModel };
    const type = Object.keys(model)[0];
    /* This is necceary to reset the editing state inside cells */
    if (type === "Standard") {
      for (const day of Object.keys(model[type])) {
        model[type][day].error = false;
        if (day === "saturday" || day === "sunday") {
          model[type][day].value = "0";
        } else {
          model[type][day].value = "8";
        }
      }
      setEditRowsModel(model);
    } else if (type) {
      Object.entries(model[type]).forEach(([day]) => {
        model[type][day].error = false;
        model[type][day].value = "";
      });
      setEditRowsModel(model);
    }
    const standard = {
      id: "Standard", total: 40, monday: "8", tuesday: "8", wednesday: "8", thursday: "8", friday: "8", saturday: "0", sunday: "0"
    };
    setTouched(true, false);
    setRows(rows.length === 1 ? [standard] : [standard, ...otRoles]);
  }, [editRowsModel, rows.length, setEditRowsModel, setRows, setTouched]);

  return (
    <Grid container justifyContent="center">
      <Grid item xs={4}>
        <Button
          variant="secondary"
          color="blue"
          onClick={handleGenerateHours}
          data-cy="add-standard-hours-button"
          disabled={disabled}
        >
          Standard 40-Hour Week
        </Button>
      </Grid>
      <Grid item xs={4}>
        <Box
          display="flex"
          justifyContent="center"
        >
          <Button
            variant="secondary"
            color="blue"
            onClick={handleSetZeroHours}
            data-cy="set-zero-hours-button"
            disabled={disabled}
          >
            Zero Hours Worked
          </Button>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box
          display="flex"
          justifyContent="right"
        />
      </Grid>
    </Grid>
  );
}
