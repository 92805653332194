export const NEW = "new";
export const EMPLOYER_CHAT = "employer_chat";
export const AGENCY_CHAT = "agency_chat";
export const HISTORY = "history";
export const RESUME = "resume";
export const RESPONSES = "responses";
export const VIEW_RESUME = "view_resume";
export const VIEW_RESPONSES = "view_responses";
export const EVENT_HISTORY = "event_history";
export const ARCHIVED = "archived";
export const UNCONFIRMED = "unconfirmed";
export const REJECTED = "rejected";
export const AWAITING_SORT = "awaiting_sort";
export const HIRED = "hired";
export const OFFER = "offer";
export const CONTRACT_ACTIVE = 'contract_active';
export const CONTRACT_ENDED = 'contract_ended';
