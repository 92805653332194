import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import capitalize from "lodash/capitalize";

import CommentQuestion from "mdi-material-ui/CommentQuestion";
import CommentQuote from "mdi-material-ui/CommentQuote";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchPositionCorrespondence } from "../../../../actions/recruiter/jobcastUpdatesActions";
import { getPositionCorrespondence } from "../../../../selectors/jobcastUpdatesSelectors";
import LoadingPage from "../../../General/LoadingPage";
import Content from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Content";
import DisabledTextField from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/DisabledTextField";
import Subheader from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";

import withNotificationMarkedRead from "./withNotificationMarkedRead";

const MATCH_QUESTION = "match_question";
const FEEDBACK = "feedback";

const PRIVATE = "PRIVATE";
const PUBLIC = "PUBLIC";

const REPLY_ICONS = {
  [PUBLIC]: { icon: "reply_all" },
  [PRIVATE]: { icon: "reply" },
};

const styles = () => ({
  iconContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: 24,
  },
  noMargin: {
    margin: 0,
  },
  replyContainer: {
    backgroundColor: "rgba(176, 190, 197, 0.08)",
    paddingRight: 24,
    paddingTop: 5,
    marginBottom: 5,
    borderLeft: "4px solid #90A4AE",
    display: "flex",
    flexDirection: "row",
    minHeight: 60,
  },
  replyIcon: {
    color: "#90A4AE",
    marginLeft: 20,
    marginRight: 20,
    fontSize: 24,
  },
  replyCaption: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 30,
    textAlign: "center",
  },
});

function QuestionAnswer({
  notification: {
    attributes: {
      notificationSpecifics: { positionCorrespondenceId },
      jobcastId,
    },
  },
  jobcast: {
    attributes: {
      individualAvatar,
      individualFirstName,
      individualLastInitial,
      organizationName,
    },
  },
  classes,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPositionCorrespondence(jobcastId, positionCorrespondenceId));
  }, [dispatch, jobcastId, positionCorrespondenceId]);

  const positionCorrespondence = useSelector((state) => getPositionCorrespondence(state, positionCorrespondenceId));

  return positionCorrespondence ? (
    <div>
      <Subheader
        leftChip={{
          chipHeader: "Update from",
          individualFirstName,
          individualLastInitial,
          individualAvatar,
          chipSubText: organizationName,
        }}
      />
      <Content
        iconImage={
          positionCorrespondence.attributes.subjectType === MATCH_QUESTION
            ? CommentQuestion
            : CommentQuote
        }
        iconCaption={
          positionCorrespondence.attributes.subjectType === MATCH_QUESTION
            ? "Q&A"
            : "Feedback"
        }
        label={
          positionCorrespondence.attributes.subjectType === MATCH_QUESTION
            ? "Question"
            : "JobCast Feedback"
        }
        value={positionCorrespondence.attributes.question}
        helperText={moment(positionCorrespondence.attributes.createdAt).format(
          "MMMM Do, YYYY"
        )}
      >
        <div className={classes.replyContainer}>
          <div className={classes.iconContainer}>
            <Icon classes={{ root: classes.replyIcon }}>
              {REPLY_ICONS[positionCorrespondence.attributes.visibility].icon}
            </Icon>
            <div
              className={classnames("gray", "caption", classes.replyCaption)}
              style={{ position: "absolute", top: "26px", left: "19px" }}
            >
              <div>
                {capitalize(positionCorrespondence.attributes.visibility)}
              </div>
              <div>Reply</div>
            </div>
          </div>

          <DisabledTextField
            label="Answer"
            helperText={moment(
              positionCorrespondence.attributes.answeredAt
            ).format("MMMM Do, YYYY")}
            value={positionCorrespondence.attributes.answer}
          />
        </div>
      </Content>
    </div>
  ) : (
    <LoadingPage />
  );
}

export default withStyles(styles)(withNotificationMarkedRead(QuestionAnswer));
