import { createSelector } from "reselect";

export const getAllRequests = state => state.requests.items;
export const getIndexLoadStatus = state => state.requests.indexTypesLoaded;

export const getRequestById = (state, { requestId }) => state.requests.items[requestId];

export const makeGetRequestById = () => createSelector([getRequestById], request => request);

export const makeGetIndexLoadStatus = () => createSelector([getIndexLoadStatus], indexLoadStatus => indexLoadStatus);
