import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import forEach from "lodash/forEach";
import React, {
  useContext, useRef, useMemo, createContext
} from 'react';

import { ErrorPage } from "./components/General/404Error";

const BugsnagContext = createContext({
  updateBugsnagMetaData: () => {}
});

// eslint-disable-next-line react/display-name
export const withBugsnag = Component => function (props) {
  const { updateBugsnagMetaData } = useContext(BugsnagContext);
  return <Component updateBugsnagMetaData={updateBugsnagMetaData} {...props} />;
};

function BugsnagWrapper({ children }) {
  const metaData = useRef({});

  const value = useMemo(() => {
    const updateBugsnagMetaData = (data) => {
      metaData.current = { ...metaData.current, ...data };
    };
    return { updateBugsnagMetaData };
  }, []);

  // To get Cypress experimental studio to work - Comment from here
  if (process.env.NODE_ENV === 'production' && process.env.BUGSNAG_API_KEY_CORE) {
    const bugsnagClient = bugsnag({
      apiKey: process.env.BUGSNAG_API_KEY_CORE,
      appVersion: process.env.HEROKU_RELEASE_VERSION,
      releaseStage: process.env.HEROKU_ENV,
      beforeSend(report) {
        forEach(metaData.current, (val, key) => {
          report.updateMetaData(key, val);
        });
      },
    });

    window.bugsnagClient = bugsnagClient;

    bugsnagClient.use(bugsnagReact, React);
    const ErrorBoundary = bugsnagClient.getPlugin("react");

    return (
      <BugsnagContext.Provider value={value}>
        <ErrorBoundary
          FallbackComponent={ErrorPage}
        >
          {children}
        </ErrorBoundary>
      </BugsnagContext.Provider>
    );
  }
  //  To here

  window.bugsnagClient = {
    notify: (error) => {
      console.error("bugsnag dev env", error);
    }
  };

  return (
    <BugsnagContext.Provider value={value}>
      {children}
    </BugsnagContext.Provider>
  );
}

export default BugsnagWrapper;
