import React from "react";
import { connect } from "react-redux";

import { getCurrentIndividual } from "../../../selectors/individualSelectors";

import EmployerButtonContainer from "../../employer/JobCasts/view/Candidates/view/ButtonContainer";
import RecruiterButtonContainer from "../../recruiter/Candidates/view/ButtonContainer";

function HeaderButtonContainer({ isEmployer, candidate, archived = false }) {
  if (isEmployer) {
    return <EmployerButtonContainer candidate={candidate} archived={archived} />;
  }
  return <RecruiterButtonContainer candidate={candidate} />;
}

const mapStateToProps = state => ({
  isEmployer: getCurrentIndividual(state).employer
});

export default connect(mapStateToProps, null)(HeaderButtonContainer);
