import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

import {
  RECEIVE_ORGANIZATION_PROFILES,
  RECEIVE_ORGANIZATION_PROFILE,
  UPDATE_ORGANIZATION_PROFILE_FILTER,
} from "../actions/employers/organizationProfileActions";

const _defaultState = {
  items: {},
  loaded: false,
  filterId: null,
};

const organizationProfileReducer = (state = _defaultState, action) => {
  Object.freeze(state);
  let newItems;

  switch (action.type) {
    case RECEIVE_ORGANIZATION_PROFILES:
      return merge({}, state, { items: action.payload, loaded: true });
    case RECEIVE_ORGANIZATION_PROFILE:
      newItems = cloneDeep(state.items);
      newItems[action.payload.id] = merge({}, action.payload, { loaded: true });
      return { ...state, items: newItems };
    case UPDATE_ORGANIZATION_PROFILE_FILTER:
      return merge({}, state, { filterId: action.payload });
    default:
      return state;
  }
};

export default organizationProfileReducer;
