import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import unset from "lodash/unset";

import {
  RECEIVE_PREFERRED_LINKS,
  RECEIVE_PREFERRED_LINK,
  REMOVE_PREFERRED_LINK,
} from "../actions/preferredLinkActions";
import { mergeWithArrayOverwrite } from "../util/formatHelpers";

const _defaultState = { items: {} };

const preferredLinksReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PREFERRED_LINKS:
      return merge({}, state, { items: action.payload, loaded: true });
    case RECEIVE_PREFERRED_LINK:
      const newItem = action.payload;
      return mergeWithArrayOverwrite({}, state, {
        items: { [newItem.id]: newItem },
      });
    case REMOVE_PREFERRED_LINK:
      const toRemoveId = action.payload;
      const newState = cloneDeep(state);

      unset(newState, ["items", toRemoveId]);

      return newState;
    default:
      return state;
  }
};

export default preferredLinksReducer;
