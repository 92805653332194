import {
  SET_MODAL_VISIBLE,
  OPEN_MODAL,
  CLEAR_MODAL,
} from "../../actions/shared/uiActions";

const _defaultState = {
  visible: false,
  content: {},
};

export const modalReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case SET_MODAL_VISIBLE:
      return { ...state, visible: action.payload };
    case OPEN_MODAL:
      return {
        ...state,
        content: action.payload,
        visible: true,
      };
    case CLEAR_MODAL:
      return _defaultState;
    default:
      return state;
  }
};
