import TextField from "@material-ui/core/TextField";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import PropTypes from "prop-types";
import React, { memo } from "react";

function MultiLineTextField(props) {
  return (
    <TextField
      {...props}
      helperText={props.helperText}
      InputProps={merge(
        {
          error: props.error,
          className: `multiline-box${
            props.disabled ? " disabled" : props.error ? " error" : ""
          }`,
          disabled: props.disabled,
          disableUnderline: true,
          style: props.disabled ? { color: "#37474F" } : {},
          onBlur: props.onBlur,
          classes: { input: `${props.disabled ? "disabled" : ""}` },
          inputProps: {
            "data-cy": `${
              props.label ? `${props.label}-` : ""
            }multiline-text-field`,
          },
        },
        props.InputProps || {}
      )}
      InputLabelProps={
      props.label
        ? { className: "multiline disabled-input-label", shrink: true }
        : {}
    }
      multiline
      rows={props.rows ? props.rows : 5}
    />
  );
}

export function HelperTextWithCharCount({ errorMessage, value, maxCount }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div>{errorMessage}</div>
      <div>
        {typeof value === "string" ? value.length : 0}
        {' '}
        /
        {maxCount}
      </div>
    </div>
  );
}

MultiLineTextField.defaultProps = {
  label: "",
};

MultiLineTextField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  FormHelperTextProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  id: PropTypes.string,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.func,
  label: PropTypes.node,
  margin: PropTypes.oneOf(["none", "dense", "normal"]),
  multiline: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowsMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  select: PropTypes.bool,
  SelectProps: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
  ]),
  "data-layout": PropTypes.string,
};

export default memo(MultiLineTextField, (prevProps, nextProps) => isEqual(prevProps, nextProps));
