import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";

export const style = theme => ({
  navContainer: {
    height: "80px",
    display: "flex",
    flexDirection: "row",
    minWidth: "179px",
    alignItems: "center",
    [theme.breakpoints.down("1024")]: {
      paddingLeft: "20px"
    }
  },
  smallActionContainer: {
    width: "calc(100% - 336px - 260px)"
  },
  wideActionContainer: {
    width: "calc(100% - 336px - 320px)"
  }
});
class NavigationContainer extends React.PureComponent {
  render() {
    const {
      candidate,
      classes,
      overrideStyle,
      wideActionContainer,
      drawerComponent
    } = this.props;
    const children = React.Children.map(this.props.children, child => React.cloneElement(child, {
      candidate,
      drawerComponent
    }));

    return (
      <div
        className={classnames(
          classes.navContainer,
          wideActionContainer
            ? classes.wideActionContainer
            : classes.smallActionContainer
        )}
        style={overrideStyle || {}}
      >
        {children}
      </div>
    );
  }
}

export default withStyles(style)(NavigationContainer);
