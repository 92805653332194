import cloneDeep from "lodash/cloneDeep";
import keys from "lodash/keys";
import merge from "lodash/merge";

import {
  RECEIVE_ORG_JOB_CATEGORIES,
  RECEIVE_ORG_JOB_CATEGORY,
} from "../actions/employers/orgJobCategoryActions";

const _defaultState = {
  items: {},
  loaded: false,
};

const orgJobCategoryReducer = (state = _defaultState, action) => {
  Object.freeze(state);
  let newItems;

  switch (action.type) {
    case RECEIVE_ORG_JOB_CATEGORY:
      newItems = cloneDeep(state.items);
      newItems[action.payload.id] = merge({}, action.payload, { loaded: true });
      return { ...state, items: newItems };
    case RECEIVE_ORG_JOB_CATEGORIES:
      return { items: action.payload, loaded: true };
    default:
      return state;
  }
};

export default orgJobCategoryReducer;
