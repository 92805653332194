import normalize from "json-api-normalizer";

import { GET } from "../../util/apiHelpers";

export const RECEIVE_ORGANIZATION_PROFILES = "RECEIVE_ORGANIZATION_PROFILES";
export const RECEIVE_ORGANIZATION_PROFILE = "RECEIVE_ORGANIZATION_PROFILE";

export const receiveOrganizationProfiles = (organizationProfiles) => ({
  type: RECEIVE_ORGANIZATION_PROFILES,
  payload: organizationProfiles,
});

export const receiveOrganizationProfile = (organizationProfile) => ({
  type: RECEIVE_ORGANIZATION_PROFILE,
  payload: organizationProfile,
});

export const fetchOrganizationProfiles = () => (dispatch) => GET("/api/v3/recruiter/organization_profiles").then((res) => {
  const normalized = normalize(res.data);

  return dispatch(
    receiveOrganizationProfiles(normalized.organizationProfile)
  );
});

export const fetchOrganizationProfile = (id) => (dispatch) => GET(`/api/v3/recruiter/organization_profiles/${id}`).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(
    receiveOrganizationProfile(
      normalized.organizationProfile[res.data.data.id]
    )
  );
});
