import ProfileIcon from "mdi-material-ui/Account";
import moment from "moment";
import React from "react";

import withNotificationMarkedRead from "../../../recruiter/NotificationsDrawer/NotificationViewTab/withNotificationMarkedRead";
import Content from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Content";
import Subheader from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";

function InvitationAccepted({
  notification: {
    attributes: { messageBody, createdAt, metadata },
  },

}) {
  return (
    <div>
      <Subheader
        headerText="Invitation Accepted"
      />
      <Content
        iconImage={ProfileIcon}
        iconCaption="Invitation Accepted"
        label="Invitation Accepted"
        value={`${metadata.firstName} ${metadata.lastName} from ${messageBody}`}
        helperText={moment(createdAt).format("MMMM Do, YYYY")}
      />
    </div>
  );
}

export default withNotificationMarkedRead(InvitationAccepted);
