import {
  SET_HEADER_MENU_VISIBLE,
  SET_HEADER_MENU_SCOPE,
} from "../../actions/shared/uiActions";
import { determineScopeFromPath } from "../../components/Header/HeaderPrimary/NavigationTabs/constants";

const _defaultState = {
  visible: false,
  scope: determineScopeFromPath(),
};

export const headerMenuReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case SET_HEADER_MENU_VISIBLE:
      return { ...state, visible: action.payload };
    case SET_HEADER_MENU_SCOPE:
      return { ...state, scope: action.payload };
    default:
      return state;
  }
};
