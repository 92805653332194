// stage types
export const NEW = "new";
export const REVIEWING = "reviewing";
export const INTERVIEWING = "interviewing";
export const OFFERS = "offers";
export const REJECTED = "rejected";
export const HIRES = "hires";

// nav selection types
export const AGENCY_CHAT = "agency_chat";
export const VIEW_RESUME = "view_resume";
export const VIEW_RESPONSES = "view_responses";
export const EVENT_HISTORY = "event_history";
export const HIRE_DETAILS = "hire_details";
export const CONTRACT_WORK = "contract_work";

// action types
export const ADVANCE = "advance";
export const REJECT = "reject";
export const GUARANTEE_FAILURE = "guarantee_failure";

// tab types
export const RESUME = "resume";
export const RESPONSES = "responses";
export const HISTORY = "history";

// stage events
export const AWAITING_SORT = "AWAITING-SORT";
export const SCREENING = "SCREENING";
export const EARLY_INTERVIEW = "EARLY-INTERVIEW";
export const LATE_INTERVIEW = "LATE-INTERVIEW";
export const CONFIRMED_NOT_WATCHLISTED = "CONFIRMED-NOT-WATCHLISTED";

// status types
export const WITHDRAWN = "withdrawn";
export const HIRED = "hired";
export const OFFER = "offer";
