import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import propTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import {
  clearProfileDrawer,
  setProfileDrawerVisible
} from "../../actions/shared/uiActions";

import ProfileDrawerHeader from "./ProfileDrawerHeader";

const style = theme => ({
  paper: {
    width: "900px",
    height: "100%",
    [theme.breakpoints.down("900")]: {
      width: "100%"
    }
  },
  profileDrawerButton: {
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "1px",
    padding: "6px 16px",
    height: "36px",
    boxShadow: "none",
    margin: "9px 0px 6px 0px"
  },
  footer: {
    flex: 1,
    backgroundColor: "#F5F5F5"
  }
});

class ProfileDrawer extends React.PureComponent {
  render() {
    const {
      visible,
      classes,
      content,
      setProfileDrawerNotVisible,
      headerText
    } = this.props;

    return (
      <Drawer
        anchor="right"
        open={visible}
        onClose={setProfileDrawerNotVisible}
        classes={{ paper: classes.paper }}
      >
        <ProfileDrawerHeader
          setProfileDrawerNotVisible={setProfileDrawerNotVisible}
          text={headerText}
        />
        {isEmpty(content) ? <></> : content}
      </Drawer>
    );
  }
}

ProfileDrawer.propTypes = {
  visible: propTypes.bool,
  content: propTypes.object
};

const mapStateToProps = state => {
  const { visible, content, headerText } = state.ui.profileDrawer;
  return {
    visible,
    content,
    headerText
  };
};

const mapDispatchToProps = dispatch => ({
  clearProfileDrawer: () => dispatch(clearProfileDrawer()),
  setProfileDrawerNotVisible: () => dispatch(setProfileDrawerVisible(false))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(ProfileDrawer));
