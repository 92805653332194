import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import find from "lodash/find";
import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { setHeaderMenuScope } from '../../../actions/shared/uiActions';
import { getCurrentIndividual } from '../../../selectors/individualSelectors';
import { usePrevious } from '../../General/customHooks/usePrevious';

import {
  extractKeyFromPath,
  EMPLOYER_TAB_OPTIONS,
  RECRUITER_TAB_OPTIONS
} from '../HeaderPrimary/NavigationTabs/constants';

function MobileTabsScopeToggle({ scope, history }) {
  const dispatch = useDispatch();

  const currentIndividual = useSelector(getCurrentIndividual);
  const isEmployer = currentIndividual.employer;
  const isTemp = scope === 'temp';

  const handleChange = (event) => {
    const newScope = event.target.checked ? 'temp' : 'perm';

    dispatch(setHeaderMenuScope(newScope));

    const defaultTab = find(
      isEmployer ? EMPLOYER_TAB_OPTIONS : RECRUITER_TAB_OPTIONS,
      { exclusiveScope: newScope, isScopedDefault: true }
    );

    if (defaultTab.handleClick) {
      defaultTab.handleClick(history);
    } else {
      history.push({ pathname: `/${defaultTab.key}` });
    }
  };

  const currTabKey = extractKeyFromPath(history.location.pathname);
  const prevTabKey = usePrevious(currTabKey);

  useEffect(() => {
    if (!prevTabKey || currTabKey === prevTabKey) return () => {};

    const tabOptions = isEmployer ? EMPLOYER_TAB_OPTIONS : RECRUITER_TAB_OPTIONS;

    const currentTab = tabOptions[currTabKey];
    const hasExclusiveScope = currentTab?.exclusiveScope;
    const scopeMismatch = hasExclusiveScope && currentTab.exclusiveScope !== scope;

    if (scopeMismatch) {
      dispatch(setHeaderMenuScope(currentTab.exclusiveScope));
    }

    return () => {};
  }, [currTabKey, prevTabKey, isEmployer, scope, dispatch]);

  return (
    <FormControlLabel
      style={{ marginLeft: 8 }}
      label={(
        <div style={{ fontSize: 25, color: "#546E7A" }}>
          {isTemp ? 'Contract' : 'Perm'}
        </div>
      )}
      control={(
        <Switch checked={isTemp} onChange={handleChange} color="default" />
      )}
    />
  );
}

export default withRouter(MobileTabsScopeToggle);
