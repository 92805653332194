import React from "react";
import { connect } from "react-redux";

import { createMessage } from "../../../../actions/employers/candidateActions";
import { clearModal } from "../../../../actions/shared/uiActions";
import {
  inputValue,
  updateFormValue,
  errorString,
  hasErrors,
  validateFields,
} from "../../../../util/formHelpers";
import FileInput from "../../../forms/custom/FileInput.react";
import ActionModalContainer from "../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../General/ActionModal/ActionModalHeader";

class AttachFileModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      loading: false,
      file_input: inputValue(),
    };

    this.updateFormValue = updateFormValue.bind(this);
    this.inputErrorString = errorString.bind(this);
    this.inputHasErrors = hasErrors.bind(this);
    this.validateFields = validateFields.bind(this);
    this.preSubmitValidations = {
      file_input: { file: true, required: true },
    };
  }

  async readFile(file) {
    return await new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (output) => resolve(output.target.result);
      fileReader.readAsDataURL(file);
    });
  }

  async readDocument() {
    const file = await this.readFile(this.state.file_input.value);
    const message = {
      file,
      file_name: this.state.file_input.value.name,
      file_type: this.state.file_input.value.type,
    };

    return message;
  }

  handleSubmit = () => {
    if (this.state.submitted) {

    } else {
      this.validateFields(this.preSubmitValidations)
        .then(() => {
          this.readDocument().then((document) => {
            this.setState({ submitted: true, loading: true }, () => {
              this.props
                .createMessage(this.props.candidate.attributes.id, document)
                .then(this.props.clearModal)
                .catch(() => this.setState({ loading: false, submitted: false }));
            });
          });
        })
        .catch(() => {});
    }
  };

  renderContent() {
    const {
      otherParticipantFirstName: firstName,
      otherParticipantLastName: lastName
    } = this.props.conversation.attributes;
    const { employer } = this.props;

    const name = `${firstName} ${employer ? lastName : lastName[0]}`;

    return (
      <div>
        {`Select and send a file to ${name}. Once sent it cannot be deleted.`}
        <div style={{ width: 300 }}>
          <FileInput
            error={this.inputHasErrors("file_input")}
            helperText={this.inputErrorString("file_input")}
            accept="image/png, image/jpg, image/jpeg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint	application/x-iwork-keynote-sffkey, text/csv"
            id="file_input"
            label="File Attachment"
            onChange={this.updateFormValue("file_input")}
            value={this.state.file_input.value}
          />
        </div>
      </div>
    );
  }

  render() {
    const { candidate } = this.props;
    const { loading } = this.state;

    return (
      <ActionModalContainer color="blue">
        <ActionModalHeader
          title="Attach Document"
          subtitle={`${candidate.attributes.firstName} ${candidate.attributes.lastName}`}
        />
        <ActionModalContent>{this.renderContent()}</ActionModalContent>
        <ActionModalFooter
          actionText="Send Attachment"
          handleAction={this.handleSubmit}
          isSubmitting={loading}
        />
      </ActionModalContainer>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearModal: () => dispatch(clearModal()),
  createMessage: (id, message) => dispatch(createMessage(id, message)),
});

export default connect(null, mapDispatchToProps)(AttachFileModal);
