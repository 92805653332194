import merge from "lodash/merge";

import { RECEIVE_VIEWING_AS } from "../actions/shared/individualActions";
import {
  RECEIVE_NOTIFICATIONS,
  SET_NOTIFICATIONS_LOADED,
} from "../actions/shared/notificationActions";

const defaultState = {
  loaded: false,
  items: {},
};

export default function notificationReducer(
  state = defaultState,
  { type, payload } = {}
) {
  Object.freeze(state);

  switch (type) {
    case RECEIVE_NOTIFICATIONS:
      return merge({}, state, {
        items: payload,
      });
    case SET_NOTIFICATIONS_LOADED:
      return merge({}, state, { loaded: payload });
    case RECEIVE_VIEWING_AS:
    default:
      return state;
  }
}
