import normalize from "json-api-normalizer";

import { GET, POST, PATCH } from "../../util/apiHelpers";

export const RECEIVE_CERTIFICATIONS = "RECEIVE_CERTIFICATIONS";
export const RECEIVE_CERTIFICATION = "RECEIVE_CERTIFICATION";

export const receiveCertifications = certifications => ({
  type: RECEIVE_CERTIFICATIONS,
  payload: certifications
});

export const receiveCertification = certification => ({
  type: RECEIVE_CERTIFICATION,
  payload: certification
});

export const fetchCertifications = () => dispatch => GET("/api/v3/recruiter/certifications").then(res => {
  const normalized = normalize(res.data);
  return dispatch(receiveCertifications(normalized.certification));
});

export const createCertification = data => dispatch => POST(`/api/v3/recruiter/certifications`, data).then(res => {
  const normalized = normalize(res.data);
  return dispatch(
    receiveCertification(normalized.certification[res.data.data.id])
  );
});

export const archiveCertification = id => dispatch => PATCH(`/api/v3/recruiter/certifications/${id}/archive`).then(res => {
  const normalized = normalize(res.data);
  return dispatch(
    receiveCertification(normalized.certification[res.data.data.id])
  );
});
