import React from "react";
import { connect } from "react-redux";

import {
  openModal,
  updateAnimation
} from "../../../../../../../actions/shared/uiActions";
import Button from "../../../../../../forms/custom/Button";
import RejectModal from "../modals/RejectModal";

function RejectButton({
  candidate,
  openModal,
  overrideStyle,
  overloadedClasses,
  updateCardExitAnimation,
  onClick
}) {
  const handleClick = () => {
    updateCardExitAnimation();
    openModal(
      <RejectModal candidate={candidate} />
    );
    onClick && onClick();
  };

  return (
    <Button
      color="red"
      variant="primary"
      onClick={handleClick}
      style={overrideStyle || {}}
      overloadedClasses={overloadedClasses}
      data-cy="reject-button"
    >
      reject
    </Button>
  );
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateCardExitAnimation: () => dispatch(updateAnimation(`candidate-card-${ownProps.candidate.id}`, {
    classes: { exit: "fadeOut" }
  })),
  openModal: content => dispatch(openModal(content))
});

export default connect(
  null,
  mapDispatchToProps
)(RejectButton);
