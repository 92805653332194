import React from "react";
import { connect } from "react-redux";

import { openModal } from "../../../../../../../actions/shared/uiActions";
import Button from "../../../../../../forms/custom/Button";
import HireModal from "../modals/HireModal";

function HireButton({
  candidate,
  openModal,
  overrideStyle,
  overloadedClasses
}) {
  const handleClick = () => openModal(<HireModal candidate={candidate} />);

  return (
    <Button
      color="blue"
      variant="primary"
      onClick={handleClick}
      style={({ padding: "0 26px 0 26px", ...overrideStyle || {} })}
      overloadedClasses={overloadedClasses}
      data-cy="hire-button"
    >
      hire
    </Button>
  );
}

const mapDispatchToProps = dispatch => ({
  openModal: content => dispatch(openModal(content))
});

export default connect(
  null,
  mapDispatchToProps
)(HireButton);
