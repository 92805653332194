import find from 'lodash/find';
import values from 'lodash/values';

export const mergePrequisites = (jobcastPrequisites, orgPrequisites = []) => {
  // checks if the jobcast has the prequisite in the organization settings (to mark as checked),
  // but includes previously added prequisites in the jobcast if the organizaiton removed them
  const tempPrequisites = [];
  jobcastPrequisites.concat(values(orgPrequisites)).forEach((prerequisite) => {
    if (find(tempPrequisites, { id: prerequisite.id })) return;

    const name = prerequisite.name ?? prerequisite.attributes?.name ?? '';
    const description = prerequisite.description ?? prerequisite.attributes?.description ?? '';
    const prerequisite_type = prerequisite.prerequisite_type ?? prerequisite.attributes?.prerequisite_type ?? 'basic';
    const isDefault = prerequisite.default ?? prerequisite.attributes?.default ?? false;
    const responsibility = prerequisite.responsibility ?? prerequisite.attributes?.responsibility ?? 'either_party';

    tempPrequisites.push({
      id: prerequisite.id,
      name,
      prerequisite_type,
      description,
      responsibility,
      default: isDefault,
      checked: (
        isDefault ||
        Boolean(find(jobcastPrequisites, { id: prerequisite.id }))
      ),
    });
  });
  return tempPrequisites;
};
