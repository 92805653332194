import filter from "lodash/filter";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { createSelector } from "reselect";

import {
  PERSONAL_LISTS,
  TEAM_LISTS,
} from "../../components/employer/Agencies/constants";
import { individualsSelector } from "../individualSelectors";

import { getAllLegacyInvitations, getPreferredLinks } from "./agencySelectors";

export const getAllRecruiterLists = (state) => state.recruiterLists.items;
export const getRecruiterListById = (state, props) => state.recruiterLists.items[props.recruiterListId].attributes;
export const getRecruiterListsLoadStatus = (state) => state.recruiterLists.loaded;

export const makeGetRecruiterListById = () => createSelector(
  [getAllRecruiterLists, (_, listId) => listId],
  (recruiterLists, listId) => recruiterLists[listId]
);

export const makeGetRecruiterLists = (scope) => createSelector([getAllRecruiterLists], (recruiterLists) => {
  switch (scope) {
    case PERSONAL_LISTS:
      return filter(
        recruiterLists,
        ({ attributes }) => attributes.ownerType === "Individual"
      );
    case TEAM_LISTS:
      return filter(
        recruiterLists,
        ({ attributes }) => attributes.ownerType === "Organization"
      );
    default:
      return recruiterLists || [];
  }
});

export const makeGetRecruiterListIndividuals = () => createSelector(
  [
    getPreferredLinks,
    getAllRecruiterLists,
    individualsSelector,
    getAllLegacyInvitations,
    (_, listId) => listId,
  ],
  (preferredLinks, recruiterLists, individuals, invitations, listId) => {
    if (isEmpty(recruiterLists[listId])) return [];
    const preferredLinkOrganizationIds = map(
      preferredLinks,
      ({ attributes }) => attributes.recruiterOrganizationId
    );

    const recruitersWithPrefLink = filter(
      individuals,
      ({ id, attributes }) => includes(recruiterLists[listId].attributes.subjectIds, id) &&
          includes(preferredLinkOrganizationIds, attributes.organizationId)
    );

    const legacyInvites = filter(invitations, ({ id }) => includes(recruiterLists[listId].attributes.subjectIds, id));

    return recruitersWithPrefLink.concat(legacyInvites);
  }
);
