import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useSelector } from "react-redux";

import {
  getViewingAs,
  getCurrentIndividual,
} from "../../selectors/individualSelectors";

import { possessifyName } from "../../util/formatHelpers";
import { VIEWING_AS_Z_INDEX } from "../../util/namedConstants";
import { storageAvailable } from "../../util/storageHelpers";

const useStyles = makeStyles({
  container: {
    display: "flex",
    position: "fixed",
    bottom: 0,
    height: 40,
    left: 0,
    right: 0,
    zIndex: VIEWING_AS_Z_INDEX,
    background: "white",
    border: "3px solid red",
    justifyContent: "center",
    alignItems: "center",
  },
});

function ViewingAsBanner() {
  const classes = useStyles();

  const { isViewableAsTeammatePage } = useSelector(
    (state) => state.ui.viewingAsBanner
  );

  const viewingAs = useSelector(getViewingAs);
  const currentIndividual = useSelector(getCurrentIndividual);

  if (currentIndividual?.employer) {
    if (Boolean(viewingAs && viewingAs.key !== "me")) {
      global.localStorage.removeItem('viewingAs');
    }
    return null;
  }

  const isViewingAsAdmin = storageAvailable() && global.localStorage.ghosting;

  const isViewingAsTeammate =
    isViewableAsTeammatePage && Boolean(viewingAs && viewingAs.key !== "me");

  let warningText = "";
  if (isViewingAsAdmin && isViewingAsTeammate) {
    warningText = `You are ghosting on production and acting as ${viewingAs.value}.`;
  } else if (isViewingAsAdmin) {
    warningText = "You are ghosting on production.";
  } else if (isViewingAsTeammate) {
    warningText = currentIndividual.employer
      ? `You are viewing ${possessifyName(
        viewingAs.value
      )} JobCasts. All actions you take will be recorded as your actions.`
      : `You are acting as ${viewingAs.value}.`;
  }

  if (!warningText) return null;

  return (
    <div className={classes.container}>{warningText}</div>
  );
}

export default ViewingAsBanner;
