import React from "react";
import { connect } from "react-redux";

import { openModal } from "../../../../../../../actions/shared/uiActions";
import Button from "../../../../../../forms/custom/Button";
import ShareModal from "../modals/ShareModal";

function ShareButton({
  candidate,
  openModal,
  overrideStyle,
  overloadedClasses
}) {
  const handleClick = () => openModal(
    <ShareModal
      selectedCandidateId={candidate.id}
      jobcastId={candidate.attributes.jobcastId}
    />
  );

  return (
    <Button
      color="grey"
      variant="secondary"
      onClick={handleClick}
      style={overrideStyle || {}}
      overloadedClasses={overloadedClasses}
      data-cy="share-button"
    >
      Share
    </Button>
  );
}

const mapDispatchToProps = dispatch => ({
  openModal: content => dispatch(openModal(content))
});

export default connect(
  null,
  mapDispatchToProps
)(ShareButton);
