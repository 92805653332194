import { object, string } from 'yup';

export const CONVERTING = "Converting to Full-Time";
export const ENDED = "Contract has ended";
export const TERMINATED = "Worker terminated or quit";
export const OTHER = "Other";

export const REASON_OPTIONS = [
  ENDED,
  TERMINATED,
  OTHER,
];

export const validationSchema = object().shape({
  last_day: string().nullable().when("reason", {
    is: (val) => val !== CONVERTING,
    then: string().required("Last Day of Work is required"),
    otherwise: string().nullable(),
  }),
  reason: string().required("Cannot be blank"),
  other_reason: string().when("reason", {
    is: "Other",
    then: string().required("Reason is required").minWordsWithoutHTML(3, "Must be at least 3 words"),
    otherwise: string().nullable(),
  }),
});

export const initialValues = {
  last_day: null,
  reason: "",
  other_reason: ""
};
