import CircularProgress from "@material-ui/core/CircularProgress";
import Icon from "@material-ui/core/Icon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Select, { components } from "react-select";

export function Dropdown({
  children, isOpen, target, onClose
}) {
  return (
    <div style={{ position: "relative" }}>
      {target}
      {isOpen ? <Menu>{children}</Menu> : null}
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </div>
  );
}

function Menu(props) {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: 4,
        marginTop: 8,
        position: "absolute",
        zIndex: 2,
      }}
      {...props}
    />
  );
}

function Blanket(props) {
  return (
    <div
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: "fixed",
        zIndex: 1,
      }}
      {...props}
    />
  );
}

export const psuedoMenuItem = (option, onClick) => (
  <ListItem onClick={onClick} style={listStyle}>
    <ListItemText
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 0,
      }}
      disableTypography
      primary={<span>{option ? option.selectedLabel : ""}</span>}
      secondary={<ChevronDown />}
    />
  </ListItem>
);

const listStyle = {
  padding: "9px 0px 11px 0px",
  width: "100%",
  backgroundColor: "#008dae",
  fontFamily: "'proxima-nova', helvetica",
  fontSize: "16px",
  fontWeight: "700",
  fontStyle: "normal",
  fontStretch: "normal",
  lineHeight: "19px",
  letterSpacing: "0.15px",
  textAlign: "left",
  color: "#ffffff",
  height: 36,
  cursor: "pointer",
};

function ChevronDown() {
  return (
    <Svg style={{ marginRight: -6, marginTop: -2 }}>
      <path
        d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  );
}

function Svg(p) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      focusable="false"
      role="presentation"
      {...p}
    />
  );
}

function Control(props) {
  return (
    <components.Control {...props}>
      <Icon style={{ marginTop: 2, marginLeft: 4 }}>search</Icon>
      {props.children}
    </components.Control>
  );
}

function IndicatorsContainer() {
  return <></>;
}

function LoadingIndicator() {
  return <CircularProgress style={{ marginRight: 8 }} color="primary" size={14} />;
}

export const customStyles = {
  control: (provided) => ({
    ...provided,
    width: 256,
    margin: 8,
    zIndex: 10,
    position: "fixed",
    background: "white",
    fontFamily: "'proxima-nova', helvetica",
    fontSize: "16px",
    color: "#37474F",
  }),
  loadingMessage: (provided) => ({
    ...provided,
    textAlign: "center",
    width: 274,
  }),
  menu: () => ({ background: "white", position: "fixed" }),
  menuList: (provided, state) => ({
    ...provided,
    marginTop: 54,
    width: 274,
    maxHeight: 290,
    overflowY: "auto",
    position: "fixed",
    background: "white",
    borderRadius: "0 0 4px 4px",
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    fontFamily: "'proxima-nova', helvetica",
    fontSize: "16px",
    color: "#37474F",
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    minWidth: 274,
    fontFamily: "'proxima-nova', helvetica",
    fontSize: "16px",
    color: "#37474F",
  }),
  option: (provided, state) => ({
    ...provided,
    height: state.isDisabled ? 20 : provided.height,
    padding: state.isDisabled ? "0 12px" : provided.padding,
    textAlign: state.isDisabled ? "center" : provided.textAlign,
    cursor: state.isDisabled ? "initial" : "pointer",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "'proxima-nova', helvetica",
    fontSize: "16px",
    color: "#90A4AE",
  }),
};

export const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary50: "#EEF7F8",
    primary25: "#F3F4F4",
    primary: "#008dae",
  },
});

export const createOptionLabel = (prefix, count = "", isDivider) => (
  <span
    style={{
      display: "flex",
      justifyContent: isDivider ? "center" : "space-between",
      width: 235,
    }}
  >
    <span>{prefix}</span>
    <span style={{ fontVariantNumeric: "tabular-nums lining-nums" }}>
      {count || ""}
    </span>
  </span>
);

export const dividerOption = {
  key: "divider",
  label: createOptionLabel("──────────────", "", true),
  value: "divider",
  selectedLabel: "divider",
  isDisabled: true,
  id: null,
};

export const createOption = ({
  id, selectedLabel, labelPrefix, count
}) => ({
  key: id,
  label: createOptionLabel(labelPrefix, count),
  value: labelPrefix,
  selectedLabel: selectedLabel || labelPrefix,
  id,
  count,
});

function LeftNavDropdown({
  options,
  selectedOption,
  handleSelection,
  isOpen,
  toggleOpen,
  isLoaded,
}) {
  return (
    <div
      style={{
        padding: "0 12px 12px 24px",
      }}
      data-cy="left-nav-dropdown"
    >
      <Dropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        target={psuedoMenuItem(selectedOption, toggleOpen)}
      >
        <div
          style={{
            background: "white",
            width: 274,
            height: 54,
            position: "fixed",
            borderRadius: "4px 4px 0 0",
            marginTop: -8,
            boxShadow:
              "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Select
            autoFocus
            backspaceRemovesValue={false}
            components={{
              Control,
              IndicatorsContainer,
              LoadingIndicator,
            }}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            onChange={handleSelection}
            options={options}
            placeholder="Search..."
            styles={customStyles}
            tabSelectsValue={false}
            value={selectedOption}
            theme={theme}
            isLoading={!isLoaded}
          />
        </div>
      </Dropdown>
    </div>
  );
}

export default LeftNavDropdown;
