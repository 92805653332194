import difference from "lodash/difference";
import map from "lodash/map";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { markNotificationsRead } from "../../../../actions/shared/notificationActions";

const withNotificationsReader = Component => {
  class NotificationsReader extends React.Component {
    componentDidMount() {
      const { unreadNotifications, markNotificationsRead } = this.props;

      if (unreadNotifications.length) {
        markNotificationsRead(this.mapToIds(unreadNotifications));
      }
    }

    componentDidUpdate({ unreadNotifications: prevUnreadNotifications }) {
      const { unreadNotifications, markNotificationsRead } = this.props;

      const currIds = this.mapToIds(unreadNotifications);
      const prevIds = this.mapToIds(prevUnreadNotifications);

      const newIds = difference(currIds, prevIds);

      if (newIds.length) {
        markNotificationsRead(newIds);
      }
    }

    mapToIds = (notificationItems) => map(notificationItems, (item) => item.id);

    render() {
      return <Component {...this.props} />;
    }
  }

  NotificationsReader.propTypes = {
    unreadNotifications: PropTypes.array.isRequired
  };

  const mapDispatchToProps = dispatch => ({
    markNotificationsRead: ids => dispatch(markNotificationsRead(ids))
  });

  return connect(null, mapDispatchToProps)(NotificationsReader);
};

export default withNotificationsReader;
