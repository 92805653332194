import { withStyles } from "@material-ui/core/styles";
import round from "lodash/round";
import PropTypes from "prop-types";
import React from "react";

import PageCardRowHeader from "../../../../../../General/PageCardRowHeader";
import StarRatingDisplay from "../../../../../../General/StarRatingDisplay";

const StyledRatingValue = withStyles({
  ratingValueContainer: {
    display: "flex",
    height: 32,
    alignItems: "center",
  },
  ratingValueNumber: {
    marginLeft: 4,
    color: "#90a4ae",
  },
})(({ value, classes }) => (
  <div className={classes.ratingValueContainer}>
    <StarRatingDisplay rating={value || 0} />
    <div className={classes.ratingValueNumber}>
      ({typeof value === "number" ? round(value, 1) : "N/A"})
    </div>
  </div>
));

export const StyledRatings = withStyles({
  container: {
    paddingLeft: 80,
    paddingRight: 24,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  label: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 12,
    height: 32,
    paddingBottom: 0,
    color: "#B0BEC5",
    fontFamily: "proxima-nova",
    fontSize: "13px",
    fontWeight: "600",
    letterSpacing: "0.4px",
    textTransform: "uppercase",
  },
  count: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: -4,
    color: "#90a4ae",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
})(
  ({
    average,
    communication,
    knowledge,
    professionalism,
    numReviews,
    classes,
  }) => {
    return (
      <div className={classes.container} data-cy="candidate-rating-categories">
        <div className={classes.flexCol} style={{ marginRight: 160 }}>
          <div className={classes.flexRow}>
            <div className={classes.label}>Overall</div>
            <StyledRatingValue value={average} />
          </div>
          <div className={classes.count}>
            <i>
              {numReviews} Rating
              {numReviews !== 1 && "s"}
            </i>
          </div>
        </div>
        <div className={classes.flexCol}>
          <div className={classes.label}>Communication</div>
          <div className={classes.label}>Knowledge</div>
          <div className={classes.label}>Professionalism</div>
        </div>
        <div className={classes.flexCol}>
          <StyledRatingValue value={communication?.average} />
          <StyledRatingValue value={knowledge?.average} />
          <StyledRatingValue value={professionalism?.average} />
        </div>
      </div>
    );
  }
);

StyledRatings.propTypes = {
  numRatees: PropTypes.number.isRequired,
  overall: PropTypes.number,
  communication: PropTypes.number,
  knowledge: PropTypes.number,
  professionalism: PropTypes.number,
};

function CandidateRatings({ ratingsData }) {
  return (
    <>
      <PageCardRowHeader title="Ratings from Candidates" />
      <StyledRatings {...ratingsData} />
    </>
  );
}

CandidateRatings.propTypes = {
  ratingsData: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default CandidateRatings;
