import CircularProgress from "@material-ui/core/CircularProgress";
import { withFormik } from "formik";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import merge from "lodash/merge";
import omit from "lodash/omit";
import pick from "lodash/pick";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import {
  advanceCandidate,
  fetchCandidate,
} from "../../../../../../../../actions/employers/candidateActions";
import { fetchJobCast } from "../../../../../../../../actions/employers/jobcastActions";
import {
  clearModal,
  clearProfileDrawer,
  setProfileDrawerVisible,
} from "../../../../../../../../actions/shared/uiActions";
import { getCandidate } from "../../../../../../../../selectors/candidateSelectors";
import { getJobCast } from "../../../../../../../../selectors/jobcastSelectors";
import { getCurrentOrganization } from "../../../../../../../../selectors/organizationSelectors";
import { buildFullName } from "../../../../../../../../util/formatHelpers";
import { string, object, boolean } from "../../../../../../../../util/yup";

import Button from "../../../../../../../forms/custom/Button";
import FormStepper from "../../../../../../../forms/custom/FormStepper";
import ActionModalContent from "../../../../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../../../../General/ActionModal/ActionModalFooter";
import LoadingPage from "../../../../../../../General/LoadingPage";
import withSnackbar from "../../../../../../../General/withSnackbar";

import {
  shouldInviteToATS,
  HireModalSteps,
  ArchivedModalSteps,
  HireModalStepHelperTexts,
} from "../../constants";

import BillingInfo from "./BillingInfo";
import HireInformation from "./HireInformation";
import HireOverview from "./HireOverview";
import InviteCandidateToATS from "./InviteCandidateToATS";
import JobCastStatus from "./JobCastStatus";

class HireModalContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      step: 0,
    };
  }

  showAtsInvite = () => {
    const { sourceTrackingCode, atsSetup, candidateStage } =
      this.props.organization;
    const { atsApplicationUrl } = this.props.jobcast.attributes;
    const { status } = this.props.candidate.attributes;

    return (
      atsSetup &&
      Boolean(sourceTrackingCode) &&
      Boolean(atsApplicationUrl) &&
      Boolean(shouldInviteToATS[status]) &&
      shouldInviteToATS[status][candidateStage]
    );
  };

  loadCandidate = async () => {
    const { fetchCandidate } = this.props;
    return fetchCandidate();
  };

  setFormValues = async () => {
    const {
      jobcast, candidate, organization, costCenters
    } = this.props;

    const orgInvoiceMemoRequired = organization.invoiceMemoRequired;
    let costCenterInvoiceMemoRequired = false;

    const costCenterKeys = keys(costCenters);
    if (costCenterKeys.length === 1) {
      const defaultCostCenter = costCenters[costCenterKeys[0]];
      if (defaultCostCenter.attributes.invoiceMemoRequired) {
        costCenterInvoiceMemoRequired = true;
      }
    }

    return this.props.setValues({
      currency: jobcast.attributes.salaryMinCurrency,
      workplace_country_code: jobcast.attributes.countryCodes[0],
      workplace_locale: "",
      workplace_postal_code: "",
      hire_start_date: "",
      fixed_fee: !candidate.attributes.feeOptionChoices,
      fee_option_id: candidate.attributes.feeOptionChoices
        ? "" // dont autofill option if there are choices
        : candidate.attributes.feeOption.id,
      close_after_hire: "",
      salary: "",
      invoice_memo: jobcast.attributes.invoiceMemo || "",
      ats_invite: this.showAtsInvite(),
      cost_center_id: jobcast.attributes.costCenterId,
      invoice_memo_required:
        orgInvoiceMemoRequired || costCenterInvoiceMemoRequired,
      deferred_invoice_memo: jobcast.attributes.deferredInvoiceMemo,
      deferred_invoice_memo_email:
        jobcast.attributes.deferredInvoiceMemoEmail || "",
    });
  };

  componentDidMount() {
    this.loadCandidate()
      .then(this.props.fetchJobCast)
      .then(this.setFormValues)
      .then(() => this.setState({ loaded: true }));
  }

  getCandidateName() {
    const {
      candidate: {
        attributes: { firstName, lastName },
      },
    } = this.props;
    return buildFullName(firstName, lastName);
  }

  renderBackButton() {
    const { step } = this.state;
    return step > 0 ? (
      <Button
        color="grey"
        variant="secondary"
        onClick={() => this.setState({ step: step - 1 })}
        disabled={false}
        data-cy="action-modal-button-back"
      >
        Back
      </Button>
    ) : (
      <></>
    );
  }

  renderNextButton() {
    const { step } = this.state;
    const {
      isSubmitting, handleSubmit, validateForm, setTouched, setFieldValue, previouslyArchived
    } = this.props;
    const isLastStep = step === 2;
    const isMiddleStep = step === 1;

    const handleNextAction = () => {
      if (isLastStep) {
        return handleSubmit();
      }
      if (isMiddleStep && previouslyArchived) {
        setFieldValue("close_after_hire", "true").then(() => {
          return handleSubmit();
        })
      } else if (isMiddleStep) {
        // validate all fields on Hire Information and Billing Info sections
        // (close_after_hire is on next step)
        // advance to next step if errors not present, otherwise set error fields touched
        validateForm().then((err) => {
          const formErrors = omit(err, "close_after_hire");

          if (isEmpty(formErrors)) {
            this.setState({ step: step + 1 });
          } else {
            setTouched(formErrors);
          }
        });
      } else {
        return this.setState({ step: step + 1 });
      }
    };

    return (
      <div>
        <Button
          color="blue"
          variant="primary"
          onClick={handleNextAction}
          disabled={isSubmitting}
          data-cy="action-modal-button-next"
          style={{ marginLeft: 12 }}
        >
          {isLastStep || (previouslyArchived && isMiddleStep) ? "Hire" : "Next"}
        </Button>
        {isSubmitting && (
          <CircularProgress
            size={24}
            style={{
              position: "absolute",
              top: "20%",
              left: "50%",
              marginRight: "-12px",
              marginLeft: "-12px",
              color: "#90A4AE",
            }}
          />
        )}
      </div>
    );
  }

  renderButtonList() {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {this.renderBackButton()}
        {this.renderNextButton()}
      </div>
    );
  }

  renderStepContent() {
    const { step } = this.state;
    const { jobcast, candidate } = this.props;
    const formProps = pick(this.props, [
      "values",
      "errors",
      "touched",
      "handleChange",
      "handleBlur",
      "setFieldValue",
      "submitCount",
    ]);

    switch (step) {
      case 0:
        return (
          <HireOverview
            jobcast={jobcast}
            candidate={candidate}
            isSubmitted={false}
          />
        );
      case 1:
        return (
          <>
            <HireInformation {...formProps} jobcast={jobcast} />
            <BillingInfo
              {...formProps}
              jobcast={jobcast}
              candidate={candidate}
            />
          </>
        );
      case 2:
        return (
          <>
            <JobCastStatus {...formProps} />
            {this.showAtsInvite() && <InviteCandidateToATS {...formProps} />}
          </>
        );
      default:
        return <></>;
    }
  }

  renderContent() {
    return (
      <>
        <FormStepper
          activeStep={this.state.step}
          steps={this.props.previouslyArchived ? ArchivedModalSteps : HireModalSteps}
          stepHelperTexts={HireModalStepHelperTexts}
          helperTextStyle={{ padding: "12px 48px" }}
          overrideStepperStyle={{ paddingTop: 0 }}
        />
        {this.renderStepContent()}
      </>
    );
  }

  render() {
    const { errors, submitCount } = this.props;
    const { loaded } = this.state;

    return (
      <>
        <ActionModalContent style={{ paddingLeft: 12, paddingRight: 12 }}>
          {loaded ? this.renderContent() : <LoadingPage />}
        </ActionModalContent>
        <ActionModalFooter
          customButtons={this.renderButtonList()}
          submissionError={Boolean(submitCount && Object.values(errors).length)}
        />
      </>
    );
  }
}

// incomplete at the moment
HireModalContent.propTypes = {
  jobcast: PropTypes.object.isRequired,
  candidate: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  costCenters: PropTypes.array.isRequired,
  jobcastLoaded: PropTypes.bool.isRequired,
  candidateLoaded: PropTypes.bool.isRequired,
  clearModal: PropTypes.func.isRequired,
  advanceCandidate: PropTypes.func.isRequired,
  fetchJobCast: PropTypes.func.isRequired,
  fetchCandidate: PropTypes.func.isRequired,
  setProfileDrawerNotVisible: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  submitCount: PropTypes.number.isRequired,
  snackbar: PropTypes.object.isRequired,
};

const HireModalFormContent = withFormik({
  handleSubmit: (values, { props, setSubmitting }) => {
    const { advanceCandidate, clearProfileDrawer, previouslyArchived } = props;
    const submissionData = pick(values, [
      "fee_option_id",
      "currency",
      "hire_start_date",
      "invoice_memo",
      "ats_invite",
      "close_after_hire",
      "workplace_country_code",
      "cost_center_id",
      "deferred_invoice_memo",
      "deferred_invoice_memo_email",
    ]);

    if (values.workplace_country_code === "USA") {
      merge(
        submissionData,
        pick(values, ["workplace_locale", "workplace_postal_code"])
      );
    }

    submissionData.salary = parseFloat(values.salary.replace(/,/g, ""));
    submissionData.hire_start_date = moment
      .utc(values.hire_start_date)
      .toISOString();

    advanceCandidate(submissionData)
      .then(() => props.snackbar.showMessage(
        `${buildFullName(
          props.candidate.attributes.firstName,
          props.candidate.attributes.lastName
        )} has been hired`
      ))
      .then(() => props.fetchJobCast())
      .then(() => props.setHireSubmitted(true))
      .then(() => {
        if (values.close_after_hire === "true" && !previouslyArchived) {
          clearProfileDrawer();
          props.history.push("/jobcasts/live");
        }
      })
      .catch(() => setSubmitting(false));
  },
  mapPropsToValues: ({ organization, costCenters }) => {
    const orgInvoiceMemoRequired = organization.invoiceMemoRequired;
    let costCenterInvoiceMemoRequired = false;

    const costCenterKeys = keys(costCenters);
    if (costCenterKeys.length === 1) {
      const defaultCostCenter = costCenters[costCenterKeys[0]];
      if (defaultCostCenter.attributes.invoiceMemoRequired) {
        costCenterInvoiceMemoRequired = true;
      }
    }

    return {
      invoice_memo_required:
        orgInvoiceMemoRequired || costCenterInvoiceMemoRequired,
    };
  },
  validationSchema: object().shape({
    currency: string().required("Cannot be blank").nullable(),
    salary: string()
      .required("Cannot be blank")
      .test(
        "salary-validation",
        "Must be at greater than 999",
        (value) => parseFloat(value?.replace(/,/g, "")) > 999
      ),
    workplace_country_code: string().required("Cannot be blank"),
    workplace_locale: string().when("workplace_country_code", {
      is: "USA",
      then: string().required("Cannot be blank."),
    }),
    hire_start_date: string().required("Cannot be blank"),
    workplace_postal_code: string().when("workplace_country_code", {
      is: "USA",
      then: string().required("Cannot be blank.").min(5).max(5),
    }),
    invoice_memo: string().when("invoice_memo_required", {
      is: true,
      then: string()
        .max(140)
        .nullable()
        .required("Required by your organization admin"),
      otherwise: string().max(140),
    }),
    fixed_fee: boolean(),
    ats_invite: boolean(),
    fee_option_id: string().when("fixed_fee", {
      is: false,
      then: string().required("Cannot be blank"),
    }),
    close_after_hire: string()
      .required("Cannot be blank")
      .oneOf(["true", "false"]),
    cost_center_id: string().required("Cannot be blank"),
    deferred_invoice_memo_email: string().when("deferred_invoice_memo", {
      is: true,
      then: string()
        .required("Cannot be blank")
        .email("Must be a valid email address"),
    }),
  }),
})(HireModalContent);

const mapStateToProps = (state, ownProps) => {
  const jobcast = getJobCast(state, ownProps.candidate.attributes.jobcastId);
  const candidate = getCandidate(state, ownProps.candidate.id);
  const organization = getCurrentOrganization(state);
  const costCenters = state.costCenters.items;
  const previouslyArchived = jobcast.attributes.status === "archived"

  return {
    jobcast,
    candidate,
    organization,
    costCenters,
    jobcastLoaded: Boolean(jobcast && jobcast.loaded),
    candidateLoaded: Boolean(candidate && candidate.loaded),
    previouslyArchived
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  clearProfileDrawer: () => dispatch(clearProfileDrawer()),
  clearModal: () => dispatch(clearModal()),
  advanceCandidate: (data) => dispatch(advanceCandidate(ownProps.candidate.id, data)),
  fetchJobCast: () => dispatch(fetchJobCast(ownProps.candidate.attributes.jobcastId)),
  fetchCandidate: () => dispatch(fetchCandidate(ownProps.candidate.id)),
  setProfileDrawerNotVisible: () => dispatch(setProfileDrawerVisible(false)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar()(HireModalFormContent)));
