import React from "react";

export function Field({ label, children }) {
  return (
    <div style={{ marginBottom: 20 }}>
      <div
        className="field-label"
        style={{
          paddingBottom: 0,
          wordBreak: "break-word",
        }}
      >
        {label}
      </div>
      {children}
    </div>
  );
}

function FieldSection({ children }) {
  return <div style={{ padding: "0px 24px" }}>{children}</div>;
}

export default FieldSection;
