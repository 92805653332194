import normalize from "json-api-normalizer";

import { GET, POST, PATCH } from "../../util/apiHelpers";

export const RECEIVE_EMPLOYEE_WORK_HISTORIES =
  "RECEIVE_EMPLOYEE_WORK_HISTORIES";
export const RECEIVE_EMPLOYEE_WORK_HISTORY = "RECEIVE_EMPLOYEE_WORK_HISTORY";

export const receiveEmployeeWorkHistories = (employeeWorkHistories) => ({
  type: RECEIVE_EMPLOYEE_WORK_HISTORIES,
  payload: employeeWorkHistories,
});

export const receiveEmployeeWorkHistory = (employeeWorkHistory) => ({
  type: RECEIVE_EMPLOYEE_WORK_HISTORY,
  payload: employeeWorkHistory,
});

export const fetchEmployeeWorkHistories = () => (dispatch) => GET("/api/v3/recruiter/employee_work_histories").then((res) => {
  const normalized = normalize(res.data);
  return dispatch(
    receiveEmployeeWorkHistories(normalized.employeeWorkHistory)
  );
});

export const createEmployeeWorkHistory = (data) => (dispatch) => POST(`/api/v3/recruiter/employee_work_histories`, data).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(
    receiveEmployeeWorkHistory(
      normalized.employeeWorkHistory[res.data.data.id]
    )
  );
});

export const archiveEmployeeWorkHistory = (id) => (dispatch) => PATCH(`/api/v3/recruiter/employee_work_histories/${id}/archive`).then(
  (res) => {
    const normalized = normalize(res.data);
    return dispatch(
      receiveEmployeeWorkHistory(
        normalized.employeeWorkHistory[res.data.data.id]
      )
    );
  }
);
