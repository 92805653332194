import {
  makeStyles,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Table,
} from "@material-ui/core";
import { FieldArray, useFormikContext } from "formik";
import map from "lodash/map";
import React from "react";

import { useSelector } from "react-redux";

import { getCurrentIndividual } from "../../../../../selectors/individualSelectors";
import {
  BASIC_CONFIRMATION_FIELD,
  DATE_FIELD,
  DOCUMENT_FIELD,
  SOW_FIELD,
  TIMESHEET_SUBMITTERS_FIELD,
  determineChecklistItemType,
  AGENCY, BOTH_PARTIES, EMPLOYER, EITHER_PARTY
} from "../fieldHelpers";

import BasicConfirmationFieldRow from "./BasicConfirmationFieldRow";
import DateFieldRow from "./DateFieldRow";
import DocumentFieldRow from "./DocumentFieldRow";
import SOWFieldRow from "./SOWFieldRow";
import TimesheetSubmittersFieldRow from "./TimesheetSubmittersFieldRow";

function TypedChecklistItemRow(props) {
  const rowType = determineChecklistItemType(props.checklistItem);

  const RowComponent = {
    [SOW_FIELD]: SOWFieldRow,
    [BASIC_CONFIRMATION_FIELD]: BasicConfirmationFieldRow,
    [DATE_FIELD]: DateFieldRow,
    [DOCUMENT_FIELD]: DocumentFieldRow,
    [TIMESHEET_SUBMITTERS_FIELD]: TimesheetSubmittersFieldRow,
  }[rowType];

  return React.createElement(RowComponent, props);
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px 0px`,
  },
  header: {
    backgroundColor: "#e3e9e9",
    "& th": {
      fontWeight: "bold",
      color: "#37474F",
    },
    "& th:not(:last-child)": {
      borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
  },
  body: {
    "& td": {
      borderBottom: "none",
      color: "#37474F",
    },
    "& td:not(:last-child)": {
      borderRight: "1px solid rgba(224, 224, 224, 1)",
    },
    "& tr:not(:last-child)": {
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
  },
}));

function ChecklistTable({ editing = true, candidate }) {
  const classes = useStyles();

  const { values } = useFormikContext();

  return (
    <TableContainer component={Paper} classes={{ root: classes.container }}>
      <Table>
        <TableHead classes={{ root: classes.header }}>
          <TableCell align="center" style={{ width: "30%" }}>
            Process
          </TableCell>
          <TableCell align="center" style={{ width: "40%" }}>
            Confirmation
          </TableCell>
          <TableCell align="center" style={{ width: "30%" }}>
            Completed
          </TableCell>
        </TableHead>
        <TableBody classes={{ root: classes.body }}>
          <FieldArray
            name="checklistItems"
            render={() =>
              map(values.checklistItems, (checklistItem, idx) => (
                <TypedChecklistItemRow
                  {...{
                    checklistItem,
                    editable: editing,
                    fieldId: `checklistItems.${idx}`,
                    candidate,
                  }}
                />
              ))}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ChecklistTable;
