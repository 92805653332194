import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { connect } from "react-redux";

import { advanceCandidate } from "../../../../../../actions/employers/candidateActions";
import {
  clearProfileDrawer,
  updateAnimation
} from "../../../../../../actions/shared/uiActions";
import { getJobCast } from "../../../../../../selectors/jobcastSelectors";
import { getCurrentOrganization } from "../../../../../../selectors/organizationSelectors";
import Button from "../../../../../forms/custom/Button";
import withSnackbar from "../../../../../General/withSnackbar";

import { shouldInviteToATS } from "./constants";

const buttonStyle = {
  position: "absolute",
  top: "20%",
  left: "50%",
  marginRight: "-12px",
  marginLeft: "-12px",
  color: "#008dae"
};

class KeepButton extends React.Component {
  state = { clicked: false };

  handleClick = () => {
    const {
      advanceCandidate,
      snackbar,
      clearProfileDrawer,
      updateCardExitAnimation
    } = this.props;

    if (!this.state.clicked) {
      updateCardExitAnimation();
      this.setState({ clicked: true }, () => {
        advanceCandidate({ ats_invite: this.inviteToAts() })
          .then(() => snackbar.showMessage("Candidate advanced to 'Reviewing'"))
          .then(clearProfileDrawer);
      });
    }
  };

  inviteToAts = () => {
    const { sourceTrackingCode, atsSetup, candidateStage } = this.props.organization;
    const { atsApplicationUrl } = this.props.jobcast.attributes;

    return Boolean(
      atsSetup &&
      Boolean(sourceTrackingCode) &&
      Boolean(atsApplicationUrl) &&
      Boolean(shouldInviteToATS.new) &&
      shouldInviteToATS.new[candidateStage]
    );
  }

  render() {
    const { overrideStyle, overloadedClasses } = this.props;
    const { clicked } = this.state;

    return (
      <Button
        color="blue"
        variant="primary"
        onClick={this.handleClick}
        style={({
          padding: "0 26px 0 26px",
          ...overrideStyle || {}
        })}
        overloadedClasses={overloadedClasses}
        disabled={clicked}
        data-cy="keep-button"
      >
        {clicked ? (
          <CircularProgress size={24} stlye={buttonStyle} />
        ) : (
          "keep"
        )}
      </Button>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const organization = getCurrentOrganization(state);
  const jobcast = getJobCast(state, ownProps.candidate.attributes.jobcastId);

  return { organization, jobcast };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  advanceCandidate: (data = {}) => dispatch(advanceCandidate(ownProps.candidate.id, data)),
  clearProfileDrawer: () => dispatch(clearProfileDrawer()),
  updateCardExitAnimation: () => dispatch(
    updateAnimation(`candidate-card-${ownProps.candidate.id}`, {
      classes: { exit: "bounceOutRight" }
    })
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar()(KeepButton));
