import { withFormik } from "formik";
import pick from "lodash/pick";
import React, { Component } from "react";
import { connect } from "react-redux";

import { submitReportFailure } from "../../../../../../../actions/employers/candidateActions";
import { fetchOpenTransactions } from "../../../../../../../actions/employers/financeActions";
import {
  clearModal,
  setProfileDrawerVisible,
} from "../../../../../../../actions/shared/uiActions";
import { buildFullName } from "../../../../../../../util/formatHelpers";
import { string, object, mixed } from "../../../../../../../util/yup";
import ActionModalContainer from "../../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../../../General/ActionModal/ActionModalHeader";
import withSnackbar from "../../../../../../General/withSnackbar";

import AdditionalInformation from "./ReportFailure/AdditionalInformation";
import Reason from "./ReportFailure/Reason";
import SupportingDocument from "./ReportFailure/SupportingDocument";

class ReportFailureModal extends Component {
  description = {
    true:
      "Please provide the information requested below. We will review this within 2 business days." +
      " Once your request is approved we will refund your payment via ACH.",
    false:
      "Please provide the information requested below." +
      " We will review this within 2 business days and confirm the guarantee failure via email.",
  }[Boolean(this.props.candidate.attributes.collectionAt)];

  getCandidateName() {
    const { firstName, lastName } = this.props.candidate.attributes;

    return buildFullName(firstName, lastName);
  }

  renderFields() {
    const formProps = pick(this.props, [
      "values",
      "errors",
      "touched",
      "handleChange",
      "handleBlur",
      "setFieldValue",
      "submitCount",
    ]);

    return [Reason, SupportingDocument, AdditionalInformation].map(
      (fieldComponent, idx) => {
        const fieldKey = `report-failure-field-${idx}`;

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "70%",
              padding: "0px 24px 0px 24px",
            }}
            key={fieldKey}
          >
            {React.createElement(fieldComponent, formProps)}
          </div>
        );
      }
    );
  }

  renderContent() {
    return (
      <>
        <div style={{ marginBottom: 24 }}>{this.description}</div>
        {this.renderFields()}
      </>
    );
  }

  render() {
    const {
      handleSubmit, errors, submitCount, isSubmitting
    } = this.props;

    return (
      <ActionModalContainer color="red">
        <ActionModalHeader
          title="Report Guarantee Failure"
          subtitle={this.getCandidateName()}
        />
        <ActionModalContent>{this.renderContent()}</ActionModalContent>
        <ActionModalFooter
          actionText="Report Failure"
          handleAction={handleSubmit}
          isSubmitting={isSubmitting}
          customErrorMessage={errors.customErrorMessage}
          submissionError={Boolean(submitCount && Object.values(errors).length)}
        />
      </ActionModalContainer>
    );
  }
}

const ReportFailureModalForm = withFormik({
  handleSubmit: async (values, { props, setSubmitting, setFieldError }) => {
    const submissionData = { reason: values.reason };
    if (values.note) {
      submissionData.note = values.note;
    }

    const readFile = async (file) => new Promise((resolve) => {
      const fileReader = new FileReader();
      fileReader.onload = (output) => resolve(output.target.result);
      fileReader.readAsDataURL(file);
    });

    submissionData.document_file = await readFile(values.document);
    submissionData.document_file_name = values.document.name;
    submissionData.document_file_type = values.document.type;

    props
      .submitReportFailure(submissionData)
      .then(() => {
        setSubmitting(false);
        props.snackbar.showMessage(
          "Guarantee failure has been reported and will be reviewed."
        );
        props.clearModal();
        props.setProfileDrawerNotVisible();
        props.fetchOpenTransactions();
      })
      .catch(({ errors }) => {
        setSubmitting(false);
        if (errors) setFieldError("customErrorMessage", errors);
      });
  },
  mapPropsToValues: () => ({
    reason: "",
    document: null,
    note: "",
  }),
  validationSchema: object().shape({
    reason: string().required("Cannot be blank"),
    document: mixed().required("Cannot be blank"),
  }),
})(ReportFailureModal);

const mapDispatchToProps = (dispatch, ownProps) => ({
  submitReportFailure: (data) => dispatch(
    submitReportFailure(ownProps.candidate.attributes.placementId, data)
  ),
  clearModal: () => dispatch(clearModal()),
  setProfileDrawerNotVisible: () => dispatch(setProfileDrawerVisible(false)),
  fetchOpenTransactions: () => dispatch(fetchOpenTransactions()),
});

export default connect(
  null,
  mapDispatchToProps
)(withSnackbar()(ReportFailureModalForm));
