import merge from "lodash/merge";

import { RECEIVE_BILLING_STATEMENTS } from "../../actions/employers/financeActions";

const _defaultState = { items: {} };

const statementReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_BILLING_STATEMENTS:
      return merge({}, state, { items: action.payload });
    default:
      return state;
  }
};

export default statementReducer;
