import { Formik } from "formik";

import map from "lodash/map";
import merge from "lodash/merge";
import omit from "lodash/omit";
import round from "lodash/round";
import React, { useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { updateAgencyFees } from "../../../../../actions/employers/agencyActions";
import { clearModal } from "../../../../../actions/shared/uiActions";
import { makeGetAgencyOrganization } from "../../../../../selectors/employer/agencySelectors";
import { getCurrentOrganization } from "../../../../../selectors/organizationSelectors";
import { mapKeysToSnakeCase } from "../../../../../util/formatHelpers";
import {
  FLAT_FEE,
  PERCENTAGE_OF_COMPENSATION,
} from "../../../../forms/formik/FormikFeeOptions/fieldHelpers";
import ActionModalContainer from "../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../General/ActionModal/ActionModalHeader";
import withSnackbar from "../../../../General/withSnackbar";

import { buildValidationSchema } from "../../fieldHelpers";

import { CUSTOM } from "../../TempInviteAgency/fieldHelpers";

import ChangeFeeModalContent from "./ChangeFeeModalContent";
import { buildInitialValues } from "./fieldHelpers";

const ChangeFeeModal = ({ organizationId, snackbar }) => {
  const dispatch = useDispatch();

  const getAgencyOrganization = makeGetAgencyOrganization();
  const currentOrganization = useSelector(getCurrentOrganization);
  const agency = useSelector(
    (state) => getAgencyOrganization(state, organizationId),
    [organizationId]
  );

  const initialValues = useMemo(
    () => buildInitialValues({ agency, currentOrganization }),
    [agency, currentOrganization]
  );

  const validationSchema = useMemo(
    () => buildValidationSchema({ currentOrganization }),
    [currentOrganization]
  );

  const handleSubmit = useCallback((values, { setSubmitting, resetForm }) => {
    const formData = () => {
      const formattedValues = mapKeysToSnakeCase(
        omit(values, ["perm", "temp"])
      );

      const parseFeeOptions = (feeOptionValues) =>
        map(feeOptionValues, ({
          fee_type, fee, fee_note, currency
        }) => ({
          fee_percentage:
            fee_type === PERCENTAGE_OF_COMPENSATION
              ? round(parseFloat(fee) / 100, 4)
              : null,
          fee_cents:
            fee_type === FLAT_FEE
              ? parseFloat(fee.replace(/,/g, "")) * 100
              : null,
          note: feeOptionValues.length > 1 ? fee_note : "",
          fee_type,
          currency: fee_type === FLAT_FEE ? currency : null,
        }));

      if (values.placementTypes.perm) {
        merge(formattedValues, {
          perm: merge(
            mapKeysToSnakeCase(
              omit(values.perm, ["multipleFees", "feeOptions"])
            ),
            {
              fee_options: values.perm.feeType === CUSTOM ?
                parseFeeOptions(values.perm.feeOptions) : [],
            }
          )
        });
      }

      if (values.placementTypes.temp) {
        merge(formattedValues, {
          temp: merge(
            mapKeysToSnakeCase(
              omit(values.temp, ["multipleFees", "feeOptions"])
            ),
            {
              fee_options: values.temp.feeType === CUSTOM ?
                parseFeeOptions(values.temp.feeOptions) : [],
            }
          )
        });
      }

      return formattedValues;
    };
    const combinedFeeOptions = [
      ...(formData()?.perm?.fee_options || []),
      ...(formData()?.temp?.fee_options || []),
    ];

    dispatch(updateAgencyFees(formData()))
      .then(() => {
        setSubmitting(false);
        dispatch(clearModal());
      })
      .then(() => {
        if (combinedFeeOptions.length > 1) {
          snackbar.showMessage("Fees successfully updated");
        } else {
          snackbar.showMessage("Fee successfully updated");
        }
        resetForm();
      })
      .catch(() => {
        setSubmitting(false);
        snackbar.showMessage("There was an issue processing your request");
      });
  }, [dispatch, snackbar]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <ActionModalContainer color="blue">
          <ActionModalHeader
            title="Preferred Agency Fee Change"
            subtitle={agency.organization.attributes.name}
          />
          <ActionModalContent>
            <ChangeFeeModalContent
              currentOrganization={currentOrganization}
            />
          </ActionModalContent>
          <ActionModalFooter
            actionText="Update Fee"
            handleAction={props.handleSubmit}
            isSubmitting={props.isSubmitting}
          />
        </ActionModalContainer>
      )}
    </Formik>
  );
};

export default (withSnackbar()(ChangeFeeModal));
