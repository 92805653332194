import merge from "lodash/merge";

import { RECEIVE_COST_CENTER_INVOICE_PERIODS } from "../../actions/employers/financeActions";

const defaultState = {
  items: {},
};

const costCenterInvoicePeriodReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_COST_CENTER_INVOICE_PERIODS:
      return merge({}, state, { items: action.payload });
    default:
      return state;
  }
};

export default costCenterInvoicePeriodReducer;
