// section links

export const ADD_AGENCIES = "add_agencies";
export const INVITED_AGENCIES = "invited_agencies";
export const DESCRIPTION = "description";
export const MESSAGE_BOARD = "message_board";
export const ALERTS = "alerts";

export const CANDIDATES = "candidates";
export const NEW = "new";
export const REVIEWING = "reviewing";
export const INTERVIEWING = "interviewing";
export const OFFERS = "offers";
export const REJECTED = "rejected";
export const HIRES = "hires";
export const ARCHIVED = "archived";

export const NAV_OPTIONS = [
  ADD_AGENCIES,
  INVITED_AGENCIES,
  DESCRIPTION,
  MESSAGE_BOARD,
  CANDIDATES
];
