import Checkbox from "@material-ui/core/Checkbox";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { memo } from "react";

import shouldSkipRerender from "./shouldSkipRerender";

function EditableCheckbox({
  formState,
  formContext,
  label,
  overrideStyle,
  ...fieldProps
}) {
  const {
    touched, errors, handleChange, handleBlur, values
  } = formContext;

  return (
    <span>
      <Checkbox
        disabled={!formState.editing}
        style={overrideStyle}
        error={Boolean(
          get(touched, fieldProps.id) && get(errors, fieldProps.id)
        )}
        checked={get(values, fieldProps.id)}
        onChange={handleChange}
        onBlur={handleBlur}
        value={get(values, fieldProps.id) || ""}
        color="primary"
        data-cy={`${fieldProps.id}-checkbox`}
        {...fieldProps}
      />
      {label}
    </span>
  );
}

EditableCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  formState: PropTypes.object.isRequired,
  formContext: PropTypes.object.isRequired,
};

export default memo(EditableCheckbox, shouldSkipRerender);
