import React from "react";

function EditHireInfo() {
  return (
    <div>
      <div>Please update this hire with accurate information.</div>
      <br />
      <div>Once submitted the following will happen:</div>
      <ol>
        <li>The old version of this invoice will be voided and deleted.</li>
        <li>
          The updated invoice will be sent to the recipients associated with the
          selected cost center, either immediately or when the standard invoice
          is scheduled to go out.
        </li>
        <li>
          The submitting agency will be notified if there are changes to the
          currency, guaranteed compensation, start date, salary, or fee.
        </li>
      </ol>
    </div>
  );
}

export default EditHireInfo;
