import Card from "@material-ui/core/Card";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 99999
  },
  card: {
    boxShadow: "1px 2px 12px #B0BEC5",
  }
}));

function PopoverLink({
  renderLink, renderPopoverContent, overrideCardStyles, parentRef, mouseOverOnly = false, style = {}
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const mouseOver = mouseOverOnly;

  const handleLinkClick = e => {
    if (mouseOver) { return; }
    setAnchorEl(e.currentTarget);
    setOpen(!open);
  };

  const handleClickaway = (e) => {
    // ignore the clickaway listener if the link (anchorEl) is clicked,
    // so the handling can be exclusively deferred to handleLinkClick
    if (!(anchorEl && anchorEl.contains(e.target))) {
      setOpen(false);
    }
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleMouseEnter = (parentRef) => {
    setAnchorEl(parentRef?.current.children[0]);
    setOpen(true);
  };

  return (
    <span
      style={{ maxWidth: 1200, ...style }}
      onMouseEnter={() => (mouseOver ? handleMouseEnter(parentRef) : undefined)}
      onMouseLeave={mouseOver ? handleMouseLeave : undefined}
    >
      {renderLink(handleLinkClick, open)}
      { mouseOver ? (
        <Popper
          open={open}
          anchorEl={anchorEl}
          className={classes.popper}
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: 'window',
            },
          }}
          disablePortal={false}
          placement="right"
        >
          {open && (
          <ClickAwayListener onClickAway={handleClickaway}>
            <Card style={overrideCardStyles} className={classes.card}>
              {renderPopoverContent(handleLinkClick)}
            </Card>
          </ClickAwayListener>
          )}
        </Popper>
      ) : (
        <Popper open={open} anchorEl={anchorEl} className={classes.popper}>
          {open && (
          <ClickAwayListener onClickAway={handleClickaway}>
            <Card style={overrideCardStyles} className={classes.card}>
              {renderPopoverContent(handleLinkClick)}
            </Card>
          </ClickAwayListener>
          )}
        </Popper>
      )}
    </span>
  );
}

export default PopoverLink;
