import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import map from "lodash/map";
import AccountMultiple from "mdi-material-ui/AccountMultiple";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { extractKeyFromPath } from "../HeaderPrimary/NavigationTabs/constants";

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: 25,
    paddingRight: 10,
    color: "#AEBABF"
  }
}));

function HeaderListLinks({
  items, history, handleMenuClick
}) {
  const [value, setValue] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setValue(extractKeyFromPath(history.location.pathname));
  }, [history.location.pathname]);

  return (
    <List indicatorColor="primary" value={value || false}>
      {map(items, ({
        key, label, icon, handleClick
      }) => {
        return (
          <ListItem
            button
            key={key}
            onClick={
              handleClick
                ? () => { handleClick(history); handleMenuClick(); }
                : () => { history.push(`/${key}`); handleMenuClick(); }
            }
          >
            {icon && (
              <Icon className={classes.icon}>
                {icon === "account_multiple" ? <AccountMultiple /> : icon}
              </Icon>
            )}
            <ListItemText
              primary={label}
              primaryTypographyProps={{ style: { fontSize: 25 } }}
            />
          </ListItem>
        );
      })}
    </List>
  );
}

export default withRouter(HeaderListLinks);
