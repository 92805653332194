import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from "react-redux";

const drawerWidth = 193;

const styles = () => ({
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
    backgroundColor: "#008dae",
    height: "100%",
    minHeight: "calc(100vh - 135px)",
    borderRight: 0,
    overflow: "visible",
  },
});

let last_known_scroll_y_position = 0;
let last_known_scroll_x_position = 0;
let ticking = false;

class PageLeftDrawer extends Component {
  state = {
    sticky: false,
    offsetLeft: -last_known_scroll_x_position,
  };

  stickyCheck = (scrollY) => {
    if (scrollY > 135 && !this.state.sticky) {
      this.setState(() => ({ sticky: true }));
    } else if (scrollY <= 135 && this.state.sticky) {
      this.setState(() => ({ sticky: false }));
    }
  };

  checkAndStopTicking = () => {
    this.setState({ offsetLeft: -last_known_scroll_x_position });
    this.stickyCheck(last_known_scroll_y_position);
    ticking = false;
  };

  tickingRequestFrames = (e) => {
    last_known_scroll_y_position =
      window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;

    last_known_scroll_x_position =
      window.pageXOffset ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollLeft;

    if (!ticking) {
      window.requestAnimationFrame(this.checkAndStopTicking);
      ticking = true;
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.tickingRequestFrames);
  }

  componentWillUnmount() {
    window.addEventListener("scroll", this.tickingRequestFrames);
  }

  render() {
    const { classes, visible, content } = this.props;
    const { sticky, offsetLeft } = this.state;

    if (visible) {
      return (
        <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }}>
          <div
            style={
              sticky
                ? {
                  width: 193,
                  position: "fixed",
                  top: 0,
                  left: offsetLeft,
                  paddingTop: 24,
                }
                : { paddingTop: 24 }
            }
          >
            {content}
          </div>
        </Drawer>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  visible: state.ui.leftDrawer.visible,
  content: state.ui.leftDrawer.content,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PageLeftDrawer));
