import React from 'react';
import { Row } from "react-grid-system";

function ExampleComponent() {
  return (
    <Row
      style={{
        justifyContent: "space-between",
        alignItems: "center",
        height: "72px",
        margin: "0",
        backgroundColor: "red",
        padding: "0 24px",
      }}
    >
      EXAMPLE FEATURE FLAG ENABLED
    </Row>
  );
}

export default ExampleComponent;
