import { withStyles } from "@material-ui/core/styles";
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import StatisticsIcon from "@material-ui/icons/BarChart";
import ProfileIcon from "mdi-material-ui/Account";
import JobcastIcon from "mdi-material-ui/Briefcase";
import FeesIcon from "mdi-material-ui/CurrencyUsd";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import { setProfileDrawerHeaderText } from "../../../../../../actions/shared/uiActions";
import { getCurrentOrganization } from "../../../../../../selectors/organizationSelectors";
import { getInsuranceRequirements } from "../../../../../../selectors/settingSelectors";
import IconTabs from "../../../../../forms/custom/IconTabs";
import AgencyDrawerHeader from "../../shared/AgencyDrawerHeader";

import {
  JOBCASTS_TAB,
  FEES_TAB,
  STATISTICS_TAB,
  PROFILE_TAB,
  CONTRACT_WORK_TAB
} from "../../shared/constants";
import ContractWorkTab from "../../shared/tabs/ContractWorkTab/ContractWorkTab";
import JobCastsTab from "../../shared/tabs/JobCastsTab/JobCastsTab";
import ProfileTab from "../../shared/tabs/ProfileTab";
import StatisticsTab from "../../shared/tabs/StatisticsTab/StatisticsTab";

import FeesTab from "./tabs/FeesTab/FeesTab";

const style = (theme) => ({
  headerContainer: {
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("900")]: {
      width: 900,
    },
  },
  tabContent: {
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down("900")]: {
      width: 900,
    },
  },
  tabContainer: {
    height: "100%",
  },
});

class LegacyDrawer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      tabValue: props.tabSelection || JOBCASTS_TAB,
    };
  }

  componentDidMount() {
    const { setProfileDrawerHeaderText } = this.props;

    setProfileDrawerHeaderText("Preferred Agency Information");
  }

  handleTabChange = (_event, value) => {
    this.setState({ tabValue: value });
  };

  renderHeaderContent() {
    const { classes, agency } = this.props;
    const { contractActivated } = this.props.currentOrganization;
    const tempEnabled = this.props.agency?.preferredLink?.attributes?.tempEnabled ?? false;

    const tabItems = [
      {
        icon: <JobcastIcon />,
        label: "JobCasts",
      },
      { icon: <FeesIcon />, label: "Fees", disabled: false },
      { icon: <StatisticsIcon />, label: "Statistics", disabled: false },
      {
        icon: <ProfileIcon />,
        label: "Profile",
        disabled: false,
      },
    ];

    if (contractActivated && tempEnabled) {
      tabItems.push({
        icon: <AssignmentOutlinedIcon />,
        label: "Contract Work",
        disabled: false,
      });
    }

    return (
      <div className={classes.headerContainer}>
        <AgencyDrawerHeader
          agency={agency}
          handleTabChange={this.handleTabChange}
        />
        <IconTabs
          items={tabItems}
          style={{ width: 900 }}
          value={this.state.tabValue}
          handleChange={this.handleTabChange}
        />
      </div>
    );
  }

  renderTabContent() {
    const { classes, agency } = this.props;

    switch (this.state.tabValue) {
      case JOBCASTS_TAB:
      default:
        return (
          <div>
            <JobCastsTab agency={agency} />
          </div>
        );
      case FEES_TAB:
        return (
          <div className={classes.tabContainer}>
            <FeesTab agency={agency} />
          </div>
        );
      case STATISTICS_TAB:
        return <StatisticsTab agency={agency} />;
      case PROFILE_TAB:
        return <ProfileTab agency={agency} />;
      case CONTRACT_WORK_TAB:
        return <ContractWorkTab agency={agency} />;
    }
  }

  render() {
    return (
      <div
        className="drawer-content-container"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowY: "auto",
          height: "100%",
        }}
      >
        {this.renderHeaderContent()}
        <div className={this.props.classes.tabContent} data-cy="legacy-drawer-tab-content">
          {this.renderTabContent()}
        </div>
      </div>
    );
  }
}

LegacyDrawer.propTypes = {
  agency: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentOrganization: getCurrentOrganization(state),
  insuranceRequirements: getInsuranceRequirements(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setProfileDrawerHeaderText: (text) => dispatch(setProfileDrawerHeaderText(text)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(style)(LegacyDrawer));
