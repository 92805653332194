import merge from "lodash/merge";

import { RECEIVE_STRIPE_ACCOUNT_INFO } from "../../actions/recruiters/financeActions";

const defaultState = {
  info: {},
  loaded: false
};

const stripeAccountReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_STRIPE_ACCOUNT_INFO:
      return merge({}, state, { info: action.payload, loaded: true });
    default:
      return state;
  }
};

export default stripeAccountReducer;
