import { KeyboardDatePicker } from "@material-ui/pickers";
import { useFormikContext } from "formik";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";

function FormContextDatePicker({ id, inputProps, ...passThroughProps }) {
  const {
    values, errors, touched, handleBlur, setFieldValue
  } = useFormikContext();

  const onChange = (moment) => setFieldValue(id, moment && moment.utc(new Date()).format("YYYY-MM-DD"));

  const value = get(values, id);

  const errorMessage = get(touched, id) && get(errors, id);

  return (
    <KeyboardDatePicker
      {...{ id, value, onChange }}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      onBlur={handleBlur}
      FormHelperTextProps={{
        style: { marginBottom: "-18px" },
        "data-cy": `${id}-helper-text`,
      }}
      format="MM/DD/YYYY"
      clearable
      inputProps={{
        "data-cy": `${id}-datepicker-input`,
        ...inputProps,
      }}
      {...passThroughProps}
    />
  );
}

FormContextDatePicker.defaultProps = {
  inputProps: {},
  label: "",
};

FormContextDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  inputProps: PropTypes.object,
  label: PropTypes.node,
};

export default FormContextDatePicker;
