import find from "lodash/find";
import { createSelector } from "reselect";

import { getAllRequests } from "../requestSelectors";

export const makeGetRequestByJobcastId = () => {
  const getJobCastId = (_state, jobcastId) => jobcastId;

  return createSelector(
    [getAllRequests, getJobCastId],
    (requests, jobcastId) => find(
      requests,
      request => request.attributes.jobcastId === jobcastId
    )
  );
};
