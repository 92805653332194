import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import MultiLineTextField from "../../../../../../../forms/custom/MultiLineTextField";
import StarRatingPanel from "../../../../../../../forms/custom/StarRatingPanel";
import GlobalAvatar from "../../../../../../../General/GlobalAvatar";

function RateRecruiter({ recruiter, reviewPreviouslySubmitted }) {
  const {
    setFieldValue,
    setFieldTouched,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const mobileView = useSelector((state) => state.ui.browser.lessThan.medium);

  const { individual, organization } = recruiter;

  const { avatarUrl, firstName, lastName } = individual.attributes;

  const descriptionText = reviewPreviouslySubmitted
    ? "You have already rated this agency recruiter for this JobCast. You can edit your rating and review or leave them as is."
    : "Rate your experience with this recruiter to help boost their public profile!";

  return (
    <>
      <h5>Rate Your Agency Recruiter</h5>
      <div style={{ marginTop: 16, marginBottom: 16 }}>{descriptionText}</div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            marginRight: 12,
            minWidth: 125,
            maxWidth: 150,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <GlobalAvatar
            individual={{
              avatarIcon: avatarUrl,
              firstName,
              lastName,
            }}
            size={80}
          />
          <div
            className="semibold"
            style={{ marginTop: 8 }}
          >
            {`${firstName} ${lastName}`}
          </div>
          <div>{`${organization.attributes.name}`}</div>
        </div>
        <div>
          <StarRatingPanel
            centered={mobileView}
            id="recruiterExperience"
            key="recruiterExperience"
            label="Rate your experience with this recruiter on this JobCast"
            error={Boolean(
              touched.recruiterExperience && errors.recruiterExperience
            )}
            helperText={
              touched.recruiterExperience && errors.recruiterExperience
            }
            onChange={(target) => setFieldValue("recruiterExperience", target.target.value)}
            onBlur={() => setFieldTouched("recruiterExperience", true)}
            value={values.recruiterExperience}
          />
          <MultiLineTextField
            error={Boolean(errors.recruiterReview && touched.recruiterReview)}
            helperText={touched.recruiterReview && errors.recruiterReview}
            label="Add a written review"
            onChange={handleChange}
            value={values.recruiterReview}
            id="recruiterReview"
            rows={3}
            onBlur={handleBlur}
            placeholder="They were great to work with on this JobCast. His candidates were all qualified and had excellent interviews."
          />
          <FormControlLabel
            control={(
              <Checkbox
                id="privateRecruiterRating"
                checked={values.privateRecruiterRating}
                onChange={(_e) => {
                  setFieldValue(
                    "privateRecruiterRating",
                    !values.privateRecruiterRating
                  );
                }}
                color="primary"
                data-cy="recruiter-experience-review-visibility-checkbox"
              />
            )}
            label={(
              <span>
                I would like to hide my name and company from this review
              </span>
            )}
            style={{
              paddingLeft: 3,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default RateRecruiter;
