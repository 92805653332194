import moment from "moment";
import React from "react";
import { Row } from "react-grid-system";

import { formatCurrency } from "../../../../../../util/formatHelpers";

import JobCastLocations from "../../../../../General/JobCastLocations";

import FormikTextField from "./FormikTextField";
import Section from "./Section";
import StyledField from "./StyledField";

const formatDate = (date) => moment(date).format("MMM D, YYYY");

function HireDetailsFields({
  hireStartDate,
  jobcastTitle,
  employerName,
  locations,
  guaranteeDate,
  adjustedRecruiterPayoutAmountCents,
  candidateName,
  payoutStatus,
  payoutMethod,
  payoutConversionRate,
  payoutConversionCurrency,
  invoiceNumber,
  editableInvoiceNumber = false,
  ...textFieldProps
}) {
  return (
    <Section title="Hire Details">
      <Row style={{ marginBottom: 20 }}>
        <StyledField label="Candidate Name" value={candidateName} />
        <StyledField label="Start Date" value={formatDate(hireStartDate)} />
        <StyledField label="Guaranteed Until" value={formatDate(guaranteeDate)} />
      </Row>
      <Row style={{ marginBottom: 20 }}>
        <StyledField label="Employer" value={employerName} />
        <StyledField label="JobCast" value={jobcastTitle} />
        <StyledField
          label="Location"
          value={<JobCastLocations locations={locations} />}
        />
      </Row>
      <Row>
        <StyledField
          label="Total Earnings"
          value={
          `${formatCurrency(adjustedRecruiterPayoutAmountCents, "USD")} USD`
        }
        />
        {payoutStatus === "processed" && payoutMethod && (
        <StyledField
          label="Amount Paid"
          value={
            `${formatCurrency(
              adjustedRecruiterPayoutAmountCents * payoutConversionRate,
              payoutConversionCurrency || "USD",
              { salaryConversion: true }
            )} ${payoutConversionCurrency || "USD"}`
          }
        />
        )}
        {editableInvoiceNumber ? (
          <FormikTextField
            {...textFieldProps}
            id="recruiterInvoiceNumber"
            label="Invoice Number"
          />
        ) : (
          <StyledField label="Invoice Number" value={invoiceNumber} />
        )}
      </Row>
    </Section>
  );
}

export default HireDetailsFields;
