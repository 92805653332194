import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { receiveCommunityCoinBalances } from "../../../../actions/recruiters/communityCoinActions";
import { getCurrentOrganization } from "../../../../selectors/organizationSelectors";
import ActionCableConsumer from "../ActionCableConsumer";

function CommunityCoinsConsumer() {
  const dispatch = useDispatch();
  const currentOrganization = useSelector(getCurrentOrganization);

  const handleReceived = useCallback(
    (data) => {
      dispatch(receiveCommunityCoinBalances(data));
    },
    [dispatch, currentOrganization.id]
  );

  const channelParams = useMemo(
    () => ({
      channel: "CommunityCoinsChannel",
      organization_id: currentOrganization.id,
    }),
    [currentOrganization.id]
  );

  return (
    <ActionCableConsumer channel={channelParams} onReceived={handleReceived} />
  );
}

export default CommunityCoinsConsumer;
