import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import classnames from "classnames";
import capitalize from "lodash/capitalize";
import merge from "lodash/merge";

import CommentQuestion from "mdi-material-ui/CommentQuestion";
import CommentQuote from "mdi-material-ui/CommentQuote";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchPositionCorrespondence } from "../../../../../actions/employers/jobcastUpdatesActions";
import { getPositionCorrespondence } from "../../../../../selectors/jobcastUpdatesSelectors";
import {
  makeGetFilteredNotifications,
  UNREAD,
} from "../../../../../selectors/notificationsSelectors";
import { trimTrailingNewlines } from "../../../../../util/formatHelpers";
import { disabledProps } from "../../../../../util/formHelpers";

import LoadingPage from "../../../../General/LoadingPage";
import withNotificationsReader from "../../../../shared/CandidateDrawer/CandidateDrawerTabs/withNotificationsReader";

import sharedStyles from "../../../../shared/NotificationsDrawer/NotificationViewTab/sharedStyles";

import { REPLY_ICONS, MATCH_QUESTION } from "./constants.js";
import QuestionActions from "./QuestionActions";

const styles = (theme) => merge({}, sharedStyles, {
  contentContainer: {
    backgroundColor: "#FFFFF",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 30,
    borderBottom: "1px solid #B0BEC5",
    paddingLeft: 24,
    paddingRight: 24,
  },
  questionContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 24,
    marginBottom: 12,
  },
  iconContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: 24,
  },
  icon: {
    color: "#90A4AE",
    fontSize: 48,
  },
  noMargin: {
    margin: 0,
  },
  answerContainer: {
    backgroundColor: "rgba(176, 190, 197, 0.08)",
    paddingRight: 24,
    paddingTop: 5,
    marginBottom: 5,
    borderLeft: "4px solid #90A4AE",
    display: "flex",
    flexDirection: "row",
    minHeight: 60,
  },
  replyIcon: {
    color: "#90A4AE",
    marginLeft: 20,
    marginRight: 20,
    fontSize: 24,
  },
  replyCaption: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 30,
    textAlign: "center",
  },
});

class PositionCorrespondence extends Component {
  state = { loading: true };

  customSubTextFieldProps = merge({}, disabledProps(true), {
    FormHelperTextProps: {
      classes: {
        root: classnames("caption", "gray", this.props.classes.noMargin),
      },
    },
    style: { marginBottom: 0 },
  });

  componentDidMount() {
    const { loading } = this.state;

    if (loading) {
      this.loadPositionCorrespondence();
    }
  }

  componentDidUpdate({
    positionCorrespondenceId: prevPositionCorrespondenceId,
  }) {
    const { positionCorrespondenceId } = this.props;

    if (prevPositionCorrespondenceId !== positionCorrespondenceId) {
      this.setState({ loading: true }, this.loadPositionCorrespondence);
    }
  }

  loadPositionCorrespondence() {
    const { fetchPositionCorrespondence } = this.props;

    fetchPositionCorrespondence().then(() => this.setState({ loading: false }));
  }

  renderAnswer() {
    const { classes, positionCorrespondence } = this.props;
    const { attributes: question } = positionCorrespondence;

    const helperText = () => (
      <div style={{ position: "absolute", top: "-5px" }}>
        {moment(question.answeredAt).format("MMMM Do, YYYY")}
      </div>
    );

    return (
      <div className={classes.answerContainer}>
        {this.renderReplyIcon()}
        <TextField
          {...this.customSubTextFieldProps}
          id={`answer-text-${question.id}`}
          label="Answer"
          helperText={helperText()}
          value={trimTrailingNewlines(question.answer)}
          multiline
        />
      </div>
    );
  }

  renderReplyIcon() {
    const { classes, positionCorrespondence } = this.props;
    const { attributes: question } = positionCorrespondence;

    return (
      <div className={classes.iconContainer}>
        <Icon classes={{ root: classes.replyIcon }}>
          {REPLY_ICONS[question.visibility].icon}
        </Icon>
        <div
          className={classnames("gray", "caption", classes.replyCaption)}
          style={{ position: "absolute", top: "26px", left: "19px" }}
        >
          <div>{capitalize(question.visibility)}</div>
          <div>Reply</div>
        </div>
      </div>
    );
  }

  renderQuestion() {
    const { positionCorrespondence, classes } = this.props;
    const { attributes: question } = positionCorrespondence;

    return (
      <div className={classes.questionContainer}>
        <div className={classnames(classes.iconContainer)}>
          {question.subjectType === MATCH_QUESTION ? (
            <>
              <CommentQuestion
                classes={{
                  root: classnames(classes.icon),
                }}
              />
              <div className={classnames("gray", "caption")}>Q & A</div>
            </>
          ) : (
            <>
              <CommentQuote
                classes={{
                  root: classnames(classes.icon),
                }}
              />
              <div className={classnames("gray", "caption")}>Feedback</div>
            </>
          )}
        </div>
        <TextField
          {...this.customSubTextFieldProps}
          id={`question-text-${question.id}`}
          label={
            question.subjectType === MATCH_QUESTION
              ? "Question"
              : "JobCast Feedback"
          }
          helperText={`${question.askedBy}, ${moment(question.createdAt).format(
            "MMMM Do, YYYY"
          )}`}
          value={trimTrailingNewlines(question.question)}
          multiline
        />
      </div>
    );
  }

  renderContent() {
    const { positionCorrespondence, jobcast, classes } = this.props;
    const { attributes: question } = positionCorrespondence;

    return (
      <div className={classes.contentContainer}>
        {this.renderQuestion()}
        {question.answer ? (
          this.renderAnswer()
        ) : (
          <QuestionActions
            questionId={question.id}
            questionSubjectType={question.subjectType}
            jobcast={jobcast}
          />
        )}
      </div>
    );
  }

  render() {
    const { classes, positionCorrespondence } = this.props;
    const { loading } = this.state;

    return !loading &&
      positionCorrespondence &&
      positionCorrespondence.attributes.askedBy ? (
        <>
          <div className={classes.subheaderContainer}>
            <h5>
              {positionCorrespondence.attributes.subjectType === MATCH_QUESTION
                ? "JobCast Question"
                : "JobCast Feedback"}
            </h5>
          </div>
          {this.renderContent()}
        </>
      ) : (
        <LoadingPage />
      );
  }
}

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();

  const mapStateToProps = (state, { positionCorrespondenceId }) => ({
    positionCorrespondence: getPositionCorrespondence(
      state,
      positionCorrespondenceId
    ),
    unreadNotifications: getFilteredNotifications(state, {
      [UNREAD]: true,
      positionCorrespondenceId,
    }),
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchPositionCorrespondence: () => dispatch(fetchPositionCorrespondence(ownProps.positionCorrespondenceId)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withNotificationsReader(PositionCorrespondence)));
