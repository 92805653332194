export const RECEIVE_PREFERRED_LINKS = "RECEIVE_PREFERRED_LINKS";
export const RECEIVE_PREFERRED_LINK = "RECEIVE_PREFERRED_LINK";
export const REMOVE_PREFERRED_LINK = "REMOVE_PREFERRED_LINK";

export const receivePreferredLinks = preferredLinks => ({
  type: RECEIVE_PREFERRED_LINKS,
  payload: preferredLinks
});

export const receivePreferredLink = preferredLink => ({
  type: RECEIVE_PREFERRED_LINK,
  payload: preferredLink
});

export const removePreferredLink = preferredLinkId => ({
  type: REMOVE_PREFERRED_LINK,
  payload: preferredLinkId
});
