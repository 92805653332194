import normalize from "json-api-normalizer";

import { GET, PATCH } from "../../util/apiHelpers";

import {
  receivePlacements,
  receivePaymentNotifications,
  setPlacementsLoaded,
  receivePaymentMethod,
} from "../shared/financeActions";

export const RECEIVE_EARNINGS_SUMMARY = "RECEIVE_EARNINGS_SUMMARY";
export const RECEIVE_STRIPE_ACCOUNT_INFO = "RECEIVE_STRIPE_ACCOUNT_INFO";

export const receiveEarningsSummary = (payload, scope) => ({
  type: RECEIVE_EARNINGS_SUMMARY,
  payload,
  scope,
});

export const receiveStripeAccountInfo = (payload) => ({
  type: RECEIVE_STRIPE_ACCOUNT_INFO,
  payload
});

export const fetchPlacements = () => (dispatch) =>
  GET(`/api/v3/recruiter/placements`).then((res) => {
    const normalized = normalize(res.data);

    dispatch(receivePlacements(normalized.placement));
    dispatch(setPlacementsLoaded(true));
  });

export const fetchPlacement = (id) => (dispatch) =>
  GET(`/api/v3/recruiter/placements/${id}`).then((res) => {
    const normalized = normalize(res.data);

    dispatch(receivePlacements(normalized.placement));
  });

export const updateInvoiceNumber = (id, data) => (dispatch) =>
  PATCH(`/api/v3/recruiter/placements/${id}/update_invoice_number`, data)
    .then((res) => {
      const normalized = normalize(res.data);

      return dispatch(receivePlacements(normalized.placement));
    })
    .catch((error) => Promise.reject(error.response.data));

export const fetchPaymentNotifications = () => (dispatch) =>
  GET("/api/v3/recruiter/payment_method/payment_notifications_index").then(
    (res) => {
      const normalized = normalize(res.data);

      dispatch(receivePaymentNotifications(normalized.paymentNotification));
    }
  );

export const fetchPaymentMethod = () => (dispatch) =>
  GET("/api/v3/recruiter/payment_method")
    .then(({ data }) =>
      dispatch(
        receivePaymentMethod({
          paymentIdentity: data.payment_identity,
          tipaltiIframeURL: data.tipalti_iframe_url,
          agencyPayable: data.agency_payable,
        })
      )
    )
    .catch(() => dispatch(receivePaymentMethod({ error: true })));

export const fetchEarningsSummary = (scope) => (dispatch) => {
  const queryStr = scope ? `?scope=${scope}` : "";

  const url = `/api/v3/recruiter/organization/earnings_summary${queryStr}`;

  return GET(url)
    .then((res) => dispatch(receiveEarningsSummary(res.data, scope || "all")))
    .catch((error) => Promise.reject(error.response.data));
};

export const fetchStripeAccount = () => (dispatch) => {
  const url = `/api/v3/recruiter/organization/stripe_account_info`;

  return GET("/api/v3/recruiter/stripe_account")
    .then((res) => {
      return dispatch(receiveStripeAccountInfo(res.data))
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
  });
}
