import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";

import MailboxOpenUpOutline from "mdi-material-ui/MailboxOpenUpOutline";
import React from "react";

const styles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    padding: 24,
    paddingBottom: 0,
    height: "calc(100% - 127px)"
  },
  textContainer: {
    textAlign: "center"
  }
});

function EmptyNotifications({ classes, text }) {
  return (
    <div className={classes.container}>
      <Icon style={{ fontSize: 75, color: "#90A4AE" }}>
        <MailboxOpenUpOutline />
      </Icon>
      <div className={classnames(classes.textContainer)}>
        {text || "There are no unread alerts to display"}
      </div>
    </div>
  );
}

export default withStyles(styles)(EmptyNotifications);
