import { Icon } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import classnames from "classnames";
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getCurrentIndividual } from '../../../../../../selectors/individualSelectors';

import { getPaymentMethod, makeGetPlacementItem } from '../../../../../../selectors/recruiter/financeSelectors';

import { FAILED, PROCESSED } from '../../earnings/PayoutsTable/tableHelpers';

import Section from "./Section";

const date_format = "MMM. D, YYYY";

const notCollected = (collectionAt) => !collectionAt;
const guaranteePeriod = (guaranteeDate) => (moment().isSameOrBefore(moment(guaranteeDate), "D"));
const noPayment = ({ loaded, items: { agencyPayable } }) => (loaded && !agencyPayable);

function Line({ width = "60%", color = "gray" }) {
  return (
    <div
      className={color}
      style={{
        width: `calc(${width} - 24px)`,
        display: "inline-block",
        height: "24px"
      }}
    >
      <div
        style={{
          borderBottom: `1px solid`,
          height: "calc(50% + 1px)"
        }}
      />
    </div>
  );
}

function TextField({ title, text, italic = true }) {
  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        width: "82%"
      }}
    >
      <div className={classnames("bold")} style={{ marginBottom: 4, whiteSpace: "pre-line" }}>
        {title}
      </div>
      {text && <div className={classnames({ italic }, "small")} style={{ whiteSpace: "pre-line" }}>{text}</div>}
    </div>
  );
}

function IconCollected({ collectionAt, style }) {
  return (
    <Icon
      {...{ style }}
      className={notCollected(collectionAt) ? "gray-light" : "primary-blue"}
    >
      <MonetizationOnIcon />
    </Icon>
  );
}

function IconGuaranteePeriod({ guaranteeDate, style }) {
  return (
    <Icon
      {...{ style }}
      className={guaranteePeriod(guaranteeDate) ? "gray-light" : "primary-blue"}
    >
      <WatchLaterIcon />
    </Icon>
  );
}

function IconPayment({ paymentMethod, style }) {
  return (noPayment(paymentMethod)
    ? (
      <Icon
        {...{ style }}
        className="red"
      >
        <CancelIcon />
      </Icon>
    ) : (
      <Icon
        {...{ style }}
        className="primary-blue"
      >
        <CheckCircleIcon />
      </Icon>
    )
  );
}

function CollectedText({ collectionAt, invoiceDate, invoiceSendDate }) {
  if (notCollected(collectionAt)) {
    const invoiceText = (invoiceDate !== null)
      ? `Invoice sent\n${moment(invoiceDate).format(date_format)}`
      : `Invoice sending\n${moment(invoiceSendDate).format(date_format)}`;
    return <TextField title={`Awaiting Collection\nfrom Employer`} text={invoiceText} />;
  }
  return <TextField title={`Collected from\nEmployer`} text={moment(collectionAt).format(date_format)} />;
}

function GuaranteeText({ guaranteeDate }) {
  return (guaranteePeriod(guaranteeDate)
    ? <TextField title={`Still Within the\nGuarantee Period`} text={`Until ${moment(guaranteeDate).format(date_format)}`} />
    : <TextField title={`Completed the\nGuarantee Period`} text={moment(guaranteeDate).format(date_format)} />
  );
}

function PaymentText({ paymentMethod }) {
  const { financeManager } = useSelector(getCurrentIndividual);

  const paymentWarning = () => {
    const { loaded, error, items } = paymentMethod;

    if (!loaded) {
      return <span>Loading Payment Information</span>;
    }

    if (error || (loaded && isEmpty(items))) {
      return <span>There was an issue loading your payout details.</span>;
    }

    return (financeManager) ? (
      <span>
        {"Please update in "}
        <Link rel="noopener noreferrer" to="/payouts/payout_information">Payout Information</Link>
      </span>
    ) : (
      <span>
        {"Please reach out to your Finance Manager. "}
        <Link rel="noopener noreferrer" to="/team">View your team</Link>
      </span>
    );
  };

  return (noPayment(paymentMethod)
    ? <TextField title={`Your Account Is Not Set\nUp to Receive Payments`} text={paymentWarning()} italic={false} />
    : <TextField title={`Your Company Is Set Up\nfor Payment`} text="Payment Method Verified" />
  );
}

function PayoutStatus({ placementId }) {
  const getPlacement = makeGetPlacementItem();
  const { attributes } = useSelector(state => getPlacement(state, placementId));
  const paymentMethod = useSelector(getPaymentMethod);

  const { payoutStatus } = attributes;
  if (payoutStatus !== PROCESSED || payoutStatus !== FAILED) {
    return (
      <Section title="Payout Status">
        <Grid container>
          {/* Icon Row */}
          <Grid item container>
            <Grid item xs={4}>
              <IconCollected
                {...attributes}
                style={{ paddingLeft: "calc(40% - 12px)", paddingRight: "12px" }}
              />
              <Line />
            </Grid>
            <Grid item xs={4}>
              <Line width="50%" />
              <IconGuaranteePeriod
                {...attributes}
                style={{ paddingLeft: "12px", paddingRight: "12px" }}
              />
              <Line width="50%" />
            </Grid>
            <Grid item xs={4}>
              <Line />
              <IconPayment
                {...{ paymentMethod }}
                style={{ paddingLeft: "12px", paddingRight: "calc(40% - 12px" }}
              />
            </Grid>
          </Grid>
          {/* Text Row */}
          <Grid item container>
            <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-start" }}>
              <CollectedText {...attributes} />
            </Grid>
            <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
              <GuaranteeText {...attributes} />
            </Grid>
            <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-end" }}>
              <PaymentText {...{ paymentMethod }} />
            </Grid>
          </Grid>
        </Grid>
      </Section>
    );
  }
  return (null);
}

export default PayoutStatus;
