import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  fetchOpenTransactions,
  fetchPlacement,
  updatePlacement,
} from "../../../../actions/employers/financeActions";
import { clearModal } from "../../../../actions/shared/uiActions";
import { getCandidate } from "../../../../selectors/candidateSelectors";
import { getCurrentOrganization } from "../../../../selectors/organizationSelectors";
import { makeGetPlacementItem } from "../../../../selectors/recruiter/financeSelectors";
import ActionModalContainer from "../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../General/ActionModal/ActionModalHeader";

import EditHireDetailsModalContents from "./EditHireDetailsModalContents";

import {
  buildInitialValues,
  validationSchema,
  handleSubmit,
} from "./fieldHelpers";

function EditHireDetailsModal({ placementId }) {
  const getPlacement = useMemo(() => makeGetPlacementItem(), []);
  const dispatch = useDispatch();

  const organization = useSelector((state) => getCurrentOrganization(state));
  const costCenters = useSelector((state) => state.costCenters.items);
  const placement = useSelector(
    (state) => getPlacement(state, placementId),
    [placementId]
  );
  const candidate = useSelector(
    (state) => getCandidate(
      state,
      placement && placement.attributes.recruiterSubmissionId
    ),
    [placement]
  );

  useEffect(() => {
    !placement && dispatch(fetchPlacement(placementId));
  }, [placementId, placement, dispatch]);

  const dispatchClearModal = () => dispatch(clearModal());
  const dispatchUpdatePlacement = (data) => dispatch(updatePlacement(placementId, data));
  const dispatchFetchOpenTransaction = () => dispatch(fetchOpenTransactions());

  return placement && candidate ? (
    <Formik
      validationSchema={validationSchema}
      initialValues={buildInitialValues(
        placement,
        candidate,
        organization,
        costCenters
      )}
      onSubmit={handleSubmit(
        dispatchFetchOpenTransaction,
        dispatchUpdatePlacement,
        dispatchClearModal,
        candidate
      )}
    >
      {({ dirty, handleSubmit, isSubmitting }) => (
        <ActionModalContainer color="blue">
          <ActionModalHeader
            title="Edit Hire Details"
            subtitle={`Invoice #${placement.attributes.invoiceNumber}`}
          />
          <ActionModalContent>
            <EditHireDetailsModalContents
              placement={placement}
              candidate={candidate}
            />
          </ActionModalContent>
          <ActionModalFooter
            disabled={!dirty}
            actionText="Submit"
            handleAction={handleSubmit}
            isSubmitting={isSubmitting}
          />
        </ActionModalContainer>
      )}
    </Formik>
  ) : null;
}

EditHireDetailsModal.propTypes = {
  placementId: PropTypes.string.isRequired,
};

export default EditHireDetailsModal;
