import keys from "lodash/keys";
import mapValues from "lodash/mapValues";
import merge from "lodash/merge";

import { RECEIVE_COMMUNITY_COIN_BALANCE } from "../actions/recruiters/communityCoinActions";
import {
  FAILED_LOGIN,
  RECEIVE_CURRENT_INDIVIDUAL,
  UPDATE_CURRENT_INDIVIDUAL,
  RECEIVE_REDIRECT_URL,
  RECEIVE_INDIVIDUALS,
  RECEIVE_VIEWING_AS,
} from "../actions/shared/individualActions";

import { meOption } from "../components/shared/Team/ChangeTeamMemberDropdown";
import { mergeWithArrayOverwrite } from "../util/formatHelpers";

const _defaultState = {
  viewing_as: meOption,
  currentIndividualId: null,
  items: {},
};

export const individualReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case FAILED_LOGIN:
      return _defaultState;
    case RECEIVE_CURRENT_INDIVIDUAL:
      const currentIndividualId = keys(action.payload)[0];
      return merge({}, state, {
        currentIndividualId,
        items: action.payload,
      });
    case UPDATE_CURRENT_INDIVIDUAL:
      return merge({}, state, {
        items: {
          [state.currentIndividualId]: {
            attributes: {
              activation_employer: action.payload.activation_employer,
            },
          },
        },
      });
    case RECEIVE_INDIVIDUALS:
      return mergeWithArrayOverwrite({}, state, { items: action.payload });
    case RECEIVE_VIEWING_AS:
      return merge({}, state, {
        viewing_as: action.payload || _defaultState.viewing_as,
      });
    case RECEIVE_REDIRECT_URL:
      return merge({}, state, {
        redirectURL: action.payload,
        urlLoaded: true,
      });
    case RECEIVE_COMMUNITY_COIN_BALANCE: {
      const itemsUpdate = mapValues(
        action.payload,
        (value) => ({ attributes: { communityCoinBalance: value } })
      );

      return merge({}, state, {
        items: itemsUpdate,
      });
    }
    default:
      return state;
  }
};
