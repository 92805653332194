import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { markNotificationsRead } from "../../../../actions/shared/notificationActions";

const withNotificationMarkedRead = BaseComponent => function ({ ...props }) {
  const {
    notification: {
      attributes: { id: notificationId, markedReadAt }
    }
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    !markedReadAt && dispatch(markNotificationsRead([notificationId]));
  }, [markedReadAt, dispatch, notificationId]);

  return <BaseComponent {...props} />;
};

export default withNotificationMarkedRead;
