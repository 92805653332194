import assign from "lodash/assign";

import { RECEIVE_OPEN_TRANSACTIONS } from "../../actions/employers/financeActions";

const _defaultState = { items: {} };

const transactionReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_OPEN_TRANSACTIONS:
      return assign({}, state, { items: action.payload });
    default:
      return state;
  }
};

export default transactionReducer;
