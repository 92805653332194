import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

import { openModal } from "../../../../../../../actions/shared/uiActions";
import Button from "../../../../../../forms/custom/Button";
import ReportFailureModal from "../modals/ReportFailureModal";

const styles = () => ({
  buttonContainer: {
    position: "relative"
  },
  buttonSubtext: {
    position: "absolute",
    right: "-20px",
    top: "37px",
    width: "100%"
  },
  statusContainer: {
    width: 145
  }
});

function ReportFailureButton({
  candidate,
  openModal,
  overrideStyle,
  drawerBtn,
  overloadedClasses,
  classes,
  displayTime,
  ...buttonProps
}) {
  const handleClick = () => openModal(<ReportFailureModal candidate={candidate} />);

  const { guaranteeFailure } = candidate.attributes;

  const hasGuaranteeFailureOption = !moment().isAfter(
    moment(candidate.attributes.guaranteeEndDate)
  );

  const renderButton = () => (
    <Button
      color="red"
      variant="primary"
      onClick={handleClick}
      style={overrideStyle || {}}
      overloadedClasses={overloadedClasses}
      data-cy="report-failure-button"
      {...buttonProps}
    >
      Report Failure
    </Button>
  );

  const renderAction = () => {
    const renderSubtext = () => (
      <div
        className="caption"
        style={{
          color: "#FF6D6D",
          textAlign: "center",
          paddingTop: 4,
          height: "100%"
        }}
      >
        <div>
          {`until ${moment(candidate.attributes.guaranteeEndDate).format(
            "MMM Do, YYYY"
          )}`}
        </div>
        {displayTime ? <div>at 10:59:59 GMT</div> : <></>}
      </div>
    );

    return (
      <div className={classes.buttonContainer}>
        {renderButton()}
        {renderSubtext()}
      </div>
    );
  };

  const renderStatus = statusText => (
    <div className={classes.statusContainer} data-cy="report-failure-status">
      <span className="bold">{statusText}</span>
      {' '}
      on
      {" "}
      {moment(guaranteeFailure.createdAt).format("MMM Do, YYYY")}
    </div>
  );

  if (guaranteeFailure.approvedAt) {
    return drawerBtn ? renderStatus("Guarantee failed") : <></>;
  } if (guaranteeFailure.createdAt) {
    return renderStatus("Failure reported");
  } if (hasGuaranteeFailureOption) {
    return renderAction();
  }
  return <></>;
}

const mapDispatchToProps = dispatch => ({
  openModal: content => dispatch(openModal(content))
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ReportFailureButton));
