import normalize from "json-api-normalizer";

import { GET } from "../../util/apiHelpers";

export const RECEIVE_INSURANCE_REQUIREMENT_AGREEMENTS = "RECEIVE_INSURANCE_REQUIREMENT_AGREEMENTS";
export const RECEIVE_INSURANCE_REQUIREMENT_AGREEMENT = "RECEIVE_INSURANCE_REQUIREMENT_AGREEMENT";

export const receiveInsuranceRequirementAgreements = (insuranceRequirementAgreements) => ({
  type: RECEIVE_INSURANCE_REQUIREMENT_AGREEMENTS,
  payload: insuranceRequirementAgreements,
});

export const receiveInsuranceRequirementAgreement = (insuranceRequirementAgreement) => ({
  type: RECEIVE_INSURANCE_REQUIREMENT_AGREEMENT,
  payload: insuranceRequirementAgreement,
});

export const getInsuranceRequirementAgreements = (state) => state.insuranceRequirementAgreements.items;

export const fetchInsuranceRequirementAgreements = () => (dispatch) => GET("/api/v3/recruiter/insurance_requirement_agreements").then((res) => {
  const normalizedData = normalize(res.data);
  return dispatch(receiveInsuranceRequirementAgreements(normalizedData.insuranceRequirementAgreement));
});
