import { TextField } from '@material-ui/core';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { useContext } from 'react';
import { Row, Col } from 'react-grid-system';

import { disabledProps } from '../../../../../../../../../util/formHelpers';
import FormContextDatePicker from '../../../../../../../../forms/formik/FormContextInputs/FormContextDatePicker';
import FeeOptionSelection from '../../../../../../../JobCasts/view/Candidates/actions/modals/Hire/FeeOptionSelection';
import SectionHeader from '../../../../../../../JobCasts/view/Candidates/actions/modals/Hire/SectionHeader';
import FieldSection from '../../../../../../../Shared/Modals/sections/FieldSection';
import { ContractContext, CandidateContext } from '../formHelpers';

function ConversionFee() {
  const contract = useContext(ContractContext);
  const candidate = useContext(CandidateContext);
  const formContext = useFormikContext();

  const {
    totalHours, candidateName, jobTitle, scheduledStartDate, scheduledEndDate,
  } = contract.attributes;

  const { agencyName } = candidate.attributes;

  const length = moment(scheduledEndDate).diff(moment(scheduledStartDate), 'days');

  return (
    <div className="body-default">
      <Row style={{ margin: "20px 24px" }}>
        <div>
          Complete this form to convert your contract worker to a full-time employee.
          Upon completion, the agency will be notified and an invoice will be sent to your
          AP department. This form is not submitted until all steps are complete.
        </div>
      </Row>
      <SectionHeader title="Contract Information" />
      <FieldSection>
        <Row>
          <Col xs={4}>
            <TextField
              {...disabledProps(true)}
              label="Worker"
              value={candidateName}
            />
          </Col>
          <Col>
            <TextField
              {...disabledProps(true)}
              label="Job Title"
              value={jobTitle}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              {...disabledProps(true)}
              label="Start Date"
              value={moment(scheduledStartDate).format("MMMM D, YYYY")}
            />
          </Col>
          <Col>
            <TextField
              {...disabledProps(true)}
              label="Length"
              value={`${length} days`}
            />
          </Col>
          <Col>
            <TextField
              {...disabledProps(true)}
              label="Hours Worked"
              value={parseFloat(totalHours)}
            />
          </Col>
        </Row>
      </FieldSection>
      <SectionHeader title="Contract Information" />
      <FieldSection>
        <Row>
          <Col>
            <TextField
              {...disabledProps(true)}
              label="Staffing Firm"
              value={agencyName}
            />
          </Col>
        </Row>
        <Row>
          <FeeOptionSelection
            label="Conversion Rate"
            {...{ candidate, ...formContext }}
            inputProps={{
              noShrink: true
            }}
          />
        </Row>
        <Row>
          <Col xs={4}>
            <FormContextDatePicker
              id="last_day"
              label="Last Day of Work"
              InputLabelProps={{ shrink: true }}
            />
          </Col>
        </Row>
      </FieldSection>
    </div>
  );
}

export default ConversionFee;
