import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";

import {
  MuiThemeProvider,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import classnames from "classnames";
import map from "lodash/map";
import PropTypes from "prop-types";
import React from "react";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      dark: "#66bace",
      main: "#008dae",
    },
    text: {
      primary: "#008dae",
      secondary: "#90A4AE",
    },
  },
  overrides: {
    MuiTab: {
      root: {
        flexShrink: "0 !important",
        minWidth: 160,
        "&$disabled": {
          color: "#90a4ae66 !important",
        },
      },
      labelIcon: {
        fontWeight: 600,
        letterSpacing: 1,
        fontSize: "14px !important",
        fontFamily: "'proxima-nova', helvetica",
      },
    },
  },
});

const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
  appbar: {
    boxShadow: "none",
    borderBottom: "1px solid #90A4AE",
  },
  tooltip: {
    margin: 0,
  },
  badge: {
    color: "#fff",
    backgroundColor: "#FF6D6D",
    height: 18,
    width: 18,
    minHeight: 18,
    minWidth: 18,
  },
  scroller: {
    marginBottom: "0px !important",
  },
});

function IconTabs({
  classes,
  style,
  items,
  handleChange,
  value,
  variant = "fullWidth",
}) {
  const renderTabs = (items) =>
    map(items, (item, idx) => {
      if (item.disabled) {
        return (
          <Tooltip
            title={item.tooltipText}
            classes={{ tooltipPlacementBottom: classes.tooltip }}
          >
            <div>
              <Tab
                key={`icontab${idx}`}
                icon={item.icon}
                label={item.label?.replace(/_/g, " ")}
                disabled
                data-cy={`tab-item-${item.label.toLowerCase()}`}
              />
            </div>
          </Tooltip>
        );
      }
      let { icon } = item;

      if (item.show_badge) {
        icon = (
          <Badge
            badgeContent={item.badge_count}
            classes={{ badge: classnames(classes.badge) }}
          >
            {icon}
          </Badge>
        );
      }

      return (
        <Tab
          key={`icontab${idx}`}
          icon={icon}
          label={item.label}
          disabled={item.disabled}
          data-cy={`tab-item-${item.label.toLowerCase()}`}
        />
      );
    });

  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root} style={style}>
        <AppBar position="static" color="inherit" className={classes.appbar}>
          <Tabs
            className="icon-tab"
            value={value}
            onChange={handleChange}
            variant={variant}
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            classes={{ scroller: classes.scroller }}
            centered={variant !== "scrollable"}
          >
            {renderTabs(items)}
          </Tabs>
        </AppBar>
      </div>
    </MuiThemeProvider>
  );
}

IconTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IconTabs);
