import Icon from "@material-ui/core/Icon";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchSearchResults as employerFetchSearchResults } from "../../../actions/employers/searchActions";
import { fetchSearchResults as recruiterFetchSearchResults } from "../../../actions/recruiters/searchActions";
import { clearSearchResults } from "../../../actions/shared/searchActions";

import EmptyResults from "./EmptyResults";
import SearchResultsIndex from "./SearchResultsIndex";

const styles = () => ({
  container: {
    flexGrow: 1,
    width: "100%",
    maxWidth: 400,
    borderRadius: 4,
    marginRight: 4,
    minWidth: 290,
  },
  searchBar: {
    position: "relative",
    maxWidth: 400,
    borderRadius: 4,
  },
  input: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#37474F",
    borderRadius: 4,
  },
  focusedInput: {
    color: "#008DAE",
  },
  searchIcon: {
    fontSize: 24,
    paddingLeft: 6,
    paddingRight: 6,
  },
});

const defaultState = {
  term: "",
  loading: null,
  resultsVisible: false,
  active: false,
};

class SearchBar extends PureComponent {
  constructor(props) {
    super(props);

    this.wrapper = React.createRef();
    this.state = defaultState;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleInputMouseDown);

    this.unlisten = this.props.history.listen(() => {
      this.setState(defaultState);
    });
  }

  componentDidUpdate(_prevProps, { term: prevTerm }) {
    const { term: currTerm } = this.state;

    if (prevTerm !== currTerm) {
      this.refetchResults();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleInputMouseDown);
    this.unlisten();
  }

  refetchResults = () => {
    this.timeOut && clearTimeout(this.timeOut);
    this.timeOut = setTimeout(() => {
      this.props.clearSearchResults();
      this.setState({ loading: true }, this.updateResults);
    }, 500);
  };

  updateResults = () => {
    const { fetchSearchResults } = this.props;
    const { term } = this.state;

    fetchSearchResults(term).then(() => this.setState({ loading: false }));
  };

  handleChange = (event) => {
    this.setState({ term: event.target.value });
  };

  handleInputMouseDown = (event) => {
    if (
      this.wrapper.current &&
      this.state.active &&
      !this.wrapper.current.contains(event.target)
    ) {
      this.setState({ active: false, resultsVisible: false });
    } else if (
      this.wrapper.current &&
      !this.state.active &&
      this.wrapper.current.contains(event.target)
    ) {
      this.setState({ active: true, resultsVisible: true });
    }
  };

  exitSearch = () => {
    this.setState({ term: "" }, () => {
      this.props.clearSearchResults();
    });
  };

  render() {
    const { results, classes, currentIndividual } = this.props;
    const { term, loading, resultsVisible } = this.state;
    const noResultsFound =
      this.state.term && loading === false && this.props.results.length === 0;
    const placeholder = currentIndividual.employer
      ? "Candidates, Agencies, and JobCasts"
      : "Search Candidates and JobCasts";

    return (
      <Paper className={classes.container}>
        <div ref={this.wrapper} className={classes.searchBar}>
          <Input
            className={classes.input}
            onChange={this.handleChange}
            id="search-term"
            value={term}
            disableUnderline
            placeholder={placeholder}
            startAdornment={<Icon className={classes.searchIcon}>search</Icon>}
            classes={{ focused: classes.focusedInput }}
            inputProps={{
              autoComplete: "off",
              style: { width: "100%", top: 12 },
              "data-cy": "global-searchbar",
            }}
          />
          {noResultsFound && resultsVisible ? (
            <EmptyResults />
          ) : (
            <SearchResultsIndex
              items={results}
              exitSearch={this.exitSearch}
              loading={loading}
              resultsVisible={resultsVisible}
              searching={!!term}
              currentIndividual={currentIndividual}
            />
          )}
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  results: state.search.items,
});

const mapDispatchToProps = (dispatch, { currentIndividual }) => ({
  fetchSearchResults: (term) =>
    dispatch(
      currentIndividual.employer
        ? employerFetchSearchResults(term)
        : recruiterFetchSearchResults(term)
    ),
  clearSearchResults: () => dispatch(clearSearchResults()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchBar))
);
