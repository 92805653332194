import map from "lodash/map";
import sortBy from "lodash/sortBy";
import { array, object, string } from "yup";

import { PERSONAL } from "./constants";

export const validationSchema = (props) => object().shape({
  name: string()
    .required("Cannot be blank")
    .max(255, "Must be less than 255 characters"),
  list_type: props.isTeamManager
    ? string().nullable().required("Cannot be blank")
    : string().nullable(),
  selected_agencies: array().when("community_inclusion", {
    is: false,
    then: array().min(1, "Must select at least one agency"),
  }),
});

export const determineValuesFromProps = ({ isTeamManager, recruiterList }) => {
  if (recruiterList) {
    return {
      name: recruiterList.attributes.name,
      list_type: recruiterList.attributes.listType,
      selected_agencies: recruiterList.attributes.subjectIds,
      community_inclusion: recruiterList.attributes.communityInclusion,
      userRemovedAgencies: [],
      userAddedAgencies: [],
    };
  }
  return {
    name: "",
    list_type: isTeamManager ? "" : PERSONAL,
    selected_agencies: [],
    community_inclusion: true,
    userRemovedAgencies: [],
    userAddedAgencies: [],
  };
};

export const createOption = (list, disabled = false) => ({ value: list.id, label: list.attributes.name, isDisabled: disabled });

export const createOptionGroup = (lists, label, disabled = false) => {
  if (lists.length === 0) return;

  const options = map(
    sortBy(lists, ({ attributes }) => attributes.name),
    (list) => createOption(list, disabled)
  );
  return {
    label,
    options,
  };
};
