import { withFormik } from "formik";
import React from "react";
import { connect } from "react-redux";

import { reactivateRequest, reactivateCommunity } from "../../../../actions/employers/requestActions";
import {
  clearModal,
  setProfileDrawerVisible,
} from "../../../../actions/shared/uiActions";
import ActionModalContainer from "../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../General/ActionModal/ActionModalHeader";
import JobCastLocations from "../../../General/JobCastLocations";
import withSnackbar from "../../../General/withSnackbar";

class RestoreAgencyRequestModal extends React.PureComponent {
  renderContent(agency) {
    return (
      <div className="body-default">
        <div>
          You are about to restore
          {" "}
          {agency.id === "RecruitiFi Community"
            ? `${agency.organization?.attributes?.name}`
            : `${agency.individual.attributes.name}`}
          {" "}
          to this JobCast.
        </div>
        <br />
        <div>
          This means they will no longer have access restricted to this JobCast. They can now submit candidates again.
        </div>
      </div>
    );
  }

  render() {
    const {
      agency, jobCast, handleSubmit, isSubmitting
    } = this.props;
    return (
      <ActionModalContainer color="blue">
        <ActionModalHeader
          title={`Restore ${agency.id === "RecruitiFi Community"
          ? 'Community'
          : 'Agency Recruiter'} to JobCast`}
          subtitle={(
            <JobCastLocations
              locations={jobCast.attributes.locations}
              preLocationText={`${jobCast.attributes.title} - `}
            />
          )}
        />
        <ActionModalContent>{this.renderContent(agency)}</ActionModalContent>
        <ActionModalFooter
          actionText="Submit"
          handleAction={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </ActionModalContainer>
    );
  }
}

const RestoreAgencyRequestModalForm = withFormik({
  handleSubmit: (_values, { props, setSubmitting }) => {
    const {
      clearModal,
      closeProfileDrawer,
      agency,
      jobCast,
      request_id,
      reactivateRequest,
      reactivateCommunity,
      snackbar,
    } = props;

    if (agency.id === "RecruitiFi Community") {
      reactivateCommunity(jobCast).then(() => {
        setSubmitting(false);
        snackbar.showMessage("Your request has been restored.");
      })
        .then(clearModal)
        .then(closeProfileDrawer)
        .catch(() => {});
    } else {
      reactivateRequest(
        request_id
      )
        .then(() => {
          setSubmitting(false);
          snackbar.showMessage("Your request has been restored.");
        })
        .then(clearModal)
        .then(closeProfileDrawer)
        .catch(() => {});
    }
  },
})(RestoreAgencyRequestModal);

const mapDispatchToProps = (dispatch) => ({
  clearModal: () => dispatch(clearModal()),
  reactivateRequest: (request_id) => dispatch(reactivateRequest(request_id)),
  reactivateCommunity: (jobCast) => dispatch(reactivateCommunity(jobCast)),
  closeProfileDrawer: () => dispatch(setProfileDrawerVisible(false)),
});

export default connect(
  null,
  mapDispatchToProps
)(withSnackbar()(RestoreAgencyRequestModalForm));
