import TextField from "@material-ui/core/TextField";
import React from "react";
import { Row, Col } from "react-grid-system";

import { disabledProps } from "../../../../util/formHelpers";
import FieldSection from "../../JobCasts/view/Candidates/actions/modals/Hire/FieldSection";
import SectionHeader from "../../JobCasts/view/Candidates/actions/modals/Hire/SectionHeader";

function PlacementDetails({ placement, jobcast, candidate }) {
  const { firstName, lastName } = candidate.attributes;
  const { workplaceCountryCode, workplaceLocale } = placement.attributes;
  const { title } = jobcast.attributes;

  return (
    <>
      <SectionHeader title="Placement Details" style={{ marginTop: 0 }} />
      <FieldSection>
        <Row>
          <Col xs={4}>
            <TextField
              {...disabledProps(true)}
              label={<div className="static-input-label">Candidate Name</div>}
              multiline
              value={`${firstName} ${lastName}`}
              inputProps={{ "data-cy": "hire-overview-agency-name" }}
            />
          </Col>
          <Col xs={4}>
            <TextField
              {...disabledProps(true)}
              label={<div className="static-input-label">JobCast Title</div>}
              multiline
              value={title}
              inputProps={{ "data-cy": "hire-overview-agency-name" }}
            />
          </Col>
          <Col xs={4}>
            <TextField
              {...disabledProps(true)}
              label={<div className="static-input-label">Location</div>}
              multiline
              value={
                workplaceLocale
                  ? `${workplaceLocale}, ${workplaceCountryCode}`
                  : workplaceCountryCode
              }
              inputProps={{ "data-cy": "hire-overview-agency-name" }}
            />
          </Col>
        </Row>
      </FieldSection>
    </>
  );
}

export default PlacementDetails;
