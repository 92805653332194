import queryString from "query-string";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";

import { calculateResponsiveState } from "redux-responsive";

import { processFeatureFlags } from "../actions/shared/featureFlagActions";
import { processIndividual, logout } from "../actions/shared/individualActions";
import { GET, PATCH } from "../util/apiHelpers";
import { storageAvailable } from "../util/storageHelpers";

function useAppInitialization() {
  const [loading, setLoading] = useState(true);
  const [cookies] = useCookies(["authToken"]);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      dispatch(calculateResponsiveState(window));
    };

    const tokenLogout = () => {
      dispatch(logout({ expired: true }));
    };

    const loadFeatureFlags = async () => {
      try {
        const res = await GET("/api/v3/authentication/feature_flags");
        processFeatureFlags(res.data, dispatch);
      } catch (error) {
        console.error("Error loading feature flags:", error);
      }
    };

    const redirectToLogin = () => {
      if (
        !window.location.pathname.match(/login/) &&
          !window.location.pathname.match(/public/)
      ) {
        window.location.href = "/app/login";
      }
    };

    const loadUser = async () => {
      try {
        const res = await GET("/api/v3/authentication/me");
        processIndividual(res.data, dispatch);
      } catch (error) {
        const { response } = error;
        if (response?.status === 401) {
          localStorage.removeItem("authToken");
          localStorage.removeItem("ghosting");
          redirectToLogin();
        }
      } finally {
        setLoading(false);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("token_expired", tokenLogout, { once: true });
    loadFeatureFlags();

    if (storageAvailable()) {
      const queryParams = queryString.parse(window.location.search);
      const { userToken, error, talk_id } = queryParams;

      if (cookies.authToken) {
        localStorage.setItem("authToken", cookies.authToken);
        document.cookie =
          "authToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      }

      if (userToken) {
        localStorage.setItem("authToken", userToken);
      }

      if (error) {
        localStorage.setItem("error", error);
      }

      if (talk_id && localStorage.getItem("authToken")) {
        PATCH("/api/v3/authentication/logout");
        localStorage.removeItem("authToken");
        localStorage.removeItem("ghosting");
      }

      if (localStorage.getItem("authToken")) {
        loadUser();
      } else {
        setLoading(false);
        redirectToLogin();
      }
    } else {
      setLoading(false);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("token_expired", tokenLogout);
    };
  }, [cookies.authToken, dispatch]);

  return loading;
}

export default useAppInitialization;
