import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { useSelector } from "react-redux";

import LoadingPage from "../../../../../../General/LoadingPage";
import RotatingEmptyState from "../../../../../../shared/EmptyState/RotatingEmptyState";

const tableStyles = makeStyles({
  root: {
    border: "1px solid #90A4AE",
    borderRadius: "4px",
    tableCell: {
      border: "1px solid #90A4AE",
    },
  },
  table: {
    minWidth: 650,
  },
  tableCellLabel: {
    border: "none",
    padding: "0",
    width: "100%",
  },
  tableCellLabelChecked: {
    border: "none",
    padding: "0",
    width: "100%",
  },
  tableContainer: {
    overflowX: "visible",
    width: "fit-content",
    marginLeft: 25
  },
  documentPdf: {
    color: "#37474F",
    fontSize: "16px",
    fontFamily: "proxima-nova",
    fontWeight: 400,
    lineHeight: "22px",
    letterSpacing: "0.5px",
    padding: "12px 0px 0px 0px",
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  documentPdfLink: {
    position: "relative",
    bottom: "4px"
  }
});

const StyledTableCell = withStyles(() => ({
  head: {
    border: "none",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#37474F",
    paddingBottom: "12px",
  },
  body: {
    border: "1px solid #90A4AE",
    color: "#37474F",
  },
}))(TableCell);

const InsuranceTable = ({ insuranceData }) => {
  const classes = tableStyles();

  const loading = useSelector((state) => state.insuranceRequirements.loading);

  return (
    <>
      {loading && <LoadingPage />}
      {!loading && insuranceData.length > 0 && (
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Policy Type</StyledTableCell>
                <StyledTableCell>Coverage Minimum</StyledTableCell>
                <StyledTableCell>Document</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {insuranceData.map(
                (coverage) => (
                  <TableRow key={coverage.id}>
                    <StyledTableCell component="th" scope="row">
                      {coverage.name}
                    </StyledTableCell>
                    <StyledTableCell>
                      {coverage.coverageDescription}
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className={classes.documentPdf}>
                        <a
                          href={coverage.document.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.documentPdfLink}
                        >
                          {coverage.document.name}
                        </a>
                      </div>
                    </StyledTableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!loading && insuranceData.length === 0 && <RotatingEmptyState />}
    </>
  );
};

export default InsuranceTable;
