import filter from "lodash/filter";
import { createSelector } from "reselect";

export const getConversations = state => state.conversations.items;

export const makeGetCandidateConversation = candidateId => createSelector(
  [getConversations],
  conversations => filter(conversations, ({ attributes }) => attributes.recruiterSubmissionId === candidateId)[0]
);

export const getCandidateConversation = (state, filter) => makeGetCandidateConversation(filter)(state);
