import { withFormik } from "formik";
import React from "react";
import { connect } from "react-redux";

import { deactivateRequest, deactivateCommunity } from "../../../../actions/employers/requestActions";
import {
  clearModal,
  setProfileDrawerVisible,
} from "../../../../actions/shared/uiActions";
import ActionModalContainer from "../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../General/ActionModal/ActionModalHeader";
import JobCastLocations from "../../../General/JobCastLocations";
import withSnackbar from "../../../General/withSnackbar";

class DeleteAgencyRequestModal extends React.PureComponent {
  renderContent(agency) {
    return (
      <div className="body-default">
        <div>
          You are about to remove
          {" "}
          {agency.id === "RecruitiFi Community"
            ? `${agency.organization?.attributes?.name}`
            : `${agency.individual.attributes.name}`}
          {" "}
          from this JobCast.
        </div>
        <br />
        <div>
          This means they will no longer be able to submit to this JobCast.
          However, they still will have access to their active candidates.
        </div>
      </div>
    );
  }

  render() {
    const {
      agency, jobCast, handleSubmit, isSubmitting
    } = this.props;

    return (
      <ActionModalContainer color="red">
        <ActionModalHeader
          title={`Remove ${agency.id === "RecruitiFi Community"
            ? 'Community'
            : 'Agency Recruiter'} from JobCast`}
          subtitle={(
            <JobCastLocations
              locations={jobCast.attributes.locations}
              preLocationText={`${jobCast.attributes.title} - `}
            />
          )}
        />
        <ActionModalContent>{this.renderContent(agency)}</ActionModalContent>
        <ActionModalFooter
          actionText="Submit"
          handleAction={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </ActionModalContainer>
    );
  }
}

const DeleteAgencyRequestModalForm = withFormik({
  handleSubmit: (_values, { props, setSubmitting }) => {
    const {
      clearModal,
      closeProfileDrawer,
      agency,
      jobCast,
      request_id,
      deactivateRequest,
      deactivateCommunity,
      snackbar,
    } = props;
    if (agency.id === "RecruitiFi Community") {
      deactivateCommunity(jobCast).then(() => {
        setSubmitting(false);
        snackbar.showMessage("Your request has been removed.");
      })
        .then(clearModal)
        .then(closeProfileDrawer)
        .catch(() => {});
    } else {
      deactivateRequest(
        request_id
      )
        .then(() => {
          setSubmitting(false);
          snackbar.showMessage("Your request has been removed.");
        })
        .then(clearModal)
        .then(closeProfileDrawer)
        .catch(() => {});
    }
  },
})(DeleteAgencyRequestModal);

const mapDispatchToProps = (dispatch) => ({
  clearModal: () => dispatch(clearModal()),
  deactivateRequest: (request_id) => dispatch(deactivateRequest(request_id)),
  deactivateCommunity: (jobCast) => dispatch(deactivateCommunity(jobCast)),
  closeProfileDrawer: () => dispatch(setProfileDrawerVisible(false)),
});

export default connect(
  null,
  mapDispatchToProps
)(withSnackbar()(DeleteAgencyRequestModalForm));
