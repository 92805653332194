import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

import {
  RECEIVE_TEMP_PREREQUISITES,
  RECEIVE_TEMP_PREREQUISITE,
} from "../actions/employers/tempPrerequisiteActions";

const _defaultState = {
  items: {},
  loaded: false,
};

const tempPrerequisiteReducer = (state = _defaultState, action) => {
  Object.freeze(state);
  let newItems;

  switch (action.type) {
    case RECEIVE_TEMP_PREREQUISITE:
      newItems = cloneDeep(state.items);
      newItems[action.payload.id] = merge({}, action.payload, { loaded: true });
      return { ...state, items: newItems };
    case RECEIVE_TEMP_PREREQUISITES:
      return { items: action.payload, loaded: true };
    default:
      return state;
  }
};

export default tempPrerequisiteReducer;
