import PropTypes from "prop-types";
import React, { Component } from "react";

import { GET } from "../../../../../../../util/apiHelpers";
import { mapKeysToCamelCase } from "../../../../../../../util/formatHelpers";
import LoadingPage from "../../../../../../General/LoadingPage";

import AllTimeActivity from "./AllTimeActivity";
import CandidateFitRatings from "./CandidateFitRatings";
import CandidateRatings from "./CandidateRatings";

class StatisticsTab extends Component {
  state = { loaded: false, data: {} };

  componentDidMount() {
    const recruiterId = this.props.agency.individual.id;

    GET(
      `/api/v3/employer/agencies/${recruiterId}/recruiter_aggregate_statistics`
    ).then(({ data }) => {
      this.setState({ loaded: true, data: mapKeysToCamelCase(data) });
    });
  }

  renderContent() {
    const {
      candidateRatings,
      jobcastParticipationStats,
      candidateProgressStats,
      individualCandidateFitRatings,
      organizationCandidateFitRatings,
    } = this.state.data;

    const { isCommunity } = this.props;

    return (
      <>
        <CandidateRatings ratingsData={candidateRatings} />
        <AllTimeActivity
          jobcastParticipationStats={jobcastParticipationStats}
          candidateProgressStats={candidateProgressStats}
          isCommunity={isCommunity}
        />
        <CandidateFitRatings
          individual={individualCandidateFitRatings}
          organization={organizationCandidateFitRatings}
        />
      </>
    );
  }

  render() {
    const { loaded } = this.state;

    return loaded ? this.renderContent() : <LoadingPage />;
  }
}

StatisticsTab.propTypes = {
  agency: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};

export default StatisticsTab;
