import PackageDown from "mdi-material-ui/PackageDown";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import withNotificationMarkedRead from "../../../recruiter/NotificationsDrawer/NotificationViewTab/withNotificationMarkedRead";

import NotificationContent from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Content";
import Subheader from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";

function PositionArchived({ notification }) {
  const { messageBody, createdAt, metadata } = notification.attributes;

  return (
    <div>
      <Subheader headerText="JobCast Closed" />
      <NotificationContent
        iconImage={PackageDown}
        iconCaption="Closed"
        label="JobCast Closed"
        value={messageBody}
        helperText={moment(metadata?.archivedAt || createdAt).format(
          "MMMM Do, YYYY"
        )}
      />
    </div>
  );
}

PositionArchived.propTypes = {
  notification: PropTypes.object.isRequired,
};

export default withNotificationMarkedRead(PositionArchived);
