import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuIcon from '@material-ui/icons/Menu';
import isEmpty from "lodash/isEmpty";
import React, { useEffect, useState } from "react";
import { Row, Visible } from "react-grid-system";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setSubNav } from "../../../actions/shared/uiActions";
import { getFeatureFlags } from "../../../selectors/featureFlagSelector";
import { getCurrentIndividual } from "../../../selectors/individualSelectors";
import { getCurrentOrganization } from "../../../selectors/organizationSelectors";
import ExampleComponent from "../../General/ExampleComponent";

import MobileMenuDropdown from "../MobileHeader/MobileMenuDropdown";

import LogoAndSubNav from "./LogoAndSubNav";
import NavigationTabs from "./NavigationTabs/NavigationTabs";
import OrganizationLogo from "./OrganizationLogo";

import TalkSubNav from "./TalkSubNav";
import UserSection from "./UserSection/UserSection";

const useStyles = makeStyles(() => ({
  headerRow: {
    justifyContent: "space-between",
    alignItems: "center",
    height: "72px",
    margin: "0",
    backgroundColor: "#ffffff",
    padding: "0 24px",
    width: ({ isMobile }) => isMobile && "95vw",
    zIndex: ({ isMobile }) => isMobile && 1000,
    position: ({ isMobile }) => isMobile && "fixed",
  },
  headerDiv: {
    maxWidth: 1900,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  subNav: {
    display: "flex",
    justifyContent: ({ publicPage }) => publicPage && "space-between",
    alignItems: "center",
    width: ({ publicPage }) => publicPage && "100%",
  },
  userSection: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
  },
}));

const HeaderPrimary = (props) => {
  const {
    currentIndividual,
    loaded,
    location,
    setSubNav,
    publicRecruiterSubmission,
    publicPage,
    visible,
    featureFlags,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const isMobile = useMediaQuery("(max-width:750px)");
  const classes = useStyles({ publicPage, isMobile });

  const isPublicSignup = Boolean(
    location.pathname.match(
      /^\/public\/(sign-up|employer-registration|legacy-agency-activation|employer-direct-registration|talk-employer-registration)\/?.*$/
    )
  );

  const isTalkOnly =
    (currentIndividual?.agreedToTalkTerms &&
      !currentIndividual?.agreedToRfiTerms) ||
    location.pathname === "/talk-login" ||
    location.pathname === "/public/talk-agency-registration";

  useEffect(() => {
    if (isTalkOnly) {
      setSubNav(<TalkSubNav />);
    }
  }, [isTalkOnly, setSubNav]);

  useEffect(() => {
    document.body.style.overflow = menuOpen ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [menuOpen]);

  const renderTabLinks = () => {
    if (!visible || !currentIndividual || isEmpty(props.currentOrganization)) {
      return null;
    }

    if (currentIndividual?.agreedToRfiTerms) {
      return <NavigationTabs />;
    }

    return null;
  };

  if (featureFlags?.includes("example_feature_flag")) {
    return <ExampleComponent />;
  }

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  if (menuOpen) {
    return (
      <MobileMenuDropdown
        handleMenuClick={handleMenuClick}
        currentIndividual={currentIndividual}
      />
    );
  }

  return (
    <Row className={classes.headerRow}>
      <div className={classes.headerDiv}>
        <div className={classes.subNav}>
          <LogoAndSubNav
            isTalkOnly={isTalkOnly}
            isPublicSignup={isPublicSignup}
            loaded={loaded}
          />
          {publicPage && publicRecruiterSubmission && (
            <Visible xs sm>
              <OrganizationLogo publicRecruiterSubmission={publicRecruiterSubmission} />
            </Visible>
          )}
          {!isMobile && renderTabLinks()}
        </div>
        {!publicPage && (
          <div className={classes.userSection}>
            {!isMobile ? <UserSection /> : (
              <span onClick={handleMenuClick} role="button">
                {loaded && <MenuIcon style={{ width: 30, height: 30 }} />}
              </span>
            )}
          </div>
        )}
      </div>
    </Row>
  );
};

const mapStateToProps = (state) => {
  const currentIndividual = getCurrentIndividual(state);
  const currentOrganization = getCurrentOrganization(state);
  const featureFlags = getFeatureFlags(state);

  return {
    currentIndividual,
    currentOrganization,
    featureFlags,
    loaded: Boolean(currentIndividual),
    publicPage: state.ui.public,
    publicRecruiterSubmission: state.recruiterSubmissions.publicSubmission,
    visible: state.ui.headerMenu.visible,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSubNav: (content) => dispatch(setSubNav(content)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderPrimary)
);
