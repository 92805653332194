import merge from "lodash/merge";

import { RECEIVE_EARNINGS_SUMMARY } from "../../actions/recruiters/financeActions";

const _defaultState = {
  temp: { loaded: false, data: {} },
  perm: { loaded: false, data: {} },
  all: { loaded: false, data: {} },
};

const earningsSummaryReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_EARNINGS_SUMMARY:
      return merge({}, state, {
        [action.scope]: {
          loaded: true,
          data: action.payload,
        },
      });

    default:
      return state;
  }
};

export default earningsSummaryReducer;
