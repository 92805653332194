import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import accounting from "accounting";
import classnames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchEarningsSummary } from "../../actions/recruiters/financeActions";
import { getFeatureFlags } from "../../selectors/featureFlagSelector";
import { getCurrentIndividual } from "../../selectors/individualSelectors";

import { TEMP_SCOPE } from "../Header/HeaderPrimary/NavigationTabs/constants";

import TadMarketRfiModal from "./Activation/Modals/TadMarketRfiModal";

const styles = {
  earnings: {
    border: "1px solid #B0BEC5",
    borderRadius: "5px",
    fontWeight: 600,
    marginRight: "10px",
    padding: "4px 10px",
    listStyle: "none",
    textAlign: "center",
    "&:hover": {
      backgroundColor: "#EAF9FF",
      borderColor: "#B0BEC5",
      cursor: "pointer",
    },
  },
  textEarnings: {
    display: "block",
    textAlign: "center",
    color: "#37474F",
    fontSize: 14,
    fontWeight: 450,
  },
  label: {
    color: "#B0BEC5",
    fontWeight: 600,
    fontSize: 10,
    lineHeight: "8px",
    letterSpacing: "1px",
  },
};

class Earnings extends Component {
  state = {
    modalOpen: false,
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  fetchEarningsData = () => {
    if (!this.props.loaded) this.props.fetchEarningsSummary(this.props.scope);
  };

  componentDidMount() {
    this.fetchEarningsData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loaded && !this.props.loaded) {
      this.fetchEarningsData();
    }
  }

  handleClick = () => {
    if (
      this.props.currentIndividual?.agreedToTalkTerms &&
      !this.props.currentIndividual?.agreedToRfiTerms
    ) {
      this.setState({ modalOpen: true });
      return;
    }
    this.props.history.push(
      `/${
        this.props.scope === TEMP_SCOPE ? "contract_payouts" : "payouts"
      }/upcoming`
    );
  };

  render() {
    const formattedEarnings = accounting.formatMoney(
      this.props.totalEarnings,
      "$",
      0
    );

    const { loaded, classes } = this.props;

    return (
      <>
        <div onClick={this.handleClick} className={classes.earnings}>
          <Tooltip
            title={<span style={{ fontSize: "12px" }}>View all earnings</span>}
          >
            <div>
              <div className={classes.textEarnings}>
                {loaded ? formattedEarnings : <CircularProgress size={20} />}
              </div>
              <h2 className={classnames(classes.label)}>EARNINGS</h2>
            </div>
          </Tooltip>
        </div>
        <TadMarketRfiModal
          open={this.state.modalOpen}
          handleClose={this.handleClose}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const currentIndividual = getCurrentIndividual(state);

  const featureFlags = getFeatureFlags(state);

  const { scope } = state.ui.headerMenu;

  const { loaded, data } = state.finance.earningsSummary[scope];

  return {
    currentIndividual,
    featureFlags,
    scope,
    loaded,
    totalEarnings: data?.total?.amount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchEarningsSummary: (scope) => dispatch(fetchEarningsSummary(scope)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Earnings))
);
