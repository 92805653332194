import map from "lodash/map";

import {
  PERCENTAGE_OF_COMPENSATION,
} from "../../../forms/formik/FormikFeeOptions/fieldHelpers";

// feeType options
export const CUSTOM = "custom";
export const STANDARDIZED = "standardized";
export const OPTIMIZED = "optimized";

export const createBlankFeeOption = () => ({
  fee: "",
  fee_note: "",
  fee_type: PERCENTAGE_OF_COMPENSATION,
  currency: null,
});

export const createBlankPlacementScope = () => ({
  feeType: OPTIMIZED,
  feeOptions: [createBlankFeeOption()],
  multipleFees: false,
  positionAssignments: [],
});

export const buildInitialValues = ({ agencies, currentOrganization }) => ({
  agency: "",
  email: "",
  firstName: "",
  lastName: "",
  placementTypes: {
    perm: !currentOrganization.contractActivated,
    temp: false,
  },
  employerAbsorptionRate: 0.0,
  existingEmails: map(agencies, ({ individual, invitation }) =>
    individual ? individual.attributes.email : invitation.attributes.email),
  perm: createBlankPlacementScope(),
  ...(currentOrganization.contractActivated && {
    temp: createBlankPlacementScope(),
  }),
});
