import TextField from "@material-ui/core/TextField";
import moment from "moment";
import React from "react";
import { Row, Col } from "react-grid-system";

import { formatCurrency } from "../../../../../../../../util/formatHelpers";

import { disabledProps } from "../../../../../../../../util/formHelpers";

import FieldSection from "./FieldSection";
import SectionHeader from "./SectionHeader";

function WorkingInformation({ candidate }) {
  return (
    <>
      <SectionHeader title="Hire Information" style={{ marginTop: 0 }} />
      <FieldSection>
        <Row>
          <Col>
            <TextField
              {...disabledProps(true)}
              label="Guaranteed Compensation"
              value={`${formatCurrency(
                candidate.attributes.salaryCents,
                candidate.attributes.salaryCurrency
              )} ${candidate.attributes.salaryCurrency}`}
              inputProps={{ "data-cy": "working-information-guaranteed-compensation" }}
            />
          </Col>
          <Col>
            <TextField
              {...disabledProps(true)}
              label="Start Date"
              value={moment(candidate.attributes.hireStartDate).format("ll")}
              inputProps={{ "data-cy": "working-information-start-date" }}
            />
          </Col>
          <Col>
            <TextField
              {...disabledProps(true)}
              label="Guaranteed Until"
              value={moment(candidate.attributes.guaranteeEndDate).format(
                "ll"
              )}
              inputProps={{ "data-cy": "working-information-end-date" }}
            />
          </Col>
        </Row>
      </FieldSection>
    </>
  );
}

export default WorkingInformation;
