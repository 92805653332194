import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

import {
  RECEIVE_INSURANCE_REQUIREMENTS,
  RECEIVE_INSURANCE_REQUIREMENT,
} from "../actions/shared/insuranceRequirementActions";

const _defaultState = {
  items: {},
  loaded: false,
};

const insuranceRequirementReducer = (state = _defaultState, action) => {
  Object.freeze(state);
  let newItems;

  switch (action.type) {
    case RECEIVE_INSURANCE_REQUIREMENT:
      newItems = cloneDeep(state.items);
      newItems[action.payload.id] = merge({}, action.payload, { loaded: true });
      return { ...state, items: newItems };
    case RECEIVE_INSURANCE_REQUIREMENTS:
      return merge({}, state, { items: action.payload, loaded: true });
    default:
      return state;
  }
};

export default insuranceRequirementReducer;
