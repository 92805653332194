export const RECEIVE_LEGACY_INVITATIONS = "RECEIVE_LEGACY_INVITATIONS";
export const REMOVE_LEGACY_INVITATION = "REMOVE_LEGACY_INVITATION";

export const receiveLegacyInvitations = (legacyInvitations) => ({
  type: RECEIVE_LEGACY_INVITATIONS,
  payload: legacyInvitations,
});

export const removeLegacyInvitation = (id) => ({
  type: REMOVE_LEGACY_INVITATION,
  payload: id,
});
