import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

import {
  UPDATE_ANIMATIONS,
  CLEAR_ANIMATIONS,
  REMOVE_ANIMATION,
  REMOVE_ANIMATIONS
} from "../../actions/shared/uiActions";

const defaultState = {
  items: {}
};

export const animationsReducer = (state = defaultState, action) => {
  Object.freeze(state);

  let newState;
  switch (action.type) {
    case UPDATE_ANIMATIONS:
      return merge({}, state, { items: action.payload });
    case REMOVE_ANIMATION:
      newState = cloneDeep(state);
      delete newState.items[action.animationkey];
      return newState;
    case REMOVE_ANIMATIONS:
      newState = cloneDeep(state);
      action.animationkeys.forEach(key => {
        delete newState.items[key];
      });
      return newState;
    case CLEAR_ANIMATIONS:
      return defaultState;
    default:
      return state;
  }
};
