import { connect } from "formik";
import React from "react";

import MultiLineTextField from "../../../../forms/custom/MultiLineTextField";

function ViolationModalContent({ formik: props }) {
  return (
    <>
      <div>
        Please use this form to report an agency recruiter for behavior
        detrimental to the community. We take all reports very seriously and
        will use this information to ensure appropriate actions are taken. Your report is anonymous.
      </div>
      <br />
      <MultiLineTextField
        error={Boolean(props.errors.reason && props.touched.reason)}
        helperText={props.touched.reason && props.errors.reason}
        label="What is the reason for this report"
        onChange={props.handleChange}
        value={props.values.reason}
        id="reason"
        rows={3}
        onBlur={props.handleBlur}
        style={{ marginBottom: 0 }}
      />
    </>
  );
}

export default connect(ViolationModalContent);
