import Icon from "@material-ui/core/Icon";
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import debounce from "lodash/debounce";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Hidden, Visible } from "react-grid-system";
import { useDispatch, useSelector } from "react-redux";

import { fetchSearchResultsV2 as employerFetchSearchResults } from "../../../actions/employers/searchActions";
import { fetchSearchResultsV2 as recruiterFetchSearchResults } from "../../../actions/recruiters/searchActions";
import {
  clearSearchResults,
  updateTerm,
} from "../../../actions/shared/searchActions";
import { clearModal } from "../../../actions/shared/uiActions";
import { getSearchTerm } from "../../../selectors/searchSelectors";
import { getCurrentIndividual } from "../../../selectors/individualSelectors";

const useStyles = makeStyles({
  input: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#37474F",
    borderRadius: 4,
  },
  focusedInput: {
    color: "#008DAE",
  },
  searchIcon: {
    fontSize: 36,
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 14,
  },
});

function SearchTextInput({
  classes,
  handleChange,
  term,
  placeholder,
  offsetRight = 0,
}) {
  return (
    <Input
      autoFocus
      className={classes.input}
      onChange={handleChange}
      id="search-term"
      value={term}
      disableUnderline
      placeholder={placeholder}
      startAdornment={<Icon className={classes.searchIcon}>search</Icon>}
      classes={{ focused: classes.focusedInput }}
      inputProps={{
        autoComplete: "off",
        style: {
          width: "100%",
          top: 12,
          paddingBottom: 20,
          fontSize: 24,
          paddingRight: offsetRight,
        },
        "data-cy": "global-searchbar",
      }}
    />
  );
}

export default function SearchInput() {
  const currentIndividual = useSelector(getCurrentIndividual);
  const dispatch = useDispatch();
  const recruiter = currentIndividual?.recruiter;
  const entitySearchFunction = recruiter
    ? recruiterFetchSearchResults
    : employerFetchSearchResults;
  const fetchFunction = (term) => dispatch(entitySearchFunction(term));
  const setTerm = (term) => dispatch(updateTerm(term));

  const term = useSelector(getSearchTerm);

  const performFetch = useCallback(
    debounce((term) => fetchFunction(term), 300),
    []
  );

  const classes = useStyles();

  useEffect(() => {
    if (term && term.length > 2) {
      performFetch(term);
    }

    if (!term || (term && term.length === 0)) {
      dispatch(clearSearchResults());
    }
  }, [term, performFetch]);

  function handleChange(event) {
    setTerm(event.target.value);
  }

  function closeModal() {
    dispatch(clearModal());
  }

  return (
    <div
      style={{
        marginLeft: -8,
        marginBottom: 20,
        borderBottom: "1px solid #90A4AE",
      }}
    >
      <Hidden xs sm>
        <SearchTextInput
          placeholder={
            recruiter
              ? "Candidates and JobCasts"
              : "Candidates, Agencies, and JobCasts"
          }
          {...{ classes, handleChange, term }}
        />
      </Hidden>
      <Visible xs sm>
        <SearchTextInput
          placeholder="Search..."
          offsetRight={36}
          {...{ classes, handleChange, term }}
        />
        <CloseIcon
          style={{
            position: "absolute",
            right: "0",
            top: "0",
            height: "36px",
            width: "36px",
            cursor: "pointer",
          }}
          onClick={closeModal}
        />
      </Visible>
    </div>
  );
}
