import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";

import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";

import Button from "../../forms/custom/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#F5F5F5",
    minHeight: "70px",
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    minHeight: "70px",
  },
  buttonProgress: {
    position: "absolute",
    top: "20%",
    left: "50%",
    marginRight: "-12px",
    marginLeft: "-12px",
    "&$blue": {
      color: "#008dae",
    },
    "&$red": {
      color: "#ff6d6d",
    },
    "&$grey": {
      color: "#90A4AE",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("780")]: {
      paddingRight: 70,
    },
  },
  childrenContainer: {
    paddingLeft: "24px",
  },
  red: {},
  blue: {},
  grey: {},
}));

function ActionModalFooter({
  isSubmitting,
  color,
  actionText,
  customButtons,
  handleAction,
  submissionError,
  customErrorMessage,
  children,
  overrideErrorStyles = {},
  disabled = false,
  emptyActions,
  varient = "secondary"
}) {
  const classes = useStyles();
  const text = actionText || "SUBMIT";

  const renderAction = () => (
    <div className={classes.actionContainer}>
      {submissionError && (
        <div
          className="error"
          style={({
            whiteSpace: "pre-wrap",
            textAlign: "right",
            width: 340,
            margin: "auto 20px",
            ...overrideErrorStyles
          })}
          data-cy="action-modal-error"
        >
          {customErrorMessage || "Please review the fields highlighted in red."}
        </div>
      )}
      <div
        className={classes.buttonContainer}
        style={{ position: "relative", margin: "auto 24px" }}
      >
        {customButtons || (
          <Button
            color={color}
            variant={varient}
            onClick={handleAction}
            disabled={isSubmitting || disabled}
            data-cy={`action-modal-button-${text.toLowerCase()}`}
          >
            {text}
          </Button>
        )}
        {isSubmitting && (
          <CircularProgress
            size={24}
            className={classnames(classes.buttonProgress, classes[color])}
          />
        )}
      </div>
    </div>
  );

  return (
    <DialogActions classes={{ root: classes.root }}>
      <div className={classes.childrenContainer}>{children}</div>
      {!emptyActions && renderAction()}
    </DialogActions>
  );
}

export default ActionModalFooter;
