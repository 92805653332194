import { Formik } from "formik";
import omit from "lodash/omit";
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from "react-redux";

import { fetchCandidate } from "../../../../../../../../actions/employers/candidateActions";
import { convertContract } from "../../../../../../../../actions/employers/contractActions";
import { clearModal, openModal } from "../../../../../../../../actions/shared/uiActions";
import { makeGetCandidate } from "../../../../../../../../selectors/candidateSelectors";
import { makeGetFilteredContracts } from "../../../../../../../../selectors/contractSelector";
import ActionModalContainer from "../../../../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../../../../General/ActionModal/ActionModalContent";
import ActionModalHeader from "../../../../../../../General/ActionModal/ActionModalHeader";
import RateRecruiterModalContent from "../../../../../../JobCasts/view/Candidates/actions/modals/RateRecruiter/RateRecruiterModalContent";
import FormFooter from "../../../../../../Shared/Modals/FormFooter";
import FormStepProvider, { FormStepper } from "../../../../../../Shared/Modals/FormStepProvider";

import {
  CandidateContext,
  ContractContext,
  useInitialValues,
  validationSchema
} from './formHelpers';
import ConversionFee from './sections/ConversionFee';
import HireAndBilling from './sections/HireAndBilling';

const CONVERSION_FEE = "Conversion Fee";
const HIRE_AND_BILLING = "Hire and Billing";

const ALL_STEPS = [CONVERSION_FEE, HIRE_AND_BILLING];

const STEP_COMPONENTS = {
  [CONVERSION_FEE]: ConversionFee,
  [HIRE_AND_BILLING]: HireAndBilling,
};

const SECTION_FIELDS = {
  [CONVERSION_FEE]: { fee_option_id: true, last_day: true },
  [HIRE_AND_BILLING]: {
    currency: true,
    salary: true,
    hire_start_date: true,
    workplace_country_code: true,
    cost_center_id: true
  },
};

function ConvertContractForm({ candidate }) {
  const dispatch = useDispatch();
  const initialValues = useInitialValues(candidate);

  const onSubmit = useCallback(async (values, { setSubmitting, resetForm }) => {
    let submissionData = values;
    if (values.workplace_country_code !== "USA") {
      submissionData = omit(values, [
        "workplace_local",
        "workplace_postal_code",
      ]);
    }

    // modal closes after the convertContract resolves before getting to agency review content
    // so I open a new modal instead
    dispatch(convertContract(candidate.id, submissionData))
      .then(() => {
        dispatch(clearModal());
        dispatch(openModal(<RateRecruiterModal {...{ candidate }} />));
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [dispatch, candidate]);

  return (
    <Formik {...{ initialValues, validationSchema, onSubmit }}>
      <FormStepProvider
        allSteps={ALL_STEPS}
        stepComponents={STEP_COMPONENTS}
        sectionFields={SECTION_FIELDS}
      >
        {(stepComponent) => (
          <>
            <ActionModalContent>
              <FormStepper />
              {React.createElement(stepComponent)}
            </ActionModalContent>
            <FormFooter sectionFields={SECTION_FIELDS} finalCta="Convert to Perm" />
          </>
        )}
      </FormStepProvider>
    </Formik>
  );
}

function RateRecruiterModal({ candidate }) {
  const { firstName, lastName } = candidate.attributes;

  return (
    <ActionModalContainer color="blue">
      <ActionModalHeader
        title="Convert to Permanent Placement"
        subtitle={`${firstName} ${lastName}`}
      />
      <RateRecruiterModalContent
        candidateId={candidate.id}
        successMessage="Contract converted to permanent placement"
      />
    </ActionModalContainer>
  );
}

function ConvertContractModal({ candidateId }) {
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const getContracts = useMemo(makeGetFilteredContracts, []);
  const getCandidate = useMemo(makeGetCandidate, []);

  const candidate = useSelector((state) => getCandidate(state, candidateId));
  const openContracts = useSelector((state) => getContracts(
    state,
    { open: true, candidateId }
  ));

  useEffect(() => {
    // fetch full candidate, not enough data on contract serialized version
    dispatch(fetchCandidate(candidateId)).then(() => setLoaded(true));
  }, [dispatch, candidateId]);

  if (!loaded || openContracts.length === 0 || !candidate) {
    return null;
  }

  const { firstName, lastName } = candidate.attributes;

  return (
    <CandidateContext.Provider value={candidate}>
      <ContractContext.Provider value={openContracts[0]}>
        <ActionModalContainer color="blue">
          <ActionModalHeader
            title="Convert to Permanent Placement"
            subtitle={`${firstName} ${lastName}`}
          />
          <ConvertContractForm {...{ candidate }} />
        </ActionModalContainer>
      </ContractContext.Provider>
    </CandidateContext.Provider>
  );
}

export default ConvertContractModal;
