import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { usePrevious } from "../util/customHooks";
import { storageAvailable } from "../util/storageHelpers";

const RELEASE_TOKEN_KEY = "releaseToken";
const storeReleaseToken = (token) => {
  localStorage.setItem(RELEASE_TOKEN_KEY, token);
};

function useReleaseRefresh() {
  // release token representing the user's current bundle is stored in localStorage
  const prevReleaseToken = localStorage.getItem(RELEASE_TOKEN_KEY);
  // release token representing the server side bundle is stored in redux
  const currReleaseToken = useSelector((state) => state.releaseToken.currToken);

  const currPath = window.location.pathname;
  const prevPath = usePrevious(currPath);

  useEffect(() => {
    // if we're not yet tracking the user's release token, theyre on the currRelease so store it
    if (!prevReleaseToken && currReleaseToken) {
      storeReleaseToken(currReleaseToken);
    }
  }, [prevReleaseToken, currReleaseToken]);

  // both tokens exist and are different => updatedReleaseToken
  const updatedReleaseToken =
    prevReleaseToken &&
    prevReleaseToken !== currReleaseToken &&
    currReleaseToken;

  useEffect(() => {
    // if there's a new release, wait until route update to reload the page
    if (updatedReleaseToken && prevPath && currPath !== prevPath) {
      storeReleaseToken(updatedReleaseToken);
      window.location.reload();
    }
  }, [currPath, prevPath, updatedReleaseToken]);
}

function withReleaseRefresh(Component) {
  function ComponentWithReleaseRefresh(props) {
    useReleaseRefresh();

    return <Component {...props} />;
  }

  // implementation dependent on localStorage, bypass if not available
  return storageAvailable() ? ComponentWithReleaseRefresh : Component;
}

export default withReleaseRefresh;
