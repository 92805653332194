import get from "lodash/get";

import React from "react";

import { useSelector } from "react-redux";

import { getCurrentOrganization } from "../../../../../../../../selectors/organizationSelectors";

import PageCardRowHeader from "../../../../../../../General/PageCardRowHeader";
import FeePlacementTable from "../../../../shared/tabs/FeesTab/FeePlacementTable";

import {
  CreateFeeRow,
  RenderCommunityRates,
  RenderTempCommunityRates,
} from "./FeesTable";

const FeesTab = (props) => {
  const { preferredLink, individual } = props.agency;
  const { feeOptions, tempEnabled, tempFeeOptions } = preferredLink.attributes;

  const currentOrganization = useSelector(getCurrentOrganization);

  const contractActivatedForBoth =
    tempEnabled && currentOrganization.contractActivated;

  const renderFees = function () {
    return feeOptions.map((feeOption, idx) => (
      <div
        key={feeOption.id}
        style={{
          display: "flex",
          marginTop: 10,
          marginLeft: 32,
          marginBottom: 16,
        }}
      >
        {CreateFeeRow(feeOption, idx + 1, feeOptions)}
      </div>
    ));
  };

  const renderTempFees = function () {
    return tempFeeOptions.map((feeOption, idx) => (
      <div
        key={feeOption.id}
        style={{
          display: "flex",
          marginTop: 10,
          marginLeft: 32,
          marginBottom: 16,
        }}
      >
        {CreateFeeRow(feeOption, idx + 1, feeOptions)}
      </div>
    ));
  };

  const showCommunityRates =
    !preferredLink ||
    get(preferredLink, "attributes.optimizedFee") ||
    !get(feeOptions, "length");

  const showTempCommunityRates =
    !preferredLink ||
    get(preferredLink, "attributes.tempOptimizedFee") ||
    !get(tempFeeOptions, "length");

  const renderLegacyRates = () => (
    <div style={{ height: "auto" }}>
      <PageCardRowHeader
        title={
          contractActivatedForBoth
            ? "Permanent Placement Fee(s)"
            : "Placement Fee(s)"
        }
        style={{ textAlign: "left" }}
      />
      {renderFees()}
    </div>
  );

  const renderTempLegacyRates = () => (
    <div style={{ height: "auto" }}>
      <PageCardRowHeader
        title="Contract Conversion Fee(s)"
        style={{ textAlign: "left" }}
      />
      {renderTempFees()}
    </div>
  );

  return (
    <>
      {showCommunityRates
        ? RenderCommunityRates(contractActivatedForBoth, preferredLink)
        : renderLegacyRates()}
      {contractActivatedForBoth && (
        showTempCommunityRates
          ? RenderTempCommunityRates(preferredLink)
          : renderTempLegacyRates()
      )}
      <PageCardRowHeader
        title="Recent Placements"
        style={{ textAlign: "left" }}
      />
      {showCommunityRates ? (
        <FeePlacementTable recruiterId={individual.id} feeOptions={[]} />
      ) : (
        <FeePlacementTable
          recruiterId={individual.id}
          feeOptions={feeOptions}
        />
      )}
    </>
  );
};

export default FeesTab;
