export const UPDATE_BRANDS = "UPDATE_BRANDS";
export const UPDATE_TEAMMATES = "UPDATE_TEAMMATES";

export function updateBrands(arrayOfIds) {
  return ({
    type: UPDATE_BRANDS,
    payload: arrayOfIds,
  });
}

export function updateTeammates(arrayOfIds) {
  return ({
    type: UPDATE_TEAMMATES,
    payload: arrayOfIds,
  });
}
