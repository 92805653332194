import startCase from "lodash/startCase";
import moment from "moment";
import React from "react";

import {
  formatFeeOptionString,
} from "../../../../../../../../util/formatHelpers";
import PageCardRowHeader from "../../../../../../../General/PageCardRowHeader";
import OptimizedRatesIcon from "../../../../../../../shared/Icons/OptimizedRatesIcon";
import FieldLabel from "../../../../../../JobCasts/view/Description/sections/FieldLabel";
import {
  CommunityFeeStructure,
  TempCommunityFeeStructure,
} from "../../../community/tabs/FeesTab/FeesTab";

export const CreateFeeRow = function (feeOption, idx, feeOptions) {
  const { note, feeType, updatedAt } = feeOption;

  return (
    <>
      <div
        data-cy="fee-type-label"
        style={{ width: "100px", paddingRight: 24 }}
      >
        <FieldLabel name="Fee Type" />
        {`${startCase(feeType)}`}
      </div>
      <div style={{ width: "205px", paddingRight: 24 }}>
        <FieldLabel name="Permanent Placement Fee" />
        {formatFeeOptionString(feeOption)}
      </div>
      <div
        data-cy="agency-fee-note-label"
        style={{ width: note ? "400px" : "0px", paddingRight: 24 }}
      >
        {note ? (
          <>
            <FieldLabel name={feeOptions.length > 1 ? `Note ${idx}` : "Note"} />
            <span data-cy="agency-fee-note">
              {note}
            </span>
          </>
        ) : null}
      </div>
      {idx === 1 ? (
        <div data-cy="fee-last-updated-label" style={{ width: "150px" }}>
          <FieldLabel name="Last Updated" />
          <span data-cy="fee-last-updated">
            {moment(updatedAt).format("MMM Do, YYYY")}
          </span>
        </div>
      ) : (
        <div style={{ width: "150px" }} />
      )}
    </>
  );
};

export const RenderCommunityRates = (
  contractActivatedForBoth,
  preferredLink
) => (
  <div>
    <PageCardRowHeader
      title={`${contractActivatedForBoth ? "Permanent " : ""}Placement Fee(s)`}
      style={{ textAlign: "left" }}
    />
    <div
      style={{
        display: "flex",
        marginTop: 10,
        marginLeft: 32,
        marginBottom: 16,
      }}
    >
      <div
        data-cy="fee-type-label"
        style={{ width: "calc(100% - 150px)", paddingRight: 24 }}
      >
        <FieldLabel name="Fee Type" />
        <a
          href="https://help.recruitifi.com/en/articles/5485229-what-is-the-recruitifi-optimized-fee-and-how-does-it-work"
          target="_blank"
          rel="noopener noreferrer"
        >
          <OptimizedRatesIcon
            style={{ marginRight: 4, marginBottom: -6, fontSize: 24 }}
          />
          {`RecruitiFi ${
            contractActivatedForBoth ? "Permanent Placement " : ""
          }Optimized Fee`}
        </a>
      </div>
      <div data-cy="fee-last-updated-label" style={{ width: "150px" }}>
        <FieldLabel name="Last Updated" />
        <span data-cy="fee-last-updated">
          {moment(preferredLink.attributes.updatedAt).format("MMM Do, YYYY")}
        </span>
      </div>
    </div>
    <PageCardRowHeader
      title={`RecruitiFi Optimized ${
        contractActivatedForBoth ? "Permanent Placement " : ""
      }Fee Structure`}
      style={{ textAlign: "left" }}
    />
    <CommunityFeeStructure />
  </div>
);

export const RenderTempCommunityRates = (preferredLink) => (
  <div>
    <PageCardRowHeader
      title="Contract Conversion Fee(s)"
      style={{ textAlign: "left" }}
    />
    <div
      style={{
        display: "flex",
        marginTop: 10,
        marginLeft: 32,
        marginBottom: 16,
      }}
    >
      <div
        data-cy="fee-type-label"
        style={{ width: "calc(100% - 150px)", paddingRight: 24 }}
      >
        <FieldLabel name="Fee Type" />
        <a
          href="http://help.recruitifi.com/en/articles/8174039-recruitifi-optimized-rate-conversion-fees"
          target="_blank"
          rel="noopener noreferrer"
        >
          <OptimizedRatesIcon
            style={{ marginRight: 4, marginBottom: -6, fontSize: 24 }}
          />
          RecruitiFi Contract Optimized Fee
        </a>
      </div>
      <div data-cy="fee-last-updated-label" style={{ width: "150px" }}>
        <FieldLabel name="Last Updated" />
        <span data-cy="fee-last-updated">
          {moment(preferredLink.attributes.updatedAt).format("MMM Do, YYYY")}
        </span>
      </div>
    </div>
    <PageCardRowHeader
      title="RecruitiFi Optimized Contract Fee Structure"
      style={{ textAlign: "left" }}
    />
    <TempCommunityFeeStructure />
  </div>
);
