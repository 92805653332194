import every from "lodash/every";
import filter from "lodash/filter";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import get from "lodash/get";
import groupBy from "lodash/groupBy";

import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import merge from "lodash/merge";
import { createSelector } from "reselect";

import createDeepEqualSelector from "../createDeepEqualSelector";

import { individualsSelector } from "../individualSelectors";
import { getAllOrganizations } from "../organizationSelectors";

export const SCOPE_ACTIVE = "SCOPE_ACTIVE";
export const SCOPE_PENDING = "SCOPE_PENDING";
export const SCOPE_SELECTED = "SCOPE_SELECTED";
export const SCOPE_FULLY_LOADED = "SCOPE_FULLY_LOADED";

export const getPreferredLinks = (state) => state.preferredLinks.items;

// todo: move this elsewhere, could be used for recruiters?
export const getAllLegacyInvitations = (state) =>
  state.invitations.legacyInvitations.items;

function buildFilterFunctions({
  scope = SCOPE_FULLY_LOADED,
  agencyIds,
  temp,
  perm,
}) {
  const filterFunctions = [];

  // both isActive and isPending should exclude recruiters activating
  // via a teammate invitation from an activated organization
  const isActive = ({ individual, organization }) =>
    Boolean(
      individual &&
        individual.attributes &&
        individual.attributes.activatedAt &&
        !individual.attributes.deactivatedAt &&
        organization.attributes.activatedAt
    );

  const isPending = ({ invitation, individual }) =>
    Boolean(invitation && !get(individual, "attributes.activatedAt"));

  const isSelected = ({ id }) => includes(agencyIds, id);

  switch (scope) {
    case SCOPE_ACTIVE:
      filterFunctions.push(isActive);
      break;
    case SCOPE_PENDING:
      filterFunctions.push(isPending);
      break;
    case SCOPE_SELECTED:
      filterFunctions.push(isSelected);
      break;
    case SCOPE_FULLY_LOADED:
    default:
      filterFunctions.push((item) => isActive(item) || isPending(item));
      break;
  }

  if (temp) {
    filterFunctions.push(
      ({ preferredLink }) => preferredLink?.attributes?.tempEnabled
    );
  }

  if (perm) {
    filterFunctions.push(
      ({ preferredLink }) => preferredLink?.attributes?.permEnabled
    );
  }

  return filterFunctions;
}

export const makeGetLegacyRecruiters = () => {
  const getFilters = (_state, filters = {}) => filters;

  const filtersSelector = createDeepEqualSelector(
    [getFilters],
    (filters) => filters
  );
  return createSelector(
    [
      getPreferredLinks,
      getAllOrganizations,
      individualsSelector,
      getAllLegacyInvitations,
      filtersSelector,
    ],
    (preferredLinks, organizations, individuals, invitations, filters) => {
      const individualsByOrganization = groupBy(
        individuals,
        (individual) => individual.attributes.organizationId
      );
      const invitationsByPrefLink = groupBy(
        invitations,
        ({ attributes }) => attributes.preferredLinkId
      );

      const invitationsByIndividual = groupBy(
        invitations,
        ({ attributes }) => attributes.individualId
      );

      const items = flatMap(preferredLinks, (preferredLink) => {
        const organization =
          organizations[preferredLink.attributes.recruiterOrganizationId];

        const individuals =
          individualsByOrganization[
            preferredLink.attributes.recruiterOrganizationId
          ] || [];

        const organizationAttributes = {
          preferredLink,
          organization,
        };

        let invitation;
        if (individuals.length) {
          return map(individuals, (individual) => {
            invitation =
              get(invitationsByIndividual, `${individual.id}[0]`) ||
              get(invitationsByPrefLink, `${preferredLink.id}[0]`);

            return merge({}, organizationAttributes, {
              individual,
              id: individual.id,
              idType: "individual",
              invitation,
            });
          });
        }
        invitation = get(invitationsByPrefLink, `${preferredLink.id}[0]`);

        return [
          merge({}, organizationAttributes, {
            individual: null,
            id: get(invitation, "id"),
            idType: "legacy_invitation",
            invitation,
          }),
        ];
      });

      const filterFunctions = [].concat(buildFilterFunctions(filters));

      return filter(items, (item) =>
        every(filterFunctions, (filterFunction) => filterFunction(item)));
    }
  );
};

export const makeGetAgencyRecruiter = () =>
  createSelector(
    [
      getAllOrganizations,
      individualsSelector,
      getPreferredLinks,
      getAllLegacyInvitations,
      (_, individualId) => individualId,
    ],
    (
      organizations,
      individuals,
      preferredLinks,
      legacyInvitations,
      individualId
    ) => {
      const individual = find(
        individuals,
        (individual) => individual.attributes.id === individualId
      );

      const organization =
        individual &&
        find(
          organizations,
          (organization) =>
            organization.attributes.id === individual.attributes.organizationId
        );

      const preferredLink =
        organization &&
        find(
          preferredLinks,
          (preferredLink) =>
            !isEmpty(organization) &&
            preferredLink.attributes.recruiterOrganizationId === organization.id
        );

      const invitation =
        preferredLink &&
        find(
          legacyInvitations,
          (legacyInvitation) =>
            legacyInvitation.attributes.preferredLinkId === preferredLink.id
        );

      return {
        individual,
        organization,
        preferredLink,
        invitation,
      };
    }
  );

export const makeGetAgencyOrganization = () =>
  createSelector(
    [
      getAllOrganizations,
      getPreferredLinks,
      getAllLegacyInvitations,
      (_, organizationId) => organizationId,
    ],
    (organizations, preferredLinks, legacyInvitations, organizationId) => {
      const organization = find(
        organizations,
        (organization) => organization.attributes.id === organizationId
      );

      const preferredLink =
        organization &&
        find(
          preferredLinks,
          (preferredLink) =>
            !isEmpty(organization) &&
            preferredLink.attributes.recruiterOrganizationId === organization.id
        );

      const invitation =
        preferredLink &&
        find(
          legacyInvitations,
          (legacyInvitation) =>
            legacyInvitation.attributes.preferredLinkId ===
            preferredLink.attributes.id
        );

      return {
        organization,
        preferredLink,
        invitation,
      };
    }
  );
