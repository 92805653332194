import React from "react";

import MultiLineTextField from "../../../../../../../forms/custom/MultiLineTextField";

function EmailNote({ values, handleChange }) {
  return (
    <MultiLineTextField
      id="emailNote"
      label="Note in Email (optional)"
      multiline
      rows={3}
      value={values.emailNote}
      onChange={handleChange}
      placeholder="Please take a look at these candidates. I’d like to give feedback in the next few days. I have had a phone conversation with each."
      style={{ marginBottom: 0 }}
    />
  );
}

export default EmailNote;
