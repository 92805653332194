import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { DatePicker } from "@material-ui/pickers";
import filter from "lodash/filter";

import find from "lodash/find";
import intersection from "lodash/intersection";
import keys from "lodash/keys";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import React from "react";
import { Row, Col } from "react-grid-system";
import NumberFormat from "react-number-format";

import constants from "../../../../../../../../util/constants";
import {
  stateOptions, currencyOptions, servicedCountryOptions, currencySymbol
} from "../../../../../../../../util/constantsToOptions";
import DropdownInput from "../../../../../../../forms/custom/DropdownInput.react";
import DropdownSelect from "../../../../../../../forms/custom/DropdownSelect";

import FieldSection from "./FieldSection";
import SectionHeader from "./SectionHeader";

const sortedCurrencyOptions = uniqBy(
  [
    { value: "USD", label: "$ USD", currencyCode: "USD" },
    ...currencyOptions,
  ],
  "value"
);

function HireInformation({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  jobcast,
  setFieldValue,
}) {
  const handleCurrencyChange = (updatedSelections) => {
    if (!updatedSelections) {
      setFieldValue("currency", null);

      return;
    }

    const selections = filter(
      sortedCurrencyOptions,
      (option) => option.isFixed
    ).concat(updatedSelections);

    const newValue = uniq(map(selections, ({ value }) => value));

    setFieldValue("currency", newValue[0]);
  };

  const selectedCurrency = values.currency
    ? [
      find(
        sortedCurrencyOptions,
        (option) => option.value === values.currency
      ),
    ]
    : [];

  const renderTaxInfoField = () => (
    <>
      <Col>
        <DropdownInput
          label="Primary Work State"
          options={stateOptions}
          id="workplace_locale"
          error={Boolean(errors.workplace_locale && touched.workplace_locale)}
          onChange={handleChange}
          value={values.workplace_locale}
        />
      </Col>
      <Col>
        <TextField
          id="workplace_postal_code"
          label="Primary Work Zip"
          inputProps={{ maxLength: 5 }}
          error={Boolean(
            errors.workplace_postal_code && touched.workplace_postal_code
          )}
          onChange={handleChange}
          value={values.workplace_postal_code}
          onBlur={handleBlur}
        />
      </Col>
    </>
  );

  const renderTaxInfoDesc = () => (
    <Row>
      <Col />
      <Col xs={8}>
        <div
          className="helper-text"
          style={{
            fontSize: "10px",
            lineHeight: "1em",
            position: "relative",
            bottom: 10,
          }}
        >
          Primary work locations are used to determine tax rates.
        </div>
      </Col>
    </Row>
  );

  const salaryWarningText = () => {
    if (
      parseInt(values.salary.replace(/,/g, ""), 10) * 100 <
        jobcast.attributes.salaryMinCents
    ) {
      return (
        <span className="helper-text error">
          Are you sure? Out of posted salary range
        </span>
      );
    } if (
      parseInt(values.salary.replace(/,/g, ""), 10) * 100 >
        jobcast.attributes.salaryMaxCents
    ) {
      return (
        <span className="helper-text error">
          Are you sure? This is above the provided salary range.
        </span>
      );
    }
    return null;
  };

  const workplaceCountryWarningText = () => {
    const uniqCountryCodes = uniq(jobcast.attributes.countryCodes);
    const validCountryKeys = keys(constants.regions);
    const intersectingCountryCodes = intersection(validCountryKeys, uniqCountryCodes);

    if (!intersectingCountryCodes.includes(values.workplace_country_code)) {
      return (
        <span className="helper-text error">
          Are you sure? Different than posted location.
        </span>
      );
    }
    return null;
  };

  return (
    <>
      <SectionHeader title="Hire Information" style={{ marginTop: 0 }} />
      <FieldSection>
        <Row>
          <Col>
            <DropdownSelect
              label="Currency"
              isClearable
              options={sortedCurrencyOptions}
              id="currency"
              helperText={touched.currency && errors.currency}
              helperTextOverrideStyle={{ marginTop: "-18px" }}
              error={Boolean(errors.currency && touched.currency)}
              onChange={handleCurrencyChange}
              value={selectedCurrency}
              style={{ width: 220 }}
            />
          </Col>
          <Tooltip title="First-year Guaranteed Compensation includes guaranteed bonuses. If you only have an hourly wage please multiply it by 2080.">
            <Col>
              <NumberFormat
                thousandSeparator
                error={Boolean(errors.salary && touched.salary)}
                helperText={
                    touched.salary && (errors.salary || salaryWarningText())
                  }
                id="salary"
                InputProps={{ startAdornment: currencySymbol(values.currency) }}
                multiline
                label="Guaranteed Compensation"
                value={values.salary}
                customInput={TextField}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Tooltip>
          <Col>
            <DatePicker
              id="hire_start_date"
              label="Start Date"
              value={values.hire_start_date || null}
              onChange={(moment) => setFieldValue(
                "hire_start_date",
                moment && moment.utc().format("YYYY-MM-DD")
              )}
              error={Boolean(errors.hire_start_date && touched.hire_start_date)}
              format="MMM. DD YYYY"
              clearable
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DropdownInput
              label="Primary Work Country"
              options={servicedCountryOptions}
              id="workplace_country_code"
              helperText={
                  touched.workplace_country_code &&
                  (errors.workplace_country_code || workplaceCountryWarningText())
                }
              error={Boolean(
                errors.workplace_country_code && touched.workplace_country_code
              )}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.workplace_country_code}
              overrideStyle={{ width: 220 }}
            />
          </Col>
          {values.workplace_country_code === "USA" &&
              renderTaxInfoField()}
        </Row>
        {values.workplace_country_code === "USA" &&
            renderTaxInfoDesc()}
      </FieldSection>
    </>
  );
}

export default HireInformation;
