import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";

import { TAB_1_WIDTH, TAB_2_WIDTH } from "../constants";

import AllNotificationsIndex from "./AllNotificationsIndex";
import JobCastNotificationsIndex from "./JobCastNotificationsIndex";

const styles = () => ({
  root: {
    position: "fixed",
    backgroundColor: "white",
    height: "100%",
    width: TAB_2_WIDTH,
    left: TAB_1_WIDTH,
    borderRadius: 0
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: 65,
    borderBottom: "1px solid #B0BEC5",
    padding: "0px 24px 0px 12px",
    "& div": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "100%"
    }
  },
  titleText: {
    marginBottom: 3
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "3px 0px 3px 12px",
    alignItems: "center",
    height: 30,
    borderBottom: "1px solid #B0BEC5"
  },
  selectRoot: {
    display: "flex",
    flexDirection: "row",
    "&:hover": {
      "& svg": {
        color: "#008dae"
      },
      "& select": {
        color: "#008dae"
      }
    }
  },
  select: {
    color: "#37474F",
    width: "100%",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.75,
    textTransform: "uppercase",
    "&:focus": {
      backgroundColor: "white"
    }
  },
  selectIcon: {
    fontSize: 24,
    top: "calc(50% - 13px)",
    color: "#37474F"
  },
  overflowEllipsis: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  }
});

function NotificationsIndexTab(props) {
  const { classes, jobcastId } = props;

  return (
    <Paper className={classnames(classes.root)}>
      {jobcastId ? (
        <JobCastNotificationsIndex {...props} />
      ) : (
        <AllNotificationsIndex {...props} />
      )}
    </Paper>
  );
}

export default withStyles(styles)(NotificationsIndexTab);
