import React from "react";

import SearchResultItems from "../SearchResultItems";

import SearchResultSectionHeader from "./SearchResultSectionHeader";

function SearchResultSection({
  items, title, numResults, exitSearch, currentIndividual
}) {
  return items.length > 0 && (
    <div>
      <SearchResultSectionHeader title={title} numResults={numResults} />
      <SearchResultItems items={items} exitSearch={exitSearch} currentIndividual={currentIndividual} />
    </div>
  );
}

export default SearchResultSection;
