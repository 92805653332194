import { Chip, TableCell, TableRow } from "@material-ui/core";
import { useField } from 'formik';
import map from "lodash/map";
import React from "react";
import { useSelector } from "react-redux";

import { makeGetTeammates } from "../../../../../selectors/recruiter/teammateSelectors";
import { buildFullName } from "../../../../../util/formatHelpers";
import DropdownSelect from "../../../../forms/custom/DropdownSelect";
import { TIMESHEET_SUBMITTERS } from "../../../Team/constants";

import ConfirmationCellContent from "./ConfirmationCellContent";
import NoActionNeeded from "./NoActionNeeded";

function TimesheetSubmittersList({ checklistItem }) {
  return (
    <div style={{
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
    }}
    >
      {map(checklistItem.timesheetSubmitters, ({ value, label }) => (
        <Chip
          key={`${value}`}
          label={label}
          className="caption"
        />
      ))}
    </div>
  );
}

export default function TimesheetSubmittersFieldRow({
  checklistItem, editable, fieldId
}) {
  const teammates = useSelector(makeGetTeammates(TIMESHEET_SUBMITTERS));

  const [{ value }, , { setValue }] = useField(`${fieldId}.timesheetSubmitters`);

  const options = teammates.map(({ id, attributes }) => (
    {
      value: id,
      label: buildFullName(attributes.firstName, attributes.lastName)
    }
  ));

  return (
    <TableRow data-cy="checklist-item-timesheet-submitters-row">
      <TableCell>
        <div className="bold">
          {checklistItem.name}
        </div>
      </TableCell>
      <TableCell align="center">
        <NoActionNeeded {...{ checklistItem, editable }}>
          {editable ? (
            <DropdownSelect
              id="timesheetSubmitters"
              isMulti
              isClearable
              label=""
              options={options}
              value={value}
              onChange={setValue}
            />
          ) : (
            <TimesheetSubmittersList {...{ checklistItem }} />
          )}
        </NoActionNeeded>
      </TableCell>
      <TableCell>
        <ConfirmationCellContent {...{ checklistItem }} />
      </TableCell>
    </TableRow>
  );
}
