import MessageAlert from "mdi-material-ui/MessageAlert";
import moment from "moment";
import React from "react";

import Content from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Content";
import Subheader from "../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";

import withNotificationMarkedRead from "./withNotificationMarkedRead";

function Announcement({
  notification,
  jobcast: {
    attributes: {
      individualAvatar,
      individualFirstName,
      individualLastInitial,
      organizationName
    }
  }
}) {
  return (
    <div>
      <Subheader
        leftChip={{
          chipHeader: "Update from",
          individualFirstName,
          individualLastInitial,
          individualAvatar,
          chipSubText: organizationName
        }}
      />
      <Content
        iconImage={MessageAlert}
        iconCaption="Announcement"
        label="Message to Agencies"
        helperText={`${moment(notification.attributes.createdAt).format(
          "MMMM Do, YYYY"
        )}`}
        value={notification.attributes.messageBody}
      />
    </div>
  );
}

export default withNotificationMarkedRead(Announcement);
