import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React from "react";
import { Row, Col } from "react-grid-system";

import FieldSection from "./FieldSection";
import SectionHeader from "./SectionHeader";

function InviteCandidateToATS({
  values,
  errors,
  setFieldValue,
  submitCount,
}) {
  return (
    <>
      <SectionHeader
        title="Invite Candidate to Apply to your ATS"
        titleStyle={{ paddingTop: 10, paddingLeft: 24 }}
        lineStyle={{ width: "100%" }}
      />
      <FieldSection>
        <Row>
          <Col>
            <RadioGroup style={{ padding: "0 0 16px 0px" }}>
              <FormControlLabel
                control={(
                  <Radio
                    color="primary"
                    checked={values.ats_invite === true}
                  />
                )}
                label="I would like to invite this candidate to apply to my ATS."
                onChange={() => setFieldValue("ats_invite", true)}
                id="ats_invite-opt-1"
                value="true
                "
              />
              <FormControlLabel
                control={(
                  <Radio
                    color="primary"
                    checked={values.ats_invite === false}
                  />
                )}
                label=" I do not want to invite this candidate to apply to my ATS."
                id="ats_invite-opt-2"
                onChange={() => setFieldValue("ats_invite", false)}
                value="false"
              />
              {Boolean(submitCount) && errors.ats_invite && (
                <FormHelperText className="helper-text error">
                  {errors.ats_invite}
                </FormHelperText>
              )}
            </RadioGroup>
          </Col>
        </Row>
      </FieldSection>
    </>
  );
}

export default InviteCandidateToATS;
