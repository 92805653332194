import normalize from "json-api-normalizer";

import { GET, POST, PATCH } from "../../util/apiHelpers";

export const RECEIVE_ORGANIZATION_PROFILES = "RECEIVE_ORGANIZATION_PROFILES";
export const RECEIVE_ORGANIZATION_PROFILE = "RECEIVE_ORGANIZATION_PROFILE";
export const UPDATE_ORGANIZATION_PROFILE_FILTER =
  "UPDATE_ORGANIZATION_PROFILE_FILTER";

export const updateOrganizationProfileFilter = (filterId) => ({
  type: UPDATE_ORGANIZATION_PROFILE_FILTER,
  payload: filterId,
});

export const receiveOrganizationProfiles = (organizationProfiles) => ({
  type: RECEIVE_ORGANIZATION_PROFILES,
  payload: organizationProfiles,
});

export const receiveOrganizationProfile = (organizationProfile) => ({
  type: RECEIVE_ORGANIZATION_PROFILE,
  payload: organizationProfile,
});

export const fetchOrganizationProfiles = () => (dispatch) => GET("/api/v3/employer/organization_profiles").then((res) => {
  const normalized = normalize(res.data);

  return dispatch(
    receiveOrganizationProfiles(normalized.organizationProfile)
  );
});

export const fetchOrganizationProfile = (id) => (dispatch) => GET(`/api/v3/employer/organization_profiles/${id}`).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(
    receiveOrganizationProfile(
      normalized.organizationProfile[res.data.data.id]
    )
  );
});

export const createOrganizationProfile = (formData) => (dispatch) => POST("/api/v3/employer/organization_profiles", formData)
  .then((res) => {
    const normalized = normalize(res.data);
    dispatch(
      receiveOrganizationProfile(
        normalized.organizationProfile[res.data.data.id]
      )
    );
    return Promise.resolve();
  })
  .catch((error) => Promise.reject(error));

export const updateOrganizationProfile = (formData, id) => (dispatch) => PATCH(`/api/v3/employer/organization_profiles/${id}`, formData)
  .then((res) => {
    const normalized = normalize(res.data);
    dispatch(
      receiveOrganizationProfile(
        normalized.organizationProfile[res.data.data.id]
      )
    );
    return Promise.resolve();
  })
  .catch((error) => Promise.reject(error));
