import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import merge from "lodash/merge";

import {
  RECEIVE_RECRUITER_LIST,
  RECEIVE_RECRUITER_LISTS,
  REMOVE_RECRUITER_LIST,
  REMOVE_RECRUITER_LISTS,
} from "../actions/employers/recruiterListActions";
import { mergeWithArrayOverwrite } from "../util/formatHelpers";

const defaultState = {
  loaded: false,
  items: {},
};

const recruiterListReducer = (state = defaultState, action) => {
  Object.freeze(state);
  let newItems;
  switch (action.type) {
    case RECEIVE_RECRUITER_LISTS:
      return mergeWithArrayOverwrite({}, state, {
        loaded: true,
        items: action.payload,
      });
    case RECEIVE_RECRUITER_LIST:
      newItems = cloneDeep(state.items);
      newItems[action.payload.id] = merge({}, action.payload, { loaded: true });
      return { ...state, items: newItems };
    case REMOVE_RECRUITER_LIST:
      newItems = cloneDeep(state.items);
      delete newItems[action.payload];
      return { ...state, items: newItems };
    case REMOVE_RECRUITER_LISTS:
      newItems = cloneDeep(state.items);
      map(
        action.payload,
        (recruiterListId) => delete newItems[recruiterListId]
      );
      return { ...state, items: newItems };
    default:
      return state;
  }
};

export default recruiterListReducer;
