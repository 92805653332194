import { withStyles } from "@material-ui/core/styles";
import { ResponsiveBar } from "@nivo/bar";
import orderBy from "lodash/orderBy";

import PropTypes from "prop-types";
import React from "react";

const styles = {
  chartContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%"
  },
  leftAxisLabelContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  leftAxisLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "13px",
    whiteSpace: "normal",
    fontWeight: 600,
    color: "#B0BEC5",
    fontFamily: "proxima-nova",
    letterSpacing: "0.4px",
    textTransform: "uppercase"
  },
  svgContainer: {
    height: "100%"
  }
};

function FunnelBarChart({
  data,
  valueKey,
  height,
  axisWidth,
  svgWidth,
  classes
}) {
  const orderedData = orderBy(data, [valueKey], ["asc"]);
  const valueMax =
    orderedData.length && orderedData[orderedData.length - 1][valueKey];
  const renderMax = valueMax * 1.1;

  const formatValueLabel = valueNum => {
    let formatText = `${valueNum}`;
    const isMax = valueNum === valueMax;
    !isMax && (formatText += ` (${Math.round((valueNum / valueMax) * 100)}%)`);

    const xAdjustment =
      (valueNum / renderMax) * svgWidth + (formatText.length / 2) * 7 + 4;
    return (
      <tspan x={xAdjustment} style={{ fontSize: "14px" }}>
        {formatText}
      </tspan>
    );
  };

  const renderAxisLabels = () => {
    const axisLabels = orderedData.map(({ id }) => id).reverse();
    return axisLabels.map(label => (
      <div
        className={classes.leftAxisLabel}
        style={{
          height: height / orderedData.length
        }}
        key={label}
      >
        {label}
      </div>
    ));
  };

  return (
    <div
      style={{ height }}
      className={classes.chartContainer}
      data-cy="bar-chart"
    >
      <div
        style={{ width: axisWidth }}
        className={classes.leftAxisLabelContainer}
      >
        {renderAxisLabels()}
      </div>
      <div className={classes.svgContainer} style={{ width: svgWidth }}>
        <ResponsiveBar
          data={orderedData}
          indexBy="id"
          maxValue={renderMax}
          keys={[valueKey]}
          colorBy="index"
          colors={orderedData.map(({ color }) => color)}
          borderColor={{ from: "color", modifiers: [["opacity", 1]] }}
          layout="horizontal"
          labelFormat={formatValueLabel}
          labelTextColor="#37474F"
          padding={0}
          enableGridY={false}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={null}
          animate
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </div>
  );
}

FunnelBarChart.propTypes = {
  title: PropTypes.node,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      color: PropTypes.string
    })
  ),
  // the key in data objects that holds their numerical value.
  // will vary by use case since the key itself is rendered in the chart
  valueKey: PropTypes.string.isRequired,
  // must be the px number -- used for calculation
  height: PropTypes.number.isRequired,
  axisWidth: PropTypes.number.isRequired,
  svgWidth: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FunnelBarChart);
