import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";

import {
  makeGetFilteredNotifications,
  UNREAD
} from "../../../../selectors/notificationsSelectors";
import withNotificationsReader from "../../../shared/CandidateDrawer/CandidateDrawerTabs/withNotificationsReader";

class HistoryTab extends React.PureComponent {
  collectHistoryEvents() {
    const { stageEvents, transferEvents } = this.props.candidate.attributes;

    const formatTransferEvent = transferEvent => ({
      id: transferEvent.id,
      date: transferEvent.updatedAt,
      formattedEvent: "JobCast Transfer",
      notes:
        "Transferred to " +
        `${transferEvent.targetPositionTitle} - ${transferEvent.targetPositionLocation}` +
        " from " +
        `${transferEvent.sourcePositionTitle} - ${transferEvent.sourcePositionLocation}`
    });

    const formatStageEvent = stageEvent => ({
      id: stageEvent.id,
      date: stageEvent.updatedAt,
      formattedEvent: stageEvent.formattedEvent,
      notes: stageEvent.note
    });

    const formattedEvents = map(stageEvents, stageEvent => formatStageEvent(stageEvent.attributes)).concat(
      map(transferEvents, transferEvent => formatTransferEvent(transferEvent.attributes))
    );

    return orderBy(formattedEvents, "date", "desc");
  }

  renderTableCells() {
    const historyRows = this.collectHistoryEvents();

    return map(historyRows, historyRow => (
      <TableRow key={historyRow.id}>
        <TableCell
          className="small"
          component="th"
          scope="row"
          style={{
            color: "#37474F",
            whiteSpace: "nowrap",
            paddingRight: 0,
            paddingLeft: 12
          }}
        >
          {moment(historyRow.date).format("ll")}
        </TableCell>
        <TableCell
          className="small"
          style={{ color: "#37474F", paddingRight: 0 }}
        >
          {historyRow.formattedEvent}
        </TableCell>
        <TableCell
          className="small"
          style={{ color: "#37474F", wordBreak: "break-word" }}
        >
          {historyRow.notes}
        </TableCell>
      </TableRow>
    ));
  }

  render() {
    return (
      <Table>
        <colgroup>
          <col style={{ width: "20%" }} />
          <col style={{ width: "30%" }} />
          <col style={{ width: "50%" }} />
        </colgroup>
        <TableHead>
          <TableRow style={{ height: 32 }}>
            <TableCell
              className="small semibold"
              style={{ color: "#37474F", paddingLeft: 28 }}
            >
              Date
            </TableCell>
            <TableCell
              className="small semibold"
              style={{ color: "#37474F", paddingLeft: 28 }}
            >
              Event
            </TableCell>
            <TableCell
              className="small semibold"
              style={{ color: "#37474F", paddingLeft: 28 }}
            >
              Notes
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{this.renderTableCells()}</TableBody>
      </Table>
    );
  }
}

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();
  const mapStateToProps = (state, ownProps) => ({
    unreadNotifications: getFilteredNotifications(state, {
      [UNREAD]: true,
      recruiterSubmissionId: ownProps.candidate.id,
      subjectType: "EmployerCandidateUpdateNotification"
    })
  });

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(
  withNotificationsReader(HistoryTab)
);
