import { withFormik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";

import { withdrawCandidate } from "../../../../../actions/recruiters/candidateActions";
import { clearModal, clearProfileDrawer } from "../../../../../actions/shared/uiActions";
import { buildFullName } from "../../../../../util/formatHelpers";
import { string, object } from "../../../../../util/yup";

import MultiLineTextField from "../../../../forms/custom/MultiLineTextField";
import ActionModalContainer from "../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../General/ActionModal/ActionModalHeader";
import withSnackbar from "../../../../General/withSnackbar";

class WithdrawModal extends Component {
  getCandidateName() {
    const { firstName, lastName } = this.props.candidate.attributes;
    return `${firstName} ${lastName}`;
  }

  renderContent() {
    const {
      values, errors, touched, handleChange, handleBlur
    } = this.props;

    return (
      <>
        <p style={{ margin: "0 0 24px 0" }}>
          The employer will be notified and the candidate will be marked as rejected. Please explain why the candidate is no longer in contention.
        </p>
        <MultiLineTextField
          error={Boolean(errors.withdrawReason && touched.withdrawReason)}
          helperText={touched.withdrawReason && errors.withdrawReason}
          label="Withdraw Reason"
          onChange={handleChange}
          value={values.withdrawReason}
          id="withdrawReason"
          rows={3}
          onBlur={handleBlur}
        />
      </>
    );
  }

  render() {
    const { isSubmitting, handleSubmit } = this.props;

    return (
      <ActionModalContainer color="red">
        <ActionModalHeader
          title="Withdraw Candidate"
          subtitle={this.getCandidateName()}
        />
        <ActionModalContent>{this.renderContent()}</ActionModalContent>
        <ActionModalFooter
          actionText="Withdraw"
          handleAction={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </ActionModalContainer>
    );
  }
}

const WithdrawModalForm = withFormik({
  handleSubmit: (values, { props, setSubmitting }) => {
    const {
      candidate: {
        id,
        attributes: { firstName, lastName },
      },
      withdrawCandidate,
      clearModal,
      clearProfileDrawer,
      snackbar,
    } = props;

    withdrawCandidate({
      candidateId: id,
      note: values.withdrawReason,
    })
      .then(() => setSubmitting(false))
      .then(clearModal)
      .then(
        snackbar.showMessage(
          `${buildFullName(firstName, lastName)} has been withdrawn.`
        )
      )
      .then(clearProfileDrawer)
      .catch(() => {});
  },
  mapPropsToValues: () => ({ withdrawReason: "" }),
  validationSchema: object().shape({
    withdrawReason: string().required("Cannot be blank").minWords(3),
  }),
})(WithdrawModal);

const mapDispatchToProps = (dispatch) => ({
  clearModal: () => dispatch(clearModal()),
  clearProfileDrawer: () => dispatch(clearProfileDrawer()),
  withdrawCandidate: (data) => dispatch(withdrawCandidate(data)),
});

export default connect(
  null,
  mapDispatchToProps
)(withSnackbar()(WithdrawModalForm));
