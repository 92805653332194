import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Star from "@material-ui/icons/Star";
import round from "lodash/round";
import sum from "lodash/sum";
import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { logout } from "../../../actions/shared/individualActions";
import { setHeaderMenuScope } from "../../../actions/shared/uiActions";
import { getCurrentOrganization } from "../../../selectors/organizationSelectors";
import GlobalAvatar from "../../General/GlobalAvatar";
import {
  PERM_SCOPE, EMPLOYER_SETTINGS_ITEMS, TEMP_SCOPE,
  RECRUITER_SETTINGS_ITEMS,
  TEMP_RECRUITER_SETTINGS_ITEMS
} from "../HeaderPrimary/NavigationTabs/constants";

import EmployerTabs from "./EmployerTabs";
import HeaderListLinks from "./HeaderListLinks";
import MobileTabsScopeToggle from "./MobileTabsScopeToggle";
import RecruiterTabs from "./RecruiterTabs";

const useStyles = makeStyles(() => ({
  overlayMenu: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    zIndex: 10000,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    color: 'black',
    WebkitOverflowScrolling: 'touch',
  },
  closeButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#ffffff',
    fontSize: '30px',
    cursor: 'pointer',
  },
  individualAvatar: {
    padding: 20,
    backgroundImage: "linear-gradient(to bottom, #018DAE 70%, #ffffff 50%)",
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    color: 'white'
  },
  numRatings: {
    display: "flex",
    alignItems: "center",
    fontSize: 20
  }
}));

const MobileMenuDropdown = ({ handleMenuClick, currentIndividual }) => {
  const scope = useSelector((state) => state.ui.headerMenu.scope);
  const currentOrganization = useSelector(getCurrentOrganization);
  const classes = useStyles();
  const dispatch = useDispatch();

  const { aggregateRatings, type } = currentIndividual;
  const {
    feedbackRatings,
    jobcastRatings,
    employerReviewRatings,
    candidateReviewRatings
  } = aggregateRatings;

  const hasTempOption = currentOrganization.contractActivated;
  const isEmployer = type === "employer";
  const isRecruiter = type === "recruiter";

  useEffect(() => {
    if (!hasTempOption && scope === TEMP_SCOPE) {
      dispatch(setHeaderMenuScope(PERM_SCOPE));
    }
  }, [hasTempOption, scope, dispatch]);

  const TabsList = isEmployer ? EmployerTabs : RecruiterTabs;

  let settingsItems;

  if (isEmployer) {
    settingsItems = EMPLOYER_SETTINGS_ITEMS;
  } else if (isRecruiter && scope === TEMP_SCOPE) {
    settingsItems = TEMP_RECRUITER_SETTINGS_ITEMS;
  } else {
    settingsItems = RECRUITER_SETTINGS_ITEMS;
  }

  const logoutItem = {
    logout: {
      key: "logout",
      label: "Logout",
      handleClick: () => { dispatch(logout()); }
    }
  };

  const calculateRating = () => {
    const typedRatings = [];

    if (isEmployer) {
      if (feedbackRatings?.numRatings) {
        typedRatings.push(feedbackRatings.average);
      }

      if (jobcastRatings?.numRatings) {
        typedRatings.push(jobcastRatings.average);
      }
    } else if (isRecruiter) {
      if (employerReviewRatings?.numRatings) {
        typedRatings.push(employerReviewRatings.average);
      }

      if (candidateReviewRatings?.numRatings) {
        typedRatings.push(candidateReviewRatings.average);
      }
    }

    if (!typedRatings.length) return 0;

    return round((sum(typedRatings) / typedRatings.length), 1);
  };

  return (
    <div className={classes.overlayMenu}>
      <button
        type="button"
        className={classes.closeButton}
        onClick={handleMenuClick}
        aria-label="close menu"
      >
        &times;
      </button>
      <div className={classes.individualAvatar}>
        <GlobalAvatar size="extra large" individual={currentIndividual} />
        <div>
          <div style={{ fontSize: 25 }}>{currentIndividual.fullName}</div>
          <div className={classes.numRatings}>
            <Star style={{ color: "#f8c11c" }} />
            {calculateRating()}
          </div>
        </div>
      </div>
      <Divider />
      {hasTempOption && (
        <>
          <MobileTabsScopeToggle scope={scope} />
          <Divider />
        </>
      )}
      <TabsList handleMenuClick={handleMenuClick} />
      <Divider />
      <HeaderListLinks
        items={settingsItems}
        handleMenuClick={handleMenuClick}
      />
      <Divider />
      <HeaderListLinks
        items={logoutItem}
        handleMenuClick={handleMenuClick}
      />
    </div>
  );
};

export default MobileMenuDropdown;
