import { useFormikContext } from "formik";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";

import Checkbox from "../../custom/Checkbox";

function FormContextCheckbox({ id, ...passThroughProps }) {
  const { values, errors, touched, setFieldValue, setFieldTouched } =
    useFormikContext();

  const value = get(values, id);
  const errorMessage = get(touched, id) && get(errors, id);

  const onChange = () => {
    setFieldTouched(id, true);
    setFieldValue(id, !value);
  };

  return (
    <Checkbox
      {...{ id, value, onChange }}
      error={Boolean(errorMessage)}
      {...passThroughProps}
    />
  );
}

FormContextCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FormContextCheckbox;
