import { GET } from "../../util/apiHelpers";

// HeaderSub actions
export const SET_SUB_NAV_CONTENT = "SET_SUB_NAV_CONTENT";
export const CLEAR_SUB_NAV = "CLEAR_SUB_NAV";

export const setSubNav = (payload) => ({
  type: SET_SUB_NAV_CONTENT,
  payload,
});

export const clearSubNav = () => ({ type: CLEAR_SUB_NAV });

// LeftDrawer actions
export const SET_LEFT_DRAWER_VISIBLE = "SET_LEFT_DRAWER_VISIBLE";
export const SET_LEFT_DRAWER_CONTENT = "SET_LEFT_DRAWER_CONTENT";
export const SET_PROFILE_DRAWER_HEADER_TEXT = "SET_PROFILE_DRAWER_HEADER_TEXT";
export const CLEAR_LEFT_DRAWER = "CLEAR_LEFT_DRAWER";
export const SET_LEFT_DRAWER_COUNTS_FETCH_PATH =
  "SET_LEFT_DRAWER_COUNTS_FETCH_PATH";
export const SET_LEFT_DRAWER_COUNTS = "SET_LEFT_DRAWER_COUNTS";

export const SET_VIEWABLE_AS_TEAMMATE_PAGE = "SET_VIEWABLE_AS_TEAMMATE_PAGE";

export const setLeftDrawerVisible = (boolean) => ({
  type: SET_LEFT_DRAWER_VISIBLE,
  payload: boolean,
});

export const setLeftDrawer = (payload) => ({
  type: SET_LEFT_DRAWER_CONTENT,
  payload,
});
export const clearLeftDrawer = () => ({ type: CLEAR_LEFT_DRAWER });

export const setLeftDrawerCountsFetchPath = (path) => ({
  type: SET_LEFT_DRAWER_COUNTS_FETCH_PATH,
  payload: path,
});

export const setLeftDrawerCounts = (counts) => ({
  type: SET_LEFT_DRAWER_COUNTS,
  payload: counts,
});

export const refetchLeftDrawerCounts = () => (dispatch, getState) => {
  const { countsFetchPath } = getState().ui.leftDrawer;

  if (countsFetchPath) {
    return GET(countsFetchPath).then((res) =>
      dispatch(setLeftDrawerCounts(res.data))
    );
  }
};

// Modal actions
export const SET_MODAL_VISIBLE = "SET_MODAL_VISIBLE";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLEAR_MODAL = "CLEAR_MODAL";

export const setModalVisible = (isVisible) => ({
  type: SET_MODAL_VISIBLE,
  payload: isVisible,
});
export const openModal = (content) => ({ type: OPEN_MODAL, payload: content });
export const clearModal = () => ({ type: CLEAR_MODAL });

// Header actions
export const SET_HEADER_SUB_VISIBLE = "SET_HEADER_SUB_VISIBLE";
export const SET_HEADER_MENU_VISIBLE = "SET_HEADER_MENU_VISIBLE";
export const SET_HEADER_MENU_SCOPE = "SET_HEADER_MENU_SCOPE";

export const setHeaderMenuVisible = (boolean) => ({
  type: SET_HEADER_MENU_VISIBLE,
  payload: boolean,
});

export const setHeaderMenuScope = (scope) => ({
  type: SET_HEADER_MENU_SCOPE,
  payload: scope,
});

// Track if page is public facing
export const SET_PAGE_PUBLIC = "SET_PAGE_PUBLIC";
export const setPagePublic = (boolean) => ({
  type: SET_PAGE_PUBLIC,
  payload: boolean,
});

// Drawer actions
export const SET_PROFILE_DRAWER_VISIBLE = "SET_PROFILE_DRAWER_VISIBLE";
export const SET_PROFILE_DRAWER_CONTENT_LOADING =
  "SET_PROFILE_DRAWER_CONTENT_LOADING";
export const OPEN_PROFILE_DRAWER = "OPEN_PROFILE_DRAWER";
export const CLEAR_PROFILE_DRAWER = "CLEAR_PROFILE_DRAWER";

export const setProfileDrawerVisible = (isVisible) => ({
  type: SET_PROFILE_DRAWER_VISIBLE,
  payload: isVisible,
});
export const setProfileDrawerLoading = (isLoading) => ({
  type: SET_PROFILE_DRAWER_CONTENT_LOADING,
  payload: isLoading,
});
export const openProfileDrawer = (content, positionId) => ({
  type: OPEN_PROFILE_DRAWER,
  payload: content,
  meta: { positionId },
});
export const clearProfileDrawer = () => ({ type: CLEAR_PROFILE_DRAWER });
export const setProfileDrawerHeaderText = (text) => ({
  type: SET_PROFILE_DRAWER_HEADER_TEXT,
  payload: text,
});

export const SET_NOTIFICATIONS_LEFT_DRAWER_VISIBLE =
  "SET_NOTIFICATIONS_LEFT_DRAWER_VISIBLE";
export const SET_NOTIFICATIONS_DRAWER_CONTENT =
  "SET_NOTIFICATIONS_DRAWER_CONTENT";

export const setNotificationsDrawerVisible = (isVisible) => ({
  type: SET_NOTIFICATIONS_LEFT_DRAWER_VISIBLE,
  payload: isVisible,
});

export const setNotificationsDrawerContent = (content) => ({
  type: SET_NOTIFICATIONS_DRAWER_CONTENT,
  payload: content,
});

export const setViewableAsTeammatePage = (isViewableAsTeammate) => ({
  type: SET_VIEWABLE_AS_TEAMMATE_PAGE,
  payload: isViewableAsTeammate,
});

// Animation actions
export const UPDATE_ANIMATIONS = "UPDATE_ANIMATIONS";
export const CLEAR_ANIMATIONS = "CLEAR_ANIMATIONS";
export const REMOVE_ANIMATION = "REMOVE_ANIMATION";
export const REMOVE_ANIMATIONS = "REMOVE_ANIMATIONS";

export const updateAnimation = (animationkey, newValues) => ({
  type: UPDATE_ANIMATIONS,
  payload: { [animationkey]: newValues },
});

export const updateAnimations = (updatedItems) => ({
  type: UPDATE_ANIMATIONS,
  payload: updatedItems,
});

export const clearAnimations = () => ({
  type: CLEAR_ANIMATIONS,
});

export const removeAnimation = (animationkey) => ({
  type: REMOVE_ANIMATION,
  animationkey,
});

export const removeAnimations = (animationkeys) => ({
  type: REMOVE_ANIMATIONS,
  animationkeys,
});

export const activateAnimation = (animationkey) => (dispatch, getState) => {
  const animationExists = Boolean(getState().ui.animations.items[animationkey]);

  if (animationExists) {
    return dispatch(
      updateAnimation(animationkey, {
        isActive: true,
      })
    );
  }
};
