import PropTypes from "prop-types";
import React, { useEffect } from "react";

import ActionCableContext from "./ActionCableContext";

function ActionCableSubscription({
  cable,
  channel,
  onReceived,
  onInitialized,
  onConnected,
  onDisconnected,
  onRejected,
}) {
  useEffect(() => {
    const newSubscription = cable.subscriptions.create(channel, {
      received(data) {
        onReceived && onReceived(data);
      },
      initialized() {
        onInitialized && onInitialized();
      },
      connected() {
        onConnected && onConnected();
      },
      disconnected() {
        onDisconnected && onDisconnected();
      },
      rejected() {
        onRejected && onRejected();
      },
    });

    return () => {
      newSubscription && cable.subscriptions.remove(newSubscription);
    };
  }, [
    channel,
    cable,
    onReceived,
    onInitialized,
    onConnected,
    onDisconnected,
    onRejected,
  ]);

  return <></>;
}

function ActionCableConsumer(props) {
  return (
    <ActionCableContext.Consumer>
      {({ cable }) => Boolean(cable) && <ActionCableSubscription {...props} cable={cable} />}
    </ActionCableContext.Consumer>
  );
}

ActionCableConsumer.propTypes = {
  channel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      channel: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  // note: functions should be memoized to avoid needlessly re-establishing connection
  onReceived: PropTypes.func,
  onInitialized: PropTypes.func,
  onConnected: PropTypes.func,
  onDisconnected: PropTypes.func,
  onRejected: PropTypes.func,
};

export default ActionCableConsumer;
