import filter from "lodash/filter";
import get from "lodash/get";
import includes from "lodash/includes";
import map from "lodash/map";
import uniq from "lodash/uniq";
import PropTypes from "prop-types";
import React, { memo } from "react";

import DropdownSelect from "../../../../forms/custom/DropdownSelect";

import { withFormContext } from "../../../../forms/formik/FormContext";

import shouldSkipRerender from "./shouldSkipRerender";

function FormContextDropdownSelect({
  formContext: {
    values, errors, touched, setFieldValue, handleBlur
  },
  inputProps = {},
  ...passThroughProps
}) {
  const { id } = passThroughProps;
  const errorMessage = (get(touched, id) && get(errors, id)) || "";

  const handleChange = (updatedSelections) => {
    if (!updatedSelections) {
      setFieldValue(id, []);

      return;
    }

    const selections = filter(
      passThroughProps.options,
      (option) => option.isFixed
    ).concat(updatedSelections);

    const newValue = uniq(map(selections, ({ value }) => value));

    setFieldValue(id, newValue[0]);
  };

  const selected = filter(
    passThroughProps.options,
    (option) => option.value === get(values, id)
  );

  return (
    <DropdownSelect
      value={selected}
      onChange={handleChange}
      isClearable
      onBlur={handleBlur}
      error={Boolean(errorMessage)}
      helperText={errorMessage}
      helperTextOverrideStyle={{ marginTop: "-18px" }}
      inputProps={({
        "data-cy": `${id}-dropdown-input`,
        ...inputProps
      })}
      {...passThroughProps}
    />
  );
}

FormContextDropdownSelect.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  formContext: PropTypes.shape({
    values: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
  }).isRequired,
};

export default withFormContext(
  memo(FormContextDropdownSelect, shouldSkipRerender)
);
