export const TAB_1_WIDTH = 240;

export const TAB_2_WIDTH = 290;

export const TAB_3_WIDTH = 500;

// todo(me): build out types here
export const POSITION_ARCHIVING_SOON = "POSITION-ARCHIVING-SOON";
export const POSITION_ARCHIVED = "POSITION-ARCHIVED";
export const PRIORITY_BONUS_ADDED = "PRIORITY-BONUS-ADDED";
export const PRIORITY_BONUS_UPDATED = "PRIORITY-BONUS-UPDATED";
export const PRIORITY_BONUS_REMOVED = "PRIORITY-BONUS-REMOVED";
