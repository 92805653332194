import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { receiveReleaseToken } from "../../../../actions/shared/releaseTokenActions";
import ActionCableConsumer from "../ActionCableConsumer";

function ReleaseTokensConsumer() {
  const dispatch = useDispatch();

  const handleReceived = useCallback(
    (data) => {
      dispatch(receiveReleaseToken(data));
    },
    [dispatch]
  );

  return (
    <ActionCableConsumer
      channel="ReleaseTokensChannel"
      onReceived={handleReceived}
    />
  );
}

export default ReleaseTokensConsumer;
