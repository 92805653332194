import includes from "lodash/includes";
import React, { useCallback } from "react";

import { useDispatch } from "react-redux";

import { openProfileDrawer } from "../../../../../../../actions/shared/uiActions";
import StarRatingDisplay from "../../../../../../General/StarRatingDisplay";
import Profile from "../../../../../../shared/JobCast/Candidates/CandidateCard/Profile/Profile";
import { VIEW_RESUME } from "../../constants";
import CandidateDrawer from "../../view/CandidateDrawer";

function CustomSubtitle({ candidate }) {
  const { status, statusText, receivedRating } = candidate.attributes;
  const dispatch = useDispatch();

  const openDrawer = useCallback(() => {
    dispatch(
      openProfileDrawer(
        <CandidateDrawer
          candidateId={candidate.id}
          navSelection={VIEW_RESUME}
        />
      )
    );
  }, [candidate.id, dispatch]);

  if (includes(["hired", "new"], status)) return statusText;

  if (status === "rejected" && !receivedRating) return statusText;

  if (!receivedRating) {
    return <span className="small gray-light">No Internal Rating</span>;
  }

  return (
    <StarRatingDisplay
      handleClick={openDrawer}
      rating={receivedRating}
      color="#B0BEC5"
      fontSize={18}
    />
  );
}

function CandidateCardProfile({ candidate }) {
  const drawerComponent = () => {
    const candidateId = candidate.attributes.id;

    return (
      <CandidateDrawer candidateId={candidateId} navSelection={VIEW_RESUME} />
    );
  };
  return (
    <Profile
      drawerComponent={drawerComponent()}
      candidate={candidate}
      subtitle={<CustomSubtitle candidate={candidate} />}
    />
  );
}

export default CandidateCardProfile;
