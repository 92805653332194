const style = () => ({
  chatContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  introSection: {
    backgroundColor: "#eceff1",
    height: "60px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 0px"
  },
  introInfo: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 40,
    textAlign: "right"
  },
  messageContainer: {
    height: "calc(100% - 63px - 115px)",
    minHeight: "400px",
    overflowY: "auto",
    overflowX: "hidden"
  },
  dateLine: {
    color: "#90A4AE",
    fontWeight: 500,
    fontSize: 12,
    margin: "auto"
  },
  messages: {
    display: "flex",
    flexDirection: "column",
    listStyleType: "none",
    paddingRight: 12,
    paddingLeft: 12
  },
  chatBubble: {
    position: "relative",
    padding: "8px 12px",
    marginBottom: "2px",
    wordBreak: "break-word",
    maxWidth: "600px",
    whiteSpace: "pre-wrap",
    marginLeft: "10px",
    marginRight: "10px"
  },
  selfChatBubble: {
    borderRadius: "24px 24px 0px 24px",
    backgroundColor: "#D0EAF0",
    alignSelf: "flex-end",
    textAlign: "left"
  },
  otherParticipantChatBubble: {
    borderRadius: "24px 24px 24px 0px",
    backgroundColor: "#eceff1",
    alignSelf: "flex-start",
    textAlign: "left"
  },
  selfTriangle: {
    position: "absolute",
    width: 0,
    height: 0,
    borderLeft: "20px solid #D0EAF0",
    borderTop: "20px solid transparent",
    borderRight: "22px solid transparent",
    bottom: 0,
    right: "-30px"
  },
  otherParticipantTriangle: {
    position: "absolute",
    width: 0,
    height: 0,
    borderLeft: "20px solid transparent",
    borderTop: "20px solid transparent",
    borderRight: "22px solid #eceff1",
    bottom: 0,
    left: "-30px"
  },
  selfCaption: {
    textAlign: "right",
    paddingRight: 8,
    color: "#90A4AE",
    marginRight: 30
  },
  otherParticipantCaption: {
    textAlign: "left",
    paddingLeft: 8,
    color: "#90A4AE"
  },
  multiLineTextField: {
    width: "70%",
    paddingTop: 12
  },
  buttonProgress: {
    position: "absolute",
    top: "20%",
    left: "50%",
    marginRight: "-12px",
    marginLeft: "-12px",
    color: "#008dae"
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    minHeight: 115,
    height: 115
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  attachFileChip: {
    backgroundColor: "#FFF",
    color: "#008DAE !important",
    marginTop: "8px",
    border: "1px solid",
    "&:hover, &:focus": {
      backgroundColor: "#f3f4f4"
    }
  },
  disabledAttachFileChip: {
    backgroundColor: "#FFF",
    color: "#00000042 !important",
    marginTop: "8px",
    border: "1px solid #90A4AE",
    "&:hover, &:focus": {
      backgroundColor: "#FFF",
      cursor: "default"
    }
  },
  attachFileIcon: {
    width: 20,
    height: 20,
    color: "#008DAE",
    marginRight: -8
  },
  disabledAttachFileIcon: {
    width: 20,
    height: 20,
    color: "#00000042",
    marginRight: -8
  },
  uploadedFileChip: {
    backgroundColor: "#FFFFFF",
    color: "#008DAE !important"
  },
  uploadedAttachedFileIcon: {
    width: 20,
    height: 20,
    color: "#B0BEC5",
    marginRight: -10
  },
  selfAvatarChatContainer: {
    alignSelf: "flex-end",
    display: "flex",
  },
  otherParticipantAvatarChatContainer: {
    alignSelf: "flex-start",
    display: "flex",
  },
});

export default style;
