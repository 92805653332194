import React from "react";
import { connect } from "react-redux";

import SOSErrorImg from "../../assets/images/SOS_Long.gif";
import { getCurrentIndividual } from "../../selectors/individualSelectors";
import Button from "../forms/custom/Button";

export class ErrorPage extends React.Component {
  render() {
    return (
      <div
        style={{
          alignItems: "center",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 24,
          backgroundColor: "#FFFF",
          height: "100%",
          width: 900,
          textAlign: "center"
        }}
      >
        <div
          style={{
            height: 450
          }}
        >
          <img
            src={SOSErrorImg}
            alt="sos-error"
            style={{ width: 500, height: 500 }}
          />
        </div>
        <h1 style={{ paddingTop: 12 }}>Error! SOS</h1>
        <h3>We can't find this page.</h3>

        <div style={{ paddingBottom: 24, paddingTop: 24 }}>
          <a href={this.props.redirectURL}>
            <Button color="blue" variant="primary">
              Home
            </Button>
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  redirectURL: getCurrentIndividual(state) ? "/app" : "https://www.recruitifi.com/"
});

export default connect(mapStateToProps)(ErrorPage);
