import Dialog from "@material-ui/core/Dialog";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { updateRecruiter } from "../../../../actions/recruiters/individualActions";
import rfiTheme from "../../../../util/theme";
import Checkbox from "../../../forms/custom/Checkbox";
import ActionModalContent from "../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../General/ActionModal/ActionModalHeader";
import RFIFeaturesGraphic from "../../../public/recruiter_registration/FormContent/RFIFeaturesGraphic";

const useStyles = makeStyles(() => ({
  backdrop: {
    position: "absolute",
    backdropFilter: "blur(8px)",
    overflowY: "hidden",
  },
  paper: {
    position: "absolute",
    verticalAlign: "middle",
    width: 780,
    maxWidth: 780,
    margin: "0",
    overflowY: "auto",
    maxHeight: "calc(100vh - 100px)",
  },
  disabledScroll: {
    overflowY: "hidden",
  },
}));

function TadMarketRfiModal({ open, handleClose }) {
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (checked !== true) {
      setError("Users must accept online terms");
      return;
    }
    dispatch(updateRecruiter({ agreed_to_rfi_terms: "true" }))
      .then(() => {
        handleClose();
        window.location.href = '/app/my-jobcasts';
      })
      .catch(() => {});
  };

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    if (open) {
      const body = document.getElementsByTagName("body")[0];
      body.classList.add(classes.disabledScroll);

      return () => {
        body.classList.remove(classes.disabledScroll);
      };
    }
  }, [open, classes]);

  return (
    <ThemeProvider theme={rfiTheme}>
      <Dialog
        open={open}
        disableBackdropClick
        disableEnforceFocus
        disableAutoFocus
        disablePortal
        onClose={handleClose}
        className={classes.root}
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
        style={{
          position: "absolute",
        }}
        color="blue"
      >
        <Formik onSubmit={handleSubmit}>
          {({ isSubmitting }) => (
            <>
              <ActionModalHeader
                title="Find Open Jobs On RecruitiFi"
                color="blue"
                handleClose={handleClose}
              />
              <ActionModalContent>
                <h4>
                  {" "}
                  Join thousands of agencies making placements on RecruitiFi
                </h4>
                <div style={{ paddingTop: "11px", paddingLeft: "7px" }}>
                  The average earnings for a hire in the USA on RecruitiFi is
                  $19,066.
                  {" "}
                  <a
                    style={{ textDecoration: "underline" }}
                    href="https://www.recruitifi.com/agency-recruiters"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more.
                  </a>
                </div>
                <br />
                <h4> How you work on RecruitiFi</h4>
                <span style={{ position: "relative", top: "5px" }}>
                  <RFIFeaturesGraphic />
                </span>

                <span style={{ position: "relative", left: "24px" }}>
                  <Checkbox
                    id="rfi-terms-checkbox"
                    label={(
                      <span style={{ fontSize: "14px", paddingBottom: "1px" }}>
                        {" "}
                        I accept the
                        {" "}
                        <a
                          style={{ fontWeight: "bold", color: "#008DAE" }}
                          href="https://www.recruitifi.com/terms/"
                        >
                          RecruitiFi online terms
                        </a>
                      </span>
                    )}
                    error={Boolean(error)}
                    helperText={error}
                    onChange={handleChange}
                  />
                </span>
              </ActionModalContent>
              <ActionModalFooter
                isSubmitting={isSubmitting}
                actionText="Search open jobs"
                handleAction={handleSubmit}
                submissionError={error}
                color="blue"
                varient="primary"
              />
            </>
          )}
        </Formik>
      </Dialog>
    </ThemeProvider>
  );
}

export default TadMarketRfiModal;
