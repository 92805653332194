import {
  SET_PROFILE_DRAWER_VISIBLE,
  SET_PROFILE_DRAWER_CONTENT_LOADING,
  SET_PROFILE_DRAWER_HEADER_TEXT,
  OPEN_PROFILE_DRAWER,
  CLEAR_PROFILE_DRAWER,
} from "../../actions/shared/uiActions";

const _defaultState = {
  visible: false,
  loading: false,
  headerText: "",
  content: {},
};

export const profileDrawerReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case SET_PROFILE_DRAWER_VISIBLE:
      return { ...state, visible: action.payload };
    case SET_PROFILE_DRAWER_CONTENT_LOADING:
      return { ...state, loading: action.payload };
    case OPEN_PROFILE_DRAWER:
      return {
        ...state,
        content: action.payload,
        visible: true,
        meta: action.meta,
      };
    case SET_PROFILE_DRAWER_HEADER_TEXT:
      return { ...state, headerText: action.payload };
    case CLEAR_PROFILE_DRAWER:
      return _defaultState;
    default:
      return state;
  }
};
