import normalize from "json-api-normalizer";

import {
  POST, GET, PATCH, DELETE
} from "../../util/apiHelpers";

export const RECEIVE_RECRUITER_LIST = "RECEIVE_RECRUITER_LIST";
export const RECEIVE_RECRUITER_LISTS = "RECEIVE_RECRUITER_LISTS";
export const REMOVE_RECRUITER_LIST = "REMOVE_RECRUITER_LIST";
export const REMOVE_RECRUITER_LISTS = "REMOVE_RECRUITER_LISTS";

export const receiveRecruiterLists = (recruiterLists) => ({
  type: RECEIVE_RECRUITER_LISTS,
  payload: recruiterLists,
});

export const removeRecruiterLists = (recruiterListIds) => ({
  type: REMOVE_RECRUITER_LISTS,
  payload: recruiterListIds,
});

export const receiveRecruiterList = (recruiterList) => ({
  type: RECEIVE_RECRUITER_LIST,
  payload: recruiterList,
});

export const removeRecruiterList = (id) => ({
  type: REMOVE_RECRUITER_LIST,
  payload: id,
});

export const fetchRecruiterLists = () => (dispatch) => GET("/api/v3/employer/recruiter_lists").then((res) => {
  const normalized = normalize(res.data);

  dispatch(receiveRecruiterLists(normalized.recruiterList));
});

export const fetchRecruiterList = (id) => (dispatch) => GET(`/api/v3/employer/recruiter_lists/${id}`).then((res) => {
  const normalized = normalize(res.data);

  dispatch(receiveRecruiterList(normalized.recruiterList[res.data.data.id]));
});

export const createRecruiterList = (data) => (dispatch) => POST("/api/v3/employer/recruiter_lists", data)
  .then((res) => {
    const normalized = normalize(res.data);
    dispatch(
      receiveRecruiterList(normalized.recruiterList[res.data.data.id])
    );
    return normalized.recruiterList[res.data.data.id];
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const updateRecruiterList = (data) => (dispatch) => PATCH(`/api/v3/employer/recruiter_lists/${data.id}`, data)
  .then((res) => {
    const normalized = normalize(res.data);
    return dispatch(
      receiveRecruiterList(normalized.recruiterList[res.data.data.id])
    );
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const addToRecruiterLists = (recruiterId, data) => (dispatch) => POST(
  `/api/v3/employer/agencies/${recruiterId}/add_or_remove_from_lists`,
  data
)
  .then((res) => {
    const normalized = normalize(res.data);

    return dispatch(receiveRecruiterLists(normalized.recruiterList));
  })
  .catch((errors) => Promise.reject(errors.response.data));

export const deleteRecruiterList = (id) => (dispatch) => DELETE(`/api/v3/employer/recruiter_lists/${id}`)
  .then(() => {
    dispatch(removeRecruiterList(id));
    Promise.resolve();
  })
  .catch((res) => Promise.reject(res));
