import React from "react";

import PageCardRowHeader from "../../../General/PageCardRowHeader";

import FieldSectionContainer from "./FieldContainer";
import FeeOptionsField from "./shared/FeeOptionsField";
import FeeTypeField from "./shared/FeeTypeField";
import PositionAssignmentsField from "./shared/PositionAssignmentsField";

function PermanentPlacement({ editFees = false }) {
  return (
    <>
      <PageCardRowHeader title="Permanent Placement" />
      <FieldSectionContainer>
        <FeeTypeField scope="perm" label="Select a fee type" />
        <FeeOptionsField scope="perm" />
        {!editFees && <PositionAssignmentsField scope="perm" />}
      </FieldSectionContainer>
    </>
  );
}

export default PermanentPlacement;
