import { connect } from "formik";
import pick from "lodash/pick";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { getJobCast } from "../../../../selectors/jobcastSelectors";
import BillingInfo from "../../JobCasts/view/Candidates/actions/modals/Hire/BillingInfo";
import HireInformation from "../../JobCasts/view/Candidates/actions/modals/Hire/HireInformation";

import EditHireInfo from "./EditHireInfo";
import PlacementDetails from "./PlacementDetails";

function EditHireDetailsModalContents({ formik, placement, candidate }) {
  const formProps = pick(formik, [
    "values",
    "errors",
    "touched",
    "handleChange",
    "handleBlur",
    "setFieldValue",
  ]);
  const { positionId } = placement.attributes;
  const jobcast = useSelector((state) => getJobCast(state, positionId));

  return (
    <>
      <EditHireInfo />
      <PlacementDetails {...{ placement, jobcast, candidate }} />
      <HireInformation {...formProps} jobcast={jobcast} />
      <BillingInfo {...formProps} candidate={candidate} jobcast={jobcast} />
    </>
  );
}

EditHireDetailsModalContents.propTypes = {
  formik: PropTypes.object.isRequired,
  placement: PropTypes.object.isRequired,
  candidate: PropTypes.object.isRequired,
};

export default connect(EditHireDetailsModalContents);
