import { connect } from "formik";
import React from "react";

import MultiLineTextField from "../../../../forms/custom/MultiLineTextField";

function RemoveFromCommunityModalContent({
  agencyName,
  employerName,
  formik: {
    values, errors, touched, handleChange, handleBlur
  },
}) {
  return (
    <>
      <div>
        {`Are you sure you would like to remove ${agencyName} from your
        community? No recruiters from this agency will receive new JobCasts from
        ${employerName}.`}
      </div>
      <br />

      <MultiLineTextField
        error={Boolean(errors.removeReason && touched.removeReason)}
        helperText={touched.removeReason && errors.removeReason}
        label="Why are you removing this agency?"
        onChange={handleChange}
        value={values.removeReason}
        id="removeReason"
        rows={3}
        onBlur={handleBlur}
        style={{ marginBottom: 0 }}
      />
    </>
  );
}

export default connect(RemoveFromCommunityModalContent);
