import filter from "lodash/filter";
import React from "react";
import { connect } from "react-redux";

import { fetchCandidate } from "../../../../../../actions/employers/candidateActions";
import { fetchJobCast } from "../../../../../../actions/employers/jobcastActions";
import { getCandidate } from "../../../../../../selectors/candidateSelectors";
import { getCurrentIndividual } from "../../../../../../selectors/individualSelectors";
import {
  selectAttributes,
  getJobCast,
} from "../../../../../../selectors/jobcastSelectors";
import {
  makeGetFilteredNotifications,
  UNREAD,
} from "../../../../../../selectors/notificationsSelectors";
import CandidateDrawer from "../../../../../shared/CandidateDrawer/CandidateDrawer";
import TempCandidateDrawer from "../../../../../shared/TempCandidateDrawer/TempCandidateDrawer";

function TypedCandidateDrawer(props) {
  const { candidate } = props;

  return candidate?.attributes?.temp ? (
    <TempCandidateDrawer {...props} />
  ) : (
    <CandidateDrawer {...props} />
  );
}

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();

  const mapStateToProps = (state, ownProps) => {
    const notifications = getFilteredNotifications(state, {
      [UNREAD]: true,
      recruiterSubmissionId: ownProps.candidateId,
    });

    const unread_chat_count = filter(
      notifications,
      (notification) => notification.attributes.notificationSubjectType === "Message"
    ).length;

    const unread_history_count = filter(
      notifications,
      (notification) => notification.attributes.notificationSubjectType ===
        "EmployerCandidateUpdateNotification"
    ).length;

    const candidate = getCandidate(state, ownProps.candidateId);
    const jobcast =
      candidate && getJobCast(state, candidate.attributes.jobcastId);

    const currentIndividual = getCurrentIndividual(state);

    return {
      unread_chat_count,
      unread_history_count,
      candidate,
      jobcast,
      loading: !jobcast,
      jobcastStatus:
        candidate &&
        jobcast &&
        selectAttributes(getJobCast(state, candidate.attributes.jobcastId), [
          "status",
        ]).status,
      currentIndividual,
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchCandidate: () => dispatch(fetchCandidate(ownProps.candidateId)),
  fetchJobcast: (jobcastId) => dispatch(fetchJobCast(jobcastId)),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(TypedCandidateDrawer);
