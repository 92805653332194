import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import classnames from "classnames";
import PackageDown from "mdi-material-ui/PackageDown";
import PropTypes from "prop-types";
import React from "react";

import { disabledProps } from "../../../../../../util/formHelpers";

const useStyles = makeStyles(() => ({
  reasonContainer: {
    backgroundColor: "rgba(176, 190, 197, 0.08)",
    paddingRight: 24,
    paddingTop: 5,
    marginBottom: 5,
    borderLeft: "4px solid #90A4AE",
    display: "flex",
    flexDirection: "row",
    minHeight: 60,
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: 16,
  },
  icon: {
    color: "#90A4AE",
    marginLeft: 20,
    marginRight: 20,
    fontSize: 24,
  },
  iconSubtext: {
    textAlign: "center",
  },
}));

function PositionArchived({ jobcast }) {
  const classes = useStyles();

  return (
    <div className={classes.reasonContainer}>
      <div className={classes.iconContainer}>
        <PackageDown classes={{ root: classes.icon }} />
        <div className={classnames("gray", "caption", classes.iconSubtext)}>
          Close Reason
        </div>
      </div>
      <TextField
        {...disabledProps(true)}
        id={`close-reason-${jobcast.id}`}
        label="Close Reason"
        value={jobcast.attributes.earlyCloseExplanation}
        multiline
      />
    </div>
  );
}

PositionArchived.propTypes = {
  jobcast: PropTypes.object.isRequired,
};

export default PositionArchived;
