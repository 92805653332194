import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

const useStyles = makeStyles(() => ({
  logoAndSubNav: {
    display: "flex",
    alignItems: "center",
    minWidth: 64,
    maxWidth: "148px",
    padding: "0",
    margin: "0 24px 0 12px",
  },
}));

const LogoAndSubNav = ({ isTalkOnly, isPublicSignup, loaded }) => {
  const classes = useStyles();

  let logoClass = "logo";
  let height = "54px";
  let width = "100%";
  let backgroundPosition = "";
  let title = "RecruitiFi";

  if (isTalkOnly) {
    title = "TALK";
    logoClass = "talk-only-logo";
    height = "44px";
    width = "115px";
  } else if (isPublicSignup) {
    logoClass = "logo-full";
    height = "72px";
    width = "148px";
    backgroundPosition = "center";
  }

  let redirectProps = {
    href: "https://www.recruitifi.com/",
    target: "_blank",
  };

  if (isTalkOnly) {
    redirectProps = {
      href: "https://talktalent.com/agency-directory/for-agencies",
      target: "_blank",
    };
  } else if (loaded) {
    redirectProps = { href: "/app" };
  }

  return (
    <div className={classes.logoAndSubNav}>
      <a
        title={title}
        className={logoClass}
        style={{
          display: "block",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          height,
          width,
          backgroundPosition,
        }}
        {...redirectProps}
      >
        {" "}
      </a>
    </div>
  );
};

export default LogoAndSubNav;
