import cloneDeep from "lodash/cloneDeep";
import concat from "lodash/concat";
import map from "lodash/map";

import merge from "lodash/merge";

import {
  RECEIVE_SEARCH_RESULTS,
  CLEAR_SEARCH_RESULTS,
  SET_LOADING_STATE,
  UPDATE_TERM,
} from "../actions/shared/searchActions";

const _defaultState = {
  items: [],
  searchedTerms: new Set(),
  results: {},
  term: "",
  loading: false,
};

export const searchReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_SEARCH_RESULTS:
      const newState = cloneDeep(state);

      const formattedItems = map(action.payload.results, (searchItemData) => ({
        term: action.payload.term,
        ...searchItemData.attributes,
      }));

      newState.items = formattedItems;
      newState.results = concat(newState.results, formattedItems);
      newState.searchedTerms.add(action.payload.term);

      return newState;
    case CLEAR_SEARCH_RESULTS:
      return _defaultState;
    case UPDATE_TERM:
      return merge({}, state, { term: action.payload });
    case SET_LOADING_STATE:
      return merge({}, state, {
        loading: action.payload,
      });
    default:
      return state;
  }
};
