import get from "lodash/get";
import keys from "lodash/keys";
import map from "lodash/map";
import sortBy from "lodash/sortBy";

import constants from "./constants";

/**
 * @callback sccb
 * @param {Object | string} value - The value of the constant for a key/value pair
 * @returns {string}
 */

/**
 * @typedef {Object} scOptions
 * @property {(sccb)=} label - a function to define the label (constant's value by default)
 * @property {(sccb)=} value - a function to define the value (constant's key by default)
 * @property {(sccb)=} sort - a function that overrides the default sorting
 * @property {(boolean)=} selected
 */

/**
 * Maps constants to label/value objects for select/dropdown inputs
 * @param {string} constant - the constant to use
 * @param {scOptions=} options
 * @returns {{ label: string, value: string, selected?: boolean}[]}
 */
export const createOptionsFrom = (constant, options = {}) => {
  const {
    label = undefined, value = undefined, sort = undefined, selected = undefined
  } = options;
  return sortBy(
    map(keys(constants[constant]), (key) => ({
      label: (
        label !== undefined ? label(constants[constant][key]) : constants[constant][key]
      ),
      value: (
        value !== undefined ? value(constants[constant][key]) : key
      ),
      ...(selected !== undefined && { selected }),
      ...(sort !== undefined && { sort: sort(constants[constant][key]) })
    })),
    (option) => ((sort !== undefined) ? option.sort : option.label)
  );
};

/* The following are common implementations of the Options from Constant mappings */
export const roleSpecialtyOptions = map(constants.rolesOptions, (value) => ({
  value,
  label: value,
}));

export const placementTypeOptions = map(constants.placementTypeOptions, (placementType) => ({
  label: placementType.label,
  value: placementType.value,
}));

export const currencyOptions = createOptionsFrom(
  "currency_options",
  {
    label: (v) => `${v.notation} ${v.code}`,
    value: (v) => v.code,
    sort: (v) => v.code
  }
);

export const currencyOptionsNoKey = createOptionsFrom(
  "currency_options",
  {
    label: (v) => `${v.notation} ${v.code}`,
    value: (v) => `${v.notation} ${v.code}`,
    sort: (v) => v.code
  }
);

export const currencySymbol = (currency) => (
  currency && constants.currency_options[currency]?.notation
);

export const currencyCode = (currency = "") => (
  currency.split(" ").slice(-1)
);

export const stateOptions = createOptionsFrom("us_states");
export const stateOptionsNoKey = createOptionsFrom("us_states", { value: (v) => v });

export const provinceOptions = createOptionsFrom("ca_provinces");
export const provinceOptionsNoKey = createOptionsFrom("ca_provinces", { value: (v) => v });

export const stateOrProvinceOptions = (countryCode = "", valueIsLabel = false) => {
  if (countryCode === "US" || countryCode === "USA") return valueIsLabel ? stateOptionsNoKey : stateOptions;
  if (countryCode === "CA" || countryCode === "CAN") return valueIsLabel ? provinceOptionsNoKey : provinceOptions;
  return [];
};

/* Uses list of all countries */
export const countryNameOptions = createOptionsFrom("countries", {
  label: (v) => v.name,
  value: (v) => v.name
});

export const alpha2CountryOptions = createOptionsFrom("countries", {
  label: (v) => v.name,
  value: (v) => v.alpha2
});

export const alpha3CountryOptions = createOptionsFrom("countries", {
  label: (v) => v.name
});

/** Uses 3 letter country codes, set of only countries we operate in */
export const servicedCountryOptions = createOptionsFrom("regions");
export const servicedCountryNameOptions = createOptionsFrom("regions", { value: (v) => v });

export const alpha3ToName = (alpha3) => {
  const country = get(constants.countries, alpha3);
  return country ? country.name : "";
};

/** Finds a country from its alpha2 code
 * @param {string} alpha2
*/
export const alpha2ToCountry = (alpha2) => {
  const country = Object.entries(constants.countries).find(([, value]) => value.alpha2 === alpha2);
  return country ? { ...country[1], alpha3: country[0] } : null;
};
