import { ResponsiveBar } from '@nivo/bar';
import React from "react";

import PageCardRowHeader from "../../../../../../General/PageCardRowHeader";

const MyResponsiveBar = ({ data, title }) => (
  <div style={{ width: "50%", height: "50%", display: 'inline-block' }}>
    <h5 style={{ marginLeft: 40 }}>
      {title}
    </h5>
    <ResponsiveBar
      data={data}
      keys={[
        'star',
      ]}
      indexBy="rating"
      margin={{
        top: 50, right: 130, bottom: 50, left: 60
      }}
      colors={{ scheme: 'paired' }}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Rating',
        legendPosition: 'middle',
        legendOffset: 32
      }}
      axisLeft={null}
      enableGridY={false}
      padding={0.05}
    />
  </div>
);

function CandidateFitRatings({ individual, organization }) {
  const noIndividualRatings = individual.every(item => item.star === 0);
  const noOrganizationRatings = organization.every(item => item.star === 0);

  if (noIndividualRatings && noOrganizationRatings) {
    return null;
  }

  return (
    <>
      <PageCardRowHeader title="Candidate Fit Ratings" />
      {!noIndividualRatings &&
        <MyResponsiveBar data={individual} title="Individual Candidate Fit Ratings" />}
      {!noOrganizationRatings &&
        <MyResponsiveBar data={organization} title="Agency Candidate Fit Ratings" />}
    </>
  );
}

export default CandidateFitRatings;
