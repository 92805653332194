import { useFormikContext } from "formik";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";

import FileInput from "../../custom/FileInput.react";

function FormContextFileInput({ id, customHelperText, ...passThroughProps }) {
  const { values, errors, touched, setFieldValue } = useFormikContext();

  const value = get(values, id);

  const errorMessage = get(touched, id) && get(errors, id);

  const handleChange = (_, newFile) => setFieldValue(id, newFile);

  return (
    <FileInput
      {...{ id, value }}
      helperText={errorMessage || customHelperText}
      error={Boolean(errorMessage)}
      onChange={handleChange}
      {...passThroughProps}
    />
  );
}

FormContextFileInput.defaultProps = {
  customHelperText: "",
};

FormContextFileInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  customHelperText: PropTypes.node,
};

export default FormContextFileInput;
