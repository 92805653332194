import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { extendJobcast } from "../../../../../../actions/employers/jobcastActions";

import Button from "../../../../../forms/custom/Button";
import useSnackbar from "../../../../../General/customHooks/useSnackbar";

import ArchiveForm from "./ArchiveForm";

const useStyles = makeStyles(() => ({
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
  },
}));

function ApproachingArchiveResolution({ jobcast }) {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const [isArchiving, setIsArchiving] = useState(null);

  const classes = useStyles();

  const handleExtend = () => {
    dispatch(extendJobcast(jobcast.id)).then(() => {
      showMessage("JobCast has been extended");
    });
  };

  return (
    <>
      <div className="semibold">Are you still working on this JobCast?</div>
      {isArchiving ? (
        <ArchiveForm
          jobcast={jobcast}
          handleCancel={() => setIsArchiving(false)}
        />
      ) : (
        <div className={classes.buttonContainer}>
          <Button
            variant="secondary"
            color="blue"
            onClick={() => setIsArchiving(true)}
            style={{ marginRight: 8 }}
          >
            No
          </Button>
          <Button variant="primary" color="blue" onClick={handleExtend}>
            Yes
          </Button>
        </div>
      )}
    </>
  );
}

ApproachingArchiveResolution.propTypes = {
  jobcast: PropTypes.object.isRequired,
};

export default ApproachingArchiveResolution;
