import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import classnames from "classnames";
import compact from "lodash/compact";
import isNumber from "lodash/isNumber";
import map from "lodash/map";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";

const styles = () => ({
  tooltip: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
  },
  multiLocationText: {
    color: "inherit",
    textDecoration: "none",
    "&$blue": {
      color: "#008DAE",
    },
    "&:hover": {
      cursor: "pointer",
      color: "#b0bec5",
    },
    "&$selected": {
      color: "#008DAE",
    },
    "&$highlightedLink": {
      color: "#008DAE",
    },
  },
  blue: {},
});

function FullLocationText({
  renderedLocationsList,
  maxWidth,
  preLocationText,
  postLocationText,
}) {
  const [exceedsMaxWidth, setExceedsMaxWidth] = useState(false);
  const innerText = useRef();

  useEffect(() => {
    if (
      isNumber(maxWidth) &&
      innerText.current?.offsetWidth > maxWidth
    ) {
      setExceedsMaxWidth(true);
    }
  }, [maxWidth, innerText]);

  function TextContent() {
    return (
      <>
        {preLocationText}
        <span>{renderedLocationsList}</span>
        {postLocationText}
      </>
    );
  }

  return (
    <Tooltip title={<TextContent />} disableHoverListener={!exceedsMaxWidth}>
      <div
        ref={innerText}
        style={
          // if we set this styling unconditionally, the max width will never be exceeded, so the tooltip won't know to appear
          exceedsMaxWidth
            ? {
              maxWidth,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }
            : {}
        }
      >
        <TextContent />
      </div>
    </Tooltip>
  );
}

function LocationCountLink({
  renderedLocationsList,
  preLocationText,
  postLocationText,
  placement,
  title,
  disableClick,
  color,
  classes,
}) {
  const [displayLocations, setDisplayLocations] = useState(false);

  function TooltipContent() {
    return (
      <div className={classes.tooltip}>
        <div style={{ marginBottom: 12 }}>Any of the following:</div>
        {renderedLocationsList}
      </div>
    );
  }

  const handleLinkClick = () => !disableClick && setDisplayLocations(!displayLocations);

  return (
    <ClickAwayListener onClickAway={() => setDisplayLocations(false)}>
      <Tooltip
        open={displayLocations}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement={placement}
        title={<TooltipContent />}
      >
        <div onClick={handleLinkClick}>
          {preLocationText}
          <span
            className={classnames({
              [classes.multiLocationText]: true,
              [classes[color]]: true,
            })}
            data-cy={`${title}-num-location-text`}
          >
            {`${renderedLocationsList.length} Locations`}
          </span>
          {postLocationText}
        </div>
      </Tooltip>
    </ClickAwayListener>
  );
}

function LocationText({ location }) {
  let text;

  if (location.isRemote) {
    text = `Remote, ${location.countryCode}`;
  } else if (location.city === "Off-Platform") {
    text = location.state;
  } else {
    text = compact([location.city, location.state]).join(", ");
  }

  return (<span data-cy="tooltip-location">{text}</span>);
}

function JobCastLocations({
  locations, fullLocationString, containsUnitedStates, ...props
}) {
  const renderedLocationsList = map(
    locations,
    ({
      state_us, postal_code_us, country_code, is_remote, ...rest
    }, index) => (
      /* This override is because input can be inconsistent with casing */
      <LocationText
        key={`${country_code}${postal_code_us}${index}`}
        location={{
          countryCode: country_code,
          isRemote: is_remote,
          ...rest,
          ...(containsUnitedStates ? { state: state_us, postalCode: postal_code_us } : {})
        }}
      />
    )
  );

  return (locations.length > 1 && !fullLocationString)
    ? <LocationCountLink renderedLocationsList={renderedLocationsList} {...props} />
    : <FullLocationText renderedLocationsList={renderedLocationsList} {...props} />;
}

JobCastLocations.propTypes = {
  color: PropTypes.string,
  locations: PropTypes.array.isRequired,
  containsUnitedStates: PropTypes.bool,
  fullLocationString: PropTypes.bool,
  placement: PropTypes.string,
  preLocationText: PropTypes.string,
  postLocationText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disableClick: PropTypes.bool,
  maxWidth: PropTypes.number,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(JobCastLocations);
