import { FormHelperText, InputLabel } from "@material-ui/core";
import { useFormikContext } from "formik";
import get from "lodash/get";
import React from "react";

import FormContextCheckbox from "../../../forms/formik/FormContextInputs/FormContextCheckbox";

function PlacementTypeSelection() {
  const { errors, touched } = useFormikContext();

  const errorMessage =
    get(touched, "placementTypes") && get(errors, "placementTypes");

  return (
    <>
      <InputLabel style={{ marginTop: 16, marginBottom: 8 }}>
        What types of placements will this agency make? Check all that apply
      </InputLabel>
      <div style={{ marginLeft: 12 }}>
        <FormContextCheckbox
          id="placementTypes.perm"
          label="Permanent Placement"
        />
        <FormContextCheckbox
          id="placementTypes.temp"
          label="Contract Workforce"
        />
      </div>
      {errorMessage && (
        <FormHelperText
          className="helper-text error"
          style={{ margin: "-8px 0px 0px 0px" }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </>
  );
}

export default PlacementTypeSelection;
