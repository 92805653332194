import React, { useState } from "react";

import { buildFullName } from "../../../../../../../util/formatHelpers";
import ActionModalContainer from "../../../../../../General/ActionModal/ActionModalContainer";
import ActionModalHeader from "../../../../../../General/ActionModal/ActionModalHeader";

import HireModalFormContent from "./Hire/HireModalFormContent";
import RateRecruiterModalContent from "./RateRecruiter/RateRecruiterModalContent";

function HireModal({ candidate }) {
  const [hireSubmitted, setHireSubmitted] = useState(false);

  return (
    <ActionModalContainer color="blue">
      <ActionModalHeader
        title="Hire Candidate"
        subtitle={buildFullName(
          candidate.attributes.firstName,
          candidate.attributes.lastName
        )}
      />
      {hireSubmitted ? (
        <RateRecruiterModalContent candidateId={candidate.id} />
      ) : (
        <HireModalFormContent
          candidate={candidate}
          setHireSubmitted={setHireSubmitted}
        />
      )}
    </ActionModalContainer>
  );
}

export default HireModal;
