import merge from "lodash/merge";

import { RECEIVE_PAYMENT_METHOD } from "../../actions/shared/financeActions";

const defaultState = {
  loaded: false,
  items: {},
  error: false,
};

const paymentMethodReducer = (state = defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_PAYMENT_METHOD: {
      const { error = false, ...payload } = action.payload;
      return merge({}, state, { loaded: true, items: payload, error });
    }
    default:
      return state;
  }
};

export default paymentMethodReducer;
