import normalize from "json-api-normalizer";

import { POST } from "../../util/apiHelpers";
import { mapKeysToSnakeCase } from "../../util/formatHelpers";
import { receiveCandidates } from "../shared/candidateActions";

// eslint-disable-next-line import/prefer-default-export
export const createAgencyExperienceReview = (data) => (_dispatch) =>
  POST(
    "/api/v3/employer/reviews/create_agency_experience_review",
    mapKeysToSnakeCase(data)
  )
    .then(() => Promise.resolve())
    .catch((error) => Promise.reject(error));

export const createCandidateFitReview = (data) => (dispatch) =>
  POST(
    "/api/v3/employer/reviews/create_candidate_fit_review",
    mapKeysToSnakeCase(data)
  )
    .then((res) => {
      const normalized = normalize(res.data);
      dispatch(receiveCandidates(normalized.candidate));
    })
    .catch((error) => Promise.reject(error));
