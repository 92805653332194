import { useFormikContext } from "formik";
import map from "lodash/map";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchJobCasts } from "../../../../../actions/employers/jobcastActions";
import { makeGetFilteredJobcasts } from "../../../../../selectors/jobcastSelectors";
import FormikAccordionInput from "../../../../forms/formik/FormikAccordionInput";
import {
  ERROR,
  IDLE,
  LOADED,
  LOADING,
} from "../../../../General/customHooks/usePagination";
import JobCastLocations from "../../../../General/JobCastLocations";

function PositionAssignmentsField({ scope }) {
  const [positionsLoadStatus, setPositionsLoadStatus] = useState(IDLE);

  const { values, errors } = useFormikContext();

  const getPositions = useMemo(makeGetFilteredJobcasts, []);

  const positions = useSelector((state) =>
    getPositions(state, null, { status: "LIVE", isTemp: scope === "temp" })
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (positionsLoadStatus === IDLE) {
      setPositionsLoadStatus(LOADING);

      dispatch(fetchJobCasts("all", false, scope === "temp"))
        .then(() => {
          setPositionsLoadStatus(LOADED);
        })
        .catch(() => {
          setPositionsLoadStatus(ERROR);
        });
    }
  }, [dispatch, scope, positionsLoadStatus, setPositionsLoadStatus]);

  const options = useMemo(
    () =>
      map(positions, ({ attributes }) => ({
        label: (
          <JobCastLocations
            locations={attributes.locations}
            preLocationText={`${attributes.title} - `}
          />
        ),
        value: attributes.id,
      })),
    [positions]
  );

  if (positionsLoadStatus !== LOADED) return null;

  return (
    <FormikAccordionInput
      id={`${scope}.positionAssignments`}
      label="JobCast Assignments"
      title="Open Jobcasts"
      fieldArrayName={`${scope}.positionAssignments`}
      formProps={{
        values,
        errors,
      }}
      options={options}
    />
  );
}

export default PositionAssignmentsField;
