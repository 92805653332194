/* eslint no-param-reassign: off */
import {
  InputLabel, makeStyles, FormHelperText
} from "@material-ui/core";
import { DataGrid, GridCellModes } from '@mui/x-data-grid';
import { useField, useFormikContext } from "formik";
import React, {
  useCallback, useState
} from "react";

import Button from "../../../../../forms/custom/Button";

import QuickFill from "./QuickFill";
import { columns, otRoles, excludeKeys } from './utils';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      color: theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
      letterSpacing: 'normal',
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.type === 'light' ? '#EDEDED' : '#1d1d1d',
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeader:first-of-type .MuiDataGrid-columnHeaderTitle': {
        color: 'transparent',
        userSelect: 'none',
      },
      '& .MuiDataGrid-columnHeader:last-of-type .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 700,
      },
      '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid ${
          theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
        }`,
      },
      '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${
          theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
        }`,
      },
      '& .MuiDataGrid-row.Mui-selected, .MuiDataGrid-row.Mui-selected:hover': {
        backgroundColor: '#fff',
      },
      '& .MuiDataGrid-row .MuiDataGrid-cell:first-of-type, .MuiDataGrid-row .MuiDataGrid-cell:last-of-type': {
        fontWeight: 700,
      },
      '& .MuiDataGrid-row .MuiDataGrid-cell:last-of-type': {
        backgroundColor: theme.palette.type === 'light' ? '#EDEDED' : '#1d1d1d',
      },
      '& .MuiDataGrid-cell': {
        color: theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)',
      },
      '& .MuiDataGrid-cell--editing': {
        backgroundColor: 'rgb(255,215,115, 0.19)',
      },
      '& .MuiDataGrid-editInputCell': {
        color: theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
      },
      '& .Mui-error': {
        backgroundColor: `rgb(126,10,15, ${theme.palette.type === 'light' ? 0.1 : 0})`,
        color: theme.palette.type === 'light' ? '#750f0f' : '#ff4343',
      },
    },
  })
);

/**
 * @param {Array} rows
 * @param {Number} row
 * @param {String} day
 * @param {String} value
 */
const checkDailySum = (rows, row, day, value) => {
  let daily = 0;
  for (let i = 0; i < rows.length; i++) {
    if (i === row) {
      daily += (Number(value) || 0); // use updated value
    } else {
      daily += Number(rows[i][day]);
    }
  }
  return daily;
};

const roundToTwo = (num) => Math.round((+num + Number.EPSILON) * 100) / 100;

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const getRow = (rows, id) => rows.findIndex((row) => row.id === id);

export default function TimesheetHoursTable({ disabled }) {
  const classes = useStyles();
  const [
    { value: rows }, { error, touched }, { setValue: setRows, setTouched }
  ] = useField("timesheetHours");
  const { initialValues } = useFormikContext();
  const [editRowsModel, setEditRowsModel] = useState({});

  const validateCells = useCallback((model, type) => {
    const row = getRow(rows, type);
    let errorDays = "";
    Object.entries(model[type]).forEach(([day, { value }]) => {
      let error = false;

      if (Number.isNaN(Number(value))) {
        error = true;
      }

      if (checkDailySum(rows, row, day, value) > 24) {
        if (value !== "") {
          error = true;
        }
        errorDays += `${capitalize(day)}, `;
      }

      model[type][day].error = error;
    });
    return errorDays;
  }, [rows]);

  const handleCellClick = useCallback((params, event) => {
    if (params.isEditable) {
      setEditRowsModel(prevModel => {
        const prevRow = Object.keys(prevModel)[0];
        if (prevRow === params.id) {
          return prevModel;
        }
        const model = {
          [params.id]: {
            ...Object.entries(params.row)
              .filter(excludeKeys)
              .reduce(
                (acc, [id, value]) => ({ ...acc, [id]: { value, mode: GridCellModes.Edit } }),
                {}
              ),
          }
        };
        // Prevent an error when switching between rows without committing
        if (prevRow) {
          model[prevRow] = Object.entries(prevModel[prevRow])
            .reduce(
              (acc, [id, { value }]) => ({ ...acc, [id]: { value, mode: GridCellModes.View } }),
              {}
            );
        }
        validateCells(model, params.id);
        return model;
      });
      event.defaultMuiPrevented = true;
    }
  }, [validateCells]);

  const handleEditRowsModelChange = useCallback((model) => {
    if (disabled) {
      setEditRowsModel({});
      setRows(initialValues.timesheetHours);
    } else {
      const type = Object.keys(model)[0];
      if (type) {
        const errorDays = validateCells(model, type);
        const error = errorDays ? `Daily hours cannot exceed 24 hours: ${errorDays.slice(0, -2)}` : "";

        const newRows = rows.slice();
        const total = Object.values(model[type])
          .reduce((acc, { value }) => (acc + (Number(value) || 0)), 0);
        newRows[getRow(rows, type)] = Object.entries(model[type])
          .reduce((acc, [day, { value }]) => {
            return { ...acc, [day]: Number.isNaN(Number(value)) ? "" : value };
          }, { id: type, total, ...(error && { error }) });
        if (error) {
          setTouched(true, false); // don't validate twice
        }
        setRows(newRows);
      }

      setEditRowsModel(model);
    }
  }, [disabled, rows, initialValues.timesheetHours, validateCells, setRows, setTouched]);

  const handleRowEditCommit = useCallback(
    (type) => {
      setTouched(true, false); // don't validate twice

      const row = getRow(rows, type);
      const newRows = rows.slice();
      // convert to 2 decimal places on save
      newRows[row] = Object.entries(editRowsModel[type])
        .reduce((acc, [day, { value }]) => ({
          ...acc,
          [day]: (value === "" || Number.isNaN(Number(value))) ? "" : roundToTwo(value).toString()
        }), { id: type, total: roundToTwo(newRows[row].total) });
      setRows(newRows);
    },
    [editRowsModel, rows, setRows, setTouched]
  );

  const handleAddOvertime = () => {
    const newRows = rows.slice();
    if (!newRows.some((row) => row.id === otRoles[0].id)) {
      newRows.splice(1, 0, otRoles[0]);
    }
    if (!newRows.some((row) => row.id === otRoles[1].id)) {
      newRows.splice(2, 0, otRoles[1]);
    }
    setRows(newRows);
  };

  return (
    <>
      <InputLabel component="legend" style={{ marginBottom: "16px" }}>
        Quick Fill
      </InputLabel>
      <QuickFill {...{
        editRowsModel, setEditRowsModel, disabled
      }}
      />
      <InputLabel component="legend" style={{ marginBottom: "16px", marginTop: "32px" }}>
        Weekly Timesheet
      </InputLabel>
      <DataGrid
        className={classes.root}
        editMode="row"
        rows={rows}
        columns={columns}
        editRowsModel={editRowsModel}
        onEditRowsModelChange={handleEditRowsModelChange}
        onRowEditCommit={handleRowEditCommit}
        onCellClick={handleCellClick}
        hideFooter
        disableColumnFilter
        disableColumnMenu
        autoHeight
        isCellEditable={() => !disabled}
      />
      {(rows.length < 3 && !disabled) && (
        <span>
          <Button
            style={{ border: "none", padding: 0 }}
            variant="secondary"
            color="blue"
            onClick={handleAddOvertime}
            data-cy="add-overtime-hours-button"
          >
            + Add Overtime Hours
          </Button>
        </span>
      )}
      <div style={{ minHeight: "24px" }}>
        {((touched && Boolean(error))) && (
          <FormHelperText
            style={({ color: "#FF6D6D", marginTop: 0, fontSize: "11px" })}
            data-cy="timesheet-hours-error-text"
          >
            {error}
          </FormHelperText>
        )}
      </div>
    </>
  );
}
