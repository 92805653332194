import { makeStyles } from "@material-ui/core";
import React from "react";

import PopoverLink from "../../../../General/PopoverLink";

const useStyles = makeStyles({
  linkText: {
    color: "#008DAE",
    "&:hover": {
      textDecoration: "underline",
      color: "#B0BEC5",
      cursor: "pointer",
    },
  },
  popoverContent: {
    minWidth: "150px",
    minHeight: "48px",
    padding: "6px 8px",
    maxWidth: "50vw",
  },
});

const PreAssignmentDescriptionPopover = ({ description, name }) => {
  const classes = useStyles();
  return description ? (
    <PopoverLink
      renderLink={(handleClick) => (
        <span
          className={classes.linkText}
          onClick={handleClick}
          role="button"
          data-cy="pre-assignment-description-link"
        >
          More Info
        </span>
      )}
      renderPopoverContent={() => (
        <div
          className={classes.popoverContent}
          data-cy="pre-assignment-description-popover"
        >
          <div className="bold" style={{ fontSize: "14px" }}>
            {name}
          </div>
          <div style={{ whiteSpace: "pre-wrap" }}>
            {description}
          </div>
        </div>
      )}
    />
  ) : null;
};

export default PreAssignmentDescriptionPopover;
