import { withStyles } from "@material-ui/core/styles";
import React from "react";

import DisabledTextField from "./DisabledTextField";

const styles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 24,
    paddingRight: 24,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: 32,
    marginBottom: 12,
  },
  iconContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: 24,
    textAlign: "center",
  },
  icon: {
    color: "#90A4AE",
    fontSize: 48,
  },
});

function Content({
  classes,
  iconImage,
  iconCaption,
  label,
  value,
  helperText,
  children,
  CustomTextComponent
}) {
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.iconContainer}>
          {React.createElement(iconImage, { classes: { root: classes.icon } })}
          <div className="gray caption">{iconCaption}</div>
        </div>
        {React.createElement(CustomTextComponent || DisabledTextField, { label, value, helperText })}
      </div>
      {children}
    </div>
  );
}

export default withStyles(styles)(Content);
