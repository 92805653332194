import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchJobCasts } from "../../../../../../../actions/employers/jobcastActions";
import {
  makeGetFilteredJobcasts,
  PERM,
  TEMP,
} from "../../../../../../../selectors/jobcastSelectors";
import { getCurrentOrganization } from "../../../../../../../selectors/organizationSelectors";
import LoadingPage from "../../../../../../General/LoadingPage";
import PageCardRowHeader from "../../../../../../General/PageCardRowHeader";

import RotatingEmptyState from "../../../../../../shared/EmptyState/RotatingEmptyState";

import AgencyJobCastsTable from "./AgencyJobCastsTable";

class JobCastsTab extends Component {
  state = { loaded: false };

  componentDidMount() {
    this.fetchJobCasts().then(() => this.setState({ loaded: true }));
    this.fetchTempJobCasts().then(() => this.setState({ loaded: true }));
  }

  fetchJobCasts = async () => {
    const { jobCastsLoaded, fetchActiveJobCasts, viewingAs } = this.props;

    if (!jobCastsLoaded || (viewingAs && viewingAs.key !== "me")) {
      return fetchActiveJobCasts();
    }
  };

  fetchTempJobCasts = async () => {
    const { tempJobCastsLoaded, fetchActiveTempJobCasts, viewingAs } = this.props;

    if (!tempJobCastsLoaded || (viewingAs && viewingAs.key !== "me")) {
      return fetchActiveTempJobCasts();
    }
  }

  renderPermContent() {
    const { jobCasts, agency } = this.props;

    return jobCasts.length ? (
      <AgencyJobCastsTable
        jobCasts={jobCasts}
        agency={agency}
        recruiterId={agency.individual.id}
      />
    ) : (
      <RotatingEmptyState />
    );
  }

  renderTempContent() {
    const { tempJobCasts, agency } = this.props;

    return tempJobCasts.length ? (
      <AgencyJobCastsTable
        jobCasts={tempJobCasts}
        agency={agency}
        recruiterId={agency.individual.id}
        temp
      />
    ) : (
      <RotatingEmptyState />
    );
  }

  render() {
    const { loaded } = this.state;
    const { contractActivated } = this.props.currentOrganization;
    const tempEnabled = this.props.agency.preferredLink?.attributes?.tempEnabled ?? false;

    return (
      <>
        <PageCardRowHeader title="Live JobCast Activity" />
        {loaded ? this.renderPermContent() : <LoadingPage />}
        {contractActivated && tempEnabled && (
          <>
            <PageCardRowHeader title="Live Contract Role Activity" />
            {loaded ? this.renderTempContent() : <LoadingPage />}
          </>
        )}
      </>
    );
  }
}

JobCastsTab.propTypes = {
  agency: PropTypes.object.isRequired,
  jobCasts: PropTypes.array.isRequired,
};

const makeMapStateToProps = () => {
  const getFilteredJobcasts = makeGetFilteredJobcasts();

  const mapStateToProps = (state) => ({
    viewingAs: state.individuals.viewing_as,
    jobCastsLoaded: state.jobcasts.indexTypesLoaded[PERM].active,
    tempJobCastsLoaded: state.jobcasts.indexTypesLoaded[TEMP].active,
    jobCasts: getFilteredJobcasts(state, "LIVE", {
      currentIndividualId: state.individuals.currentIndividualId,
    }),
    tempJobCasts: getFilteredJobcasts(state, "LIVE", {
      currentIndividualId: state.individuals.currentIndividualId,
      isTemp: true
    }),
    currentOrganization: getCurrentOrganization(state)
  });

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  fetchActiveJobCasts: () => dispatch(fetchJobCasts("active", false)),
  fetchActiveTempJobCasts: () => dispatch(
    fetchJobCasts("active", false, true)
  ),
});

export default connect(makeMapStateToProps, mapDispatchToProps)(JobCastsTab);
