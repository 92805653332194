import filter from "lodash/filter";
import find from "lodash/find";
import { createSelector } from "reselect";

import { individualsSelector } from "./individualSelectors";
import { getCurrentOrganizationId } from "./organizationSelectors";

export const getAllTeammates = createSelector(
  [individualsSelector, getCurrentOrganizationId],
  (individuals, currentOrganizationId) => filter(
    individuals,
    ({ attributes }) => attributes.organizationId === currentOrganizationId
  )
);

export const getAllTeammateInvitations = (state) => state.invitations.teammateInvitations.items;

export const getTeammate = (state, id) => {
  const items = getAllTeammates(state);

  if (items) {
    return find(items, {
      id,
    });
  }
};
