import Dialog from "@material-ui/core/Dialog";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { clearModal } from "../../../actions/shared/uiActions";

const useStyles = makeStyles({
  paper: {
    width: "780px",
    maxWidth: "780px",
    margin: "0",
  },
});

function ActionModalContainer({
  color,
  open = true,
  children,
  PaperProps = {},
  DialogProps = {},
}) {
  const theme = useTheme();
  const fullScreen = !useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClose = useCallback(() => dispatch(clearModal()), [dispatch]);

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      PaperProps={PaperProps}
      onClose={handleClose}
      data-cy="action-modal"
      {...DialogProps}
    >
      {React.Children.map(children, (child) => React.cloneElement(child, { color, handleClose }))}
    </Dialog>
  );
}

export default ActionModalContainer;
