import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";

import styles from "./styles";

function AllJobCastsItem({
  count, isSelected, setSelectedId, classes
}) {
  const renderSecondary = () => {
    if (count) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            style={{
              marginRight: 16,
              fontVariantNumeric: "tabular-nums lining-nums"
            }}
          >
            {count}
          </span>
          <span className="left-drawer-item-rfi" />
        </div>
      );
    }
    return null;
  };

  return (
    <List className={classnames(classes.list)}>
      <ListItem
        button
        className={classnames(classes.listItem, {
          [classes.selectedItem]: isSelected
        })}
        onClick={setSelectedId(null)}
      >
        <ListItemText
          className={classnames(classes.listItemText)}
          disableTypography
          primary={(
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                color: "white"
              }}
            >
              <span className={classnames("white")}>
                My Unread Alerts
              </span>
              <span
                className={classnames("caption", {
                  bold: isSelected,
                  "gray-light": !isSelected
                })}
              >
                Across All JobCasts
              </span>
            </div>
          )}
          secondary={renderSecondary()}
        />
      </ListItem>
    </List>
  );
}

export default withStyles(styles)(AllJobCastsItem);
