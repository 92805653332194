import React, { useCallback, useState } from "react";

import { useDispatch } from "react-redux";

import { createCandidateFitReview } from "../../../../../../../actions/employers/reviewActions";
import StarRatingPanel from "../../../../../../forms/custom/StarRatingPanel";

function RatingInput({ value, handleSubmit }) {
  const handleChange = useCallback(
    (e) => {
      handleSubmit(e.target.value);
    },
    [handleSubmit]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingRight: 57,
      }}
    >
      <div
        className="static-input-label"
        style={{ marginBottom: 10, marginRight: 6 }}
      >
        Internal Fit Rating:
      </div>
      <StarRatingPanel onChange={handleChange} value={value} />
    </div>
  );
}

function RatingSubmissionForm({ candidate }) {
  const dispatch = useDispatch();
  const value = candidate.attributes.receivedRating;
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(
    (value) => {
      if (submitting) return;

      setSubmitting(true);

      const submitValues = {
        subject_id: candidate.id,
        ratings: [{ rating: value, characteristic: "CANDIDATE-FIT" }],
        visibility: "PRIVATE",
      };

      setSubmitting(true);

      dispatch(createCandidateFitReview(submitValues))
        .then(() => {
          // snackbar
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
    [dispatch, candidate.id, submitting]
  );

  return <RatingInput {...{ value, handleSubmit }} />;
}

export default RatingSubmissionForm;
