import concat from "lodash/concat";

import { mapKeysToSnakeCase } from "../../../../../util/formatHelpers";
import { string, object } from "../../../../../util/yup";

export const initialValues = {
  removeReason: "",
};

export const validationSchema = object().shape({
  removeReason: string().required("Cannot be blank").minWords(3),
});

export const handleSubmit =
  (
    dispatchRemoveFromCommunity,
    clearModal,
    snackbar,
    agencyOrgName,
    setExcludedOrgIds,
    agencyId
  ) => (values, { setSubmitting, resetForm }) => {
    dispatchRemoveFromCommunity(mapKeysToSnakeCase(values))
      .then(() => {
        setExcludedOrgIds((prevExcludedOrgIds) => concat(prevExcludedOrgIds, agencyId));
      })
      .then(() => {
        setSubmitting(false);
        clearModal();
      })
      .then(() => {
        snackbar.showMessage(
          `${agencyOrgName} has been removed from your community.`
        );
        resetForm();
      })
      .catch(() => {
        setSubmitting(false);
        snackbar.showMessage("There was an issue processing your request");
      });
  };
