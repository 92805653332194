import React from "react";

function SectionHeader(props) {
  return (
    <div
      style={({
        borderBottom: "1px solid #B0BEC5",
        marginBottom: 24,
        paddingBottom: 5,
        paddingLeft: 12,
        marginTop: 20,
        ...props.style || {}
      })}
    >
      <h5 style={{ color: "#90A4AE" }}>{props.title}</h5>
    </div>
  );
}

export default SectionHeader;
