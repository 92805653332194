import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import unset from "lodash/unset";

import {
  RECEIVE_LEGACY_INVITATIONS,
  REMOVE_LEGACY_INVITATION,
} from "../../actions/legacyInvitationActions";

const _defaultState = { items: {} };

const legacyInvitationReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_LEGACY_INVITATIONS:
      return merge({}, state, { items: action.payload });
    case REMOVE_LEGACY_INVITATION:
      const toRemoveId = action.payload;
      const newState = cloneDeep(state);

      unset(newState, ["items", toRemoveId]);

      return newState;
    default:
      return state;
  }
};

export default legacyInvitationReducer;
