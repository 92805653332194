import MaterialButton from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import map from "lodash/map";
import PropTypes from "prop-types";

import React from "react";

const styles = (theme) => ({
  root: {
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "1px",
    padding: "6px 16px",
    height: "36px",
    boxShadow: "none",
    border: "1px solid #008dae",
  },
  primary: {
    color: "#fff",
    "&$blue": {
      backgroundColor: "#008dae",
      border: "1px solid #008dae",
      "&:hover": {
        backgroundColor: "#008daecc",
      },
    },
    "&$grey": {
      backgroundColor: "#90A4AE",
      border: "1px solid #90A4AE",
      "&:hover": {
        backgroundColor: "#90A4AEcc",
      },
    },
    "&$red": {
      backgroundColor: "#ff6d6d",
      border: "1px solid #FF6D6D",
      "&:hover": {
        backgroundColor: "#ff6c6ccc",
      },
    },
    "&$cardinal": {
      backgroundColor: "#F35B5E",
      border: "1px solid #F35B5E",
      "&:hover": {
        backgroundColor: "#CB3337cc",
      },
    },
    "&$eucalyptus": {
      backgroundColor: "#238740",
      border: "1px solid #238740",
      "&:hover": {
        backgroundColor: "#35A754",
      },
    },
  },
  secondary: {
    backgroundColor: "#fff",
    "&$blue": {
      color: "#008dae",
      border: "1px solid #008dae",
      "&:hover": {
        backgroundColor: "#eef7f8",
      },
    },
    "&$red": {
      color: "#ff6d6d",
      border: "1px solid #FF6D6D",
      "&:hover": {
        backgroundColor: "#fff5f5",
      },
    },
    "&$grey": {
      color: "#37474F",
      border: "1px solid #37474F",
      "&:hover": {
        backgroundColor: "#f3f4f4",
      },
    },
    "&$green": {
      color: "#45A760",
      border: "1px solid #45A760",
      "&:hover": {
        backgroundColor: "#f3f4f4",
      },
    },
    "&$cardinal": {
      color: "#F35B5E",
      border: "1px solid #F35B5E",
      "&:hover": {
        backgroundColor: "rgba(220, 78, 65, 0.1)",
      },
    },
    "&$eucalyptus": {
      backgroundColor: "#238740",
      border: "1px solid #238740",
      "&:hover": {
        backgroundColor: "#35A754",
      },
    },
  },
  candidateActionButton: {
    [theme.breakpoints.down("1024")]: {
      width: "calc(100% / 3 + 40px)",
    },
  },
  candidateActionButtonSmall: {
    [theme.breakpoints.down("1024")]: {
      width: "calc(100% / 5 + 40px)",
    },
  },
  restoreButton: {
    [theme.breakpoints.up("1024")]: {
      marginLeft: 138,
    },
  },
  reportFailureButtonOpenTransactions: {
    padding: 8,
  },
  disabled: {
    color: "#90A4AE",
    border: "1px solid #90A4AE",
    "&:hover": {
      backgroundColor: "#90A4AE",
    },
  },
  greyedOutDisabled: {
    backgroundColor: "#90A4AE",
    border: "1px solid #90A4AE",
    "&:hover": {
      backgroundColor: "#90A4AEcc",
    },
  },
  blue: {},
  red: {},
  grey: {},
  green: {},
  cardinal: {},
  eucalyptus: {},
});

function Button(props) {
  let overloadedClassesList = [];
  const {
    classes,
    children,
    color,
    disabled,
    style,
    variant,
    icon,
    overloadedClasses,
    greyedOut,
    ...other
  } = props;

  if (Boolean(overloadedClasses)) {
    overloadedClassesList = map(
      overloadedClasses,
      className => classes[className]
    );
  }

  const buttonClassNames = () => {
    if (disabled && greyedOut) {
      return classNames(classes.root, classes.greyedOutDisabled);
    }
    if (disabled) {
      return classNames(classes.root, classes.disabled);
    }
    return classNames(
      classes.root,
      {
        [classes[variant]]: true,
        [classes[color]]: true
      },
      "ie11-button",
      ...overloadedClassesList
    );
  };

  return (
    <MaterialButton
      id="button"
      disabled={disabled}
      centerRipple
      onClick={props.onClick}
      className={buttonClassNames()}
      style={style}
      size="medium"
      {...other}
    >
      {icon && <Icon style={{ fontSize: 15, marginRight: 8 }}>{icon}</Icon>}
      {children}
    </MaterialButton>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  icon: PropTypes.string,
  variant: PropTypes.string,
  overloadedClasses: PropTypes.array,
  disabled: PropTypes.bool
};

export default withStyles(styles)(Button);
