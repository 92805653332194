import makeStyles from "@material-ui/core/styles/makeStyles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import map from "lodash/map";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { extractKeyFromPath } from "./NavigationTabs/constants";

const useStyles = makeStyles({
  containerRoot: {
    marginLeft: 8,
    display: "flex",
    alignItems: "center",
    minWidth: 400,
  },
  tabRoot: {
    minWidth: "128px",
    color: "#37474f",
    fontFamily: '"proxima-nova", helvetica',
    fontSize: "12px",
    height: "36px",
    "&:hover": {
      backgroundColor: "#008dae",
      color: "#ffffff !important",
      fontFamily: '"proxima-nova", helvetica',
      opacity: 1,
    },
  },
  tabSelected: {
    fontWeight: "700 !important",
  },
  tabLabel: {
    fontSize: "14px !important",
  },
});

function HeaderTabLinks({ items, history }) {
  const [value, setValue] = useState(null);
  const filters = useSelector((state) => state.filters);

  useEffect(() => {
    setValue(extractKeyFromPath(history.location.pathname));
  }, [history.location.pathname]);

  const classes = useStyles();

  return (
    <Tabs
      value={value || false}
      indicatorColor="primary"
      classes={{ root: classes.containerRoot }}
    >
      {map(items, ({ key, label, handleClick }) => {
        let search = "";

        if (
          key === "jobcasts" &&
          (filters.teammates.length > 0 || filters.brands.length > 0)
        ) {
          const brandsString =
            filters.brands.length > 0 && filters.brands.join(",");
          const teammatessString =
            filters.teammates.length > 0 && filters.teammates.join(",");

          if (brandsString && teammatessString) {
            search = `?brands=${brandsString}&teammates=${teammatessString}`;
          } else if (brandsString) {
            search = `?brands=${brandsString}`;
          } else if (teammatessString) {
            search = `?teammates=${teammatessString}`;
          }
        }

        return (
          <Tab
            data-cy={`header-${key}-tab`}
            className="top-nav"
            classes={{
              root: classes.tabRoot,
              selected: classes.tabSelected,
            }}
            onClick={
              handleClick
                ? () => handleClick(history)
                : () => history.push({ pathname: `/${key}`, search })
            }
            key={key}
            value={key}
            centerRipple
            label={label}
          />
        );
      })}
    </Tabs>
  );
}

HeaderTabLinks.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      handleClick: PropTypes.func,
    })
  ),
};

export default withRouter(HeaderTabLinks);
