import Star from "@material-ui/icons/Star";
import EmptyStar from "@material-ui/icons/StarBorder";
import HalfStar from "@material-ui/icons/StarHalf";
import map from "lodash/map";
import times from "lodash/times";
import React, { useMemo } from "react";

function StarRatingDisplay({
  rating,
  handleClick,
  color = "#008DAE",
  fontSize = 28,
}) {
  const typedStarIcons = useMemo(() => {
    let currRating = Math.round(rating * 2) / 2;

    const icons = [];

    times(5, () => {
      if (currRating >= 1) {
        icons.push(Star);
      } else if (currRating === 0.5) {
        icons.push(HalfStar);
      } else {
        icons.push(EmptyStar);
      }
      currRating--;
    });

    return icons;
  }, [rating]);

  return (
    <div
      onClick={handleClick || (() => {})}
      style={{ cursor: handleClick ? "pointer" : "inherit" }}
    >
      {map(typedStarIcons, (Icon, i) =>
        React.createElement(Icon, { key: i, style: { color, fontSize } })
      )}
    </div>
  );
}

export default StarRatingDisplay;
