import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 24,
  },
  header: {
    borderBottom: "1px solid #90A4AE",
    paddingLeft: "12px",
    paddingBottom: "6px",
    marginBottom: "24px",
  },
  title: {
    color: "#37474f",
  },
  childrenContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

function Section({ title, children }) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h5 className={classes.title}>{title}</h5>
      </div>
      <div className={classes.childrenContainer}>{children}</div>
    </div>
  );
}

export default Section;
