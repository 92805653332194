import every from "lodash/every";
import filter from "lodash/filter";
import moment from "moment";

import { createSelector } from "reselect";

import makeEntityUnionSelector from "./entityUnionSelector";

export const SCOPE_UNANSWERED = "SCOPE_UNANSWERED";
export const SCOPE_ANSWERED = "SCOPE_ANSWERED";
export const SCOPE_FULL = "SCOPE_FULL";

const SCOPE_ALL = "SCOPE_ALL";

export const getAllPositionCorrespondences = (state) => state.jobcastUpdates.positionCorrespondences.items;
export const getAllAnnouncements = (state) => state.jobcastUpdates.announcements.items;
export const getAllJobcastHistory = (state) => state.jobcastUpdates.jobcastHistory.items;
export const getAllJobcastOwnerTransferHistory = (state) => state.jobcastUpdates.jobcastOwnerTransferHistory.items;
export const getJobcastUpdatesLoaded = (state, jobcastId) => state.jobcastUpdates.announcements.loaded &&
  state.jobcastUpdates.positionCorrespondences.loaded &&
  state.jobcastUpdates.jobcastHistory.loaded &&
  state.jobcastUpdates.jobcastOwnerTransferHistory.loaded;

export const getJobUpdatesCount = (state, jobcastId) => getJobCastUpdates(state, jobcastId).length;

export const getJobCastUpdates = (state, jobcastId) => makeEntityUnionSelector(
  [
    makeGetPositionCorrespondences({ scope: SCOPE_FULL }),
    getAllAnnouncements,
    getAllJobcastHistory,
    getAllJobcastOwnerTransferHistory,
  ],
  (item) => item.attributes.jobcastId === jobcastId,
  [(item) => moment().diff(moment(item.attributes.createdAt))]
)(state);

export const getPositionCorrespondence = (state, id) => state.jobcastUpdates.positionCorrespondences.items[id];

export const makeGetPositionCorrespondences = (
  options = { scope: SCOPE_ALL, jobcastId: null }
) => createSelector(
  [getAllPositionCorrespondences],
  (positionCorrespondences) => {
    const filterFuncs = [];

    if (options.scope === SCOPE_FULL) {
      filterFuncs.push(({ attributes }) => attributes.full);
    } else if (options.scope === SCOPE_UNANSWERED) {
      filterFuncs.push(({ attributes }) => !attributes.answeredAt);
    } else if (options.scope === SCOPE_ANSWERED) {
      filterFuncs.push(({ attributes }) => Boolean(attributes.answeredAt));
    }

    if (options.jobcastId) {
      filterFuncs.push(
        ({ attributes }) => attributes.jobcastId === options.jobcastId
      );
    }

    return filter(positionCorrespondences, (positionCorrespondence) => every(filterFuncs, (filterFunc) => filterFunc(positionCorrespondence)));
  }
);

export const getUnansweredPositionCorrespondences = (state, jobcastId) => {
  const options = { scope: SCOPE_UNANSWERED, jobcastId };

  return makeGetPositionCorrespondences(options)(state);
};

export const makeGetJobUpdatesCount = () => createSelector([getJobUpdatesCount], (count) => count);
