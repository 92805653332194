import { useField, useFormikContext } from "formik";
import { flatMap } from "lodash";
import React from "react";

import PreAssignmentDescriptionPopover from '../../../../shared/Candidates/PreAssignmentModal/ChecklistTable/PreAssignmentDescriptionPopover';
import { AGENCY } from "../../../../shared/Candidates/PreAssignmentModal/fieldHelpers";
import EditableCheckbox from "../../../JobCasts/view/Description/inputs/EditableCheckbox";

export default function EmployerTempPrerequisitesField({ editing = true }) {
  const [{ value: temp_prerequisites }] = useField("temp_prerequisites");
  const formContext = useFormikContext();

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {
        flatMap(
          temp_prerequisites,
          (prerequisite, index) => (
            !(prerequisite.responsibility === AGENCY && prerequisite.default)
              ? [(
                <div
                  style={{ display: "flex", alignItems: "baseline" }}
                  key={prerequisite.id}
                >
                  <EditableCheckbox
                    id={`temp_prerequisites.${index}.checked`}
                    label={(
                      <span
                        {...(prerequisite.default && { style: { color: "#37474F", fontWeight: 300 } })}
                      >
                        {
                          prerequisite.prerequisite_type === "document"
                            ? `${prerequisite.name} (File)`
                            : prerequisite.name
                        }
                      </span>
                    )}
                    {...{ formContext, formState: { editing } }}
                    {...(prerequisite.default && { disabled: true, color: "primary" })}
                  />
                  &nbsp;
                  <PreAssignmentDescriptionPopover {...prerequisite} />
                </div>
              )] : []
          )
        )
      }
    </div>
  );
}
