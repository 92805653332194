import React, { useEffect } from "react";
import { Row, Col } from "react-grid-system";

import { useDispatch } from "react-redux";

import { fetchContracts } from "../../../../../../../actions/employers/contractActions";
import PageCardRowHeader from "../../../../../../General/PageCardRowHeader";

import ClosedContractTable from "./ClosedContractTable";
import InsuranceTable from "./InsuranceTable";
import OpenContractTable from "./OpenContractTable";

const ContractWorkTab = ({ agency }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchContracts());
  }, [dispatch]);

  return (
    <>
      <Row>
        <Col xs={12}>
          <PageCardRowHeader title="Insurance Coverages" />
        </Col>
      </Row>
      <InsuranceTable
        insuranceData={agency.organization.attributes.activeInsuranceRequirementAgreements}
      />
      <Row>
        <Col xs={12}>
          <PageCardRowHeader title="Open Contracts" />
        </Col>
      </Row>
      <OpenContractTable recruiterId={agency.individual.id} />
      <Row>
        <Col xs={12}>
          <PageCardRowHeader title="Closed Contracts" />
        </Col>
      </Row>
      <ClosedContractTable recruiterId={agency.individual.id} />
    </>
  );
};

export default ContractWorkTab;
