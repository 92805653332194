import normalize from "json-api-normalizer";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { receiveMessage } from "../../../../actions/shared/candidateActions";
import ActionCableConsumer from "../ActionCableConsumer";

function MessagesConsumer({ conversationId }) {
  const dispatch = useDispatch();

  const handleReceived = useCallback(
    (data) => {
      const { message } = normalize(data);
      dispatch(
        receiveMessage({
          message,
          conversationId,
        })
      );
    },
    [dispatch, conversationId]
  );

  const channelParams = useMemo(
    () => ({
      channel: "MessagesChannel",
      conversation_id: conversationId,
    }),
    [conversationId]
  );

  return (
    <ActionCableConsumer channel={channelParams} onReceived={handleReceived} />
  );
}

export default MessagesConsumer;
