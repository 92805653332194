import get from "lodash/get";
import queryString from "query-string";
import React from "react";
import { useSelector } from "react-redux";

import {
  getCurrentIndividual,
  getViewingAs,
} from "../../../selectors/individualSelectors";

import ActionCableProvider from "./ActionCableProvider";

function UserActionCableProvider({ children }) {
  const currentIndividual = useSelector(getCurrentIndividual);
  const viewingAs = useSelector(getViewingAs);

  const baseUrl = get(currentIndividual, "actionCableConnectionPath");
  const token = get(currentIndividual, "token");
  const viewingAsId = viewingAs && viewingAs.key !== "me" && viewingAs.key;

  const params = {};
  token && Object.assign(params, { token });
  viewingAsId && Object.assign(params, { viewingAs: viewingAsId });

  const url =
    baseUrl && params.token
      ? `${baseUrl}?${queryString.stringify(params)}`
      : null;

  return <ActionCableProvider url={url}>{children}</ActionCableProvider>;
}

export default UserActionCableProvider;
