import moment from 'moment';
import React from 'react';

import {
  getFormattedDate,
  isWithin48Hours,
  APPROVED_MESSAGE,
  AWAITING_MESSAGE,
  NOT_SUBMITTED_MESSAGE,
  ZERO_HOURS_MESSAGE
} from '../utils';

export function payPeriodStatusMessage(payPeriod, modal = false) {
  const {
    approvedAt,
    editedAt,
    submittedAt,
    standardHours,
    nonStandardHours,
    doubleTimeHours,
    additionalBilling,
    disputedAt,
    disputeExplanation,
  } = payPeriod.attributes;

  const day = (moment().isoWeekday() < 3) ? 3 : 10;
  const zeroHoursDate = getFormattedDate(moment().isoWeekday(day)
    .hours(23)
    .minutes(30)
    .utcOffset('America/New_York'));

  const nextWednesday = moment().clone().startOf('week').add(3, 'days')
    .hours(23)
    .minutes(30)
    .utcOffset('America/New_York');

  let formattedUpcomingWednesday = getFormattedDate(nextWednesday);

  const isSubmittedOrEditedWithin48Hours =
    isWithin48Hours(submittedAt, formattedUpcomingWednesday) ||
    isWithin48Hours(editedAt, formattedUpcomingWednesday);

  if (isSubmittedOrEditedWithin48Hours) {
    formattedUpcomingWednesday = getFormattedDate(nextWednesday.clone().add(7, 'days'));
  }

  if (approvedAt) {
    return APPROVED_MESSAGE;
  }

  if (submittedAt) {
    if (!standardHours && !nonStandardHours && !doubleTimeHours && !additionalBilling) {
      return modal ? `Submitted - can be edited until ${zeroHoursDate}` : ZERO_HOURS_MESSAGE;
    }

    return modal ? `Submitted - can be edited until ${formattedUpcomingWednesday} or approved by employer` : AWAITING_MESSAGE;
  }

  if (disputedAt && modal) {
    return (
      <>
        <div style={{ marginBottom: 8 }}>
          <span style={{ color: '#FF6D6D', fontWeight: 'bold' }}>Disputed By Employer</span> - Please resubmit hours to remove the disputed status.
        </div>
        <div style={{ marginBottom: 8 }}>
          <span style={{ fontWeight: 'bold' }}>Dispute Explanation:</span> {disputeExplanation}
        </div>
      </>
    );
  }

  return NOT_SUBMITTED_MESSAGE;
}
