import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import React from "react";

import { Row, Col } from "react-grid-system";

const useStyles = makeStyles({
  container: {
    boxSizing: "border-box",
    color: "#FFF",
    paddingLeft: 24,
    height: "72px",
    "&$blue": {
      backgroundColor: "#008dae",
      border: "1px solid #008dae",
    },
    "&$red": {
      backgroundColor: "#ff6d6d",
      border: "1px solid #FF6D6D",
    },
    "&$grey": {
      backgroundColor: "#90A4AE",
      border: "1px solid #90A4AE",
    },
    "&$green": {
      backgroundColor: "#3C9255",
      border: "1px solid #3C9255",
    }
  },
  closeIconContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    paddingRight: 24,
  },
  closeIcon: {
    fontSize: "xx-large",
    marginRight: -7,
    "&:hover": {
      cursor: "pointer",
    },
  },
  red: {},
  blue: {},
  grey: {},
  green: {},
});

function ActionModalHeader({
  title, subtitle, color, handleClose
}) {
  const classes = useStyles();

  return (
    <DialogTitle id="responsive-dialog-title" style={{ padding: 0 }}>
      <Row nogutter>
        <Col xs={9} style={{ padding: 0, margin: 0 }}>
          <div className={classnames(classes.container, classes[color])}>
            {title && (
              <div style={{ marginTop: subtitle ? "0.5em" : "1em" }}>
                <h4
                  className="truncate"
                  style={{ color: "#FFF" }}
                  data-cy="action-modal-title"
                >
                  {title}
                </h4>
              </div>
            )}
            {subtitle && (
              <div className="subtitle-1 truncate" style={{ color: "#FFF" }}>
                {subtitle}
              </div>
            )}
          </div>
        </Col>
        <Col xs={3} style={{ padding: 0, margin: 0 }}>
          <div
            className={classnames(
              classes.closeIconContainer,
              classes.container,
              classes[color]
            )}
          >
            {handleClose && (
              <Icon
                className={classes.closeIcon}
                onClick={handleClose}
                data-cy="close-action-modal"
              >
                close
              </Icon>
            )}
          </div>
        </Col>
      </Row>
    </DialogTitle>
  );
}

export default ActionModalHeader;
