import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import each from "lodash/each";
import React from "react";

import { JOBCASTS, CANDIDATES, AGENCIES } from "./constants";
import SearchResultsSection from "./SearchResultSection/SearchResultSection";

const styles = () => ({
  container: {
    borderRadius: 0,
    position: "absolute",
    width: "100%",
    minWidth: 120,
    maxHeight: 450,
    zIndex: 99,
    boxShadow: "6px 8px 12px"
  },
  loadingCardContainer: {
    height: 60,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

function LoadingCard({ classes }) {
  return (
    <div className={classes.loadingCardContainer}>
      <CircularProgress style={{ height: 36, width: 36 }} />
    </div>
  );
}
class SearchResultsIndex extends React.Component {
  filterResults = items => {
    const results = {
      [JOBCASTS]: [],
      [CANDIDATES]: [],
      [AGENCIES]: []
    };

    each(items, item => {
      const itemType = item.type;
      results[itemType].push(item);
    });

    return results;
  };

  renderResultSections() {
    const { exitSearch, items, currentIndividual } = this.props;
    const results = this.filterResults(items);

    return (
      <>
        <SearchResultsSection
          title="Candidates"
          items={results[CANDIDATES]}
          numResults={results[CANDIDATES].length}
          exitSearch={exitSearch}
          currentIndividual={currentIndividual}
        />
        <SearchResultsSection
          title="Agencies"
          items={results[AGENCIES]}
          numResults={results[AGENCIES].length}
          exitSearch={exitSearch}
          currentIndividual={currentIndividual}
        />
        <SearchResultsSection
          title="JobCasts"
          items={results[JOBCASTS]}
          numResults={results[JOBCASTS].length}
          exitSearch={exitSearch}
          currentIndividual={currentIndividual}
        />
      </>
    );
  }

  render() {
    const { classes, loading, resultsVisible } = this.props;

    return (
      resultsVisible && (
        <Card className={classes.container}>
          <div style={{ maxHeight: 450, overflow: "auto" }}>
            {this.renderResultSections()}
            {loading && <LoadingCard classes={classes} />}
          </div>
        </Card>
      )
    );
  }
}

export default withStyles(styles)(SearchResultsIndex);
