// nav routes
export const OVERVIEW = "overview";
export const REQUIREMENTS = "requirements";
export const COMPENSATION = "compensation";
export const SPECIFICS = "specifics";
export const AGENCIES = "agencies";
export const REVIEW = "review";

export const SECTIONS = [
  OVERVIEW,
  REQUIREMENTS,
  COMPENSATION,
  SPECIFICS,
  AGENCIES,
  REVIEW,
];

// remote work options
export const NON_REMOTE = "Yes, they must report in-person to a specific location";
export const REMOTE_BUT = "No, but they must be able to service a general area";
export const REMOTE = "No, they can work from anywhere";

export const REMOTE_WORK_OPTIONS = [NON_REMOTE, REMOTE_BUT, REMOTE];

// save statuses
export const SAVED = "saved";
export const UNSAVED = "unsaved";
export const SAVING = "saving";

export const SECTION_FIELDS = {
  [OVERVIEW]: [
    "organization_profile_id",
    "title",
    "level",
    "reports_to",
    "vacant_since",
    "position_count",
    "locations",
    "travel_required",
    "work_remotely",
    "roles",
    "visa_support",
    "visa_support_non_us",
  ],
  [REQUIREMENTS]: ["description", "must_haves", "nice_to_haves"],
  [COMPENSATION]: [
    "currency",
    "salary_min",
    "salary_max",
    "signing_bonus_type",
    "bonus_description",
    "relocation_package",
    "culture_and_perks",
    "corporate_culture_url",
    "benefits",
  ],
  [SPECIFICS]: [
    "ats_application_url",
    "document",
    "institutions_to_source_from",
    "institutions_to_avoid",
    "interview_steps",
    "invoice_memo",
    "ofccp",
    "screening_questions",
    "deferred_invoice_memo",
    "deferred_invoice_memo_email",
    "job_id",
  ],
  [AGENCIES]: [
    "community_inclusion",
    "selected_agencies",
    "userAddedAgencies",
    "userRemovedAgencies",
    "suggested_agencies",
    "agencySelectionType",
    "selected_agency_list",
    "standardized_preferred_fee_option_id",
  ],
};

export const ALL_FIELDS = [
  OVERVIEW,
  REQUIREMENTS,
  COMPENSATION,
  SPECIFICS,
  AGENCIES,
].reduce((fieldList, section) => fieldList.concat(SECTION_FIELDS[section]), []);
