// if the candidates current stage and the organizations
// selected stage to invite candidates at indicates
// the candidate should be invited to the ats
export const shouldInviteToATS = {
  new: { REVIEWING: true },
  reviewing: { INTERVIEWING: true },
  interviewing: { OFFER: true },
  offer: { HIRE: true },
};

export const HireModalSteps = [
  "Agency Information",
  "Hire & Billing Details",
  "JobCast Status",
];

export const ArchivedModalSteps = [
  "Agency Information",
  "Hire & Billing Details"
]

export const HireModalStepHelperTexts = [
  "Please review this information and make sure it is correct. You can click on your Agent’s name to view their profile and edit their fee if necessary.",
  "Carefully complete the form below. Upon hire, the agency will be notified and an invoice will be sent to your AP department. You can review your hires in the “Billing” section.",
];
