import every from "lodash/every";

export const SOW_FIELD = "sowField";
export const DOCUMENT_FIELD = "documentField";
export const DATE_FIELD = "dateField";
export const BASIC_CONFIRMATION_FIELD = "basicConfirmationField";
export const TIMESHEET_SUBMITTERS_FIELD = "timesheetSubmittersField";

export const EITHER_PARTY = "either_party";
export const EMPLOYER = "my_company";
export const AGENCY = "staffing_firm";
export const BOTH_PARTIES = "both_parties";

export function determineChecklistItemType(checklistItem) {
  if (checklistItem.name === "Statement of Services") return SOW_FIELD;

  if (checklistItem.documentRequired) {
    return DOCUMENT_FIELD;
  }

  if (checklistItem.requestedDate) {
    return DATE_FIELD;
  }

  if (checklistItem.timesheetSubmittersRequired) {
    return TIMESHEET_SUBMITTERS_FIELD;
  }

  return BASIC_CONFIRMATION_FIELD;
}

export const checkResponsibility = ({ employerConfirmedAt, agencyConfirmedAt, responsibility }) => {
  switch (responsibility) {
    case BOTH_PARTIES:
      return Boolean(employerConfirmedAt && agencyConfirmedAt);
    case EMPLOYER:
      return Boolean(employerConfirmedAt);
    case AGENCY:
      return Boolean(agencyConfirmedAt);
    case EITHER_PARTY:
    default:
      return Boolean(employerConfirmedAt || agencyConfirmedAt);
  }
};

const checkBasicConfirmed = (item, individual) => {
  const { responsibility } = item;
  const employerConfirmedAt = individual.employer ? item.basicConfirmed : item.employerConfirmedAt;
  const agencyConfirmedAt = individual.recruiter ? item.basicConfirmed : item.agencyConfirmedAt;

  return checkResponsibility({ employerConfirmedAt, agencyConfirmedAt, responsibility });
};

export function checklistItemComplete(item, currentIndividual) {
  const type = determineChecklistItemType(item);
  switch (type) {
    case SOW_FIELD:
      return Boolean(item.agencyConfirmedAt && item.employerConfirmedAt);
    case DOCUMENT_FIELD:
      return Boolean(item.document);
    case DATE_FIELD:
      return Boolean(item.submittedDate);
    case TIMESHEET_SUBMITTERS_FIELD:
      return Boolean(currentIndividual.employer && item.agencyConfirmedAt) ||
        Boolean(item.timesheetSubmitters.length);
    case BASIC_CONFIRMATION_FIELD:
    default:
      return checkBasicConfirmed(item, currentIndividual);
  }
}

export function allChecklistItemsComplete(checklistItems, currentIndividual) {
  return every(checklistItems, (item) => checklistItemComplete(item, currentIndividual));
}
