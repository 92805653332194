import normalize from "json-api-normalizer";
import get from "lodash/get";
import omit from "lodash/omit";
import values from "lodash/values";
import { batch } from "react-redux";

import { getViewingAs } from "../../selectors/individualSelectors";
import { GET, PATCH, POST } from "../../util/apiHelpers";

import { storageAvailable } from "../../util/storageHelpers";
import { clearCandidates } from "../shared/candidateActions";
import { clearContracts } from "../shared/contractActions";
import { processFeatureFlags } from "../shared/featureFlagActions";
import {
  processIndividual,
  login,
  updateCurrentIndividual,
  receiveIndividuals,
  receiveEmailSettings,
  receiveViewingAs,
} from "../shared/individualActions";
import { clearJobcasts } from "../shared/jobcastActions";
import { receivePositionCorrespondence } from "../shared/jobcastUpdatesActions";
import { receiveOrganizations } from "../shared/organizationActions";
import { clearPayPeriods } from "../shared/payPeriodActions";
import { clearRequests } from "../shared/requestActions";

// callback action types//
export const RECEIVE_MATCH_PREFERENCES = "RECEIVE_MATCH_PREFERENCES";
export const RECEIVE_RECRUITER_APPLICATION = "RECEIVE_RECRUITER_APPLICATION";

// api actions//
export const createRecruiter = (formData) => (dispatch) => POST("/api/v3/individual/create_recruiter", formData)
  .then(() => dispatch(
    login({
      email: formData.email,
      password: formData.password,
    })
  ))
  .catch((error) => Promise.reject(error.response.data));

export const updateRecruiter = (formData) => (dispatch) => PATCH("/api/v3/recruiter/individual", formData).then((res) => {
  const normalized = normalize(res.data);

  batch(() => {
    dispatch(receiveIndividuals(normalized.individual));
    dispatch(receiveOrganizations(normalized.organization));
  });

  return normalized.individual;
});

export const activateRecruiter = () => async (dispatch) => {
  const individualRes = await PATCH("/api/v3/recruiter/individual/activate");
  const flagsRes = await GET("/api/v3/authentication/feature_flags");

  processFeatureFlags(flagsRes.data, dispatch);
  processIndividual(individualRes.data, dispatch);
};

export const updateRecruiterMatchPreferences = (formData) => (dispatch) => PATCH(`/api/v3/recruiter/match_preferences`, formData)
  .then((res) => {
    const normalized = values(normalize(res.data).matchPreferences)[0]
      .attributes;

    return dispatch(receiveRecruiterMatchPreferences(normalized));
  })
  .catch((error) => Promise.reject(error.response.data));

export const fetchActivationDetails = () => (dispatch) => GET("/api/v3/recruiter/activation_details").then((res) => dispatch(updateCurrentIndividual(res.data.data.attributes)));

export const fetchRecruiterApplication = () => (dispatch) => GET("/api/v3/recruiter/recruiter_application").then((res) => dispatch(receiveRecruiterApplication(res.data.data.attributes)));

export const updateRecruiterApplication = (formData) => (dispatch) => PATCH("/api/v3/recruiter/recruiter_application", formData)
  .then((res) => dispatch(receiveRecruiterApplication(res.data.data.attributes)))
  .catch((error) => Promise.reject(error.response.data));

export const submitRecruiterApplication = () => (dispatch) => POST("/api/v3/recruiter/recruiter_application/submit").then((res) => dispatch(receiveRecruiterApplication(res.data.data.attributes)));

export const createPositionCorrespondence = (data) => (dispatch) => POST(
  `/api/v3/recruiter/jobcasts/${data.jobcastId}/position_correspondences`,
  data
).then((res) => {
  const normalized = normalize(res.data);
  return dispatch(receivePositionCorrespondence(normalized.positionCorrespondence));
});

export const updateEmailSettings = (email_settings) => (dispatch) => PATCH("/api/v3/recruiter/email_settings", email_settings)
  .then((res) => {
    dispatch(receiveEmailSettings(res.data));
    return Promise.resolve();
  })
  .catch((error) => Promise.reject(error));

export const setViewingAs = (viewingAs) => (dispatch, getState) => {
  const currViewingAsKey = get(getViewingAs(getState()), "key", "me");
  const viewingAsChanged = viewingAs.key !== currViewingAsKey;

  if (storageAvailable()) {
    viewingAs.key === "me"
      ? global.localStorage.removeItem("viewingAs")
      : global.localStorage.setItem(
        "viewingAs",
        JSON.stringify(omit(viewingAs, ["label"]))
      );
  }

  batch(() => {
    dispatch(receiveViewingAs(viewingAs));
    if (viewingAsChanged) {
      dispatch(clearCandidates());
      dispatch(clearJobcasts());
      dispatch(clearRequests());
      dispatch(clearContracts());
      dispatch(clearPayPeriods());
    }
  });
};

// callback actions//
export const receiveRecruiterMatchPreferences = (payload) => ({
  type: RECEIVE_MATCH_PREFERENCES,
  payload,
});

export const receiveRecruiterApplication = (payload) => ({
  type: RECEIVE_RECRUITER_APPLICATION,
  payload,
});
