import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { useSelector } from "react-redux";

import ActionModalContainer from "../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../General/ActionModal/ActionModalContent";

import OmnisearchResults from "./OmnisearchResults";
import SearchInput from "./SearchInput";

function OmnisearchModalContentLoading() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 12,
        minHeight: 346,
      }}
    >
      <CircularProgress size={60} />
    </div>
  );
}

function OmnisearchModalContent() {
  const isLoading = useSelector(
    (state) => state.search.term.length > 2 && !Boolean(state.search.searchedTerms.has(state.search.term))
  );
  if (isLoading) return <OmnisearchModalContentLoading />;

  return <OmnisearchResults />;
}

export default function OmnisearchModal({ currentIndividual }) {
  return (
    <ActionModalContainer>
      <ActionModalContent>
        <SearchInput {...{ currentIndividual }} />
        <OmnisearchModalContent />
      </ActionModalContent>
    </ActionModalContainer>
  );
}
