import React from "react";
import { Row } from "react-grid-system";

import { formatCurrency } from "../../../../../../util/formatHelpers";

import { formatDate, PayoutConversionRate } from "./ConversionRate";
import MultiColStyledField from "./MultiColStyledField";
import Section from "./Section";
import StyledField from "./StyledField";
import usePlacementContext from "./usePlacementContext";

function PayoutCalculation() {
  const {
    payoutCompletedAt,
    adjustedRecruiterPayoutAmountCents,
    payoutMethod,
    payoutConversionRate,
    payoutConversionCurrency,
  } = usePlacementContext();

  if (!payoutCompletedAt || !payoutMethod) return null;

  const payoutCurrency = payoutConversionCurrency || "USD";

  const amountPaidValue =
    `${formatCurrency(
      adjustedRecruiterPayoutAmountCents * payoutConversionRate,
      payoutCurrency,
      { salaryConversion: true }
    )} ${payoutCurrency}`;

  return (
    <Section title="Payout Calculation">
      <MultiColStyledField
        label={<h5>Amount Paid:</h5>}
        value={<h5>{amountPaidValue}</h5>}
      />
      <Row>
        <StyledField
          label="Payout Processed"
          value={formatDate(payoutCompletedAt)}
        />
        <StyledField label="Payout Method Type" value={payoutMethod} />
      </Row>
      <MultiColStyledField
        label="Total Earnings:"
        underline
        value={
          `${formatCurrency(adjustedRecruiterPayoutAmountCents, "USD")
          } ${"USD"}`
        }
      />
      <PayoutConversionRate />
      <MultiColStyledField
        label={<span style={{ fontWeight: 600 }}>Amount Paid:</span>}
        value={<span style={{ fontWeight: 600 }}>{amountPaidValue}</span>}
      />
    </Section>
  );
}

export default PayoutCalculation;
