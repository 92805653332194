import React from "react";

import { parseSupportingInfo } from "../utils";

function SearchResultItemSupportInfo({ item }) {
  return (
    <div
      className="small"
      style={{
        textAlign: "end",
        paddingRight: 12,
        width: "100%",
        maxWidth: 100,
        display: "flex",
        justifyContent: "flex-end",
        color: "#90A4AE"
      }}
    >
      {parseSupportingInfo(item)}
    </div>
  );
}

export default SearchResultItemSupportInfo;
