import React from "react";

import { ErrorPage } from "./components/General/404Error";

function withErrorDefault(importFn) {
  return new Promise((resolve) => {
    importFn()
      .then(resolve)
      .catch(() => {
        resolve({ default: ErrorPage });
      });
  });
}

export default function asyncComponent(importComponent) {
  class AsyncComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null,
      };
    }

    async componentDidMount() {
      const { default: component } = await withErrorDefault(importComponent);

      this.setState({
        component,
      });
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}
