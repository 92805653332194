import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

function LoadingPage({ iconStyles = {} }) {
  return (
    <div
      style={{
        width: "100%",
        color: "#008dae",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
      }}
    >
      <CircularProgress
        style={({
          margin: 36, width: 60, height: 60, ...iconStyles
        })}
      />
    </div>
  );
}

export default LoadingPage;
