import normalize from "json-api-normalizer";

import { GET } from "../../util/apiHelpers";

import { mapKeysToCamelCase } from "../../util/formatHelpers";
import {
  receivePositionCorrespondences,
  receivePositionCorrespondence,
  receiveAnnouncements,
  receivePositionHistory,
  receiveAnnouncementLoaded,
  receivePositionCorrespondenceLoaded,
  receivePositionHistoryLoaded,
} from "../shared/jobcastUpdatesActions";

// api actions//
export const fetchPositionCorrespondences = jobcastId => dispatch => GET(`/api/v3/recruiter/jobcasts/${jobcastId}/position_correspondences`).then(
  res => {
    const normalized = normalize(res.data);
    return dispatch(receivePositionCorrespondences(normalized.positionCorrespondence));
  }
);

export const fetchPositionCorrespondence = (jobcastId, positionCorrespondenceId) => dispatch => GET(
  `/api/v3/recruiter/jobcasts/${jobcastId}/position_correspondences/${positionCorrespondenceId}`
).then(res => {
  const normalized = normalize(res.data);
  return dispatch(receivePositionCorrespondence(normalized.positionCorrespondence));
});

export const fetchUnansweredPositionCorrespondences = jobcastId => dispatch => GET(
  `/api/v3/recruiter/jobcasts/${jobcastId}/position_correspondences/unanswered_questions`
)
  .then(res => {
    const normalized = normalize(res.data);
    return dispatch(receivePositionCorrespondences(normalized.positionCorrespondence));
  })
  .catch(error => Promise.reject(error.response.data));

export const fetchPositionHistory = jobcastId => dispatch => GET(`/api/v3/recruiter/jobcasts/${jobcastId}/position_history`).then(
  res => {
    const normalized = mapKeysToCamelCase(res.data);

    return dispatch(receivePositionHistory(normalized));
  }
);

export const fetchAnnouncements = jobcastId => dispatch => GET(`/api/v3/recruiter/jobcasts/${jobcastId}/announcements`).then(
  res => {
    const normalized = normalize(res.data);
    return dispatch(receiveAnnouncements(normalized.announcement));
  }
);

export const fetchUpdates = jobcastId => dispatch => {
  const announcements = dispatch(fetchAnnouncements(jobcastId)).then(() => {
    dispatch(receiveAnnouncementLoaded(jobcastId));
    return Promise.resolve();
  });

  const positionCorrespondences = dispatch(fetchPositionCorrespondences(jobcastId)).then(() => {
    dispatch(receivePositionCorrespondenceLoaded(jobcastId));
    return Promise.resolve();
  });

  const unansweredPositionCorrespondences = dispatch(
    fetchUnansweredPositionCorrespondences(jobcastId)
  ).then(() => {
    dispatch(receivePositionCorrespondenceLoaded(jobcastId));
    return Promise.resolve();
  });

  const positionHistory = dispatch(fetchPositionHistory(jobcastId)).then(() => {
    dispatch(receivePositionHistoryLoaded(jobcastId));
    return Promise.resolve();
  });

  return Promise.all([
    announcements,
    positionCorrespondences,
    unansweredPositionCorrespondences,
    positionHistory
  ]);
};
