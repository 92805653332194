import { TextField, Grid } from "@material-ui/core";
import { Formik } from "formik";
import moment from "moment";
import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { object, string, array } from 'yup';

import { editContract as employerEditContract, fetchContracts } from "../../../actions/employers/contractActions";
import { fetchCostCenters } from "../../../actions/employers/costCenterActions";
import { fetchTeammates } from "../../../actions/employers/teammateActions";
import { editContract as recruiterEditContract } from '../../../actions/recruiters/contractActions';
import { clearModal, refetchLeftDrawerCounts } from "../../../actions/shared/uiActions";
import { makeGetFilteredContracts } from "../../../selectors/contractSelector";
import { getCurrentIndividual } from "../../../selectors/individualSelectors";
import { buildFullName } from "../../../util/formatHelpers";
import { disabledProps } from "../../../util/formHelpers";
import FieldLabel from "../../employer/JobCasts/view/Description/sections/FieldLabel";
import TimesheetApproversField from
  "../../employer/Shared/Modals/sections/BillingInformation/TimesheetApproversField";
import FormContextDatePicker from
  "../../forms/formik/FormContextInputs/FormContextDatePicker";
import ActionModalContainer from "../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../General/ActionModal/ActionModalHeader";
import useRouteParamFilters from "../../General/customHooks/useRouteParamFilters";
import useSnackbar from "../../General/useSnackbar";

import CostCenterField from "./sections/CostCenterField";
import TimesheetSubmittersField from "./sections/TimeSheetSubmittersField";

const employerValidationSchema = object().shape({
  scheduledEndDate: string().nullable().required("Cannot be blank"),
  timesheetApproverIds: array().of(string()).min(1, "Cannot be blank"),
  costCenterId: string().nullable().required("Cannot be blank"),
});

const recruiterValidationSchema = object().shape({
  timesheetSubmitters: array().of(
    object().shape({
      value: string().nullable(),
      label: string().nullable(),
    })
  ).min(1, "Must have at least 1 submitter"),
});

function EditContractModal({ candidate }) {
  const dispatch = useDispatch();
  const snackbar = useSnackbar();
  const history = useHistory();
  const location = useLocation();

  const [filters] = useRouteParamFilters({ history, location, arrayify: false });

  const currentIndividual = useSelector(getCurrentIndividual);

  const getContracts = useMemo(makeGetFilteredContracts, []);
  const contract = useSelector(
    (state) => getContracts(state, { candidateId: candidate.id })
  )[0];

  const candidateName = `${candidate.attributes.firstName} ${candidate.attributes.lastName}`;

  const timesheetApproverIds = contract.attributes.primaryApprovers.map((approver) => approver.id);
  const { costCenterId } = contract.attributes;

  const timesheetSubmitters = (contract.attributes.timesheetSubmitters ?? [])
    .map(({ id, firstName, lastName }) => (
      {
        value: id,
        label: buildFullName(firstName, lastName)
      }
    ));

  const employer = currentIndividual.type === "employer";

  const validationSchema = employer ? employerValidationSchema : recruiterValidationSchema;

  const onSubmit = (values, { setSubmitting }) => {
    const editContract = employer ? employerEditContract : recruiterEditContract;

    const data = {
      scheduled_end_date: values.scheduledEndDate,
      timesheet_approver_ids: values.timesheetApproverIds,
      timesheet_submitter_ids: values.timesheetSubmitters.map(({ value }) => value),
      cost_center_id: values.costCenterId,
    };

    dispatch(editContract(contract.attributes.recruiterSubmissionId, contract.id, data))
      .then(() => {
        dispatch(clearModal());
        dispatch(fetchTeammates());
        dispatch(fetchContracts(filters));
        dispatch(fetchCostCenters());

        snackbar.showMessage("Contract successfully saved");
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        scheduledEndDate:
          moment(contract.attributes.scheduledEndDate).utc().format("YYYY-MM-DD"),
        timesheetApproverIds,
        timesheetSubmitters,
        costCenterId,
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, ...props }) => (
        <ActionModalContainer color="blue">
          <ActionModalHeader
            title="Edit Contract"
            subtitle={candidateName}
          />
          <ActionModalContent>
            <p style={{ margin: "0 0 24px 0" }}>
              Use this form to edit fields on an open contract.
              Only identifying information and fields that can be edited will appear here.
            </p>
            <div style={{ width: "100%", overflow: 'hidden' }}>
              <Grid item xs={3}>
                <TextField
                  {...disabledProps(true)}
                  label={<FieldLabel name="Worker Name" />}
                  value={candidateName}
                />
              </Grid>
              <Grid container style={{ marginBottom: 40 }}>
                <Grid item xs={4}>
                  {employer ? (
                    <>
                      <FormContextDatePicker
                        id="scheduledEndDate"
                        label="Tentative End Date"
                        inputLabelProps={{ shrink: true }}
                        inputProps={{ style: { height: 23 } }}
                      />
                      <CostCenterField />
                    </>
                  ) : (
                    <TextField
                      {...disabledProps(true)}
                      label="Tentative End Date"
                      value={moment(contract.attributes.scheduledEndDate).utc().format("MMM Do, YYYY")}
                    />
                  )}
                </Grid>
                <Grid item xs={6} style={{ marginLeft: 20 }}>
                  {employer
                    ? <TimesheetApproversField />
                    : <TimesheetSubmittersField />}
                </Grid>
              </Grid>
            </div>
          </ActionModalContent>
          <ActionModalFooter
            actionText="Save"
            handleAction={() => {
              handleSubmit();
            }}
            isSubmitting={props.isSubmitting}
          />
        </ActionModalContainer>
      )}
    </Formik>
  );
}

export default EditContractModal;
