import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import size from "lodash/size";
import React from "react";
import { useSelector } from "react-redux";

import DropdownInput from "../../forms/custom/DropdownInput.react";

function LabelTooltip() {
  return (
    <Tooltip
      placement="right"
      title="If you do not see the correct Cost Center, add it from your Invoice Settings. Cost Centers are required to mark a hire."
    >
      <label style={{ width: "fit-content" }}>
        <span style={{ display: "flex", alignItems: "center" }}>
          Cost Center
          <InfoOutlinedIcon
            style={{
              color: "#90A4AE",
              fontSize: 16,
              paddingLeft: 4,
            }}
          />
        </span>
      </label>
    </Tooltip>
  );
}

function CostCenterDropdown({
  disabled,
  error,
  helperText,
  id,
  onChange,
  onBlur,
  value,
  style,
}) {
  const costCenters = useSelector((state) => state.costCenters.items);
  const isVisible = size(costCenters) > 1;
  const costcenterOptions = orderBy(
    map(costCenters, ({ attributes: { id, name } }) => ({
      label: name,
      value: id,
    })),
    ({ label }) => label.toLowerCase()
  );

  return isVisible ? (
    <DropdownInput
      overrideStyle={style}
      allowEmpty
      disabled={disabled}
      noShrink
      error={error}
      helperText={helperText}
      id={id}
      label={<LabelTooltip />}
      onChange={onChange}
      onBlur={onBlur}
      options={costcenterOptions}
      value={value}
      labelStyle={{
        color: "#B0BEC5",
      }}
    />
  ) : (
    <></>
  );
}

export default CostCenterDropdown;
