import { useContext } from "react";

import { SnackbarContext } from "../GlobalSnackbarProvider";

export default function useSnackbar() {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error("useSnackbar must be used within SnackbarContextProvider");
  }
  return context.snackbar;
}
