import React from "react";

import CandidateActionMenu from "../../../employer/JobCasts/view/Candidates/actions/CandidateActionMenu";
import DeleteCandidateButton from "../actions/DeleteCandidateButton";

import HireDetailsButton from "../actions/modalMountCTAs/HireDetailsButton";
import SubmitHoursButton from "../actions/modalMountCTAs/SubmitHoursButton";
import WithdrawButton from "../actions/modalMountCTAs/WithdrawButton";
import RemindButton from "../actions/RemindButton";

function ButtonContainer({ candidate }) {
  const buttons = (() => {
    switch (candidate.attributes.status) {
      case "unconfirmed":
        return [
          DeleteCandidateButton,
          RemindButton
        ];
      case "contract_active":
        return [SubmitHoursButton, CandidateActionMenu];
      case "contract_ended":
        return [CandidateActionMenu];
      case "hired":
      case "hired_failed":
        return [HireDetailsButton, CandidateActionMenu];
      case "rejected":
      case "withdrawn":
        return [CandidateActionMenu];
      case "new":
      case "awaiting_sort":
      case "reviewing":
      case "interviewing":
      case "offer":
      default:
        return [WithdrawButton, CandidateActionMenu];
    }
  })();

  const buttonElements = buttons.map((buttonEl, idx) => React.createElement(buttonEl, {
    key: `${buttonEl}-${candidate.id}-${idx}`,
    candidate,
    drawerBtn: true,
    overrideStyle: idx ? { marginLeft: 16 } : {},
    displayTime: true,
  }));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {buttonElements}
    </div>
  );
}

export default ButtonContainer;
