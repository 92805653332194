import TimerSandComplete from "mdi-material-ui/TimerSand";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import withNotificationMarkedRead from "../../../../recruiter/NotificationsDrawer/NotificationViewTab/withNotificationMarkedRead";

import NotificationContent from "../../../../shared/NotificationsDrawer/NotificationViewTab/partials/Content";
import Subheader from "../../../../shared/NotificationsDrawer/NotificationViewTab/partials/Subheader";

import ApproachingArchiveResolution from "./StatusResolution/ApproachingArchiveResolution";
import PositionArchived from "./StatusResolution/PositionArchived";
import PositionExtended from "./StatusResolution/PositionExtended";

function StatusResolution({ jobcast }) {
  const { archivedAt, approachingArchived } = jobcast.attributes;

  if (approachingArchived) {
    return <ApproachingArchiveResolution jobcast={jobcast} />;
  }

  if (moment(archivedAt).isBefore(moment())) {
    return <PositionArchived jobcast={jobcast} />;
  }

  return <PositionExtended jobcast={jobcast} />;
}

StatusResolution.propTypes = {
  jobcast: PropTypes.object.isRequired,
};

function PositionArchivingSoon({ jobcast, notification }) {
  const { messageBody, createdAt } = notification.attributes;

  return (
    <div>
      <Subheader headerText="JobCast Closing Soon" />
      <NotificationContent
        iconImage={TimerSandComplete}
        iconCaption="Closing Soon"
        label="JobCast Closing Soon"
        value={messageBody}
        helperText={moment(createdAt).format("MMMM Do, YYYY")}
      />
      <div style={{ paddingLeft: 24, paddingRight: 24 }}>
        <StatusResolution jobcast={jobcast} />
      </div>
    </div>
  );
}

PositionArchivingSoon.propTypes = {
  jobcast: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
};

export default withNotificationMarkedRead(PositionArchivingSoon);
