import get from "lodash/get";
import isDeepEqual from "lodash/isEqual";

const isShallowEqual = (objA, objB) => {
  let key;

  for (key in objA) {
    if (objA[key] !== objB[key]) {
      return false;
    }
  }

  for (key in objB) {
    if (objA[key] !== objB[key]) {
      return false;
    }
  }

  return true;
};

// makes sure that an input component doesn't rerender
// with irrelevant changes to the form state (ex: another input's value changing)
const shouldSkipRerender = (
  {
    formContext: {
      values: prevValues,
      errors: prevErrors,
      touched: prevTouched,
    },
    formState: prevFormState,
    inputProps: prevInputProps,
    style: prevStyle,
    ...prevCustomProps
  },
  {
    formContext: {
      values: nextValues,
      errors: nextErrors,
      touched: nextTouched,
    },
    formState: nextFormState,
    inputProps: nextInputProps,
    style: nextStyle,
    ...nextCustomProps
  }
) => {
  // only want to do a shallow compare with custom props since
  // we don't know how deep these can go
  if (!isShallowEqual(prevCustomProps, nextCustomProps)) {
    return false;
  }
  const { id } = prevCustomProps;
  // these formContext props will never go more than a couple levels deep (numbered lists, for ex)
  // so we do a deep compare
  const isTargetEqual = (prevObj, nextObj) => isDeepEqual(get(prevObj, id), get(nextObj, id));

  return (
    isDeepEqual(prevFormState, nextFormState) &&
      isDeepEqual(prevInputProps, nextInputProps) &&
      isDeepEqual(prevStyle, nextStyle) &&
      isTargetEqual(prevValues, nextValues) &&
      isTargetEqual(prevErrors, nextErrors) &&
      isTargetEqual(prevTouched, nextTouched)
  );
};

export default shouldSkipRerender;
