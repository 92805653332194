import includes from "lodash/includes";
import startCase from "lodash/startCase";
import React from "react";

import PopoverLink from "../../General/PopoverLink";

import OptimizedRatesIcon from '../Icons/OptimizedRatesIcon';

import { JOBCASTS, CANDIDATES, AGENCIES } from "./constants";

export const deconstructName = (item) => {
  let name;

  switch (item.type) {
    case JOBCASTS:
      const jobcastDescription = JSON.parse(item.description);
      name = jobcastDescription.title;
      break;
    case CANDIDATES:
      name = item.name;
      break;
    case AGENCIES:
      const agencyDescription = JSON.parse(item.description);
      name = agencyDescription.individual_name;
      break;
    default:
      return {};
  }

  if (name) {
    const [firstName, ...lastName] = name.split(" ");
    return {
      firstName,
      lastName: lastName.join(" "),
    };
  }

  return {};
};

export const deconstructDescription = (item) => {
  let description = {};

  switch (item.type) {
    case JOBCASTS:
      description = JSON.parse(item.description);
      return description.location;
    case AGENCIES:
      description = JSON.parse(item.description);
      return description.organization_name;
    case CANDIDATES:
      description = JSON.parse(item.description);
      return description.position_info;
    default:
      return item.description;
  }
};

export const parseSupportingInfo = (item) => {
  switch (item.type) {
    case JOBCASTS:
      return startCase(
        item.isRecruiter
          ? item.requestStatus
          : item.status === "archived"
            ? "closed"
            : item.status
      );
    case AGENCIES:
      if (item.status === "RecruitiFi Optimized Fee") {
        return (
          <PopoverLink
            renderLink={(handleClick, _isSelected) => (
              <span onClick={handleClick} onMouseEnter={handleClick}>
                <OptimizedRatesIcon style={{ fontSize: 28 }} />
              </span>
            )}
            renderPopoverContent={(handleClick) => (
              <div
                data-cy="fee-tooltip-content"
                style={{ padding: "6px 8px", fontSize: 16, zIndex: 99999 }}
                onMouseLeave={handleClick}
              >
                <a
                  href="https://help.recruitifi.com/en/articles/5485229-what-is-the-recruitifi-optimized-fee-and-how-does-it-work"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RecruitiFi Optimized Fee
                </a>
              </div>
            )}
          />
        );
      }
      return item.status;

    case CANDIDATES:
      return item.isRecruiter && item.status === "new"
        ? "Awaiting Sort"
        : startCase(item.status);
    default:
      return startCase(item.status);
  }
};

export const parseCandidateStatus = (status) => {
  if (status === "offer") {
    return "offers";
  } if (status === "hired") {
    return "hires";
  }
  return status;
};

export const parseRecruiterCandidateStatusSection = (status) => {
  if (status === "hired") {
    return "hired";
  } if (includes(["withdrawn", "declined", "rejected"], status)) {
    return "rejected";
  }
  return "active";
};
