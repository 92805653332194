// callback action types//
export const RECEIVE_SEARCH_RESULTS = "RECEIVE_SEARCH_RESULTS";
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS";
export const SET_LOADING_STATE = "SET_LOADING_STATE";
export const UPDATE_TERM = "UPDATE_TERM";

// callback actions//
export const receiveSearchResults = (results, term = "") => ({
  type: RECEIVE_SEARCH_RESULTS,
  payload: { results, term },
});

export const clearSearchResults = () => ({
  type: CLEAR_SEARCH_RESULTS,
});

export const setLoadingState = (value) => ({
  type: SET_LOADING_STATE,
  payload: value,
});

export const updateTerm = (value) => ({
  type: UPDATE_TERM,
  payload: value,
});
