import get from "lodash/get";
import omit from "lodash/omit";
import { batch } from "react-redux";

import { getViewingAs } from "../../selectors/individualSelectors";
import { PATCH, POST } from "../../util/apiHelpers";

import { storageAvailable } from "../../util/storageHelpers";
import {
  processIndividual,
  receiveEmailSettings,
  receiveViewingAs,
} from "../shared/individualActions";
import { clearJobcasts } from "../shared/jobcastActions";

// callback action types//
export const RECEIVE_INDIVIDUAL_POSITION_TEMPLATE =
  "RECEIVE_INDIVIDUAL_POSITION_TEMPLATE";

// callback actions//
export const receiveIndividualPositionTemplate = (positionTemplate) => ({
  type: RECEIVE_INDIVIDUAL_POSITION_TEMPLATE,
  payload: positionTemplate,
});

// api actions//
export const updateEmailSettings = (email_settings) => (dispatch) => PATCH("/api/v3/employer/email_settings", email_settings)
  .then((res) => {
    dispatch(receiveEmailSettings(res.data));
    return Promise.resolve();
  })
  .catch((error) => Promise.reject(error));

export const createEmployer = (formData) => (dispatch) => POST("/api/v3/individual/create_employer", formData)
  .then((res) => processIndividual(res.data, dispatch))
  .catch((error) => Promise.reject(error.response.data));

export const setViewingAs = (viewingAs) => (dispatch, getState) => {
  const currViewingAsKey = get(getViewingAs(getState()), "key", "me");
  const viewingAsChanged = viewingAs.key !== currViewingAsKey;

  if (storageAvailable()) {
    viewingAs.key === "me"
      ? global.localStorage.removeItem("viewingAs")
      : global.localStorage.setItem(
        "viewingAs",
        JSON.stringify(omit(viewingAs, ["label"]))
      );
  }

  batch(() => {
    dispatch(receiveViewingAs(viewingAs));
    if (viewingAsChanged) {
      dispatch(clearJobcasts());
    }
  });
};
