import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import round from "lodash/round";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { formatCurrency } from "../../../../util/formatHelpers";
import PageCardRowHeader from "../../../General/PageCardRowHeader";
import RotatingEmptyState from "../../EmptyState/RotatingEmptyState";
import { UNCONFIRMED } from "../constants";

/** @type React.Context<any> */
const CandidateContext = React.createContext({});

function Section({ label, children }) {
  return (
    <div data-cy="candidate-responses" style={{ paddingBottom: 24 }}>
      <div
        className="field-label"
        style={{
          paddingBottom: 0,
          wordBreak: "break-word",
        }}
      >
        {label}
      </div>
      {children}
    </div>
  );
}

Section.propTypes = {
  label: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

function AskingSalary({ employer }) {
  const { askingSalary, askingSalaryCurrency } = useContext(CandidateContext);

  const tooltip = (
    <Tooltip
      id="tooltip-icon"
      title={`This is provided by the candidate as an initial asking salary.
        It can be discussed with the ${
          employer
            ? "submitting agency via the Agency chat"
            : "corporate recruiter via the Employer Chat"
        }.`}
    >
      <InfoOutlinedIcon
        style={{
          color: "#546e7a",
          margin: "0px 0px -2px 4px",
          fontSize: 14,
        }}
      />
    </Tooltip>
  );
  return (
    <div style={{ paddingBottom: 24 }}>
      <div className="field-label" style={{ paddingBottom: 0 }}>
        Asking Salary
        {tooltip}
      </div>
      {`${askingSalaryCurrency} ${askingSalary}`}
    </div>
  );
}

function VisaSponsorship() {
  const { visaSponsorship, jobcastCountryCodes } = useContext(CandidateContext);

  if (intersection(jobcastCountryCodes, ["US", "USA"]).length === 0) {
    return null;
  }

  return (
    <Section label="Visa Sponsorship">{visaSponsorship ? "Yes" : "No"}</Section>
  );
}

function ConsideredLocations() {
  const { positionLocations } = useContext(CandidateContext);

  if (isEmpty(positionLocations)) return null;

  return (
    <div
      style={{
        paddingBottom: 24,
        paddingLeft: 36,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="field-label" style={{ paddingBottom: 0 }}>
        Would like to be considered for the following location(s):
      </div>
      {map(positionLocations, (location) => (
        <div>
          {`${location.city}, ${location.state}`}
          <br />
        </div>
      ))}
    </div>
  );
}

function EmptyScreeningQuestions() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p className="body-default">
        Ask screening question on your JobCasts to learn more about your
        candidates. The responses will appear here.
      </p>
    </div>
  );
}

function ScreeningQuestionResponses() {
  const { screeningQuestionsAndResponses } = useContext(CandidateContext);

  return (
    <div style={{ paddingLeft: 36 }}>
      {map(screeningQuestionsAndResponses, (sq) => (
        <Section label={sq.question}>{sq.answer}</Section>
      ))}
    </div>
  );
}

function ScreeningQuestions({ employer }) {
  const { screeningQuestionsAndResponses, status } =
    useContext(CandidateContext);

  if (status === UNCONFIRMED) return null;

  return (
    <>
      <PageCardRowHeader title="Candidate responses to the application questions" />
      <div style={{ display: "flex", flexDirection: "row", paddingLeft: 36 }}>
        <div>
          <AskingSalary employer={employer} />
        </div>
        <div style={{ paddingLeft: 48 }}>
          <VisaSponsorship />
        </div>
      </div>
      <div
        style={{
          width: "100%",
        }}
      >
        <ConsideredLocations />
        {isEmpty(screeningQuestionsAndResponses) && employer ? (
          <EmptyScreeningQuestions />
        ) : (
          <ScreeningQuestionResponses />
        )}
      </div>
    </>
  );
}

function MustHaves() {
  const { mustHavesTitle, mustHavesValue, recruiterNote } =
    useContext(CandidateContext);

  return (
    <>
      <PageCardRowHeader title="Agency responses to the must-haves" />
      <div style={{ paddingLeft: 36 }}>
        {map(mustHavesTitle, (mustHave, idx) => (
          <Section key={idx} label={mustHave}>
            {mustHavesValue[idx] || ""}
          </Section>
        ))}
        {recruiterNote && (
          <Section label="Additional Information from the agency">
            {recruiterNote}
          </Section>
        )}
      </div>
    </>
  );
}

function PriorityBonus({ candidate }) {
  if (!candidate.attributes.priorityBonus) return null;

  const { amount, currencyCode } = candidate.attributes.priorityBonus;
  return (
    <Section label="Priority Bonus">
      {formatCurrency(amount, currencyCode, { noConversion: true })}{" "}
      {currencyCode}
    </Section>
  );
}

function FeeOptions() {
  const { feeOption, feeOptionChoices } = useContext(CandidateContext);

  return (
    <Section label="Agreed To Fee(s)">
      {map(
        feeOptionChoices || [feeOption],
        ({ feeType, feePercentage, feeCents, currency, note }, index) => (
          <div key={index}>
            {feeType === "percentage"
              ? `${round(feePercentage * 100, 1)}% ${note ? `- ${note}` : ""}`
              : formatCurrency(feeCents, currency)}
          </div>
        )
      )}
    </Section>
  );
}

function CandidateFeeBreakdown({ candidate, employer }) {
  return (
    <>
      <PageCardRowHeader title="Candidate fee breakdown" />
      <div style={{ display: "flex", flexDirection: "row", paddingLeft: 36 }}>
        <div>
          <FeeOptions />
        </div>
        <div style={{ paddingLeft: 48 }}>
          <PriorityBonus {...{ candidate, employer }} />
        </div>
      </div>
    </>
  );
}

function ResponsesTab({ candidate, employer }) {
  const { offPlatform } = candidate.attributes;

  if (offPlatform) return <RotatingEmptyState />;

  return (
    <CandidateContext.Provider value={candidate.attributes}>
      <CandidateFeeBreakdown {...{ candidate, employer }} />
      <ScreeningQuestions employer={employer} />
      <MustHaves />
    </CandidateContext.Provider>
  );
}

export default ResponsesTab;
