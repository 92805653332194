import keys from "lodash/keys";

import { RECEIVE_MATCH_PREFERENCES } from "../../actions/recruiters/individualActions";
import { RECEIVE_CURRENT_INDIVIDUAL } from "../../actions/shared/individualActions";
import { mergeWithArrayOverwrite } from "../../util/formatHelpers";

const _defaultState = {
  items: {},
};

export const matchPreferenceReducer = (state = _defaultState, action) => {
  Object.freeze(state);

  switch (action.type) {
    case RECEIVE_CURRENT_INDIVIDUAL:
      const currentIndividualId = keys(action.payload)[0];
      const { matchPreferences } = action.payload[currentIndividualId].attributes;
      return mergeWithArrayOverwrite({}, state, { items: matchPreferences });
    case RECEIVE_MATCH_PREFERENCES:
      return mergeWithArrayOverwrite({}, state, {
        items: action.payload,
      });
    default:
      return state;
  }
};

export default matchPreferenceReducer;
