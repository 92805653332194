export const RECEIVE_REQUESTS = "RECEIVE_REQUESTS";
export const CLEAR_REQUESTS = "CLEAR_REQUESTS";
export const SET_REQUESTS_INDEX_LOADED = "SET_REQUESTS_INDEX_LOADED";

export const receiveRequests = (requests) => ({
  type: RECEIVE_REQUESTS,
  payload: requests,
});

export const clearRequests = () => ({
  type: CLEAR_REQUESTS,
});

export const setRequestsIndexLoaded = (
  positionType,
  indexType,
  isLoaded = true
) => ({
  type: SET_REQUESTS_INDEX_LOADED,
  payload: { indexType, positionType, isLoaded },
});
