import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteForever from "@material-ui/icons/DeleteForever";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import map from "lodash/map";
import mapKeys from "lodash/mapKeys";
import merge from "lodash/merge";
import PropTypes from "prop-types";
import React, { Component } from "react";

import Button from "../custom/Button";

const styles = {
  numberAdornment: {
    borderRadius: "3px",
    backgroundColor: "#90a4ae",
    color: "#fff",
    display: "inline-block",
    fontFamily: "proxima-nova",
    fontSize: "12px",
    height: "15px",
    lineHeight: "15px",
    marginRight: "10px",
    padding: "0 3px",
    textAlign: "center",
    minWidth: "9px",
    position: "relative",
  },
  numberAdornmentError: {
    borderRadius: "3px",
    backgroundColor: "#FF6D6D",
    color: "#fff",
    display: "inline-block",
    fontFamily: "proxima-nova",
    fontSize: "12px",
    height: "15px",
    lineHeight: "15px",
    marginRight: "10px",
    padding: "0 3px",
    textAlign: "center",
    minWidth: "9px",
    position: "relative",
  },
  addNew: {
    color: "#008dae",
    width: 100,
    cursor: "pointer",
    fontSize: "12px",
    height: "32px",
    lineHeight: "32px",
    flexDirection: "row",
    display: "flex",
    alignItems: "center"
  },
  deleteContainer: {
    cursor: "pointer",
    marginLeft: "10px",
    position: "relative",
    width: 24,
    bottom: "6px"
  }
};

class EditableNumberedList extends Component {
  constructor(props) {
    super(props);
    this.state = { listFocused: false };
  }

  componentDidMount() {
    const { minItems } = this.props;
    const numItems = this.getNumItems();

    if (numItems < minItems) {
      this.addItems(minItems - numItems)();
    }
  }

  componentDidUpdate(prevProps) {
    const { editing, minItems } = this.props;

    if (prevProps.editing && !editing) {
      this.unsetEditing();
    } else if (!prevProps.editing && editing && minItems > this.getNumItems()) {
      this.addItems(minItems - this.getNumItems())();
    }
  }

  addItems = (toAdd = 1) => () => {
    const { listName, values, setFieldValue } = this.props;

    const newItems = [];
    while (newItems.length < toAdd) {
      newItems.push("");
    }

    setFieldValue(listName, values[listName].concat(newItems));
  };

  removeItem = (idx) => () => {
    const {
      listName,
      values,
      touched,
      setFieldValue,
      setFieldTouched
    } = this.props;

    const newItems = cloneDeep(values[listName]);
    newItems.splice(idx, 1);
    setFieldValue(listName, newItems);

    if (touched[listName]) {
      let newTouched = cloneDeep(touched[listName]);
      delete newTouched[idx];
      newTouched = mapKeys(newTouched, touchedIdx => (touchedIdx > idx ? touchedIdx - 1 : touchedIdx));
      setFieldTouched(listName, newTouched);
    }
  };

  unsetEditing = () => {
    const {
      listName, values, setFieldValue, setFieldTouched
    } = this.props;

    const newValues = values[listName].filter(val => val);
    setFieldValue(listName, newValues);
    setFieldTouched(listName, {});
  }

  updateItem = (idx) => (e) => {
    const { listName, values, setFieldValue } = this.props;

    const newItems = cloneDeep(values[listName]);
    newItems[idx] = e.currentTarget.value;

    setFieldValue(listName, newItems);
  };

  setItemTouched = (idx) => (_e) => {
    const { listName, touched, setFieldTouched } = this.props;

    const newTouchedItems = merge({}, touched[listName] || {}, { [idx]: true });

    setFieldTouched(listName, newTouchedItems);
  };

  getNumItems = () => {
    const { listName, values } = this.props;
    return values[listName].length;
  };

  getItemValue = (idx) => {
    const { listName, values } = this.props;

    return values[listName][idx];
  };

  getItemError = (idx) => {
    const { listName, errors } = this.props;

    return (isArray(errors[listName]) && errors[listName][idx]) || "";
  };

  getItemTouched = (idx) => {
    const { listName, touched } = this.props;

    return (typeof touched[listName] === "object" && touched[listName][idx]) || false;
  };

  showItemError = (idx) => {
    const { editing } = this.props;

    return Boolean(editing && this.getItemTouched(idx) && this.getItemError(idx));
  }

  getListError = () => {
    const { listName, errors } = this.props;
    return typeof errors[listName] === "string" && errors[listName];
  }

  showListError = () => {
    const { editing, listName, touched } = this.props;
    const { listFocused } = this.state;

    return Boolean(editing && !listFocused && touched[listName] && this.getListError());
  }

  setListFocused = () => {
    this.setState({ listFocused: true });
  }

  handleBlur = (itemIdx) => () => {
    this.setItemTouched(itemIdx)();
    this.setState({ listFocused: false });
  }

  renderField(idx) {
    const { listName, inputField } = this.props;

    return React.cloneElement(inputField, {
      value: this.getItemValue(idx),
      error: Boolean(this.showItemError(idx) || this.showListError()),
      helperText: (this.showItemError(idx) && this.getItemError(idx)) || "",
      onChange: this.updateItem(idx),
      onBlur: this.handleBlur(idx),
      onFocus: this.setListFocused,
      id: `${listName}-${idx}-input`,
      inputProps: { "data-cy": `${listName}-${idx}-input` },
      style: { marginBottom: "0" }
    });
  }

  renderDelete(idx) {
    const {
      editing, minItems, classes
    } = this.props;

    // I moved the disableDelete outside the div so that it won't take up unnecessary space
    // Possibly the rest of the inner check can be moved out as well
    return (
      <div className={classes.deleteContainer}>
        {editing && this.getNumItems() > minItems ? (
          <DeleteForever
            onClick={this.removeItem(idx)}
            style={{
              color: "#FF6D6D",
              cursor: "pointer",
              fontSize: "28px",
              height: "28px",
              width: "28px"
            }}
          />
        ) : null}
      </div>
    );
  }

  renderNum(idx) {
    const { classes, increaseStartNumberBy = 0 } = this.props;

    const hasError = Boolean(this.showItemError(idx) || this.showListError(idx));

    return (
      <span
        className={
          hasError ? classes.numberAdornmentError : classes.numberAdornment
        }
      >
        {idx + 1 + increaseStartNumberBy}
      </span>
    );
  }

  renderItem(idx) {
    const {
      listName, showNumber, itemStyle, disableDelete
    } = this.props;

    return (
      <div
        key={`${listName}-${idx}`}
        style={({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "20px",
          ...itemStyle || {}
        })}
      >
        {showNumber && this.renderNum(idx)}
        {this.renderField(idx)}
        {!disableDelete && this.renderDelete(idx)}
      </div>
    );
  }

  renderItems() {
    const { editing, values, listName } = this.props;

    let itemsToRender = values[listName];

    if (!editing) {
      let maxValIdx = itemsToRender.length - 1;
      while (!itemsToRender[maxValIdx] && maxValIdx >= 0) {
        maxValIdx--;
      }
      itemsToRender = itemsToRender.slice(0, maxValIdx + 1);
    }

    return map(itemsToRender, (_item, idx) => this.renderItem(idx));
  }

  renderAdd() {
    const {
      disableAdd, editing, maxItems, listName
    } = this.props;

    if (!disableAdd && editing && !(maxItems && this.getNumItems() >= maxItems)) {
      return (
        <Button
          style={{ border: "none", padding: 0, width: "125px" }}
          onClick={this.addItems()}
          data-cy={`add-another-${listName}`}
        >
          + Add Another
        </Button>
      );
    }
  }

  renderTitle() {
    const { title } = this.props;
    if (title) {
      return (
        <div
          className="static-input-label"
          style={{ paddingBottom: 6, lineHeight: "13px" }}
        >
          {title}
        </div>
      );
    }
    return null;
  }

  renderListError() {
    if (this.showListError()) {
      return <div className="error helper-text">{this.getListError()}</div>;
    }
  }

  render() {
    const { listName, editing, style } = this.props;

    if (!editing && !this.getNumItems()) {
      return <></>;
    }
    return (
      <div
        style={({
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          marginBottom: 24,
          ...style || {}
        })}
      >
        {this.renderTitle()}
        <div data-cy={`${listName}-body`}>
          {this.renderItems()}
        </div>
        {this.renderAdd()}
        {this.renderListError()}
      </div>
    );
  }
}

EditableNumberedList.defaultProps = {
  editing: true,
  disableDelete: false,
  disableAdd: false,
  showNumber: true,
  minItems: 1
};

EditableNumberedList.propTypes = {
  // formikBag props
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  // the input element to be rendered for each item
  inputField: PropTypes.node.isRequired,
  listName: PropTypes.string.isRequired,
  // props to add/remove functionality
  editing: PropTypes.bool,
  disableAdd: PropTypes.bool,
  disableDelete: PropTypes.bool,
  minItems: PropTypes.number,
  maxItems: PropTypes.number,
  showNumber: PropTypes.bool
};

export default withStyles(styles)(EditableNumberedList);
