const sharedStyles = {
  subheaderContainer: {
    height: "85px",
    backgroundColor: "#ECEFF1",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 12
  }
};

export default sharedStyles;
