import { useField } from "formik";
import map from "lodash/map";
import omitBy from "lodash/omitBy";
import React from "react";
import { useSelector } from "react-redux";

import { getCostCenters } from "../../../../actions/employers/costCenterActions";
import FormContextDropdown from "../../../forms/formik/FormContextInputs/FormContextDropdown";

const CostCenterField = ({ costCenterToExclude }) => {
  const costCenters = useSelector(getCostCenters);

  const [{ value, onBlur }, { error }, { setValue }] = useField('costCenterId');

  let filteredCostCenters = {};

  if (costCenterToExclude) {
    filteredCostCenters = omitBy(
      costCenters,
      (costCenter) => costCenter.id === costCenterToExclude.id
    );
  } else {
    filteredCostCenters = costCenters;
  }

  const options = map(filteredCostCenters, (costCenter) => ({
    value: costCenter.attributes.id,
    label: costCenter.attributes.name,
  }));

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormContextDropdown
      id="costCenterId"
      label="Cost Center"
      options={options}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      error={Boolean(error)}
      helperText={error}
    />
  );
};

export default CostCenterField;
