import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

import {
  RECEIVE_CONVERSATION,
  RECEIVE_MESSAGE,
} from "../actions/shared/candidateActions";

const _defaultState = { loaded: false, items: {} };

export const conversationReducer = (state = _defaultState, action) => {
  Object.freeze(state);
  let newConversation;

  switch (action.type) {
    case RECEIVE_CONVERSATION:
      newConversation = cloneDeep(state.items);
      newConversation[action.payload.id] = merge({}, action.payload);
      return { ...state, loaded: true, items: newConversation };
    case RECEIVE_MESSAGE:
      newConversation = cloneDeep(state.items);
      newConversation[action.payload.conversationId].attributes.messages =
        merge(
          newConversation[action.payload.conversationId].attributes.messages,
          action.payload.message
        );
      return { ...state, items: newConversation };
    default:
      return state;
  }
};
