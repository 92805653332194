import { Collapse, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { getCurrentOrganization } from "../../../../selectors/organizationSelectors";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
  },
  title: {
    color: "#008DAE",
    cursor: "pointer",
    marginBottom: theme.spacing(2),
  },
}));

function AdvancedOptionsSection({ children, overrideStyle = {} }) {
  const currentOrganization = useSelector(getCurrentOrganization);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  // temporary, remove when standardized fee handling built (post MVP)
  if (currentOrganization.hasStandardizedPreferredFees) return <></>;

  const actionText = expanded ? "Hide" : "Show";

  return (
    <div className={classes.container} style={overrideStyle.container || {}}>
      <div onClick={() => setExpanded(!expanded)} className={classes.title}>
        {actionText} Advanced Options
      </div>
      <Collapse in={expanded}>{children}</Collapse>
    </div>
  );
}

export default AdvancedOptionsSection;
