import React from "react";

import RotatingEmptyState from "../../../EmptyState/RotatingEmptyState";

import AgencyMustHaves from "./AgencyMustHaves";

import CandidateScreeningQuestions from "./CandidateScreeningQuestions";

function ResponsesTab(props) {
  const { offPlatform } = props.candidate.attributes;

  if (offPlatform) return <RotatingEmptyState />;

  return (
    <>
      <CandidateScreeningQuestions {...props} />
      <AgencyMustHaves {...props} />
    </>
  );
}

export default ResponsesTab;
