import { Formik } from "formik";
import concat from "lodash/concat";
import map from "lodash/map";
import merge from "lodash/merge";
import omit from "lodash/omit";
import round from "lodash/round";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { createLegacyInvitations } from "../../../../../actions/employers/agencyActions";
import { clearModal } from "../../../../../actions/shared/uiActions";
import { makeGetAgencyRecruiter } from "../../../../../selectors/employer/agencySelectors";
import { getCurrentOrganization } from "../../../../../selectors/organizationSelectors";
import { mapKeysToSnakeCase } from "../../../../../util/formatHelpers";
import {
  PERCENTAGE_OF_COMPENSATION,
  FLAT_FEE,
} from "../../../../forms/formik/FormikFeeOptions/fieldHelpers";
import ActionModalContainer from "../../../../General/ActionModal/ActionModalContainer";
import ActionModalContent from "../../../../General/ActionModal/ActionModalContent";
import ActionModalFooter from "../../../../General/ActionModal/ActionModalFooter";
import ActionModalHeader from "../../../../General/ActionModal/ActionModalHeader";
import withSnackbar from "../../../../General/withSnackbar";

import { buildValidationSchema } from "../../fieldHelpers";

import { CUSTOM } from "../../TempInviteAgency/fieldHelpers";

import AddAsLegacyModalContent from "./AddAsLegacyModalContent";

import { buildInitialValues } from "./fieldHelpers";

function AddAsLegacyModal({ recruiterId, snackbar, setExcludedOrgIds }) {
  const dispatch = useDispatch();

  const getAgencyRecruiter = makeGetAgencyRecruiter();
  const currentOrganization = useSelector(getCurrentOrganization);
  const agencyRecruiter = useSelector((state) => getAgencyRecruiter(state, recruiterId));

  const initialValues = useMemo(
    () => buildInitialValues({ agencyRecruiter, currentOrganization }),
    [agencyRecruiter, currentOrganization]
  );

  const validationSchema = useMemo(
    () => buildValidationSchema({ currentOrganization }),
    [currentOrganization]
  );

  const handleSubmit = useCallback((values, { setSubmitting, resetForm }) => {
    const formData = () => {
      const formattedValues = mapKeysToSnakeCase(
        omit(values, ["perm", "temp"])
      );

      const parseFeeOptions = (feeOptionValues) =>
        map(feeOptionValues, ({
          fee_type, fee, fee_note, currency
        }) => ({
          fee_percentage:
              fee_type === PERCENTAGE_OF_COMPENSATION
                ? round(parseFloat(fee) / 100, 4)
                : null,
          fee_cents:
              fee_type === FLAT_FEE
                ? parseFloat(fee.replace(/,/g, "")) * 100
                : null,
          note: feeOptionValues.length > 1 ? fee_note : "",
          fee_type,
          currency: fee_type === FLAT_FEE ? currency : null,
        }));

      if (values.placementTypes.perm) {
        merge(formattedValues, {
          perm: merge(
            mapKeysToSnakeCase(
              omit(values.perm, ["multipleFees", "feeOptions"])
            ),
            {
              fee_options: values.perm.feeType === CUSTOM ?
                parseFeeOptions(values.perm.feeOptions) : [],
            }
          )
        });
      }

      if (values.placementTypes.temp) {
        merge(formattedValues, {
          temp: merge(
            mapKeysToSnakeCase(
              omit(values.temp, ["multipleFees", "feeOptions"])
            ),
            {
              fee_options: values.temp.feeType === CUSTOM ?
                parseFeeOptions(values.temp.feeOptions) : [],
            }
          )
        });
      }

      return formattedValues;
    };

    dispatch(createLegacyInvitations(formData()))
      .then(() => {
        setExcludedOrgIds((prevExcludedOrgIds) =>
          concat(prevExcludedOrgIds, agencyRecruiter.organization.attributes.id));
      })
      .then(() => {
        setSubmitting(false);
        dispatch(clearModal());
      })
      .then(() => {
        snackbar.showMessage(
          `${agencyRecruiter.organization.attributes.name} has been invited as a preferred agency`
        );
        resetForm();
      })
      .catch(() => {
        setSubmitting(false);
        snackbar.showMessage("There was an issue processing your request");
      });
  }, [agencyRecruiter, setExcludedOrgIds, snackbar, dispatch]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <ActionModalContainer color="blue">
          <ActionModalHeader
            title="Add to Preferred Agencies"
            subtitle={agencyRecruiter.organization.attributes.name}
          />
          <ActionModalContent>
            <AddAsLegacyModalContent currentOrganization={currentOrganization} />
          </ActionModalContent>
          <ActionModalFooter
            actionText="Invite"
            handleAction={props.handleSubmit}
            isSubmitting={props.isSubmitting}
          />
        </ActionModalContainer>
      )}
    </Formik>
  );
}

export default withSnackbar()(AddAsLegacyModal);
