import normalize from "json-api-normalizer";

import { GET, POST, PATCH } from "../../util/apiHelpers";

import { mapKeysToCamelCase } from "../../util/formatHelpers";
import {
  receivePositionCorrespondences,
  receivePositionCorrespondence,
  receiveAnnouncements,
  receiveAnnouncement,
  receivePositionHistory,
  receiveAnnouncementLoaded,
  receivePositionHistoryLoaded,
  receivePositionOwnerTransferHistory,
  receivePositionOwnerTransferHistoryLoaded,
  receivePositionCorrespondenceLoaded,
} from "../shared/jobcastUpdatesActions";
import { refetchLeftDrawerCounts } from "../shared/uiActions";

// callback action types//
export const ARCHIVE_POSITION_CORRESPONDANCE =
  "ARCHIVE_POSITION_CORRESPONDANCE";

// callback actions//
export const archivePositionCorrespondence = (id) => ({
  type: ARCHIVE_POSITION_CORRESPONDANCE,
  payload: id,
});

// api actions//
export const fetchUnansweredPositionCorrespondences = () => (dispatch) =>
  GET("/api/v3/employer/position_correspondences/unanswered_questions")
    .then((res) => {
      const normalized = normalize(res.data);
      return dispatch(
        receivePositionCorrespondences(normalized.positionCorrespondence)
      );
    })
    .catch((error) => Promise.reject(error.response.data));

export const fetchPositionCorrespondences = (jobcastId) => (dispatch) =>
  GET(`/api/v3/employer/jobcasts/${jobcastId}/position_correspondences`).then(
    (res) => {
      const normalized = normalize(res.data);
      return dispatch(
        receivePositionCorrespondences(normalized.positionCorrespondence)
      );
    }
  );

export const fetchPositionCorrespondence =
  (positionCorrespondenceId) => (dispatch) =>
    GET(
      `/api/v3/employer/position_correspondences/${positionCorrespondenceId}`
    ).then((res) => {
      const normalized = normalize(res.data);
      return dispatch(
        receivePositionCorrespondence(normalized.positionCorrespondence)
      );
    });

export const fetchAnnouncements = () => (dispatch) =>
  GET(`/api/v3/employer/announcements`).then((res) => {
    const normalized = normalize(res.data);
    return dispatch(receiveAnnouncements(normalized.announcement));
  });

export const createAnnouncement = (data) => (dispatch) =>
  POST(`/api/v3/employer/announcements`, data)
    .then((res) => {
      const normalized = normalize(res.data);
      return dispatch(receiveAnnouncement(normalized.announcement));
    })
    .catch((errors) => Promise.reject(errors));

export const archiveQuestion = (id) => (dispatch) =>
  PATCH(`/api/v3/employer/position_correspondences/${id}/archive`)
    .then((res) => {
      const normalized = normalize(res.data);
      dispatch(refetchLeftDrawerCounts());
      return dispatch(
        receivePositionCorrespondence(normalized.positionCorrespondence)
      );
    })
    .catch((err) => Promise.reject(err));

export const fetchPositionHistory = (jobcastId) => (dispatch) =>
  GET(`/api/v3/employer/jobcasts/${jobcastId}/position_history`).then((res) => {
    const normalized = mapKeysToCamelCase(res.data);

    return dispatch(receivePositionHistory(normalized));
  });

export const fetchPositionOwnerTransferHistory = (jobcastId) => (dispatch) =>
  GET(
    `/api/v3/employer/jobcasts/${jobcastId}/transfers/position_transfers_received`
  ).then((res) => {
    const normalized = mapKeysToCamelCase(res.data);

    return dispatch(receivePositionOwnerTransferHistory(normalized));
  });

export const fetchUpdates = (jobcastId) => (dispatch) => {
  const announcements = dispatch(fetchAnnouncements(jobcastId)).then(() => {
    dispatch(receiveAnnouncementLoaded(jobcastId));
    return Promise.resolve();
  });

  const positionCorrespondences = dispatch(
    fetchPositionCorrespondences(jobcastId)
  ).then(() => {
    dispatch(receivePositionCorrespondenceLoaded(jobcastId));
    return Promise.resolve();
  });

  const positionHistory = dispatch(fetchPositionHistory(jobcastId)).then(() => {
    dispatch(receivePositionHistoryLoaded(jobcastId));
    return Promise.resolve();
  });

  const positionOwnerTransferHistory = dispatch(
    fetchPositionOwnerTransferHistory(jobcastId)
  ).then(() => {
    dispatch(receivePositionOwnerTransferHistoryLoaded(jobcastId));
    return Promise.resolve();
  });

  return Promise.all([
    announcements,
    positionCorrespondences,
    positionHistory,
    positionOwnerTransferHistory,
  ]);
};

export const submitPositionCorrespondenceAnswer = (data) => (dispatch) =>
  PATCH(
    `/api/v3/employer/position_correspondences/${data.questionId}/answer`,
    data
  ).then((res) => {
    const normalized = normalize(res.data);
    dispatch(refetchLeftDrawerCounts());
    return dispatch(
      receivePositionCorrespondence(normalized.positionCorrespondence)
    );
  });
