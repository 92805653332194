import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AssignmentIcon from "@material-ui/icons/AssignmentInd";
import DescriptionIcon from "@material-ui/icons/Description";
import HistoryIcon from "@material-ui/icons/History";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import classnames from "classnames";

import startCase from "lodash/startCase";
import CashUsd from "mdi-material-ui/CashUsd";
import React from "react";
import { connect } from "react-redux";

import { openProfileDrawer } from "../../../../../../actions/shared/uiActions";

import { EMPLOYER_CHAT } from "../../../../CandidateDrawer/constants";

import {
  AGENCY_CHAT,
  VIEW_RESUME,
  VIEW_RESPONSES,
  EVENT_HISTORY,
  HIRE_DETAILS
} from "./constants";

const styles = theme => ({
  badge: {
    height: 20,
    width: 20,
    color: "#fff",
    backgroundColor: "#FF6D6D"
  }
});

class Navigation extends React.PureComponent {
  withBadge = (icon, badge_content = null) => {
    if (badge_content && badge_content > 0) {
      return (
        <Badge
          badgeContent={badge_content}
          classes={{ badge: classnames(this.props.classes.badge) }}
        >
          {icon}
        </Badge>
      );
    }
    return icon;
  };

  handleAction = () => {
    const {
      candidate,
      openProfileDrawer,
      drawerComponent,
      navSelection,
      handleAction: customAction
    } = this.props;

    if (customAction) {
      customAction();
    } else {
      openProfileDrawer(
        drawerComponent(navSelection),
        candidate.attributes.jobcastId
      );
    }
  };

  mapSelectionToIcon(selection) {
    const style = { fontSize: 30 };
    const { unread_chat_count, unread_history_count } = this.props;
    switch (selection) {
      case AGENCY_CHAT:
      case EMPLOYER_CHAT:
        if (unread_chat_count && unread_chat_count > 0) {
          style.color = "#008DAE";
        }
        return this.withBadge(
          <QuestionAnswerIcon style={style} />,
          unread_chat_count
        );
      case VIEW_RESUME:
        return <DescriptionIcon style={style} />;
      case VIEW_RESPONSES:
        return <AssignmentIcon style={style} />;
      case EVENT_HISTORY:
        if (unread_history_count && unread_history_count > 0) {
          style.color = "#008DAE";
        }
        return this.withBadge(
          <HistoryIcon style={style} />,
          unread_history_count
        );
      case HIRE_DETAILS:
        return <CashUsd style={style} />;
      default:
        return <div style={{ height: 60, width: 60 }} />;
    }
  }

  render() {
    const { navSelection } = this.props;

    return Boolean(navSelection) ? (
      <Tooltip title={`${startCase(navSelection)}`}>
        <IconButton
          data-cy={`candidate-nav-${navSelection.toLowerCase()}`}
          style={{ height: 60, width: 60, color: "#90A4AE" }}
          onClick={this.handleAction}
        >
          {this.mapSelectionToIcon(navSelection)}
        </IconButton>
      </Tooltip>
    ) : (
      <div style={{ height: 60, width: 60 }} />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openProfileDrawer: (content, positionId) => dispatch(openProfileDrawer(content, positionId))
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(Navigation));
