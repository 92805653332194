import filter from "lodash/filter";
import map from "lodash/map";
import pick from "lodash/pick";
import sortBy from "lodash/sortBy";

import PropTypes from "prop-types";
import React from "react";
import { Col } from "react-grid-system";

import { formatFeeOptionString } from "../../../../../../../../util/formatHelpers";

import DropdownInput from "../../../../../../../forms/custom/DropdownInput.react";

const parseFeeOption = (option) => ({
  value: option.id,
  label: formatFeeOptionString(option, { withNote: true }),
  disabled: option.disabled,
});

const buildPostHireOptionGroups = ({ feeOption, feeOptionChoices }) => {
  const optionGroups = [];

  const currentFeeOption = feeOption;

  optionGroups.push({
    label: "Current Fee on Hire",
    options: [parseFeeOption(currentFeeOption)],
  });

  if (feeOptionChoices) {
    const otherOptions = filter(
      feeOptionChoices,
      (fo) => fo.id !== currentFeeOption.id
    );

    optionGroups.push({
      label: "Agency Fee Options",
      options: sortBy(
        map(otherOptions, parseFeeOption),
        (option) => option.label
      ),
    });
  }

  return optionGroups;
};

const buildPreHireOptions = ({ feeOptionChoices, feeOption }) => map(feeOptionChoices || [feeOption], (option) => parseFeeOption(option));

function FeeOptionSelection({
  candidate,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  label,
  inputProps = {},
}) {
  const determineHelperText = () => {
    if (touched.fee_option_id && errors.fee_option_id) {
      return errors.fee_option_id;
    }

    // they will be initializing a standardized preferred fee on the position
    if (
      Boolean(
        values.fee_option_id &&
          candidate.attributes.feeOptionChoices &&
          candidate.attributes.feeOptionChoices[0].isStandardized
      )
    ) {
      return "Will update existing and future candidates submitted by your preferred agencies.";
    }
  };

  const optionSets = {
    optionGroups: buildPostHireOptionGroups(candidate.attributes),
    options: buildPreHireOptions(candidate.attributes),
  };

  const optionSetKey = (Boolean(candidate.attributes.hiredAt) && !candidate.attributes.temp)
    ? "optionGroups"
    : "options";

  return (
    <Col xs={6}>
      <DropdownInput
        {...pick(optionSets, optionSetKey)}
        disabled={optionSets[optionSetKey].length === 1 || undefined}
        label={label ?? "Select a fee"}
        id="fee_option_id"
        helperText={determineHelperText()}
        error={Boolean(errors.fee_option_id && touched.fee_option_id)}
        onChange={handleChange}
        value={values.fee_option_id}
        onBlur={handleBlur}
        {...inputProps}
      />
    </Col>
  );
}

FeeOptionSelection.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  candidate: PropTypes.object.isRequired,
};

export default FeeOptionSelection;
