import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";

import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchJobCasts } from "../../../../actions/employers/jobcastActions";
import { fetchJobcastRequests } from "../../../../actions/recruiters/jobcastActions";
import { getCurrentIndividual } from "../../../../selectors/individualSelectors";
import { getJobCasts } from "../../../../selectors/jobcastSelectors";

import {
  makeGetFilteredNotifications,
  UNREAD,
} from "../../../../selectors/notificationsSelectors";
import { makeGetJobcastsRequiringAttention } from "../../../../selectors/recruiter/jobcastSelectors";
import { TAB_1_WIDTH } from "../constants";

import AllJobCastsItem from "./AllJobCastsItem";
import LiveJobCastsList from "./LiveJobCastsList";

const styles = () => ({
  root: {
    position: "fixed",
    width: TAB_1_WIDTH,
    backgroundColor: "#37474F",
    height: "100%",
    borderRight: 0,
    borderRadius: 0,
    overflowY: "auto",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 2,
    width: 40,
    height: 40,
    padding: 2,
    margin: "12px 12px 12px 24px",
  },
  logo: {
    display: "block",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: 40,
    height: 40,
  },
});

class JobcastsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    const { fetchActiveJobCasts, fetchJobcastRequests, currentIndividual } =
      this.props;

    if (currentIndividual.employer) {
      Promise.all([fetchActiveJobCasts(false), fetchActiveJobCasts(true)]).then(
        () =>
          this.setState({
            loaded: true,
            jobcasts: orderBy(
              this.props.jobcasts,
              ({ attributes }) => attributes.title
            ),
          })
      );
    } else if (currentIndividual.recruiter) {
      Promise.all([
        fetchJobcastRequests({ temp: true }),
        fetchJobcastRequests({ perm: true }),
      ]).then(() =>
        this.setState({
          loaded: true,
          jobcasts: orderBy(
            this.props.jobcasts.filter(jobcast => jobcast !== null),
            ({ attributes }) => attributes.title
          ),
        }));
    }
  }

  renderLogo() {
    const { classes } = this.props;
    return (
      <div className={classes.logoContainer}>
        <a
          title="RecruitiFi"
          className={classnames(classes.logo, "logo")}
          href="/app"
          style={{ backgroundSize: "contain", backgroundRepeat: "no-repeat" }}
        >
          {" "}
        </a>
      </div>
    );
  }

  renderJobCastList() {
    const { selectedId, setSelectedId } = this.props;
    const { jobcasts } = this.state;

    return (
      <LiveJobCastsList
        jobcasts={jobcasts}
        setSelectedId={setSelectedId}
        selectedId={selectedId}
      />
    );
  }

  static renderLoading() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress style={{ width: 60, height: 60, color: "#FFFFFF" }} />
      </div>
    );
  }

  render() {
    const {
      classes, totalAlertsCount, setSelectedId, selectedId
    } = this.props;
    const { loaded } = this.state;

    return (
      <Paper
        data-cy="jobcasts-tab"
        classes={{ root: classes.root }}
      >
        {this.renderLogo()}
        <AllJobCastsItem
          count={totalAlertsCount}
          isSelected={!Boolean(selectedId)}
          setSelectedId={setSelectedId}
        />
        {loaded ? this.renderJobCastList() : JobcastsTab.renderLoading()}
      </Paper>
    );
  }
}

const makeMapStateToProps = () => {
  const getFilteredNotifications = makeGetFilteredNotifications();
  // initializing two of the same function to preserve selector argument memoization
  const getPermJobcastsRequiringAttention = makeGetJobcastsRequiringAttention();
  const getTempJobcastsRequiringAttention = makeGetJobcastsRequiringAttention();

  const mapStateToProps = (state) => {
    const currentIndividual = getCurrentIndividual(state);
    const getTypedJobcasts = (type) => getJobCasts(state, type.toUpperCase());

    return {
      currentIndividual,
      jobcasts: currentIndividual.employer
        ? filter(
          getTypedJobcasts("started"),
          (jobcast) =>
            jobcast.attributes.individualId === currentIndividual.id
        )
        : [
          ...getPermJobcastsRequiringAttention(state, { notification: true }),
          ...getTempJobcastsRequiringAttention(state, { notification: true, isTemp: true }),
        ],
      totalAlertsCount: getFilteredNotifications(state, {
        [UNREAD]: true,
        recipientId: currentIndividual.id,
      }).length,
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = (dispatch) => ({
  fetchActiveJobCasts: (temp) => dispatch(fetchJobCasts("active", true, temp)),
  fetchJobcastRequests: (params) =>
    dispatch(fetchJobcastRequests({ status: "requires_attention", ...params })),
});

export default connect(
  makeMapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(JobcastsTab));
